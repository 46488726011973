import { GridConfig, KPIData } from '../ExplorerGrid/grid/utils/gridView';
import { CampaignCollection } from '../../types/collections';

export const updateWithKpiData = (
    config: GridConfig,
    collection: CampaignCollection | undefined
): GridConfig => {
    const baselineKPIs: KPIData = {};

    collection?.baselineSet.targets.forEach(target => {
        baselineKPIs[target.id] = target.value;
    });

    return {
        ...config,
        kpiData: baselineKPIs,
        baselineId: collection?.baselineSet.id,
    };
};
