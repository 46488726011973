import axios, { AxiosResponse } from 'axios';
import { getRustAPIUrl } from '../../../utils/api';
import { useQuery } from '@tanstack/react-query';
import { WorkspaceMember } from '../../../types/workspace-management';
import { objectToCamel } from 'ts-case-convert';

import { useActiveWorkspaceId } from '../../../providers/useActiveWorkspaceId.ts';

type PermissionResponse = {
    data: WorkspaceMember[];
};
const fetchPermissions = async (
    workspaceId: string
): Promise<WorkspaceMember[]> => {
    const result = await axios.get<
        PermissionResponse,
        AxiosResponse<PermissionResponse>
    >(`${getRustAPIUrl()}/workspaces/${workspaceId}/members`);

    return objectToCamel(result.data.data);
};

export const useMembers = () => {
    const workspaceId = useActiveWorkspaceId();
    return useQuery(membersKey(workspaceId), () =>
        fetchPermissions(workspaceId)
    );
};

export const membersKey = (workspaceId: string) => [
    'workspace-members',
    workspaceId,
];
