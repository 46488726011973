import { ValueFormatterParams } from '@ag-grid-community/core';
import { Localization } from '../../../../types/datamodel/localization';

export const currencyFormatter = ({
    currency,
    locale,
}: Pick<Localization, 'currency' | 'locale'>) => {
    const formatter = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency,
    });

    return (params: ValueFormatterParams) => {
        if (!params?.value || isNaN(params?.value)) return '';
        return formatter.format(params.value);
    };
};
