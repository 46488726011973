import { Column, ColWidth } from '../../Table/types';
import { ModifierRow } from '../../Table';
import { RemoveBaselineColumn } from './RemoveBaselineColumn.tsx';

export const getRemoveBaselineColumn = <
    T extends ModifierRow
>(): Column<T> => ({
    accessor: 'modifiers',
    title: '',
    isClickable: false,
    render: row => <RemoveBaselineColumn row={row} />,
    width: ColWidth.Tiny,
});
