import { DateFieldWrapper } from './DateFieldWrapper.tsx';
import { Stack, styled } from '@mui/material';
import { useDateRangePicker } from './useDateRangePicker.tsx';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';

const localeText = {
    fieldDayPlaceholder: () => 'dd',
    fieldMonthPlaceholder: () => 'mm',
    fieldYearPlaceholder: () => 'yyyy',
};

export const DateFields = () => {
    const {
        dispatch,
        currentDateRange,
        state: { currentSide },
    } = useDateRangePicker();
    const handleChange =
        (type: 'selectStart' | 'selectEnd') => (date: Date) => {
            dispatch({ type, date });
        };

    return (
        <LocalizationProvider
            dateAdapter={AdapterDateFns}
            localeText={localeText}
        >
            <DateFieldsStack>
                <DateFieldWrapper
                    label="From"
                    value={currentDateRange.start}
                    onChange={handleChange('selectStart')}
                    focused={currentSide === 'start'}
                    onFocus={() =>
                        dispatch({
                            type: 'selectSide',
                            side: 'start',
                        })
                    }
                />
                <DateFieldWrapper
                    label="To"
                    value={currentDateRange.end}
                    onChange={handleChange('selectEnd')}
                    focused={currentSide === 'end'}
                    minDate={currentDateRange.start}
                    onFocus={() =>
                        dispatch({
                            type: 'selectSide',
                            side: 'end',
                        })
                    }
                />
            </DateFieldsStack>
        </LocalizationProvider>
    );
};

const DateFieldsStack = styled(Stack)(({ theme }) => ({
    borderTop: `1px solid ${theme.border.medium}`,
}));

DateFieldsStack.defaultProps = {
    direction: 'row',
    p: 2,
};
