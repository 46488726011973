import { Button, Stack } from '@mui/material';
import {
    addDays,
    addWeeks,
    Day,
    eachWeekOfInterval,
    isThisWeek,
} from 'date-fns';
import { Dispatch, memo, useMemo } from 'react';
import { getWeekNumber } from '../../utils/weeks';
import { GridSpacer } from './CalendarDay';
import { DateRangePickerAction, PeriodType } from './reducer';

interface CalendarWeekNumbersProps {
    month: Interval;
    weekStartsOn: Day;
    currentPeriod: PeriodType;
    dispatch: Dispatch<DateRangePickerAction>;
}

const CalendarWeekNumbers = ({
    month,
    weekStartsOn,
    currentPeriod,
    dispatch,
}: CalendarWeekNumbersProps) => {
    const weeks = useMemo(
        () => eachWeekOfInterval(month, { weekStartsOn }),
        [month, weekStartsOn]
    );

    const selectWeek = (w: Date) => () => {
        const preset = isThisWeek(w)
            ? 'this_week'
            : isThisWeek(addWeeks(w, 1))
            ? 'last_week'
            : 'custom';

        if (currentPeriod === 'main' && preset !== 'custom') {
            dispatch({
                type: 'selectPreset',
                period: 'main',
                preset,
            });
        } else {
            dispatch({
                type: 'selectRange',
                dateRange: {
                    start: w,
                    end: addDays(w, 6),
                },
            });
        }
    };

    return (
        <Stack spacing={0.5} sx={{ opacity: 0.5 }}>
            <GridSpacer />
            {weeks.map(w => (
                <Button
                    disableElevation={true}
                    key={getWeekNumber(w, weekStartsOn)}
                    onClick={selectWeek(w)}
                    color="info"
                    sx={{
                        minWidth: 0,
                        fontSize: 14,
                        width: 32,
                        height: 32,
                        borderRadius: 32,
                    }}
                >
                    {getWeekNumber(w, weekStartsOn)}
                </Button>
            ))}
        </Stack>
    );
};

const Component = memo(CalendarWeekNumbers);
export { Component as CalendarWeekNumbers };
