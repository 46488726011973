import { useSeinoStore } from '../store/seino-store';
import { useContext, useMemo } from 'react';
import { DateRangeContext } from '../providers/DateRangeProvider.tsx';
import { DateRanges } from '../components/DateRangePicker/reducer.tsx';
import {
    lastDays,
    precedingPeriod,
} from '../components/DateRangePicker/dateHelpers.ts';

type UseDateRangeReturn = {
    dateRanges: DateRanges;
    setDateRanges: (value: DateRanges) => void;
};

export const useDateRange = (): UseDateRangeReturn => {
    const context = useContext(DateRangeContext);
    const store = useSeinoStore(
        ({ dataGrid: { dateRanges, setDateRanges } }) => ({
            dateRanges,
            setDateRanges,
        })
    );

    const dateRanges = context?.enabled ? context.dateRanges : store.dateRanges;

    if (!dateRanges) {
        throw new Error('Date ranges should be provided');
    }

    const setDateRanges = context?.enabled
        ? context.setDateRanges
        : store.setDateRanges;

    return useMemo(
        () => ({ dateRanges, setDateRanges }),
        [dateRanges, setDateRanges]
    );
};

const defaultDateRange = () => lastDays(30);
const defaultCompareRange = () => precedingPeriod(defaultDateRange());
export const defaultDateRanges = (): DateRanges => ({
    main: defaultDateRange(),
    compare: defaultCompareRange(),
});
