import { Close, Campaign } from '@mui/icons-material';
import { useCallback, useState } from 'react';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import {
    Paper,
    Modal,
    Box,
    Badge,
    IconButton,
    Typography,
    Divider,
    styled,
} from '@mui/material';

import { useLocalStorage } from 'usehooks-ts';
import { isAfter } from 'date-fns';
import { Link } from 'react-router-dom';

const StyledModal = styled(Modal)(`
    display: flex;
    align-items: flex-start;
    margin: 4rem 8rem;
    overflow-y: auto;
    justify-content: center;
`);

const StyledContent = styled(Box)(`
    padding: 0 4rem 4rem 4rem;
    height: auto;
    line-height: 2rem;
    overflow-y: scroll;
`);

const CloseButton = styled(Box)(`
    display: flex;
    justify-content: flex-end;
    margin: 1rem;
`);

const ModalContent = styled(Box)(`
    display: flex;
    flex-wrap: nowrap;

    @media (max-width: 1024px) {
        flex-wrap: wrap;
    }
`);

export const Changelog = () => {
    const [open, setOpen] = useState(false);

    // NB - Set this to the date you make changes to the change log
    // when opened the user sets the current timestamp in localstorage
    // Used for displaying the red '!' in the toolbar
    const dateUpdated = new Date('2023-10-26');

    const [dateViewed, setDateViewed] = useLocalStorage<string | undefined>(
        'changelog',
        undefined
    );

    const handleOnClose = () => setOpen(false);

    const toggleOpen = useCallback(() => {
        setOpen(!open);
        setDateViewed(new Date().toISOString());
    }, [setDateViewed, open]);

    let iconContent = <Campaign />;

    const changelogHasNewUpdates =
        dateViewed === undefined || isAfter(dateUpdated, new Date(dateViewed));

    if (changelogHasNewUpdates) {
        iconContent = (
            <Badge badgeContent={'!'} color="error">
                <Campaign />
            </Badge>
        );
    }

    return (
        <>
            <IconButton onClick={toggleOpen} color="contrastText">
                {iconContent}
            </IconButton>
            <StyledModal
                open={open}
                role="presentation"
                onClose={handleOnClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Paper sx={{ overflowX: 'hidden' }}>
                    <CloseButton>
                        <IconButton onClick={toggleOpen}>
                            <Close />
                        </IconButton>
                    </CloseButton>
                    <StyledContent>
                        <Box>
                            <Typography variant="h2" id="parent-modal-title">
                                Recent updates
                            </Typography>
                            <Typography variant="caption">
                                Let us show you what's new in SEINō!
                            </Typography>
                            <Box my={2}>
                                <Divider />
                            </Box>
                        </Box>
                        <ModalContent>
                            <Box>
                                <Box mb={2}>
                                    <Typography variant="h5">
                                        Alerting
                                    </Typography>
                                </Box>
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    marginRight={2}
                                    maxWidth={500}
                                >
                                    <Typography pr={4}>
                                        Alerting is a powerful new feature,
                                        developed together with our customers to
                                        safeguard against ever finding out too
                                        late that something is going wrong with
                                        a mailing.
                                    </Typography>
                                </Box>
                            </Box>
                            <Box
                                display="flex"
                                justifyContent="center"
                                flexDirection="column"
                                alignItems="center"
                                marginTop={2}
                            >
                                <Zoom>
                                    <iframe
                                        width="560"
                                        style={{ border: 'none' }}
                                        height="315"
                                        src="https://www.youtube.com/embed/IFS-I0l37Ow?si=PC29Bws7yv7umGaL"
                                        title="YouTube video player"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    ></iframe>
                                </Zoom>
                                <Typography variant="caption" ml={3}>
                                    Learn how to setup powerful alerts in SEINō
                                </Typography>
                            </Box>
                        </ModalContent>
                        <Box>
                            <Link to="/changes" onClick={() => setOpen(false)}>
                                View all updates
                            </Link>
                        </Box>
                    </StyledContent>
                </Paper>
            </StyledModal>
        </>
    );
};
