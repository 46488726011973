import { useIntegrations } from '../../../hooks/use-integrations.ts';
import Grid2 from '@mui/material/Unstable_Grid2';
import {
    IntegrationType,
    SupportedIntegrations,
} from '../../../types/workspace-management';
import { IntegrationCard } from './IntegrationCard.tsx';

export const AvailableIntegrations = () => {
    const { data } = useIntegrations();

    const enabledIntegrations = (data || []).map(i => i.type);

    const integrations = Object.keys(SupportedIntegrations).filter(
        k => !(enabledIntegrations as string[]).includes(k)
    ) as IntegrationType[];

    return (
        <Grid2 container spacing={4}>
            {integrations.map(integration => (
                <Grid2 xs={4} key={`integration-${integration}`}>
                    <IntegrationCard integrationType={integration} />
                </Grid2>
            ))}
        </Grid2>
    );
};
