import _ from 'lodash';

export type Order = 'asc' | 'desc';

function sort<T>(a: T, b: T) {
    if (typeof a === 'string' && typeof b === 'string') {
        return a.localeCompare(b, undefined, { numeric: true });
    }

    if (typeof a === 'number' && typeof b === 'number') {
        return a - b;
    }

    return a < b ? -1 : 1;
}

function sortBy<T>(getValue: (item: T) => unknown, order: Order = 'asc') {
    return (a: T, b: T) =>
        order === 'asc'
            ? sort(getValue(a), getValue(b))
            : sort(getValue(b), getValue(a));
}

const isDefined = <T>(x: T | null | undefined): x is T =>
    x !== null && x !== undefined;

export function sortItemsByKey<T>(items: T[], key: keyof T, order: Order) {
    return sortItemsBy(items, item => item[key], order);
}

export function sortItemsBy<T>(
    items: T[],
    getValue: (item: T) => unknown,
    order: Order
) {
    const [values, empties] = _.partition(items, item =>
        isDefined(getValue(item))
    );
    const sorted = values.sort(sortBy(getValue, order));

    // Empty values always go last for better UX
    return [...sorted, ...empties];
}
