import {
    Container as MuiContainer,
    Paper,
    Stack,
    Typography,
    styled,
} from '@mui/material';
import { ReactElement, ReactNode } from 'react';

const PageContainer = styled(MuiContainer)(() => ({
    xs: { maxWidth: 'xl' },
    md: { maxWidth: 'lg' },
}));

const Content = styled(Paper, {
    shouldForwardProp: property => property !== 'padding',
})<{ padding?: boolean }>(({ padding, theme }) => ({
    margin: '1rem 0',
    padding: padding ? theme.spacing(2) : 0,
}));

export const Header = styled(Typography, {
    shouldForwardProp: property => property !== 'tabbed',
})<{ tabbed?: boolean }>(({ theme, tabbed = false }) => ({
    variant: 'h6',
    fontWeight: 'bold',
    paragraph: false,
    paddingLeft: tabbed ? 18 : 0,
    color: theme.palette.primary.dark,
}));

const PageHeader = ({
    header,
    sideBar,
    tabbed = false,
}: {
    header?: string | ReactElement;
    sideBar?: ReactNode;
    padding?: boolean;
    tabbed?: boolean;
}) => (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
        {header && <Header tabbed={tabbed}>{header}</Header>}
        {sideBar && sideBar}
    </Stack>
);

export const Page = ({
    children,
    padding = true,
    ...headerProps
}: {
    header?: string | ReactElement;
    sideBar?: ReactNode;
    children: ReactNode;
    padding?: boolean;
    tabbed?: boolean;
}) => (
    <PageContainer>
        <PageHeader {...headerProps} />

        <Content elevation={0} padding={padding}>
            {children}
        </Content>
    </PageContainer>
);
Page.Container = PageContainer;
Page.Header = PageHeader;
Page.Content = Content;
