import { useActiveWorkspace } from '../../providers/WorkspaceProvider.hooks.ts';
import { useLocalStorage } from 'usehooks-ts';
import { useMutation } from '@tanstack/react-query';

export type RequestOnboardingProps = {
    idp_id: string;
    email: string;
    name: string;
    workspace_id: string;
    workspace_name: string;
};

const requestOnboarding = async (data: RequestOnboardingProps) =>
    window.fetch('https://hook.eu2.make.com/wyipew555a8emhaujii885qowda6a12g', {
        method: 'POST',
        headers: {
            'content-type': 'application/json;charset=UTF-8',
        },
        body: JSON.stringify(data),
    });

export function useRequestAccountOnboarding() {
    const { workspace } = useActiveWorkspace();

    const [requested, setRequested] = useLocalStorage(
        `workspace-activation-${workspace?.id}`,
        false
    );

    const request = useMutation(requestOnboarding, {
        onSuccess: _ => {
            setRequested(true);
        },
    });

    return {
        requested,
        request: (data: RequestOnboardingProps) => request.mutate(data),
        isLoading: request.isLoading,
        isError: request.isError,
    };
}
