import {
    Button,
    ButtonGroup,
    ClickAwayListener,
    Divider,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Popper,
    Typography,
} from '@mui/material';
import React, { useRef, useState } from 'react';
import { ArrowDropDown, CheckCircle } from '@mui/icons-material';
import styled from 'styled-components';
import {
    AccessLevel,
    friendlyAccessLevel,
    Permission,
} from '../../../types/permission/permission';

const OptionButton = styled(Typography).attrs({
    variant: 'body2',
    fontSize: 12,
})``;

interface SelectorInterface {
    enabled?: boolean;
    value: Permission;
    onChange: (newValue: AccessLevel) => void;
    onRemove: () => void;
}

export function AccessLevelSelector({
    enabled = true,
    value,
    onChange,
    onRemove,
}: SelectorInterface) {
    const [open, setOpen] = useState(false);
    const anchorRef = useRef<HTMLDivElement | null>(null);

    const handleToggle = () => setOpen(prevOpen => !prevOpen);
    const handleClose = (event: Event) => {
        const currentAnchorRef = anchorRef.current;
        if (
            currentAnchorRef &&
            currentAnchorRef.contains(event.target as HTMLElement)
        ) {
            return;
        }

        setOpen(false);
    };

    return (
        <>
            <ButtonGroup
                disabled={!enabled}
                variant="text"
                disableElevation
                disableRipple
                disableFocusRipple
                ref={anchorRef}
            >
                <Button
                    style={{ textTransform: 'capitalize' }}
                    size="small"
                    onClick={handleToggle}
                    endIcon={enabled && <ArrowDropDown />}
                >
                    <OptionButton>
                        {friendlyAccessLevel(value.accessLevel)}
                    </OptionButton>
                </Button>
            </ButtonGroup>

            <Popper
                sx={{ zIndex: 1301 }}
                open={open}
                anchorEl={anchorRef.current}
            >
                <Paper sx={{ minWidth: 'fit-content', maxWidth: '400px' }}>
                    <ClickAwayListener onClickAway={handleClose}>
                        <List sx={{ paddingBlock: 0 }}>
                            <ListItemButton
                                disabled={value.accessLevel === 'full_access'}
                                dense
                                onClick={() => onChange('full_access')}
                            >
                                <ListItemIcon>
                                    {value.accessLevel === 'full_access' && (
                                        <CheckCircle fontSize="small" />
                                    )}
                                </ListItemIcon>
                                <ListItemText
                                    primary="Full access"
                                    secondary={
                                        <Typography variant="caption">
                                            Can make changes and share with
                                            others
                                        </Typography>
                                    }
                                />
                            </ListItemButton>
                            <ListItemButton
                                disabled={value.accessLevel === 'read_write'}
                                dense
                                onClick={() => onChange('read_write')}
                            >
                                <ListItemIcon>
                                    {value.accessLevel === 'read_write' && (
                                        <CheckCircle fontSize="small" />
                                    )}
                                </ListItemIcon>
                                <ListItemText
                                    primary="Can edit"
                                    secondary={
                                        <Typography variant="caption">
                                            Can make changes but not share with
                                            others
                                        </Typography>
                                    }
                                />
                            </ListItemButton>
                            <ListItemButton
                                disabled={value.accessLevel === 'read'}
                                dense
                                onClick={() => onChange('read')}
                            >
                                <ListItemIcon>
                                    {value.accessLevel === 'read' && (
                                        <CheckCircle fontSize="small" />
                                    )}
                                </ListItemIcon>
                                <ListItemText
                                    primary="Can view"
                                    secondary={
                                        <Typography variant="caption">
                                            Cannot edit and share with others
                                        </Typography>
                                    }
                                />
                            </ListItemButton>
                            <Divider />
                            <ListItemButton onClick={onRemove}>
                                <ListItemIcon />
                                <ListItemText
                                    primary="No access"
                                    primaryTypographyProps={{
                                        color: 'error.dark',
                                    }}
                                />
                            </ListItemButton>
                        </List>
                    </ClickAwayListener>
                </Paper>
            </Popper>
        </>
    );
}
