import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { getRustAPIUrl } from '../../../../../utils/api';
import { useActiveWorkspaceId } from '../../../../../providers/useActiveWorkspaceId.ts';

export type TrackedView = {
    id: number;
    name: string;
};

export type Database = { id: number; name: string };
type View = { id: number; name: string; has_children: boolean };

/**
 * Return the list of currently tracked views.
 */
export const useTrackedViews = (integrationId: string) => {
    const workspaceId = useActiveWorkspaceId();

    return useQuery({
        queryKey: ['workspaces', workspaceId, integrationId, 'tracked-views'],
        queryFn: async (): Promise<TrackedView[]> => {
            const response = await axios.get(
                `${getRustAPIUrl()}/workspaces/${workspaceId}/integrations/copernica/${integrationId}/tracked-views`
            );

            return response.data;
        },
    });
};

export const useUpdateTrackedViews = (integrationId: string) => {
    const workspaceId = useActiveWorkspaceId();
    const queryClient = useQueryClient();
    const queryKey = [
        'workspaces',
        workspaceId,
        integrationId,
        'tracked-views',
    ];

    return useMutation({
        mutationKey: queryKey,
        onMutate: async newValue => {
            await queryClient.cancelQueries(queryKey);
            queryClient.setQueryData(queryKey, newValue);
        },
        mutationFn: async (views: TrackedView[]) => {
            await axios.put(
                `${getRustAPIUrl()}/workspaces/${workspaceId}/integrations/copernica/${integrationId}/tracked-views`,
                views
            );
        },
    });
};

/**
 * Return a list of profile databases in the configured Copernica account.
 */
export const useCopernicaDatabases = (integrationId: string) => {
    const workspaceId = useActiveWorkspaceId();

    return useQuery({
        queryKey: ['workspaces', workspaceId, integrationId, 'databases'],
        queryFn: async (): Promise<Database[]> => {
            const response = await axios.get(
                `${getRustAPIUrl()}/workspaces/${workspaceId}/integrations/copernica/${integrationId}/databases`
            );

            return response.data.data;
        },
    });
};

/**
 * Returns the views in the given database. Note that views are organized as a
 * tree in Copernica. This query only returns the first level, i.e. those views
 * that do not have a parent view.
 */
export const useCopernicaDatabaseViews = (
    integrationId: string,
    databaseId: number,
    enabled: boolean
) => {
    const workspaceId = useActiveWorkspaceId();

    return useQuery({
        queryKey: [
            'workspaces',
            workspaceId,
            integrationId,
            'databases',
            databaseId,
            'views',
        ],
        queryFn: async (): Promise<View[]> => {
            const response = await axios.get(
                `${getRustAPIUrl()}/workspaces/${workspaceId}/integrations/copernica/${integrationId}/databases/${databaseId}/views`
            );

            return response.data.data;
        },
        enabled,
    });
};

/**
 * Returns the list of child views of the given view.
 * @see useCopernicaDatabaseViews
 */
export const useCopernicaChildViews = (
    integrationId: string,
    viewId: number,
    enabled: boolean
) => {
    const workspaceId = useActiveWorkspaceId();

    return useQuery({
        queryKey: ['workspaces', workspaceId, integrationId, 'views', viewId],
        queryFn: async (): Promise<View[]> => {
            const response = await axios.get(
                `${getRustAPIUrl()}/workspaces/${workspaceId}/integrations/copernica/${integrationId}/views/${viewId}/views`
            );

            return response.data.data;
        },
        enabled,
    });
};
