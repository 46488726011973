import { useContext } from 'react';
import { DateRangePickerContext } from './DateRangePickerContext.tsx';

export const useDateRangePicker = () => {
    const context = useContext(DateRangePickerContext);

    return {
        ...context,
        currentDateRange: context.state.dateRanges[context.state.currentPeriod],
    };
};
