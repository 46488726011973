import { Container, Stack, styled } from '@mui/material';
import { PropsWithChildren } from 'react';

export const drawerWidth = 360;

export const Layout = ({ children }: PropsWithChildren) => (
    <Container
        sx={({ elevation }) => ({
            backgroundColor: elevation.base,
            position: 'relative',
            height: '100%',
        })}
        component={Stack}
        maxWidth={false}
    >
        {children}
    </Container>
);

Layout.Main = styled('main', {
    shouldForwardProp: prop => prop !== 'open',
})<{
    open?: boolean;
}>(({ theme, open }) => ({
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: 0,
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: `${drawerWidth}px`,
    }),
}));
