import { Box, Button, MenuItem, Select, Stack, TextField } from '@mui/material';
import React, { useState } from 'react';
import { FormDialog } from '../../components/Dialog/FormDialog';
import { useExplorerViews } from '../../hooks/use-explorer-views';
import { useDatasets } from '../../hooks/use-datasets';
import _ from 'lodash';
import { useDatasetSchema } from '../../hooks/use-dataset-schema';
import { useActiveWorkspace } from '../../providers/WorkspaceProvider.hooks';
import { ExplorerView } from '../../components/ExplorerGrid/grid/utils/gridView';
import { useActiveUser } from '../../providers/CurrentUserProvider.hooks';
import { newView } from '../../types/explorer';

interface SelectDatasetPanelProps {
    open: boolean;
    onClose: () => void;
    onCreate: (view: ExplorerView) => void;
}

interface CreateViewProps {
    onCreate: (view: ExplorerView) => void;
}

export const CreateView = ({ onCreate }: CreateViewProps) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <Box>
            <Button size="small" type="button" onClick={() => setIsOpen(true)}>
                New view
            </Button>
            <CreateViewModal
                open={isOpen}
                onClose={() => setIsOpen(false)}
                onCreate={onCreate}
            />
        </Box>
    );
};

const CreateViewModal = ({
    open,
    onClose,
    onCreate,
}: SelectDatasetPanelProps) => {
    const { workspaceId } = useActiveWorkspace();
    const user = useActiveUser();
    const { createView } = useExplorerViews(workspaceId);

    const { data: availableDatasets, isLoading: datasetsLoading } =
        useDatasets();

    const [title, setTitle] = useState('');
    const [dataset, setDataset] = useState('campaign_metrics_by_send_date');

    const { data: schema, isLoading: schemaLoading } =
        useDatasetSchema(dataset);

    const options = _.chain(availableDatasets || [])
        .filter(({ id }) => id !== 'campaigns')
        .map(({ id, title }) => ({ label: title || id, value: id }))
        .sortBy('label')
        .value();

    const reset = () => {
        setTitle('');
        setDataset('campaign_metrics_by_send_date');
    };

    const handleClose = () => {
        reset();
        onClose();
    };

    const handleConfirm = () => {
        if (!schema) {
            throw new Error('Schema is unexpectedly undefined');
        }

        const view = newView(title, dataset, schema, {
            id: user.memberId,
            name: user.name,
            email: user.email,
        });
        createView(view);
        onCreate(view);
        handleClose();
    };

    return (
        <FormDialog
            open={open}
            title="Create view"
            contentLoading={datasetsLoading}
            content={
                <Stack spacing={2}>
                    <TextField
                        size="small"
                        label="Title"
                        value={title}
                        onChange={e => setTitle(e.target.value)}
                    />
                    <Select
                        size="small"
                        value={dataset}
                        onChange={e => setDataset(e.target.value)}
                    >
                        {options.map(({ label, value }) => (
                            <MenuItem key={value} value={value}>
                                {label}
                            </MenuItem>
                        ))}
                    </Select>
                </Stack>
            }
            handleClose={handleClose}
            handleConfirm={handleConfirm}
            confirmButtonText="Save"
            confirmButtonProps={{ disabled: schemaLoading }}
        ></FormDialog>
    );
};
