import { useQuery } from '@tanstack/react-query';
import { AlertRule } from '../types/alerting';
import { sortBy } from 'lodash';
import { getAlertRules } from '../api-client/alert-rules';
import { useActiveWorkspaceId } from '../providers/useActiveWorkspaceId.ts';

export const useAlertRules = () => {
    const workspaceId = useActiveWorkspaceId();

    const result = useQuery<AlertRule[]>({
        queryKey: [workspaceId, 'alert-rules'],
        queryFn: async () => {
            return getAlertRules(workspaceId);
        },

        // todo: remove
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        staleTime: Infinity,
    });

    return {
        ...result,
        data: result.data && sortBy(result.data, 'title'),
    };
};
