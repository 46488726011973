import axios from 'axios';
import { getRustAPIUrl, MutationOptions, SimpleError } from '../../utils/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';

interface SignupProps {
    name: string;
    company: string;
    esp: string[];
    newsletter_opt_in: boolean;
}

const signup = async (data: SignupProps) => {
    return await axios.post<unknown, Error | SimpleError>(
        `${getRustAPIUrl()}/signup`,
        {
            ...data,
        }
    );
};

export const useOnboarding = (options?: MutationOptions<SignupProps>) => {
    const queryClient = useQueryClient();
    return useMutation<unknown, Error, SignupProps>({
        mutationFn: variables => signup(variables),
        ...options,
        onSuccess: async (data, variables, context) => {
            await queryClient.invalidateQueries(['fetch-initial-data']);
            options?.onSuccess?.(data, variables, context);
        },
    });
};
