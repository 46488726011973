import { Box, Button } from '@mui/material';
import { useDashboardDialog } from './useDashboardDialog.tsx';
import * as React from 'react';

export const ShareDashboard = () => {
    const { openDialog } = useDashboardDialog();

    const handleShareOnClick = () => openDialog({ panel: 'sharing' });

    return (
        <Box>
            <Button size="small" variant="text" onClick={handleShareOnClick}>
                Share
            </Button>
        </Box>
    );
};
