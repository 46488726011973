import {
    AccessLevel,
    createPermission,
    ObjectType,
    Permission,
} from '../../types/permission/permission.ts';
import { shareColumns } from '../../pages/Dashboard/Dialog/shareColumns.tsx';
import { Table } from '../Table';
import * as React from 'react';
import { usePermissions } from '../../hooks/use-permissions.ts';
import { usePermissionMutation } from '../../hooks/use-permission-mutation.ts';
import { useActiveWorkspace } from '../../providers/WorkspaceProvider.hooks.ts';
import { useActiveUser } from '../../providers/CurrentUserProvider.hooks.ts';
import { useQueryClient } from '@tanstack/react-query';

export function PermittedSubjects({
    objectType,
    objectId,
    currentAccessLevel,
}: {
    objectType: ObjectType;
    objectId: string;
    currentAccessLevel: AccessLevel;
}) {
    const { memberId } = useActiveUser();
    const {
        workspaceId,
        workspace: { displayName },
    } = useActiveWorkspace();
    const { data: permissions = [], isLoading } = usePermissions(
        workspaceId,
        objectId,
        objectType
    );

    const permissionsWithWorkspace = permissions;
    if (!permissions.find(({ subjectId }) => subjectId === workspaceId)) {
        permissionsWithWorkspace.push(
            createPermission(
                workspaceId,
                'workspace',
                workspaceId,
                displayName,
                objectType,
                objectId,
                'none'
            )
        );
    }

    const queryClient = useQueryClient();
    const mutate = usePermissionMutation();

    const accessLevelOnChange = (
        permission: Permission,
        newAccessLevel: AccessLevel
    ) => {
        const newValue = {
            ...permission,
            accessLevel: newAccessLevel,
        };

        mutate.mutate(newValue, {
            onSuccess: () => {
                queryClient.invalidateQueries([
                    'usePermissions',
                    workspaceId,
                    objectId,
                    objectType,
                ]);
            },
        });
    };

    // Full admin meaning: having the full_access directly on self, not via workspace.
    const currentUserIsFullAdmin = permissionsWithWorkspace.some(
        x => x.subjectId === memberId && x.accessLevel === 'full_access'
    );

    return (
        <Table<Permission>
            orderByFunction={(a, b) => {
                if (a.subjectId === memberId) {
                    return Number.MIN_SAFE_INTEGER;
                }

                if (a.subjectType === 'workspace') {
                    return 1;
                } else if (b.subjectType === 'workspace') {
                    return -1;
                }

                return a.subjectName.localeCompare(b.subjectName);
            }}
            isLoading={isLoading}
            rows={permissionsWithWorkspace}
            columns={shareColumns(
                memberId,
                currentAccessLevel,
                accessLevelOnChange,
                currentUserIsFullAdmin
            )}
        />
    );
}
