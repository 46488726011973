import { GetTokenSilentlyOptions, useAuth0 } from '@auth0/auth0-react';
import { useCallback } from 'react';
import { AUTH0_API_AUDIENCE, AUTH0_DEFAULT_SCOPE } from '../constants';
import { LogoutOptions } from '@auth0/auth0-react/src/auth0-context';

const DEFAULT_OPTIONS: GetTokenSilentlyOptions = {
    authorizationParams: {
        audience: AUTH0_API_AUDIENCE,
        scope: AUTH0_DEFAULT_SCOPE,
    },
};

interface SeinoAuth {
    isLoading: boolean;
    fetchToken: () => Promise<string>;
    logout: (options?: LogoutOptions) => void;
    error: Error | undefined;
}

export const useSeinoAuth = (): SeinoAuth => {
    const { error, isLoading, getAccessTokenSilently, logout } = useAuth0();

    const fetchToken = useCallback(
        async () => await getAccessTokenSilently(DEFAULT_OPTIONS),
        [getAccessTokenSilently]
    );

    return {
        isLoading,
        error,
        fetchToken,
        logout,
    };
};
