export type IntegrationType = keyof typeof SupportedIntegrations;

export const SupportedIntegrations = {
    braze: {
        label: 'Braze',
        text: 'Synchronize the e-mail events from your Braze environment to SEINō.',
        child: 'account',
        svg: '/img/integrations/braze-icon.svg',
        new: false,
        custom: true,
    },
    copernica: {
        label: 'Copernica',
        text: 'Import all your e-mail activity from Copernica to SEINō.',
        child: 'account',
        svg: '/img/integrations/copernica-icon.svg',
        new: false,
        custom: false,
    },
    deployteq: {
        label: 'Deployteq',
        text: 'Synchronize all your e-mail activity from Deployteq to SEINō.',
        child: 'brand',
        svg: '/img/integrations/deployteq-icon.svg',
        new: false,
        custom: false,
    },
    google_analytics: {
        label: 'Google Analytics',
        text: 'Import your website data from GA into SEINō. Supports both Universal Analytics and Google Analytics 4.',
        child: 'view',
        svg: '/img/integrations/google-analytics-icon.svg',
        new: false,
        custom: false,
    },
    klaviyo: {
        label: 'Klaviyo',
        text: 'Import your e-mail flows and campaign data from Klaviyo to SEINō.',
        child: 'account',
        svg: '/img/integrations/klaviyo-icon.svg',
        new: false,
        custom: false,
    },
    mailcampaigns: {
        label: 'MailCampaigns',
        text: 'Analyze your email campaigns by connecting MailCampaigns with SEINō.',
        child: 'account',
        svg: '/img/integrations/mailcampaigns.svg',
        new: false,
        custom: true,
    },
    maileon: {
        label: 'Maileon',
        text: 'Connect Maileon to view your e-mail data in SEINō.',
        child: 'account',
        svg: '/img/integrations/maileon.svg',
        new: true,
        custom: true,
    },
    mailkit: {
        label: 'Mailkit',
        text: 'Quickly analyze data across all your Mailkit accounts in SEINō.',
        child: 'account',
        svg: '/img/integrations/mailkit.svg',
        new: true,
        custom: true,
    },
    marigold: {
        label: 'Marigold Engage',
        text: 'Streamline analyzing your Marigold Engage (formerly Selligent) data in SEINō.',
        child: 'account',
        svg: '/img/integrations/marigold.svg',
        new: true,
        custom: true,
    },
    salesforce: {
        label: 'Salesforce',
        text: 'Simplify reporting on your Salesforce Marketing Cloud email data in SEINō.',
        child: 'account',
        svg: '/img/integrations/salesforce.svg',
        new: false,
        custom: false,
    },
    smtpeter: {
        label: 'SMTPeter',
        text: 'Get all your transactional e-mail data from SMTPeter in SEINō.',
        child: 'account',
        svg: '/img/integrations/smtpeter.svg',
        new: false,
        custom: true,
    },
    webpower: {
        label: 'Spotler MailPro',
        text: 'Collect MailPro (formerly Webpower) data in SEINō for easy e-mail analytics.',
        child: 'campaign',
        svg: '/img/integrations/spotler_mailpro.svg',
        new: true,
        custom: true,
    },
    google_bigquery: {
        label: 'Google BigQuery',
        text: 'Export your aggregated e-mail campaign data to your own BigQuery environment.',
        child: 'dataset',
        svg: '/img/integrations/google-bigquery-icon.svg',
        new: false,
        custom: true,
    },
    google_cloud_storage: {
        label: 'Google Cloud Storage',
        text: 'Exchange files with SEINō via a Google Cloud Storage bucket.',
        child: 'bucket',
        svg: '/img/integrations/google-cloud-storage-icon.svg',
        new: false,
        custom: true,
    },
};

export const isValidIntegration = (
    integrationId: PropertyKey
): integrationId is IntegrationType => {
    return integrationId in SupportedIntegrations;
};
