import {
    Autocomplete as MUIAutocomplete,
    AutocompleteProps as MUIAutocompleteProps,
    Box,
    CircularProgress,
} from '@mui/material';
import React, { useCallback, useState, useEffect } from 'react';
import { Input } from './WidgetConfigForm.styled';
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
    DragEndEvent,
} from '@dnd-kit/core';
import { SortableChip } from './SortableChip';
import {
    arrayMove,
    horizontalListSortingStrategy,
    SortableContext,
    sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';
import { mergeAndMaintainSequence } from './merge-and-maintain-sequence.ts';

interface AutocompleteProps
    extends Omit<
        MUIAutocompleteProps<string, true, false, false>,
        'renderInput' | 'onChange'
    > {
    label: string;
    loading?: boolean;
    sortOnSelect?: boolean;
    onChange: (newValue: string[]) => void;
    minNumberOfValues?: number;
}

export const AutocompleteSortable = ({
    label,
    onChange,
    loading,
    minNumberOfValues,
    sortOnSelect,
    options,
    value,
    getOptionLabel,
    ...rest
}: AutocompleteProps): JSX.Element => {
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const [items, setItems] = useState<string[]>([]);

    useEffect(() => setItems(value || []), [value]);

    const handleDragEnd = useCallback(
        (event: DragEndEvent) => {
            const { active, over } = event;

            if (!active.id || !over?.id) return;

            setItems(items => {
                const oldIndex = items.indexOf(active.id as string);
                const newIndex = items.indexOf(over.id as string);

                const updatedItems = arrayMove(items, oldIndex, newIndex);
                onChange(updatedItems);
                return updatedItems;
            });
        },
        [setItems, onChange]
    );

    const removeItem = useCallback(
        (v: string) => {
            setItems(i => {
                if (!i.includes(v)) return i;
                if (minNumberOfValues && i.length === minNumberOfValues) {
                    return i;
                }

                const updatedItems = i.filter(i => i !== v);
                onChange(updatedItems);
                return updatedItems;
            });
        },
        [minNumberOfValues, onChange]
    );

    const renderTags = useCallback(() => {
        return items.map((v, i) => (
            <SortableChip
                key={`${v}-${i}`}
                value={v}
                label={getOptionLabel?.(v) || v}
                id={v}
                onDelete={item => {
                    removeItem(item);
                }}
            />
        ));
    }, [items, removeItem, getOptionLabel]);

    return (
        <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
        >
            <SortableContext
                items={items}
                strategy={horizontalListSortingStrategy}
            >
                <MUIAutocomplete
                    {...rest}
                    getOptionLabel={getOptionLabel}
                    options={options}
                    defaultValue={value}
                    value={items}
                    multiple
                    disabled={loading}
                    loading={loading}
                    onChange={(_, newValues, reason) => {
                        if (
                            minNumberOfValues &&
                            reason === 'removeOption' &&
                            newValues.length === minNumberOfValues - 1
                        ) {
                            return;
                        }

                        if (sortOnSelect && reason === 'selectOption') {
                            onChange(
                                mergeAndMaintainSequence(
                                    options,
                                    items,
                                    newValues
                                )
                            );
                        } else {
                            onChange(newValues);
                        }
                    }}
                    renderTags={renderTags}
                    renderInput={params => (
                        <Input
                            {...params}
                            variant="outlined"
                            fullWidth
                            label={label}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {params.InputProps.endAdornment}
                                        {loading ? (
                                            <Box
                                                sx={{
                                                    position: 'absolute',
                                                    right: 48,
                                                }}
                                            >
                                                <CircularProgress
                                                    color="inherit"
                                                    size={16}
                                                />
                                            </Box>
                                        ) : null}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    )}
                />
            </SortableContext>
        </DndContext>
    );
};
