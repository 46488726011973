import { useState } from 'react';
import { AvailableGoogleAnalyticsView } from '../../../../types/integration';
import { Button, Stack, Typography } from '@mui/material';
import { SelectView } from './SelectView';
import { SelectMarket } from './SelectMarket';
import { useViews } from './useViews';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { getRustAPIUrl } from '../../../../utils/api.ts';
import { LoadingButton } from '@mui/lab';
import { integrationsQueryKey } from '../../../../hooks/use-integrations.ts';

interface AddViewDialogProps {
    workspaceId: string;
    integrationId: string;
    onClose: () => void;
}

type PostBody = {
    property_id: string;
    market?: string | null;
};

export function AddViewDialog({
    onClose,
    workspaceId,
    integrationId,
}: AddViewDialogProps) {
    const [view, setView] = useState<AvailableGoogleAnalyticsView | null>(null);
    const [market, setMarket] = useState<string | null>(null);

    const queryClient = useQueryClient();
    const { mutateAsync, isLoading } = useMutation({
        mutationFn: (property: PostBody) =>
            axios.post(
                `${getRustAPIUrl()}/workspaces/${workspaceId}/integrations/google-analytics/properties`,
                property
            ),
        onSuccess: () =>
            queryClient.invalidateQueries(integrationsQueryKey(workspaceId)),
    });

    const {
        data: { availableViews },
    } = useViews(workspaceId, integrationId);

    if (availableViews.length === 0) {
        return (
            <Typography variant="body2">
                You currently do not have access to any GA accounts.
            </Typography>
        );
    }

    return (
        <Stack spacing={2} sx={{ paddingTop: 1 }}>
            <SelectView
                value={view}
                workspaceId={workspaceId}
                integrationId={integrationId}
                onChange={view => setView(view)}
            />

            <SelectMarket
                value={market}
                onChange={market => setMarket(market)}
            />

            <Stack direction="row" justifyContent="right" spacing={1}>
                <Button onClick={() => onClose()}>Cancel</Button>
                <LoadingButton
                    variant="contained"
                    color="primary"
                    disabled={!view}
                    loading={isLoading}
                    onClick={async () => {
                        if (!view) {
                            return;
                        }

                        await mutateAsync({
                            property_id: view.id,
                            market,
                        });

                        onClose();
                    }}
                >
                    Save
                </LoadingButton>
            </Stack>
        </Stack>
    );
}
