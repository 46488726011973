import { ulid } from 'ulid';
import { thisQuarter } from '../../components/DateRangePicker/dateHelpers.ts';
import { BinaryFilter } from '../../queries/filters.ts';

export type Target<T = Date> = {
    id: string;
    workspaceId: string;
    dataset: string;
    metric: string;
    title: string;
    visualization: 'bar' | 'line';
    filters: BinaryFilter[];
    dateStart: T;
    dateEnd: T;
    target: number;
    createdAt?: number;
    modifiedAt?: number;
};

export const newTarget = (workspaceId: string): Target => ({
    id: ulid(),
    workspaceId,
    title: '',
    metric: '',
    visualization: 'line',
    filters: [],
    dataset: 'campaign_metrics_by_send_date',
    target: 0,
    dateStart: thisQuarter().start,
    dateEnd: thisQuarter().end,
});
