import { BinaryFilter } from './filters';
import { match } from 'ts-pattern';
import {
    addDays,
    addMonths,
    addQuarters,
    addWeeks,
    differenceInCalendarDays,
} from 'date-fns';
import { DateRange } from '../components/DateRangePicker/reducer.tsx';

export type Granularity = 'day' | 'week' | 'month' | 'quarter';

export interface TimeSeriesQuery {
    dateRange: Interval;
    granularity?: Granularity;
    dimension?: string;
    metrics: string[];
    filters: BinaryFilter[];
}

export const calculateGranularity = (range: number | DateRange) => {
    const lengthOfRangeInDays =
        typeof range === 'number'
            ? range
            : differenceInCalendarDays(range.end, range.start);

    switch (true) {
        case lengthOfRangeInDays <= 70:
            return 'day';

        case lengthOfRangeInDays <= 365:
            return 'week';

        default:
            return 'month';
    }
};

export const addPeriods = (
    date: Date,
    granularity: Granularity,
    periods: number
) =>
    match(granularity)
        .with('day', () => addDays(date, periods))
        .with('week', () => addWeeks(date, periods))
        .with('month', () => addMonths(date, periods))
        .with('quarter', () => addQuarters(date, periods))
        .exhaustive();
