import { useContext } from 'react';
import { DashboardContext } from './DashboardProvider.tsx';

export function useDashboardContext() {
    const dashboard = useContext(DashboardContext);

    if (!dashboard) {
        throw new Error('invalid dashboard found.');
    }

    return dashboard;
}
