import * as React from 'react';
import { useCallback, useState } from 'react';
import { ActionButton } from '../../../components/Button/ActionButton';
import { useActiveWorkspace } from '../../../providers/WorkspaceProvider.hooks';
import { FormDialog } from '../../../components/Dialog/FormDialog.tsx';
import {
    Stack,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
// @ts-expect-error todo
import { zodResolver } from '@hookform/resolvers/zod';
import { useInviteUser } from './useInviteUser.ts';

const schema = z.object({
    email: z.string().email(),
    role: z.enum(['member', 'admin']),
});

const defaultUser = {
    email: '',
    role: 'member',
};
export type Invite = typeof defaultUser;

export const InviteUserDialog = () => {
    const [openDialog, setOpenDialog] = useState(false);
    const { workspace } = useActiveWorkspace();
    const handleClick = useCallback(() => setOpenDialog(true), []);
    const handleClose = useCallback(() => setOpenDialog(false), []);

    const { reset, register, control, handleSubmit } = useForm({
        resolver: zodResolver(schema),
        defaultValues: defaultUser,
    });

    const { mutateAsync: inviteUser, isLoading } = useInviteUser();

    if (workspace.role !== 'admin') {
        return;
    }

    const onSubmit = handleSubmit(async data => {
        await inviteUser(data);
        reset();
        handleClose();
    });

    return (
        <>
            <ActionButton onClick={handleClick}>Invite</ActionButton>
            <FormDialog
                open={openDialog}
                handleClose={handleClose}
                handleConfirm={onSubmit}
                disableRestoreFocus={true}
                title={`Invite to ${workspace.displayName}`}
                content={
                    <Stack gap={1}>
                        <TextField
                            label="Email address"
                            placeholder="email@example.com"
                            autoFocus={true}
                            {...register('email')}
                        />
                        <Controller
                            render={({ field }) => (
                                <ToggleButtonGroup
                                    size="small"
                                    exclusive={true}
                                    {...field}
                                >
                                    <ToggleButton value="member">
                                        Member
                                    </ToggleButton>
                                    <ToggleButton value="admin">
                                        Admin
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            )}
                            name="role"
                            control={control}
                        />
                    </Stack>
                }
                confirmButtonText="Invite"
                confirmButtonProps={{ loading: isLoading }}
            />
        </>
    );
};
