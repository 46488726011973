import { BaseRow } from '../../Table/types.ts';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { CollectionForm } from '../Collections.tsx';
import { IconButton } from '@mui/material';
import { DeleteForever } from '@mui/icons-material';

export const RemoveBaselineColumn = ({ row }: { row: BaseRow }) => {
    const { control, watch } = useFormContext<CollectionForm>();
    const fields = watch('targets');

    const found = (fields || [])
        .map((field, index) => ({ ...field, index }))
        .find(field => field.id === row.id);

    const { remove } = useFieldArray({
        control,
        name: 'targets',
    });

    return (
        <IconButton
            size="extraSmall"
            color="light"
            onClick={event => {
                event.preventDefault();
                if (found) {
                    remove(found.index);
                }
            }}
        >
            <DeleteForever fontSize="small" />
        </IconButton>
    );
};
