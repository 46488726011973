import { Chip, Stack, Typography } from '@mui/material';
import {
    IntegrationType,
    SupportedIntegrations,
} from '../../../types/workspace-management';
import { ActionButton } from '../../../components/Button/ActionButton.tsx';
import { Link } from 'react-router-dom';

import { useActiveWorkspaceId } from '../../../providers/useActiveWorkspaceId.ts';

interface IntegrationCardProps {
    integrationType: IntegrationType;
}

export const IntegrationCard = ({ integrationType }: IntegrationCardProps) => {
    const workspaceId = useActiveWorkspaceId();
    const integrationInfo = SupportedIntegrations[integrationType];

    return (
        <Stack spacing={1} sx={{ height: '100%' }}>
            <Stack direction="row" alignItems="center" spacing={1}>
                <img height={32} width={32} src={integrationInfo.svg} alt="" />
                <Typography variant="body2" fontWeight="bold">
                    {integrationInfo.label}
                </Typography>
                {integrationInfo.new && (
                    <Chip
                        color="success"
                        label="new"
                        size="small"
                        variant="outlined"
                    />
                )}
            </Stack>
            <Typography variant="caption" sx={{ minHeight: '40px' }}>
                {integrationInfo.text}
            </Typography>
            <ActionButton
                style={{ alignSelf: 'flex-start' }}
                component={Link}
                to={`/workspaces/${workspaceId}/settings/integrations/${integrationType}`}
            >
                Connect
            </ActionButton>
        </Stack>
    );
};
