import {
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { useIntegrations } from '../../../../hooks/use-integrations';
import {
    CanopyDeployIntegration,
    isDeployteq,
} from '../../../../types/integration';
import { match, P } from 'ts-pattern';
import { sortBy } from 'lodash';
import { getImageForCountryCode } from '../../../../components/Form/RuleBuilder/utils';
import { AddBrand } from './AddBrand';
import { ConfigureBrandDialog } from './ConfigureBrand/ConfigureBrandDialog';
import { RemoveBrand } from './RemoveBrand';
import { BucketDetails } from '../BucketDetails.tsx';

interface BrandListProps {
    workspaceId: string;
}

export const BrandList = ({ workspaceId }: BrandListProps) => {
    const { data: integrations } = useIntegrations();
    const brands = sortBy(integrations?.filter(isDeployteq) || [], 'name');

    return (
        <Stack spacing={2}>
            <Typography variant="h6">1. Connect brands</Typography>
            <Typography variant="body2">
                Import data from all your Deployteq brands right into SEINō.
            </Typography>

            <Stack direction="row" justifyContent="right">
                <AddBrand workspaceId={workspaceId} />
            </Stack>

            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" width={40}>
                                Markets
                            </TableCell>
                            <TableCell>Brand name</TableCell>
                            <TableCell align="center">Status</TableCell>
                            <TableCell width={20} align="right"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {brands.map(brand => (
                            <BrandRow
                                key={brand.id}
                                workspaceId={workspaceId}
                                integration={brand}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Typography variant="h6">
                2. Set up database growth tracking
            </Typography>
            <Typography variant="body2">
                Create a campaign to make nightly exports of profiles in
                Deployteq you want to track, and export them to our Google Cloud
                Storage Bucket.
            </Typography>
            <Typography align="right">
                <BucketDetails path="deployteq_profiles/" />
            </Typography>
        </Stack>
    );
};

type BrandRowProps = {
    workspaceId: string;
    integration: CanopyDeployIntegration;
};
const BrandRow = ({ workspaceId, integration }: BrandRowProps) => {
    const marketMapping = integration.field_mappings?.find(
        m => m.name === 'market'
    );

    return (
        <TableRow>
            <TableCell align="center">
                {match(marketMapping)
                    .with(P.nullish, { type: 'null' }, () => 'None')
                    .with({ type: 'fixed_value' }, ({ value }) => (
                        <Typography variant="body2">
                            {getImageForCountryCode(value)}
                        </Typography>
                    ))
                    .with({ type: 'condition' }, ({ rules }) => rules.length)
                    .otherwise(() => 'Custom')}
            </TableCell>
            <TableCell>{integration.name}</TableCell>
            <TableCell align="center">Active</TableCell>
            <TableCell align="right">
                <Stack direction="row">
                    <ConfigureBrandDialog
                        workspaceId={workspaceId}
                        integration={integration}
                    />

                    <RemoveBrand
                        workspaceId={workspaceId}
                        integrationId={integration.id}
                    />
                </Stack>
            </TableCell>
        </TableRow>
    );
};
