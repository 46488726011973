import { useContext } from 'react';
import { useActiveUser } from './CurrentUserProvider.hooks';
import { useActiveWorkspace } from './WorkspaceProvider.hooks.ts';
import { useViews } from '../hooks/use-explorer-views.ts';
import { usePermissions } from '../hooks/use-permissions.ts';
import { ExplorerViewContext } from './ExplorerViewProvider.tsx';

export function useActiveExplorerView() {
    const context = useContext(ExplorerViewContext);

    if (context === undefined) {
        throw new Error(
            'useActiveExplorer must be used within a ExplorerProvider'
        );
    }

    return context;
}

export function useView(viewId: string) {
    const { memberId } = useActiveUser();
    const { workspaceId } = useActiveWorkspace();
    const { data: views, isLoading } = useViews();
    const { data: permissions, isLoading: isLoadingPermission } =
        usePermissions(workspaceId, viewId, 'explorer_view');

    const sharedWith =
        permissions?.filter(
            p => p.objectId === viewId && p.subjectId !== memberId
        ) ?? [];
    const view = views?.find(v => v.id === viewId);

    return {
        view,
        accessLevel: view?.accessLevel,
        sharedWith: sharedWith,
        isLoading: isLoading || isLoadingPermission,
    };
}
