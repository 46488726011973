import { Box, styled } from '@mui/material';

type PerformanceCardGridProps = { minItemWidth: number };

export const PerformanceCardGrid = styled(Box, {
    shouldForwardProp: prop => prop !== 'minItemWidth',
})<PerformanceCardGridProps>(
    ({ minItemWidth }) => `
    /**
    * User input values.
    */
    --grid-layout-gap: 8px;
    --grid-column-count: 12;
    --grid-item--min-width: ${minItemWidth}px;

    /**
    * Calculated values.
    */
    --gap-count: calc(var(--grid-column-count) - 1);
    --total-gap-width: calc(var(--gap-count) * var(--grid-layout-gap));
    --grid-item--max-width: calc(
        (100% - var(--total-gap-width)) / var(--grid-column-count)
    );

    display: grid;
    grid-template-columns: repeat(
        auto-fit,
        minmax(
            max(var(--grid-item--min-width), var(--grid-item--max-width)),
            1fr
        )
    );
    grid-gap: var(--grid-layout-gap);
    overflow: hidden;
`
);
