import {
    AdvancedFilterModel,
    ColDef,
    ColGroupDef,
    FilterModel,
    GridApi,
    IMultiFilterModel,
    IServerSideDatasource,
    IServerSideGetRowsParams,
    ScalarAdvancedFilterModelType,
    TextAdvancedFilterModelType,
} from '@ag-grid-community/core';
import { AsyncDuckDBConnection } from '@duckdb/duckdb-wasm';
import {
    DataModelField,
    DataModelSchema,
    isGroupedDimension,
    isMetric,
    isNonGroupedDimension,
} from '../../types/datamodel/schema.ts';
import { DuckDataset } from '../../hooks/use-duck-dataset.ts';
import {
    findField,
    getFieldsFromSchema,
    includeSourceMetrics,
} from '../../queries/schema.ts';
import { sqlForMetric } from '../../queries/metrics-sql.ts';
import {
    BinaryOperator,
    Filter,
    LogicalAndFilter,
} from '../../queries/filters.ts';
import { match, P } from 'ts-pattern';
import { OrderBy, QueryBuilder } from '../../queries/query-builder.ts';
import { ColumnDefContext, createColumnDef } from './columnDefs.ts';
import { formatDimension } from '../../utils/dimension-format.ts';
import * as Sentry from '@sentry/react';
import { captureException } from '@sentry/react';

type DataSourceOptions = {
    duckdb: AsyncDuckDBConnection;
    dataset: DuckDataset;
    dateRange: Interval;
    columnDefContext: ColumnDefContext;
};

type ArrowTable = Awaited<ReturnType<AsyncDuckDBConnection['query']>>;

export type DataSourceState =
    | { state: 'success'; totalCount: number }
    | { state: 'loading' }
    | { state: 'error' };

export class DataSource extends EventTarget implements IServerSideDatasource {
    private duckdb: AsyncDuckDBConnection;
    private dataset: DuckDataset;
    private dateRange: Interval;
    private columnDefContext: ColumnDefContext;
    private defaultQueryTags: Record<string, string>;

    private fields: DataModelField[] = [];

    private previous: { sql: string; result: ArrowTable } | undefined =
        undefined;

    constructor({
        duckdb,
        dataset,
        dateRange,
        columnDefContext,
    }: DataSourceOptions) {
        super();

        this.duckdb = duckdb;
        this.dataset = dataset;
        this.dateRange = dateRange;
        this.columnDefContext = columnDefContext;

        this.defaultQueryTags = {
            workspace_id: this.dataset.workspaceId,
            dataset: this.dataset.id,
            component: 'grid',
        };
    }

    async getRows(params: IServerSideGetRowsParams) {
        const {
            api,
            request: {
                startRow,
                endRow,
                rowGroupCols,
                pivotCols,
                valueCols,
                groupKeys,
                sortModel,
                filterModel,
                pivotMode,
            },
        } = params;

        this.fields = this.visibleFields(api);

        const groupedDimensions =
            rowGroupCols.length > groupKeys.length
                ? [rowGroupCols[groupKeys.length].id]
                : this.fields.filter(isGroupedDimension).map(f => f.id);

        const nonGroupedDimensions =
            rowGroupCols.length > groupKeys.length
                ? []
                : this.fields.filter(isNonGroupedDimension).map(f => f.id);

        const metrics = this.fields.filter(isMetric).map(f => f.id);

        const [where, having] = createFilters(this.dataset.schema, filterModel);

        for (let i = 0; i < groupKeys.length; i++) {
            where.push({
                member: rowGroupCols[i].id,
                operator: 'equals',
                values: groupKeys[i] === '' ? ['', null] : [groupKeys[i]],
            });
        }

        const pivotFields = this.fieldsForIds(pivotCols.map(c => c.id));
        const valueFields = this.fieldsForIds(valueCols.map(c => c.id));

        const { selectPivotBaseFields, selectPivotFields, pivotColDefs } =
            await this.pivot(pivotFields, valueFields, where);

        if (
            this.fields.length === 0 &&
            rowGroupCols.length === 0 &&
            selectPivotBaseFields.length === 0
        ) {
            api.setPivotResultColumns(null);
            params.success({
                rowData: [],
                rowCount: 0,
            });
            this.updateState({
                state: 'success',
                totalCount: 0,
            });

            return;
        }

        const baseQuery = this.baseQueryBuilder()
            .selectFields(this.fields)
            .selectFields(this.fieldsForIds(rowGroupCols.map(c => c.id)))
            .select(selectPivotBaseFields)
            .where(where)
            .having(having)
            .toSql();

        const sql = new QueryBuilder(this.dataset, {})
            .with({ sql: baseQuery, name: 'base' })
            .from('base')
            .selectFields(this.fieldsForIds(groupedDimensions))
            .selectFields(this.fieldsForIds(nonGroupedDimensions))
            .selectFields(this.fieldsForIds(metrics))
            .select(selectPivotFields)
            .select(['count(*) as _child_count'])
            .selectId()
            .orderBy(
                sortModel
                    .flatMap((s): OrderBy[] =>
                        s.colId === 'ag-Grid-AutoColumn'
                            ? rowGroupCols.map(c => [c.id, s.sort])
                            : [[s.colId, s.sort]]
                    )
                    .filter(
                        s =>
                            groupedDimensions.includes(s[0]) ||
                            nonGroupedDimensions.includes(s[0]) ||
                            metrics.includes(s[0])
                    )
            )
            .toSql();

        const handleResult = (result: ArrowTable) => {
            if (groupKeys.length === 0) {
                api.setPivotResultColumns(
                    pivotMode && pivotColDefs ? pivotColDefs : null
                );
            }

            params.success({
                rowData: result.slice(startRow, endRow).toArray(),
                rowCount: result.numRows,
            });
        };

        if (this.previous?.result && this.previous.sql === sql) {
            handleResult(this.previous.result);
            return;
        }

        this.query(sql)
            .then(result => {
                this.previous = { sql, result };

                handleResult(result);
            })
            .catch(err => {
                captureException(err);
                console.error(err);
                params.fail();
            });

        if (groupKeys.length === 0) {
            this.updateState({ state: 'loading' });

            const totalsQueryBuilder = new QueryBuilder(this.dataset, {})
                .with({ sql: baseQuery, name: 'base' })
                .from('base')
                .select(['count(*) as _total_count']);

            if (selectPivotFields.length > 0) {
                totalsQueryBuilder.select(selectPivotFields);
            } else {
                totalsQueryBuilder.selectFields(this.fields.filter(isMetric));
            }

            api.setGridOption('pinnedBottomRowData', []);
            this.query(totalsQueryBuilder.toSql())
                .then(result => {
                    api.setGridOption('pinnedBottomRowData', result.toArray());
                    this.updateState({
                        state: 'success',
                        totalCount: result.toArray()[0]['_total_count'],
                    });
                })
                .catch(err => {
                    console.error(err);
                    this.updateState({ state: 'error' });
                });
        }
    }

    syncFields(api: GridApi) {
        const fieldIds = (fields: DataModelField[]) =>
            fields
                .map(f => f.id)
                .toSorted()
                .join(',');

        const before = fieldIds(this.fields);
        const after = fieldIds(this.visibleFields(api));

        if (before !== after) {
            api.getServerSideGroupLevelState().forEach(x =>
                api.refreshServerSide({
                    route: x.route,
                })
            );
        }
    }

    async distinctValues(
        field: DataModelField
    ): Promise<(string | number | null)[]> {
        const sql = `select distinct ${field.id} as value from ${this.dataset.tableId} order by value nulls first`;

        return (await this.query(sql)).toArray().map((row: any) => row.value);
    }

    private visibleFields(api: GridApi) {
        const pivotMode = api.isPivotMode();

        return this.fieldsForIds(
            (api.getColumns() || [])
                .filter(c =>
                    pivotMode
                        ? c.isRowGroupActive() || c.isValueActive()
                        : c.isVisible()
                )
                .map(c => c.getColId())
        );
    }

    private fieldsForIds(ids: string[]): DataModelField[] {
        return getFieldsFromSchema(this.dataset.schema, ids);
    }

    baseQueryBuilder() {
        return new QueryBuilder(this.dataset, { intermediateTable: true })
            .selectFields(this.fields)
            .whereDateRange(this.dateRange);
    }

    private updateState(state: DataSourceState) {
        this.dispatchEvent(new CustomEvent('updated', { detail: state }));
    }

    private async pivot(
        pivotFields: DataModelField[],
        valueFields: DataModelField[],
        where: Filter[]
    ) {
        const selectPivotBaseFields: string[] = [];
        const selectPivotFields: string[] = [];
        const pivotColDefs: ColGroupDef[] = [];

        if (pivotFields.length === 0 || valueFields.length === 0) {
            return {
                selectPivotBaseFields,
                selectPivotFields,
            };
        }

        const sql = new QueryBuilder(this.dataset, {})
            .whereDateRange(this.dateRange)
            .selectFields(pivotFields)
            .where(where)
            .orderBy(pivotFields.map(f => [f.id, 'asc']))
            .toSql();

        const result = await this.query(sql);
        const findSourceMetric = includeSourceMetrics(
            findField(this.dataset.schema)
        );

        for (const row of result) {
            const groupIdParts = [];
            let level: (ColGroupDef | ColDef)[] = pivotColDefs;
            const filter: LogicalAndFilter = { and: [] };

            for (let i = 0; i < pivotFields.length; i++) {
                const value = row[pivotFields[i].id];
                const valueFmt = formatDimension(value, pivotFields[i].format);

                filter.and.push({
                    member: pivotFields[i].id,
                    operator: 'equals',
                    values: value === '' ? ['', null] : [value],
                });

                const headerName = valueFmt === '' ? '(Blanks)' : valueFmt;
                groupIdParts.push(headerName);
                const groupId = groupIdParts.join(';');

                let groupDef: ColGroupDef | undefined = level.find(
                    (def): def is ColGroupDef =>
                        'groupId' in def && def.groupId === groupId
                );

                if (!groupDef) {
                    groupDef = {
                        groupId,
                        headerName,
                        children: [],
                    };
                    level.push(groupDef);
                }

                level = groupDef.children;
            }

            for (const valueField of valueFields) {
                if (selectPivotFields.length > 99) {
                    break;
                }

                const pivotColId: string = [
                    ...groupIdParts,
                    valueField.name,
                ].join(';');

                const colDef = createColumnDef(this.columnDefContext)(
                    valueField
                );

                colDef.colId = pivotColId;
                colDef.field = pivotColId;
                colDef.filter = null;
                colDef.floatingFilter = false;
                level.push(colDef);

                const includedMetrics: string[] = [];
                for (const sourceMetric of findSourceMetric(valueField)) {
                    if (includedMetrics.includes(sourceMetric.id)) {
                        continue;
                    }
                    includedMetrics.push(sourceMetric.id);

                    selectPivotBaseFields.push(
                        `${sqlForMetric(sourceMetric, {
                            intermediateTable: true,
                            pivotFilter: filter,
                        })} as "${pivotColId}_${sourceMetric.id}"`
                    );
                }

                selectPivotFields.push(
                    `${sqlForMetric(valueField, {
                        sourcePrefix: `${pivotColId}_`,
                    })}::double as "${pivotColId}"`
                );
            }
        }

        return { selectPivotBaseFields, selectPivotFields, pivotColDefs };
    }

    async query(sql: string, tags?: Record<string, string>) {
        return await query(
            this.duckdb,
            sql,
            tags ? { ...this.defaultQueryTags, ...tags } : this.defaultQueryTags
        );
    }
}

function query(
    db: AsyncDuckDBConnection,
    sql: string,
    tags?: Record<string, string>
) {
    const start = performance.now();
    const result = db.query(sql);

    Sentry.metrics.timing(
        'duckdb_query',
        performance.now() - start,
        'millisecond',
        { tags }
    );

    return result;
}

function createFilters(
    schema: DataModelSchema,
    filters: FilterModel | AdvancedFilterModel | null
): [Filter[], Filter[]] {
    if (filters?.filterType) {
        throw new Error('advanced filter model not supported');
    }

    const where: Filter[] = [];
    const having: Filter[] = [];

    if (filters === null) {
        return [where, having];
    }

    const ff = findField(schema);

    for (const [colId, filter] of Object.entries(filters)) {
        const field = ff(colId);
        if (!field) {
            continue;
        }

        if (isMetric(field)) {
            const havingFilter = createFilter(
                sqlForMetric(field, { percentRange: '100.0' }),
                filter,
                true
            );
            if (havingFilter) {
                having.push(havingFilter);
            }
        } else if (isGroupedDimension(field)) {
            const whereFilter = createFilter(colId, filter);
            if (whereFilter) {
                where.push(whereFilter);
            }
        } else if (isNonGroupedDimension(field)) {
            const havingFilter = createFilter(
                `any_value(${colId})`,
                filter,
                true
            );
            if (havingFilter) {
                having.push(havingFilter);
            }
        }
    }

    return [where, having];
}

function createFilter(
    colId: string,
    filter:
        | AdvancedFilterModel
        | IMultiFilterModel
        | { filterType: 'set'; values: string[] }
        | null,
    aggregate?: boolean
): Filter | undefined {
    return match(filter)
        .with(P.nullish, () => undefined)
        .with(
            { operator: P.union('AND', 'OR'), conditions: P.array() },
            ({ operator, conditions }) => {
                const filters =
                    conditions
                        ?.map(f => createFilter(colId, f, aggregate))
                        .filter((f): f is Filter => f !== undefined) || [];

                if (filters.length === 0) {
                    return undefined;
                }

                if (filters.length === 1) {
                    return filters[0];
                }

                return operator === 'AND' ? { and: filters } : { or: filters };
            }
        )
        .with({ filterType: 'text' }, ({ type, filter }) => ({
            values: filter ? [filter] : [],
            operator: textFilterTypeToOperator(type),
            member: colId,
            aggregate,
        }))
        .with({ filterType: 'set' }, ({ values }) => {
            return {
                // todo: handle empty set more elegantly
                values:
                    values.length === 0
                        ? ['$h84bCkZ']
                        : values.flatMap(v => {
                              return v === null ? [null, ''] : [v];
                          }),
                operator: 'equals' as const,
                member: colId,
                aggregate,
            };
        })
        .with({ filterType: 'boolean' }, () => undefined)
        .with({ filterType: 'number' }, filterModel => {
            const filter = filterModel.filter;
            const type = filterModel.type;

            // Workaround for filterTo missing from AG grid types.
            const filterTo =
                'filterTo' in filterModel &&
                typeof filterModel.filterTo === 'number'
                    ? filterModel.filterTo
                    : undefined;

            return {
                values:
                    filter === undefined
                        ? []
                        : filterTo === undefined
                        ? [filter]
                        : [filter, filterTo],
                operator: numberFilterTypeToOperator(type),
                member: colId,
                aggregate,
            };
        })
        .with({ filterType: 'date' }, () => undefined)
        .with({ filterType: 'join' }, () => undefined)
        .with({ filterType: 'object' }, () => undefined)
        .with({ filterType: 'dateString' }, () => undefined)
        .with(
            { filterModels: P.union(P.array(), P.nullish) },
            ({ filterModels }) => {
                const filters =
                    filterModels
                        ?.map(f => createFilter(colId, f, aggregate))
                        .filter((f): f is Filter => f !== undefined) || [];

                if (filters.length === 0) {
                    return undefined;
                }

                if (filters.length === 1) {
                    return filters[0];
                }

                return { and: filters };
            }
        )
        .exhaustive();
}

const textFilterTypeToOperator = (
    type: TextAdvancedFilterModelType
): BinaryOperator =>
    match(type)
        .with('equals', () => 'equals' as const)
        .with('notEqual', () => 'notEquals' as const)
        .with('contains', () => 'contains' as const)
        .with('notContains', () => 'notContains' as const)
        .with('startsWith', () => 'startsWith' as const)
        .with('endsWith', () => 'endsWith' as const)
        .with('blank', () => 'notSet' as const)
        .with('notBlank', () => 'set' as const)
        .exhaustive();

const numberFilterTypeToOperator = (
    type: ScalarAdvancedFilterModelType | 'inRange'
): BinaryOperator =>
    match(type)
        .with('equals', () => 'equals' as const)
        .with('notEqual', () => 'notEquals' as const)
        .with('greaterThan', () => 'gt' as const)
        .with('greaterThanOrEqual', () => 'gte' as const)
        .with('lessThan', () => 'lt' as const)
        .with('lessThanOrEqual', () => 'lte' as const)
        .with('blank', () => 'notSet' as const)
        .with('notBlank', () => 'set' as const)
        .with('inRange', () => 'inRange' as const)
        .exhaustive();
