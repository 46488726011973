import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { getRustAPIUrl } from '../../../utils/api.ts';
import { useNotify } from '../../../hooks/use-notify.tsx';
import { useActiveWorkspaceId } from '../../../providers/useActiveWorkspaceId.ts';

type UpdateSettingsArgs = {
    name: string;
    locale: string;
};

export const useUpdateSettings = () => {
    const workspaceId = useActiveWorkspaceId();
    const queryClient = useQueryClient();
    const notify = useNotify();

    return useMutation({
        mutationFn: ({ name, locale }: UpdateSettingsArgs) =>
            axios.put(`${getRustAPIUrl()}/workspaces/${workspaceId}/settings`, {
                name,
                locale,
            }),
        onSuccess: () => {
            notify('Updated workspace settings', 'success');

            return queryClient.invalidateQueries(['fetch-initial-data']);
        },
        onError: () => notify('Failed to update workspace settings', 'error'),
    });
};
