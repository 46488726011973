import {
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    Radio,
} from '@mui/material';
import * as React from 'react';
import { ReactNode, useState } from 'react';
import { Target } from '../../../types/targets';
import { useCalculatorListItemData } from './useCalculatorListItemData.ts';

export type CalculatorListItem = {
    id: string;
    label: string | ReactNode;
    value?: number;
};

const PrecedingPeriodListItem = ({
    id,
    value,
    selected,
    disabled,
    primaryLabel,
    secondaryLabel,
    onSelect,
}: {
    id: string;
    value?: number;
    disabled: boolean;
    selected: boolean;
    primaryLabel: ReactNode;
    secondaryLabel?: ReactNode;
    onSelect: (value: number) => void;
}) => (
    <ListItem disablePadding>
        <ListItemButton
            disabled={disabled}
            onClick={() => value && onSelect(value)}
            selected={selected}
        >
            <ListItemIcon>
                <Radio
                    disabled={disabled}
                    checked={selected}
                    value={id}
                    onChange={() => value && onSelect(value)}
                    inputProps={{ 'aria-label': id }}
                />
            </ListItemIcon>
            <ListItemText primary={primaryLabel} secondary={secondaryLabel} />
        </ListItemButton>
    </ListItem>
);

export const PrecedingPeriodList = ({
    title,
    target,
    onSelect,
    formatValue,
}: {
    title: string;
    target: Target;
    onSelect: (value: number) => void;
    formatValue?: (value: number) => ReactNode;
}) => {
    const [selected, setSelected] = useState<string>();
    const items = useCalculatorListItemData(target);

    return (
        <List
            disablePadding
            dense
            subheader={
                <ListSubheader sx={{ paddingLeft: 0 }}>{title}</ListSubheader>
            }
        >
            {items.map(item => (
                <PrecedingPeriodListItem
                    key={item.id}
                    id={item.id}
                    primaryLabel={item.label}
                    secondaryLabel={
                        item?.value
                            ? formatValue?.(item.value) || item.value
                            : 'Not enough data available for this period'
                    }
                    disabled={!item?.value}
                    selected={selected === item.id}
                    value={item.value}
                    onSelect={() => {
                        if (item.value) {
                            setSelected(item.id);
                            onSelect(item.value);
                        }
                    }}
                />
            ))}
        </List>
    );
};
