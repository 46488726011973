import { ConfigForm, Label } from '../../components/Widget/ConfigForm';
import { FilterBuilder } from '../../components/FilterBuilder/FilterBuilder';
import { Loader } from '../../components/Loader/Loader';
import { AlertRule } from '../../types/alerting';
import {
    Button,
    InputAdornment,
    Skeleton,
    Stack,
    Switch,
    TextField,
    Tooltip,
} from '@mui/material';
import { DatasetSelect } from '../../components/DatasetSelect/DatasetSelect';
import {
    datasetQueryKey,
    useDatasetSchema,
} from '../../hooks/use-dataset-schema';
import Typography from '@mui/material/Typography';
import { AlertRecipientsInput } from './AlertRecipientsInput';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useAlertRuleMutation } from '../../hooks/use-alert-rule-mutation';
import { LoadingButton } from '@mui/lab';
import {
    BinaryFilter,
    binaryOperators,
    isSetOperator,
} from '../../queries/filters';
import { useAlertPreview } from '../../hooks/use-alert-preview';
import { HealthChartSkeleton } from '../../components/HealthChart/HealthChartSkeleton';
import { HealthChart } from '../../components/HealthChart/HealthChart';
import * as React from 'react';
import { AlertAsText } from './AlertAsText';
import { z, ZodTypeAny } from 'zod';
// @ts-expect-error todo
import { zodResolver } from '@hookform/resolvers/zod';
import { match } from 'ts-pattern';
import { SchemaFieldSelect } from '../../components/SchemaFieldSelect/SchemaFieldSelect.tsx';
import { fieldExists } from '../../queries/schema.ts';
import { QueryClient, useQueryClient } from '@tanstack/react-query';
import {
    DataModelField,
    DataModelSchema,
    isGroupedDimension,
} from '../../types/datamodel/schema.ts';

import { useActiveWorkspaceId } from '../../providers/useActiveWorkspaceId.ts';

interface AlertFormProps {
    alert: AlertRule;
    onSubmit: () => void;
}

const filterSchema = z
    .object({
        member: z.string(),
        operator: z.enum(binaryOperators),
        values: z.string().min(1).array(),
    })
    .refine(
        ({ operator, values }) => {
            return isSetOperator(operator) || values.length > 0;
        },
        () => ({ path: ['values'] })
    );

const ruleConfigSchema = (queryClient: QueryClient, workspaceId: string) =>
    z
        .object({
            dataset: z.string(),
            filters: filterSchema.array(),
            conditions: filterSchema.array().min(1),
            dimension: z.string().optional().nullable(),
            duration: z.number().positive().max(365),
        })
        .superRefine(({ dataset, dimension, filters, conditions }, ctx) => {
            const schema: DataModelSchema | undefined =
                queryClient.getQueryData(datasetQueryKey(workspaceId, dataset));

            const isExistingField =
                (schema && fieldExists(schema)) || (() => false);

            if (dimension && !isExistingField(dimension)) {
                ctx.addIssue({
                    path: ['dimension'],
                    code: z.ZodIssueCode.custom,
                });
            }

            const validateFilterRow =
                (name: string) =>
                ({ member }: BinaryFilter, index: number) => {
                    if (!isExistingField(member)) {
                        ctx.addIssue({
                            path: [name, index, 'member'],
                            code: z.ZodIssueCode.custom,
                        });
                    }
                };

            filters.forEach(validateFilterRow('filters'));
            conditions.forEach(validateFilterRow('conditions'));
        });

const ruleSchema = (configSchema: ZodTypeAny) =>
    z.intersection(
        configSchema,
        z.object({
            id: z.string(),
            workspaceId: z.string(),
            title: z.string().nonempty('Please provide a title'),
            recipients: z.string().email().array(),
            webhookUrl: z.string().url().nullable().or(z.literal('')),
            message: z.string(),
            enabled: z.boolean(),
        })
    );

const allowedDatasets = [
    'campaign_metrics_by_send_date',
    'campaign_metrics_by_event_date',
    'list_members',
    'website_metrics_ga_api',
    'workspaces',
];

const canGroupBy = (f: DataModelField) =>
    isGroupedDimension(f) &&
    // Temporary filter until unnested datasets are fully released.
    f.category !== 'UTM';

export const AlertForm = ({ alert, onSubmit }: AlertFormProps) => {
    const configSchema = ruleConfigSchema(
        useQueryClient(),
        useActiveWorkspaceId()
    );

    const {
        control,
        register,
        reset,
        handleSubmit,
        formState: { isDirty, errors },
    } = useForm({
        defaultValues: alert,
        resolver: zodResolver(ruleSchema(configSchema)),
    });

    const { dataset, conditions, filters, dimension, duration } = useWatch({
        control,
    });
    const { data: schema } = useDatasetSchema(dataset);

    const { update, isUpdating } = useAlertRuleMutation();

    const configResult = configSchema.safeParse({
        dataset,
        filters,
        conditions,
        dimension,
        duration,
    });

    const submitHandler = handleSubmit(async (value: AlertRule) =>
        update(value).then(() => {
            reset(value);
            onSubmit();
        })
    );

    const preview = useAlertPreview(
        configResult.success ? configResult.data : undefined,
        configResult.success
    );

    if (!schema) {
        return <Loader />;
    }

    return (
        <form onSubmit={submitHandler}>
            <ConfigForm>
                <Label>Title</Label>
                <TextField
                    {...register('title')}
                    error={!!errors.title}
                    helperText={errors.title?.message}
                />

                <Label>Dataset</Label>
                <Controller
                    control={control}
                    name="dataset"
                    render={({ field: { ref, ...props } }) => (
                        <DatasetSelect
                            allowedDatasets={allowedDatasets}
                            {...props}
                        />
                    )}
                />

                <Label>Conditions</Label>
                <Controller
                    control={control}
                    name="conditions"
                    render={({ field: { value, onChange }, fieldState }) => {
                        return (
                            <FilterBuilder
                                dataset={dataset!}
                                schema={schema}
                                filters={value}
                                onChange={onChange}
                                fieldType="metrics"
                                addButtonText="Add condition"
                                fieldState={fieldState}
                            />
                        );
                    }}
                />

                <Label>Filters</Label>
                <Controller
                    control={control}
                    name="filters"
                    render={({ field: { value, onChange }, fieldState }) => (
                        <FilterBuilder
                            dataset={dataset!}
                            schema={schema}
                            filters={value}
                            onChange={onChange}
                            fieldType="dimensions"
                            addButtonText="Add filter"
                            fieldState={fieldState}
                        />
                    )}
                />

                <Label>Group by</Label>
                <Controller
                    control={control}
                    name="dimension"
                    render={({
                        field: { value, onChange },
                        fieldState: { error },
                    }) => (
                        <SchemaFieldSelect
                            fullWidth
                            schema={schema}
                            error={error}
                            value={value ?? null}
                            onChange={onChange}
                            includeNone={true}
                            fieldFilter={canGroupBy}
                        />
                    )}
                />

                <Label>Interval</Label>
                <Stack direction="row" alignItems="center" spacing={1}>
                    <Tooltip
                        placement="right"
                        PopperProps={{
                            modifiers: [
                                {
                                    name: 'offset',
                                    options: {
                                        offset: [0, 50],
                                    },
                                },
                            ],
                        }}
                        title="The interval within which your condition(s) will be evaluated using a weighted average of the values over the number of days you choose."
                    >
                        <TextField
                            {...register('duration', { valueAsNumber: true })}
                            sx={{ width: 80 }}
                            inputMode="numeric"
                            type="number"
                            inputProps={{ min: 1, max: 365 }}
                        />
                    </Tooltip>
                    <Typography>
                        {alert.duration === 1 ? 'day' : 'days'}
                    </Typography>
                </Stack>

                <Label>Preview</Label>
                <Stack spacing={2}>
                    {configResult.success ? (
                        <AlertAsText
                            alert={configResult.data}
                            variant="body2"
                        />
                    ) : null}

                    {match(preview)
                        .with(
                            { status: 'success' },
                            ({ data: { results, conditions, dimension } }) => {
                                const alerts = results.filter(
                                    x => x.result
                                ).length;

                                return (
                                    <Stack spacing={1}>
                                        <HealthChart
                                            results={results}
                                            conditions={conditions}
                                            dimension={dimension}
                                        />
                                        <Typography fontSize="smaller">
                                            This alert would have triggered{' '}
                                            {alerts === 1
                                                ? '1 time '
                                                : `${alerts} times `}
                                            in the past 30 days.
                                        </Typography>
                                    </Stack>
                                );
                            }
                        )
                        .with({ status: 'loading', isFetching: true }, () => (
                            <Stack spacing={1}>
                                <HealthChartSkeleton />
                                <Skeleton width={345} />
                            </Stack>
                        ))
                        .otherwise(() => (
                            <Stack>
                                <HealthChartSkeleton animation={false} />
                                <Typography fontSize="small">
                                    {conditions?.length === 0
                                        ? 'Please provide at least one condition to show a preview of this alert.'
                                        : 'Ensure all conditions are configured properly to show a preview of this alert.'}
                                </Typography>
                            </Stack>
                        ))}
                </Stack>

                <Label>Recipients</Label>
                <Controller
                    name={'recipients'}
                    control={control}
                    render={({
                        field: { ref, onChange, ...props },
                        fieldState: { error },
                    }) => (
                        <AlertRecipientsInput
                            options={[]}
                            inputProps={{
                                error: !!error,
                                helperText:
                                    error &&
                                    'Please ensure every entry is a valid e-mail address.',
                            }}
                            onChange={(e, value) => onChange(value)}
                            {...props}
                        />
                    )}
                />

                <Label>Message</Label>
                <TextField multiline={true} rows={3} {...register('message')} />

                <Label>Webhook</Label>
                <TextField
                    {...register('webhookUrl')}
                    error={!!errors.webhookUrl}
                    helperText={errors.webhookUrl?.message}
                    placeholder="https://"
                    inputMode="url"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <Typography variant="caption">
                                    (optional)
                                </Typography>
                            </InputAdornment>
                        ),
                    }}
                />

                <Label>Enabled</Label>
                <Controller
                    name="enabled"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <Switch
                            checked={value}
                            onChange={onChange}
                            color="success"
                        />
                    )}
                />

                <Label></Label>
                <Stack direction="row" spacing={1}>
                    <LoadingButton
                        type="submit"
                        size="small"
                        loading={isUpdating}
                    >
                        Save
                    </LoadingButton>
                    {isDirty && (
                        <Button
                            hidden={!isDirty}
                            type="reset"
                            size="small"
                            onClick={e => {
                                e.preventDefault();
                                reset(alert);
                            }}
                        >
                            Reset
                        </Button>
                    )}
                </Stack>
            </ConfigForm>
        </form>
    );
};
