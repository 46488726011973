import { LegacyDialog } from '../../../../components/Dialog/LegacyDialog.tsx';
import { useState } from 'react';
import { AddViewDialog } from './AddViewDialog';
import { Add } from '@mui/icons-material';
import { ActionButton } from '../../../../components/Button/ActionButton';
import { useViews } from './useViews';
import { Tooltip } from '@mui/material';

interface AddViewProps {
    workspaceId: string;
    integrationId: string;
}

export const AddView = ({ workspaceId, integrationId }: AddViewProps) => {
    const [openDialog, setOpenDialog] = useState(false);

    const handleClick = () => setOpenDialog(true);
    const handleClose = () => setOpenDialog(false);

    const {
        data: { availableViews, selectedViews },
    } = useViews(workspaceId, integrationId);

    const tooltipTitle =
        availableViews.length > 0
            ? null
            : selectedViews.length > 0
            ? 'All views have been selected for import.'
            : 'No access to GA views has been granted yet.';

    return (
        <>
            <Tooltip title={tooltipTitle}>
                {/* Wrap in <span> to trigger tooltip in disabled state. */}
                <span>
                    <ActionButton
                        startIcon={<Add />}
                        size="small"
                        disabled={availableViews.length === 0}
                        onClick={handleClick}
                    >
                        Add GA view
                    </ActionButton>
                </span>
            </Tooltip>
            <LegacyDialog
                open={openDialog}
                handleClose={handleClose}
                title="Add Google Analytics view"
                content={
                    <AddViewDialog
                        onClose={handleClose}
                        workspaceId={workspaceId}
                        integrationId={integrationId}
                    />
                }
            />
        </>
    );
};
