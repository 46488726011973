// TODO make env variable
import { generateAbsoluteUrl } from './url-generator';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { UseMutationOptions } from '@tanstack/react-query';

export const getAPIUrl = (): string => {
    const apiUrlFromEnv = import.meta.env.VITE_API_URL || '';

    if (apiUrlFromEnv) {
        return apiUrlFromEnv;
    }

    const API_BASE = 'api.seino';
    const protocol = process.env.NODE_ENV === 'development' ? 'http' : 'https';
    const extension =
        process.env.NODE_ENV === 'development' ? 'localhost' : 'ai';

    return `${protocol}://${API_BASE}.${extension}`;
};

export const getRustAPIUrl = (): string => {
    const rustApiUrl = import.meta.env.VITE_API_RS_URL || '';

    if (rustApiUrl) {
        return rustApiUrl;
    }

    const API_BASE = 'api.seino';
    const protocol = process.env.NODE_ENV === 'development' ? 'http' : 'https';
    const extension =
        process.env.NODE_ENV === 'development' ? 'localhost' : 'ai';

    return `${protocol}://${API_BASE}.${extension}:7777`;
};

export const getRedirectUri = (): string =>
    generateAbsoluteUrl('/config/data-sources/');

export type MutationOptions<T> = Pick<
    UseMutationOptions<unknown, Error | ValidationError, T>,
    'onSuccess' | 'onError'
>;

interface ValidationErrorResponse extends AxiosResponse {
    status: 400;
    data: {
        detail: string;
        status: string;
        title: string;
    };
}

interface SimpleErrorResponse extends AxiosResponse {
    status: 400;
    data: {
        error: string;
    };
}

export interface SimpleError extends AxiosError {
    response: SimpleErrorResponse;
}

export interface ValidationError extends AxiosError {
    response: ValidationErrorResponse;
}

function axiosResponseIsValidationResponse(
    response: AxiosResponse
): response is ValidationErrorResponse {
    return (
        response.status === 400 &&
        typeof response.data?.status === 'number' &&
        typeof response.data?.detail === 'string' &&
        typeof response.data?.title === 'string'
    );
}

export function isValidationError(error: unknown): error is ValidationError {
    return Boolean(
        axios.isAxiosError(error) &&
            error.response &&
            axiosResponseIsValidationResponse(error.response)
    );
}

function axiosResponseIsSimpleErrorResponse(
    response: AxiosResponse
): response is SimpleErrorResponse {
    return response.status === 400 && typeof response.data.error === 'string';
}

export function isSimpleApiError(error: unknown): error is SimpleError {
    return Boolean(
        axios.isAxiosError(error) &&
            error.response &&
            axiosResponseIsSimpleErrorResponse(error.response)
    );
}
