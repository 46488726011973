import { DimensionFormat } from '../types/datamodel/schema.ts';
import { match } from 'ts-pattern';
import { memoize } from 'lodash';

type Formatter = (value: unknown) => string;
const toString = (value: unknown) =>
    value || value === 0
        ? typeof value === 'string'
            ? value
            : `${value}`
        : '';

const getFormatterInternal = (format: DimensionFormat | unknown): Formatter => {
    return match(format)
        .with(
            'boolean',
            () => (x: unknown) =>
                typeof x === 'boolean' ? (x ? 'Yes' : 'No') : ''
        )
        .with('weekday', () => formatWeekday)
        .with('month', () => formatMonth)
        .otherwise(() => toString);
};

export const getDimensionFormatter = memoize(getFormatterInternal);
export const formatDimension = (
    value: unknown,
    format: DimensionFormat | unknown
) => getDimensionFormatter(format)(value);

const formatWeekday = (input: unknown) => {
    switch (toString(input)) {
        case '0':
            return 'Sun';
        case '1':
            return 'Mon';
        case '2':
            return 'Tue';
        case '3':
            return 'Wed';
        case '4':
            return 'Thu';
        case '5':
            return 'Fri';
        case '6':
            return 'Sat';
        default:
            return '';
    }
};

const formatMonth = (input: unknown) => {
    switch (toString(input)) {
        case '1':
            return 'Jan';
        case '2':
            return 'Feb';
        case '3':
            return 'Mar';
        case '4':
            return 'Apr';
        case '5':
            return 'May';
        case '6':
            return 'Jun';
        case '7':
            return 'Jul';
        case '8':
            return 'Aug';
        case '9':
            return 'Sep';
        case '10':
            return 'Oct';
        case '11':
            return 'Nov';
        case '12':
            return 'Dec';
        default:
            return '';
    }
};
