import * as React from 'react';
import { useEffect } from 'react';
import { useDashboardFromRoute } from '../../../pages/Dashboard/useDashboardFromRoute.ts';
import { isPermitted } from '../../../types/permission/permission.ts';
import { Toggle } from '../../Toggle/Toggle.tsx';

export const EditModeSwitch = () => {
    const { editMode, setEditMode, selectedDashboard } =
        useDashboardFromRoute();

    const canWrite = isPermitted(
        selectedDashboard?.accessLevel || 'none',
        'write'
    );

    useEffect(() => {
        if (!canWrite && editMode) {
            setEditMode(false);
        }
    }, [canWrite, editMode, setEditMode]);

    if (!canWrite) {
        return null;
    }

    return (
        <Toggle
            label="Edit mode"
            checked={editMode}
            onChange={checked => setEditMode(checked)}
        />
    );
};
