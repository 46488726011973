import { useFlags } from 'launchdarkly-react-client-sdk';
import { matchPath, useLocation, useParams } from 'react-router-dom';

export const useNavigationItems = () => {
    const { workspaceId } = useParams<{ workspaceId: string | undefined }>();
    const { seinoEngaged } = useFlags();
    const { pathname } = useLocation();

    const menuItems: {
        key: string;
        label: string;
        href: string;
        beta?: boolean;
        pattern: string | string[];
    }[] = [
        {
            key: 'dashboard',
            label: 'Dashboard',
            href: workspaceId
                ? `/workspaces/${workspaceId}/dashboard`
                : '/dashboard',
            pattern: [
                '/workspaces/:workspaceId/dashboard/:dashboardId',
                '/workspaces/:workspaceId/dashboard',
                '/dashboard',
            ],
        },
        {
            key: 'explorer',
            label: 'Explorer',
            href: workspaceId
                ? `/workspaces/${workspaceId}/explorer`
                : '/explorer',
            pattern: '/workspaces/:workspaceId/explorer/*',
        },
        {
            key: 'alerting',
            label: 'Alerting',
            href: workspaceId
                ? `/workspaces/${workspaceId}/alerting`
                : '/alerting',
            pattern: `/workspaces/:workspaceId/alerting`,
        },
    ];

    if (seinoEngaged) {
        menuItems.push({
            key: 'engagement',
            label: 'Engagement',
            href: workspaceId
                ? `/workspaces/${workspaceId}/engaged`
                : '/engaged',
            beta: true,
            pattern: '/workspaces/:workspaceId/engaged',
        });
    }

    const activeTab = menuItems.findIndex(({ pattern }) => {
        const patterns = Array.isArray(pattern) ? pattern : [pattern];
        return patterns.some(p => matchPath(p, pathname));
    });

    return {
        menuItems,
        activeTab,
    };
};
