import {
    Alert,
    Autocomplete,
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { z } from 'zod';
import { useOnboarding } from './useSignup';
import { Navigate, useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { useActiveUser } from '../../providers/CurrentUserProvider.hooks';
import { listOfEsps } from './listOfEsps';
// @ts-expect-error todo
import { zodResolver } from '@hookform/resolvers/zod';
import * as React from 'react';
import { isSimpleApiError } from '../../utils/api.ts';
import { SignOutLink } from '../../components/Navigate/SignOutLink.tsx';

const validationSchema = z
    .object({
        name: z.string().min(1),
        company: z
            .string()
            .min(1, 'The company name should contain at least 1 character')
            .max(30, "We currently don't support more than 30 characters."),
        esp: z
            .array(z.enum(listOfEsps))
            .min(1, 'To proceed, choose at least one automation tool'),
        newsletter_opt_in: z.boolean(),
    })
    .required();

type OnboardingData = z.infer<typeof validationSchema>;

export const Welcome = () => {
    const navigate = useNavigate();

    const {
        control,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm<OnboardingData>({
        resolver: zodResolver(validationSchema),
        defaultValues: {
            name: '',
            company: '',
            esp: [],
            newsletter_opt_in: true,
        },
    });
    const { mutate, isLoading } = useOnboarding({
        onError: error => {
            setError('root.serverError', {
                message: isSimpleApiError(error)
                    ? error.response.data.error
                    : 'Failed to signup. Please contact hello@seino.ai.',
            });
        },
    });

    const { onboardingRequired } = useActiveUser();

    if (!onboardingRequired) {
        return <Navigate to="/dashboard" />;
    }

    return (
        <Grid container maxWidth="md">
            <Grid
                item
                xs={6}
                sx={theme => ({
                    color: theme.palette.secondary.main,
                    backgroundColor: theme.palette.primary.dark,
                    position: 'relative',
                })}
            >
                <Box
                    position="absolute"
                    top="50%"
                    left="50%"
                    sx={{ transform: 'translate(-50%, -50%)' }}
                >
                    <Typography
                        variant="h4"
                        fontWeight="bold"
                        color="secondary.contrastText"
                    >
                        SEINō
                    </Typography>
                    <Typography
                        variant="body2"
                        fontWeight="bold"
                        color="secondary.contrastText"
                    >
                        Get email marketing insights in seconds, instead of days
                    </Typography>
                </Box>
            </Grid>
            <Grid
                item
                xs={6}
                p={4}
                paddingY={2}
                sx={theme => ({
                    backgroundColor: theme.palette.background.paper,
                })}
            >
                <Box display="flex" justifyContent="flex-end" mb={2}>
                    <SignOutLink />
                </Box>

                <Typography color="primary.main" variant="h2">
                    Welcome!
                </Typography>

                <Typography color="primary.main" variant="body2">
                    You're about to create your workspace in SEINō.
                </Typography>

                {errors.root?.serverError && (
                    <Alert severity="error" sx={{ marginBlock: 2 }}>
                        <Typography paragraph variant="body2">
                            {errors.root?.serverError?.message}
                        </Typography>
                    </Alert>
                )}

                <Stack
                    component="form"
                    autoComplete="off"
                    onSubmit={handleSubmit(data => {
                        mutate(data, {
                            onSuccess: () => navigate(`/dashboard`),
                        });
                    })}
                >
                    <Controller
                        render={({ field, fieldState: { error } }) => (
                            <FormControl margin="normal">
                                <TextField
                                    helperText={
                                        error?.message ||
                                        'We like a personal approach, so we want to know how to call you.'
                                    }
                                    size="small"
                                    error={!!error}
                                    {...field}
                                    fullWidth
                                    label="Name"
                                    variant="outlined"
                                />
                            </FormControl>
                        )}
                        name="name"
                        control={control}
                    />

                    <Controller
                        render={({ field, fieldState: { error } }) => (
                            <FormControl margin="normal">
                                <TextField
                                    helperText={
                                        error?.message ||
                                        'We will create a personalized workspace based on your company name.'
                                    }
                                    size="small"
                                    error={!!error}
                                    {...field}
                                    fullWidth
                                    label="Company"
                                    variant="outlined"
                                />
                            </FormControl>
                        )}
                        name="company"
                        control={control}
                    />

                    <Controller
                        render={({
                            field: { ref, onChange, ...props },
                            fieldState: { error },
                        }) => (
                            <FormControl margin="normal">
                                <Autocomplete
                                    multiple
                                    freeSolo
                                    size="small"
                                    options={listOfEsps}
                                    renderInput={params => (
                                        <TextField
                                            variant="outlined"
                                            label="Marketing Automation Software"
                                            placeholder="Marketing Automation Software"
                                            {...params}
                                        />
                                    )}
                                    onChange={(e, value) => onChange(value)}
                                    {...props}
                                />
                                <FormHelperText error={!!error?.message}>
                                    {error?.message ||
                                        'We would like to know which automation software you use for a tailored journey.'}
                                </FormHelperText>
                            </FormControl>
                        )}
                        name="esp"
                        control={control}
                    />

                    <Controller
                        name="newsletter_opt_in"
                        control={control}
                        render={({ field }) => (
                            <FormControl margin="dense">
                                <FormControlLabel
                                    labelPlacement="end"
                                    slotProps={{
                                        typography: {
                                            variant: 'mediumBody',
                                        },
                                    }}
                                    control={
                                        <Checkbox
                                            defaultChecked
                                            {...field}
                                            size="small"
                                            aria-label="Newsletter opt-in"
                                        />
                                    }
                                    label="Sign up for our
                                    newsletter to stay in the know"
                                />
                            </FormControl>
                        )}
                    />

                    <Box mt={2}>
                        <LoadingButton loading={isLoading} type="submit">
                            Continue
                        </LoadingButton>
                    </Box>
                </Stack>
            </Grid>
        </Grid>
    );
};
