import { SetStateAction } from 'react';

export const resolveNewState = <T>(
    prevValue: T,
    newValue: SetStateAction<T>
): T => {
    if (newValue instanceof Function) {
        return newValue(prevValue);
    }

    return newValue;
};
