import { createContext, PropsWithChildren, useContext } from 'react';
import { amsterdamLocale, Localization } from '../types/datamodel/localization';
import { WorkspaceContext } from './WorkspaceProvider';

export const LocaleContext = createContext<Localization>(amsterdamLocale);

export function LocaleProvider({ children }: PropsWithChildren) {
    const workspace = useContext(WorkspaceContext);
    const locale = workspace?.locale || amsterdamLocale;

    return (
        <LocaleContext.Provider value={locale}>
            {children}
        </LocaleContext.Provider>
    );
}
