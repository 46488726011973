import { Stack } from '@mui/material';
import * as React from 'react';
import { HealthChartItem } from './HealthChartItem';
import { HealthChartLegend } from './Legend';
import { DataModelField } from '../../types/datamodel/schema';

interface HealthChartProps {
    conditions: Condition[];
    dimension: Dimension | null;
    results: HealthChartDatum[];
}

export type Condition = Pick<DataModelField, 'name' | 'format'>;
export type Dimension = Pick<DataModelField, 'id' | 'name' | 'format'>;

export interface HealthChartDatum {
    rangeStart: string;
    rangeEnd: string;
    result: boolean | null;
    checkValues: (number | null)[];
    checkResults: (boolean | null)[];
    details: HealthChartDatumDetails[];
}

interface HealthChartDatumDetails {
    dimension: null | string;
    result: boolean | null;
    metrics: (number | null)[];
    results: (boolean | null)[];
}

export const HealthChart = ({
    conditions,
    dimension,
    results,
}: HealthChartProps) => {
    return (
        <Stack spacing={0.5} width="fit-content">
            <Stack direction="row" spacing={0.25}>
                {results.map(datum => (
                    <HealthChartItem
                        key={datum.rangeEnd}
                        data={datum}
                        conditions={conditions}
                        dimension={dimension}
                    />
                ))}
            </Stack>
            <HealthChartLegend />
        </Stack>
    );
};
