import axios from 'axios';
import { DashboardConfig } from '../../types/dashboard';
import { getRustAPIUrl } from '../../utils/api';

export const fetchDashboardConfig = async (workspaceId: string) => {
    const result = await axios.get<{ dashboards: DashboardConfig[] }>(
        `${getRustAPIUrl()}/workspaces/${workspaceId}/dashboards`
    );

    return result.data.dashboards;
};

export const updateDashboardConfig = async (
    workspaceId: string,
    dashboardConfig: DashboardConfig,
    id: string
) => {
    return axios.put<DashboardConfig>(
        `${getRustAPIUrl()}/workspaces/${workspaceId}/dashboards/${id}`,
        {
            ...dashboardConfig,
        }
    );
};

export const deleteDashboardConfig = (workspaceId: string, id: string) =>
    axios.delete(
        `${getRustAPIUrl()}/workspaces/${workspaceId}/dashboards/${id}`
    );
