import { Navigate, Route, Routes } from 'react-router-dom';
import { ExplorerLayout } from '../layouts/ExplorerLayout';
import { DashboardLandingPage } from '../pages/Dashboard/DashboardLandingPage.tsx';
import { NoWorkspaces } from '../pages/Info/NoWorkspaces';
import { Logout } from '../pages/Logout/Logout';
import { NotFound } from '../pages/NotFound/NotFound.tsx';
import { Profile } from '../pages/Profile/Profile';
import { EngagementPage } from '../pages/Engaged/EngagementPage';
import { ErrorElement } from '../components/ErrorPage/ErrorElement';
import { WorkspaceProvider } from '../providers/WorkspaceProvider';
import { Callback } from '../pages/Login/Callback';
import { AuthenticatedRoute } from '../components/AuthenticationGuard/AuthenticationGuard';
import { NavigateWithWorkspaceId } from '../components/AuthenticationGuard/NavigateWithWorkspaceId';
import { ExplorerView } from '../pages/Explorer/ExplorerView';
import { ExplorerViewProvider } from '../providers/ExplorerViewProvider';
import { useTrackVisit } from '../hooks/use-track-visit';
import { AlertingPage } from '../pages/Alerting/AlertingPage';
import { PublicErrorPage } from '../components/ErrorPage/PublicErrorPage';
import { ChangelogPage } from '../pages/Changelog/ChangelogPage';
import { Welcome } from '../pages/Onboarding/Welcome';
import { OnboardingLayout } from '../layouts/OnboardingLayout';
import { RewriteToExplorerView } from '../pages/Explorer/RewriteToExplorerView';
import { WebviewRedirect } from '../pages/Explorer/WebviewRedirect.tsx';
import { Dashboard } from '../pages/Dashboard/Dashboard.tsx';
import { TargetsPage } from '../pages/Targets/TargetsPage.tsx';
import * as Sentry from '@sentry/react';
import { Settings } from '../pages/WorkspaceSettings/Settings.tsx';
import { Members } from '../pages/WorkspaceSettings/Members/Members.tsx';
import { Integrations } from '../pages/WorkspaceSettings/Integrations/Integrations.tsx';
import { IntegrationPage } from '../pages/WorkspaceSettings/Integrations/IntegrationPage.tsx';
import { General } from '../pages/WorkspaceSettings/General.tsx';
import { Agency } from '../pages/WorkspaceSettings/Agency/Agency.tsx';
import { AllWorkspaces } from '../pages/Admin/AllWorkspaces.tsx';
import { ProtectedRoute, RouteCondition } from './ProtectedRoute.tsx';
import { AdminSettings } from '../pages/Admin/AdminSettings.tsx';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
const seinoAdminCondition: RouteCondition[] = [
    { condition: user => !!user.isSeinoAdmin },
];

export const SeinoRouter = () => {
    useTrackVisit();
    return (
        <SentryRoutes>
            <Route path="/" errorElement={<ErrorElement />}>
                <Route path="/login" element={<Callback />} />
                <Route path="/logout" element={<Logout />} />
                <Route path="/callback" element={<Callback />} />
                <Route path="/error" element={<PublicErrorPage />} />
                <Route element={<AuthenticatedRoute />}>
                    <Route path="/welcome" element={<OnboardingLayout />}>
                        <Route index element={<Welcome />} />
                    </Route>
                    <Route
                        path="/profile"
                        element={<ExplorerLayout hideWorkspaceSelector />}
                    >
                        <Route index element={<Profile />} />
                    </Route>
                    <Route path="/workspaces">
                        <Route
                            path=":workspaceId/datasets/campaigns/:campaignId/webview"
                            element={<WebviewRedirect />}
                        />
                        <Route
                            path=":workspaceId"
                            element={
                                <WorkspaceProvider>
                                    <ExplorerLayout />
                                </WorkspaceProvider>
                            }
                        >
                            <Route
                                path="admin"
                                element={
                                    <ProtectedRoute
                                        conditions={seinoAdminCondition}
                                    >
                                        <AdminSettings />
                                    </ProtectedRoute>
                                }
                            >
                                <Route
                                    path="workspaces"
                                    element={<AllWorkspaces />}
                                ></Route>
                            </Route>
                            <Route path="settings" element={<Settings />}>
                                <Route
                                    index
                                    element={
                                        <NavigateWithWorkspaceId to="settings/general" />
                                    }
                                />
                                <Route path="agency" element={<Agency />} />
                                <Route path="general" element={<General />} />
                                <Route path="members" element={<Members />} />
                                <Route
                                    path="integrations"
                                    element={<Integrations />}
                                ></Route>
                                <Route
                                    path="integrations/:integrationType"
                                    element={<IntegrationPage />}
                                />
                            </Route>
                            <Route
                                path="dashboard"
                                element={<DashboardLandingPage />}
                            />
                            <Route
                                path="dashboard/:dashboardId"
                                element={<Dashboard />}
                            />
                            <Route
                                path="reporting/dashboard"
                                element={<Navigate replace to="/dashboard" />}
                            />
                            <Route path="alerting" element={<AlertingPage />} />
                            <Route
                                path="alerting/:alertId"
                                element={<AlertingPage />}
                            />
                            <Route
                                path="explorer"
                                element={<RewriteToExplorerView />}
                            />
                            <Route
                                path="explorer/:viewId"
                                element={
                                    <ExplorerViewProvider>
                                        <ExplorerView />
                                    </ExplorerViewProvider>
                                }
                            >
                                <Route
                                    path="settings/:tabId"
                                    element={<ExplorerView />}
                                >
                                    <Route
                                        path=":detailId"
                                        element={
                                            <ExplorerViewProvider>
                                                <ExplorerView />
                                            </ExplorerViewProvider>
                                        }
                                    />
                                </Route>
                            </Route>
                            <Route path="targets" element={<TargetsPage />} />
                            <Route
                                path="engaged"
                                element={<EngagementPage />}
                            />
                        </Route>
                    </Route>
                    <Route element={<ExplorerLayout hideWorkspaceSelector />}>
                        <Route
                            path="/engaged"
                            element={<NavigateWithWorkspaceId to="engaged" />}
                        />
                        <Route path="/changes" element={<ChangelogPage />} />
                        <Route
                            path="/reporting/dashboard"
                            element={
                                <NavigateWithWorkspaceId to="reporting/dashboard" />
                            }
                        />
                        <Route
                            path="/dashboard"
                            element={<NavigateWithWorkspaceId to="dashboard" />}
                        />
                        <Route
                            path="/explorer"
                            element={<NavigateWithWorkspaceId to="explorer" />}
                        />
                        <Route
                            path="/alerting"
                            element={<NavigateWithWorkspaceId to="alerting" />}
                        />
                        <Route
                            path="/"
                            element={<NavigateWithWorkspaceId to="dashboard" />}
                        />
                    </Route>
                    <Route
                        path="/info/no-workspaces"
                        element={<NoWorkspaces />}
                    />
                </Route>
            </Route>
            <Route path="*" element={<NotFound />} />
        </SentryRoutes>
    );
};
