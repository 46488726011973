import { createContext, PropsWithChildren } from 'react';
import { useParams } from 'react-router-dom';
import { ExplorerView } from '../components/ExplorerGrid/grid/utils/gridView';
import { useSetLastExplorerViewId } from '../hooks/use-last-explorer-view-id';
import { useActiveWorkspace } from './WorkspaceProvider.hooks';
import { AccessLevel, Permission } from '../types/permission/permission';
import { useView } from './ExplorerViewProvider.hooks.ts';
import { ViewNotFound } from '../pages/NotFound/ViewNotFound.tsx';

export const ExplorerViewContext = createContext<
    | {
          viewId: string;
          view: ExplorerView;
          accessLevel: AccessLevel;
          sharedWith: Permission[];
      }
    | undefined
>(undefined);

export function ExplorerViewProvider({ children }: PropsWithChildren) {
    const { workspaceId } = useActiveWorkspace();
    const { viewId } = useParams<{ viewId: string | undefined }>();
    useSetLastExplorerViewId(workspaceId, viewId);

    if (!viewId) {
        throw new Error('viewId was not found in url.');
    }

    const { view, isLoading, accessLevel, sharedWith } = useView(viewId);

    if (isLoading) {
        return null;
    }

    if (!view || !accessLevel) {
        return <ViewNotFound />;
    }

    return (
        <WithValue
            children={children}
            view={view}
            sharedWith={sharedWith ?? []}
            accessLevel={accessLevel}
        />
    );
}

const WithValue = ({
    view,
    accessLevel,
    sharedWith,
    children,
}: PropsWithChildren<{
    view: ExplorerView;
    accessLevel: AccessLevel;
    sharedWith: Permission[];
}>) => {
    const value = { viewId: view.id, view, accessLevel, sharedWith };

    return (
        <ExplorerViewContext.Provider value={value}>
            {children}
        </ExplorerViewContext.Provider>
    );
};
