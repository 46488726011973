import { GridConfig } from '../components/ExplorerGrid/grid/utils/gridView';
import { ColumnState } from '@ag-grid-community/core';
import { WidgetConfig } from '../types/dashboard';

const duckifyName = (name: string | undefined) =>
    name?.replace(/^Campaigns./, '');

function normalizer() {
    return (nameInput: string | undefined) => {
        const name = duckifyName(nameInput);

        if (!name) {
            return name;
        }

        if (name.startsWith('metric_goal_')) {
            return name.substring(12);
        }

        return fieldMap[name] || name;
    };
}

export const normalizeConfig = (config: WidgetConfig): WidgetConfig => {
    const normalize = normalizer();

    const dataset =
        config.type === 'subscriber_growth'
            ? 'list_members'
            : 'campaign_metrics_by_send_date';

    const isAlreadyNormalized =
        config.metrics.every(m => m === normalize(m)) &&
        (config.filters || []).every(f => f.member === normalize(f.member)) &&
        (config.dimensions || []).every(d => d === normalize(d)) &&
        config.dataset &&
        config.dataset !== 'campaigns';

    // Prevent re-render due to new object creation if object is already normalized.
    return isAlreadyNormalized
        ? config
        : {
              ...config,
              dataset,
              metrics: config.metrics.map(normalize) as string[],
              filters:
                  config.filters?.map(f => ({
                      ...f,
                      member: normalize(f.member),
                  })) || [],
              dimensions: (config.dimensions?.map(normalize) || []) as string[],
          };
};

const upgradeViewColumn = (column: ColumnState): ColumnState => {
    const colId =
        fieldMap[column.colId] ||
        (column.colId.startsWith('metric_goal_')
            ? column.colId.substring(12)
            : column.colId);

    return {
        ...column,
        colId,
    };
};

const upgradeFieldMap = <T>(data: Record<string, T> | null | undefined) => {
    return Object.fromEntries(
        Object.entries(data || {}).map(([key, value]) => [
            fieldMap[key] || key,
            value,
        ])
    );
};

export const upgradeGridConfig = (config: GridConfig): GridConfig => {
    if (config.dataset !== undefined && config.dataset !== 'campaigns') {
        return config;
    }

    return {
        ...config,
        dataset: 'campaign_metrics_by_send_date',
        columns: config.columns?.map(upgradeViewColumn) || [],
        kpiData: upgradeFieldMap(config.kpiData),
        filterModel: upgradeFieldMap(config.filterModel),
    };
};

const fieldMap: Record<string, string> = {
    broadcast_campaign_type: 'broadcast_type',
    broadcast_campaign_id: 'campaign_id',
    broadcast_campaign_name: 'campaign_name',
    broadcast_mailing_name: 'content_name',
    broadcast_mailing_type: 'mailing_type',
    broadcast_mailing_market: 'market',
    broadcast_mailing_subject: 'subject',
    broadcast_mailing_audience: 'audience',
    broadcast_mailing_send_date: 'send_date',
    broadcast_mailing_send_date_day: 'send_date_day',
    broadcast_mailing_send_date_week: 'send_date_week',
    broadcast_mailing_send_date_month: 'send_date_month',
    broadcast_mailing_send_date_year: 'send_date_year',
    broadcast_mailing_send_date_year_week: 'send_date_year_week',
    metric_email_sent_all_sum: 'sent_all',
    metric_email_sent_unique_sum: 'sent_unique',
    metric_email_open_unique_sum: 'open',
    metric_email_open_unique_rate: 'open_rate',
    metric_email_clicks_unique_sum: 'clicks_unique',
    metric_email_clicks_unique_rate: 'clicks_unique_rate',
    metric_email_clicks_all_sum: 'clicks_all',
    metric_email_clicks_to_open_all_rate: 'cto_all',
    metric_email_clicks_to_open_unique_rate: 'cto_unique',
    metric_email_clicks_per_user_avg: 'clicks_per_user',
    metric_email_softbounce_sum: 'softbounces',
    metric_email_softbounce_rate: 'softbounces_rate',
    metric_email_hardbounce_sum: 'hardbounces',
    metric_email_hardbounce_rate: 'hardbounces_rate',
    metric_email_complaints_sum: 'complaints',
    metric_email_complaints_rate: 'complaints_rate',
    metric_email_unsubscribe_sum: 'unsubscribes',
    metric_email_unsubscribe_rate: 'unsubscribes_rate',
    metric_email_delivered: 'delivered',
    metric_email_deliverability_rate: 'deliverability_rate',
    metric_website_session_sum: 'sessions',
    metric_website_bounce_sum: 'bounces',
    metric_website_bounce_rate: 'bounce_rate',
    metric_website_session_time_avg: 'avg_session_duration',
    metric_website_transactions_sum: 'transactions',
    metric_website_conversion_rate_sends: 'cvr_sends',
    metric_website_conversion_rate_opens: 'cvr_opens',
    metric_website_conversion_rate: 'cvr_sessions',
    metric_website_revenue_sum: 'revenue',
    metric_website_revenue_per_session: 'revenue_per_session',
    metric_website_revenue_per_email_sent: 'revenue_per_email',
    metric_website_average_order_value: 'average_order_value',
};
