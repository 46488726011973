import {
    AccountCircle,
    ExitToApp,
    ManageAccounts,
    Settings,
    SupervisorAccount,
} from '@mui/icons-material';
import { useTheme } from '@mui/material';
import React, { ComponentProps } from 'react';
import { useNavigate } from 'react-router-dom';
import { useInitialData } from '../../hooks/use-initial-data.ts';
import Typography from '@mui/material/Typography';
import { MoreMenu } from '../MoreMenu/MoreMenu.tsx';
import { useActiveUser } from '../../providers/CurrentUserProvider.hooks.ts';
import { useActiveWorkspaceFromRoute } from '../../providers/useActiveWorkspaceFromRoute.ts';

function useMenuItems(showProfileLink: boolean) {
    const navigate = useNavigate();
    const workspace = useActiveWorkspaceFromRoute();
    const user = useActiveUser();

    const menuItems: ComponentProps<typeof MoreMenu>['items'] = [];

    if (showProfileLink) {
        menuItems.push({
            key: 'my-profile',
            label: 'My profile',
            icon: <ManageAccounts fontSize="small" />,
            onClick: () => navigate('/profile'),
        });
    }

    if (user.workspaces.length > 0) {
        menuItems.push({
            key: 'settings',
            label: 'Settings',
            icon: <Settings fontSize="small" />,
            onClick: () =>
                navigate(
                    `/workspaces/${
                        workspace?.id ?? user.workspaces[0].id
                    }/settings`
                ),
        });
    }

    if (user.isSeinoAdmin) {
        menuItems.push({
            key: 'admin',
            label: 'Admin',
            icon: <SupervisorAccount fontSize="small" />,
            onClick: () =>
                navigate(
                    `/workspaces/${
                        workspace?.id ?? user.workspaces[0].id
                    }/admin/workspaces`
                ),
        });
    }

    menuItems.push({
        key: 'logout',
        label: 'Logout',
        icon: <ExitToApp fontSize="small" />,
        onClick: () => navigate('/logout'),
    });

    return menuItems;
}

interface UserProps {
    showProfileLink: boolean;
}

export const User = ({ showProfileLink }: UserProps) => {
    const { data: user } = useInitialData();
    const theme = useTheme();
    const menuItems = useMenuItems(showProfileLink);

    if (!user) {
        return null;
    }

    return (
        <MoreMenu
            disableMenuListPadding
            header={
                <>
                    <Typography variant="mediumBoldBody" display="block">
                        {user.name}
                    </Typography>
                    <Typography variant="smallMediumBody">
                        {user.email}
                    </Typography>
                </>
            }
            buttonLabel={user.name}
            buttonProps={{
                sx: {
                    textTransform: 'none',
                    fontWeight: 400,
                    color: theme.tab.toolbar.contrastText,
                },
            }}
            items={menuItems}
            icon={<AccountCircle fontSize="small" />}
        />
    );
};
