import { TooltipProps } from 'recharts';
import { useLocale } from '../../providers/LocaleProvider.hooks';
import { Box, Card, Stack, Typography, useTheme } from '@mui/material';
import { formatNumber, NumberFormat } from '../../utils/number-format';
import React, { Fragment } from 'react';
import { format } from 'date-fns';
import { TARGET_CARD_WIDTH } from './TargetCard.styled.tsx';
import { getTargetConditionColor } from './get-target-condition-color.ts';
import { DataModelField } from '../../types/datamodel/schema.ts';

export type ProgressLineTooltipProps = TooltipProps<any, any> & {
    field?: DataModelField;
};

export const ProgressLineTooltip = ({
    active,
    label,
    payload,
    field,
}: ProgressLineTooltipProps) => {
    const locale = useLocale();
    const theme = useTheme();

    const dateFormat = 'EEEE do, LLLL yyyy';
    const textLabel = label instanceof Date ? format(label, dateFormat) : label;

    if (!active) {
        return null;
    }

    return (
        <Card
            elevation={0}
            sx={{
                paddingInline: 2,
                pt: '20px',
                zIndex: 10000,
                width: TARGET_CARD_WIDTH,
                height: 110,
            }}
        >
            <Stack spacing={1}>
                <Typography
                    variant="smallMediumBody"
                    textTransform="uppercase"
                    noWrap
                >
                    {textLabel}
                </Typography>

                {payload
                    ?.filter(
                        ({ dataKey }) =>
                            dataKey && !['bounds'].includes(dataKey.toString())
                    )
                    .map(condition => (
                        <Fragment key={condition.dataKey}>
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                            >
                                <Stack
                                    direction="row"
                                    spacing={1}
                                    sx={{ marginRight: 1.5 }}
                                    maxWidth={130}
                                >
                                    <Box
                                        sx={{
                                            width: 4,
                                            height: '100%',
                                            background: getTargetConditionColor(
                                                theme.palette,
                                                condition.payload
                                            ),
                                        }}
                                    ></Box>
                                    <Typography
                                        variant="smallMediumBody"
                                        noWrap
                                    >
                                        {condition.name}
                                    </Typography>
                                </Stack>

                                <Typography variant="smallBoldBody">
                                    {formatNumber(
                                        condition.payload.cumulativeValue,
                                        field?.format,
                                        locale,
                                        {
                                            mode: 'compact',
                                        }
                                    )}
                                </Typography>
                            </Stack>
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                key={`${condition.dataKey}-expected`}
                            >
                                <Stack
                                    direction="row"
                                    spacing={1}
                                    sx={{ marginRight: 1.5 }}
                                >
                                    <Box
                                        sx={{
                                            width: 4,
                                            height: '100%',
                                            background:
                                                theme.palette.primary.main,
                                        }}
                                    ></Box>
                                    <Typography variant="smallMediumBody">
                                        Expected
                                    </Typography>
                                </Stack>

                                <Typography variant="smallBoldBody">
                                    {formatNumber(
                                        condition.payload.expectedValue,
                                        field?.format ?? NumberFormat.Generic,
                                        locale,
                                        {
                                            mode: 'compact',
                                        }
                                    )}
                                </Typography>
                            </Stack>
                        </Fragment>
                    ))}
            </Stack>
        </Card>
    );
};
