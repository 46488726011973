import { ServiceAccountEmail } from './ServiceAccountEmail';
import { isGA } from '../../../../types/integration';
import { useIntegration } from '../useIntegration';

interface ConnectionInfoProps {
    integrationId: string;
}

export const ConnectionInfo = ({ integrationId }: ConnectionInfoProps) => {
    const { data: integration } = useIntegration(integrationId);

    if (
        isGA(integration) &&
        integration.connection_type === 'seino_service_account'
    ) {
        return (
            <ServiceAccountEmail email={integration.service_account || ''} />
        );
    }

    return <></>;
};
