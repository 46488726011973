import {
    AccessLevel,
    isPermitted,
    ObjectType,
} from '../../types/permission/permission.ts';
import { GrantObjectAccess } from './GrantObjectAccess.tsx';
import { PermittedSubjects } from './PermittedSubjects.tsx';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { Alert, styled } from '@mui/material';

const AccessLevelDescription = styled(Alert)``;
AccessLevelDescription.defaultProps = {
    color: 'info',
};

export function ShareObject({
    objectType,
    objectId,
    currentAccessLevel,
}: {
    objectType: ObjectType;
    objectId: string;
    currentAccessLevel: AccessLevel;
}) {
    if (!currentAccessLevel) {
        return (
            <AccessLevelDescription>
                <Typography>
                    It looks like you don't have the necessary permissions
                </Typography>
            </AccessLevelDescription>
        );
    }

    return (
        <>
            {isPermitted(currentAccessLevel, 'share') && (
                <GrantObjectAccess
                    objectId={objectId}
                    objectType={objectType}
                />
            )}

            <PermittedSubjects
                objectId={objectId}
                objectType={objectType}
                currentAccessLevel={currentAccessLevel}
            />
        </>
    );
}
