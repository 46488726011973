import { grey } from '@mui/material/colors';
import * as React from 'react';
import { useState } from 'react';
import { Popover } from '../Popper/Popover';
import { Columns, ColWidth } from '../Table/types';
import { SearchInput, useSearch } from '../Search';
import { Table } from '../Table';
import { SearchInputBox } from './ItemSelector.styles';
import { getModifiersColumn } from '../Table/getModifiersColumn';
import { Stack, styled } from '@mui/material';
import { SeinoTheme } from '../../theme/lightTheme.ts';
import { formatHumanReadableDate } from '../../utils/date-format.ts';

export const Scrollable = styled('div')`
    max-height: 450px;
    overflow: auto;
`;

export const List = styled(Stack)({
    minWidth: 600,
    spacing: 1,
    pt: 1,
});

export interface Item {
    id: string;
    name: string;
    createdAt: number;
    modifiedAt: number;
}

function renderModified(item: Item) {
    if (item.modifiedAt) {
        return formatHumanReadableDate(new Date(item.modifiedAt));
    }

    if (item.createdAt) {
        formatHumanReadableDate(new Date(item.createdAt));
    }

    return 'N/A';
}

interface ItemSelectorProps<T extends Item> {
    title?: string;
    selected?: T;
    items: T[];
    columns?: Columns<T>;
    onSelect?: (item: T) => void;
    onDuplicate?: (item: T) => void;
    canDuplicate?: (item: T) => boolean;
    onRemove?: (item: T) => void;
    canRemove?: (item: T) => boolean;
    closeOnSelect: boolean;
    isLoading?: boolean;
}

export default function ItemSelector<T extends Item>({
    title,
    items,
    columns,
    selected,
    onSelect,
    onDuplicate,
    canDuplicate,
    onRemove,
    canRemove,
    isLoading,
    closeOnSelect = false,
}: ItemSelectorProps<T>) {
    const [isOpen, setIsOpen] = useState(false);

    const defaultColumns: Columns<T> = [
        {
            accessor: 'id',
            isVisible: false,
        },
        {
            accessor: 'name',
            isClickable: true,
            headerStyling: (theme: SeinoTheme) => ({
                background: theme.palette.background?.paper,
            }),
        },
        {
            getValue: row => renderModified(row),
            accessor: 'modifiedAt',
            title: 'Last modified',
            isClickable: true,
            width: ColWidth.DateTime,
        },
    ];

    const columnsWithModifiers: Columns<T> = [
        ...(columns ? columns : defaultColumns),
        getModifiersColumn<T>(
            onRemove,
            onDuplicate,
            i => (canDuplicate ? canDuplicate(i) : true),
            i => (canRemove ? canRemove(i) : true)
        ),
    ];

    const { setSearch, results } = useSearch(items, ['id', 'name']);

    return (
        <Popover
            open={isOpen}
            onClose={() => setIsOpen(false)}
            toggle={() => setIsOpen(current => !current)}
            width={400}
            buttonContent={selected?.name || title || 'Select item'}
            buttonStyle={{
                backgroundColor: grey[50],
            }}
            buttonConfig={{
                fullWidth: true,
            }}
        >
            <List>
                <SearchInputBox>
                    <SearchInput setSearch={setSearch} />
                </SearchInputBox>
                <Scrollable>
                    <Table
                        columns={columnsWithModifiers}
                        rows={results}
                        isRowHighlighted={row => row.id === selected?.id}
                        onRowClick={item => {
                            if (closeOnSelect) {
                                setIsOpen(false);
                            }
                            onSelect?.(item);
                        }}
                        defaultOrderBy="name"
                        defaultOrder="asc"
                        isLoading={isLoading}
                    />
                </Scrollable>
            </List>
        </Popover>
    );
}
