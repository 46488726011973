import { styled, Box, Typography } from '@mui/material';

export const HeaderItem = styled(Typography)`
    font-weight: 700;
    font-size: 14px;
`;
export const StyledRow = styled(Box)<{ hover?: 'off' | undefined }>`
    display: grid;
    grid-template-columns: 3fr 5fr 5fr 5fr 1fr;

    padding: 8px 4px;
    ${({ theme }) =>
        ({ hover = true }) =>
            hover &&
            `:hover {
        background: ${theme.palette.background.default};
        boxshadow: 0 2px 4px rgba0, 0, 0, 0.25;`}
`;

export const ChangeText = styled(Typography)`
    font-size: 12px;
`;

const ValueWrapper = styled(Box)<{ isPositive?: 'true' | undefined }>`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
`;

export const ValueWrapperComponent = ({
    isPositive,
    children,
}: {
    isPositive?: 'true' | undefined;
    children: React.ReactNode;
}) => {
    const color = isPositive === 'true' ? 'green' : 'red';
    return <ValueWrapper style={{ color }}>{children}</ValueWrapper>;
};
