import {
    Box,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Select,
    Typography,
} from '@mui/material';
import React from 'react';
import { CheckCircle } from '@mui/icons-material';
import { grey } from '@mui/material/colors';

export type DropdownOption = {
    label: string;
    secondaryLabel?: string;
    value: string;
};

type DropdownProps<T> = {
    isLoading: boolean;
    value: T | undefined;
    onChange: (value: T) => void;
    options: T[];
};

export const AutocompleteDropdown = ({
    isLoading,
    value,
    onChange,
    options,
}: DropdownProps<DropdownOption>) => (
    <Box
        position="absolute"
        top={5}
        right={13}
        display="flex"
        alignItems="center"
        minWidth="fit-content"
    >
        <Select<string | undefined>
            variant="standard"
            value={value?.value || ''}
            displayEmpty={false}
            renderValue={value =>
                options.find(o => o.value === value)?.label || ''
            }
            onChange={event => {
                const found = options.find(o => o.value === event.target.value);
                if (found) {
                    onChange(found);
                }
            }}
            disabled={isLoading}
            autoWidth
            disableUnderline
            MenuProps={{
                disablePortal: true,
                MenuListProps: {
                    sx: {
                        paddingBlock: 0,
                    },
                },
            }}
            sx={theme => ({
                '& .MuiInputBase-input': {
                    padding: 0,
                    paddingBlock: 0.5,
                    paddingInline: 1,
                    color: grey[500],
                    fontSize: '.8rem',
                    borderRadius: 1,

                    border: `1px solid transparent`,
                },
                '& .MuiInputBase-input:focus-visible,.MuiInputBase-input:hover':
                    {
                        backgroundColor: !isLoading
                            ? theme.elevation[3]
                            : 'unset',
                        border: `1px solid ${theme.border.light}`,
                        color: theme.palette.text.primary,
                    },
                ':root': {
                    select: {
                        padding: 8,
                    },
                },
            })}
            size="small"
        >
            {options.map(option => (
                <MenuItem key={option.value} value={option.value}>
                    <ListItemIcon>
                        {option.value === value?.value && (
                            <CheckCircle fontSize="small" />
                        )}
                    </ListItemIcon>
                    <ListItemText
                        sx={{ marginBlock: 0 }}
                        primaryTypographyProps={{ fontSize: '0.875rem' }}
                        primary={option.label}
                        secondary={
                            <Typography variant="caption">
                                {option.secondaryLabel}
                            </Typography>
                        }
                    />
                </MenuItem>
            ))}
        </Select>
    </Box>
);
