import { FieldMappingRule } from '../../../../../types/integration';
import {
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
} from '@mui/material';
import { Clear } from '@mui/icons-material';
import { SelectMarket } from '../../GoogleAnalytics/SelectMarket';

interface FieldMappingRuleProps {
    rule: FieldMappingRule;
    onChange: (rule: FieldMappingRule) => void;
    onDelete: () => void;
}

export const FieldMappingRuleRow = ({
    rule,
    onChange,
    onDelete,
}: FieldMappingRuleProps) => {
    return (
        <Stack direction="row" spacing={2}>
            <FormControl fullWidth={true} sx={{ maxWidth: 200 }}>
                <InputLabel size="small">Field</InputLabel>
                <Select
                    size="small"
                    label="Field"
                    value={rule.field}
                    onChange={event => {
                        onChange({ ...rule, field: event.target.value });
                    }}
                >
                    <MenuItem value="campaign_name">Campaign name</MenuItem>
                    <MenuItem value="content_name">Content name</MenuItem>
                    <MenuItem value="broadcast_metadata.seino_market">
                        Market
                    </MenuItem>
                </Select>
            </FormControl>

            <FormControl fullWidth={true} sx={{ maxWidth: 150 }}>
                <InputLabel size="small">Condition</InputLabel>
                <Select
                    size="small"
                    label="Market mapping"
                    value={rule.type}
                    onChange={event =>
                        onChange({
                            ...rule,
                            type: event.target
                                .value as FieldMappingRule['type'],
                        })
                    }
                >
                    <MenuItem value="contains">Contains</MenuItem>
                    <MenuItem value="equals">Equals</MenuItem>
                    <MenuItem value="starts_with">Starts with</MenuItem>
                    <MenuItem value="ends_with">Ends with</MenuItem>
                    <MenuItem value="regex">Regex</MenuItem>
                </Select>
            </FormControl>

            <TextField
                label="Value"
                size="small"
                sx={{ minWidth: 100 }}
                value={rule.value}
                onChange={event =>
                    onChange({ ...rule, value: event.target.value })
                }
            />

            <SelectMarket
                value={rule.result}
                onChange={market => {
                    onChange({ ...rule, result: market || '' });
                }}
            />

            <IconButton onClick={onDelete}>
                <Clear />
            </IconButton>
        </Stack>
    );
};
