import { Box, Button, Grid, styled } from '@mui/material';
import { MetricByDimension } from '../../../../../types/dashboard';
import { MetricByDimensionBuilderRow } from './MetricByDimensionRow';
import { defaultMetricsByDimension } from './defaultMetricsByDimension.tsx';
import { FilterBuilder } from '../../../../FilterBuilder/FilterBuilder.tsx';
import { useDatasetSchema } from '../../../../../hooks/use-dataset-schema.ts';

interface MetricsByDimensionBuilderProps {
    dataset: string;

    metricsByDimensions: MetricByDimension[];
    onChange: (metricsByDimensions: MetricByDimension[]) => void;
}

const SingleMetricContainer = styled(Box)`
    background-color: #f6f6f6;
    border-radius: 8px;
    border: 1px solid #eeeeee;
    width: 100%;
    padding: 8px;
    margin: 8px 0 0 16px;
`;

export const MetricsByDimensionBuilder = ({
    dataset,
    metricsByDimensions,
    onChange,
}: MetricsByDimensionBuilderProps) => {
    const { data: schema } = useDatasetSchema(dataset);
    const addMetricsByDimension = () =>
        onChange([...metricsByDimensions, defaultMetricsByDimension()]);

    const handleChange =
        (replaceI: number) => (newMetricsByDimension: MetricByDimension) =>
            onChange(
                metricsByDimensions.map((f, i) =>
                    i === replaceI ? newMetricsByDimension : f
                )
            );

    const handleDelete = (deleteI: number) => () =>
        onChange(metricsByDimensions.filter((_, i) => i !== deleteI));

    if (!schema) {
        return null;
    }

    return (
        <Grid container spacing={2} alignItems="center">
            {metricsByDimensions.map((f, i) => (
                <SingleMetricContainer key={`metric-${f.metric}-${i}`}>
                    <MetricByDimensionBuilderRow
                        datasetName={dataset}
                        key={i}
                        metricByDimension={f}
                        onChange={handleChange(i)}
                        onDelete={handleDelete(i)}
                    />
                    <Box pt={2} pr={8}>
                        <FilterBuilder
                            dataset={dataset}
                            onChange={filters =>
                                handleChange(i)({
                                    metric: f.metric,
                                    dimension: f.dimension,
                                    filters,
                                })
                            }
                            schema={schema}
                            fieldType="dimensions"
                            filters={metricsByDimensions[i].filters || []}
                        />
                    </Box>
                </SingleMetricContainer>
            ))}
            <Grid item xs={12}>
                <Button variant={'dashed'} onClick={addMetricsByDimension}>
                    Add High/Low Metric
                </Button>
            </Grid>
        </Grid>
    );
};
