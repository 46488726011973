import { Column, ColWidth } from '../../Table/types';
import { TargetsTableProperties } from './BaselineTargetsTable';
import { TargetDisplayColumn } from './TargetDisplayColumn.tsx';

export const getTargetDisplayColumn = (): Column<TargetsTableProperties> => ({
    accessor: 'value',
    title: 'Targets',
    isClickable: false,
    render: row => <TargetDisplayColumn row={row} />,
    width: ColWidth.Medium,
});
