import { useState } from 'react';

interface MarketProps {
    market: string | null | undefined;
}

export const Market = ({ market: marketRaw }: MarketProps) => {
    const [error, setError] = useState(false);
    if (!marketRaw || error) return <></>;

    const market = marketRaw.toUpperCase();
    const uri = `/img/markets/${market === 'UK' ? 'GB' : market}.png`;

    return <img src={uri} alt={market} onError={() => setError(true)} />;
};
