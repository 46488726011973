import { createContext, PropsWithChildren, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useInitialData } from '../hooks/use-initial-data';
import { useSetLastWorkspaceId } from '../hooks/use-last-workspace-id';
import {
    amsterdamLocale,
    Localization,
    londonLocale,
    usLocale,
} from '../types/datamodel/localization';
import { match } from 'ts-pattern';
import { LocaleProvider } from './LocaleProvider';
import { NotFound } from '../pages/NotFound/NotFound.tsx';
import { Workspace } from '../types/user';
import { WorkspaceSuspendedError } from '../components/ErrorPage/WorkspaceSuspendedError.tsx';
import { WorkspaceCancelledError } from '../components/ErrorPage/WorkspaceCancelledError.tsx';

export const WorkspaceContext = createContext<
    | {
          workspaceId: string;
          workspace: Workspace;
          locale: Localization;
          isAdmin: boolean;
          isLoading: boolean;
      }
    | undefined
>(undefined);

function WorkspaceProvider({ children }: PropsWithChildren) {
    const { workspaceId } = useParams<{ workspaceId: string | undefined }>();
    useSetLastWorkspaceId(workspaceId);

    if (!workspaceId) {
        throw new Error('workspaceId was not found in url.');
    }

    const { data: initialData } = useInitialData();

    const foundWorkspace = useMemo(
        () =>
            initialData?.workspaces.find(
                w => w.id === workspaceId || w.name === workspaceId
            ),
        [initialData?.workspaces, workspaceId]
    );

    if (!foundWorkspace) {
        return <NotFound />;
    }

    if (foundWorkspace.status === 'suspended') {
        return <WorkspaceSuspendedError />;
    }

    if (foundWorkspace.status === 'cancelled') {
        return <WorkspaceCancelledError />;
    }

    return <WithValue children={children} workspace={foundWorkspace} />;
}

const WithValue = ({
    workspace,
    children,
}: PropsWithChildren<{ workspace: Workspace }>) => {
    // @todo move to backend
    const locale = match(workspace.locale)
        .with('en_US', () => usLocale)
        .with('en_GB', () => londonLocale)
        .with('nl_NL', () => amsterdamLocale)
        .otherwise(() => amsterdamLocale);

    const value = {
        workspaceId: workspace.id,
        workspace,
        isLoading: false,
        locale,
        isAdmin: workspace.role === 'admin',
    };

    return (
        <WorkspaceContext.Provider value={value}>
            <LocaleProvider>{children}</LocaleProvider>
        </WorkspaceContext.Provider>
    );
};

export { WorkspaceProvider };
