import { useSearchParams } from 'react-router-dom';

function urlSearchParamsToRecord<Parameters extends Record<string, string>>(
    searchParams: URLSearchParams
): Parameters {
    const params: Record<string, string> = {};

    for (const [key, value] of searchParams.entries()) {
        params[key] = value;
    }

    return params as Parameters;
}

function extractDialogParams<Parameters extends Record<string, string>>(
    searchParams: Parameters,
    prefixed: boolean
): Parameters {
    const params: Record<string, string> = {};
    for (const key in searchParams) {
        if (prefixed && key.startsWith('dialog.')) {
            params[key.replace(new RegExp(`^dialog.`), '')] = searchParams[key];
        } else {
            params[key] = searchParams[key];
        }
    }

    return params as Parameters;
}

function useCloseDialog() {
    const [_, setSearchParams] = useSearchParams();

    return {
        close: () =>
            setSearchParams(prev => {
                const newParams = new URLSearchParams();
                for (const [key, value] of prev.entries()) {
                    if (!key.startsWith('dialog.')) {
                        newParams.set(key, value);
                    }
                }
                return newParams;
            }),
    };
}

function useOpenDialog<Parameters extends Record<string, string>>() {
    const [_, setSearchParams] = useSearchParams();
    return {
        open: (type: string, parameters: Parameters) =>
            setSearchParams(prev => {
                const params = extractDialogParams<Parameters>(
                    parameters,
                    false
                );

                prev.set('dialog.type', type);
                for (const [key, value] of Object.entries(params ?? [])) {
                    prev.set(`dialog.${key}`, value);
                }

                return prev;
            }),
    };
}

export function useDialog<Parameters extends Record<string, string>>(
    type?: string
) {
    const { close } = useCloseDialog();
    const { open } = useOpenDialog();
    const [searchParams] = useSearchParams();
    const params = extractDialogParams<Parameters>(
        urlSearchParamsToRecord(searchParams),
        true
    );

    return {
        closeDialog: () => close(),
        isOpen: params['type'] === type,
        currentDialog: params['type'] || undefined,
        openDialog: (parameters: Parameters) => {
            if (type === undefined)
                throw new Error('Cannot open dialog without provided type');

            open(type, parameters);
        },
        parameters: params,
    };
}
