import { Heading2 } from '../Settings.styled.tsx';
import { Typography } from '@mui/material';
import { ActionButton } from '../../../components/Button/ActionButton.tsx';
import React, { useCallback } from 'react';
import { useActiveWorkspace } from '../../../providers/WorkspaceProvider.hooks.ts';
import ConfirmDialog from '../../../components/ConfirmDialog/ConfirmAlert.tsx';
import { useToggle } from 'usehooks-ts';
import { useDeleteWorkspace } from './useDeleteWorkspace.ts';
import { useNavigate } from 'react-router-dom';
import { useActiveUser } from '../../../providers/CurrentUserProvider.hooks.ts';

export const DeleteWorkspace = () => {
    const { workspace } = useActiveWorkspace();
    const { workspaces } = useActiveUser();
    const [dialogOpen, toggleDialog] = useToggle(false);
    const { mutate, isLoading } = useDeleteWorkspace();
    const navigate = useNavigate();

    const onSubmit = useCallback(() => {
        mutate(undefined, {
            onSuccess: () => {
                const firstOtherWorkspace = workspaces.find(
                    w =>
                        w.licenseId === workspace.licenseId &&
                        w.id !== workspace.id
                );

                navigate(
                    firstOtherWorkspace
                        ? `/workspaces/${firstOtherWorkspace.id}/settings/${
                              firstOtherWorkspace.licenseType === 'agency'
                                  ? 'agency'
                                  : ''
                          }`
                        : '/welcome'
                );
            },
        });
    }, [mutate, navigate, workspace.id, workspace.licenseId, workspaces]);

    if (workspace.status === 'active' || workspace.role !== 'admin') {
        return;
    }

    return (
        <>
            <Heading2>Delete workspace</Heading2>
            <Typography variant="body2">
                Delete this workspace and all related dashboards, explorer views
                and alerts.
            </Typography>
            <div>
                <ActionButton color="error" onClick={toggleDialog}>
                    Delete workspace
                </ActionButton>
            </div>
            <ConfirmDialog
                open={dialogOpen}
                isDangerousAction={true}
                title={`Delete workspace ${workspace.displayName}`}
                content={`Deleting a workspace is irreversible. Are you sure?`}
                isExecuting={isLoading}
                onContinue={onSubmit}
                onAnyDecision={toggleDialog}
                continueText="Delete"
            />
        </>
    );
};
