import { useSeinoAuth } from '../../hooks/use-seino-auth.ts';
import { Link } from '@mui/material';
import * as React from 'react';

export const SignOutLink = () => {
    const { logout } = useSeinoAuth();
    return (
        <Link
            component="button"
            variant="body2"
            color="primary.main"
            onClick={() =>
                logout({
                    logoutParams: {
                        returnTo: window.location.origin,
                    },
                })
            }
        >
            Logout
        </Link>
    );
};
