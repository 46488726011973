import { Stack } from '@mui/material';
import { DataModelField } from '../../../types/datamodel/schema';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { CollectionForm } from '../Collections';
import { ButtonAutocomplete } from '../../ButtonAutocomplete/ButtonAutocomplete';
import { AutocompleteItem } from '../../ButtonAutocomplete/autocompleteItem';
import { Add } from '@mui/icons-material';

interface BaselineSelectProps {
    schemas: DataModelField[];
}

interface AutocompleteWithCategory extends AutocompleteItem {
    category: string;
}

export const AddBaseline = ({ schemas }: BaselineSelectProps) => {
    const { control, watch } = useFormContext<CollectionForm>();
    const enabledFields = watch('targets')?.map(({ id }) => id) || [];
    const { append } = useFieldArray({
        control,
        name: 'targets',
    });

    const metrics = schemas?.filter(
        d => d.features?.includes('baseline') && !enabledFields.includes(d.id)
    );
    const options = metrics.map(({ id, name, category }) => ({
        id,
        name,
        category,
    }));

    if (!options || options.length < 1) {
        return null;
    }

    const handleOnChange = (selected: AutocompleteItem | null) => {
        const candidateMetric = metrics.find(
            option => option.id === selected?.id
        );
        if (!candidateMetric) {
            return;
        }

        append({
            id: candidateMetric.id,
            metric: candidateMetric.name,
            value: 0,
            format: candidateMetric?.format,
            category: candidateMetric?.category,
        });
    };

    return (
        <Stack direction="row" gap={2}>
            <ButtonAutocomplete<AutocompleteWithCategory>
                label="Add baseline"
                popperTitle="Add baseline"
                options={options}
                groupBy={item => item.category}
                onChange={handleOnChange}
                variant="filter"
                buttonProps={{
                    size: 'small',
                    startIcon: <Add fontSize="small" />,
                    endIcon: undefined,
                    sx: {
                        maxWidth: 140,
                    },
                }}
            />
        </Stack>
    );
};
