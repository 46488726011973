import { NumberFormat } from '../utils/number-format';
import { useDatasetSchema } from './use-dataset-schema';
import { match } from 'ts-pattern';
import { useMemo } from 'react';
import {
    isFilterableDimension,
    isFunnelMetric,
    isMetricFormat,
    notHasFeature,
    sortFields,
} from '../types/datamodel/schema.ts';

export const useDashboardDataset = (dataset: string) => {
    const useDataSchemaResult = useDatasetSchema(dataset);

    const data = useMemo(() => {
        const fields = (useDataSchemaResult?.data?.fields || []).filter(
            notHasFeature('hide')
        );

        return {
            dimensions: Object.fromEntries(
                fields.filter(isFilterableDimension).map(f => [
                    f.id,
                    {
                        id: f.id,
                        label: f.shortName ?? f.name,
                        type: match(f.format)
                            .with('date', () => 'time' as const)
                            .with('boolean', () => 'boolean' as const)
                            .otherwise(() => 'string' as const),
                        category: f.category,
                    },
                ])
            ),
            metrics: Object.fromEntries(
                fields
                    .toSorted(sortFields)
                    .filter(f => isMetricFormat(f.format))
                    .map(f => [
                        f.id,
                        {
                            id: f.id,
                            label: f.shortName ?? f.name,
                            format: match(f.format)
                                .with('percent', () => NumberFormat.Percent)
                                .with('currency', () => NumberFormat.Currency)
                                .with('duration', () => NumberFormat.Duration)
                                .otherwise(() => NumberFormat.Generic),
                            moreIsBetter:
                                !f.features?.includes('negative_measure'),
                            category: f.category,
                            features: f.features,
                        },
                    ])
            ),
            funnelMetrics: Object.fromEntries(
                fields.filter(isFunnelMetric).map(f => [
                    f.id,
                    {
                        id: f.id,
                        label: f.shortName ?? f.name,
                        format: match(f.format)
                            .with('percent', () => NumberFormat.Percent)
                            .with('currency', () => NumberFormat.Currency)
                            .with('duration', () => NumberFormat.Duration)
                            .otherwise(() => NumberFormat.Generic),
                        moreIsBetter: !f.features?.includes('negative_measure'),
                        category: f.category,
                    },
                ])
            ),
        };
    }, [useDataSchemaResult?.data?.fields]);

    return useMemo(
        () => ({
            data,
            isLoading: useDataSchemaResult.isLoading,
            error: useDataSchemaResult.error,
        }),
        [data, useDataSchemaResult.isLoading, useDataSchemaResult.error]
    );
};
