import { Alert, Box, Button, styled } from '@mui/material';
import { isDemoWorkspace } from '../../types/user';
import { useActiveUser } from '../../providers/CurrentUserProvider.hooks.ts';
import { useRequestAccountOnboarding } from './useRequestAccountOnboarding.tsx';
import { useActiveWorkspaceFromRoute } from '../../providers/useActiveWorkspaceFromRoute.ts';

const CTA = styled(Button)({
    display: 'inline-block',
    marginInline: '1em',
});

CTA.defaultProps = {
    variant: 'contained',
    color: 'info',
    size: 'small',
};

export const DemoBanner = () => {
    const workspace = useActiveWorkspaceFromRoute();
    const user = useActiveUser();
    const { requested, isLoading, isError, request } =
        useRequestAccountOnboarding();

    const handleContactOnClick = async () => {
        if (!workspace) return;

        request({
            idp_id: user.memberId,
            email: user.email,
            name: user.name,
            workspace_id: workspace.id,
            workspace_name: workspace.name,
        });
    };

    if (!isDemoWorkspace(workspace)) {
        return null;
    }

    if (isError) {
        return (
            <Alert severity="error">
                <strong>
                    Something went wrong while processing your request. Try
                    again later or contact us at{' '}
                    <a href="mailto:hello@seino.ai?subject=Account%20Onboarding">
                        hello@seino.ai
                    </a>
                    .
                </strong>
            </Alert>
        );
    }

    if (!isLoading && requested) {
        return (
            <Alert severity="info">
                <strong>
                    We have received your request for onboarding and will get in
                    touch with you as soon as possible. Until then, you can
                    still explore with demo data.
                </strong>
            </Alert>
        );
    }

    return (
        <Alert severity="info" sx={{ alignItems: 'center' }}>
            <Box display="flex" alignItems="center">
                <strong>
                    You’re currently exploring demo data. To get started with
                    your own data, please contact us to onboard your account.
                </strong>
                <CTA
                    aria-label="Reacht out to us"
                    onClick={() => handleContactOnClick()}
                >
                    Request onboarding
                </CTA>
            </Box>
        </Alert>
    );
};
