import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { AddBaseline } from './CollectionForm/AddBaseline';
import { Container, LoaderContainer } from './CollectionConfigurator.styles';
import { BaselineTargetsTableForm } from './CollectionForm/BaselineTargetsTableForm';
import { BaselineTargetsTable } from './CollectionForm/BaselineTargetsTable';
import { CollectionTitle } from './CollectionForm/CollectionTitle.tsx';
import { useCampaignCollection } from '../../hooks/use-campaign-collections';
import ConfirmDialog from '../ConfirmDialog/ConfirmAlert';
import { useState } from 'react';
import { DataModelField } from '../../types/datamodel/schema';
import { useActiveWorkspace } from '../../providers/WorkspaceProvider.hooks';
import { useActiveExplorerView } from '../../providers/ExplorerViewProvider.hooks';
import { isPermitted } from '../../types/permission/permission';

interface CollectionConfiguratorProps {
    baselineId: string;
    editMode: boolean;
    setEditMode: (v: boolean) => void;
    onRemove: (baselineId: string) => void;
    schemas: DataModelField[] | undefined;
}

export const CollectionConfigurator = ({
    baselineId,
    editMode,
    setEditMode,
    onRemove,
    schemas,
}: CollectionConfiguratorProps) => {
    const { workspaceId } = useActiveWorkspace();
    const { accessLevel } = useActiveExplorerView();
    const [startDelete, setStartDelete] = useState(false);

    const { data: collections = [], refetch } =
        useCampaignCollection(workspaceId);

    if (!schemas) return null;
    const collection = collections.find(c => c.baselineSet.id === baselineId);

    if (baselineId === 'none') {
        return (
            <Box display="flex" justifyContent="flex-end">
                <Button type="submit">Apply</Button>
            </Box>
        );
    }

    if (!collection) {
        refetch();
        return (
            <LoaderContainer>
                <CircularProgress />
            </LoaderContainer>
        );
    }

    return (
        <Container>
            <Typography variant="body2" sx={{ marginBottom: '10px' }}>
                Here you can create baselines that are applied only to the
                current view. You can also save baselines as templates so they
                can be used with other views.
            </Typography>
            {editMode ? (
                <Box>
                    <Box my={2}>
                        <CollectionTitle />
                    </Box>
                    <Box my={2}>{schemas && <BaselineTargetsTableForm />}</Box>
                    <Box my={2}>
                        {schemas && isPermitted(accessLevel, 'write') && (
                            <AddBaseline schemas={schemas} />
                        )}
                    </Box>
                    {isPermitted(accessLevel, 'write') && (
                        <Box display="flex" justifyContent="space-between">
                            <Button
                                variant="dashed"
                                color="error"
                                size="small"
                                onClick={() => setStartDelete(true)}
                            >
                                {'Delete Template'}
                            </Button>
                            <Button type="submit">{'Save Template'}</Button>
                        </Box>
                    )}
                </Box>
            ) : (
                <Box>
                    <Box my={2}>
                        <BaselineTargetsTable
                            targets={collection.baselineSet.targets}
                        />
                    </Box>
                    {isPermitted(accessLevel, 'write') && (
                        <>
                            <Box my={2}>
                                <Button
                                    variant="dashed"
                                    size="small"
                                    onClick={() => setEditMode(true)}
                                >
                                    Edit Template
                                </Button>
                            </Box>
                            <Box display="flex" justifyContent="flex-end">
                                <Button type="submit">Apply Template</Button>
                            </Box>
                        </>
                    )}
                </Box>
            )}
            <ConfirmDialog
                open={startDelete}
                content={`Are you sure you want to delete the template "${collection.name}"?`}
                continueText="Delete template"
                isDangerousAction={true}
                cancelText="Cancel"
                onAnyDecision={() => setStartDelete(false)}
                onContinue={() => {
                    setStartDelete(false);
                    onRemove(collection.id);
                }}
            />
        </Container>
    );
};
