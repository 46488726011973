import { useReportingSubscriptionMutation } from './useReportingSubscriptions';
import { ChangeEvent } from 'react';
import { ulid } from 'ulid';
import { FormControlLabel, Switch } from '@mui/material';
import { ReportingSubscription } from '../../../types/reporting/subscription';

export function ConfigureSubscription({
    workspaceId,
    memberId,
    subscription,
    label,
}: {
    workspaceId: string;
    memberId: string;
    label: string;
    subscription?: ReportingSubscription;
}) {
    const { unsubscribe, subscribe } = useReportingSubscriptionMutation();
    const handleOnSwitch = async (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            await subscribe({
                id: subscription?.id || ulid(),
                memberId,
                payload: {
                    type: 'weeklySummary',
                    workspaceId,
                },
            });
        } else {
            if (subscription) {
                await unsubscribe(subscription.id);
            }
        }
    };

    return (
        <FormControlLabel
            key={workspaceId}
            componentsProps={{
                typography: { variant: 'mediumBody' },
            }}
            label={label}
            control={
                <Switch
                    onChange={handleOnSwitch}
                    checked={Boolean(subscription)}
                    color="secondary"
                />
            }
        />
    );
}
