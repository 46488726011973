import { useCallback, useState } from 'react';

import { Order } from './sorting';
import { BaseRow, Columns } from './types';

export const useOrder = <T extends BaseRow>(
    defaultOrderBy: keyof T | undefined,
    defaultOrder: Order,
    columns: Columns<T>
) => {
    const [orderBy, setOrderBy] = useState<keyof T>(
        defaultOrderBy ?? columns[0].accessor
    );
    const [order, setOrder] = useState<Order>(defaultOrder);

    const changeOrder = useCallback(
        (field: keyof T) => {
            if (orderBy === field) {
                setOrder(order === 'desc' ? 'asc' : 'desc');
            } else {
                setOrderBy(field);
            }
        },
        [order, orderBy]
    );

    return [orderBy, order, changeOrder] as const;
};
