import { ButtonGroup, IconButton } from '@mui/material';
import { FileCopy } from '@mui/icons-material';
import * as React from 'react';
import { Column, ColWidth } from './types';
import { ModifierRow } from './Table';
import { RemoveModifier } from './RemoveModifier.tsx';

export function getModifiersColumn<T extends ModifierRow>(
    remove?: (item: T) => void,
    duplicate?: (item: T) => void,
    canDuplicate?: (item: T) => boolean,
    canRemove?: (item: T) => boolean
): Column<T> {
    return {
        accessor: 'modifiers',
        title: '',
        isClickable: false,
        render: (row: T) => (
            <ButtonGroup>
                {duplicate && canDuplicate?.(row) && (
                    <IconButton
                        size="extraSmall"
                        color="light"
                        onClick={event => {
                            event.preventDefault();
                            if (row.id) {
                                duplicate(row);
                            }
                        }}
                    >
                        <FileCopy fontSize="small" />
                    </IconButton>
                )}
                {remove && canRemove?.(row) && (
                    <RemoveModifier<T>
                        key={`table-modifier-${row.id}`}
                        item={row}
                        onRemove={remove}
                    />
                )}
            </ButtonGroup>
        ),
        width: ColWidth.Tiny,
    };
}
