import { useSeinoStore } from '../../store/seino-store';
import { DateRangePicker } from './DateRangePicker';
import locale from 'date-fns/locale/en-US';
import { useDateRange } from '../../hooks/use-date-range';
import { useMediaQuery, useTheme } from '@mui/material';
import * as React from 'react';
import { useCallback, useMemo, useReducer } from 'react';
import {
    DateRangePickerContext,
    DateRangePickerContextProps,
} from './DateRangePickerContext';
import { reducer } from './reducer';
import { useEventListener } from 'usehooks-ts';
import { areRangesEqual } from './equals.ts';

type DateRangePickerWrapperProps = { allowCompare: boolean };

export const DateRangePickerWrapper = ({
    allowCompare,
}: DateRangePickerWrapperProps) => {
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.up('lg'));
    const sm = useMediaQuery(theme.breakpoints.up('md'));
    const calendarCount = md ? 3 : sm ? 2 : 1;
    const weekStartsOn = useSeinoStore(state => state.views.firstDayOfWeek);

    const { dateRanges, setDateRanges } = useDateRange();

    const onChange: DateRangePickerContextProps['onChange'] = useCallback(
        dateRanges => setDateRanges(dateRanges),
        [setDateRanges]
    );

    const reducerCallback = useMemo(
        () => reducer(calendarCount, weekStartsOn),
        [calendarCount, weekStartsOn]
    );

    const [state, dispatch] = useReducer(reducerCallback, {
        isOpen: false,
        dateRanges,
        currentPeriod: 'main',
        currentSide: 'start',
        lastVisibleMonth: new Date(),
    });

    const value: DateRangePickerContextProps = useMemo(
        () => ({
            dispatch,
            state,
            onChange,
            locale,
            weekStartsOn,
            calendarCount,
            allowCompare,
        }),
        [dispatch, state, onChange, weekStartsOn, calendarCount, allowCompare]
    );

    const apply = useCallback(() => {
        if (!areRangesEqual(dateRanges, state.dateRanges)) {
            onChange(state.dateRanges);
        }

        dispatch({ type: 'close' });
    }, [dateRanges, state.dateRanges, onChange]);

    const cancel = useCallback(() => {
        dispatch({ type: 'reset', dateRanges });
    }, [dateRanges]);

    useEventListener('keyup', event => {
        switch (event.key) {
            case 'Escape':
                cancel();
                break;
            case 'Enter':
                apply();
                break;
        }
    });

    return (
        <DateRangePickerContext.Provider value={value}>
            <DateRangePicker onApply={apply} onCancel={cancel} />
        </DateRangePickerContext.Provider>
    );
};
