import axios from 'axios';
import { getRustAPIUrl } from '../../utils/api';

export interface FetchWorkspaceProps {
    workspaceId: string;
}

export const fetchIntegrations = (workspaceId: string) => {
    return axios.get(
        `${getRustAPIUrl()}/workspaces/${workspaceId}/integrations`
    );
};

export const fetchGaViews = ({ workspaceId }: FetchWorkspaceProps) => {
    return axios.get<{ data: Property[] }>(
        `${getRustAPIUrl()}/workspaces/${workspaceId}/integrations/google-analytics/properties`
    );
};

export type Property = {
    id: string;
    name: string;
    account_id: string;
    account_name: string;
};

export const connectGoogleAnalytics = (workspaceId: string) =>
    axios.post(
        `${getRustAPIUrl()}/workspaces/${workspaceId}/integrations/google-analytics`
    );
