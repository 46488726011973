import React from 'react';
import { Alert, AlertTitle, Stack, Typography } from '@mui/material';
import { AvailableIntegrations } from './AvailableIntegrations.tsx';
import { EnabledIntegrations } from './EnabledIntegrations.tsx';
import { SettingsTab } from '../SettingsTab.tsx';
import { useActiveWorkspace } from '../../../providers/WorkspaceProvider.hooks.ts';

export const Integrations = () => {
    const { isAdmin } = useActiveWorkspace();

    if (!isAdmin) {
        return (
            <SettingsTab title="Integrations">
                <Alert severity="warning">
                    <AlertTitle>Insufficient privileges</AlertTitle>
                    Please ask an administrator to make changes to the
                    integrations of this workspace.
                </Alert>
            </SettingsTab>
        );
    }

    return (
        <SettingsTab title="Integrations">
            <Stack gap={4}>
                <Stack gap={2}>
                    <Typography>Enabled</Typography>
                    <EnabledIntegrations />
                </Stack>

                <Stack gap={2}>
                    <Typography>Available</Typography>
                    <AvailableIntegrations />
                </Stack>
            </Stack>
        </SettingsTab>
    );
};
