import { Palette } from '@mui/material';
import { TargetData } from './useTargetData.ts';

export const getTargetConditionColor = (palette: Palette, data: TargetData) => {
    if (!data.cumulativeValue) {
        return '#000';
    }

    return data.cumulativeValue >= data.expectedValue
        ? palette.success.main
        : palette.error.main;
};
