import { FormControlLabel, FormControlLabelProps, Switch } from '@mui/material';
import { ReactNode } from 'react';

interface ToggleProps {
    checked: boolean;
    label?: string | ReactNode;
    onChange?: (checked: boolean) => void;
    componentProps?: FormControlLabelProps;
    disabled?: boolean;
    labelPlacement?: 'start' | 'end';
}

export const Toggle = ({
    label,
    onChange,
    checked,
    componentProps,
    disabled = false,
    labelPlacement = 'start',
}: ToggleProps) => {
    return (
        <FormControlLabel
            componentsProps={{
                typography: { variant: 'body2' },
                ...componentProps,
            }}
            control={
                <Switch
                    disabled={disabled ?? false}
                    size="small"
                    onChange={(_, checked) => onChange?.(checked)}
                    color="success"
                    checked={checked}
                />
            }
            labelPlacement={labelPlacement ?? 'start'}
            label={label}
        />
    );
};
