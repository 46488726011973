import { ColumnState } from '@ag-grid-community/core';
import { createColumnWidthCalculator } from '../columnWidths.ts';

export const addMissingColumns = (
    columnStates: ColumnState[],
    columnDefs: { colId: string; headerName?: string }[]
) => {
    const columnWidthCalculator = createColumnWidthCalculator();
    const columnIds = columnStates.map(c => c.colId);

    columnDefs.forEach((col, i) => {
        if (columnIds.includes(col.colId)) {
            return;
        }

        // Insert column after preceding column from definition, as long as it is not the first
        if (i > 0) {
            const index = columnStates.findIndex(
                c => c.colId === columnDefs[i - 1].colId
            );

            columnStates.splice(index + 1, 0, {
                colId: col.colId,
                width: columnWidthCalculator(col.headerName),
                hide: true,
            });
        }
        // Insert column before succeeding column from definition
        else {
            const index = columnStates.findIndex(
                c => c.colId === columnDefs[i + 1].colId
            );

            columnStates.splice(index, 0, {
                colId: col.colId,
                width: columnWidthCalculator(col.headerName),
                hide: true,
            });
        }
    });

    return columnStates;
};
