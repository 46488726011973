import { FormatType } from '../../../types/datamodel/schema';
import { Euro, Numbers, Percent } from '@mui/icons-material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

const props: SvgIconProps = {
    fontSize: 'extraSmall',
};

export const TargetStartAdornment = ({ format }: { format: FormatType }) => {
    switch (format) {
        case 'percent':
            return <Percent {...props} />;
        case 'number':
            return <Numbers {...props} />;
        case 'currency':
            return <Euro {...props} />;
        default:
            return null;
    }
};
