import {
    ConditionFieldMapping,
    FieldMappingRule,
} from '../../../../../types/integration';
import { Button, Stack } from '@mui/material';
import { FieldMappingRuleRow } from './FieldMappingRuleRow';
import { useEffect } from 'react';

const createNewRule = (): FieldMappingRule => ({
    type: 'starts_with',
    value: '',
    field: 'content_name',
    result: '',
});

interface FieldMappingBuilderProps {
    mapping: ConditionFieldMapping;
    onChange: (mapping: ConditionFieldMapping) => void;
}

export const FieldMappingBuilder = ({
    mapping,
    onChange,
}: FieldMappingBuilderProps) => {
    useEffect(() => {
        if (mapping.rules.length === 0) {
            onRuleAdd();
        }
    });

    const onRuleAdd = () =>
        onChange({ ...mapping, rules: [...mapping.rules, createNewRule()] });

    const onRuleChange = (i: number, newRule: FieldMappingRule) => {
        onChange({
            ...mapping,
            rules: mapping.rules.map((old, j) => (i === j ? newRule : old)),
        });
    };

    const onRuleDelete = (i: number) =>
        onChange({
            ...mapping,
            rules: mapping.rules.filter((_, j) => i !== j),
        });

    return (
        <Stack spacing={2}>
            {mapping.rules.map((rule, i) => (
                <FieldMappingRuleRow
                    key={i}
                    rule={rule}
                    onChange={newRule => onRuleChange(i, newRule)}
                    onDelete={() => onRuleDelete(i)}
                />
            ))}

            <Stack direction="row" justifyContent="right">
                <Button
                    fullWidth={false}
                    onClick={() => onRuleAdd()}
                    sx={{ maxWidth: 100 }}
                >
                    Add
                </Button>
            </Stack>
        </Stack>
    );
};
