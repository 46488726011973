import { DeleteOutline } from '@mui/icons-material';
import { IconButton } from '@mui/material';

export const DeleteWidgetButton = ({
    onConfirm,
}: {
    onConfirm: () => void;
}) => {
    return (
        <IconButton onClick={onConfirm} aria-label="Delete widget">
            <DeleteOutline />
        </IconButton>
    );
};
