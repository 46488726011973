import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { AddBaseline } from './CollectionForm/AddBaseline';
import { CollectionTitle } from './CollectionForm/CollectionTitle.tsx';
import { Container } from './CollectionConfigurator.styles';
import { BaselineTargetsTableForm } from './CollectionForm/BaselineTargetsTableForm';
import { CreateTemplateSwitch } from './CollectionForm/CreateTemplateSwitch';
import { DataModelField } from '../../types/datamodel/schema';
import { useActiveExplorerView } from '../../providers/ExplorerViewProvider.hooks';
import { isPermitted } from '../../types/permission/permission';

interface CollectionConfiguratorProps {
    createTemplate: boolean;
    setCreateTemplate: (v: boolean) => void;
    schemas: DataModelField[] | undefined;
}
export const CurrentViewBaselinesConfigurator = ({
    createTemplate,
    setCreateTemplate,
    schemas,
}: CollectionConfiguratorProps) => {
    const { accessLevel } = useActiveExplorerView();

    if (!schemas || !accessLevel) return null;

    const hasEditRights = isPermitted(accessLevel, 'write');

    return (
        <Container>
            <Typography mb={2} variant="body2">
                Here you can create baselines that are applied only to the
                current view. You can also save baselines as templates so they
                can be used with other views.
            </Typography>
            {hasEditRights && (
                <>
                    <Box my={2}>
                        <CreateTemplateSwitch
                            onChange={(
                                evt: React.ChangeEvent<HTMLInputElement>
                            ) => {
                                setCreateTemplate(evt.target.checked);
                            }}
                        />
                        {createTemplate && (
                            <Box my={2}>
                                <CollectionTitle initial="" />
                            </Box>
                        )}
                    </Box>
                </>
            )}
            <Box my={2}>{schemas && <BaselineTargetsTableForm />}</Box>
            {hasEditRights && (
                <>
                    <Box my={2}>
                        {schemas && <AddBaseline schemas={schemas} />}
                    </Box>

                    <Box display="flex" justifyContent="flex-end">
                        <Button type="submit">
                            {createTemplate ? 'Create Template' : 'Save'}
                        </Button>
                    </Box>
                </>
            )}
        </Container>
    );
};
