import * as React from 'react';
import { PropsWithChildren } from 'react';
import { Dialog, DialogProps } from '@mui/material';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

import { useDialog } from './useDialog.ts';
import { useFullscreen } from '../../hooks/use-fullscreen.ts';

export const SeinoDialogForm = styled(Stack)({
    autoComplete: 'off',
    component: 'form',
});
SeinoDialogForm.defaultProps = {
    direction: 'column',
    justifyContent: 'center',
    marginBlock: 2,
    spacing: 2,
    width: '100%',
};

export const SeinoDialog = ({
    children,
    id,
    ...dialogProps
}: PropsWithChildren<Omit<DialogProps, 'open'> & { id: string }>) => {
    const { isOpen, closeDialog } = useDialog(id);
    const fullScreen = useFullscreen();

    return (
        <Dialog
            disableAutoFocus
            fullScreen={fullScreen}
            fullWidth
            scroll="paper"
            maxWidth="lg"
            open={isOpen}
            onClose={() => closeDialog()}
            transitionDuration={0}
            {...dialogProps}
            PaperProps={{
                sx: {
                    minHeight: 500,
                    ...dialogProps.PaperProps?.sx,
                },
                ...dialogProps.PaperProps,
            }}
        >
            {children}
        </Dialog>
    );
};
