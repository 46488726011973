import { useQuery } from '@tanstack/react-query';
import { AlertRuleConfig, AlertRuleResults } from '../types/alerting';
import { getAlertRulePreview } from '../api-client/alert-rules';

import { useActiveWorkspaceId } from '../providers/useActiveWorkspaceId.ts';

export const useAlertPreview = (
    alertRule: AlertRuleConfig | undefined,
    enabled: boolean
) => {
    const workspaceId = useActiveWorkspaceId();

    return useQuery<AlertRuleResults>({
        queryKey: [workspaceId, 'alert-rule', JSON.stringify(alertRule)],
        queryFn: async () => {
            if (!alertRule) {
                return;
            }

            return {
                ...(await getAlertRulePreview(workspaceId, alertRule)),
            };
        },
        enabled,
    });
};
