import { useQuery } from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';
import { DataModelSchema } from '../types/datamodel/schema';
import { getRustAPIUrl } from '../utils/api';

import { useActiveWorkspaceId } from '../providers/useActiveWorkspaceId.ts';

const fetchDatasetSchema =
    (workspaceId: string, dataset: string) => async () => {
        const result = await axios.get<
            DataModelSchema,
            AxiosResponse<DataModelSchema>
        >(`${getRustAPIUrl()}/workspaces/${workspaceId}/datasets/${dataset}`, {
            params: { schema: true },
        });

        return result.data;
    };

export const datasetQueryKey = (
    workspaceId: string,
    dataset: string | undefined
) => ['dataset-schema', workspaceId, dataset];

export const useDatasetSchema = (dataset: string | undefined) => {
    const workspaceId = useActiveWorkspaceId();

    return useQuery(
        datasetQueryKey(workspaceId, dataset),
        fetchDatasetSchema(workspaceId, dataset || ''),
        { enabled: !!workspaceId && !!dataset, staleTime: 30_000 }
    );
};
