import React from 'react';
import { Button, IconButton } from '@mui/material';
import { Redo, Undo } from '@mui/icons-material';
import { useUndoState } from '../../hooks/use-undo-state.ts';

type UndoRedoResetProps<T> = Pick<
    ReturnType<typeof useUndoState<T>>,
    'clear' | 'undo' | 'redo' | 'canUndo' | 'canRedo'
>;

export function UndoRedoReset<T>({
    clear,
    undo,
    redo,
    canUndo,
    canRedo,
}: UndoRedoResetProps<T>) {
    if (!canUndo && !canRedo) {
        return <></>;
    }

    return (
        <>
            <IconButton size="small" disabled={!canUndo} onClick={undo}>
                <Undo fontSize="small" />
            </IconButton>

            <IconButton size="small" disabled={!canRedo} onClick={redo}>
                <Redo fontSize="small" />
            </IconButton>

            <Button type="reset" size="small" onClick={() => clear()}>
                Reset
            </Button>
        </>
    );
}
