export const engagementColors = [
    '#7986CB',
    '#4FC3F7',
    '#81C784',
    '#FFB94F',
    '#FF6F61',
];

export const getEngagementColor = (segment: number): string =>
    engagementColors[segment - 1];
