import { useIntegrations } from '../../../hooks/use-integrations';

export const useIntegration = (integrationId: string) => {
    const result = useIntegrations();

    return {
        ...result,
        data: result.data?.find(x => x.id === integrationId),
    };
};
