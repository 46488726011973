import {
    Autocomplete,
    AutocompleteProps,
    TextField,
    TextFieldProps,
} from '@mui/material';

type AlertRecipientsInputProps = Omit<
    AutocompleteProps<string, true, false, true>,
    'renderInput'
> & {
    inputProps: TextFieldProps;
};

export const AlertRecipientsInput = ({
    inputProps,
    ...props
}: AlertRecipientsInputProps) => {
    return (
        <Autocomplete
            {...props}
            renderInput={props => <TextField {...props} {...inputProps} />}
            freeSolo={true}
            multiple={true}
            autoSelect={true}
        />
    );
};
