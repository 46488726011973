import Typography from '@mui/material/Typography';
import { Stack } from '@mui/material';
import { ActionButton } from '../../components/Button/ActionButton.tsx';
import { Add } from '@mui/icons-material';
import { useDialog } from '../../components/Dialog/useDialog.ts';

export function EmptyTarget() {
    const { openDialog } = useDialog('target');

    return (
        <Stack
            padding={4}
            gap={2}
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={theme => ({
                marginTop: theme.spacing(2),
            })}
        >
            <Stack gap={4} direction="row" alignItems="center">
                <Stack gap={2} maxWidth={500}>
                    <Typography variant="h4">Set and track targets</Typography>
                    <Typography variant="body2">
                        For each metric, you can set specific targets and see if
                        your campaigns overperform or underperform
                    </Typography>

                    <div>
                        <ActionButton
                            variant="contained"
                            startIcon={<Add />}
                            onClick={() => openDialog({})}
                        >
                            Create your first target
                        </ActionButton>
                    </div>
                </Stack>
            </Stack>
        </Stack>
    );
}
