import { useSnackbar } from 'notistack';
import {
    useOptimisticRemove,
    useOptimisticUpdate,
} from '../../hooks/use-optimistic-update.ts';
import { deleteTarget, putTarget } from '../../api-client/targets.ts';
import { Target } from '../../types/targets';
import { useActiveWorkspaceId } from '../../providers/useActiveWorkspaceId.ts';

export function useTargetMutation() {
    const workspaceId = useActiveWorkspaceId();
    const { enqueueSnackbar } = useSnackbar();

    const { create, isLoading: isUpdating } = useOptimisticUpdate<Target>(
        async target => {
            try {
                await putTarget(workspaceId, target);
            } catch (err) {
                enqueueSnackbar('Failed to update target', {
                    variant: 'error',
                });
                throw err;
            }
        },
        [workspaceId, 'targets'],
        { invalidateOnSettled: true }
    );

    const { remove } = useOptimisticRemove(
        async targetId => {
            await deleteTarget(workspaceId, targetId);
        },
        [workspaceId, 'targets']
    );

    return {
        create,
        update: (target: Target) => create(target),
        isUpdating,
        remove,
    };
}
