import { ValueFormatterParams } from '@ag-grid-community/core';
import { Localization } from '../../../../types/datamodel/localization';

export const percentageFormatter = (
    { locale }: Pick<Localization, 'locale'>,
    formatOptions: Intl.NumberFormatOptions = {}
) => {
    const formatter = new Intl.NumberFormat(locale, {
        style: 'percent',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        ...formatOptions,
    });

    return (params: Pick<ValueFormatterParams, 'value'>) => {
        if (!params?.value || isNaN(params.value)) return '';
        return formatter.format(params.value);
    };
};
