import { Alert, Box, Divider, Typography } from '@mui/material';
import React from 'react';
import { HeaderItem, StyledRow } from './SubscriberList.styles';
import { useSubscriberListData } from './use-subscriber-list-data';
import { Loader } from '../../../Loader/Loader';
import { DivergenceRechart } from './Chart/DivergenceRechart';
import { DeltaRechart } from './Chart/DeltaRechart';
import { CumulativeRechart } from './Chart/CumulativeRechart';
import { ChangeRechart } from './Chart/ChangeRechart';

interface SubscriberListProps {
    filteredLists?: string[];
}

export const SubscriberListRechart = ({
    filteredLists = [],
}: SubscriberListProps) => {
    const { data, isLoading, error } = useSubscriberListData();
    const headerRow = (
        <React.Fragment>
            <HeaderItem>List</HeaderItem>
            <HeaderItem>Members in / out</HeaderItem>
            <HeaderItem>Members Delta</HeaderItem>
            <HeaderItem>Members Cumulative</HeaderItem>
            <HeaderItem textAlign="right">Total</HeaderItem>
        </React.Fragment>
    );

    if (filteredLists.length === 0) {
        return (
            <Alert severity="info">
                No lists selected. Please select lists in the config panel.
            </Alert>
        );
    }

    if (isLoading) {
        return <Loader />;
    }

    if (error || !data) {
        return (
            <Alert severity="error">
                An error occurred while fetching the subscriber lists
            </Alert>
        );
    }

    return (
        <div>
            {filteredLists.map((list, i, arr) => {
                const listData = data[list]?.data || [];

                return (
                    <React.Fragment key={list}>
                        <StyledRow>
                            <>
                                {i === 0 && headerRow}
                                <Box display="flex" alignItems="center">
                                    <Typography
                                        variant="h6"
                                        fontSize="12px"
                                        fontWeight="500"
                                    >
                                        {list}
                                    </Typography>
                                </Box>
                                <DivergenceRechart data={listData} />
                                <DeltaRechart data={listData} />
                                <CumulativeRechart data={listData} />
                                <ChangeRechart data={listData} />
                            </>
                        </StyledRow>
                        {i !== arr.length - 1 && <Divider />}
                    </React.Fragment>
                );
            })}
        </div>
    );
};
