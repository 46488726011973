import { styled, TableCell } from '@mui/material';

export const HighLowTableCell = styled(TableCell, {
    shouldForwardProp: prop => prop !== 'isLast',
})<{
    isLast: boolean;
}>(
    ({ isLast }) => `
    padding: 4px !important;
    border-bottom: ${isLast ? 'none !important' : ''};
`
);
