import axios from 'axios';
import { CampaignCollection } from '../../types/collections';
import { getRustAPIUrl } from '../../utils/api';

export const deleteCampaignCollection = (
    workspaceId: string,
    collectionId: string
) => {
    axios.delete(
        `${getRustAPIUrl()}/workspaces/${workspaceId}/campaign-collections/${collectionId}`
    );
};

export const putCampaignCollection = (
    workspaceId: string,
    collection: CampaignCollection
) => {
    const col = {
        ...collection,
        baselineSet: {
            ...collection.baselineSet,
            targets: collection.baselineSet.targets.map(target => ({
                ...target,
                value: target.value.toString(),
            })),
        },
    };
    axios.put<CampaignCollection>(
        `${getRustAPIUrl()}/workspaces/${workspaceId}/campaign-collections/${
            collection.id
        }`,
        { ...col }
    );
};
