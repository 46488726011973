import { TargetsTableProperties } from './BaselineTargetsTable.tsx';
import { Controller, useFormContext } from 'react-hook-form';
import { CollectionForm } from '../Collections.tsx';
import { InputAdornment, Stack, TextField } from '@mui/material';
import { grey } from '@mui/material/colors';
import { TargetStartAdornment } from './TargetStartAdornment.tsx';
import * as React from 'react';

export const TargetInputColumn = ({
    row,
    disabled,
}: { row: TargetsTableProperties } & { disabled?: boolean }) => {
    const { watch } = useFormContext<CollectionForm>();
    const fields = watch('targets');

    const found = (fields || [])
        .map((field, index) => ({ ...field, index }))
        .find(field => field.id === row.id);

    if (!found) {
        return null;
    }

    return (
        <Stack gap={1}>
            <Controller
                key={`targets-${found.id}-value`}
                render={({ field }) => (
                    <TextField
                        {...field}
                        disabled={disabled}
                        variant="standard"
                        type="number"
                        color="info"
                        size="small"
                        inputProps={{
                            style: {
                                fontSize: '1em',
                                textAlign: 'end',
                            },
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment
                                    sx={{ color: grey[600] }}
                                    position="start"
                                >
                                    <TargetStartAdornment
                                        format={found.format}
                                    />
                                </InputAdornment>
                            ),
                        }}
                    />
                )}
                name={`targets[${found.index}].value`}
            />
        </Stack>
    );
};
