import { FirstDayOfWeekSettings } from './FirstDayOfWeekSettings';
import { ReportingSettings } from './ReportingSettings/ReportingSettings';
import { Page } from '../../components/Page/Page';
import { useFeatureFlag } from '../../hooks/use-feature-flags';
import { ProfileSection } from './ProfileSection';

export const Profile = () => {
    const { flag: showReportingSettings } = useFeatureFlag('weeklySummary');

    return (
        <Page.Container>
            <Page.Content
                padding
                elevation={1}
                sx={({ elevation }) => ({
                    backgroundColor: elevation.background,
                })}
            >
                <ProfileSection.Container>
                    <FirstDayOfWeekSettings />
                    {showReportingSettings && <ReportingSettings />}
                </ProfileSection.Container>
            </Page.Content>
        </Page.Container>
    );
};
