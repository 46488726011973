import { WidgetConfig } from '../../../types/dashboard';
import { CampaignPerformanceWidget } from './CampaignPerformance/CampaignPerformanceWidget';
import { Funnel } from './Funnel/Funnel';
import { SubscriberListWidget } from './SubscriberList/SubscriberListWidget';
import { TableWidget } from './Table/TableWidget';
import { HighLow } from './HighLow/HighLowWidget';
import { GraphWidget } from './Graph/GraphWidget';
import { isEqual } from 'lodash';
import { memo } from 'react';
import { TargetWidget } from './Target/TargetWidget.tsx';

interface WidgetProps {
    config: WidgetConfig;
    canWrite: boolean;
}

// TODO move this functionality to `Widget.tsx` in `components/Dashboard`
export const CampaignPerformanceWidgetType = 'campaign_performance' as const;
export const FunnelWidgetType = 'funnel' as const;
export const TableWidgetType = 'table' as const;
export const SubscriberGrowthType = 'subscriber_growth' as const;
export const HighLowWidgetType = 'high_low' as const;
export const TargetWidgetType = 'target' as const;
const GraphWidgetType = 'graph' as const;

const widgetMapping: any = {
    [CampaignPerformanceWidgetType]: CampaignPerformanceWidget,
    [GraphWidgetType]: GraphWidget,
    [FunnelWidgetType]: Funnel,
    [TableWidgetType]: TableWidget,
    [SubscriberGrowthType]: SubscriberListWidget,
    [HighLowWidgetType]: HighLow,
    [TargetWidgetType]: TargetWidget,
};

const MapWidgetInner = ({ config, canWrite }: WidgetProps) => {
    const Component = widgetMapping[config.type];

    return (
        (Component && <Component {...config} isEditable={canWrite} />) || null
    );
};

export const MapWidget = memo(MapWidgetInner, isEqual);
