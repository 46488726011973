import {
    Badge,
    Card,
    CardContent,
    CardHeader,
    Collapse,
    Stack,
    styled,
    useTheme,
} from '@mui/material';
import { Annotation } from '../../types/annotation';
import {
    formatHumanReadableDate,
    shortDateFormat,
    shortDateRangeFormat,
} from '../../utils/date-format.ts';
import { MoreMenu } from '../MoreMenu/MoreMenu.tsx';
import { ReactNode } from 'react';
import Typography from '@mui/material/Typography';
import { SeverityIndicator } from './SeverityIndicator.tsx';
import { isSameDay } from 'date-fns';

const AnnotationCard = styled(Card)(({ theme }) => ({
    backgroundColor: theme.elevation[2],
}));
AnnotationCard.defaultProps = {
    variant: 'outlined',
};

export const CollapsibleAnnotationCard = ({
    collapsed,
    header,
    content,
}: {
    collapsed: boolean;
    header: ReactNode;
    content: ReactNode;
}) => (
    <AnnotationCard sx={{ borderWidth: collapsed ? 0 : 1 }}>
        <Collapse in={!collapsed}>{header}</Collapse>
        <AnnotationContent collapsed={collapsed}>{content}</AnnotationContent>
    </AnnotationCard>
);

export const AnnotationHeader = ({
    severity,
    title,
    modificationDate,
    avatar,
    hideMenu,
    handleOnMutate,
    handleOnRemove,
}: {
    avatar?: ReactNode;
    severity?: Annotation['severity'];
    title?: string;
    modificationDate?: Date | number;
    hideMenu?: boolean;
    handleOnMutate?: () => void;
    handleOnRemove?: () => void;
}) => {
    const theme = useTheme();

    return (
        <CardHeader
            sx={{
                '.MuiCardHeader-avatar': {
                    alignSelf: 'start',
                    marginTop: 1,
                },
            }}
            avatar={
                avatar ||
                (severity ? (
                    <Badge overlap="circular" variant="dot" badgeContent=" ">
                        <SeverityIndicator severity={severity} />
                    </Badge>
                ) : undefined)
            }
            title={title}
            titleTypographyProps={{
                variant: 'mediumBody',
                color: theme.palette.text.primary,
            }}
            subheader={
                modificationDate &&
                formatHumanReadableDate(new Date(modificationDate))
            }
            subheaderTypographyProps={{
                variant: 'smallMediumBody',
                color: theme.palette.text.primaryLight,
            }}
            action={
                !hideMenu && (
                    <MoreMenu
                        placement="bottom-start"
                        items={[
                            {
                                key: 'edit',
                                label: 'Edit',
                                onClick: handleOnMutate,
                            },
                            {
                                key: 'delete',
                                label: 'Delete',
                                onClick: handleOnRemove,
                            },
                        ]}
                    />
                )
            }
        />
    );
};

export const AnnotationContent = styled(CardContent, {
    shouldForwardProp: prop => prop !== 'collapsed',
})<{ collapsed?: boolean }>(({ theme, collapsed }) => ({
    '&:last-child': {
        paddingBottom: collapsed ? 0 : 8,
    },

    paddingBlock: 0,
    paddingInline: collapsed ? 0 : theme.spacing(2.5),
}));

export const AnnotationMessage = ({
    annotation: { dateStart, dateEnd, message },
}: {
    annotation: Annotation;
}) => {
    const title = isSameDay(dateStart, dateEnd)
        ? shortDateFormat(dateStart)
        : shortDateRangeFormat(dateStart, dateEnd);

    return (
        <Stack>
            <Typography variant="body2" paragraph>
                {message}
            </Typography>
            <Typography variant="smallMediumBody">Period: {title}</Typography>
        </Stack>
    );
};
