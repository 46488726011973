import { useState } from 'react';
import { FormDialog } from '../../../../components/Dialog/FormDialog';
import { Link, Stack, TextField, Typography } from '@mui/material';
import { useConnectCopernica } from './useConnectCopernica';

export const EnableCopernica = ({
    workspaceId,
    integrationId,
    open = false,
    onEnable,
    onClose,
}: {
    workspaceId: string;
    integrationId: string;
    open: boolean;
    onEnable: () => void;
    onClose: () => void;
}) => {
    const [token, setToken] = useState('');
    const [errorMessage, setErrorMessage] = useState<undefined | string>(
        undefined
    );

    const { mutate, isError, isLoading } = useConnectCopernica({
        onSuccess: () => onEnable(),
        onError: () => {
            setErrorMessage(
                'Failed to enable the integration, please check your API token'
            );
        },
    });

    const handleSubmit = () => {
        mutate({ workspaceId, integrationId, token });
    };

    return (
        <FormDialog
            open={open}
            handleClose={onClose}
            handleConfirm={handleSubmit}
            title="Enable Copernica integration"
            error={
                isError &&
                errorMessage && (
                    <Typography
                        variant="overline"
                        marginY={2}
                        textAlign="center"
                        paragraph
                        color="red"
                    >
                        {errorMessage}
                    </Typography>
                )
            }
            content={
                <Stack spacing={2}>
                    <Typography variant="body2">
                        SEINō uses the Copernica REST API to retrieve data about
                        your mailings. In order to access the API, we need an
                        access token.
                    </Typography>

                    <Typography variant="body2">
                        Please create an access token through the{' '}
                        <Link
                            href="https://ms.copernica.com/en#/admin/account/access-tokens"
                            target="_blank"
                        >
                            API access token page
                        </Link>{' '}
                        in Copernica and copy it in the field below.
                    </Typography>
                    <TextField
                        size="small"
                        label="Token"
                        onChange={e => setToken(e.target.value)}
                    />
                </Stack>
            }
            confirmButtonText="Confirm"
            confirmButtonProps={{ loading: isLoading }}
        />
    );
};
