import { AlertCard } from '../../AlertCard/AlertCard.tsx';
import { Typography } from '@mui/material';

export const WidgetError = () => {
    return (
        <AlertCard severity="error">
            <Typography>
                Oops! We encountered an error while rendering this widget.
                Please try adjusting its settings, or refresh the page.
            </Typography>
        </AlertCard>
    );
};
