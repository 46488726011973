import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNotify } from '../../../hooks/use-notify.tsx';
import axios from 'axios';
import { getRustAPIUrl } from '../../../utils/api.ts';
import { membersKey } from './useMembers.tsx';
import { useActiveWorkspaceId } from '../../../providers/useActiveWorkspaceId.ts';

type ChangeRoleArgs = {
    memberId: string;
    role: string;
};

export const useChangeRole = () => {
    const workspaceId = useActiveWorkspaceId();
    const queryClient = useQueryClient();
    const notify = useNotify();

    return useMutation({
        mutationFn: ({ memberId, role }: ChangeRoleArgs) =>
            axios.put(
                `${getRustAPIUrl()}/workspaces/${workspaceId}/members/${memberId}/role`,
                { role }
            ),
        onSuccess: () => {
            notify('Role changed successfully', 'success');
            return queryClient.invalidateQueries(membersKey(workspaceId));
        },
        onError: () => notify('Failed to update role.', 'error'),
    });
};
