import { useQuery } from '@tanstack/react-query';
import { fetchDashboardConfig, updateDashboardConfig } from '../../api-client';
import { quickInsightsDashboardConfig } from '../../types/dashboard/quickInsightsDashboard.ts';
import { defaultDashboardConfig } from '../../types/dashboard';
import { isDemoWorkspace, ModifiedBy } from '../../types/user';
import { useActiveUser } from '../../providers/CurrentUserProvider.hooks.ts';
import { useActiveWorkspaceFromRoute } from '../../providers/useActiveWorkspaceFromRoute.ts';

export const useDashboards = () => {
    const workspace = useActiveWorkspaceFromRoute();
    const { memberId, email, name } = useActiveUser();

    return useQuery(
        ['dashboards', workspace?.id || 'no-workspace'],
        async () => {
            if (!workspace) {
                return undefined;
            }

            const response = await fetchDashboardConfig(workspace.id);

            if (response.length > 0) {
                return response;
            }

            const owner: ModifiedBy = {
                id: memberId,
                name,
                email,
            };

            const dashboard = isDemoWorkspace(workspace)
                ? quickInsightsDashboardConfig(owner)
                : defaultDashboardConfig(owner);

            await updateDashboardConfig(workspace.id, dashboard, dashboard.id);

            return [dashboard];
        }
    );
};
