import { Alert, Link } from '@mui/material';
import { GridApi } from '@ag-grid-community/core';
import { useEffect, useState } from 'react';

interface FiltersAlertProps {
    grid: GridApi<any>;
}

export const FiltersAlert = ({ grid }: FiltersAlertProps) => {
    const [rowCount, setRowCount] = useState(grid.getDisplayedRowCount());
    const [filterModel, setFilterModel] = useState(grid.getFilterModel());

    useEffect(() => {
        const eventListener = () => setRowCount(grid.getDisplayedRowCount());
        grid.addEventListener('modelUpdated', eventListener);
        return () => {
            if (!grid.isDestroyed()) {
                grid.removeEventListener('modelUpdated', eventListener);
            }
        };
    }, [grid]);

    useEffect(() => {
        const eventListener = () => setFilterModel(grid.getFilterModel());
        grid.addEventListener('filterChanged', eventListener);
        return () => {
            if (!grid.isDestroyed()) {
                grid.removeEventListener('filterChanged', eventListener);
            }
        };
    }, [grid]);

    const showAlert =
        rowCount === 0 && Object.keys(filterModel || {}).length > 0;

    if (!showAlert) {
        return null;
    }

    return (
        <Alert severity="warning" hidden={true}>
            There are no rows matching your current filters.{' '}
            <Link href="#" onClick={() => grid.setFilterModel({})}>
                Clear filters
            </Link>
        </Alert>
    );
};
