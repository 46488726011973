import { Autocomplete, Stack, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { ActionButton } from '../../../components/Button/ActionButton.tsx';
import React from 'react';
import { useActiveWorkspace } from '../../../providers/WorkspaceProvider.hooks.ts';
import { useUpdateSettings } from './useUpdateSettings.tsx';

const locales = [
    { id: 'nl_NL', label: 'EU' },
    { id: 'en_GB', label: 'United Kingdom' },
    { id: 'en_US', label: 'United States' },
];
const localeOptions = locales.map(l => l.id);

export const SettingsForm = () => {
    const { workspace } = useActiveWorkspace();
    const disabled = workspace.role !== 'admin';

    const { mutate, isLoading } = useUpdateSettings();
    const { control, register, handleSubmit } = useForm({
        disabled,
        defaultValues: {
            name: workspace.displayName,
            locale: workspace.locale,
        },
    });

    const onSubmit = handleSubmit(data => mutate(data));

    return (
        <form onSubmit={onSubmit}>
            <Stack gap={2} sx={{ maxWidth: '400px' }}>
                <TextField label="Name" {...register('name')} />

                <Controller
                    name="locale"
                    control={control}
                    render={({ field: { ref, onChange, ...props } }) => (
                        <Autocomplete
                            options={localeOptions}
                            onChange={(e, value) => onChange(value)}
                            renderInput={props => (
                                <TextField
                                    {...props}
                                    label="Region"
                                    helperText="Used for date and number / currency formatting."
                                />
                            )}
                            getOptionLabel={key =>
                                locales.find(l => l.id === key)?.label || ''
                            }
                            {...props}
                        />
                    )}
                />

                <div>
                    <ActionButton
                        loading={isLoading}
                        disabled={disabled}
                        onClick={onSubmit}
                    >
                        Save
                    </ActionButton>
                </div>
            </Stack>
        </form>
    );
};
