import { GetState, SetState, StateCreator } from 'zustand';
import { InitialData, Workspace } from '../../types/user';

type AuthenticationState =
    | 'initializing' // Waiting for Auth0 SDK
    | 'authenticating' // Retrieving access token + workspaces
    | 'authenticated_without_workspace' // Signed in, but user is not linked to any workspaces
    | 'authenticated' // Signed in for specific workspace
    | 'error'; // An error occurred while authenticating, see `error` state property

export interface UserStore {
    visitLogged: boolean;
    setVisitLogged: (logged: boolean) => void;
    authState: AuthenticationState;
    error: Error | null;
    tokenDeprecated: string;
    workspaces: Workspace[];
    isAccountAdmin: boolean;
    onboardingRequired: boolean;
    currentWorkspaceId: string;
    memberId: string;
    setCurrentWorkspaceId: (id: string) => void;

    authenticate: (initialData: InitialData) => void;

    switchWorkspace: (workspaceId: string) => void;
}

const initialWorkspaceId = window.localStorage.getItem('currentOrgid') || '';

const setTitle = (
    currentWorkspaceId: string,
    workspaces: Workspace[]
): void => {
    const currentWorkspace = workspaces.find(
        ws => ws.id === currentWorkspaceId
    );

    if (currentWorkspace) {
        document.title = `${currentWorkspace.displayName} | SEINō`;
    }
};

export const userStore: StateCreator<UserStore> = (
    set: SetState<UserStore>,
    get: GetState<UserStore>
) => ({
    authState: 'initializing',
    visitLogged: false,
    setVisitLogged: (logged: boolean) => {
        set(state => ({
            ...state,
            visitLogged: logged,
        }));
    },
    error: null,
    tokenDeprecated: '',
    workspaces: [],
    isAccountAdmin: false,
    onboardingRequired: false,
    currentWorkspaceId: initialWorkspaceId,
    setCurrentWorkspaceId: (workspaceId: string) => {
        set(state => ({
            ...state,
            currentWorkspaceId: workspaceId,
        }));
        setTitle(workspaceId, get().workspaces);
        window.localStorage.setItem('currentOrgid', workspaceId);
    },
    memberId: '',
    authenticate: (initialData: InitialData) => {
        // Set currentWorkspaceId to first workspace if
        //   A) there are initialData
        //   B) no currentWorkspaceId was found in local storage
        //   C) currentWorkspaceId was found in localStorage, but does not match any of the linked initialData
        const { workspaces, onboardingRequired, memberId } = initialData;

        const currentWorkspaceId =
            workspaces.length > 0 &&
            (!initialWorkspaceId ||
                !workspaces.map(ws => ws.id).includes(initialWorkspaceId))
                ? workspaces[0].id
                : initialWorkspaceId;

        set({
            workspaces,
            memberId,
            onboardingRequired,
        });

        get().setCurrentWorkspaceId(currentWorkspaceId);
    },

    switchWorkspace: workspaceId => {
        setTitle(workspaceId, get().workspaces);
        get().setCurrentWorkspaceId(workspaceId);
    },
});
