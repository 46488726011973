import { useQuery } from '@tanstack/react-query';
import { getTargets } from '../../api-client/targets.ts';
import { Target } from '../../types/targets';
import { orderBy } from 'lodash';
import { useActiveWorkspaceId } from '../../providers/useActiveWorkspaceId.ts';

export function useTargets() {
    const workspaceId = useActiveWorkspaceId();

    return useQuery<Target[]>({
        queryKey: [workspaceId, 'targets'],
        queryFn: async () => await getTargets(workspaceId),
        select: data => orderBy(data, ['dateEnd', 'title'], ['desc', 'asc']),
    });
}
