// Workaround to hide additional dropdown caret
export const autocompleteComponentProps = {
    popupIndicator: {
        sx: {
            display: 'none',
            '&:active': {
                backgroundColor: '#f5f5f5',
            },
        },
    },
    popper: {
        sx: {
            '& .MuiAutocomplete-option[aria-selected="true"]': {
                backgroundColor: 'transparent',
            },
        },
    },
};
