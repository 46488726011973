import {
    Box,
    BoxProps,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogTitleProps,
    IconButton,
    IconButtonProps,
    Stack,
    styled,
    Tab as MuiTab,
    Tabs,
} from '@mui/material';
import * as React from 'react';
import { PropsWithChildren, SyntheticEvent } from 'react';
import { Close } from '@mui/icons-material';
import { grey } from '@mui/material/colors';

import { useDialog } from './useDialog.ts';

export const SeinoDialogContent = styled(DialogContent, {
    shouldForwardProp: propName => propName !== 'disablePadding',
})<{ disablePadding?: boolean }>(({ theme, disablePadding }) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1.5),

    ...(disablePadding && {
        padding: 0,
    }),
}));
export const SeinoDialogContainer = styled(Box)({
    padding: 0,
    display: 'flex',
    flex: 1,
});

const DialogTabs = styled(Tabs)(() => ({
    width: '340px',
    paddingInline: 0,
    paddingTop: '1em',
    paddingBottom: '1em',
    backgroundColor: grey[100],
}));
export const SeinoDialogTabs = ({
    value,
    onChange,
    children,
}: PropsWithChildren<{
    value: any;
    onChange?: (event: SyntheticEvent, value: any) => void;
}>) => (
    <DialogTabs
        TabIndicatorProps={{
            sx: {
                backgroundColor: 'unset',
            },
        }}
        orientation="vertical"
        value={value}
        onChange={onChange}
    >
        {children}
    </DialogTabs>
);

export function SeinoDialogTabPanel({
    children,
    value,
    id,
    ...boxProps
}: PropsWithChildren<{ id: string; value?: string } & BoxProps>) {
    return (
        <Box
            width="100%"
            padding={1}
            role="tabpanel"
            hidden={value === undefined || value !== id}
            id={`vertical-tabpanel-${id}`}
            aria-labelledby={`vertical-tab-${id}`}
            {...boxProps}
        >
            {value === id && children}
        </Box>
    );
}

export const SeinoDialogClose = (props: IconButtonProps) => {
    const { closeDialog } = useDialog();

    return (
        <IconButton
            aria-label="close"
            onClick={closeDialog}
            sx={theme => ({
                color: theme.palette.grey[500],
            })}
            {...props}
        >
            <Close />
        </IconButton>
    );
};

export const SeinoDialogTitle = (
    props: { disableClose?: boolean } & DialogTitleProps
) => {
    const { children, disableClose, ...other } = props;

    return (
        <DialogTitle
            display="flex"
            justifyContent="space-between"
            sx={{
                padding: 0.4,
                position: 'relative',
            }}
            {...other}
        >
            {children}
            {!disableClose && <SeinoDialogClose />}
        </DialogTitle>
    );
};

/**
 * Hack to get all dialog tabs to have the same height.
 * @todo find better solution for this.
 */
export const TabbedDialogActionPlaceholder = () => (
    <DialogActions>
        <Button size="small" sx={{ visibility: 'hidden' }}>
            &nbsp;
        </Button>
    </DialogActions>
);

export const TabbedDialogSection = ({ children }: PropsWithChildren) => (
    <Stack width="100%" py={1}>
        {children}
    </Stack>
);

export const TabbedDialogContent = ({ children }: PropsWithChildren) => (
    <DialogContent
        sx={{
            minHeight: '60vh',
            maxHeight: '60vh',
            overflow: 'auto',
            px: 0.4,
            py: 1,
        }}
    >
        <Stack gap={3}>{children}</Stack>
    </DialogContent>
);

export const SeinoDialogTab = styled(MuiTab)(({ theme }) => ({
    alignItems: 'flex-start',
    color: theme.palette.primary.main,
    minHeight: 6,

    fontWeight: theme.typography.fontWeightMedium,
    '&.Mui-selected': {
        color: theme.palette.primary.main,
        backgroundColor: grey[200],
        fontWeight: theme.typography.fontWeightBold,
    },
    '&:hover': {
        backgroundColor: grey[200],
    },
}));
