import {
    Collapse,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    ListSubheader,
} from '@mui/material';
import styled from 'styled-components';
import { ReactElement } from 'react';
import { theme } from '../../theme';

const StyledList = styled(List)
    .withConfig({
        shouldForwardProp: property => !['nested'].includes(property),
    })
    .attrs<{ nested: boolean }>(({ nested = false }) => ({
        disablePadding: true,
        sx: {
            paddingLeft: nested ? theme.spacing(6) : 0,
        },
    }))<{ nested?: boolean }>`
`;

const StyledListItem = styled(ListItem).attrs({
    disablePadding: true,
})``;

interface NestedTab {
    id: string;
    label: string;
    selected?: boolean;
    onClick?: (tabId: string) => void;
}

interface Tab {
    id: string;
    primary: string | ReactElement;
    secondary?: string | ReactElement;
    selected?: boolean;
    icon: ReactElement;
    onClick?: (tabId: string) => void;
    nestedTabs?: NestedTab[];
}

interface Section {
    id: string;
    title?: string | ReactElement;
    tabs: Tab[];
}

interface MenuProps {
    sections: Section[];
}

export const Menu = ({ sections }: MenuProps) => (
    <>
        {sections.map(section => (
            <StyledList
                key={`list-${section.id}`}
                subheader={
                    section.title && (
                        <ListSubheader sx={{ pl: 1 }}>
                            {section.title}
                        </ListSubheader>
                    )
                }
            >
                {section.tabs.map(tab => (
                    <div key={`list-item-${tab.id}`}>
                        {tab.onClick ? (
                            <StyledListItem>
                                <ListItemButton
                                    dense
                                    selected={tab.selected}
                                    onClick={() => tab.onClick?.(tab.id)}
                                >
                                    <ListItemIcon>{tab.icon}</ListItemIcon>
                                    <ListItemText
                                        primary={tab.primary}
                                        secondary={tab.secondary}
                                    />
                                </ListItemButton>
                            </StyledListItem>
                        ) : (
                            <ListItem>
                                <ListItemIcon>{tab.icon}</ListItemIcon>
                                <ListItemText
                                    primary={tab.primary}
                                    secondary={tab.secondary}
                                />
                            </ListItem>
                        )}
                        {tab.nestedTabs && (
                            <Collapse
                                key={`collapse-${tab.id}`}
                                in={true}
                                timeout="auto"
                                unmountOnExit
                            >
                                <StyledList
                                    key={`list-${section.id}-${tab.id}`}
                                >
                                    {tab.nestedTabs.map(nestedTab => (
                                        <StyledListItem
                                            key={`tab-${tab.id}-${nestedTab.id}`}
                                        >
                                            <ListItemButton
                                                sx={{ pl: theme.spacing(8) }}
                                                selected={nestedTab.selected}
                                                onClick={() =>
                                                    nestedTab.onClick?.(
                                                        nestedTab.id
                                                    )
                                                }
                                            >
                                                <ListItemText
                                                    primary={nestedTab.label}
                                                />
                                            </ListItemButton>
                                        </StyledListItem>
                                    ))}
                                </StyledList>
                            </Collapse>
                        )}
                    </div>
                ))}
            </StyledList>
        ))}
    </>
);
