import { GetState, SetState, StateCreator } from 'zustand';
import { DateRanges } from '../../components/DateRangePicker/reducer';
import { defaultDateRanges } from '../../hooks/use-date-range.ts';

export interface DataGridStore {
    dateRanges: DateRanges;
    setDateRanges: (dateRanges: DateRanges) => void;
    darkMode: boolean;
    setDarkMode: (darkMode: boolean) => void;
    currentViewIds: Record<string, string | undefined>;
    getCurrentViewId: (workspaceId: string) => string | undefined;
    setCurrentViewId: (workspaceId: string, viewId: string | undefined) => void;
}

export const dataGridStore: StateCreator<DataGridStore> = (
    set: SetState<DataGridStore>,
    get: GetState<DataGridStore>
) => ({
    dateRanges: defaultDateRanges(),
    setDateRanges: (dateRanges: DateRanges) => set({ dateRanges: dateRanges }),
    darkMode: JSON.parse(window.localStorage.getItem('darkMode') || 'false'),
    setDarkMode: darkMode => {
        set({ darkMode });
    },
    currentViewIds: {},
    getCurrentViewId: workspaceId => get().currentViewIds[workspaceId],
    setCurrentViewId: (workspaceId, currentViewId) => {
        set({ currentViewIds: { [workspaceId]: currentViewId } });
    },
});
