import { ulid } from 'ulid';
import { BinaryFilter } from '../../queries/filters';
import { DateRanges } from '../../components/DateRangePicker/reducer.tsx';
import { arrayMove } from '@dnd-kit/sortable';
import type { UniqueIdentifier } from '@dnd-kit/core/dist/types';
import { ModifiedBy } from '../user';
import { AccessLevel } from '../permission/permission.ts';

interface DashboardUser {
    id: string;
    name: string;
    email: string;
}

export interface DashboardConfig {
    id: string;
    config: {
        title: string;
        widgets: WidgetConfig[];
        dateRanges?: DateRanges<string>;
    };
    createdAt: number;
    createdBy?: DashboardUser;
    modifiedAt: number;
    modifiedBy?: ModifiedBy;
    accessLevel: AccessLevel;
    version: number;
}

export interface WidgetConfig {
    id: string;
    type: WidgetType;
    dataset: string;
    title: string;
    modified: number;
    metricsByDimensions?: MetricByDimension[];
    metrics: string[];
    funnelMetrics?: string[];
    conversionMetric?: string;
    filters?: BinaryFilter[];
    filteredLists?: string[];
    targets?: string[];
    dimensions?: string[];
    dimension?: string;
    groupBy?: string[];
}

export type MetricByDimension = {
    metric: string;
    dimension: string;
    filters?: BinaryFilter[];
};

export const fixedConversionMetrics = [
    'sent_unique',
    'delivered',
    'open',
    'clicks_unique',
];

export type WidgetType =
    | 'campaign_performance'
    | 'graph'
    | 'funnel'
    | 'table'
    | 'high_low'
    | 'subscriber_growth'
    | 'target';

export const createDashboard = (
    title: string,
    owner: DashboardUser,
    initialConfig?: DashboardConfig
): DashboardConfig => {
    const dashboard = initialConfig || defaultDashboardConfig(owner);

    return {
        ...dashboard,
        config: {
            ...dashboard.config,
            title,
        },
        createdBy: owner,
    };
};

export const duplicate = (
    fromDashboard: DashboardConfig,
    currentUser: ModifiedBy
): DashboardConfig => {
    return {
        id: ulid(),
        version: 1,
        createdAt: Date.now(),
        createdBy: currentUser,
        modifiedAt: Date.now(),
        modifiedBy: currentUser,
        accessLevel: 'full_access',
        config: {
            ...fromDashboard.config,
        },
    };
};

export const setTitle = (dashboard: DashboardConfig, title: string) => ({
    ...dashboard,
    config: { ...dashboard.config, title },
});

export const addWidget = (
    dashboard: DashboardConfig,
    newWidget: WidgetConfig
) => ({
    ...dashboard,
    config: {
        ...dashboard.config,
        widgets: [newWidget, ...dashboard.config.widgets],
    },
});

export const updateWidget = (
    dashboard: DashboardConfig,
    newWidget: WidgetConfig
) => ({
    ...dashboard,
    config: {
        ...dashboard.config,
        widgets: dashboard.config.widgets.map(oldWidget =>
            oldWidget.id === newWidget.id ? newWidget : oldWidget
        ),
    },
});

export const updateWidgets = (
    dashboard: DashboardConfig,
    updatedWidgets: WidgetConfig[]
) => ({
    ...dashboard,
    config: {
        ...dashboard.config,
        widgets: updatedWidgets,
    },
});

export const deleteWidget = (dashboard: DashboardConfig, id: string) => ({
    ...dashboard,
    config: {
        ...dashboard.config,
        widgets: dashboard.config.widgets.filter(w => w.id !== id),
    },
});

export const setDateRanges = (
    dashboard: DashboardConfig,
    dateRanges: DateRanges<string>
) => ({
    ...dashboard,
    config: {
        ...dashboard.config,
        dateRanges,
    },
});

export const reorderWidgets = (
    dashboard: DashboardConfig,
    activeId: UniqueIdentifier,
    overId: UniqueIdentifier | null
) => {
    const { widgets } = dashboard.config;

    const oldIndex = widgets.indexOf(
        widgets.find(({ id }) => id === activeId) as WidgetConfig
    );
    const newIndex = widgets.indexOf(
        widgets.find(({ id }) => id === overId) as WidgetConfig
    );

    if (oldIndex > -1 && newIndex > -1) {
        dashboard.config.widgets = arrayMove(widgets, oldIndex, newIndex);
    }

    return dashboard;
};

const defaultPerformanceWidget = (): WidgetConfig => ({
    id: ulid(),
    dataset: 'campaign_metrics_by_send_date',
    type: 'campaign_performance',
    title: 'Performance overview',
    modified: new Date().getTime(),
    metrics: ['sent_unique', 'open_rate', 'cto_unique', 'sessions', 'revenue'],
});

const defaultGraphWidget = (): WidgetConfig => ({
    id: ulid(),
    dataset: 'campaign_metrics_by_send_date',
    type: 'graph',
    title: 'Revenue Graph',
    modified: new Date().getTime(),
    metrics: ['revenue'],
});

const defaultFunnelWidget = (): WidgetConfig => ({
    id: ulid(),
    dataset: 'campaign_metrics_by_send_date',
    type: 'funnel',
    title: 'Funnel',
    modified: new Date().getTime(),
    metrics: [],
    funnelMetrics: [...fixedConversionMetrics, 'transactions'],
});

const defaultHighLowWidget = (): WidgetConfig => ({
    id: ulid(),
    dataset: 'campaign_metrics_by_send_date',
    type: 'high_low',
    title: 'High / Low',
    modified: new Date().getTime(),
    metrics: [],
    metricsByDimensions: [
        {
            metric: '',
            dimension: '',
            filters: [],
        },
    ],
});

const defaultTableWidget = (): WidgetConfig => ({
    id: ulid(),
    dataset: 'campaign_metrics_by_send_date',
    type: 'table',
    title: 'Table',
    modified: new Date().getTime(),
    metrics: ['sent_unique', 'open', 'clicks_unique', 'transactions'],
});

const defaultSubscriberGrowthWidget = (): WidgetConfig => ({
    id: ulid(),
    dataset: 'list_members',
    type: 'subscriber_growth',
    title: 'Subscriber Growth',
    modified: new Date().getTime(),
    metrics: [
        'Campaigns.metric_email_sent_unique_sum',
        'Campaigns.metric_email_open_unique_sum',
        'Campaigns.metric_email_clicks_unique_sum',
        'Campaigns.metric_website_transactions_sum',
    ],
});

const defaultTargetWidget = (): WidgetConfig => ({
    id: ulid(),
    dataset: 'campaign_metrics_by_send_date',
    type: 'target',
    title: 'Targets',
    modified: new Date().getTime(),
    metrics: [],
    targets: [],
});

const defaultWidgets = {
    campaign_performance: defaultPerformanceWidget,
    graph: defaultGraphWidget,
    funnel: defaultFunnelWidget,
    high_low: defaultHighLowWidget,
    table: defaultTableWidget,
    subscriber_growth: defaultSubscriberGrowthWidget,
    target: defaultTargetWidget,
};

export const defaultWidgetOfType = (type: WidgetType) => {
    return defaultWidgets[type]();
};

export const defaultDashboardConfig = (
    currentUser: ModifiedBy
): DashboardConfig => ({
    id: ulid(),
    config: {
        title: 'Default Dashboard',
        widgets: [defaultPerformanceWidget()],
    },
    version: 1,
    accessLevel: 'full_access',
    modifiedAt: Date.now(),
    modifiedBy: currentUser,
    createdAt: Date.now(),
    createdBy: currentUser,
});
