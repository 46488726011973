import { DeleteOutline } from '@mui/icons-material';
import { Grid, IconButton, TextFieldVariants } from '@mui/material';
import {
    BinaryFilter,
    BinaryOperator,
    isEqualsOperator,
    isOutlierOperator,
    isSetOperator,
} from '../../queries/filters';
import { FilterBuilderOperator } from './FilterBuilderOperator';
import { FilterBuilderField } from './FilterBuilderField';
import { FilterBuilderValue } from './FilterBuilderValue';

import { useFilterBuilderContext } from './useFilterBuilderContext.tsx';

interface FilterBuilderRowProps {
    filter: BinaryFilter;
    index: number;
    onChange: (filter: BinaryFilter) => void;
    onDelete: () => void;
    variant?: TextFieldVariants;
}

const getDefaultValue = (op: BinaryOperator) =>
    isOutlierOperator(op) ? ['2.0'] : [];

export const FilterBuilderRow = ({
    filter,
    index,
    onChange,
    onDelete,
    variant,
}: FilterBuilderRowProps) => {
    const { fieldType } = useFilterBuilderContext();

    const handleChangeField = (value: string | undefined) =>
        onChange(
            fieldType === 'dimensions'
                ? { ...filter, member: value, values: [] }
                : { ...filter, member: value }
        );

    const handleChangeOperator = (operator: BinaryOperator) => {
        const clearValues =
            (fieldType !== 'metrics' &&
                isEqualsOperator(operator) &&
                !isEqualsOperator(filter.operator)) ||
            (isSetOperator(operator) && !isSetOperator(filter.operator)) ||
            isOutlierOperator(operator) !== isOutlierOperator(filter.operator);

        onChange({
            ...filter,
            operator,
            values: clearValues ? getDefaultValue(operator) : filter.values,
        });
    };

    const handleChangeValues = (values: string[] | null) =>
        onChange({ ...filter, values: values || [] });

    return (
        <Grid container spacing={1} alignItems="center">
            <Grid item xs={12} sm={fieldType === 'dimensions' ? 3 : 4.5}>
                <FilterBuilderField
                    filter={filter}
                    index={index}
                    variant={variant}
                    onChange={handleChangeField}
                />
            </Grid>
            <Grid item xs={12} sm={fieldType === 'dimensions' ? 3 : 4.5}>
                <FilterBuilderOperator
                    value={filter.operator}
                    variant={variant}
                    onChange={handleChangeOperator}
                />
            </Grid>
            <Grid item xs={12} sm={fieldType === 'dimensions' ? 5 : 2}>
                <FilterBuilderValue
                    value={filter.values.map(x => `${x}`)}
                    field={filter.member || ''}
                    operator={filter.operator}
                    variant={variant}
                    onChange={handleChangeValues}
                    index={index}
                />
            </Grid>
            <Grid item xs={12} sm={1}>
                <IconButton
                    onClick={onDelete}
                    size="small"
                    sx={theme => ({
                        borderRadius: 1,
                        background: theme.elevation[2],
                    })}
                >
                    <DeleteOutline />
                </IconButton>
            </Grid>
        </Grid>
    );
};
