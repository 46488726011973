import { ValueFormatterParams } from '@ag-grid-community/core';
import { Localization } from '../../../../types/datamodel/localization';

export const numberFormatter = ({ locale }: Pick<Localization, 'locale'>) => {
    const formatter = new Intl.NumberFormat(locale);
    return (params: ValueFormatterParams) => {
        if (!params?.value || isNaN(params?.value)) return '';
        return formatter.format(params.value);
    };
};
