import { useContext } from 'react';
import { DuckContext, DuckContextProps } from '../providers/duck';
import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AsyncDuckDBConnection } from '@duckdb/duckdb-wasm';
import { identity } from 'lodash';

type DuckQuery = (
    duck: DuckContextProps
) => ReturnType<AsyncDuckDBConnection['query']>;

export const useDuckQuery = <TRow = any, TResult = TRow[]>(
    queryKey: QueryKey,
    queryFn: DuckQuery,
    queryOptions: UseQueryOptions<TResult> = {},
    processResult: (rows: TRow[]) => TResult = identity
) => {
    const duck = useContext(DuckContext);
    const enabled =
        !!duck &&
        (typeof queryOptions.enabled === 'undefined' || queryOptions.enabled);

    return useQuery(
        queryKey,
        async (): Promise<TResult> => {
            if (!duck) {
                return Promise.reject('DuckDB is surprisingly not initialized');
            }

            return processResult((await queryFn(duck)).toArray());
        },
        { enabled, ...queryOptions, retry: false }
    );
};
