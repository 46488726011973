import { CollectionForm } from '../Collections';
import { useFormContext } from 'react-hook-form';
import { Table } from '../../Table/Table';
import { Baseline } from '../../../types/baselines';
import { Columns } from '../../Table/types';
import { getTargetInputColumn } from './getTargetInputColumn';
import { getRemoveBaselineColumn } from './getRemoveBaselineColumn';
import { Stack, Typography } from '@mui/material';
import { useActiveExplorerView } from '../../../providers/ExplorerViewProvider.hooks';
import { isPermitted } from '../../../types/permission/permission';

type TargetsTableProperties = Baseline;

const columns: Columns<TargetsTableProperties> = [
    {
        accessor: 'id',
        isVisible: false,
    },
    {
        accessor: 'metric',
        title: 'Metrics',
        isClickable: false,
        render: row => {
            return (
                <Stack>
                    <Typography variant="body2">{row.metric}</Typography>
                    <Typography variant="caption">{row.category}</Typography>
                </Stack>
            );
        },
    },
];

export const BaselineTargetsTableForm = () => {
    const { watch } = useFormContext<CollectionForm>();
    const fields = watch('targets') || [];
    const { accessLevel } = useActiveExplorerView();

    const orderedFields = fields.sort(
        (a, b) =>
            a.category.localeCompare(b.category) ||
            a.metric.localeCompare(b.metric)
    );

    const hasEditRights = isPermitted(accessLevel, 'write');
    let columnsWithInputValue: Columns<TargetsTableProperties> = [
        ...columns,
        getTargetInputColumn(hasEditRights),
    ];

    if (hasEditRights) {
        columnsWithInputValue = [
            ...columnsWithInputValue,
            getRemoveBaselineColumn(),
        ];
    }

    if (orderedFields.length < 1) {
        return null;
    }

    return (
        <Table<TargetsTableProperties>
            columns={columnsWithInputValue}
            rows={orderedFields}
        />
    );
};
