import { useStoredDashboard } from './useStoredDashboard.ts';
import { useEffect } from 'react';

export function useSetSelectedDashboardId(
    workspaceId: string,
    dashboardId: string | undefined
) {
    const { setStoredDashboardId } = useStoredDashboard(workspaceId);

    useEffect(() => {
        setStoredDashboardId(dashboardId);
        /* eslint-disable react-hooks/exhaustive-deps */
    }, [workspaceId, dashboardId]);
}
