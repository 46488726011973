import { styled, Typography } from '@mui/material';

export const Heading2 = styled(Typography)(({ theme }) => ({
    fontSize: 24,
    borderBottom: `1px solid ${theme.border.medium}`,
}));

Heading2.defaultProps = {
    ...Heading2.defaultProps,
    variant: 'h2',
    fontWeight: 'light',
};
