import { Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Columns } from '../../../components/Table/types.ts';
import * as React from 'react';
import { useMemo } from 'react';
import { Table } from '../../../components/Table';
import { SearchInput, useSearch } from '../../../components/Search';
import { useActiveUser } from '../../../providers/CurrentUserProvider.hooks.ts';
import { Workspace } from '../../../types/user';
import { useActiveWorkspace } from '../../../providers/WorkspaceProvider.hooks.ts';
import { CreateWorkspaceDialog } from './CreateWorkspaceDialog.tsx';
import { useToggle } from 'usehooks-ts';
import { ActionButton } from '../../../components/Button/ActionButton.tsx';

type TableProps = Pick<Workspace, 'id' | 'displayName' | 'status'>;
const columns: Columns<TableProps> = [
    { accessor: 'id', isVisible: false },
    {
        accessor: 'displayName',
        title: 'Name',
        searchable: true,
        isClickable: true,
    },
    {
        accessor: 'status',
        title: 'Status',
        align: 'right',
        cellStyling: () => ({ textTransform: 'capitalize' }),
    },
];

export const WorkspaceList = ({ all }: { all?: boolean }) => {
    const { workspace } = useActiveWorkspace();
    const user = useActiveUser();
    const navigate = useNavigate();
    const [isCreateDialogOpen, toggleCreateDialog] = useToggle(false);

    const licenseWorkspaces = useMemo(
        () =>
            all
                ? user.workspaces
                : user.workspaces.filter(
                      w => w.licenseId === workspace.licenseId
                  ),
        [all, user.workspaces, workspace.licenseId]
    );

    const handleOnRowClick = ({ id }: TableProps) =>
        navigate(`/workspaces/${id}/settings/general`);

    const { setSearch, search, results } = useSearch(licenseWorkspaces);

    return (
        <Stack gap={1}>
            <Stack direction="row" justifyContent="space-between" gap={1}>
                <SearchInput setSearch={setSearch} />
                <ActionButton onClick={toggleCreateDialog}>Create</ActionButton>
            </Stack>

            <Table<TableProps>
                columns={columns}
                defaultOrderBy="displayName"
                rows={results}
                searchQuery={search}
                onRowClick={handleOnRowClick}
            />

            <CreateWorkspaceDialog
                license={!all && workspace.licenseId ? workspace : undefined}
                open={isCreateDialogOpen}
                onClose={toggleCreateDialog}
            />
        </Stack>
    );
};
