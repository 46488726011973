import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ArrowForward, PlayCircleOutline } from '@mui/icons-material';
import {
    AccordionActions,
    Box,
    Button,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Stack,
    styled,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { Story, useGettingStartedSteps } from './useGettingStartedSteps';
import { Link, useSearchParams } from 'react-router-dom';
import { match } from 'ts-pattern';

const Title = styled(Typography, {
    shouldForwardProp: prop => prop !== 'active',
})<{
    active?: boolean;
}>(({ theme: { palette }, active }) => ({
    color: active ? palette.primary.main : palette.primary.light,
}));
Title.defaultProps = {
    variant: 'smallBoldBody',
};

const Description = styled(Typography)``;
Description.defaultProps = {
    variant: 'mediumBody',
    paragraph: true,
};

const InteractiveDemoDescription = styled(Typography)``;
InteractiveDemoDescription.defaultProps = {
    variant: 'smallBoldBody',
};

export default function GettingStartedSteps() {
    const { steps, activeStep, currentPage, setActiveStep } =
        useGettingStartedSteps();
    const [searchParams] = useSearchParams();

    const theme = useTheme();

    const isXs = useMediaQuery(theme.breakpoints.down('xs'));
    const isSm = useMediaQuery(theme.breakpoints.between('sm', 'md'));
    const isMd = useMediaQuery(theme.breakpoints.between('md', 'lg'));
    const isLg = useMediaQuery(theme.breakpoints.between('lg', 'xl'));
    const isXl = useMediaQuery(theme.breakpoints.up('xl'));

    const width = match(true as boolean)
        .with(isXs, () => theme.breakpoints.values.xs)
        .with(isSm, () => theme.breakpoints.values.sm)
        .with(isMd, () => theme.breakpoints.values.md)
        .with(isLg, () => theme.breakpoints.values.lg)
        .with(isXl, () => theme.breakpoints.values.xl)
        .otherwise(() => theme.breakpoints.values.md);

    const handleChange = (panel: string) => () => {
        setActiveStep(activeStep?.id !== panel ? panel : undefined);
    };

    const handleStoryOnClick = (story: Story) => {
        window.Storylane.Play({
            type: 'popup',
            demo_type: 'image',
            demo_url: story.cta,
            width: width,
            height: '100%',
            scale: '0.95',
        });
    };

    return (
        <Box>
            {steps.map(step => (
                <Accordion
                    key={step.id}
                    expanded={activeStep?.id === step.id}
                    onChange={handleChange(step.id)}
                    sx={theme => ({
                        backgroundColor:
                            activeStep?.id === step.id
                                ? theme.elevation[2]
                                : theme.elevation.base,
                        borderBottom: `1px solid ${theme.border.light}`,
                    })}
                >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Stack spacing={2} direction="row" alignItems="center">
                            <Box
                                sx={theme => ({
                                    color:
                                        activeStep?.id === step.id
                                            ? theme.palette.primary.main
                                            : theme.palette.primary.light,
                                })}
                            >
                                {step.icon}
                            </Box>
                            <Title active={activeStep?.id === step.id}>
                                {step.label}
                            </Title>
                        </Stack>
                    </AccordionSummary>
                    <AccordionDetails sx={{ paddingInline: 2 }}>
                        <Description>{step.description}</Description>

                        {step.stories.length > 0 && (
                            <Box mt={1}>
                                <List dense>
                                    {step.stories.map(story => (
                                        <ListItemButton
                                            sx={{ paddingBlock: 0.2 }}
                                            key={story.key}
                                            onClick={() =>
                                                handleStoryOnClick(story)
                                            }
                                        >
                                            <ListItemIcon
                                                sx={{ minWidth: '30px' }}
                                            >
                                                <PlayCircleOutline fontSize="extraSmall" />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={story.label}
                                            />
                                        </ListItemButton>
                                    ))}
                                </List>
                            </Box>
                        )}
                    </AccordionDetails>
                    {currentPage?.id !== activeStep?.id && (
                        <AccordionActions>
                            <Button
                                variant="outlined"
                                endIcon={<ArrowForward fontSize="extraSmall" />}
                                size="extraSmall"
                                component={Link}
                                to={{
                                    pathname: step.ctaButton.href,
                                    search: searchParams.toString(),
                                }}
                            >
                                {step.ctaButton.label}
                            </Button>
                        </AccordionActions>
                    )}
                </Accordion>
            ))}
        </Box>
    );
}
