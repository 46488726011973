import { Annotation } from '../../types/annotation';
import { Box } from '@mui/material';

export const SeverityIndicator = ({
    severity,
}: {
    severity: Annotation['severity'];
}) => (
    <Box
        component="span"
        sx={theme => ({
            backgroundColor:
                severity === 'note'
                    ? theme.palette.info.light
                    : theme.palette.error.light,
            width: 10,
            height: 10,
            borderRadius: '50%',
        })}
    />
);
