import { DataModelSchema } from '../datamodel/schema';
import { ExplorerView } from '../../components/ExplorerGrid/grid/utils/gridView';
import { createColumnWidthCalculator } from '../../components/ExplorerGrid/grid/columnWidths';
import { v4 as uuidv4 } from 'uuid';
import { ColumnState } from '@ag-grid-community/core';
import { ulid } from 'ulid';
import { InitialData, ModifiedBy } from '../user';
import { DatasetWithSchema } from '../../api-client/datasets';
import { saveView } from '../../api-client';
import { isField } from '../../queries/schema.ts';

export const newView = (
    title: string,
    dataset: string,
    schema: DataModelSchema,
    currentUser: ModifiedBy,
    groupBy: string[] = []
): ExplorerView => {
    const columnWidthCalculator = createColumnWidthCalculator();

    return {
        id: ulid(),
        title,
        modifiedAt: Date.now(),
        modifiedBy: currentUser,
        createdAt: Date.now(),
        createdBy: currentUser,
        version: 1,
        accessLevel: 'full_access',
        config: {
            dataset,
            pivotMode: false,
            filterModel: [],
            columns: schema.fields
                .concat(
                    schema.fields
                        .find(f =>
                            f.features?.includes('explorer_master_detail')
                        )
                        ?.children?.filter(child =>
                            child.features?.includes(
                                'explorer_master_detail_root'
                            )
                        ) || []
                )
                .map(
                    ({ id, name, features }): ColumnState => ({
                        hide:
                            !features?.includes('explorer_default_field') ||
                            groupBy.includes(id),
                        colId: id,
                        width: columnWidthCalculator(name),
                        rowGroup: groupBy.includes(id),
                        pivot: false,
                        pinned: false,
                        sort: groupBy.includes(id) ? 'asc' : null,
                    })
                ),
            valueColumns: [],
            kpiData: {},
        },
    };
};

export const duplicateView = (
    fromView: ExplorerView,
    currentUser: ModifiedBy
): ExplorerView => ({
    id: uuidv4(),
    version: 1,
    title: fromView.title + ' (Copy)',
    createdAt: Date.now(),
    createdBy: currentUser,
    modifiedAt: Date.now(),
    modifiedBy: currentUser,
    accessLevel: 'full_access',
    config: {
        ...fromView.config,
    },
});

export const initializeViews = async (
    workspaceId: string,
    dataset: DatasetWithSchema,
    user: InitialData
) => {
    const view = defaultView(dataset, {
        id: user.memberId,
        name: user.name,
        email: user.email,
    });

    await saveView({ workspaceId, view });

    return [view];
};

const defaultView = (
    { id, title, schema }: DatasetWithSchema,
    user: ModifiedBy
): ExplorerView => {
    const groupColumns = [
        schema.fields.find(f => f.id === 'mailing_type') ??
            schema.fields.find(f => f.id === 'flow_name') ??
            schema.fields.find(f => f.id === 'journey_name') ??
            schema.fields.find(f => f.id === 'campaign_name'),
        schema.fields.find(f => f.format === 'country'),
    ].filter(isField);

    if (id !== 'campaign_metrics_by_send_date' || groupColumns.length === 0) {
        return newView(title, id, schema, user, []);
    }

    return newView(
        `Emails by ${groupColumns
            .map(f => f.name.replace(' name', '').toLowerCase())
            .join(' and ')}`,
        id,
        schema,
        user,
        groupColumns.map(c => c.id)
    );
};
