import { FormControlLabel, Switch, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { CollectionForm } from '../Collections';

interface CreateTemplateSwitchProps {
    onChange: (value: React.ChangeEvent<HTMLInputElement>) => void;
}

export const CreateTemplateSwitch = ({
    onChange,
}: CreateTemplateSwitchProps) => {
    const { watch } = useFormContext<CollectionForm>();
    const fields = watch('targets') || [];

    return fields.length > 0 ? (
        <FormControlLabel
            control={<Switch onChange={onChange} />}
            label={<Typography variant="body2">Create template</Typography>}
        />
    ) : null;
};
