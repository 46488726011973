import { useState } from 'react';
import Typography from '@mui/material/Typography';

import { useIntegrations } from '../../../../hooks/use-integrations.ts';
import { IntegrationInfo } from '../IntegrationInfo.tsx';
import { EnableSalesforce } from './EnableSalesforce.tsx';
import { DisableIntegration } from '../DisableIntegration.tsx';
import { BucketDetails } from '../BucketDetails.tsx';

export const SalesforceIntegration = () => {
    const [openDialog, setOpenDialog] = useState(false);
    const { data } = useIntegrations();
    const integration = data?.find(x => x.type === 'salesforce');
    const isEnabled = Boolean(integration);

    const handleEnableClick = () => {
        setOpenDialog(true);
    };

    return (
        <>
            <IntegrationInfo
                integrationType="salesforce"
                enabled={isEnabled}
                enableButtonProps={{ onClick: handleEnableClick }}
                headerContent={
                    integration?.id && (
                        <DisableIntegration
                            integrationId={integration.id}
                            name="Salesforce"
                        />
                    )
                }
            />
            <EnableSalesforce
                open={openDialog}
                onClose={() => setOpenDialog(false)}
            />
            {isEnabled && (
                <>
                    <Typography variant="body2">
                        The Salesforce Marketing Cloud integration is enabled.
                    </Typography>
                    <div>
                        <BucketDetails path="sfmc/ingest" />
                    </div>
                </>
            )}
        </>
    );
};
