import { alpha, createTheme, Theme } from '@mui/material';
import { lightTheme, SeinoTheme } from './theme/lightTheme';
import * as React from 'react';

// noinspection ES6UnusedImports
import type {} from '@mui/lab/themeAugmentation';
import type {} from '@mui/x-tree-view/themeAugmentation';

// Documentation on how to extend theming can be found here:
// https://mui.com/customization/theming/#custom-variables
declare module '@mui/material/styles' {
    interface Theme extends SeinoTheme {}

    // allow configuration using `createTheme`
    interface ThemeOptions extends SeinoTheme {}
}

declare module '@mui/material/Button' {
    interface ButtonPropsVariantOverrides {
        dashed: true;
        addWidget: true;
        copy: true;
        filter: true;
        cta: true;
        link: true;
    }

    interface ButtonPropsSizeOverrides {
        extraSmall: true;
    }
}

declare module '@mui/material/IconButton' {
    interface IconButtonPropsColorOverrides {
        light: true;
        contrastText: true;
    }

    interface IconButtonPropsSizeOverrides {
        extraSmall: true;
    }
}

declare module '@mui/material/Toolbar' {
    interface ToolbarPropsVariantOverrides {
        seino: true;
    }
}

declare module '@mui/material/SvgIcon' {
    interface SvgIconPropsColorOverrides {
        contrastText: true;
    }
    interface SvgIconPropsSizeOverrides {
        extraSmall: true;
    }
}

declare module '@mui/material/styles' {
    interface TypeText {
        primary: string;
        primaryLight: string;
        highlight: string;
        secondary: string;
        disabled: string;
    }

    interface Palette {
        text: TypeText;
    }

    interface TypographyVariants {
        mediumBody: React.CSSProperties;
        mediumBoldBody: React.CSSProperties;
        smallMediumBody: React.CSSProperties;
        smallBoldBody: React.CSSProperties;
    }

    interface TypographyVariantsOptions {
        mediumBody: React.CSSProperties;
        mediumBoldBody?: React.CSSProperties;
        smallMediumBody?: React.CSSProperties;
        smallBoldBody?: React.CSSProperties;
    }
}

declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        mediumBody: true;
        mediumBoldBody: true;
        smallMediumBody: true;
        smallBoldBody: true;
    }
}

declare module '@mui/material/styles/createMixins' {
    interface Mixins {
        denseToolbar: {
            minHeight: number;
        };
    }
}

export const theme = createTheme({
    ...lightTheme,
    spacing: 8,
    mixins: {
        denseToolbar: {
            minHeight: 48,
        },
    },
    typography: palette => ({
        h1: {
            fontSize: '3rem',
            fontWeight: 'lighter',
        },
        h2: {
            fontSize: '2.75rem',
            fontWeight: 'lighter',
        },
        h3: {
            fontSize: '2.25rem',
        },
        h4: {
            fontSize: '2rem',
        },
        h5: {
            fontSize: '1.575rem',
        },
        h6: {
            fontSize: '1.125rem',
        },
        body1: {
            fontSize: '1rem',
        },
        body2: {
            fontSize: '0.875rem',
            color: palette.text.primary,
        },
        mediumBody: {
            fontSize: '0.875rem',
            fontWeight: 500,
            color: palette.text.primary,
        },
        mediumBoldBody: {
            fontSize: '0.875rem',
            fontWeight: 800,
            color: palette.text.primary,
        },
        smallMediumBody: {
            fontSize: '0.75rem',
            fontWeight: 500,
            color: palette.text.primaryLight,
        },
        smallBoldBody: {
            fontSize: '0.75rem',
            fontWeight: 600,
            color: palette.text.primaryLight,
        },
        caption: {
            color: palette.text.primaryLight,
        },
        subtitle1: {
            fontSize: '14px',
            fontWeight: 'bold',
            textTransform: 'uppercase',
        },
        subtitle2: {
            fontSize: '1rem',
            fontWeight: 500,
        },
        overline: {
            fontSize: '0.75rem',
        },
        fontFamily: [
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    }),
    components: {
        MuiAutocomplete: {
            styleOverrides: {
                noOptions: ({ theme }) => ({
                    color: theme.text.primary,
                    fontSize: '0.875rem',
                }),
                groupLabel: {},
            },
            defaultProps: {
                size: 'small',
            },
        },
        MuiAccordion: {
            defaultProps: {
                elevation: 0,
                disableGutters: true,
                square: true,
                variant: 'outlined',
            },
            styleOverrides: {
                root: {
                    border: 0,
                    backgroundColor: 'transparent',
                    '&:before': {
                        display: 'none',
                    },
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                colorPrimary: ({ theme }) => ({
                    color: alpha(theme.palette.text.primary, 0.6),
                }),
            },
        },
        MuiSvgIcon: {
            variants: [
                {
                    props: { fontSize: 'extraSmall' },
                    style: { height: '.9em', width: '.9em', lineHeight: '1em' },
                },
                {
                    props: { color: 'contrastText' },
                    style: ({ theme: { palette } }) => ({
                        color: palette.primary.contrastText,
                    }),
                },
            ],
        },
        MuiIconButton: {
            variants: [
                {
                    props: { size: 'extraSmall' },
                    style: { fontSize: '12px' },
                },
                {
                    props: { color: 'light' },
                    style: ({ theme: { palette } }) => ({
                        color: palette.text.primaryLight,
                        '&:hover': {
                            color: palette.text.primaryLight,
                        },
                    }),
                },
                {
                    props: { color: 'contrastText' },
                    style: ({ theme: { palette } }) => ({
                        color: palette.primary.contrastText,
                        '&:hover': {
                            color: palette.primary.contrastText,
                        },
                    }),
                },
            ],
        },
        MuiButtonGroup: {
            styleOverrides: {
                grouped: {
                    minWidth: 0,
                    ':not(:last-of-type)': {
                        borderColor: 'rgba(0, 0, 0, 0.30)',
                        '&.Mui-disabled': {
                            borderColor: 'rgba(0, 0, 0, 0.08)',
                        },
                    },
                },
            },
        },
        MuiToggleButtonGroup: {
            styleOverrides: {
                root: {
                    maxWidth: 'fit-content',
                },
            },
        },
        MuiToggleButton: {
            styleOverrides: {
                root: ({ theme }) => ({
                    textTransform: 'initial',
                    justifyContent: 'space-evenly',
                    gap: theme.spacing(1),
                }),
            },
        },
        MuiButton: {
            defaultProps: {
                disableElevation: true,
            },
            styleOverrides: {
                root: {
                    fontWeight: 'bold',
                },
                sizeSmall: {
                    fontSize: '0.75rem',
                    padding: '4px 8px',
                    '& .MuiSvgIcon-root': {
                        fontSize: '1.125rem',
                    },
                },
            },
            variants: [
                {
                    props: { size: 'extraSmall' },
                    style: { fontSize: '10px' },
                },
                {
                    props: { type: 'button' },
                    style: ({ theme: { palette } }) => ({
                        color: palette.secondary.contrastText,
                        backgroundColor: palette.secondary.main,
                        '&:hover': {
                            backgroundColor: palette.secondary.dark,
                        },
                    }),
                },
                {
                    props: { type: 'submit' },
                    style: ({ theme: { palette } }) => ({
                        color: palette.secondary.contrastText,
                        backgroundColor: palette.secondary.main,
                        '&:hover': {
                            backgroundColor: palette.secondary.dark,
                        },
                    }),
                },
                {
                    props: { type: 'submit', disabled: true },
                    style: ({ theme }) => ({
                        backgroundColor: theme.palette.primary.light,
                        '&.Mui-disabled': {
                            backgroundColor:
                                theme.palette.action.disabledBackground,
                        },
                    }),
                },
                {
                    props: { type: 'reset' },
                    style: ({ theme: { palette } }) => ({
                        color: palette.primary.main,
                        textTransform: 'initial',
                        letterSpacing: '0.46px',
                    }),
                },
                {
                    props: { variant: 'dashed' },
                    style: ({ theme: { palette } }) => ({
                        borderWidth: 1,
                        borderStyle: 'dashed',
                        borderColor: palette.primary.light,
                        color: palette.primary.main,
                        fontWeight: 'normal',
                        '&:hover': {
                            borderStyle: 'dashed',
                        },
                    }),
                },
                {
                    props: { variant: 'dashed', color: 'error' },
                    style: ({ theme: { palette } }) => ({
                        borderWidth: 1,
                        borderStyle: 'dashed',
                        color: palette.error.main,
                        borderColor: palette.error.main,

                        '&:hover': {
                            borderStyle: 'dashed',
                        },
                    }),
                },
                {
                    props: { variant: 'filter' },
                    style: ({ theme: { elevation, border } }) => ({
                        fontWeight: 500,
                        textTransform: 'initial',
                        fontSize: '0.75rem',
                        border: `1px solid ${border.medium}`,
                        background: elevation[1],
                    }),
                },
                {
                    props: { variant: 'cta' },
                    style: ({ theme: { palette } }) => ({
                        color: palette.secondary.contrastText,
                        backgroundColor: palette.secondary.main,
                        borderWidth: 0,
                        '&:hover': {
                            borderStyle: 'solid',
                            backgroundColor: palette.secondary.dark,
                        },
                    }),
                },
                {
                    props: { variant: 'link' },
                    style: ({ theme: { palette } }) => ({
                        fontWeight: 'normal',
                        backgroundColor: 'inherit',
                        textTransform: 'capitalize',
                        textDecoration: 'underline',
                        padding: 0,
                        '&:hover, &:active': {
                            textDecoration: 'underline',
                            color: palette.info.dark,
                            backgroundColor: 'inherit',
                        },
                    }),
                },
            ],
        },
        MuiLoadingButton: {
            styleOverrides: {
                loadingIndicator: ({ ownerState: { size } }) => ({
                    ...(size === 'small'
                        ? {
                              '& .MuiCircularProgress-root': {
                                  width: '12px !important',
                                  height: '12px !important',
                              },
                          }
                        : {}),
                }),
            },
        },
        MuiSelect: {
            styleOverrides: {
                outlined: ({ theme }) => ({
                    background: theme.palette.background.paper,
                    color: theme.palette.text.primaryLight,
                }),
            },
            defaultProps: {
                size: 'small',
            },
        },
        MuiDialogContentText: {
            styleOverrides: {
                root: ({ theme: { palette } }) => ({
                    color: palette.text.primary,
                }),
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: ({ theme }) => ({
                    mt: 1.5,
                    color: theme.palette.primary.dark,
                }),
            },
        },
        MuiCircularProgress: {
            styleOverrides: {
                root: ({ theme }) => ({
                    color: theme.black,
                }),
            },
        },
        MuiToolbar: {
            styleOverrides: {
                root: ({ theme: { tab } }) => ({
                    backgroundColor: tab.toolbar.background,
                    justifyContent: 'space-between',
                }),
            },
            variants: [
                {
                    props: { variant: 'seino' },
                    style: ({ theme: { palette } }) => ({
                        backgroundColor: palette.background.paper,
                        minHeight: '48px',
                    }),
                },
            ],
        },
        MuiTab: {
            styleOverrides: {
                root: ({ theme }) => ({
                    color: theme.palette.primary.main,
                    textTransform: 'none',
                }),
            },
        },
        MuiInput: {
            styleOverrides: {
                root: ({ theme }: { theme: Theme }) => ({
                    color: theme.palette.primary.main,
                    fontSize: '1em',
                }),
                sizeSmall: {
                    fontSize: '0.875rem',
                },
            },
        },
        MuiInputAdornment: {
            styleOverrides: {
                root: ({ theme }) => ({
                    '& p': {
                        color: theme.palette.text.primary,
                    },
                }),
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: ({ theme }) => ({
                    '& .MuiOutlinedInput-root': {
                        backgroundColor: theme.palette.background.paper,
                        color: theme.palette.primary.main,
                    },
                    '& .MuiInputBase-input.Mui-disabled': {
                        color: theme.palette.text.primary,
                        WebkitTextFillColor: theme.palette.text.primary,
                    },
                }),
            },
            defaultProps: {
                size: 'small',
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: ({ theme }) => ({
                    backgroundColor: theme.elevation.background,
                    borderRadius: 4,
                    borderColor: theme.border.light,

                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: theme.border.light,
                    },

                    '&:hover': {
                        backgroundColor: theme.elevation[1],
                        borderColor: theme.border.light,
                    },

                    '& fieldset': {
                        borderColor: theme.border.light,
                    },
                    '&:hover fieldset': {
                        borderColor: theme.border.light,
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: theme.border.light,
                    },
                }),
            },
        },
        MuiFilledInput: {
            defaultProps: {
                disableUnderline: true,
            },
            styleOverrides: {
                root: ({ theme }) => ({
                    backgroundColor: theme.elevation.background,
                    border: `1px solid ${theme.border.light}`,
                    borderRadius: 4,

                    '&:hover': {
                        backgroundColor: theme.elevation[1],
                    },
                }),
            },
        },
        MuiFormControlLabel: {
            defaultProps: {
                componentsProps: {
                    typography: {
                        variant: 'body2',
                    },
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: ({ theme }: { theme: Theme }) => ({
                    color: theme.palette.primary.main,
                    fontSize: '1em',
                }),
            },
        },
        MuiInputLabel: {
            defaultProps: {
                size: 'small',
            },
            styleOverrides: {
                root: ({ theme }: { theme: Theme }) => ({
                    fontSize: theme.typography.pxToRem(14),
                    color: theme.palette.text.primaryLight,
                }),
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: ({ theme: { palette } }) => ({
                    borderBottom: `1px double ${palette.divider}`,
                    color: palette.text.primary,
                }),
                head: ({ theme: { palette } }) => ({
                    backgroundColor: 'unset',
                    fontWeight: 'bold',
                    color: palette.text.primary,
                    '&:hover': {
                        color: palette.text.primary,
                    },
                    '&.Mui-active': {
                        color: palette.text.primary,
                        fontWeight: 'bold',
                    },
                }),
            },
        },
        MuiTableBody: {
            styleOverrides: {
                root: ({ theme }) => ({
                    '& .MuiTableRow-root:hover': {
                        backgroundColor: theme.elevation[2],
                    },
                }),
            },
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    cursor: 'pointer',
                },
            },
        },
        MuiList: {
            styleOverrides: {
                root: {
                    minWidth: 150,
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    lineHeight: '20px',
                    paddingBlock: '8px',
                },
            },
        },
        MuiListSubheader: {
            styleOverrides: {
                root: ({ theme }: { theme: Theme }) => ({
                    backgroundColor: 'unset',
                    color: theme.palette.primary.main,
                    fontWeight: 'bold',
                    fontSize: '1em',
                }),
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: ({ theme }) => ({
                    minWidth: theme.spacing(6),
                }),
            },
        },
        MuiListItemText: {
            styleOverrides: {
                root: ({ theme }) => ({
                    color: theme.palette.primary.main,
                }),
                primary: ({ theme }) => ({
                    fontSize: theme.typography.pxToRem(14),
                }),
                secondary: ({ theme }) => ({
                    color: theme.palette.text.primaryLight,
                }),
            },
        },
        MuiTreeItem: {
            styleOverrides: {
                label: {
                    fontSize: '0.875rem',
                },
            },
        },
    },
});
