import { Box } from '@mui/material';
import { Select } from '../Form/Select';
import { useInitialData } from '../../hooks/use-initial-data';
import {
    generatePath,
    matchRoutes,
    useLocation,
    useNavigate,
    useParams,
} from 'react-router-dom';
import { routes } from '../../const/routes';
import { useFlags } from 'launchdarkly-react-client-sdk';

type Item = {
    value: string;
    name: string;
    label: string;
};

export const WorkspaceSelector = ({
    variant,
    noBorder,
}: {
    variant?: 'light' | 'dark';
    noBorder?: boolean;
}) => {
    const { isLoading, data } = useInitialData();
    const { workspaceId } = useParams<{ workspaceId: string | undefined }>();
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const { anonymizeData } = useFlags();

    const handleOnChange = (item: Item | null) => {
        if (!item) {
            return;
        }

        const match = matchRoutes(routes, pathname) || [];
        if (match.length > 0) {
            const { params, route } = match[0];
            navigate(
                generatePath(route?.rewriteTo || route.path, {
                    ...params,
                    workspaceId: item.value,
                })
            );
        }
    };

    if (isLoading || !data || !workspaceId) {
        return null;
    }

    const mappedWorkspaceOptions: Item[] = data.workspaces.map(
        ({ displayName, id, name }) => ({
            value: id,
            name: name,
            label: anonymizeData
                ? `Demo Org ${displayName
                      .charAt(displayName.length - 1)
                      .toUpperCase()}_${displayName.charAt(0).toUpperCase()}`
                : displayName,
        })
    );

    const selectedWorkspace = mappedWorkspaceOptions.find(
        w => w.value === workspaceId || w.name === workspaceId
    );

    if (selectedWorkspace?.label) {
        document.title = `${selectedWorkspace.label} | SEINō`;
    }

    return (
        <Box width="250px" sx={{ zIndex: 9999 }}>
            <Select<Item>
                dark={variant !== 'light'}
                border={!noBorder}
                size="small"
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                value={selectedWorkspace}
                onChange={handleOnChange}
                options={[
                    {
                        label: 'Select Workspace',
                        options: mappedWorkspaceOptions,
                    },
                ]}
            />
        </Box>
    );
};
