import {
    Line,
    LineChart,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';
import { SubscriberListDatum } from '../use-subscriber-list-data';
import { RechartTooltip } from '../../../../Rechart/RechartTooltip';
import React from 'react';
import { useTheme } from '@mui/material';

interface CumulativeRechartProps {
    data: SubscriberListDatum[];
}

export const CumulativeRechart = ({ data }: CumulativeRechartProps) => {
    const absMax = Math.max(...data.map(({ members }) => members));
    const theme = useTheme();

    return (
        <ResponsiveContainer width="100%" height="100%">
            <LineChart data={data}>
                <Tooltip
                    filterNull={false}
                    cursor={false}
                    position={{ y: -100 }}
                    content={<RechartTooltip />}
                />
                <YAxis hide={true} domain={[0.85 * absMax, 1.05 * absMax]} />
                <XAxis dataKey="date" axisLine={false} tickLine={false} />
                <Line
                    type="monotone"
                    dataKey="members"
                    name="Members total"
                    dot={false}
                    stroke={theme.text.secondary}
                />
                <ReferenceLine y={absMax} stroke="#b1b1b1" />
            </LineChart>
        </ResponsiveContainer>
    );
};
