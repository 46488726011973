import { Annotation } from '../../types/annotation';
import * as React from 'react';
import { useState } from 'react';
import { Collapse, IconButton } from '@mui/material';
import { KeyboardReturn } from '@mui/icons-material';
import {
    AnnotationContent,
    AnnotationHeader,
    AnnotationMessage,
    CollapsibleAnnotationCard,
} from './AnnotationCard.tsx';
import { AnnotationForm } from './AnnotationForms.tsx';
import ConfirmDialog from '../ConfirmDialog/ConfirmAlert.tsx';
import { useAnnotationMutation } from './use-annotation-mutation.ts';

const BackAvatar = ({
    onClick,
    collapsed,
}: {
    onClick: () => void;
    collapsed: boolean;
}) => (
    <IconButton
        size="small"
        sx={{
            display: collapsed ? 'none' : 'flex',
        }}
        onClick={onClick}
    >
        <KeyboardReturn fontSize="small" />
    </IconButton>
);

const NewAnnotationHeader = ({
    annotation,
    handleClose,
}: {
    annotation: Annotation;
    handleClose: () => void;
}) => {
    return (
        <AnnotationHeader
            avatar={<BackAvatar onClick={handleClose} collapsed={false} />}
            title={annotation.createdBy?.name}
            modificationDate={annotation.createdAt}
            hideMenu
        />
    );
};

const ExistingHeader = ({
    annotation,
    collapsed,
    mutating,
    onStartMutating,
    onStopMutating,
    onRemove,
}: {
    onRemove: () => void;
    onStartMutating: () => void;
    onStopMutating: () => void;
    mutating: boolean;
    collapsed: boolean;
    annotation: Annotation;
}) => (
    <AnnotationHeader
        avatar={
            mutating ? (
                <Collapse in={!collapsed}>
                    <BackAvatar
                        collapsed={collapsed}
                        onClick={onStopMutating}
                    />
                </Collapse>
            ) : undefined
        }
        severity={!mutating ? annotation.severity : undefined}
        title={
            annotation?.modifiedBy?.name ||
            annotation?.createdBy?.name ||
            'unknown'
        }
        hideMenu={mutating}
        modificationDate={annotation?.modifiedAt || annotation.createdAt}
        handleOnMutate={onStartMutating}
        handleOnRemove={onRemove}
    />
);

export function MutableAnnotation({
    annotation,
    collapsedOnInit = true,
    isNew,
}: {
    annotation: Annotation;
    collapsedOnInit?: boolean;
    isNew?: boolean;
}) {
    const [collapsed, setCollapsed] = useState(collapsedOnInit);
    const [removeAnnotation, setRemoveDialog] = useState(false);
    const [isMutating, setIsMutating] = useState(false);

    const handleClose = () => {
        setCollapsed(true);
    };

    const { remove } = useAnnotationMutation();

    return (
        <div>
            <CollapsibleAnnotationCard
                collapsed={collapsed}
                header={
                    isNew ? (
                        <NewAnnotationHeader
                            annotation={annotation}
                            handleClose={handleClose}
                        />
                    ) : (
                        <ExistingHeader
                            onRemove={() => setRemoveDialog(true)}
                            onStartMutating={() => setIsMutating(true)}
                            onStopMutating={() => setIsMutating(false)}
                            mutating={isMutating}
                            collapsed={collapsed}
                            annotation={annotation}
                        />
                    )
                }
                content={
                    isNew || (!isNew && isMutating) ? (
                        <AnnotationForm
                            initialAnnotation={annotation}
                            collapsed={collapsed}
                            textFieldOnFocus={() => setCollapsed(false)}
                            handleOnMutateSuccess={() => {
                                setIsMutating(false);
                                if (isNew) {
                                    setCollapsed(true);
                                }
                            }}
                        />
                    ) : (
                        <AnnotationContent>
                            <AnnotationMessage annotation={annotation} />
                        </AnnotationContent>
                    )
                }
            />

            <ConfirmDialog
                open={removeAnnotation}
                onAnyDecision={() => setRemoveDialog(false)}
                onContinue={() => remove(annotation.id)}
                isDangerousAction={true}
                content="Are you sure you want to remove this annotation?"
                continueText="Remove"
            />
        </div>
    );
}
