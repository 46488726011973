import { useTheme } from '@mui/material';

export const useSelectStyle = (
    size: 'small' | 'medium' | 'large',
    dark: boolean = false,
    border: boolean = true
) => {
    const theme = useTheme();
    const adaptiveColor = dark ? theme.white : theme.black;
    const divider = `1px solid ${theme.palette.divider}`;
    const fontSize = size === 'small' ? '14px' : '16px';

    return {
        input: (base: any) => {
            return {
                ...base,
                color: adaptiveColor,
                fontSize,
            };
        },
        placeholder: (base: any) => {
            return {
                ...base,
                color: adaptiveColor,
                fontSize,
            };
        },
        control: (base: any) => {
            return {
                ...base,
                width: '100%',
                borderRadius: '4px',
                color: adaptiveColor,
                boxShadow: 'none',
                fontSize: '12px',
                border: border ? divider : 'none',
                backgroundColor: 'transparent',
            };
        },
        indicatorSeparator: (base: any) => {
            return { ...base, background: 'none' };
        },
        singleValue: (base: any) => {
            return {
                ...base,
                fontSize,
                color: adaptiveColor,
            };
        },
        option: (styles: any, { isFocused, isSelected }: any) => {
            return {
                ...styles,
                color: theme.black,
                backgroundColor:
                    isSelected || isFocused
                        ? theme.palette.background.default
                        : theme.palette.background.paper,
                alignItems: 'center',
            };
        },

        groupHeading: (base: any) => {
            return {
                ...base,
                fontWeight: 'bold',
                fontSize,
                textTransform: 'capitalize',
                color: theme.black,
                borderBottom: divider,
                marginBottom: '10px',
                padding: '10px',
            };
        },
        menu: (base: any) => {
            return {
                ...base,
                boxShadow: 'none',
                marginTop: '0',
                zIndex: 2,
            };
        },
        menuList: (base: any) => {
            return {
                ...base,
                position: 'fixed',
                minWidth: '400px', // Think of something elegant for this
                border: divider,
                borderRadius: '4px',
                padding: '0 10px',
                backgroundColor: theme.palette.background.paper,
            };
        },
        container: (base: any) => {
            return {
                ...base,
                border: 'none',
                display: 'flex',
                alignItems: 'center',
                height: size === 'large' ? '60px' : '40px',
                minWidth: '190px',
            };
        },
    };
};
