import axios from 'axios';
import { getRustAPIUrl, SimpleError } from '../../../../utils/api';
import {
    useMutation,
    UseMutationOptions,
    useQueryClient,
} from '@tanstack/react-query';
import { integrationsQueryKey } from '../../../../hooks/use-integrations.ts';

const connect = (
    workspaceId: string,
    client_id: string,
    client_secret: string
) => {
    return axios.post(
        `${getRustAPIUrl()}/workspaces/${workspaceId}/integrations/deployteq`,
        { client_id, client_secret }
    );
};

type Options = Pick<
    UseMutationOptions<
        unknown,
        Error | SimpleError,
        {
            workspaceId: string;
            integrationId: string;
            clientId: string;
            clientSecret: string;
        }
    >,
    'onSuccess' | 'onError'
>;
export const useSetDeployteqCredentials = (options?: Options) => {
    const queryClient = useQueryClient();
    return useMutation(
        ({ workspaceId, clientId, clientSecret }) =>
            connect(workspaceId, clientId, clientSecret),
        {
            ...options,
            onSuccess: (data, variables, context) => {
                queryClient.invalidateQueries(
                    integrationsQueryKey(variables.workspaceId)
                );
                options?.onSuccess?.(data, variables, context);
            },
        }
    );
};
