import { Alert, Stack, Typography } from '@mui/material';
import React from 'react';
import { CopernicaIntegration } from '../../../../../types/integration';
import {
    useCopernicaDatabases,
    useTrackedViews,
    useUpdateTrackedViews,
} from './TrackedViews.hooks';
import { DatabaseTree } from './DatabaseTree';
import { match, P } from 'ts-pattern';
import { Loader } from '../../../../../components/Loader/Loader';

type TrackedViewsProps = {
    integration: CopernicaIntegration;
};
export const TrackedViews = ({ integration }: TrackedViewsProps) => {
    const trackedViews = useTrackedViews(integration.id);
    const databases = useCopernicaDatabases(integration.id);
    const { mutate } = useUpdateTrackedViews(integration.id);

    return (
        <Stack gap={1}>
            <Typography variant="body2">
                You can monitor the development of your Copernica database over
                time in SEINō by configuring the views you want to track below.
            </Typography>

            <Typography variant="body2">
                Note that changes to the selected views will only be visible the
                next day, since we import the profiles on a nightly basis.
            </Typography>

            {match({ trackedViews, databases })
                .with(
                    P.union(
                        { trackedViews: { status: 'loading' } },
                        { databases: { status: 'loading' } }
                    ),
                    () => <Loader />
                )
                .with(
                    P.union(
                        { trackedViews: { status: 'error' } },
                        { databases: { status: 'error' } }
                    ),
                    () => (
                        <Alert severity="error">
                            Failed to retrieve Copernica databases. Please check
                            again later.
                        </Alert>
                    )
                )
                .with(
                    {
                        trackedViews: { status: 'success' },
                        databases: { status: 'success' },
                    },
                    ({
                        trackedViews: { data: trackedViews },
                        databases: { data: databases },
                    }) => (
                        <DatabaseTree
                            selected={trackedViews}
                            setSelected={mutate}
                            databases={databases}
                            integrationId={integration.id}
                        />
                    )
                )
                .exhaustive()}
        </Stack>
    );
};
