import { Box } from '@mui/system';
import styled from 'styled-components';

export const Container = styled(Box)`
    width: 100%;
`;

export const LoaderContainer = styled(Box)`
    width: 100%;
    display: flex;
    justify-content: center;
`;
