import { AddCircleOutline } from '@mui/icons-material';
import { Button } from '@mui/material';
import { WidgetType } from '../../../types/dashboard';

interface AddWidgetProps {
    type: WidgetType;
    label: string;
    onClick: (type: WidgetType) => void;
}

export const AddWidgetButton = ({ type, label, onClick }: AddWidgetProps) => {
    return (
        <Button
            startIcon={<AddCircleOutline />}
            variant="dashed"
            onClick={() => onClick(type)}
        >
            {label}
        </Button>
    );
};
