import {
    ColDef,
    ICellRendererParams,
    ValueFormatterParams,
    ValueGetterParams,
} from '@ag-grid-community/core';
import { DataModelSchema } from '../../../../../types/datamodel/schema';
import {
    currencyFormatter,
    CountryComponent,
    numberFormatter,
    percentageFormatter,
} from '../../../../ExplorerGrid/grid/utils';
import { Localization } from '../../../../../types/datamodel/localization';
import { uniq } from 'lodash';
import { match } from 'ts-pattern';
import {
    monthComparator,
    monthGetter,
    weekdayComparator,
    weekdayGetter,
} from '../../../../ExplorerGrid/grid/utils/datePartFormat.ts';
import { WeightedValue } from '../../../../ExplorerGrid/grid/utils/weightedAverage';
import { timeFormatter } from '../../../../ExplorerGrid/grid/utils/timeFormatter';
import { metricColor } from '../../../../../utils/metric-color';

export const getColDefs = (
    schema: DataModelSchema,
    dimensions: string[],
    groupBy: string[],
    metrics: string[],
    locale: Localization,
    showCompare: boolean
) => {
    const groupByColumn = {
        colId: 'ag-Grid-AutoColumn',
        headerName: 'Group',
        cellRenderer: 'agGroupCellRenderer',
        showRowGroup: true,
    };

    const groupColumns = uniq([...dimensions, ...groupBy]).map((d): ColDef => {
        const schemaItem = schema?.fields.find(({ id }) => id === d);
        return {
            colId: d,
            headerName: schemaItem?.shortName ?? schemaItem?.name,
            cellDataType: 'text',
            rowGroup: groupBy.includes(d),
            rowGroupIndex: groupBy.findIndex(id => id === d),
            hide: !dimensions.includes(d),
            lockVisible: true,
            suppressSizeToFit: true,
            suppressColumnsToolPanel: true,
            valueGetter: match(schemaItem?.format)
                .with('month', () => monthGetter)
                .with('weekday', () => weekdayGetter)
                .with(
                    'boolean',
                    () =>
                        ({ data }: ValueGetterParams) =>
                            data && (data[d] ? 'Yes' : 'No')
                )
                .otherwise(() => ({ data }: ValueGetterParams) => {
                    if (!data) return '';

                    return data[d];
                }),
            comparator: match(schemaItem?.format)
                .with('month', () => monthComparator)
                .with('weekday', () => weekdayComparator)
                .otherwise(() => undefined),
            valueFormatter: (params: ValueFormatterParams) => {
                const { value } = params;

                return value;
            },
            cellRenderer:
                schemaItem?.format === 'country'
                    ? CountryComponent
                    : (evt: ICellRendererParams) => {
                          return (
                              <span data-testid={`${evt.rowIndex}-${d}`}>
                                  {evt.valueFormatted || ''}
                              </span>
                          );
                      },
        };
    });

    const columns = metrics.flatMap<ColDef>((m): ColDef[] => {
        const schemaItem = schema?.fields.find(({ id }) => id === m);
        if (!schemaItem) {
            return [];
        }

        const columnDefinition = {
            colId: m,
            headerName: schemaItem?.shortName ?? schemaItem?.name,
            aggFunc:
                schemaItem.agg?.type === 'weighted_avg' ? 'weightedAvg' : 'sum',
            type: 'rightAligned',
            cellDataType: 'text',
            lockVisible: true,
            suppressSizeToFit: true,
            suppressColumnsToolPanel: true,
            valueGetter: ({ data }: ValueGetterParams) => {
                if (!data || !data[m]) return 0;

                if (schemaItem.agg?.type === 'weighted_avg') {
                    return new WeightedValue(
                        data?.[schemaItem.agg.numerator].current || 0,
                        data?.[schemaItem.agg.denominator].current || 0
                    );
                }

                return data[m].current;
            },
            valueFormatter: match(schemaItem.format)
                .with('percent', () => percentageFormatter(locale))
                .with('currency', () => currencyFormatter(locale))
                .with('duration', () => timeFormatter)
                .otherwise(() => numberFormatter(locale)),
            cellRenderer: (evt: ICellRendererParams) => {
                return (
                    <span data-testid={`${evt.rowIndex}-${m}`}>
                        {evt.valueFormatted || ''}
                    </span>
                );
            },
        };

        if (schemaItem.agg?.type !== 'sum' || !showCompare) {
            return [columnDefinition];
        }

        const deltaColumnDefinition = {
            colId: `${m}_delta`,
            hide: true,
            headerName: `∆ ${schemaItem?.shortName ?? schemaItem?.name}`,
            aggFunc: 'weightedAvg',
            suppressSizeToFit: true,
            type: 'rightAligned',
            valueGetter: ({ data }: ValueGetterParams) => {
                const current = data[m].current || 0;
                const last = data[m].last || 0;

                return new WeightedValue(current - last, last);
            },
            valueFormatter: percentageFormatter(locale, {
                minimumFractionDigits: 0,
            }),
            cellRenderer: ({
                value,
                valueFormatted,
                rowIndex,
            }: ICellRendererParams) => {
                if (!value || !valueFormatted) return '';

                const currentValueMoreThanPreviousPeriod =
                    valueFormatted?.charAt(0) !== '-';

                if (!schemaItem) {
                    console.error(`Missing metric ${m}`);
                }

                const arrow = currentValueMoreThanPreviousPeriod ? '↑' : '↓';
                const color = metricColor(
                    currentValueMoreThanPreviousPeriod,
                    !!schemaItem?.features?.includes('negative_measure')
                );

                return (
                    <span style={{ textAlign: 'right' }}>
                        <span
                            data-testid={`${rowIndex}-${m}_delta`}
                            style={{ color }}
                        >
                            {arrow}
                            {valueFormatted}
                        </span>
                    </span>
                );
            },
        };

        return [columnDefinition, deltaColumnDefinition];
    });

    return [
        ...(groupBy.length > 0 ? [groupByColumn] : []),
        ...groupColumns,
        ...columns,
    ];
};
