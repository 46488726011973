import { Badge, BadgeProps, IconButton, styled } from '@mui/material';
import * as React from 'react';
import { useDrawer } from '../../hooks/use-drawer.ts';
import { Message } from '@mui/icons-material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useDateRange } from '../../hooks/use-date-range.ts';
import { useAnnotations } from './use-annotations.ts';

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    '& .MuiBadge-badge': {
        border: `2px solid ${theme.palette.background.paper}`,
        background: theme.palette.primary.light,
        padding: '0 4px',
    },
}));

export function AnnotationsButton() {
    const { isOpen, closeDrawer, openDrawer } = useDrawer('annotations');

    const { annotations: showAnnotations } = useFlags();
    const { dateRanges } = useDateRange();

    const { data } = useAnnotations(dateRanges.main);

    const handleOnClick = () => (isOpen ? closeDrawer() : openDrawer({}));

    if (!showAnnotations) {
        return null;
    }

    return (
        <IconButton
            size="small"
            aria-label="annotations"
            onClick={handleOnClick}
            sx={{ marginInline: 1 }}
        >
            <StyledBadge color="primary" badgeContent={data?.length}>
                <Message fontSize="small" />
            </StyledBadge>
        </IconButton>
    );
}
