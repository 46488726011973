import { isEqual } from 'lodash';

/**
 * Returns `original` if it deeply equals `newValue`. Can be used to make the
 * output of an expression referentially equivalent, which can be useful in
 * the context of React to prevent re-renders.
 *
 * For example:
 * ```
 * <Widget config={stabilize(foo, foo.filter(someFilter)} />
 * ```
 *
 * @param original
 * @param newValue
 */
export const stabilize = <T>(original: T, newValue: T) =>
    isEqual(original, newValue) ? original : newValue;
