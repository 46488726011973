import { WidgetConfig } from '../../../../types/dashboard';
import { Widget } from '../Widget';

import { SubscriberListRechart } from './SubscriberListRechart';
import { useHasDataset } from '../../../../hooks/use-datasets.ts';

interface SubscriberListConfig extends WidgetConfig {
    isEditable: boolean;
}

export const SubscriberListWidget = (config: SubscriberListConfig) => {
    const Component = SubscriberListRechart;

    const hasSubscriberWidget = useHasDataset('list_members');
    if (!hasSubscriberWidget) {
        return null;
    }

    return (
        <Widget config={config}>
            <Component filteredLists={config.filteredLists} />
        </Widget>
    );
};
