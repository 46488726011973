import { Box, Card, Stack, styled, Typography } from '@mui/material';
import { Point } from '@nivo/line';
import { format } from 'date-fns';
import { DataModelField } from '../../../../types/datamodel/schema.ts';
import { formatNumber } from '../../../../utils/number-format.ts';
import { useLocale } from '../../../../providers/LocaleProvider.hooks.ts';
import { formatDimension } from '../../../../utils/dimension-format.ts';
import { parseDate } from '../../../../utils/date-format.ts';

interface TooltipProps {
    metric: DataModelField;
    groupBy: DataModelField | undefined;
    points: Point[];
}

const DATE_FORMAT = 'EEEE, d MMMM yyyy';

export const Tooltip = ({ metric, points, groupBy }: TooltipProps) => {
    const locale = useLocale();
    const start = format(points[0].data.x as Date, DATE_FORMAT);
    const end = format(parseDate((points[0].data as any).xEnd), DATE_FORMAT);

    return (
        <Card elevation={2} sx={{ p: 2, fontSize: 14 }}>
            <Typography sx={{ fontWeight: 'bold', fontSize: 14 }}>
                {start === end ? start : [start, end].join(' - ')}
            </Typography>
            <Stack spacing={0}>
                {points
                    .sort((a, b) => +b.data.y - +a.data.y)
                    .slice(0, 10)
                    .map(point => (
                        <SerieLabel
                            key={point.id}
                            color={point.color}
                            label={
                                groupBy
                                    ? formatDimension(
                                          point.serieId.toString(),
                                          groupBy.format
                                      )
                                    : metric.shortName || metric.name
                            }
                            value={formatNumber(
                                point.data.y,
                                metric.format,
                                locale
                            )}
                        />
                    ))}
            </Stack>
        </Card>
    );
};

type SerieLabelProps = {
    label: string;
    color: string;
    value: string;
};

const SerieLabel = ({ color, label, value }: SerieLabelProps) => {
    return (
        <Stack direction="row">
            <Legend color={color} />
            <Stack>
                <Text>
                    {`${label}: `}
                    <strong>{value}</strong>
                </Text>
            </Stack>
        </Stack>
    );
};

const Text = styled(Typography)({
    paragraph: false,
    fontSize: 14,
});

interface LegendProps {
    color: string;
}

const Legend = styled(Box)<LegendProps>(({ color }) => ({
    component: 'span',
    width: 8,
    height: 8,
    background: color,
    verticalAlign: 'middle',
    marginTop: '0.375rem',
    marginRight: '0.375rem',
}));
