import { Stack } from '@mui/material';
import {
    Content,
    SubContent,
    Table,
    WithModifiers,
} from '../../../components/Table';
import { useMembers } from './useMembers.tsx';
import { SearchInput, useSearch } from '../../../components/Search';
import { WorkspaceMember } from '../../../types/workspace-management';
import { InviteUserDialog } from './InviteUserDialog.tsx';
import * as React from 'react';
import { useMemo } from 'react';
import { Columns, ColWidth } from '../../../components/Table/types.ts';
import { HighlightText } from '../../../components/Hightlight/Hightlight.tsx';
import { formatHumanReadableDate } from '../../../utils/date-format.ts';
import { ActionsColumn } from './ActionsColumn.tsx';
import { Heading2 } from '../Settings.styled.tsx';
import { useActiveWorkspace } from '../../../providers/WorkspaceProvider.hooks.ts';

export type TableProps = WithModifiers<WorkspaceMember>;

export const Members = () => {
    const { isAdmin } = useActiveWorkspace();
    const { data: permissions, isLoading } = useMembers();

    const { search, setSearch, results } = useSearch(permissions || [], [
        'id',
        'name',
        'email',
    ]);

    const columns: Columns<TableProps> = useMemo(
        () => [...baseColumns, ...(isAdmin ? actionsColumns : [])],
        [isAdmin]
    );

    return (
        <Stack spacing={2}>
            <Heading2>Members</Heading2>

            <Stack gap={1}>
                <Stack direction="row" justifyContent="space-between" gap={1}>
                    <SearchInput setSearch={setSearch} />
                    <InviteUserDialog />
                </Stack>

                <Table<TableProps>
                    columns={columns}
                    rows={results}
                    searchQuery={search}
                    isLoading={isLoading}
                />
            </Stack>
        </Stack>
    );
};

const baseColumns: Columns<TableProps> = [
    { accessor: 'id', isVisible: false },
    {
        accessor: 'email',
        isVisible: false,
        searchable: true,
    },
    {
        accessor: 'name',
        title: 'Name',
        searchable: true,
        isClickable: false,
        render: (member, _, searchQuery) => (
            <Stack alignItems="center" direction="row">
                <div>
                    <Content>
                        <HighlightText query={searchQuery} text={member.name} />
                    </Content>
                    <SubContent>
                        <HighlightText
                            query={searchQuery}
                            text={member.email}
                        />
                    </SubContent>
                </div>
            </Stack>
        ),
    },
    {
        render: member => (
            <Content>{formatHumanReadableDate(member.lastSeen)}</Content>
        ),
        accessor: 'lastSeen',
        title: 'Last seen',
        isClickable: false,
        width: ColWidth.Medium,
        align: 'right',
    },
    {
        accessor: 'role',
        title: 'Role',
        width: 80,
        cellStyling: () => ({ textTransform: 'capitalize' }),
    },
];

const actionsColumns: Columns<TableProps> = [
    {
        accessor: 'modifiers',
        title: '',
        isClickable: false,
        width: ColWidth.Tiny,
        render: member => <ActionsColumn member={member} />,
        align: 'right',
    },
];
