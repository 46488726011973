import {
    addMonths,
    differenceInCalendarMonths,
    isAfter,
    isValid,
    min,
} from 'date-fns';
import { Dispatch } from 'react';
import { normalizeInterval } from './dateHelpers';
import { match } from 'ts-pattern';
import { stabilize } from '../../utils/stabilize.ts';
import {
    calculateCompareRange,
    calculateMainRange,
    ComparePreset,
    MainPreset,
} from './presetDefinitions.ts';

type IntervalSide = 'start' | 'end';
export type PeriodType = 'main' | 'compare';

export type DateRanges<T = Date> = {
    main: DateRangeWithPreset<MainPreset, T>;
    compare: DateRangeWithPreset<ComparePreset, T>;
};

export type DateRange<T = Date> = { start: T; end: T };
export type DateRangeWithPreset<T, U = Date> = DateRange<U> & {
    preset: T;
};

export interface DateRangePickerState {
    isOpen: boolean;
    dateRanges: DateRanges;
    currentSide: IntervalSide;
    currentPeriod: PeriodType;
    lastVisibleMonth: number | Date;
}

export type DateRangePickerDispatch = Dispatch<DateRangePickerAction>;

type Reducer<T> = (
    action: Extract<DateRangePickerAction, { type: T }>
) => DateRangePickerState;

export type DateRangePickerAction =
    | { type: 'toggle'; dateRanges: DateRanges }
    | { type: 'close' }
    | { type: 'reset'; dateRanges: DateRanges }
    | { type: 'selectPeriod'; period: PeriodType }
    | { type: 'selectDate'; date: Date }
    | { type: 'selectStart'; date: Date | number | null }
    | { type: 'selectEnd'; date: Date | number | null }
    | ({ type: 'selectPreset' } & (
          | { period: 'main'; preset: MainPreset }
          | { period: 'compare'; preset: ComparePreset }
      ))
    | { type: 'selectRange'; dateRange: DateRange }
    | { type: 'adjustVisibleMonths'; offset: number }
    | { type: 'selectSide'; side: IntervalSide };

export const reducer =
    (calendars: number, weekStartsOn: Day) =>
    (
        state: DateRangePickerState,
        action: DateRangePickerAction
    ): DateRangePickerState =>
        stabilize(
            state,
            centerSelectedRange(
                alignComparePeriod(
                    match(action)
                        .with({ type: 'close' }, close(state))
                        .with({ type: 'reset' }, reset(state))
                        .with({ type: 'toggle' }, toggle(state))
                        .with({ type: 'selectDate' }, selectDate(state))
                        .with(
                            { type: 'selectStart' },
                            selectStart(state, calendars)
                        )
                        .with({ type: 'selectEnd' }, selectEnd(state))
                        .with({ type: 'selectPeriod' }, selectPeriod(state))
                        .with({ type: 'selectRange' }, selectRange(state))
                        .with(
                            { type: 'adjustVisibleMonths' },
                            adjustVisibleMonths(state)
                        )
                        .with({ type: 'selectSide' }, selectSide(state))
                        .with(
                            { type: 'selectPreset' },
                            selectPreset(state, weekStartsOn)
                        )
                        .exhaustive()
                ),
                action,
                calendars
            )
        );

const close =
    (state: DateRangePickerState): Reducer<'close'> =>
    () => ({ ...state, isOpen: false });

const reset =
    (state: DateRangePickerState): Reducer<'reset'> =>
    ({ dateRanges }) => ({ ...state, dateRanges, isOpen: false });

const toggle =
    (state: DateRangePickerState): Reducer<'toggle'> =>
    ({ dateRanges }) => ({
        ...state,
        dateRanges,
        isOpen: !state.isOpen,
        currentSide: 'start' as const,
    });

const selectPeriod =
    (state: DateRangePickerState): Reducer<'selectPeriod'> =>
    ({ period }) => ({ ...state, currentPeriod: period });

const selectDate =
    (state: DateRangePickerState): Reducer<'selectDate'> =>
    ({ date }) => ({
        ...state,
        dateRanges: {
            ...state.dateRanges,
            [state.currentPeriod]: {
                preset: 'custom',
                ...normalizeInterval({
                    start:
                        state.currentSide === 'start'
                            ? date
                            : currentRange(state).start,
                    end:
                        state.currentSide === 'end'
                            ? date
                            : isAfter(date, currentRange(state).end)
                            ? date
                            : currentRange(state).end,
                }),
            },
        },
        currentSide:
            state.currentSide === 'start'
                ? ('end' as const)
                : ('start' as const),
    });

const selectStart =
    (state: DateRangePickerState, calendars: number): Reducer<'selectStart'> =>
    ({ date }) => {
        if (!isValid(date)) {
            return state;
        }

        const startDateRange = normalizeInterval({
            start: date as Date,
            end:
                currentRange(state).end < (date as Date)
                    ? (date as Date)
                    : currentRange(state).end,
        });

        return {
            ...state,
            dateRanges: {
                ...state.dateRanges,
                [state.currentPeriod]: { preset: 'custom', ...startDateRange },
            },
            lastVisibleMonth: min([
                addMonths(startDateRange.start, calendars - 1),
                new Date(),
            ]),
            currentSide: 'end' as const,
        };
    };

const selectEnd =
    (state: DateRangePickerState): Reducer<'selectEnd'> =>
    ({ date }) => {
        if (!isValid(date)) {
            return state;
        }

        const endDateRange = normalizeInterval({
            start: currentRange(state).start,
            end: date as Date,
        });

        return {
            ...state,
            dateRanges: {
                ...state.dateRanges,
                [state.currentPeriod]: { preset: 'custom', ...endDateRange },
            },
            lastVisibleMonth: endDateRange.end,
            currentSide: 'start' as const,
        };
    };

const selectRange =
    (state: DateRangePickerState): Reducer<'selectRange'> =>
    ({ dateRange }) => ({
        ...state,
        dateRanges: {
            ...state.dateRanges,
            [state.currentPeriod]: {
                preset: 'custom',
                ...normalizeInterval(dateRange),
            },
        },
        currentSide: 'start' as const,
    });

const adjustVisibleMonths =
    (state: DateRangePickerState): Reducer<'adjustVisibleMonths'> =>
    ({ offset }) => ({
        ...state,
        lastVisibleMonth: addMonths(state.lastVisibleMonth, offset),
    });

const selectSide =
    (state: DateRangePickerState): Reducer<'selectSide'> =>
    ({ side }) => ({
        ...state,
        currentSide: side,
        lastVisibleMonth:
            currentRange(state).preset === 'disabled'
                ? state.lastVisibleMonth
                : side === 'start'
                ? addMonths(currentRange(state).start, 1)
                : addMonths(currentRange(state).end, 1),
    });

const selectPreset =
    (state: DateRangePickerState, weekStartsOn: Day): Reducer<'selectPreset'> =>
    action => ({
        ...state,
        ...match(action)
            .with({ period: 'main' }, ({ preset }): Partial<typeof state> => {
                const main = calculateMainRange(
                    weekStartsOn,
                    state.dateRanges.main,
                    preset
                );

                return {
                    dateRanges: {
                        ...state.dateRanges,
                        main,
                    },
                    lastVisibleMonth: main.end,
                };
            })
            .with(
                { period: 'compare' },
                ({ preset }) =>
                    ({
                        dateRanges: {
                            ...state.dateRanges,
                            compare: calculateCompareRange(
                                state.dateRanges.main,
                                state.dateRanges.compare,
                                preset
                            ),
                        },
                        currentPeriod:
                            preset === 'disabled' ? 'main' : 'compare',
                    } satisfies Partial<DateRangePickerState>)
            )
            .exhaustive(),
    });

const alignComparePeriod = (
    state: DateRangePickerState
): DateRangePickerState => ({
    ...state,
    dateRanges: {
        ...state.dateRanges,
        compare: calculateCompareRange(
            state.dateRanges.main,
            state.dateRanges.compare,
            state.dateRanges.compare.preset
        ),
    },
});

const skipCenterFor = ['adjustVisibleMonths', 'selectRange', 'selectDate'];

const centerSelectedRange = (
    state: DateRangePickerState,
    action: DateRangePickerAction,
    calendars: number
): DateRangePickerState => {
    if (skipCenterFor.includes(action.type)) {
        return state;
    }

    if (
        state.currentPeriod === 'compare' &&
        state.dateRanges.compare.preset === 'disabled'
    ) {
        return state;
    }

    const range = currentRange(state);
    const side = state.currentSide;
    const coveredMonths = differenceInCalendarMonths(range.end, range.start);
    const midpoint = new Date(+range.start + (+range.end - +range.start) / 2);

    if (coveredMonths >= calendars) {
        return {
            ...state,
            lastVisibleMonth: match(side)
                .with('start', () => addMonths(range.start, calendars - 1))
                .with('end', () => range.end)
                .exhaustive(),
        };
    }

    return {
        ...state,
        lastVisibleMonth: calendars === 3 ? addMonths(midpoint, 1) : range.end,
    };
};

const currentRange = (state: DateRangePickerState) =>
    state.dateRanges[state.currentPeriod];
