import { useOptimisticRemove } from '../../../hooks/use-optimistic-update';
import { ExplorerView } from '../../../components/ExplorerGrid/grid/utils/gridView';
import { deleteView } from '../../../api-client';

export const useRemoveView = (workspaceId: string) => {
    const { remove } = useOptimisticRemove<ExplorerView>(
        async viewId => {
            await deleteView({ workspaceId, viewId });
        },
        ['explorer-views', workspaceId]
    );

    return {
        remove,
    };
};
