import { ReactNode } from 'react';
import { DashboardIcon } from '../Icons/DashboardIcon';
import { ExplorerIcon } from '../Icons/ExplorerIcon';
import { AccountCircle, Message, Warning } from '@mui/icons-material';
import { matchPath, useLocation } from 'react-router-dom';
import { useGettingStartedDrawer } from '../Drawer/useGettingStartedDrawer.tsx';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useActiveWorkspaceFromRoute } from '../../providers/useActiveWorkspaceFromRoute.ts';

export type Story = { key: string; label: string; cta: string };

export type Step = {
    id: string;
    label: string;
    description: string;
    icon: ReactNode;
    stories: Story[];
    pattern: string | string[];
    ctaButton: {
        label: string;
        href: string;
    };
};
export const useGettingStartedSteps = () => {
    const workspace = useActiveWorkspaceFromRoute();
    const { section, openDrawer } = useGettingStartedDrawer();
    const { pathname } = useLocation();

    const { annotations } = useFlags();

    const steps: Step[] = [];

    steps.push(
        {
            id: 'dashboards',
            label: 'Dashboard',
            pattern: [
                '/workspaces/:workspaceId/dashboard',
                '/workspaces/:workspaceId/dashboard/*',
            ],
            description:
                'Bring all your CRM data together in a visual dashboard in just a few clicks.',
            icon: <DashboardIcon fontSize="extraSmall" />,
            stories: [
                {
                    key: 'create_a_graph_widget',
                    label: 'Create a Graph widget',
                    cta: 'https://app.storylane.io/share/rl83g75zocx1',
                },
                {
                    key: 'create_a_funnel_widget',
                    label: 'Create a Funnel widget',
                    cta: 'https://app.storylane.io/share/yuw3bixh8to5',
                },
                {
                    key: 'create_a_high/low_widget',
                    label: 'Create a High/Low widget',
                    cta: 'https://app.storylane.io/share/8l8vivdjhhxc',
                },
                {
                    key: 'create_a_table_widget',
                    label: 'Create a Table widget',
                    cta: 'https://app.storylane.io/share/kqlv9d5uzuz3',
                },
                {
                    key: 'create_a_performance_overview_widget',
                    label: 'Create a Performance Overview widget',
                    cta: 'https://app.storylane.io/share/xxl1req3eac0',
                },
                {
                    key: 'create_a_subscriber_widget',
                    label: 'Create a Subscriber widget',
                    cta: 'https://app.storylane.io/share/xfhbftonrtdt',
                },
                {
                    key: 'create_a_target_widget',
                    label: 'Create a Target widget',
                    cta: 'https://app.storylane.io/share/knkmlgrdkk4t',
                },
            ],
            ctaButton: {
                label: 'View dashboards',
                href: `/workspaces/${workspace?.id}/dashboard`,
            },
        },
        {
            id: 'data-explorer',
            label: 'Data explorer',
            pattern: '/workspaces/:workspaceId/explorer/*',
            description:
                'Easily group and aggregate your campaign data in many different ways.',
            icon: <ExplorerIcon fontSize="extraSmall" />,
            stories: [
                {
                    key: 'using_filters',
                    label: 'Using Filters',
                    cta: 'https://app.storylane.io/share/owvncxoxos2q',
                },
                {
                    key: 'group_rows',
                    label: 'Group rows',
                    cta: 'https://app.storylane.io/share/ebgtkxtvuzl8',
                },
                {
                    key: 'create_a_chart',
                    label: 'Create a chart',
                    cta: 'https://app.storylane.io/share/0jkr89eycxwh',
                },
                {
                    key: 'set_a_baseline',
                    label: 'Set a Baseline',
                    cta: 'https://app.storylane.io/share/ghgnjfav8yaz',
                },
                {
                    key: 'create_annotation_on_explorer',
                    label: 'Create a new annotation',
                    cta: 'https://app.storylane.io/share/lpwhc3btsp68',
                },
            ],
            ctaButton: {
                label: 'Start exploring',
                href: `/workspaces/${workspace?.id}/explorer`,
            },
        },
        {
            id: 'alerting',
            label: 'Alerting',
            description:
                'Get automatic alerts based on your campaign performance.',
            pattern: `/workspaces/:workspaceId/alerting`,
            icon: <Warning fontSize="extraSmall" />,
            stories: [
                {
                    key: 'significant_increase_in_unsubscribers',
                    label: 'Significant increase in unsubscribers',
                    cta: 'https://app.storylane.io/share/uws9wojsghws',
                },
                {
                    key: 'decrease_in_deliverability',
                    label: 'Decrease in deliverability',
                    cta: 'https://app.storylane.io/share/4avibl9rmn1i',
                },
                {
                    key: 'create_a_new_alert',
                    label: 'Create a new alert',
                    cta: 'https://app.storylane.io/share/hp1tvcetkryh',
                },
            ],
            ctaButton: {
                label: 'Add an alert',
                href: `/workspaces/${workspace?.id}/alerting`,
            },
        }
    );

    if (annotations) {
        steps.push({
            id: 'annotations',
            label: 'Annotations',
            description: 'Quickly understand fluctuations in your metrics.',
            pattern: [
                '/workspaces/:workspaceId/dashboard',
                '/workspaces/:workspaceId/dashboard/*',
                '/workspaces/:workspaceId/explorer',
                '/workspaces/:workspaceId/explorer/*',
            ],
            icon: <Message fontSize="extraSmall" />,
            stories: [
                {
                    key: 'create_annotation',
                    label: 'Create a new annotation',
                    cta: 'https://app.storylane.io/share/lpwhc3btsp68',
                },
            ],
            ctaButton: {
                label: 'Add a new annotation',
                href: `/workspaces/${workspace?.id}/dashboard`,
            },
        });
    }

    if (workspace && workspace.role === 'admin') {
        steps.push({
            id: 'team-members',
            label: 'Invite your team',
            description:
                'Invite your team so anyone can proactively monitor campaigns',
            pattern: `/workspaces/:workspaceId/settings/members`,
            icon: <AccountCircle fontSize="extraSmall" />,
            stories: [],
            ctaButton: {
                label: 'Invite team members',
                href: `/workspaces/${workspace.id}/settings/members`,
            },
        });
    }

    const currentPage = steps.find(({ pattern }) => {
        const patterns = Array.isArray(pattern) ? pattern : [pattern];
        return patterns.some(p => matchPath(p, pathname));
    });

    return {
        steps,
        activeStep: steps.find(s => s.id === section) || currentPage,
        currentPage,
        setActiveStep: (step: string | undefined) => openDrawer(step),
    };
};
