import React from 'react';
import { SettingsTab } from './SettingsTab.tsx';
import { SettingsForm } from './General/SettingsForm.tsx';
import { DeleteWorkspace } from './General/DeleteWorkspace.tsx';

export const General = () => {
    return (
        <SettingsTab title="General">
            <SettingsForm />
            <DeleteWorkspace />
        </SettingsTab>
    );
};
