import { AutocompleteWithDropdown } from '../AutocompleteWithDropdown/AutocompleteWithDropdown.tsx';
import * as React from 'react';
import { useMemo, useState } from 'react';
import { AutocompleteValue } from '../AutocompleteWithDropdown/Autocomplete.tsx';
import { ulid } from 'ulid';
import { AccessLevel, ObjectType } from '../../types/permission/permission.ts';
import { usePermissionMutation } from '../../hooks/use-permission-mutation.ts';
import { DropdownOption } from '../AutocompleteWithDropdown/AutocompleteDropdown.tsx';
import { usePermissions } from '../../hooks/use-permissions.ts';
import { useMembers } from '../../pages/WorkspaceSettings/Members/useMembers.tsx';
import { useQueryClient } from '@tanstack/react-query';
import { sharePermissionOptions } from './sharePermissionOptions.tsx';
import { useActiveWorkspaceId } from '../../providers/useActiveWorkspaceId.ts';

export function GrantObjectAccess({
    objectType,
    objectId,
}: {
    objectType: ObjectType;
    objectId: string;
}) {
    const workspaceId = useActiveWorkspaceId();
    const queryClient = useQueryClient();

    const { data: permissions = [], isLoading: isLoadingPerms } =
        usePermissions(workspaceId, objectId, objectType);

    const { data: members = [], isLoading: isLoadingMembers } = useMembers();

    const [autocompleteValues, setAutocompleteValues] = useState<
        AutocompleteValue[]
    >([]);

    const [dropdownValue, setDropdownValue] = useState<DropdownOption>(
        sharePermissionOptions[0]
    );

    const { mutateAsync, isLoading: isMutating } = usePermissionMutation();

    const handleOnSubmit = () => {
        if (dropdownValue) {
            Promise.all(
                autocompleteValues.map(member =>
                    mutateAsync({
                        id: ulid(),
                        accessLevel: dropdownValue.value as AccessLevel,
                        subjectId: member.value,
                        subjectType: 'user',
                        objectId: objectId,
                        objectType: objectType,
                        workspaceId: workspaceId,
                    })
                )
            ).then(() => {
                queryClient.invalidateQueries([
                    'usePermissions',
                    workspaceId,
                    objectId,
                    objectType,
                ]);
                queryClient.invalidateQueries([
                    'useWorkspacePermissions',
                    workspaceId,
                ]);
                setAutocompleteValues([]);
                setDropdownValue(sharePermissionOptions[0]);
            });
        }
    };

    const permittedSubjectIds = permissions.map(p => p.subjectId);
    const autocompleteOptions: AutocompleteValue[] = useMemo(
        () =>
            members
                .filter(member => !permittedSubjectIds.includes(member.id))
                .toSorted((a, b) => a.name.localeCompare(b.name))
                ?.map(value => ({
                    label: value.name,
                    secondaryLabel: value.email,
                    value: value.id,
                })),
        [members, permittedSubjectIds]
    );

    return (
        <AutocompleteWithDropdown
            buttonLabel="Share"
            isLoading={isLoadingPerms || isLoadingMembers || isMutating}
            autocompleteOptions={autocompleteOptions || []}
            autocompleteValue={autocompleteValues}
            autocompleteOnChange={v => setAutocompleteValues(v)}
            dropdownOptions={sharePermissionOptions}
            dropdownValue={dropdownValue}
            dropdownOnChange={v => setDropdownValue(v)}
            onSubmit={handleOnSubmit}
            autocompleteInputProps={{
                noResultsMessage:
                    'There are no other people in this workspace to share this view with.',
                placeholder:
                    autocompleteValues.length === 0
                        ? 'Share with...'
                        : undefined,
            }}
        />
    );
}
