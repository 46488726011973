import {
    Box,
    Card,
    Popper,
    PopperProps,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { format } from 'date-fns';
import { formatNumber } from '../../utils/number-format';
import * as React from 'react';
import { Condition, Dimension, HealthChartDatum } from './HealthChart';
import { useLocale } from '../../providers/LocaleProvider.hooks';
import { resultToColor } from '../../utils/resultToColor';
import { formatDimension } from '../../utils/dimension-format.ts';
import { parseDate } from '../../utils/date-format.ts';

type HealthChartTooltipProps = PopperProps & {
    data: HealthChartDatum;
    conditions: Condition[];
    dimension: Dimension | null;
};

export const HealthChartTooltip = ({
    data,
    conditions,
    dimension,
    ...props
}: HealthChartTooltipProps) => {
    const dateFormat = 'EEEE do, LLLL yyyy';
    const { rangeStart, rangeEnd, details } = data;

    return (
        <Popper sx={{ zIndex: 9999 }} {...props}>
            <Card elevation={2} sx={{ p: 2 }}>
                <Stack spacing={1}>
                    <Typography variant="body2" textAlign="center">
                        <strong>
                            {format(parseDate(rangeStart), dateFormat)}
                            {rangeStart !== rangeEnd &&
                                ` - ${format(parseDate(rangeEnd), dateFormat)}`}
                        </strong>
                    </Typography>

                    {dimension || conditions.length > 1 ? (
                        <ResultTable
                            conditions={conditions}
                            dimension={dimension}
                            details={details}
                        />
                    ) : (
                        <ResultStack
                            conditions={conditions}
                            metrics={details[0].metrics}
                            results={details[0].results}
                        />
                    )}
                </Stack>
            </Card>
        </Popper>
    );
};

type SimpleResultStackProps = Pick<HealthChartTooltipProps, 'conditions'> &
    Pick<
        HealthChartTooltipProps['data']['details'][number],
        'metrics' | 'results'
    >;
const ResultStack = ({
    conditions,
    metrics,
    results,
}: SimpleResultStackProps) => {
    const locale = useLocale();

    return conditions.map((condition, i) => (
        <Stack direction="row" justifyContent="space-between" key={i}>
            <Stack direction="row" spacing={1} sx={{ marginRight: 1.5 }}>
                <Box
                    sx={theme => ({
                        width: 4,
                        height: '100%',
                        background: resultToColor(results[i], theme),
                    })}
                ></Box>
                <Typography variant="body2">{condition.name}</Typography>
            </Stack>

            <Typography variant="body2">
                {formatNumber(metrics[i], condition.format, locale)}
            </Typography>
        </Stack>
    ));
};

type ResultTableProps = Pick<HealthChartTooltipProps['data'], 'details'> &
    Pick<HealthChartTooltipProps, 'dimension' | 'conditions'>;

const ResultTable = ({ dimension, conditions, details }: ResultTableProps) => {
    const locale = useLocale();
    return (
        <>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ width: 0, padding: 0 }} />
                        {dimension && <TableCell>{dimension.name}</TableCell>}
                        {conditions.map((c, i) => (
                            <TableCell key={i} sx={{ textAlign: 'right' }}>
                                {c.name}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {details
                        .slice(0, 10)
                        .map(
                            ({
                                dimension: dimensionValue,
                                result,
                                results,
                                metrics,
                            }) => (
                                <TableRow key={dimensionValue}>
                                    <TableCell
                                        sx={theme => ({
                                            width: 4,
                                            padding: 0,
                                            background: resultToColor(
                                                result,
                                                theme
                                            ),
                                        })}
                                    />
                                    {dimension && (
                                        <TableCell>
                                            {formatDimension(
                                                dimensionValue,
                                                dimension.format
                                            ) || '(empty)'}
                                        </TableCell>
                                    )}
                                    {conditions.map((condition, i) => (
                                        <TableCell
                                            key={i}
                                            sx={theme => ({
                                                textAlign: 'right',
                                                color:
                                                    results[i] === true
                                                        ? theme.palette.error
                                                              .main
                                                        : undefined,
                                            })}
                                        >
                                            {formatNumber(
                                                metrics[i],
                                                condition.format,
                                                locale
                                            )}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            )
                        )}
                </TableBody>
            </Table>
            {details.length > 10 && (
                <Typography variant="caption" fontStyle="italic">
                    Showing first 10 out of {details.length} results
                </Typography>
            )}
        </>
    );
};
