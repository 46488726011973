import { Navigate, useParams, useSearchParams } from 'react-router-dom';
import { useActiveWorkspace } from '../../providers/WorkspaceProvider.hooks';
import { useViews } from '../../hooks/use-explorer-views';
import { useLastExplorerViewId } from '../../hooks/use-last-explorer-view-id';
import { ViewNotFound } from '../NotFound/ViewNotFound.tsx';

export const RewriteToExplorerView = () => {
    const { viewId } = useParams<{ viewId: string | undefined }>();
    const [searchParams] = useSearchParams();
    const { workspaceId } = useActiveWorkspace();
    const lastViewId = useLastExplorerViewId();

    const { data: views = [], isLoading } = useViews();

    if (isLoading) {
        return null;
    }

    const viewIdFromUrl = viewId && views.find(v => v.id === viewId);
    const viewIdFromLocalStorage = views.find(v => v.id === lastViewId);
    const firstAvailableViewId = views.find(x => x !== undefined);

    const firstView =
        viewIdFromUrl || viewIdFromLocalStorage || firstAvailableViewId;

    if (!firstView) {
        return <ViewNotFound />;
    }

    return (
        <Navigate
            replace
            to={{
                pathname: `/workspaces/${workspaceId}/explorer/${firstView.id}`,
                search: searchParams.toString(),
            }}
        />
    );
};
