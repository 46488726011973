import {
    Line,
    LineChart,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';
import { SubscriberListDatum } from '../use-subscriber-list-data';
import { RechartTooltip } from '../../../../Rechart/RechartTooltip';
import React from 'react';
import { useTheme } from '@mui/material';

interface DeltaRechartProps {
    data: SubscriberListDatum[];
}

export const DeltaRechart = ({ data }: DeltaRechartProps) => {
    const absMax = Math.max(
        ...data.flatMap(row => [row.members_in, row.members_out].map(Math.abs))
    );
    const theme = useTheme();

    return (
        <ResponsiveContainer width="100%" height="100%">
            <LineChart data={data}>
                <Tooltip
                    cursor={false}
                    filterNull={false}
                    position={{ y: -100 }}
                    wrapperStyle={{ zIndex: 10 }}
                    content={<RechartTooltip />}
                />
                <XAxis
                    dataKey="date"
                    axisLine={false}
                    tickLine={false}
                    style={{ fontSize: '0.75rem' }}
                />
                <YAxis hide={true} domain={[-1.1 * absMax, 1.1 * absMax]} />
                <Line
                    type="monotone"
                    dataKey="members_delta"
                    name="Members delta"
                    stroke={theme.text.secondary}
                    dot={false}
                    activeDot={true}
                />
                <ReferenceLine y={0} stroke="rgb(226, 92, 59)" />
            </LineChart>
        </ResponsiveContainer>
    );
};
