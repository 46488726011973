import { Box, styled, Tooltip, Typography } from '@mui/material';
import { ThumbIcon } from './ThumbIcon';

export const Title = styled(Typography)`
    font-size: 14px;
    font-weight: 500;
    white-space: nowrap;
`;

export interface HighLowDisplayProps {
    type: 'high' | 'low';
    label: string | React.ReactNode;
    value: string;
}

const Container = styled(Box)`
    display: flex;
    align-items: center;
    margin-top: 4px;
    padding: 4px 8px;
`;

export const HighLowDisplay = ({ label, type, value }: HighLowDisplayProps) => (
    <Container>
        <ThumbIcon type={type} />
        <Box>
            <Tooltip title={label}>
                <Typography
                    variant="body2"
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                >
                    {`${label ?? ''}`}
                </Typography>
            </Tooltip>
            <Typography
                variant="mediumBoldBody"
                color={({ palette }) =>
                    type === 'high' ? palette.success.dark : palette.error.main
                }
            >
                {value}
            </Typography>
        </Box>
    </Container>
);
