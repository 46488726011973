import { useEffect } from 'react';
import { useSeinoStore } from '../store/seino-store';
import { useInitialData } from './use-initial-data';
import { useActiveWorkspaceFromRoute } from '../providers/useActiveWorkspaceFromRoute.ts';

export const useTrackVisit = () => {
    const workspace = useActiveWorkspaceFromRoute();
    const { data: user } = useInitialData();
    const { visitLogged, setVisitLogged } = useSeinoStore(store => store.user);

    useEffect(() => {
        if (user?.memberId && workspace && !visitLogged) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'loggedin_visit',
                user_id: user.memberId,
                workspace_id: workspace.id,
                workspace_name: workspace.name,
            });

            setVisitLogged(true);
        }
    }, [user, workspace, visitLogged, setVisitLogged]);
};
