import { Box, BoxProps } from '@mui/material';
import { getEngagementColor } from './segments';

export const SegmentMarker = (
    allProps: BoxProps & { segment: number; active?: boolean }
) => {
    const { segment, active, ...props } = allProps;
    const backgroundColor = getEngagementColor(segment);

    return (
        <Box
            sx={{
                width: '14px',
                height: '14px',
                display: 'inline-block',
                verticalAlign: 'middle',
                backgroundColor,
                ...(active
                    ? {
                          outline: `1px solid ${backgroundColor}`,
                          outlineOffset: '2px',
                      }
                    : {}),
            }}
            {...props}
        />
    );
};
