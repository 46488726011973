import { fieldExists, findField } from '../../queries/schema.ts';
import { useEffect, useMemo } from 'react';
import { Autocomplete, TextField, TextFieldProps } from '@mui/material';
import {
    DataModelField,
    DataModelSchema,
    sortFields,
} from '../../types/datamodel/schema.ts';
import { FieldError } from 'react-hook-form';

type SchemaFieldSelectProps = {
    schema: DataModelSchema;
    value: string | null;
    onChange: (value: string | null) => void;
    fieldFilter: (field: DataModelField) => boolean;
    error?: FieldError;
    includeNone?: boolean;
    textFieldProps?: TextFieldProps;
    fullWidth?: boolean;
};
export const SchemaFieldSelect = ({
    schema,
    value,
    onChange,
    fieldFilter,
    error = undefined,
    includeNone = false,
    textFieldProps,
    fullWidth,
}: SchemaFieldSelectProps) => {
    const isValid = !value || fieldExists(schema)(value);
    const options = useMemo(
        () =>
            (includeNone ? ['none'] : []).concat(
                schema.fields
                    .sort(sortFields)
                    .filter(fieldFilter)
                    .map(d => d.id)
            ),
        [fieldFilter, includeNone, schema.fields]
    );

    useEffect(() => {
        if (!isValid) {
            onChange(null);
        }
    }, [isValid, onChange]);

    return (
        <Autocomplete
            fullWidth={fullWidth}
            multiple={false}
            disableClearable={false}
            value={isValid ? (value === null ? 'none' : value) ?? null : null}
            options={options}
            getOptionLabel={id => findField(schema)(id)?.name || 'None'}
            groupBy={id => findField(schema)(id)?.category || ''}
            onChange={(_, field) => {
                onChange(field === 'none' ? null : field);
            }}
            renderInput={params => (
                <TextField
                    {...params}
                    variant={'outlined'}
                    fullWidth={fullWidth}
                    label="Field"
                    {...textFieldProps}
                    error={!!error}
                />
            )}
        />
    );
};
