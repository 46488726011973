import React, { Fragment } from 'react';
import { styled } from '@mui/material';

type Props = {
    maxLength?: number;
    onlyRenderWhenMatching?: boolean;
    query?: string;
    text: string;
};

const escapeRegex = (s: string) => s.replace(/[$()*+./?[\\\]^{|}-]/g, '\\$&');

const TruncatedText = ({
    text,
    maxLength,
}: {
    maxLength: number;
    text: string;
}) => {
    const truncatedText = maxLength !== 0 ? text.slice(0, maxLength) : text;
    return (
        <>
            {truncatedText}
            {truncatedText !== text ? '…' : ''}
        </>
    );
};

export const HighlightText = ({ text, query, maxLength = 0 }: Props) => {
    if (!query) {
        return <TruncatedText maxLength={maxLength} text={text} />;
    }

    const regex = new RegExp(
        query.trim().split(' ').map(escapeRegex).join('|'),
        'gi'
    );
    const parts = text.split(regex);
    const matches = text.match(regex);

    if (!matches?.length) {
        return <TruncatedText maxLength={maxLength} text={text} />;
    }

    return (
        <>
            {parts.map((part, index) => (
                <Fragment key={index}>
                    {part}
                    {matches && matches[index] && (
                        <Highlight>{matches[index]}</Highlight>
                    )}
                </Fragment>
            ))}
        </>
    );
};

const Highlight = styled(`mark`)(
    ({ theme }) => `
    background: ${theme.searchHighlight.background};
    color: ${theme.searchHighlight.text};
`
);
