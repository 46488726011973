import {
    Area,
    ComposedChart,
    Line,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';
import { Box, lighten, useTheme } from '@mui/material';
import { TargetData } from './useTargetData.ts';
import { formatNumber } from '../../utils/number-format.ts';
import { Target } from '../../types/targets';
import { DataModelField } from '../../types/datamodel/schema.ts';
import { useLocale } from '../../providers/LocaleProvider.hooks.ts';
import React, { useId, useMemo } from 'react';
import { ProgressLineTooltip } from './ProgressLineTooltip.tsx';
import { getTargetConditionColor } from './get-target-condition-color.ts';

const getBounds = (data: TargetData) => {
    if (!data.cumulativeValue) {
        return null;
    }

    return data.cumulativeValue >= data.expectedValue
        ? [data.expectedValue, data.cumulativeValue]
        : [data.cumulativeValue, data.expectedValue];
};

const isPositive = (data?: TargetData) => {
    return data && data.cumulativeValue >= data.expectedValue;
};

const isNegative = (data?: TargetData) =>
    data && data.cumulativeValue < data.expectedValue;

export function ProgressLine({
    target,
    firstValue,
    lastValue,
    dataModelField,
    data,
}: {
    target: Target;
    firstValue?: TargetData;
    lastValue?: TargetData;
    dataModelField?: DataModelField;
    data?: TargetData[];
}) {
    const gradientId = useId();
    const theme = useTheme();
    const locale = useLocale();

    const useGradientForLineColor =
        dataModelField?.agg?.type === 'weighted_avg';

    const elements = useMemo(
        () =>
            data?.flatMap((d, index) => {
                const withBounds = { ...d, bounds: getBounds(d) };

                if (index < data.length - 1) {
                    const next = {
                        ...data[index + 1],
                        bounds: getBounds(data[index + 1]),
                    };

                    if (next.cumulativeValue === null) {
                        return;
                    }

                    if (isPositive(d) && isNegative(next)) {
                        next.bounds = [
                            withBounds.expectedValue,
                            withBounds.expectedValue,
                        ];
                    }

                    const area = (
                        <Area
                            dot={false}
                            activeDot={false}
                            type="monotone"
                            key={`area-${index}`}
                            label={`area-${index}`}
                            data={[withBounds, next]}
                            dataKey="bounds"
                            tooltipType="none"
                            legendType="none"
                            strokeWidth={0}
                            isAnimationActive={false}
                            fill={lighten(
                                getTargetConditionColor(theme.palette, d),
                                0.8
                            )}
                        />
                    );

                    const line = (
                        <Line
                            dot={false}
                            activeDot={false}
                            type="monotone"
                            key={`line-${index}`}
                            name={dataModelField?.name}
                            data={[withBounds, next]}
                            tooltipType="none"
                            legendType="none"
                            dataKey="cumulativeValue"
                            label={`line-${index}`}
                            animationDuration={400}
                            strokeWidth={2}
                            stroke={lighten(
                                getTargetConditionColor(theme.palette, d),
                                0.4
                            )}
                        />
                    );

                    return useGradientForLineColor ? [] : [area, line];
                }
                return [];
            }),
        [data, dataModelField?.name, theme.palette, useGradientForLineColor]
    );

    if (!data || data.length < 1) return null;

    if (!firstValue || !lastValue || !dataModelField) {
        return null;
    }

    const stop = (color: 'success' | 'error', offset: number) => (
        <stop
            offset={`${offset}%`}
            stopColor={lighten(theme.palette[color].main, 0.4)}
        />
    );

    const values = data
        .filter(p => p.cumulativeValue !== null)
        .map(p => p.cumulativeValue);

    const yMin = Math.min(target.target, ...values);
    const yMax = Math.max(target.target, ...values);

    const threshold = (100 * (firstValue.expectedValue - yMin)) / (yMax - yMin);

    return (
        <Box height={100}>
            <ResponsiveContainer debounce={1}>
                <ComposedChart data={data}>
                    <defs>
                        <linearGradient
                            id={gradientId}
                            x1="0%"
                            x2="0%"
                            y1="100%"
                            y2="0%"
                        >
                            {stop('error', 0)}
                            {stop('error', threshold)}
                            {stop('success', threshold)}
                            {stop('success', 100)}
                        </linearGradient>
                    </defs>
                    <XAxis
                        scale="time"
                        type="number"
                        interval="preserveStartEnd"
                        dataKey="end"
                        hide
                        domain={[
                            target.dateStart.getTime(),
                            target.dateEnd.getTime(),
                        ]}
                    />

                    <Tooltip
                        position={{ y: -110, x: -15 }}
                        payloadUniqBy={payload => payload.name}
                        wrapperStyle={{
                            zIndex: theme.zIndex.modal,
                        }}
                        content={<ProgressLineTooltip field={dataModelField} />}
                    />

                    <YAxis
                        hide
                        strokeWidth={0}
                        tickFormatter={value =>
                            formatNumber(value, dataModelField.format, locale, {
                                mode: 'compact',
                            })
                        }
                        fontSize={12}
                        domain={[firstValue.cumulativeValue, target.target]}
                    />
                    {elements}
                    {useGradientForLineColor && (
                        <Line
                            dot={false}
                            activeDot={false}
                            type="monotone"
                            dataKey="cumulativeValue"
                            animationDuration={600}
                            name={dataModelField.name}
                            strokeWidth={2}
                            stroke={`url(#${gradientId})`}
                        />
                    )}
                    <Line
                        dot={false}
                        activeDot={false}
                        type="monotone"
                        dataKey="expectedValue"
                        animationDuration={600}
                        name={dataModelField.name}
                        strokeWidth={2}
                        strokeDasharray="3 3"
                        stroke={theme.border.dark}
                    />
                </ComposedChart>
            </ResponsiveContainer>
        </Box>
    );
}
