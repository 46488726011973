import { useLocalStorage } from 'usehooks-ts';

export function useStoredDashboard(workspaceId: string) {
    const [storedDashboardId, setStoredDashboardId] = useLocalStorage<
        string | undefined
    >(`dashboard-view-${workspaceId}`, undefined);

    return {
        storedDashboardId,
        setStoredDashboardId,
    };
}
