import { Divider, useMediaQuery, useTheme } from '@mui/material';
import {
    SeinoToolbar,
    ToolbarContent,
    ToolbarLeftContent,
    ToolbarRightContent,
} from './Navigation.styles';
import { OpenExplorerDialogButton } from './OpenExplorerDialogButton.tsx';
import { ExplorerViewToolbar } from './Toolbar/ExplorerViewToolbar';
import { CreateView } from './CreateView';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useActiveWorkspace } from '../../providers/WorkspaceProvider.hooks';
import { ExplorerView } from '../../components/ExplorerGrid/grid/utils/gridView';
import { DateRangePickerWrapper } from '../../components/DateRangePicker/DateRangePickerWrapper';
import { ViewSelector } from './ViewSelector.tsx';
import { AnnotationsButton } from '../../components/Annotations/AnnotationsButton.tsx';

export const Navigation = () => {
    const navigate = useNavigate();
    const { workspaceId } = useActiveWorkspace();
    const theme = useTheme();
    const lgOrLarger = useMediaQuery(theme.breakpoints.up('lg'));

    const handleViewCreate = ({ id }: ExplorerView) => {
        navigate(`/workspaces/${workspaceId}/explorer/${id}`);
    };

    return (
        <SeinoToolbar color="secondary">
            <ToolbarContent>
                <ToolbarLeftContent>
                    <ViewSelector />
                    <CreateView onCreate={handleViewCreate} />
                    <OpenExplorerDialogButton />
                    <AnnotationsButton />
                </ToolbarLeftContent>
                <ToolbarRightContent>
                    <ExplorerViewToolbar />
                    {lgOrLarger && <Divider orientation="vertical" />}
                    <DateRangePickerWrapper allowCompare={false} />
                </ToolbarRightContent>
            </ToolbarContent>
        </SeinoToolbar>
    );
};
