import { Box, styled } from '@mui/material';
import { grey } from '@mui/material/colors';

export const SearchInputBox = styled(Box)({
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    gap: 5,
    padding: 5,
    borderBottomWidth: 2,
    borderBottomStyle: 'solid',
    borderBottomColor: grey[200],
    width: '100%',
});
