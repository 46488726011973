import { SnackbarMessage, useSnackbar, VariantType } from 'notistack';
import { useCallback } from 'react';

export const useNotify = () => {
    const { enqueueSnackbar } = useSnackbar();

    return useCallback(
        (msg: SnackbarMessage, variant: VariantType = 'default') => {
            enqueueSnackbar(msg, {
                variant,
            });
        },
        [enqueueSnackbar]
    );
};
