import { useQuery } from '@tanstack/react-query';
import { getAlertRuleResults } from '../api-client/alert-rules';
import { AlertRuleResults } from '../types/alerting';
import { useActiveWorkspaceId } from '../providers/useActiveWorkspaceId.ts';

export const useAlertRuleResults = (ruleId: string) => {
    const workspaceId = useActiveWorkspaceId();

    return useQuery<AlertRuleResults>({
        queryKey: [workspaceId, 'alert-rule-results', ruleId],
        queryFn: async () => {
            return getAlertRuleResults(workspaceId, ruleId);
        },
    });
};
