import { matchRoutes, useLocation } from 'react-router-dom';
import { useInitialData } from '../hooks/use-initial-data.ts';
import { routes } from '../const/routes.ts';

export const useActiveWorkspaceFromRoute = () => {
    const { pathname } = useLocation();
    const { data: currentUser } = useInitialData();

    const workspaceId = matchRoutes(routes, pathname)?.[0].params.workspaceId;
    if (!currentUser || !workspaceId) {
        return undefined;
    }

    return currentUser.workspaces.find(
        w => w.id === workspaceId || w.name === workspaceId
    );
};
