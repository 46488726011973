import {
    useMutation,
    UseMutationOptions,
    useQueryClient,
} from '@tanstack/react-query';
import axios from 'axios';
import { getRustAPIUrl, SimpleError } from '../../../../utils/api';

const connect = (
    workspaceId: string,
    integrationId: string,
    apiKey: string
) => {
    return axios.put(
        `${getRustAPIUrl()}/workspaces/${workspaceId}/integrations/klaviyo/${integrationId}/connect`,
        { api_key: apiKey }
    );
};

type Options = Pick<
    UseMutationOptions<
        unknown,
        Error | SimpleError,
        {
            workspaceId: string;
            integrationId: string;
            apiKey: string;
        }
    >,
    'onSuccess' | 'onError'
>;
export const useConnectKlaviyo = (options?: Options) => {
    const queryClient = useQueryClient();
    return useMutation(
        ({ workspaceId, integrationId, apiKey }) =>
            connect(workspaceId, integrationId, apiKey),
        {
            ...options,
            onSuccess: (data, variables, context) => {
                queryClient.invalidateQueries([
                    'useIntegrations',
                    variables.workspaceId,
                ]);
                options?.onSuccess?.(data, variables, context);
            },
        }
    );
};
