import { useEffect } from 'react';

export const useLastWorkspaceId = () => {
    return window.localStorage.getItem('workspace');
};

export const useSetLastWorkspaceId = (workspaceId: string | undefined) => {
    useEffect(() => {
        if (!workspaceId) {
            return;
        }

        window.localStorage.setItem('workspace', workspaceId);
    }, [workspaceId]);
};
