import { QueryKey, useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { getRustAPIUrl } from '../../utils/api';
import { InitialData } from '../../types/user';
import { match, P } from 'ts-pattern';

const dismissGetStarted = async () => {
    await axios.post(`${getRustAPIUrl()}/getting-started/dismiss`);
};

const queryKey: QueryKey = ['fetch-initial-data'];

export const useDismissGettingStarted = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: dismissGetStarted,
        onMutate: async () => {
            await queryClient.cancelQueries({
                queryKey: [],
            });
            const previous = queryClient.getQueryData<InitialData>(queryKey);

            const newValue: InitialData | undefined = match(previous)
                .with(P.not(P.nullish), (selections, value) => ({
                    ...value,
                    showGettingStarted: false,
                }))
                .otherwise(() => undefined);

            queryClient.setQueryData<InitialData>(queryKey, () => newValue);

            return { previous, newValue };
        },
        onError: (err, _, context) =>
            queryClient.setQueryData(queryKey, context?.previous),
        onSettled: async () => await queryClient.invalidateQueries(queryKey),
    });
};
