export interface InitialData {
    workspaces: Workspace[];
    onboardingRequired: boolean;
    memberId: string;
    name: string;
    email: string;
    showGettingStarted: boolean;
    isSeinoAdmin?: boolean;
}

export type Workspace = {
    id: string;
    name: string;
    status: string;
    displayName: string;
    role: string;
    locale: string;
    licenseId?: number;
    licenseType?: string;
    licenseHolder?: string;
};

export const isDemoWorkspace = (workspace?: Workspace) =>
    workspace?.status === 'new';

export interface ModifiedBy {
    id: string;
    name: string;
    email: string;
}

export function userToModifiedBy(user: InitialData): ModifiedBy {
    return {
        id: user.memberId,
        email: user.email,
        name: user.name,
    };
}
