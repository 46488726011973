import { EmptyTarget } from './EmptyTarget.tsx';
import { Alert, Container, Stack } from '@mui/material';
import React from 'react';
import { TargetCard } from './TargetCard.tsx';
import { TargetDialog } from './TargetDialog.tsx';
import { Info } from '@mui/icons-material';
import { useTargets } from './useTargets.ts';
import { useDialog } from '../../components/Dialog/useDialog.ts';
import { DatasetSchemaPreloader } from '../../components/DatasetSchemaPreloader/DatasetSchemaPreloader.tsx';
import { isEmpty } from 'lodash';
import { NewTarget } from './NewTarget.tsx';

export function TargetsPage() {
    const { data: targets, isLoading } = useTargets();

    const { openDialog } = useDialog<{ id?: string }>('target');

    const handleTargetOnEdit = (id: string) => openDialog({ id });

    return (
        <>
            <DatasetSchemaPreloader />
            <TargetDialog />
            <Container maxWidth="xl" sx={{ marginTop: 3 }}>
                <Alert icon={<Info fontSize="inherit" />} severity="info">
                    <strong>This functionality is still in beta</strong>
                </Alert>

                {!isLoading && isEmpty(targets) && <EmptyTarget />}

                <Stack direction="row" mt={2} gap={2} flexWrap="wrap">
                    {targets?.map(target => (
                        <TargetCard
                            key={target.id}
                            isLoading={isLoading}
                            target={target}
                            onEdit={handleTargetOnEdit}
                        />
                    ))}

                    {!isEmpty(targets) && <NewTarget />}
                </Stack>
            </Container>
        </>
    );
}
