type Event = { type: string };
type EventHandler<T> = (data: T) => void;
type Unsubscribe = () => void;

export interface EventBus<T extends Event> {
    subscribe: <K extends T['type']>(
        type: K,
        eventHandler: EventHandler<Extract<T, { type: K }>>
    ) => Unsubscribe;

    publish: (data: T) => void;
}

/**
 * Create an event bus for an app. The result is meant to be a singleton.
 *
 * @example
 *
 * ```ts
 * type Event =
 *  | { type: 'a', foo: string }
 *  | { type: 'b', bar: number };
 *
 * const eventBus = createEventBus<Event>();
 *
 * event.subscribe('a', (data) => {
 *     console.log(data.foo);
 * });
 *
 * event.publish({ type: 'a', foo: 'bar' });
 * ```
 */
export const createEventBus = <T extends Event>(): EventBus<T> => {
    const customEventTarget = new EventTarget();

    const subscribe = <K extends T['type']>(
        type: K,
        eventHandler: (data: Extract<T, { type: K }>) => void
    ) => {
        const eventListener = (event: Event) => {
            const data = (event as CustomEvent).detail as Extract<
                T,
                { type: K }
            >;

            eventHandler(data);
        };

        customEventTarget.addEventListener(type, eventListener);

        return () => {
            customEventTarget.removeEventListener(type, eventListener);
        };
    };

    const publish = (data: T) => {
        customEventTarget.dispatchEvent(
            new CustomEvent(data.type, { detail: data })
        );
    };

    return { subscribe, publish };
};
