import { createSvgIcon } from '@mui/material';

export const DashboardIcon = createSvgIcon(
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M2.08331 5.41668C2.08331 3.84533 2.08331 3.05965 2.57147 2.5715C3.05962 2.08334 3.8453 2.08334 5.41665 2.08334C6.98799 2.08334 7.77367 2.08334 8.26182 2.5715C8.74998 3.05965 8.74998 3.84533 8.74998 5.41668C8.74998 6.98803 8.74998 7.7737 8.26182 8.26186C7.77367 8.75001 6.98799 8.75001 5.41665 8.75001C3.8453 8.75001 3.05962 8.75001 2.57147 8.26186C2.08331 7.7737 2.08331 6.98803 2.08331 5.41668Z"
            stroke="currentColor"
            strokeWidth="1.5"
        />
        <path
            d="M11.25 14.5833C11.25 13.012 11.25 12.2263 11.7382 11.7382C12.2263 11.25 13.012 11.25 14.5833 11.25C16.1547 11.25 16.9404 11.25 17.4285 11.7382C17.9167 12.2263 17.9167 13.012 17.9167 14.5833C17.9167 16.1547 17.9167 16.9404 17.4285 17.4285C16.9404 17.9167 16.1547 17.9167 14.5833 17.9167C13.012 17.9167 12.2263 17.9167 11.7382 17.4285C11.25 16.9404 11.25 16.1547 11.25 14.5833Z"
            stroke="currentColor"
            strokeWidth="1.5"
        />
        <path
            d="M2.08331 14.5833C2.08331 13.012 2.08331 12.2263 2.57147 11.7382C3.05962 11.25 3.8453 11.25 5.41665 11.25C6.98799 11.25 7.77367 11.25 8.26182 11.7382C8.74998 12.2263 8.74998 13.012 8.74998 14.5833C8.74998 16.1547 8.74998 16.9404 8.26182 17.4285C7.77367 17.9167 6.98799 17.9167 5.41665 17.9167C3.8453 17.9167 3.05962 17.9167 2.57147 17.4285C2.08331 16.9404 2.08331 16.1547 2.08331 14.5833Z"
            stroke="currentColor"
            strokeWidth="1.5"
        />
        <path
            d="M11.25 5.41668C11.25 3.84533 11.25 3.05965 11.7382 2.5715C12.2263 2.08334 13.012 2.08334 14.5833 2.08334C16.1547 2.08334 16.9404 2.08334 17.4285 2.5715C17.9167 3.05965 17.9167 3.84533 17.9167 5.41668C17.9167 6.98803 17.9167 7.7737 17.4285 8.26186C16.9404 8.75001 16.1547 8.75001 14.5833 8.75001C13.012 8.75001 12.2263 8.75001 11.7382 8.26186C11.25 7.7737 11.25 6.98803 11.25 5.41668Z"
            stroke="currentColor"
            strokeWidth="1.5"
        />
    </svg>,
    'Dashboard icon'
);
