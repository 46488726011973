import { IntegrationInfo } from '../IntegrationInfo.tsx';
import { useState } from 'react';
import { EnableDeployteq } from './EnableDeployteq.tsx';
import { useIntegrations } from '../../../../hooks/use-integrations.ts';
import { BrandList } from './BrandList.tsx';
import { ulid } from 'ulid';
import { useActiveWorkspace } from '../../../../providers/WorkspaceProvider.hooks.ts';

export const DeployteqIntegration = () => {
    const { workspaceId } = useActiveWorkspace();

    const [openDialog, setOpenDialog] = useState(false);
    const [integrationId, setIntegrationId] = useState('');
    const { data } = useIntegrations();
    const integration = data?.find(x => x.type === 'deployteq');
    const isEnabled = Boolean(integration);

    const handleEnableClick = () => {
        setOpenDialog(true);
        setIntegrationId(ulid());
    };

    return (
        <>
            <IntegrationInfo
                integrationType={'deployteq'}
                enabled={isEnabled}
                enableButtonProps={{ onClick: handleEnableClick }}
            />
            {isEnabled && <BrandList workspaceId={workspaceId} />}
            <EnableDeployteq
                workspaceId={workspaceId}
                integrationId={integrationId}
                open={openDialog}
                onEnable={() => setOpenDialog(false)}
                onClose={() => setOpenDialog(false)}
            />
        </>
    );
};
