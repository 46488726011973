import { useSearchParams } from 'react-router-dom';

function urlSearchParamsToRecord<Parameters extends Record<string, string>>(
    searchParams: URLSearchParams
): Parameters {
    const params: Record<string, string> = {};

    for (const [key, value] of searchParams.entries()) {
        params[key] = value;
    }

    return params as Parameters;
}

function extractParams<Parameters extends Record<string, string>>(
    searchParams: Parameters,
    prefixed: boolean
): Parameters {
    const params: Record<string, string> = {};
    for (const key in searchParams) {
        if (prefixed && key.startsWith('drawer.')) {
            params[key.replace(new RegExp(`^drawer.`), '')] = searchParams[key];
        } else {
            params[key] = searchParams[key];
        }
    }

    return params as Parameters;
}

function useCloseDrawer() {
    const [_, setSearchParams] = useSearchParams();

    return {
        close: () =>
            setSearchParams(prev => {
                const newParams = new URLSearchParams();
                for (const [key, value] of prev.entries()) {
                    if (!key.startsWith('drawer.')) {
                        newParams.set(key, value);
                    }
                }
                return newParams;
            }),
    };
}

function useOpenDrawer<Parameters extends Record<string, string>>() {
    const [_, setSearchParams] = useSearchParams();
    return {
        open: (type: string, parameters: Parameters) =>
            setSearchParams(prev => {
                const params = extractParams<Parameters>(parameters, false);

                prev.set('drawer.type', type);
                for (const [key, value] of Object.entries(params ?? [])) {
                    prev.set(`drawer.${key}`, value);
                }

                return prev;
            }),
    };
}

export function useDrawer<Parameters extends Record<string, string>>(
    type?: string
) {
    const { close } = useCloseDrawer();
    const { open } = useOpenDrawer();
    const [searchParams] = useSearchParams();
    const params = extractParams<Parameters>(
        urlSearchParamsToRecord(searchParams),
        true
    );

    return {
        closeDrawer: () => close(),
        isOpen: type ? params['type'] === type : params['type'] !== undefined,
        currentDrawer: params['type'] || undefined,
        openDrawer: (parameters: Parameters) => {
            if (type === undefined)
                throw new Error('Cannot open drawer without provided type');

            open(type, parameters);
        },
        parameters: params,
    };
}
