import { Autocomplete, TextField, TextFieldVariants } from '@mui/material';
import { BinaryFilter } from '../../queries/filters';
import { useFilterBuilderContext } from './useFilterBuilderContext.tsx';
import {
    isFilterableDimension,
    isFilterableMetric,
    sortFields,
} from '../../types/datamodel/schema.ts';

interface FilterBuilderFieldProps {
    filter: BinaryFilter;
    index: number;
    onChange: (field: string | undefined) => void;
    variant?: TextFieldVariants;
}

export const FilterBuilderField = ({
    filter,
    index,
    onChange,
    variant,
}: FilterBuilderFieldProps) => {
    const {
        fieldType,
        schema,
        fieldState: { error },
    } = useFilterBuilderContext();

    const fields = Object.fromEntries(
        schema.fields
            .filter(
                fieldType === 'dimensions'
                    ? isFilterableDimension
                    : isFilterableMetric
            )
            .sort(sortFields)
            .map(d => [d.id, d])
    );

    const options = Object.keys(fields);

    return (
        <Autocomplete
            multiple={false}
            value={filter.member || null}
            options={options}
            getOptionLabel={id => fields[id]?.name || ''}
            groupBy={id => fields[id].category}
            onChange={(_, field) => onChange(field ?? undefined)}
            renderInput={params => (
                <TextField
                    {...params}
                    variant={variant ?? 'outlined'}
                    fullWidth
                    label="Field"
                    error={!!(error as any)?.[index]?.member}
                />
            )}
        />
    );
};
