import {
    SeinoDialogContainer,
    SeinoDialogContent,
    SeinoDialogTitle,
} from '../../../components/Dialog/SeinoDialog.styled.tsx';
import * as React from 'react';
import { useDashboardContext } from '../useDashboardContext.ts';
import { ShareObject } from '../../../components/ShareObject/ShareObject.tsx';
import { Stack } from '@mui/material';

export function ShareDialog() {
    const dashboardConfig = useDashboardContext();

    return (
        <SeinoDialogContainer>
            <SeinoDialogContent>
                <SeinoDialogTitle>Sharing</SeinoDialogTitle>
                <Stack spacing={2}>
                    <ShareObject
                        objectId={dashboardConfig.id}
                        objectType="dashboard"
                        currentAccessLevel={dashboardConfig.accessLevel}
                    />
                </Stack>
            </SeinoDialogContent>
        </SeinoDialogContainer>
    );
}
