import { CircularProgress, Fade } from '@mui/material';
import styled from 'styled-components';
import { useTimeout } from 'usehooks-ts';
import { captureMessage } from '@sentry/react';
import { useInitialData } from '../../hooks/use-initial-data';
import { useAuth0 } from '@auth0/auth0-react';

const LogoutWrapper = styled.div`
    position: absolute;
    z-index: 10000000;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: white;
    right: 0;
    text-align: center;

    .App-dark & {
        background-color: #181818;
    }
`;

const SpinnerContainer = styled.div`
    margin-top: 150px;
`;

export const SplashScreen = () => {
    const { isAuthenticated, isLoading, error, user } = useAuth0();
    const {
        data: initialData,
        isLoading: initialDataLoading,
        error: initialDataError,
    } = useInitialData();

    useTimeout(() => {
        captureMessage('User is staring at a spinner for 5s', {
            level: 'error',
            extra: {
                initialData,
                initialDataLoading,
                initialDataError,
                user,
                isAuthenticated,
                isLoading,
                error,
            },
        });
    }, 5000);

    return (
        <Fade in={true}>
            <LogoutWrapper>
                <SpinnerContainer>
                    <CircularProgress />
                </SpinnerContainer>
            </LogoutWrapper>
        </Fade>
    );
};
