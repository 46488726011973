import { useDrawer } from '../../hooks/use-drawer.ts';
import { useLocalStorage } from 'usehooks-ts';
import { useActiveUser } from '../../providers/CurrentUserProvider.hooks';

export const useGettingStartedDrawer = () => {
    const { isOpen, closeDrawer, openDrawer, parameters } = useDrawer<{
        section?: string;
    }>('getting-started');
    const [hideGettingStarted, setHideGettingStarted] = useLocalStorage(
        'hide-getting-started',
        false
    );
    const { showGettingStarted } = useActiveUser();

    return {
        isDismissed: !showGettingStarted || hideGettingStarted,
        isOpen,
        section: parameters?.section,
        openDrawer: (section?: string) => {
            openDrawer(section ? { section } : {});
            setHideGettingStarted(false);
        },
        closeDrawer: () => {
            closeDrawer();
            setHideGettingStarted(true);
        },
    };
};
