import { DemoBanner } from '../../components/Banner/DemoBanner.tsx';
import { DashboardToolbar } from './Toolbar.tsx';
import { Container, Stack } from '@mui/material';
import { useDashboardDataset } from '../../hooks/use-dashboard-dataset.ts';
import { Loader } from '../../components/Loader/Loader.tsx';
import { AlertCard } from '../../components/AlertCard/AlertCard.tsx';
import { DashboardWidgets } from './DashboardWidgets.tsx';
import { useSetSelectedDashboardId } from './useSetSelectedDashboardId.ts';
import React, { useCallback } from 'react';
import { DateRanges } from '../../components/DateRangePicker/reducer.tsx';
import { DateRangeProvider } from '../../providers/DateRangeProvider.tsx';
import { useDashboardFromRoute } from './useDashboardFromRoute.ts';
import { DashboardNotFound } from './DashboardNotFound.tsx';
import { DashboardContext } from './DashboardProvider.tsx';
import { useDashboardState } from './useDashboardState.ts';
import { setDateRanges } from '../../types/dashboard';
import { isPermitted } from '../../types/permission/permission.ts';
import { DashboardDialog } from './DashboardDialog.tsx';
import { useDashboardContext } from './useDashboardContext.ts';
import { TargetDialog } from '../Targets/TargetDialog.tsx';
import { AnnotationsDrawer } from '../../components/Annotations/AnnotationsDrawer.tsx';
import { useDrawer } from '../../hooks/use-drawer.ts';
import { useActiveWorkspaceId } from '../../providers/useActiveWorkspaceId.ts';

const IncompleteWorkspace = () => (
    <AlertCard severity="info">
        Your workspace has not been fully configured yet.
    </AlertCard>
);

const SetSelectedDashboard = ({ dashboardId }: { dashboardId: string }) => {
    const workspaceId = useActiveWorkspaceId();
    useSetSelectedDashboardId(workspaceId, dashboardId);

    return <></>;
};

export const Dashboard = () => {
    const { selectedDashboard, isLoading } = useDashboardFromRoute();

    const { isLoading: isLoadingCampaigns, error: isCampaignsError } =
        useDashboardDataset('campaign_metrics_by_send_date');

    if (isLoading || isLoadingCampaigns) {
        return <Loader />;
    }

    if (!selectedDashboard) {
        return <DashboardNotFound />;
    }

    if (isCampaignsError) {
        return <IncompleteWorkspace />;
    }

    return (
        <DashboardContext.Provider value={selectedDashboard}>
            <ValidDashboard />
        </DashboardContext.Provider>
    );
};

function ValidDashboard() {
    const { localDashboard, saveLocal } = useDashboardState();
    const { accessLevel } = useDashboardContext();

    const { currentDrawer } = useDrawer();

    const canWrite = isPermitted(accessLevel, 'write');

    const saveDateRanges = useCallback(
        (dateRanges: DateRanges<string>) =>
            canWrite &&
            saveLocal(dashboard => setDateRanges(dashboard, dateRanges)),
        [canWrite, saveLocal]
    );

    return (
        <DateRangeProvider
            dateRanges={localDashboard?.config?.dateRanges}
            setDateRanges={saveDateRanges}
            enabled={canWrite}
        >
            <SetSelectedDashboard dashboardId={localDashboard.id} />
            <DemoBanner />
            <DashboardToolbar />
            <DashboardDialog />
            <TargetDialog />
            {currentDrawer === 'annotations' && <AnnotationsDrawer />}

            <Container maxWidth="xl" sx={{ my: 2 }}>
                <Stack m={2} spacing={2}>
                    <DashboardWidgets />
                </Stack>
            </Container>
        </DateRangeProvider>
    );
}
