import { useUndoState } from '../../hooks/use-undo-state.ts';
import { useMemo } from 'react';
import { DashboardConfig } from '../../types/dashboard';
import { cloneDeep } from 'lodash';
import { useDashboardContext } from './useDashboardContext.ts';

export function useDashboardState() {
    const dashboard = useDashboardContext();

    const {
        value: localDashboard,
        newRemoteValue: newRemoteDashboard,
        save: saveLocal,
        clear: clearLocalChanges,
        canUndo,
        canRedo,
        redo,
        undo,
    } = useUndoState<DashboardConfig>(
        'dashboard',
        dashboard.id,
        100,
        dashboard,
        useMemo(() => (a, b) => a.version > b.version, [])
    );

    return useMemo(
        () => ({
            localDashboard: cloneDeep(localDashboard),
            newRemoteDashboard,
            saveLocal,
            clearLocalChanges,
            canUndo,
            canRedo,
            redo,
            undo,
        }),
        [
            localDashboard,
            newRemoteDashboard,
            saveLocal,
            clearLocalChanges,
            canUndo,
            canRedo,
            redo,
            undo,
        ]
    );
}
