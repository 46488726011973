import {
    Card,
    CardActionArea,
    CardContent,
    CardHeader,
    lighten,
    styled,
    useTheme,
} from '@mui/material';
import React, { PropsWithChildren } from 'react';
import { DeleteOutline, Edit } from '@mui/icons-material';
import { MoreMenu, MoreMenuItem } from '../../components/MoreMenu/MoreMenu.tsx';

type StyledTargetCardProps = {
    isNew?: boolean;
};

export const TARGET_CARD_WIDTH = 214;

export const TargetCardContainer = styled(Card, {
    shouldForwardProp: props => !['isNew'].includes(props.toString()),
})<StyledTargetCardProps>(({ theme, isNew }) => ({
    minWidth: TARGET_CARD_WIDTH,
    maxWidth: TARGET_CARD_WIDTH,
    minHeight: 180,
    maxHeight: 180,

    ...(isNew && {
        border: `1px dashed ${theme.palette.secondary.main}`,
        boxShadow: '3px 4px 20px 0 rgba(142, 146, 155, 0.1)',
    }),
}));

type TargetCardContentProps = {
    isNew?: boolean;
};
export const TargetCardContent = styled(CardContent, {
    shouldForwardProp: props => props.toString() !== 'isNew',
})<TargetCardContentProps>(({ theme, isNew }) => ({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',

    '&:last-child': {
        paddingBottom: 8,
    },

    ...(isNew && {
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        color: theme.palette.success.main,
    }),
}));

type TargetCardActionAreaProps = {
    isNew?: boolean;
};
export const TargetCardActionArea = styled(CardActionArea, {
    shouldForwardProp: props => props.toString() !== 'isNew',
})<TargetCardActionAreaProps>(({ theme, isNew }) => ({
    height: '100%',
    '&:hover': {
        background: isNew
            ? lighten(theme.palette.success.light, 0.8)
            : theme.elevation[1],
    },
}));

export function StyledTargetCard({
    children,
    title,
    subheader,
    isNew,
    onRemove,
    onRemoveFromDashboard,
    onEdit,
    onClick,
}: PropsWithChildren<{
    title?: string;
    subheader?: string | React.ReactElement;
    onRemove?: () => void;
    onRemoveFromDashboard?: () => void;
    onEdit?: () => void;
    isNew?: boolean;
    onClick?: () => void;
}>) {
    const theme = useTheme();
    const menuItems: MoreMenuItem[] = [];
    if (onEdit) {
        menuItems.push({
            label: 'Edit',
            key: 'edit',
            icon: <Edit fontSize="small" />,
            onClick: onEdit,
        });
    }
    if (onRemoveFromDashboard) {
        menuItems.push({
            label: 'Remove from dashboard',
            key: 'remove_from_dashboard',
            icon: <DeleteOutline fontSize="small" />,
            onClick: onRemoveFromDashboard,
            hasDivider: Boolean(onRemove),
        });
    }
    if (onRemove) {
        menuItems.push({
            label: 'Delete target',
            key: 'delete',
            icon: <DeleteOutline fontSize="small" color="error" />,
            onClick: onRemove,
        });
    }

    if (onClick && !onEdit) {
        return (
            <TargetCardContainer isNew={isNew}>
                <TargetCardActionArea isNew={isNew} onClick={onClick}>
                    <TargetCardContent isNew={isNew}>
                        {children}
                    </TargetCardContent>
                </TargetCardActionArea>
            </TargetCardContainer>
        );
    }

    return (
        <TargetCardContainer isNew={isNew} elevation={isNew ? 0 : 1}>
            <CardHeader
                sx={{
                    paddingBottom: 0,
                    display: 'flex',
                    '.MuiCardHeader-root': { overflow: 'hidden' },
                    '.MuiCardHeader-content': { overflow: 'hidden' },
                }}
                title={title}
                titleTypographyProps={{
                    variant: 'smallMediumBody',
                    textTransform: 'uppercase',
                    noWrap: true,
                }}
                subheader={subheader}
                subheaderTypographyProps={{
                    variant: 'h6',
                    color: theme.text.primaryLight,
                    noWrap: true,
                }}
                action={menuItems.length > 0 && <MoreMenu items={menuItems} />}
            />
            <TargetCardContent isNew={isNew}>{children}</TargetCardContent>
        </TargetCardContainer>
    );
}
