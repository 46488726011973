import {
    Box,
    CircularProgress,
    CircularProgressProps,
    Typography,
} from '@mui/material';
import { useDelayRender } from '../../hooks/use-delay-render';

export const CircularProgressWithLabel = ({
    value,
    ...props
}: CircularProgressProps) => {
    const visible = useDelayRender(500);

    if (!visible) {
        return null;
    }

    const variant = value === undefined ? 'indeterminate' : 'determinate';

    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress
                variant={variant}
                value={value}
                {...props}
                thickness={2}
                size="3rem"
            />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                {value && (
                    <Typography
                        variant="caption"
                        component="div"
                    >{`${Math.round(value)}%`}</Typography>
                )}
            </Box>
        </Box>
    );
};
