import {
    FormControl,
    FormControlLabel,
    FormGroup,
    Switch,
} from '@mui/material';
import { useSeinoStore } from '../../store/seino-store.ts';
import { isPermitted } from '../../types/permission/permission.ts';
import { useDashboardContext } from './useDashboardContext.ts';

export const ReorderModeSwitch = () => {
    const { reorderMode, setReorderMode } = useSeinoStore(
        state => state.dashboard
    );

    const { accessLevel } = useDashboardContext();

    if (!isPermitted(accessLevel, 'write')) {
        return null;
    }

    return (
        <FormControl>
            <FormGroup sx={{ pl: 2 }}>
                <FormControlLabel
                    componentsProps={{ typography: { whiteSpace: 'nowrap' } }}
                    control={
                        <Switch
                            size="small"
                            onChange={(_, checked) => setReorderMode(checked)}
                            color="success"
                            checked={reorderMode}
                        />
                    }
                    labelPlacement="end"
                    label="Reorder widgets"
                />
            </FormGroup>
        </FormControl>
    );
};
