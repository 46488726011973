import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { getRustAPIUrl } from '../../../utils/api.ts';
import { membersKey } from './useMembers.tsx';
import { useNotify } from '../../../hooks/use-notify.tsx';
import { useActiveWorkspaceId } from '../../../providers/useActiveWorkspaceId.ts';

type ChangeNameArgs = {
    memberId: string;
    name: string;
};

export const useChangeUserName = () => {
    const workspaceId = useActiveWorkspaceId();
    const queryClient = useQueryClient();
    const notify = useNotify();

    return useMutation({
        mutationFn: ({ memberId, name }: ChangeNameArgs) =>
            axios.put(
                `${getRustAPIUrl()}/workspaces/${workspaceId}/members/${memberId}/name`,
                { name }
            ),
        onSuccess: () => queryClient.invalidateQueries(membersKey(workspaceId)),
        onError: () => notify('Failed to update name', 'error'),
    });
};
