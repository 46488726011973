import { Box, Button, Card, CardContent, Typography } from '@mui/material';
import GettingStartedSteps from '../GetStarted/GettingStartedSteps';
import { SeinoDrawer } from './Drawer';
import * as React from 'react';
import { PropsWithChildren, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useGettingStartedDrawer } from './useGettingStartedDrawer';
import { DismissGettingStarted } from '../GetStarted/DismissGettingStarted.tsx';
import { Launch } from '@mui/icons-material';
import { useActiveUser } from '../../providers/CurrentUserProvider.hooks.ts';

const ExternalLink = ({ to, children }: PropsWithChildren<{ to: string }>) => (
    <Button
        component={Link}
        endIcon={<Launch fontSize="extraSmall" />}
        variant="link"
        to={to}
        target="_blank"
        rel="noreferrer"
    >
        {children}
    </Button>
);

const KnowledgeBase = () => (
    <Card
        elevation={0}
        sx={theme => ({
            backgroundColor: theme.elevation['base'],
            borderBottom: `1px solid ${theme.border.light}`,
            borderRadius: 0,
        })}
    >
        <CardContent
            sx={{
                py: 1,
                '&:last-child': {
                    paddingBottom: 0,
                },
            }}
        >
            <Typography variant="mediumBoldBody">Quick links</Typography>
            <ul>
                <li>
                    <ExternalLink to="https://seino.freshdesk.com/">
                        Knowledge base
                    </ExternalLink>
                </li>
                <li>
                    <ExternalLink to="https://seino.freshdesk.com/support/tickets/new">
                        Create a support ticket
                    </ExternalLink>
                </li>
            </ul>
        </CardContent>
    </Card>
);

export const GettingStartedDrawer = () => {
    const location = useLocation();
    const { isOpen, closeDrawer, openDrawer, isDismissed, section } =
        useGettingStartedDrawer();

    const { showGettingStarted } = useActiveUser();
    const handleOnClose = () => closeDrawer();

    useEffect(() => {
        if (!isDismissed) {
            openDrawer(section);
        }
        /* eslint-disable react-hooks/exhaustive-deps */
    }, [isDismissed, location.pathname]);

    return (
        <SeinoDrawer
            open={isOpen}
            variant="persistent"
            header="Get started with SEINō"
            onClose={closeDrawer}
        >
            <Box
                display="grid"
                gridTemplateRows={{
                    gridTemplateRows: 'min-content auto min-content',
                }}
            >
                <GettingStartedSteps />
                <KnowledgeBase />
                {showGettingStarted && (
                    <DismissGettingStarted onClose={handleOnClose} />
                )}
            </Box>
        </SeinoDrawer>
    );
};
