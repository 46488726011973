import { GetTokenSilentlyOptions, useAuth0 } from '@auth0/auth0-react';
import { AUTH0_API_AUDIENCE, AUTH0_DEFAULT_SCOPE } from '../constants';
import { useEffect } from 'react';
import { configureInterceptor } from '../interceptor';

const DEFAULT_OPTIONS: GetTokenSilentlyOptions = {
    authorizationParams: {
        audience: AUTH0_API_AUDIENCE,
        scope: AUTH0_DEFAULT_SCOPE,
    },
};

export const useConfigureInterceptor = () => {
    const { getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        return configureInterceptor(
            async () => await getAccessTokenSilently(DEFAULT_OPTIONS)
        );
    }, [getAccessTokenSilently]);
};
