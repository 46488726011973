import * as React from 'react';
import { useDialog } from '../../components/Dialog/useDialog.ts';
import { Button } from '@mui/material';

export function OpenExplorerDialogButton() {
    const { openDialog } = useDialog<{ panel: string }>('explorer');

    return (
        <Button
            size="small"
            color="primary"
            variant="text"
            onClick={() => openDialog({ panel: 'general' })}
        >
            Settings
        </Button>
    );
}
