import { Box, Button } from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import ConfirmDialog from '../ConfirmDialog/ConfirmAlert';
import { useDismissGettingStarted } from './useDismissGettingStarted';

type ConfirmAndHideGuideProps = {
    onClose: () => void;
};
export const DismissGettingStarted = ({
    onClose,
}: ConfirmAndHideGuideProps) => {
    const [open, setOpen] = useState(false);
    const mutate = useDismissGettingStarted();
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        onClose();
    };

    return (
        <Box margin="10px auto">
            <Button
                variant="outlined"
                size="small"
                sx={{ maxWidth: 'fit-content', alignSelf: 'center' }}
                onClick={handleOpen}
            >
                Complete and hide guide
            </Button>
            <ConfirmDialog
                open={open}
                title={`Dismiss "Getting started" guide`}
                content="If you've dismissed this section and need to revisit it, simply click the question mark in the top navigation to reopen it."
                continueText="Dismiss"
                cancelText="Cancel"
                onAnyDecision={handleClose}
                onContinue={async () => mutate.mutateAsync().then(handleClose)}
            />
        </Box>
    );
};
