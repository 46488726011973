import { DataModelSchema } from '../types/datamodel/schema';
import { match } from 'ts-pattern';
import { fieldExists } from './schema';
import { indentN } from './indent';

export function sqlForDimensions(
    schema: DataModelSchema,
    dimensions: string[]
) {
    return dimensions
        .filter(fieldExists(schema))
        .map(d => `${sqlForDimension(schema, d)},`)
        .join('\n');
}

export function sqlForDimensionsGroupBy(
    schema: DataModelSchema,
    dimensions: string[]
) {
    const dim = dimensions.filter(fieldExists(schema));
    if (dim.length === 0) {
        return '';
    }

    return `GROUP BY\n${dim.map(indentN(2)).join(',\n')}`;
}

function sqlForDimension(schema: DataModelSchema, dimension: string) {
    return match(schema.fields.find(f => f.id === dimension))
        .with(
            { format: 'date' },
            () => `strftime(${dimension}, '%x') AS ${dimension}`
        )
        .otherwise(() => dimension);
}

export function sqlForNonGroupedDimension(dimension: string) {
    return `any_value(${dimension}) as ${dimension}`;
}
