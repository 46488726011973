import { BinaryOperator } from '../../../../queries/filters.ts';

export const conditions: Record<string, { id: BinaryOperator; label: string }> =
    {
        equals: { id: 'equals', label: 'Is' },
        notEquals: { id: 'notEquals', label: 'Is not' },
        contains: { id: 'contains', label: 'Contains' },
        notContains: { id: 'notContains', label: 'Does not contain' },
        startsWith: { id: 'startsWith', label: 'Starts with' },
        endsWith: { id: 'endsWith', label: 'Ends with' },
        set: { id: 'set', label: 'Is not empty' },
        notSet: { id: 'notSet', label: 'Is empty' },
    };
