import { useMemo, useState } from 'react';

const strContains = (search: string, item: string) =>
    item.toLowerCase().includes(search.toLowerCase());

interface Item {
    id: string;
    name: string;
}

/**
 * @items An array of objects whose properties can be searched to return a matching subset of items
 * By Default the properties `id` and `name` will be tested.
 *
 *
 * @props The names of properties of `item` which should be checked
 *
 * Note: Only string fields are supported for now
 **/
export function useSearch<T extends Item>(
    items: T[],
    props: (keyof T & string)[] = []
) {
    const [search, setSearch] = useState('');

    const results = useMemo(() => {
        if (!search) {
            return items;
        }

        return items.filter(item => {
            if (
                strContains(search, item.id) ||
                strContains(search, item.name)
            ) {
                return true;
            }

            return props.some(prop => {
                const field = item[prop];

                return (
                    // Currently we only support string fields
                    typeof field === 'string' &&
                    field.toLowerCase().includes(search.toLowerCase())
                );
            });
        });
    }, [items, search, props]);

    return {
        results,
        search,
        setSearch,
    };
}
