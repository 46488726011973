import * as React from 'react';
import { FormDialog } from '../../../components/Dialog/FormDialog.tsx';
import { Stack, TextField, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useCreateWorkspace } from './useCreateWorkspace.tsx';
import { Workspace } from '../../../types/user';

type CreateWorkspaceDialogProps = {
    license?: Pick<Workspace, 'licenseId' | 'licenseHolder'>;
    open: boolean;
    onClose: () => void;
};

export const CreateWorkspaceDialog = ({
    open,
    onClose,
    license,
}: CreateWorkspaceDialogProps) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({});
    const { mutate, isLoading } = useCreateWorkspace();

    const onSubmit = handleSubmit(async ({ name }) => {
        mutate({
            name,
            license_id: license?.licenseId,
        });
    });

    return (
        <FormDialog
            disableRestoreFocus={true}
            open={open}
            title={`New workspace`}
            content={
                <Stack gap={2}>
                    {license && (
                        <Typography variant="body2">
                            Create a new workspace within the subscription of{' '}
                            {license.licenseHolder}
                        </Typography>
                    )}

                    <TextField
                        label="Name"
                        {...register('name', { required: true })}
                        error={!!errors.name}
                        helperText={errors.name && 'Name cannot be empty'}
                        autoFocus
                    />
                </Stack>
            }
            confirmButtonText="Save"
            handleClose={onClose}
            handleConfirm={onSubmit}
            confirmButtonProps={{
                loading: isLoading,
            }}
        />
    );
};
