import {
    AggregationColumnState,
    AgGridEvent,
    ColDef,
    ColumnPivotModeChangedEvent,
    ColumnSizeState,
    ColumnState,
    CsvExportParams,
    FilterChangedEvent,
    FilterModel,
    GetContextMenuItemsParams,
    GetRowIdParams,
    GridApi,
    GridReadyEvent,
    GridState,
    IsServerSideGroupOpenByDefaultParams,
    IToolPanelColumnCompParams,
    RowGroupOpenedEvent,
    SideBarDef,
    SortModelItem,
} from '@ag-grid-community/core';
import { GridConfig } from './grid/utils/gridView.ts';
import { DataModelSchema } from '../../types/datamodel/schema.ts';
import {
    forwardRef,
    Ref,
    useCallback,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useDuckConnection } from '../../hooks/use-duck-connection.ts';
import { useLocale } from '../../providers/LocaleProvider.hooks.ts';
import { useSeinoStore } from '../../store/seino-store.ts';
import { useGridConfig } from './use-grid-config.ts';
import { useDateRange } from '../../hooks/use-date-range.ts';
import { DataSource } from './dataSource.ts';
import { AgGridReact, AgGridReactProps } from '@ag-grid-community/react';
import {
    createColumnDefs,
    createFilter,
    getDefaultAggFunc,
} from './columnDefs.ts';
import { TotalRowCount } from './TotalRowCount.tsx';
import { debounce } from 'lodash';
import { FiltersAlert } from './FiltersAlert.tsx';
import { findField } from '../../queries/schema.ts';
import { DuckDataset } from '../../hooks/use-duck-dataset.ts';
import { addMissingColumns } from './grid/utils/addMissingColumns.ts';
import { createColumnWidthCalculator } from './grid/columnWidths.ts';

export type GridProps = AgGridReactProps & { dataset: DuckDataset };
export type GridHandle = { applyConfig: (state: GridConfig) => void };
export const Grid = forwardRef<GridHandle, GridProps>(GridRender);

function GridRender({ dataset, ...props }: GridProps, ref: Ref<unknown>) {
    const duckdb = useDuckConnection();
    const locale = useLocale();
    const weekStartsOn = useSeinoStore(state => state.views.firstDayOfWeek);

    const [api, setApi] = useState<GridApi | undefined>();
    const [config, setConfig] = useGridConfig();
    const { dateRanges } = useDateRange();

    const kpis = JSON.stringify(config.kpiData);
    const dataSource = useMemo(
        () =>
            new DataSource({
                dataset,
                duckdb,
                dateRange: dateRanges.main,
                columnDefContext: {
                    locale,
                    weekStartsOn,
                    kpiData: JSON.parse(kpis),
                    widthCalculator: createColumnWidthCalculator(),
                },
            }),
        [dataset, duckdb, dateRanges.main, locale, weekStartsOn, kpis]
    );

    useImperativeHandle(
        ref,
        () => ({
            applyConfig: (config: GridConfig) => {
                if (!api) {
                    console.warn(
                        'Attempting to applying config, but grid API is not ready yet'
                    );
                    return;
                }

                const apiColumns = api.getColumns();

                api.applyColumnState({
                    state:
                        config.columns && apiColumns
                            ? addMissingColumns(
                                  config.columns,
                                  apiColumns.map(c => ({
                                      colId: c.getColId(),
                                      headerName: c.getColDef().headerName,
                                  }))
                              )
                            : config.columns,
                    applyOrder: true,
                });
                config.valueColumns && api.setValueColumns(config.valueColumns);

                try {
                    api.setFilterModel(config?.filterModel);
                } catch (err) {
                    console.warn(err);

                    // Reset filters if setFilterModel fails, which can happen if
                    // the filter configuration was changed in a non-BC way.
                    api.setFilterModel(null);
                }

                dataSource.syncFields(api);
            },
        }),
        [api, dataSource]
    );

    const columnDefs: AgGridReactProps['columnDefs'] = useMemo(
        () =>
            createColumnDefs(dataset.schema.fields, {
                locale,
                weekStartsOn,
                kpiData: JSON.parse(kpis),
                widthCalculator: createColumnWidthCalculator(),
            }),
        [dataset.schema, locale, weekStartsOn, kpis]
    );

    const statusBar = useMemo(
        (): AgGridReactProps['statusBar'] => ({
            statusPanels: [
                {
                    statusPanel: TotalRowCount,
                    statusPanelParams: { dataSource },
                    align: 'left',
                },
                { statusPanel: 'agAggregationComponent' },
            ],
        }),
        [dataSource]
    );

    const sideBarConfig = useMemo(
        (): SideBarDef => ({
            defaultToolPanel: 'columns',
            toolPanels: [
                {
                    id: 'columns',
                    labelDefault: 'Columns',
                    labelKey: 'columns',
                    iconKey: 'columns',
                    toolPanel: 'agColumnsToolPanel',
                    toolPanelParams: {
                        suppressValues: true,
                    } satisfies Partial<IToolPanelColumnCompParams>,
                },
                {
                    id: 'filters',
                    labelDefault: 'Filters',
                    labelKey: 'filters',
                    iconKey: 'filter',
                    toolPanel: 'agFiltersToolPanel',
                },
            ],
        }),
        []
    );

    const onGridReady = useCallback(
        (e: GridReadyEvent) => setApi(e.api),
        [setApi]
    );

    const onColumnUpdate = useMemo(
        () =>
            debounce(<T extends AgGridEvent>(e: T) => {
                if (
                    ('finished' in e && !e.finished) ||
                    ('source' in e &&
                        (e.source === 'gridInitializing' || e.source === 'api'))
                ) {
                    return;
                }

                dataSource.syncFields(e.api);

                setConfig(config => ({
                    ...config,
                    columns: e.api.getColumnState(),
                    valueColumns: e.api
                        .getValueColumns()
                        .map(c => c.getColId()),
                }));
            }, 32),
        [setConfig, dataSource]
    );

    const onColumnPivotModeChanged = useCallback(
        ({ api }: ColumnPivotModeChangedEvent) => {
            // When pivot mode is turned OFF, we need to mark every metric as a
            // value column, otherwise sorting doesn't work when row grouping.

            // In pivot mode, every value column is visible. So to prevent ALL
            // metrics from being shown when transitioning from regular to
            // pivot mode, we only include previously visible columns.
            api.setValueColumns(
                (api.getColumns() || []).filter(f =>
                    api.isPivotMode()
                        ? f.isAllowValue() && f.isVisible()
                        : f.isAllowValue()
                )
            );

            setConfig(cfg => ({
                ...cfg,
                pivotMode: api.isPivotMode(),
                valueColumns: api.getValueColumns().map(c => c.getColId()),
            }));
        },
        [setConfig]
    );

    const onFilterChanged = useMemo(() => {
        return debounce((event: FilterChangedEvent) => {
            setConfig(cfg => ({
                ...cfg,
                filterModel: event.api.getFilterModel(),
            }));
        }, 400);
    }, [setConfig]);

    const expandedRowGroups = useRef<string[]>([]);

    const onRowGroupOpened = useCallback(
        (e: RowGroupOpenedEvent) => {
            if (!e.event) {
                return;
            }

            const state =
                e.api.getState().rowGroupExpansion?.expandedRowGroupIds ?? [];

            expandedRowGroups.current = e.expanded
                ? e.node.id
                    ? [...state, e.node.id]
                    : state
                : state.filter(f => e.node.id && !f.startsWith(e.node.id));
        },
        [expandedRowGroups]
    );

    const isServerSideGroupOpenByDefault = useCallback(
        (e: IsServerSideGroupOpenByDefaultParams) =>
            e.rowNode.id
                ? expandedRowGroups.current.includes(e.rowNode.id)
                : false,
        [expandedRowGroups]
    );

    const getRowId = useCallback((params: GetRowIdParams) => {
        return params.parentKeys
            ? `${params.parentKeys.join(';')};${params.data['__id']}`
            : params.data['__id'];
    }, []);

    const getChildCount = useCallback(
        (data: { _child_count: number }) => data['_child_count'],
        []
    );

    const initialState = useRef(createInitialState(config, dataset.schema));

    const getContextMenuItems = useCallback(
        (params: GetContextMenuItemsParams) => {
            const isGrouping = params.api.getRowGroupColumns().length > 0;

            return [
                'copy',
                'copyWithHeaders',
                'copyWithGroupHeaders',
                'separator',
                'autoSizeAll',
                ...(isGrouping ? ['expandAll', 'contractAll'] : []),
                'resetColumn',
                'separator',
                'chartRange',
                ...(config.pivotMode ? ['pivotChart'] : []),
                'export',
            ];
        },
        [config.pivotMode]
    );

    return (
        <>
            {api && <FiltersAlert grid={api} />}
            <AgGridReact
                animateRows={false}
                autoGroupColumnDef={autoGroupColumnDef}
                className="ag-theme-balham"
                columnDefs={columnDefs}
                columnMenu="legacy"
                context={dataSource}
                defaultCsvExportParams={defaultCsvExportParams}
                debug={process.env.NODE_ENV === 'development'}
                enableCharts={true}
                enableRangeSelection={true}
                getChildCount={getChildCount}
                getContextMenuItems={getContextMenuItems}
                getRowId={getRowId}
                grandTotalRow="bottom"
                initialState={initialState.current}
                isServerSideGroupOpenByDefault={isServerSideGroupOpenByDefault}
                onColumnMoved={onColumnUpdate}
                onColumnPinned={onColumnUpdate}
                onColumnPivotChanged={onColumnUpdate}
                onColumnPivotModeChanged={onColumnPivotModeChanged}
                onColumnResized={onColumnUpdate}
                onColumnRowGroupChanged={onColumnUpdate}
                onColumnValueChanged={onColumnUpdate}
                onColumnVisible={onColumnUpdate}
                onFilterChanged={onFilterChanged}
                onGridReady={onGridReady}
                onRowGroupOpened={onRowGroupOpened}
                onSortChanged={onColumnUpdate}
                pivotMode={config.pivotMode}
                pivotPanelShow={config.pivotMode ? 'always' : 'never'}
                reactiveCustomComponents={true}
                rowGroupPanelShow="always"
                rowModelType="serverSide"
                serverSideDatasource={dataSource}
                serverSideEnableClientSideSort={true}
                sideBar={sideBarConfig}
                statusBar={statusBar}
                suppressAggFuncInHeader={true}
                suppressFocusAfterRefresh={true}
                {...props}
            />
        </>
    );
}

const autoGroupColumnDef: ColDef = {
    pinned: 'left',
};

const defaultCsvExportParams: CsvExportParams = {
    skipColumnGroupHeaders: true,
};

const createInitialState = (
    config: GridConfig,
    schema: DataModelSchema
): GridState => {
    const columns = addMissingColumns(
        config.columns || [],
        schema.fields.map(f => ({ colId: f.id, headerName: f.name }))
    );

    const allValueCols = schema.fields
        .map(f => ({ colId: f.id, aggFunc: getDefaultAggFunc(f) }))
        .filter((c): c is AggregationColumnState => !!c.aggFunc);

    const aggregationModel = config.pivotMode
        ? allValueCols.filter(c => config.valueColumns?.includes(c.colId))
        : allValueCols;

    const cols = (callback: (c: ColumnState) => boolean) =>
        columns?.filter(callback) || [];

    const colIds = (callback: (c: ColumnState) => boolean) =>
        cols(callback).map(c => c.colId);

    return {
        filter: {
            filterModel: fixFilterModel(config.filterModel, schema),
        },
        columnVisibility: {
            hiddenColIds: colIds(c => !!c.hide),
        },
        aggregation: {
            aggregationModel,
        },
        rowGroup: {
            groupColIds: cols(c => !!c.rowGroup)
                .toSorted(
                    (a, b) => (a.rowGroupIndex ?? 0) - (b.rowGroupIndex ?? 0)
                )
                .map(c => c.colId),
        },
        columnPinning: {
            leftColIds: colIds(c => c.pinned === 'left'),
            rightColIds: colIds(c => c.pinned === 'right'),
        },
        columnSizing: {
            columnSizingModel: cols(c => !!c.width) as ColumnSizeState[],
        },
        sort: {
            sortModel: cols(c => !!c.sort) as SortModelItem[],
        },
        columnOrder: {
            orderedColIds: colIds(() => true),
        },
        pivot: config.pivotMode
            ? {
                  pivotMode: true,
                  pivotColIds: cols(c => !!c.pivot)
                      .toSorted(
                          (a, b) => (a.pivotIndex ?? 0) - (b.pivotIndex ?? 0)
                      )
                      .map(c => c.colId),
              }
            : undefined,
    };
};

/**
 * Ensure stored filters that are not forwards compatible with the current
 * filter configuration are fixed.
 */
const fixFilterModel = (
    filters: FilterModel,
    schema: DataModelSchema
): FilterModel => {
    return Object.fromEntries(
        Object.entries(filters ?? {})
            .map(
                ([colId, filterModel]) =>
                    [colId, fixFilter(schema, colId, filterModel)] as const
            )
            .filter((x): x is [string, FilterModel] => !!x[1])
    );
};

const fixFilter = (
    schema: DataModelSchema,
    colId: string,
    filterModel: unknown
) => {
    const field = findField(schema)(colId);
    if (!field) {
        return;
    }

    const filter = createFilter(field);
    const shouldBeMultiFilter =
        'filter' in filter && filter.filter === 'agMultiColumnFilter';
    const isMultiFilter =
        typeof filterModel === 'object' &&
        !!filterModel &&
        'filterModels' in filterModel;

    if (shouldBeMultiFilter === isMultiFilter) {
        return filterModel;
    }

    return undefined;
};
