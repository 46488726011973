import { Box, Table, TableBody, TableContainer } from '@mui/material';
import styled from 'styled-components';
import { WidgetConfig } from '../../../../types/dashboard';
import { Widget } from '../Widget';
import { HighLowContainer } from './components/HighLowContainer';
import { ErrorBoundary } from '@sentry/react';
import { WidgetError } from '../WidgetError.tsx';

const StyledPre = styled.pre`
    white-space: pre-wrap;
    word-wrap: break-word;
    background: lightgray;
`;

export const HighLow = (config: WidgetConfig) => {
    const DEBUG = false;
    return (
        <Widget config={config}>
            <ErrorBoundary fallback={<WidgetError />}>
                <Box id="high-low-container">
                    <TableContainer>
                        <Table>
                            <TableBody>
                                {config.metricsByDimensions?.map(
                                    ({ metric, dimension, filters }, i) => (
                                        <HighLowContainer
                                            key={`${metric}-${i}`}
                                            datasetName={config.dataset}
                                            metric={metric}
                                            dimension={dimension || ''}
                                            filters={filters || []}
                                            isLast={
                                                i ===
                                                config.metricsByDimensions!
                                                    .length -
                                                    1
                                                    ? true
                                                    : false
                                            }
                                        />
                                    )
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </ErrorBoundary>
            {DEBUG && <StyledPre>{JSON.stringify(config, null, 2)}</StyledPre>}
        </Widget>
    );
};
