import { HighLowDisplay, Title } from './HighLowDisplay';
import { useDashboardDataset } from '../../../../../hooks/use-dashboard-dataset.ts';
import { useLocale } from '../../../../../providers/LocaleProvider.hooks';
import { formatNumber, NumberFormat } from '../../../../../utils/number-format';
import { BinaryFilter } from '../../../../../queries/filters';
import { useHighLowData } from '../use-high-low-data';
import { Box, Skeleton, TableRow } from '@mui/material';
import { HighLowTableCell } from './HighLow.styles';
import { Localization } from '../../../../../types/datamodel/localization';
import { useDateRange } from '../../../../../hooks/use-date-range';
import { HighLowFilters } from './HighLowFilters.tsx';

interface HighLowContainerProps {
    datasetName: string;
    metric: string;
    dimension: string;
    filters: BinaryFilter[];
    label?: string;
    isLast: boolean;
}

export const HighLowContainer = ({
    label,
    datasetName,
    metric,
    dimension,
    filters,
    isLast,
}: HighLowContainerProps) => {
    const locale = useLocale();
    const { dateRanges } = useDateRange();
    const { data: dataset } = useDashboardDataset(datasetName);

    const metricMetaData = dataset?.metrics[metric];
    const dimensionMetaData = dataset?.dimensions[dimension];
    const valid = !!metricMetaData && !!dimensionMetaData;

    const {
        data: { high, low },
        isLoading,
        error,
    } = useHighLowData(
        datasetName,
        dateRanges.main,
        metric,
        dimension,
        filters,
        valid
    );

    if (error) {
        throw new Error(`${error}`);
    }

    if (!valid) {
        return null;
    }

    const moreIsBetter = dataset?.metrics[metric]?.moreIsBetter;

    const titleMetric = metricMetaData?.label || '';
    const titleDimension = dimensionMetaData?.label || '';

    if (isLoading) {
        return (
            <TableRow>
                <HighLowTableCell isLast={isLast} width="30%">
                    <Skeleton variant="text" width="100%" height="15px" />
                </HighLowTableCell>
                <HighLowTableCell isLast={isLast} width="35%">
                    <Skeleton
                        variant="rectangular"
                        width="100%"
                        height="25px"
                    />
                </HighLowTableCell>
                <HighLowTableCell isLast={isLast} width="35%">
                    <Skeleton
                        variant="rectangular"
                        width="100%"
                        height="25px"
                    />
                </HighLowTableCell>
            </TableRow>
        );
    }

    const contentCells = [
        getDisplay(
            high.label,
            high.value,
            moreIsBetter ? 'high' : 'low',
            metricMetaData?.format || NumberFormat.Generic,
            locale,
            isLast,
            `${metric}-${dimension}`
        ),
        getDisplay(
            low.label,
            low.value,
            moreIsBetter ? 'low' : 'high',
            metricMetaData?.format || NumberFormat.Generic,
            locale,
            isLast,
            `${metric}-${dimension}`
        ),
    ];

    return (
        <TableRow>
            <HighLowTableCell isLast={isLast} width="30%">
                <Title>
                    {label && label}
                    {!label && (
                        <>
                            {titleMetric}{' '}
                            <Box component="span" fontWeight="400">
                                by{' '}
                            </Box>
                            {titleDimension}
                        </>
                    )}
                </Title>
                <HighLowFilters dataset={datasetName} filters={filters} />
            </HighLowTableCell>
            {moreIsBetter ? contentCells : contentCells.reverse()}
        </TableRow>
    );
};

const getDisplay = (
    label: string | React.ReactNode,
    value: number,
    type: 'high' | 'low',
    format: NumberFormat,
    locale: Localization,
    isLast: boolean,
    title: string
) => (
    <HighLowTableCell isLast={isLast} key={`${type}-${title}`} width="35%">
        <HighLowDisplay
            value={formatNumber(value, format, locale)}
            label={label}
            type={type}
        />
    </HighLowTableCell>
);
