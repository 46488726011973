import styled from 'styled-components';
import { Box, Divider, Typography } from '@mui/material';
import { Settings } from '@mui/icons-material';

import Zoom from 'react-medium-image-zoom';

const StyledContent = styled(Box)`
    padding: 0 4rem 4rem 4rem;
    max-width: 1024px;
    min-width: 800px;
    height: auto;
    margin: auto;
    margin-top: 4rem;
    line-height: 2rem;
`;

const StyledImg = styled.img`
    width: 100%;
    height: auto;
`;

export const ChangelogPage = () => (
    <StyledContent>
        <Box>
            <Typography variant="h1">Recent Changes</Typography>
            <Typography variant="caption">
                Let us show you what's new in SEINō!
            </Typography>
            <Box my={2}>
                <Divider />
            </Box>
        </Box>
        <Box
            mb={2}
            display="flex"
            justifyContent="space-between"
            alignItems="flex-end"
        >
            <Typography variant="h5">Baselines</Typography>
            <Typography variant="h6" color="primary.light">
                September, 2023
            </Typography>
        </Box>

        <Box display="flex" marginY={2}>
            <Typography pr={4}>
                Establishing targets and baselines is a key part of achieving
                your goals. With the new Baselines feature, you can set targets
                for your metrics and track your progress towards them.
            </Typography>
            <Box>
                <Zoom>
                    <iframe
                        width="560"
                        style={{ border: 'none' }}
                        height="315"
                        src="https://www.youtube.com/embed/b97ZTBxHwHg?si=wVz5P90VFK-GPIvP"
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    ></iframe>
                </Zoom>
                <Typography variant="caption" ml={3}>
                    Learn how to work with baselines in the SEINō Data Explorer
                </Typography>
            </Box>
        </Box>
        <Box my={4}>
            <Divider />
        </Box>

        <Box
            mb={2}
            display="flex"
            justifyContent="space-between"
            alignItems="flex-end"
        >
            <Typography variant="h5">High/Low Widget</Typography>
            <Typography variant="h6" color="primary.light">
                August, 2023
            </Typography>
        </Box>

        <Box>
            <Box display="flex" flexDirection="column" marginRight={2}>
                <Typography pr={4}>
                    Want to know what's hot and what's not? Our new High / Low
                    widget will show you your best and worst performing
                    campaigns, markets, mailings and more! Simply select the
                    metric you're interested in and the dimension by which you
                    want to see it.
                </Typography>
                <Typography mt={2}>
                    For example, Revenue by Market will show you the best and
                    worst performing markets by revenue! Try it out today by
                    clicking the "Edit mode" switch on your dashboard and adding
                    a new High / Low widget!
                </Typography>
            </Box>
        </Box>
        <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
            marginTop={2}
        >
            <Zoom>
                <StyledImg src="https://seino.ai/assets/img/features/highlow.gif" />
            </Zoom>
            <Typography variant="caption" ml={3}>
                Get important insights easily with High / Low
            </Typography>
        </Box>
        <Box my={4}>
            <Divider />
        </Box>
        <Box
            mb={2}
            display="flex"
            justifyContent="space-between"
            alignItems="flex-end"
        >
            <Typography variant="h5">Shareable Explorer views</Typography>
            <Typography variant="h6" color="primary.light">
                July, 2023
            </Typography>
        </Box>
        <Box display="flex">
            <Box display="flex" flexDirection="column">
                <Typography pr={4}>
                    Now you can share views from the data explorer with members
                    of your team. Simply click on the <Settings /> icon in the
                    toolbar, then choose 'Sharing' on the left. From here you
                    can search for a user in your workspace and grant them Read,
                    Write or Full access!
                </Typography>
            </Box>
            <Box
                display="flex"
                justifyContent="center"
                flexDirection="column"
                alignItems="center"
            >
                <Zoom>
                    <StyledImg src="https://seino.ai/assets/img/features/sharing.gif" />
                </Zoom>
                <Typography variant="caption" ml={3}>
                    Sharing Explorer views with your team is easy!
                </Typography>
            </Box>
        </Box>
    </StyledContent>
);
