import {
    Autocomplete,
    createFilterOptions,
    FilterOptionsState,
    Slider,
    Stack,
    TextField,
    TextFieldVariants,
    Typography,
} from '@mui/material';
import { useCubeDimension } from '../../hooks/use-cube-dimension';
import {
    BinaryOperator,
    isEqualsOperator,
    isOutlierOperator,
} from '../../queries/filters';
import { findField } from '../../queries/schema';
import { NumberTextField } from '../Form/NumberInput';
import { useFilterBuilderContext } from './useFilterBuilderContext.tsx';
import { useMemo } from 'react';
import { getDimensionFormatter } from '../../utils/dimension-format.ts';

interface FilterBuilderValueProps {
    field: string;
    value: string[];
    operator: BinaryOperator;
    onChange: (value: string[] | null) => void;
    index: number;
    variant?: TextFieldVariants;
}

export const FilterBuilderValue = ({ ...props }: FilterBuilderValueProps) => {
    const { fieldType } = useFilterBuilderContext();
    if (fieldType === 'dimensions') {
        return <FilterBuilderDimensionValue {...props} />;
    } else {
        return <FilterBuilderMetricValue {...props} />;
    }
};
const FilterBuilderMetricValue = ({
    field,
    value,
    onChange,
    index,
    operator,
    variant,
}: Omit<FilterBuilderValueProps, 'fieldType'>) => {
    const {
        schema,
        fieldState: { error },
    } = useFilterBuilderContext();

    if (isOutlierOperator(operator)) {
        return <SensitivitySlider value={value} onChange={onChange} />;
    }

    const format = findField(schema)(field)?.format || 'number';

    return (
        <NumberTextField
            fullWidth={true}
            variant={variant}
            onChange={e => onChange([e.target.value || ''])}
            value={value[0] || ''}
            error={!!(error as any)?.[index]?.values}
            format={format}
        />
    );
};

const SensitivitySlider = ({
    onChange,
    value,
}: Pick<FilterBuilderValueProps, 'onChange' | 'value'>) => {
    const parsedValue = -parseFloat(value[0]);

    return (
        <Stack>
            <Typography fontSize="small">Sensitivity</Typography>
            <Slider
                size="small"
                value={parsedValue ?? -2.0}
                min={-4.0}
                max={0}
                step={0.25}
                scale={x => -1 * x}
                onChange={(_, v) => {
                    onChange([
                        typeof v === 'number' ? (-1 * v).toFixed(3) : '-2.0',
                    ]);
                }}
            />
            <Stack direction="row" justifyContent="space-between">
                <Typography fontSize="small">Low</Typography>
                <Typography fontSize="small">Medium</Typography>
                <Typography fontSize="small">High</Typography>
            </Stack>
        </Stack>
    );
};

const FilterBuilderDimensionValue = ({
    field,
    value,
    onChange,
    operator,
    index,
    variant,
}: Omit<FilterBuilderValueProps, 'fieldType'>) => {
    const {
        dataset,
        schema,
        fieldState: { error },
    } = useFilterBuilderContext();
    const { isLoading, data } = useCubeDimension(dataset, field);

    const format = findField(schema)(field)?.format;
    const options = useMemo(() => (data || []).filter(x => !!x), [data]);
    const freeSolo = !isEqualsOperator(operator);
    const filterOptions = !freeSolo
        ? createFilterOptions<string>({})
        : (options: string[], state: FilterOptionsState<string>) => {
              if (!state.inputValue) {
                  return [];
              }

              return [`${state.inputValue}`];
          };

    const getOptionLabel = useMemo(
        () => getDimensionFormatter(format),
        [format]
    );

    return (
        <Autocomplete
            onChange={(_, value) => onChange(value)}
            multiple
            autoSelect={freeSolo}
            freeSolo={freeSolo}
            loading={isLoading}
            disableCloseOnSelect={true}
            disabled={['set', 'notSet'].includes(operator)}
            value={value}
            options={options}
            getOptionLabel={getOptionLabel}
            filterOptions={filterOptions}
            ChipProps={{ variant: 'outlined' }}
            size="small"
            renderInput={params => (
                <TextField
                    {...params}
                    variant={variant ?? 'outlined'}
                    fullWidth
                    label="Value"
                    error={!!(error as any)?.[index]?.values}
                />
            )}
        />
    );
};
