import { Box, styled, Typography, TypographyProps } from '@mui/material';
import { PropsWithChildren } from 'react';

export const ConfigForm = ({ children }: PropsWithChildren) => {
    return <FormGrid>{children}</FormGrid>;
};
export const FormGrid = styled(Box)({
    display: 'grid',
    gridTemplateColumns: '100px 3fr',
    columnGap: '2rem',
    rowGap: '0.875rem',
});

const LabelInner = styled(Typography)({
    variant: 'mediumBoldBody',
});

export const Label = ({ children, ...props }: TypographyProps) => {
    return (
        <LabelInner
            sx={{ alignSelf: 'start', marginTop: '0.475rem', ...props.sx }}
            {...props}
        >
            {children}
        </LabelInner>
    );
};
