import {
    CanopyDeployIntegration,
    ConditionFieldMapping,
    FieldMapping,
    FieldMappingType,
} from '../../../../../types/integration';
import { useState } from 'react';
import {
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    SelectProps,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { match } from 'ts-pattern';
import { Edit } from '@mui/icons-material';
import { FieldMappingBuilder } from './FieldMappingRuleBuilder';
import { SelectMarket } from '../../GoogleAnalytics/SelectMarket';
import { FormDialog } from '../../../../../components/Dialog/FormDialog';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { getRustAPIUrl } from '../../../../../utils/api.ts';
import { integrationsQueryKey } from '../../../../../hooks/use-integrations.ts';

interface ConfigureMarketDialogProps {
    workspaceId: string;
    integration: CanopyDeployIntegration;
}

export const ConfigureBrandDialog = ({
    workspaceId,
    integration,
}: ConfigureMarketDialogProps) => {
    const [open, setOpen] = useState(false);

    const [name, setName] = useState(integration.name);
    const [fieldMapping, setFieldMapping] = useState<FieldMapping>(
        integration.field_mappings?.find(m => m.name === 'market') ?? {
            name: 'market',
            type: 'null',
        }
    );

    const queryClient = useQueryClient();
    const { mutateAsync, isLoading } = useMutation({
        mutationFn: (field: FieldMapping) =>
            axios.put(
                `${getRustAPIUrl()}/workspaces/${workspaceId}/integrations/${
                    integration.id
                }/fields/market`,
                field
            ),
        onSuccess: () =>
            queryClient.invalidateQueries(integrationsQueryKey(workspaceId)),
    });

    const onMappingChange = (mapping: ConditionFieldMapping) =>
        setFieldMapping(mapping);

    const onTypeChange = (event: SelectChangeEvent<FieldMappingType>) =>
        setFieldMapping(
            match(event.target.value)
                .with('null', () => ({ ...fieldMapping, type: 'null' }))
                .with('custom', () => ({
                    ...fieldMapping,
                    type: 'custom',
                    sql: '',
                }))
                .with('condition', () => ({
                    ...fieldMapping,
                    type: 'condition',
                    rules: (fieldMapping as ConditionFieldMapping).rules || [],
                }))
                .with('fixed_value', () => ({
                    ...fieldMapping,
                    type: 'fixed_value',
                    value: '',
                }))
                .otherwise(() => ({ type: 'null' })) as FieldMapping
        );

    return (
        <>
            <IconButton
                onClick={() => {
                    setOpen(true);
                }}
            >
                <Edit />
            </IconButton>
            <FormDialog
                open={open}
                content={
                    <Stack spacing={4} sx={{ paddingTop: 1 }}>
                        <TextField
                            label="Brand name"
                            size="small"
                            value={name}
                            onChange={e => {
                                setName(e.target.value);
                            }}
                        />

                        <Stack spacing={2}>
                            <Stack direction="row" spacing={2}>
                                <FieldMappingTypeSelect
                                    value={fieldMapping.type}
                                    onChange={onTypeChange}
                                />

                                {fieldMapping.type === 'fixed_value' && (
                                    <SelectMarket
                                        value={fieldMapping.value}
                                        onChange={market => {
                                            setFieldMapping({
                                                ...fieldMapping,
                                                type: 'fixed_value',
                                                value: market || '',
                                            });
                                        }}
                                    />
                                )}
                            </Stack>

                            {fieldMapping.type === 'condition' && (
                                <FieldMappingBuilder
                                    mapping={fieldMapping}
                                    onChange={onMappingChange}
                                />
                            )}
                            {fieldMapping.type === 'custom' && (
                                <Typography>
                                    Please contact SEINō to set up a custom
                                    market mapping.
                                </Typography>
                            )}
                        </Stack>
                    </Stack>
                }
                confirmButtonText="Save"
                confirmButtonProps={{
                    disabled: fieldMapping.type === 'custom',
                    loading: isLoading,
                }}
                maxWidth="md"
                fullWidth={true}
                handleClose={() => setOpen(false)}
                handleConfirm={async () => {
                    await mutateAsync(fieldMapping);
                    setOpen(false);
                }}
                title="Configure brand"
            />
        </>
    );
};
const FieldMappingTypeSelect = (props: SelectProps<FieldMappingType>) => {
    return (
        <FormControl fullWidth={false} sx={{ width: 200 }}>
            <InputLabel size="small">Market mapping</InputLabel>
            <Select size="small" label="Market mapping" {...props}>
                <MenuItem value="null">None</MenuItem>
                <MenuItem value="fixed_value">Single market</MenuItem>
                <MenuItem value="condition">Multiple markets</MenuItem>
                <MenuItem value="custom">Custom</MenuItem>
            </Select>
        </FormControl>
    );
};
