import * as duckdb from '@duckdb/duckdb-wasm';
import duckdb_wasm from '@duckdb/duckdb-wasm/dist/duckdb-mvp.wasm?url';
import mvp_worker from '@duckdb/duckdb-wasm/dist/duckdb-browser-mvp.worker.js?url';
import duckdb_wasm_eh from '@duckdb/duckdb-wasm/dist/duckdb-eh.wasm?url';
import eh_worker from '@duckdb/duckdb-wasm/dist/duckdb-browser-eh.worker.js?url';
import {
    AsyncDuckDB,
    AsyncDuckDBConnection,
    LogLevel,
} from '@duckdb/duckdb-wasm';

const MANUAL_BUNDLES: duckdb.DuckDBBundles = {
    mvp: {
        mainModule: duckdb_wasm,
        mainWorker: mvp_worker,
    },
    eh: {
        mainModule: duckdb_wasm_eh,
        mainWorker: eh_worker,
    },
};

import {
    createContext,
    MutableRefObject,
    useEffect,
    useRef,
    useState,
} from 'react';

let duckSingleton: ReturnType<typeof initDuckDB> | undefined;

const initDuckDB = async () => {
    const bundle = await duckdb.selectBundle(MANUAL_BUNDLES);
    const worker = new Worker(bundle.mainWorker!);
    const logger = new duckdb.ConsoleLogger(
        process.env.NODE_ENV === 'production' ? LogLevel.WARNING : LogLevel.INFO
    );

    const db = new duckdb.AsyncDuckDB(logger, worker);
    await db.instantiate(bundle.mainModule, bundle.pthreadWorker);

    await db.open({
        query: {
            castBigIntToDouble: true,
            castTimestampToDate: true,
        },
    });

    const connection = await db.connect();
    await connection.query(
        "SET custom_extension_repository = '/duckdb/extensions'"
    );

    return { db, connection };
};

interface DuckProviderProps {
    children: React.ReactNode;
}

export interface DuckContextProps {
    db: AsyncDuckDB;
    connection: AsyncDuckDBConnection;
    downloadProgress: MutableRefObject<Record<string, Record<string, number>>>;
    setProgress: (
        workspaceId: string,
        dataset: string,
        progress: number
    ) => void;
}

export const DuckContext = createContext<DuckContextProps | null>(null);

export const DuckProvider = ({ children }: DuckProviderProps) => {
    const downloadProgress: MutableRefObject<
        Record<string, Record<string, number>>
    > = useRef({});

    const [duck, setDuck] = useState<DuckContextProps | null>(null);

    useEffect(() => {
        if (duckSingleton === undefined) {
            duckSingleton = initDuckDB();
        }

        duckSingleton.then(duck => {
            setDuck({
                ...duck,
                downloadProgress,
                setProgress: (
                    workspaceId: string,
                    dataset: string,
                    progress: number
                ) => {
                    if (!downloadProgress.current[workspaceId]) {
                        downloadProgress.current[workspaceId] = {};
                    }

                    downloadProgress.current[workspaceId][dataset] = progress;
                },
            });
        });
    }, []);

    return <DuckContext.Provider value={duck}>{children}</DuckContext.Provider>;
};
