import { Box, Link, Typography } from '@mui/material';
import { InfoPanel } from '../InfoPanel/InfoPanel.tsx';

export const WorkspaceCancelledError = () => (
    <InfoPanel
        dividerTitle="Workspace cancelled"
        content={
            <Box marginBlock={2}>
                <Typography paragraph={true} variant="body1">
                    You’re trying to access a workspace that has been cancelled.
                </Typography>

                <Typography variant="body1">
                    If you think this is a mistake, please
                    <Link
                        href="mailto:support@seino.ai"
                        style={{ marginLeft: 4 }}
                    >
                        contact us
                    </Link>
                    .
                </Typography>
            </Box>
        }
    />
);
