import { Box, Card, Stack, Typography } from '@mui/material';

import { EngagementRow } from '../../hooks/use-engagement';
import { getEngagementColor } from './segments';
import React, { useContext } from 'react';
import { ResponsiveSankey } from '@nivo/sankey';
import { EngagementContext } from './EngagementContext';
import { formatNumber } from '../../utils/number-format';
import { useLocale } from '../../providers/LocaleProvider.hooks';
import { ArrowRightAlt } from '@mui/icons-material';

type ComparisonChartProps = {
    data: EngagementRow;
};

export const SegmentSankey = ({ data }: ComparisonChartProps) => {
    const { setSegment } = useContext(EngagementContext);
    const locale = useLocale();

    const inflowLinks = data.inflow
        .toArray()
        .filter(x => x.segment_id !== data.segment_id && x.segment_id !== null)
        .map(x => ({
            source: `From ${x.segment_name}`,
            target: `${data.segment_name}`,
            value: x.size,
            clickTarget: x.segment_id,
        }))
        .sort((a, b) => a.clickTarget - b.clickTarget);

    const outflowLinks = data.outflow
        .toArray()
        .filter(x => x.segment_id !== data.segment_id)
        .map(x => ({
            source: `${data.segment_name}`,
            target: `To ${x.segment_name}`,
            value: x.size,
            clickTarget: x.segment_id,
        }))
        .sort((a, b) => a.clickTarget - b.clickTarget);

    const nodes: { id: string; segment_id: number; color: string }[] = [
        ...inflowLinks.map(x => ({
            id: x.source,
            segment_id: x.clickTarget,
            color: getEngagementColor(x.clickTarget),
        })),
        {
            id: data.segment_name,
            segment_id: data.segment_id,
            color: getEngagementColor(data.segment_id),
        },
        ...outflowLinks.map(x => ({
            id: x.target,
            segment_id: x.clickTarget,
            color: getEngagementColor(x.clickTarget),
        })),
    ];

    const links = [...inflowLinks, ...outflowLinks];
    const colors = nodes.map(x => x.color);

    if (links.length === 0) {
        return (
            <Stack alignItems="center" justifyContent="center" height="100%">
                <Typography variant="body2">
                    No comparison available.
                </Typography>
            </Stack>
        );
    }

    return (
        <ResponsiveSankey
            label={x => x.id.replace(/(From|To) /, '')}
            enableLinkGradient={true}
            margin={{ top: 4, bottom: 4 }}
            layers={['links', 'nodes', 'labels']}
            colors={colors}
            valueFormat={number => formatNumber(number, 'number', locale)}
            data={{
                nodes,
                links,
            }}
            linkTooltip={({ link }) => {
                return (
                    <Tooltip
                        value={link.value}
                        from_id={link.source.segment_id}
                        from_label={link.source.label}
                        to_id={link.target.segment_id}
                        to_label={link.target.label}
                    />
                );
            }}
            onClick={(data1: any) => {
                const newSegment = data1.clickTarget || data1.segment_id;
                if (newSegment) {
                    setSegment(newSegment);
                }
            }}
        />
    );
};

type TooltipProps = {
    from_id: number;
    from_label: string;
    to_id: number;
    to_label: string;
    value: number;
};
const Tooltip = ({
    from_id,
    from_label,
    to_id,
    to_label,
    value,
}: TooltipProps) => {
    const locale = useLocale();

    return (
        <Card sx={{ p: 1 }}>
            <Stack spacing={0.5}>
                <Stack direction="row" alignItems="center" spacing={0.5}>
                    <Box
                        sx={{
                            width: 12,
                            height: 12,
                            backgroundColor: getEngagementColor(from_id),
                        }}
                    ></Box>
                    <Typography variant="body2">{from_label}</Typography>
                    <ArrowRightAlt />
                    <Box
                        sx={{
                            width: 12,
                            height: 12,
                            backgroundColor: getEngagementColor(to_id),
                        }}
                    ></Box>
                    <Typography variant="body2">{to_label}</Typography>
                </Stack>
                <Typography variant="body2">
                    <strong>{formatNumber(value, 'number', locale)}</strong>{' '}
                    customer
                    {value === 1 ? '' : 's'}
                </Typography>
            </Stack>
        </Card>
    );
};
