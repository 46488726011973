import { camelCaseKeys, useLDClient } from 'launchdarkly-react-client-sdk';
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useActiveWorkspaceFromRoute } from '../providers/useActiveWorkspaceFromRoute.ts';

export const useFeatureFlags = () => {
    const ldClient = useLDClient();
    const { user } = useAuth0();
    const { id: workspaceId, name: workspaceName } =
        useActiveWorkspaceFromRoute() || {};

    return useQuery({
        queryKey: [
            'feature-flags',
            user?.sub || 'anonymous',
            workspaceId || 'no-workspace',
        ],
        queryFn: async () => {
            if (!user || !user.sub) {
                throw new Error('');
            }

            const flagSet = await ldClient?.identify({
                kind: 'multi',
                user: {
                    kind: 'user',
                    key: user.sub,
                    email: user.email,
                    name: user.name ?? user.nickname,
                },
                ...(workspaceId
                    ? {
                          workspace: {
                              kind: 'workspace',
                              key: workspaceId,
                              name: workspaceName,
                          },
                      }
                    : {}),
            });

            return camelCaseKeys(flagSet || {});
        },
        enabled: !!user,
    });
};

export const useFeatureFlag = (name: string) => {
    const { data, isLoading } = useFeatureFlags();

    return useMemo(
        () => ({ isLoading, flag: data && data[name] }),
        [isLoading, data, name]
    );
};
