import { useSessionStorage } from 'usehooks-ts';
import {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
} from 'react';
import { isEqual } from 'lodash';
import { resolveNewState } from '../utils/resolve-new-state.ts';
import { jsonNormalize } from '../utils/json-normalize.ts';

type UndoState<T> = Record<string, UndoHistory<T>>;
export interface UndoHistory<T> {
    undo: [T, ...T[]];
    redo: T[];
}

export const truncateHistory = <T>(
    history: UndoHistory<T>,
    maxItems: number
): UndoHistory<T> => {
    if (maxItems <= 0) {
        throw new Error('maxItems must be a positive integer');
    }

    if (history.undo.length <= maxItems && history.redo.length <= maxItems) {
        return history;
    }

    return {
        undo: [history.undo[0], ...history.undo.slice(-1 * maxItems + 1)],
        redo: history.redo.slice(-1 * maxItems),
    };
};

const createHistory = <T>(remoteValue: T): UndoHistory<T> => {
    return {
        undo: [remoteValue],
        redo: [],
    };
};

const isValidHistory = <T>(
    historyObj: unknown
): historyObj is UndoHistory<T> => {
    if (historyObj === null || typeof historyObj !== 'object') {
        return false;
    }

    if (!('undo' in historyObj) || !('redo' in historyObj)) {
        return false;
    }

    return (
        Array.isArray(historyObj.undo) &&
        Array.isArray(historyObj.redo) &&
        historyObj.undo.length > 0
    );
};

const getHistory = <T>(
    fullHistory: UndoState<T>,
    itemKey: string,
    remoteValue: T
) => {
    const historyFromStorage = fullHistory[itemKey] || {};

    return isValidHistory<T>(historyFromStorage)
        ? historyFromStorage
        : createHistory(remoteValue);
};

export const useUndoState = <T>(
    storageKey: string,
    itemKey: string,
    maxItems: number,
    remoteValue: T,
    remoteValueCompareFn: (remote: T, local: T) => boolean,
    dispatch: Dispatch<T> | undefined = undefined
) => {
    const [fullHistory, setFullHistory] = useSessionStorage<UndoState<T>>(
        storageKey,
        {}
    );

    useEffect(() => {
        if (isValidHistory(fullHistory[itemKey])) {
            return;
        }

        setFullHistory({
            ...fullHistory,
            [itemKey]: createHistory(remoteValue),
        });
    }, [fullHistory, itemKey, remoteValue, setFullHistory]);

    const setHistory = useCallback(
        (arg: SetStateAction<UndoHistory<T>>) =>
            setFullHistory(fullHistory => ({
                ...fullHistory,
                [itemKey]: truncateHistory(
                    resolveNewState(fullHistory[itemKey], arg),
                    maxItems
                ),
            })),
        [itemKey, maxItems, setFullHistory]
    );

    const save = useCallback(
        (arg: SetStateAction<T>) => {
            setHistory(history => {
                const oldValue = history.undo[history.undo.length - 1];
                const newValue = jsonNormalize(resolveNewState(oldValue, arg));

                if (isEqual(oldValue, newValue)) {
                    return history;
                }

                return {
                    undo: [...history.undo, newValue],
                    redo: [],
                };
            });
        },
        [setHistory]
    );

    const undo = useCallback(
        () =>
            setHistory(history => {
                if (history.undo.length <= 1) {
                    return history;
                }

                const undoValue = history.undo.pop();
                if (undoValue) {
                    history.redo.push(undoValue);
                }

                const newHistory = { ...history };
                dispatch?.(history.undo[history.undo.length - 1]);

                return newHistory;
            }),
        [setHistory, dispatch]
    );

    const redo = useCallback(() => {
        setHistory(history => {
            const redoValue = history.redo.pop();
            if (redoValue) {
                history.undo.push(redoValue);
            }

            const newHistory = { ...history };
            dispatch?.(history.undo[history.undo.length - 1]);

            return newHistory;
        });
    }, [setHistory, dispatch]);

    const clear = useCallback(
        (value?: T | undefined, doDispatch?: boolean) => {
            setHistory(history => {
                const newHistory = {
                    undo: [value || history.undo[0]],
                    redo: [],
                } satisfies UndoHistory<T>;

                if (doDispatch) {
                    dispatch?.(newHistory.undo[0]);
                }

                return newHistory;
            });
        },
        [setHistory, dispatch]
    );

    const history = getHistory(fullHistory, itemKey, remoteValue);
    const hasNewRemoteValue = remoteValueCompareFn(
        remoteValue,
        history.undo[0]
    );

    return useMemo(
        () => ({
            value: history.undo[history.undo.length - 1],
            newRemoteValue: hasNewRemoteValue ? remoteValue : undefined,
            canRedo: history.redo.length > 0,
            canUndo: history.undo.length > 1,
            save,
            undo,
            redo,
            clear,
        }),
        [
            clear,
            hasNewRemoteValue,
            history.redo.length,
            history.undo,
            redo,
            remoteValue,
            save,
            undo,
        ]
    );
};
