import { ReactElement } from 'react';
import { useDashboardFromRoute } from '../../../pages/Dashboard/useDashboardFromRoute.ts';

export const EditModeComponent = ({
    component,
}: {
    component: ReactElement;
}) => {
    const { editMode } = useDashboardFromRoute();

    if (!editMode) {
        return <></>;
    }

    return component;
};
