import { Autocomplete, TextField, TextFieldVariants } from '@mui/material';
import { BinaryOperator } from '../../queries/filters';
import { useMemo } from 'react';
import { dimensionOperators } from './dimensionOperators.tsx';
import { metricOperators } from './metricOperators.tsx';
import { useFilterBuilderContext } from './useFilterBuilderContext.tsx';

interface FilterBuilderOperatorProps {
    value: BinaryOperator;
    onChange: (condition: BinaryOperator) => void;
    variant?: TextFieldVariants;
}

export const FilterBuilderOperator = ({
    onChange,
    value,
    variant,
}: FilterBuilderOperatorProps) => {
    const { fieldType } = useFilterBuilderContext();

    const operators = useMemo(() => {
        return fieldType === 'dimensions'
            ? dimensionOperators
            : metricOperators;
    }, [fieldType]);

    return (
        <Autocomplete
            multiple={false}
            freeSolo={false}
            disableClearable={true}
            value={value}
            onChange={(_, value) => onChange(value)}
            options={Object.values(operators).map(c => c.id)}
            getOptionLabel={c => operators[c].label}
            renderInput={params => (
                <TextField
                    {...params}
                    variant={variant ?? 'outlined'}
                    fullWidth
                    label="Condition"
                />
            )}
        />
    );
};
