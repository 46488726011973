import { useQuery } from '@tanstack/react-query';
import { deleteView, fetchViews, saveView } from '../api-client';
import {
    useOptimisticRemove,
    useOptimisticUpdate,
} from './use-optimistic-update';
import {
    ExplorerView,
    GridConfig,
} from '../components/ExplorerGrid/grid/utils/gridView';
import { sortBy } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useActiveWorkspace } from '../providers/WorkspaceProvider.hooks';
import { useActiveExplorerView } from '../providers/ExplorerViewProvider.hooks';
import { useActiveUser } from '../providers/CurrentUserProvider.hooks';
import { upgradeGridConfig } from '../utils/normalize-config';
import { duplicateView as duplicate, initializeViews } from '../types/explorer';
import { ModifiedBy } from '../types/user';
import { AxiosResponse } from 'axios';
import { usePrimaryDataset } from './use-primary-dataset.ts';

export const useViews = () => {
    const { workspaceId } = useActiveWorkspace();
    const user = useActiveUser();
    const { data: primaryDataset, isLoading: primaryDatasetLoading } =
        usePrimaryDataset();

    return useQuery<ExplorerView[]>(
        ['explorer-views', workspaceId],
        async () => {
            const data = await fetchViews({ workspaceId });

            if (primaryDataset && data.data.length === 0) {
                return await initializeViews(workspaceId, primaryDataset, user);
            }

            const views: ExplorerView[] = data.data.map(
                (view: any): ExplorerView => {
                    const viewConfig = JSON.parse(view.config);

                    return {
                        id: view.id || view.viewId,
                        title: view.title || viewConfig.title,
                        createdAt: new Date(view.createdAt).getTime(),
                        createdBy: view.createdBy,
                        modifiedAt: new Date(view.modifiedAt).getTime(),
                        modifiedBy: view.modifiedBy,
                        accessLevel: view.accessLevel || 'full_access',
                        version: view.version || 1,
                        config: upgradeGridConfig({
                            dataset: viewConfig.dataset,
                            baselineId: viewConfig.baselineId,
                            kpiData: viewConfig.kpiData,
                            filterModel: viewConfig.filterModel,
                            pivotMode: viewConfig.pivotMode,
                            columns: viewConfig.columns || viewConfig.view,
                            valueColumns: viewConfig.valueColumns,
                            dateRanges: viewConfig.dateRanges,
                        }),
                    };
                }
            );

            return views;
        },
        {
            refetchInterval: 10_000,
            enabled: !primaryDatasetLoading,
        }
    );
};

export const useExplorerViews = (workspaceId: string) => {
    const navigate = useNavigate();
    const result = useViews();
    const user = useActiveUser();
    const viewUser: ModifiedBy = {
        id: user.memberId,
        name: user.name,
        email: user.email,
    };

    const { viewId: currentViewId } = useActiveExplorerView();

    const { create, update, isError, isLoading } = useOptimisticUpdate<
        ExplorerView,
        AxiosResponse<ExplorerView>
    >(
        async view => await saveView({ workspaceId, view }),
        ['explorer-views', workspaceId]
    );

    const { remove } = useOptimisticRemove<ExplorerView>(
        async viewId => {
            if (currentViewId === viewId) {
                navigate(`/workspaces/${workspaceId}/explorer`);
            }

            await deleteView({ workspaceId, viewId });
        },
        ['explorer-views', workspaceId]
    );

    const duplicateView = async (viewToDuplicate: ExplorerView) => {
        const newView = duplicate(viewToDuplicate, viewUser);

        await create(newView);

        return Promise.resolve(newView);
    };

    return {
        ...result,
        isError,
        isLoading,
        views: sortBy(result.data, 'title'),
        createView: create,
        updateView: (id: string, config: GridConfig, title?: string) =>
            update(
                id,
                (view): ExplorerView => ({
                    ...view,
                    ...(title !== undefined ? { title } : {}),
                    config,

                    // Just for making optimistic update work properly
                    modifiedAt: Date.now(),
                    modifiedBy: viewUser,
                    version: view.version + 1,
                })
            ),
        deleteView: remove,
        duplicateView,
    };
};
