import {
    FormControl,
    Grid,
    IconButton,
    MenuItem,
    Select,
    Snackbar,
    Typography,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { useSeinoStore } from '../../store/seino-store';
import { ProfileSection } from './ProfileSection';

export function FirstDayOfWeekSettings() {
    const [daysOptions] = useState([
        { label: 'Sunday', value: '0' },
        { label: 'Monday', value: '1' },
        { label: 'Tuesday', value: '2' },
        { label: 'Wednesday', value: '3' },
        { label: 'Thursday', value: '4' },
        { label: 'Friday', value: '5' },
        { label: 'Saturday', value: '6' },
    ]);

    const [firstDayOfWeek, setLocalFirstDayOfWeek] = useState(daysOptions[1]);
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const { setFirstDayOfWeek } = useSeinoStore(store => store.views);

    useEffect(() => {
        const selected = parseInt(
            window.localStorage?.getItem('first_day_of_week') || '1'
        );

        setLocalFirstDayOfWeek(daysOptions[selected]);
    }, [daysOptions]);

    useEffect(() => {
        window.localStorage?.setItem('first_day_of_week', firstDayOfWeek.value);
        setFirstDayOfWeek(parseInt(firstDayOfWeek.value) as Day);
    }, [firstDayOfWeek, setFirstDayOfWeek]);

    return (
        <Grid container rowSpacing={4} columnSpacing={4}>
            <Grid item xs={4}>
                <Typography variant="h6">Personal settings</Typography>
            </Grid>
            <Grid item xs={8}>
                <ProfileSection.Section expanded>
                    <ProfileSection.Summary id="first-day-of-week">
                        <ProfileSection.Header primary="First day of the week" />
                    </ProfileSection.Summary>
                    <ProfileSection.Details>
                        <FormControl>
                            <Select
                                labelId="first-day-of-the-week-label"
                                id="first-day-of-the-week"
                                value={firstDayOfWeek.value}
                                autoWidth
                                onChange={e => {
                                    setLocalFirstDayOfWeek(
                                        daysOptions[
                                            parseInt(
                                                (e.target.value as string) ||
                                                    '0'
                                            )
                                        ]
                                    );
                                    setOpenSnackbar(true);
                                }}
                            >
                                {daysOptions.map(day => (
                                    <MenuItem
                                        dense
                                        key={day.value}
                                        value={day.value as string}
                                    >
                                        {day.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </ProfileSection.Details>
                </ProfileSection.Section>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={openSnackbar}
                    autoHideDuration={6000}
                    onClose={() => setOpenSnackbar(false)}
                    message="First day of week updated"
                    action={
                        <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={() => setOpenSnackbar(false)}
                        >
                            <Close fontSize="small" />
                        </IconButton>
                    }
                />
            </Grid>
        </Grid>
    );
}
