import { Stack } from '@mui/material';
import { EditModeComponent } from '../../components/Dashboard/EditMode/EditModeComponent.tsx';
import { DashboardTitle } from './DashboardTitle.tsx';
import { normalizeConfig } from '../../utils/normalize-config.ts';
import { MapWidget } from '../../components/Dashboard/Widgets/MapWidget.tsx';
import { SortableWidgets } from './SortableWidgets.tsx';

import { ReorderModeSwitch } from './ReorderModeSwitch.tsx';
import { useDashboardState } from './useDashboardState.ts';
import { useSeinoStore } from '../../store/seino-store.ts';
import { isPermitted } from '../../types/permission/permission.ts';
import { AddWidget } from './AddWidget.tsx';
import { useDashboardContext } from './useDashboardContext.ts';

export const DashboardWidgets = () => {
    const { localDashboard } = useDashboardState();
    const { reorderMode } = useSeinoStore(state => state.dashboard);
    const { accessLevel } = useDashboardContext();
    const canWrite = isPermitted(accessLevel, 'write');

    return (
        <Stack spacing={2}>
            <Stack direction="row" alignItems="center" spacing={2}>
                <EditModeComponent component={<DashboardTitle />} />
                <EditModeComponent component={<ReorderModeSwitch />} />
            </Stack>

            <EditModeComponent component={<AddWidget />} />

            {reorderMode ? (
                <SortableWidgets />
            ) : (
                localDashboard.config.widgets.map(w => (
                    <MapWidget
                        config={normalizeConfig(w)}
                        key={w.id}
                        canWrite={canWrite}
                    />
                ))
            )}
        </Stack>
    );
};
