import { useActiveExplorerView } from '../../providers/ExplorerViewProvider.hooks.ts';
import { useExplorerState } from '../../hooks/use-explorer-state.ts';
import { useDialog } from '../../components/Dialog/useDialog.ts';
import {
    SeinoDialogContainer,
    SeinoDialogContent,
    SeinoDialogTab,
    SeinoDialogTabPanel,
    SeinoDialogTabs,
    TabbedDialogActionPlaceholder,
    TabbedDialogContent,
    TabbedDialogSection,
    SeinoDialogTitle,
} from '../../components/Dialog/SeinoDialog.styled.tsx';
import { EditViewName } from './EditViewName.tsx';
import { ActionButton } from '../../components/Button/ActionButton.tsx';
import * as React from 'react';
import { SeinoDialog } from '../../components/Dialog/SeinoDialog.tsx';
import { useCampaignCollection } from '../../hooks/use-campaign-collections.ts';
import { DialogActions, Stack } from '@mui/material';
import { ShareObject } from '../../components/ShareObject/ShareObject.tsx';
import { Collections } from '../../components/Collections/Collections.tsx';
import { useActiveWorkspaceId } from '../../providers/useActiveWorkspaceId.ts';

const useBaselineId = () => {
    const workspaceId = useActiveWorkspaceId();
    const { data: collections = [] } = useCampaignCollection(workspaceId);
    const { view: currentView } = useExplorerState();

    if (currentView.config.baselineId) {
        const relevantCollection = collections.find(
            c => c.baselineSet.id === currentView.config.baselineId
        );

        if (relevantCollection && relevantCollection.baselineSet.id) {
            return relevantCollection.baselineSet.id;
        }
    }
    return 'current';
};

export const ExplorerDialog = () => {
    const {
        openDialog,
        closeDialog,
        parameters: { panel },
    } = useDialog<{ panel: string; baselineId?: string }>('explorer');
    const { viewId, accessLevel } = useActiveExplorerView();
    const { view: currentView } = useExplorerState();
    const baselineId = useBaselineId();

    const handleOnChange = (_: unknown, panel: string) => {
        if (panel === 'baselines') {
            openDialog({ panel, baselineId });
        } else {
            openDialog({ panel });
        }
    };

    if (!currentView || !accessLevel) {
        return null;
    }

    return (
        <SeinoDialog id="explorer">
            <SeinoDialogContainer>
                <SeinoDialogTabs
                    value={panel || 'general'}
                    onChange={handleOnChange}
                >
                    <SeinoDialogTab
                        key="dialog-dashboard-general-tab"
                        label="General"
                        value="general"
                    />
                    <SeinoDialogTab
                        key="dialog-dashboard-baselines-tab"
                        label="Baselines"
                        value="baselines"
                    />
                    <SeinoDialogTab
                        key="dialog-dashboard-sharing-tab"
                        label="Sharing"
                        value="sharing"
                    />
                </SeinoDialogTabs>

                <SeinoDialogTabPanel
                    key="dialog-dashboard-general-panel"
                    value={panel}
                    id="general"
                >
                    <SeinoDialogContent>
                        <SeinoDialogTitle>General</SeinoDialogTitle>
                        <TabbedDialogContent>
                            <TabbedDialogSection>
                                <EditViewName />
                            </TabbedDialogSection>
                        </TabbedDialogContent>
                        <DialogActions>
                            <ActionButton onClick={() => closeDialog()}>
                                Apply
                            </ActionButton>
                        </DialogActions>
                    </SeinoDialogContent>
                </SeinoDialogTabPanel>

                <SeinoDialogTabPanel
                    key="dialog-dashboard-baselines-panel"
                    value={panel}
                    id="baselines"
                >
                    <SeinoDialogContent>
                        <SeinoDialogTitle>Baselines</SeinoDialogTitle>
                        <TabbedDialogContent>
                            <TabbedDialogSection>
                                <Collections />
                            </TabbedDialogSection>
                        </TabbedDialogContent>
                        <TabbedDialogActionPlaceholder />
                    </SeinoDialogContent>
                </SeinoDialogTabPanel>

                <SeinoDialogTabPanel
                    key="dialog-dashboard-sharing-panel"
                    value={panel}
                    id="sharing"
                >
                    <SeinoDialogContent>
                        <SeinoDialogTitle>Sharing</SeinoDialogTitle>
                        <TabbedDialogContent>
                            <TabbedDialogSection>
                                <Stack spacing={2}>
                                    <ShareObject
                                        objectId={viewId}
                                        objectType="explorer_view"
                                        currentAccessLevel={accessLevel}
                                    />
                                </Stack>
                            </TabbedDialogSection>
                        </TabbedDialogContent>
                        <TabbedDialogActionPlaceholder />
                    </SeinoDialogContent>
                </SeinoDialogTabPanel>
            </SeinoDialogContainer>
        </SeinoDialog>
    );
};
