import * as React from 'react';
import { ReactNode, useRef } from 'react';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import {
    Box,
    Button,
    ButtonProps,
    Paper,
    Popover as MuiPopover,
    PopoverProps,
} from '@mui/material';

type ViewSelectorProps = {
    buttonContent: ReactNode;
    buttonConfig?: Omit<ButtonProps, 'onClick' | 'ref'>;
    buttonStyle?: ButtonProps['sx'];
    popoverConfig?: Omit<PopoverProps, 'onClose' | 'open' | 'ref'>;
    popoverStyle?: PopoverProps['sx'];
    width?: number;
    open: boolean;
    toggle: () => void;
    onClose: () => void;
    children?: React.ReactNode;
};

export const Popover = (props: ViewSelectorProps) => {
    const { open, toggle, onClose } = props;

    const ref = useRef<HTMLButtonElement | null>(null);

    return (
        <Box width={props.width}>
            <Button
                ref={ref}
                sx={{
                    textTransform: 'none',
                    justifyContent: 'space-between',
                    borderBottomWidth: 0,
                    ...props.buttonStyle,
                }}
                variant="text"
                onClick={toggle}
                endIcon={props.open ? <ArrowDropUp /> : <ArrowDropDown />}
                {...props.buttonConfig}
            >
                {props.buttonContent}
            </Button>
            <MuiPopover
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                anchorEl={ref.current}
                onClose={onClose}
                open={open}
                sx={props.popoverStyle}
                {...props.popoverConfig}
            >
                <Paper
                    square
                    elevation={1}
                    sx={theme => ({
                        borderColor: theme.palette.divider,
                    })}
                >
                    {props.children}
                </Paper>
            </MuiPopover>
        </Box>
    );
};
