import { Box, Button, Stack } from '@mui/material';
import {
    addMonths,
    eachDayOfInterval,
    format,
    isSameDay,
    isThisMonth,
    isWithinInterval,
} from 'date-fns';
import { useMemo } from 'react';
import styled from 'styled-components';
import { CalendarDay, GridSpacer } from './CalendarDay';
import { CalendarWeekdays } from './CalendarWeekdays';
import { CalendarWeekNumbers } from './CalendarWeekNumbers';
import { getMonthInterval } from './dateHelpers';
import { MonthSwitcher } from './MonthSwitcher';

import { useDateRangePicker } from './useDateRangePicker.tsx';

interface CalendarMonthProps {
    month: Date;
    isFirst: boolean;
    isLast: boolean;
}

export const CalendarMonth = ({
    month,
    isFirst,
    isLast,
}: CalendarMonthProps) => {
    const {
        dispatch,
        locale,
        weekStartsOn,
        allowCompare,
        state: { dateRanges, currentPeriod },
    } = useDateRangePicker();

    const monthInterval = getMonthInterval(month);
    const dates = useMemo(
        () => eachDayOfInterval(monthInterval),
        [monthInterval]
    );

    const selectMonth = () => {
        const preset = isThisMonth(month)
            ? 'this_month'
            : isThisMonth(addMonths(month, 1))
            ? 'last_month'
            : 'custom';

        if (currentPeriod === 'main' && preset !== 'custom') {
            dispatch({
                type: 'selectPreset',
                period: 'main',
                preset,
            });
        } else {
            dispatch({
                type: 'selectRange',
                dateRange: monthInterval,
            });
        }
    };

    return (
        <Stack spacing={0.5}>
            <Stack direction="row" alignContent="center">
                <MonthSwitcher dispatch={dispatch} dir="prev" hide={!isFirst} />
                {!(isFirst && isLast) && <GridSpacer />}
                <Button onClick={selectMonth} sx={{ flexGrow: 1 }}>
                    {format(month, 'MMMM yyyy', { locale })}
                </Button>
                <MonthSwitcher dispatch={dispatch} dir="next" hide={!isLast} />
            </Stack>

            <Stack direction="row">
                <CalendarWeekNumbers
                    month={monthInterval}
                    weekStartsOn={weekStartsOn}
                    currentPeriod={currentPeriod}
                    dispatch={dispatch}
                />
                <CalendarGrid>
                    <CalendarWeekdays
                        weekStartsOn={weekStartsOn}
                        locale={locale}
                    />
                    {dates.map(d => (
                        <CalendarDay
                            key={d.getDate()}
                            dispatch={dispatch}
                            date={d}
                            firstOfMainRange={isSameDay(
                                dateRanges.main.start,
                                d
                            )}
                            lastOfMainRange={isSameDay(dateRanges.main.end, d)}
                            inMainRange={isWithinInterval(d, dateRanges.main)}
                            firstOfCompareRange={isSameDay(
                                dateRanges.compare.start,
                                d
                            )}
                            lastOfCompareRange={isSameDay(
                                dateRanges.compare.end,
                                d
                            )}
                            inCompareRange={isWithinInterval(
                                d,
                                dateRanges.compare
                            )}
                            weekStartsOn={weekStartsOn}
                            currentPeriod={currentPeriod}
                            allowCompare={allowCompare}
                        />
                    ))}
                </CalendarGrid>
            </Stack>
        </Stack>
    );
};

const CalendarGrid = styled(Box)`
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    row-gap: 4px;
`;
