import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';

export const OnboardingLayout = () => (
    <Box
        sx={{
            backgroundColor: '#00181C',
            minHeight: '100vh',
            flexGrow: 1,
        }}
        justifyContent="center"
        alignItems="center"
        position="relative"
        display="flex"
    >
        <Outlet />
    </Box>
);
