import { createContext } from 'react';

type EngagementContextProps = {
    segment: number;
    setSegment: (segment: number) => void;
};

export const EngagementContext = createContext<EngagementContextProps>({
    segment: 1,
    setSegment: () => {},
});
