import * as React from 'react';
import ConfirmDialog from '../../../components/ConfirmDialog/ConfirmAlert.tsx';
import { WorkspaceMember } from '../../../types/workspace-management';
import { useActiveWorkspace } from '../../../providers/WorkspaceProvider.hooks.ts';
import { useRemoveMember } from './useRemoveMember.tsx';

type RemoveMemberDialogProps = {
    open: boolean;
    onClose: () => void;
    member: WorkspaceMember;
};

export const RemoveMemberDialog = ({
    open,
    onClose,
    member,
}: RemoveMemberDialogProps) => {
    const { workspace } = useActiveWorkspace();

    const { mutateAsync, isLoading } = useRemoveMember();

    return (
        <ConfirmDialog
            open={open}
            isDangerousAction={true}
            continueText={'Remove'}
            onContinue={async () => {
                await mutateAsync(member.id);
                onClose();
            }}
            isExecuting={isLoading}
            onCancel={onClose}
            content={`Are you sure you want to remove ${member.name} from ${workspace.displayName}?`}
        />
    );
};
