import { DuckDatasetQuery } from './use-duck-dataset-query';
import { useDuckDataset } from './use-duck-dataset';
import { useContext } from 'react';
import { DuckContext } from '../providers/duck';

type UseDuckExecuteProps = {
    dataset: string;
    fetchSchema?: boolean;
};

type UseDuckExecuteResult<TRow> =
    | { status: 'loading'; execute: undefined }
    | {
          status: 'ready';
          execute: (queryFn: DuckDatasetQuery) => Promise<TRow[]>;
      };

export const useDuckExecute = <TRow>({
    dataset,
}: UseDuckExecuteProps): UseDuckExecuteResult<TRow> => {
    const duck = useContext(DuckContext);
    const { isLoading, data: datasetMetadata } = useDuckDataset(dataset);

    if (isLoading || !duck || !datasetMetadata) {
        return { status: 'loading', execute: undefined };
    }

    const { schema, tableId } = datasetMetadata;

    return {
        status: 'ready',
        execute: async (queryFn: DuckDatasetQuery) => {
            return (
                await duck.connection.query(
                    queryFn(tableId, schema!.dateField, schema!)
                )
            ).toArray();
        },
    };
};
