import { useOptimisticUpdate } from '../../../hooks/use-optimistic-update';
import {
    ExplorerView,
    GridConfig,
} from '../../../components/ExplorerGrid/grid/utils/gridView';
import { saveView } from '../../../api-client';
import { duplicateView } from '../../../types/explorer';
import { useActiveUser } from '../../../providers/CurrentUserProvider.hooks';

export const useModify = (workspaceId: string) => {
    const user = useActiveUser();
    const viewUser = {
        id: user.memberId,
        name: user.name,
        email: user.email,
    };

    const { create, update, isLoading } = useOptimisticUpdate<ExplorerView>(
        async view => {
            await saveView({ workspaceId, view });
        },
        ['explorer-views', workspaceId]
    );

    const duplicate = async (viewToDuplicate: ExplorerView) => {
        const newView = duplicateView(viewToDuplicate, viewUser);

        await create(newView);

        return Promise.resolve(newView);
    };

    return {
        create,
        updateView: (id: string, config: GridConfig, title?: string) =>
            update(
                id,
                (view): ExplorerView => ({
                    ...view,
                    ...(title !== undefined ? { title } : {}),
                    config,

                    // Just for making optimistic update work properly
                    modifiedAt: Date.now(),
                    modifiedBy: viewUser,
                    version: view.version + 1,
                })
            ),
        isLoading,
        duplicate,
    };
};
