import { Add } from '@mui/icons-material';
import { LoadingButton, LoadingButtonProps } from '@mui/lab';

export const EnableButton = (props: LoadingButtonProps) => (
    <LoadingButton
        startIcon={<Add />}
        variant="contained"
        size="small"
        {...props}
    >
        Enable
    </LoadingButton>
);
