import { IconButton } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { useState } from 'react';
import ConfirmDialog from '../../../../components/ConfirmDialog/ConfirmAlert';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { getRustAPIUrl } from '../../../../utils/api.ts';
import { integrationsQueryKey } from '../../../../hooks/use-integrations.ts';

interface RemoveBrandProps {
    workspaceId: string;
    integrationId: string;
}
export const RemoveBrand = ({
    workspaceId,
    integrationId,
}: RemoveBrandProps) => {
    const [open, setOpen] = useState(false);

    const queryClient = useQueryClient();
    const { mutateAsync, isLoading } = useMutation({
        mutationFn: () =>
            axios.delete(
                `${getRustAPIUrl()}/workspaces/${workspaceId}/integrations/${integrationId}`
            ),
        onSuccess: () =>
            queryClient.invalidateQueries(integrationsQueryKey(workspaceId)),
    });

    const handleConfirm = async () => {
        await mutateAsync();
        setOpen(false);
    };

    return (
        <>
            <IconButton onClick={() => setOpen(true)}>
                <Delete />
            </IconButton>
            <ConfirmDialog
                open={open}
                title="Remove Deployteq brand"
                content="Are you sure you want to remove this Deployteq brand?"
                continueText="Remove"
                isDangerousAction={true}
                isExecuting={isLoading}
                onCancel={() => setOpen(false)}
                onContinue={handleConfirm}
            />
        </>
    );
};
