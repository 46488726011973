import { ResponsiveLine } from '@nivo/line';
import { NumberFormat } from '../../../../../utils/number-format';
import { ChartPoint } from './useCompareDateRangeQuery';

interface MetricChartLineProps {
    format: string;
    data: ChartPoint[];
}

export const MetricChartLine = ({ data, format }: MetricChartLineProps) => {
    return (
        <ResponsiveLine
            colors={['#60c2d5']}
            data={[{ id: 'current', data }]}
            enableGridX={false}
            enableGridY={false}
            lineWidth={1}
            pointSize={0}
            yScale={{
                type: 'linear',
                min: 0,

                // Ensure percentages are rendered on scale from 0 to 101%.
                // Note that the 1% is added to prevent lines at 100% being clipped.
                max: format === NumberFormat.Percentage ? 1.01 : undefined,
            }}
            // Unused, but crashes / triggers warning if not defined
            pointLabelYOffset={0}
            axisBottom={null}
        />
    );
};
