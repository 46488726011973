import {
    Alert,
    AlertTitle,
    Box,
    Card,
    CardProps,
    Collapse,
    Stack,
    styled,
    Typography,
} from '@mui/material';
import { ErrorBoundary } from '@sentry/react';
import { uuid4 } from '@sentry/utils';
import {
    addWidget,
    deleteWidget,
    WidgetConfig,
} from '../../../types/dashboard';
import { EditModeComponent } from '../EditMode/EditModeComponent';
import { ActiveFilters } from './ActiveFilters';
import { ShowConfigToggleButton } from './Config/ShowConfigToggleButton';
import { WidgetConfigPanel } from './Config/WidgetConfigPanel';
import { DeleteWidgetButton } from './DeleteWidget';
import { DuplicateWidgetButton } from './DuplicateWidget';
import { useDashboardFromRoute } from '../../../pages/Dashboard/useDashboardFromRoute.ts';
import { useToggle } from '../../../hooks/use-toggle.tsx';
import { useDashboardState } from '../../../pages/Dashboard/useDashboardState.ts';

interface WidgetProps {
    config: WidgetConfig;
    children: React.ReactNode;
    cardProps?: CardProps;
}

export const WidgetCard = styled(Card)({
    '&.MuiChip-root': {
        backgroundColor: 'white',
    },
    position: 'relative',
});

export const Widget = ({ children, config, cardProps }: WidgetProps) => {
    const { editMode } = useDashboardFromRoute();
    const { localDashboard, saveLocal } = useDashboardState();

    const [showConfig, toggleShowConfig] = useToggle(editMode);

    const handleDeleteWidget = (id: string) =>
        saveLocal(deleteWidget(localDashboard, id));

    const handleDuplicateWidget = () =>
        saveLocal(
            addWidget(localDashboard, {
                ...config,
                title: config.title + ' (copy)',
                id: uuid4(),
            })
        );

    const errorContent = (
        <Card sx={{ px: 4, py: 2 }}>
            <Box margin="auto">
                <Alert severity="error">
                    <AlertTitle>Error</AlertTitle>
                    <Box>
                        <span>
                            An error was encountered rendering this widget.
                            Create a ticket in our helpdesk via the link below
                            if the problem persists.
                        </span>
                        <Box mt={1}>
                            <a href="https://seino.freshdesk.com/support/tickets/new">
                                Create ticket
                            </a>
                        </Box>
                    </Box>
                </Alert>
            </Box>
        </Card>
    );

    return (
        <ErrorBoundary fallback={errorContent}>
            <div style={{ padding: '2px', marginBottom: '20px' }}>
                <WidgetCard
                    sx={{
                        px: 5,
                        py: 2,
                        overflowX: 'auto',
                    }}
                    {...cardProps}
                >
                    <>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            mb={1}
                        >
                            <Box>
                                <Typography
                                    variant="h5"
                                    color="secondary.light"
                                    fontWeight="bold"
                                >
                                    {config.title}
                                </Typography>
                            </Box>
                            <EditModeComponent
                                component={
                                    <Box
                                        display="flex"
                                        justifyContent="flex-end"
                                    >
                                        <DuplicateWidgetButton
                                            onClick={handleDuplicateWidget}
                                        />
                                        <DeleteWidgetButton
                                            onConfirm={() =>
                                                handleDeleteWidget(config.id)
                                            }
                                        />
                                        <ShowConfigToggleButton
                                            showConfig={showConfig}
                                            toggleShowConfig={toggleShowConfig}
                                        />
                                    </Box>
                                }
                            />
                        </Stack>

                        <Collapse in={editMode && showConfig}>
                            <Box mt={1}>
                                {editMode && showConfig && (
                                    <WidgetConfigPanel
                                        type={config.type}
                                        selectedConfig={config}
                                    />
                                )}
                            </Box>
                        </Collapse>
                        <ActiveFilters
                            dataset={config.dataset}
                            filters={config.filters}
                        />

                        {children}
                    </>
                </WidgetCard>
            </div>
        </ErrorBoundary>
    );
};
