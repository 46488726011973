import { DropdownOption } from '../AutocompleteWithDropdown/AutocompleteDropdown.tsx';

export const sharePermissionOptions: DropdownOption[] = [
    {
        label: 'Full access',
        value: 'full_access',
        secondaryLabel: 'Can make changes and share with others',
    },
    {
        label: 'Can edit',
        value: 'read_write',
        secondaryLabel: 'Can make changes but not share with others',
    },
    {
        label: 'Can view',
        value: 'read',
        secondaryLabel: 'Cannot edit and share with others',
    },
];
