import { Box, Typography } from '@mui/material';
import { InfoPanel } from '../../components/InfoPanel/InfoPanel';
import { Navigate } from 'react-router-dom';
import { useActiveUser } from '../../providers/CurrentUserProvider.hooks';

export const NoWorkspaces = () => {
    const { onboardingRequired, workspaces } = useActiveUser();

    if (onboardingRequired) {
        return <Navigate to="/welcome" />;
    }

    if (workspaces.length > 0) {
        return <Navigate to="/dashboard" />;
    }

    return (
        <InfoPanel
            hideWorkspaceSelector
            dividerTitle="You're currently not a member of any workspace."
            content={
                <Box width="fit-content" textAlign="left" alignSelf="center">
                    <Typography mt={4} paragraph={true} variant="body1">
                        To use SEINō you should be a member of at least 1
                        workspace.
                    </Typography>
                </Box>
            }
        />
    );
};
