import { updateWidget, WidgetConfig } from '../../../../types/dashboard';
import { Widget } from '../Widget.tsx';
import { useTargets } from '../../../../pages/Targets/useTargets.ts';
import { TargetCard } from '../../../../pages/Targets/TargetCard.tsx';
import React, { useCallback, useMemo } from 'react';
import { Alert, Stack } from '@mui/material';
import { Target } from '../../../../types/targets';
import { useDialog } from '../../../Dialog/useDialog.ts';
import { useDashboardFromRoute } from '../../../../pages/Dashboard/useDashboardFromRoute.ts';
import { isEmpty } from 'lodash';
import { useDashboardState } from '../../../../pages/Dashboard/useDashboardState.ts';
import { NewTarget } from '../../../../pages/Targets/NewTarget.tsx';
import { useSubscribe } from '../../../../event-bus';

export interface TargetWidgetConfig extends WidgetConfig {
    targets: string[];
}

const sortTargets =
    (sortOrder: string[]) =>
    (a: Target, b: Target): number => {
        const aIndex = sortOrder.indexOf(a.id);
        const bIndex = sortOrder.indexOf(b.id);

        // If both names are found in the sortOrder array, compare their indices
        if (aIndex !== -1 && bIndex !== -1) {
            return aIndex - bIndex;
        }

        // If one name is not found in the sortOrder array, prioritize the one that is found
        if (aIndex !== -1) return -1;
        if (bIndex !== -1) return 1;

        // If both names are not found in the sortOrder array, maintain their original order
        return 0;
    };

export function TargetWidget(config: TargetWidgetConfig) {
    const { data: targets, isLoading } = useTargets();
    const { saveLocal } = useDashboardState();

    const { openDialog } = useDialog<{ id?: string; widgetId?: string }>(
        'target'
    );

    const handleOnEdit = (id?: string) =>
        openDialog(id ? { id, widgetId: config.id } : { widgetId: config.id });

    const applyChange = useCallback(
        (change: Pick<WidgetConfig, 'targets'>) =>
            saveLocal(prevState =>
                updateWidget(prevState, {
                    ...config,
                    ...change,
                })
            ),
        [config, saveLocal]
    );

    useSubscribe('target_created', ({ id, widgetId }) => {
        if (widgetId === config.id && !config.targets.includes(id)) {
            applyChange({
                targets: [...config.targets, id],
            });
        }
    });

    const { editMode } = useDashboardFromRoute();

    const filteredTargets = useMemo(
        () =>
            targets
                ?.filter(target => config.targets.includes(target.id))
                .sort(sortTargets(config.targets)),
        [config.targets, targets]
    );

    const hasTargets = !isEmpty(filteredTargets);

    return (
        <Widget
            config={config}
            cardProps={{
                ...(hasTargets && {
                    elevation: 0,
                    sx: { backgroundColor: 'transparent' },
                }),
            }}
        >
            <Stack direction="row" gap={2} p={0.275} flexWrap="wrap">
                {!editMode && !hasTargets && (
                    <Alert severity="info">
                        No target selected. Please select a target in the config
                        panel.
                    </Alert>
                )}

                {filteredTargets?.map(target => (
                    <TargetCard
                        key={target.id}
                        isLoading={isLoading}
                        target={target}
                        disableMenu={!editMode}
                        onRemoveFromDashboard={() =>
                            applyChange({
                                targets: config.targets.filter(
                                    t => t !== target.id
                                ),
                            })
                        }
                        onEdit={handleOnEdit}
                    />
                ))}

                {editMode && <NewTarget widgetId={config.id} />}
            </Stack>
        </Widget>
    );
}
