import React, { ReactNode } from 'react';
import { ErrorBoundary } from '@sentry/react';
import { Button, ButtonProps, Stack } from '@mui/material';
import { Autocomplete, AutocompleteValue } from './Autocomplete';
import { AutocompleteDropdown, DropdownOption } from './AutocompleteDropdown';
import { AutocompleteContainer } from './AutocompleteWithDropdown.styled';
import { AutocompleteInputOverrideProps } from './AutocompleteInput.tsx';

type Props<T extends AutocompleteValue, DropdownValue> = {
    disabled?: boolean;
    isLoading: boolean;
    buttonLabel: string | ReactNode;
    buttonProps?: ButtonProps;
    autocompleteOptions: T[];
    onSubmit: () => void;
    autocompleteValue: T[];
    autocompleteOnChange: (selected: T[]) => void;
    dropdownValue: DropdownValue | undefined;
    dropdownOptions: DropdownValue[];
    dropdownOnChange: (selected: DropdownValue) => void;
    autocompleteInputProps?: AutocompleteInputOverrideProps;
};

export const AutocompleteWithDropdown = ({
    disabled,
    isLoading,
    buttonLabel,
    buttonProps,
    autocompleteOptions,
    onSubmit,
    autocompleteValue,
    autocompleteOnChange,
    dropdownOptions,
    dropdownValue,
    dropdownOnChange,
    autocompleteInputProps,
}: Props<AutocompleteValue, DropdownOption>) => {
    return (
        <ErrorBoundary>
            <Stack flexDirection="row" gap={1} alignItems="center">
                <AutocompleteContainer direction="column">
                    <Autocomplete
                        disabled={disabled}
                        isLoading={isLoading}
                        options={autocompleteOptions}
                        selected={autocompleteValue}
                        onChange={autocompleteOnChange}
                        autocompleteInputProps={autocompleteInputProps}
                    />
                    {!disabled && autocompleteOptions.length > 0 && (
                        <AutocompleteDropdown
                            value={dropdownValue}
                            onChange={dropdownOnChange}
                            isLoading={isLoading}
                            options={dropdownOptions}
                        />
                    )}
                </AutocompleteContainer>

                <Button
                    onClick={onSubmit}
                    disabled={
                        autocompleteOptions.length === 0 ||
                        autocompleteValue.length === 0
                    }
                    variant="contained"
                    size="small"
                    {...buttonProps}
                >
                    {buttonLabel}
                </Button>
            </Stack>
        </ErrorBoundary>
    );
};
