import { ulid } from 'ulid';
import { useState } from 'react';
import { EnableDeployteq } from './EnableDeployteq';
import { Add } from '@mui/icons-material';
import { ActionButton } from '../../../../components/Button/ActionButton';

interface AddBrandProps {
    workspaceId: string;
}

export const AddBrand = ({ workspaceId }: AddBrandProps) => {
    const [open, setOpen] = useState(false);
    const [integrationId, setIntegrationId] = useState('');

    const handleClick = () => {
        setIntegrationId(ulid());
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleEnable = () => {
        setOpen(false);
    };

    return (
        <>
            <ActionButton startIcon={<Add />} onClick={handleClick}>
                Add brand
            </ActionButton>
            <EnableDeployteq
                key={integrationId}
                workspaceId={workspaceId}
                integrationId={integrationId}
                open={open}
                onEnable={handleEnable}
                onClose={handleClose}
            />
        </>
    );
};
