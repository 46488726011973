import { StateCreator } from 'zustand';
import { DashboardStore, dashboardStore } from './dashboard';
import { DataGridStore, dataGridStore } from './data-grid';
import { compose, createStore } from './helpers';
import { UserStore, userStore } from './user';
import { ViewStore, viewStore } from './view';

type SeinoStore = {
    views: ViewStore;
    user: UserStore;
    dashboard: DashboardStore;
    dataGrid: DataGridStore;
};

const storeCreator: StateCreator<SeinoStore> = (set, get, api) => {
    const views = compose<SeinoStore, 'views'>('views', viewStore)(
        set,
        get,
        api
    );

    const user = compose<SeinoStore, 'user'>('user', userStore)(set, get, api);

    const dashboardSource = compose<SeinoStore, 'dashboard'>(
        'dashboard',
        dashboardStore
    )(set, get, api);

    const dataGridSource = compose<SeinoStore, 'dataGrid'>(
        'dataGrid',
        dataGridStore
    )(set, get, api);
    return {
        views: views,
        user: user,
        dashboard: dashboardSource,
        dataGrid: dataGridSource,
    };
};

export const useSeinoStore = createStore<SeinoStore>(storeCreator);
