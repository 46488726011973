import { DashboardConfig } from '../../../types/dashboard';
import { useDashboardMutation } from '../use-dashboard-mutation.ts';
import { useDashboards } from '../use-dashboards.ts';
import { ulid } from 'ulid';
import ItemSelector, {
    Item,
} from '../../../components/ItemSelector/ItemSelector';
import { useDashboardFromRoute } from '../useDashboardFromRoute.ts';
import { isPermitted } from '../../../types/permission/permission.ts';
import { useNavigate } from 'react-router-dom';
import { useActiveWorkspace } from '../../../providers/WorkspaceProvider.hooks.ts';

type SelectedDashboardConfig = DashboardConfig & Item;

export const DashboardSelector = () => {
    const { workspaceId } = useActiveWorkspace();
    const { setSelectedDashboardId, selectedDashboardId, setEditMode } =
        useDashboardFromRoute();
    const navigate = useNavigate();

    const { isLoading, data: dashboards } = useDashboards();

    const items: SelectedDashboardConfig[] =
        dashboards?.map(d => ({
            ...d,
            name: d.config.title,
        })) || [];

    const { createDashboard, remove } = useDashboardMutation();

    const handleOnSelect = (item: SelectedDashboardConfig) => {
        setSelectedDashboardId(item.id);
        setEditMode(false);
    };

    const handleOnDuplicate = (item: SelectedDashboardConfig) => {
        const toDuplicate = items?.find(v => v.id === item.id);

        if (toDuplicate) {
            createDashboard(toDuplicate.config.title + ' (copy)', {
                ...toDuplicate,
                id: ulid(),
            });
        }
    };

    const handleOnRemove = ({ id }: SelectedDashboardConfig) => {
        const otherView = items.find(v => v.id !== id);
        if (otherView) {
            navigate(`/workspaces/${workspaceId}/dashboard/${otherView.id}`);
            remove(id);
        }
    };

    return (
        <ItemSelector
            title="Select dashboard"
            closeOnSelect={true}
            items={items}
            selected={items?.find(d => d.id === selectedDashboardId)}
            isLoading={isLoading}
            onDuplicate={handleOnDuplicate}
            onRemove={handleOnRemove}
            canRemove={item =>
                isPermitted(item.accessLevel, 'delete') &&
                (items?.length || [].length) > 1
            }
            onSelect={i => handleOnSelect(i)}
        />
    );
};
