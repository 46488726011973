import {
    differenceInCalendarDays,
    endOfDay,
    endOfMonth,
    endOfQuarter,
    endOfWeek,
    endOfYear,
    Interval,
    setDate,
    startOfDay,
    startOfMonth,
    startOfQuarter,
    startOfWeek,
    startOfYear,
    subDays,
    subMonths,
    subQuarters,
    subWeeks,
    subYears,
} from 'date-fns';
import { DateRange, DateRangeWithPreset } from './reducer.tsx';
import { ComparePreset, MainPreset } from './presetDefinitions.ts';

export const normalizeInterval = <T extends DateRange>(x: T): T => ({
    ...x,
    start: startOfDay(x.start < x.end ? x.start : x.end),
    end: endOfDay(x.end > x.start ? x.end : x.start),
});

export const getMonthInterval = (d: Date): DateRange =>
    normalizeInterval({
        start: setDate(d, 1),
        end: endOfMonth(d),
    });

export const precedingPeriod = ({
    start,
    end,
}: Interval): DateRangeWithPreset<ComparePreset> => ({
    start: subDays(start, differenceInCalendarDays(end, start) + 1),
    end: subDays(start, 1),
    preset: 'preceding_period',
});

export const yearOverYear = ({
    start,
    end,
}: Interval): DateRangeWithPreset<ComparePreset> => ({
    start: subYears(start, 1),
    end: subYears(end, 1),
    preset: 'year_over_year',
});

export const lastDays = (
    n: 7 | 14 | 28 | 30 | 90
): DateRangeWithPreset<MainPreset> => ({
    start: startOfDay(subDays(new Date(), n - 1)),
    end: endOfDay(new Date()),
    preset: `last_${n}_days`,
});

export const thisWeek = (
    weekStartsOn: Day
): DateRangeWithPreset<MainPreset> => ({
    start: startOfWeek(new Date(), { weekStartsOn }),
    end: endOfDay(new Date()),
    preset: 'this_week',
});

export const lastWeek = (
    weekStartsOn: Day
): DateRangeWithPreset<MainPreset> => ({
    start: subWeeks(startOfWeek(new Date(), { weekStartsOn }), 1),
    end: subWeeks(endOfWeek(new Date(), { weekStartsOn }), 1),
    preset: 'last_week',
});

export const thisMonth = (): DateRangeWithPreset<MainPreset> => ({
    start: startOfMonth(new Date()),
    end: endOfDay(new Date()),
    preset: 'this_month',
});

export const lastMonth = (): DateRangeWithPreset<MainPreset> => ({
    start: startOfMonth(subMonths(new Date(), 1)),
    end: endOfMonth(subMonths(new Date(), 1)),
    preset: 'last_month',
});

export const thisQuarter = (): DateRangeWithPreset<MainPreset> => ({
    start: startOfQuarter(new Date()),
    end: endOfDay(new Date()),
    preset: 'this_quarter',
});

export const lastQuarter = (): DateRangeWithPreset<MainPreset> => ({
    start: startOfQuarter(subQuarters(new Date(), 1)),
    end: endOfQuarter(subQuarters(new Date(), 1)),
    preset: 'last_quarter',
});

export const thisYear = (): DateRangeWithPreset<MainPreset> => ({
    start: startOfYear(new Date()),
    end: endOfDay(new Date()),
    preset: 'this_year',
});

export const lastYear = (): DateRangeWithPreset<MainPreset> => ({
    start: startOfYear(subYears(new Date(), 1)),
    end: endOfYear(subYears(new Date(), 1)),
    preset: 'last_year',
});
