import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import Button from '@mui/material/Button';

interface Props {
    showConfig: boolean;
    toggleShowConfig: () => void;
}

export const ShowConfigToggleButton = ({
    showConfig,
    toggleShowConfig,
}: Props): JSX.Element => (
    <Button onClick={toggleShowConfig}>
        {showConfig ? (
            <KeyboardArrowUp width={24} />
        ) : (
            <KeyboardArrowDown width={24} />
        )}
    </Button>
);
