import {
    Box,
    Button,
    CircularProgress,
    Dialog as MuiDialog,
    DialogActions,
    DialogContent,
    DialogProps,
    DialogTitle,
    IconButton,
    Stack,
    styled,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import React, { ReactNode } from 'react';

const CloseButton = styled(IconButton)(({ theme }) => ({
    position: 'absolute',
    right: 8,
    top: 8,
    color: theme.palette.grey[500],
}));

export const FormDialog = ({
    open,
    handleClose,
    handleConfirm,
    error,
    title,
    subtitle,
    content,
    contentLoading,
    confirmButtonText,
    confirmButtonProps,
    ...props
}: {
    open: boolean;
    title: string;
    error?: string | ReactNode | false;
    subtitle?: string | ReactNode;
    confirmButtonText?: string;
    confirmButtonProps?: LoadingButtonProps;
    content: ReactNode;
    contentLoading?: boolean;
    handleClose: () => void;
    handleConfirm?: () => void;
} & Omit<DialogProps, 'content'>) => {
    return (
        <MuiDialog open={open} onClose={handleClose} {...props}>
            <form
                onSubmit={e => {
                    e.preventDefault();
                    handleConfirm && handleConfirm();
                }}
                autoComplete="off"
                noValidate
                style={{
                    overflowY: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <DialogTitle fontSize={16}>
                    {title}
                    <CloseButton aria-label="close" onClick={handleClose}>
                        <Close />
                    </CloseButton>
                </DialogTitle>
                <DialogContent sx={{ minWidth: 450 }}>
                    {/* The top-margin is to prevent the label of the first form input to clip  */}
                    <Stack sx={{ marginTop: 0.75 }}>
                        {subtitle}

                        {error && error}

                        {contentLoading ? (
                            <Box alignSelf="center">
                                <CircularProgress />
                            </Box>
                        ) : (
                            content
                        )}
                    </Stack>
                </DialogContent>
                {confirmButtonText && (
                    <DialogActions
                        /* Custom padding to align with max width form inputs. */
                        sx={{ px: 2.5, pb: 1.5, pt: 0 }}
                    >
                        <Stack direction="row" spacing={2}>
                            <Button onClick={handleClose}>Cancel</Button>
                            <LoadingButton
                                variant="contained"
                                color="info"
                                type="submit"
                                {...confirmButtonProps}
                            >
                                {confirmButtonText}
                            </LoadingButton>
                        </Stack>
                    </DialogActions>
                )}
            </form>
        </MuiDialog>
    );
};
