import { useEffect } from 'react';

type MountSensorProps = { onMount: (isMounted: boolean) => void };
export const MountSensor = ({ onMount }: MountSensorProps) => {
    useEffect(() => {
        onMount(true);
        return () => onMount(false);
    });
    return null;
};
