import { Box, Link, Typography } from '@mui/material';
import { SeinoLogo } from '../../components/Graphics/SeinoLogo';

export const NotFound = () => {
    return (
        <Box
            width="100%"
            height="100vh"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
        >
            <SeinoLogo />
            <Typography variant="h2" my={4}>
                404
            </Typography>

            <Typography>
                The page you were looking for could not be found. Click{' '}
                <Link href="/">here</Link> to go back to the home page.
            </Typography>
        </Box>
    );
};
