import { PaletteOptions } from '@mui/material';

export const lightText = {
    primary: '#000000',
    primaryLight: '#595959',
    secondary: '#009DBB',
    secondaryLight: '#5aceed',
    disabled: '#bccbd2',
};

export const lightPalette: PaletteOptions = {
    text: lightText,
    primary: {
        main: '#000000',
        light: '#9e9e9e',
        contrastText: '#FFFFFF',
    },
    background: {
        default: '#FAFAFA',
        paper: '#FFFFFF',
    },
    secondary: {
        dark: '#00667A',
        main: '#009DBB',
        contrastText: '#FFFFFF',
    },
    divider: '#eeeeee',
    success: {
        main: '#449D61',
        dark: '#449D61',
        light: '#B0EBD6',
        contrastText: '#FFFFFF',
    },
    info: {
        main: '#0B76CE',
        contrastText: '#FFFFFF',
    },
    error: {
        main: '#DA5563',
        dark: '#7e1621',
        light: '#F4CBD0',
        contrastText: '#FFFFFF',
    },
    warning: {
        main: '#E4BE87',
        light: '#F2E0C8',
        dark: '#D59B44',
        contrastText: '#FFFFFF',
    },
};
