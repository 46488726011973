import { Skeleton, SkeletonProps, Stack } from '@mui/material';
import { range } from 'lodash';
import * as React from 'react';
import { HealthChartLegend } from './Legend';

export const HealthChartSkeleton = (props: SkeletonProps) => {
    return (
        <Stack spacing={0.5} width="fit-content">
            <Stack direction="row" spacing={0.25}>
                {range(0, 30).map(i => (
                    <Skeleton
                        key={i}
                        variant="rectangular"
                        sx={{
                            borderRadius: 2,
                            width: '6px',
                            height: '32px',
                        }}
                        {...props}
                    />
                ))}
            </Stack>
            <HealthChartLegend />
        </Stack>
    );
};
