import { useEffect, useState } from 'react';

export const useDelayRender = (delayMs: number) => {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => setVisible(true), delayMs);

        return () => clearTimeout(timer);
    }, [setVisible, delayMs]);

    return visible;
};
