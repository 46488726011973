import Accordion, { AccordionProps } from '@mui/material/Accordion';
import AccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Divider,
    ListItemText,
    ListItemTextProps,
    Stack,
    styled,
} from '@mui/material';
import AccordionDetails from '@mui/material/AccordionDetails';

const Container = styled(Stack)``;
Container.defaultProps = {
    divider: <Divider />,
    gap: 6,
};
const Section = styled(Accordion)``;
const Summary = styled(AccordionSummary)({
    '.MuiAccordionSummary-content': { margin: 0 },
});
Summary.defaultProps = {
    expandIcon: <ExpandMoreIcon />,
};

const Details = styled(AccordionDetails)(({ theme }) => ({
    paddingBlock: theme.spacing(2),
    borderTop: `1px solid ${theme.border.medium}`,
}));
const Header = styled(ListItemText)``;
Header.defaultProps = {
    primaryTypographyProps: {
        variant: 'mediumBoldBody',
    },
    secondaryTypographyProps: {
        variant: 'smallMediumBody',
        color: 'text.primaryLight',
    },
};

export function ProfileSection({
    sectionProps,
    summaryProps,
    headerProps,
}: {
    sectionProps: AccordionProps;
    headerProps: ListItemTextProps;
    summaryProps: AccordionSummaryProps;
}) {
    return (
        <ProfileSection.Section {...sectionProps}>
            <ProfileSection.Summary {...summaryProps}>
                <ProfileSection.Header {...headerProps} />
            </ProfileSection.Summary>
            <ProfileSection.Details></ProfileSection.Details>
        </ProfileSection.Section>
    );
}

ProfileSection.Container = Container;
ProfileSection.Section = Section;
ProfileSection.Summary = Summary;
ProfileSection.Header = Header;
ProfileSection.Details = Details;
