import { lightPalette, lightText } from '../lightPalette';
import { blue } from '@mui/material/colors';

export type SeinoTheme = typeof lightTheme;

export const lightTheme = {
    palette: lightPalette,
    text: lightText,
    white: '#FFFFFF',
    black: '#000000',
    tab: {
        indicator: blue[400],
        toolbar: {
            background: '#000',
            contrastText: '#fff',
        },
    },
    table: {
        default: {
            background: '#FFFFFF',
        },
        defaultHover: {
            background: '#FAFAFA',
        },
        selected: {
            background: '#e3f2fd',
        },
        selectedHover: {
            background: '#2196F3',
        },
    },
    border: {
        light: '#EEEEEE',
        medium: '#d9d9d9',
        dark: '#4D4D4D',
    },
    background: {
        dark: '#000',
    },
    elevation: {
        background: '#FFFFFF',
        base: '#FAFAFA',
        1: '#f9f9f9',
        2: '#f4f4f4',
        3: '#EEEEEE',
    },
    searchHighlight: {
        background: '#E4F5FC',
        text: '#235C69',
    },
    grey: {
        100: '#212121',
        90: '#424242',
        80: '#616161',
        70: '#757575',
        60: '#9E9E9E',
        50: '#BDBDBD',
        40: '#E0E0E0',
        30: '#EEEEEE',
        20: '#F5F5F5',
        10: '#FAFAFA',
    },
    green: {
        100: '#245B31',
        90: '#337948',
        80: '#3B8A54',
        70: '#449D61',
        60: '#4BAC6D',
        50: '#51B782',
        40: '#71C497',
        30: '#9AD4B4',
        20: '#C1E4D1',
        10: '#E5F4ED',
    },
    blue: {
        100: '#235C69',
        90: '#337D94',
        80: '#3C91AD',
        70: '#47A5C7',
        60: '#50B5DB',
        50: '#5ABFE2',
        40: '#6FCAE8',
        30: '#94D9F1',
        20: '#BDE8F7',
        10: '#E4F5FC',
    },
    purple: {
        100: '#671779',
        90: '#811D85',
        80: '#90218B',
        70: '#A02592',
        60: '#AD2897',
        50: '#B949A5',
        40: '#C66AB4',
        30: '#D696C9',
        20: '#E6BFDE',
        10: '#F5E6F1',
    },
    amber: {
        100: '#FF6F00',
        90: '#FF8F00',
        80: '#FFA000',
        70: '#FFB300',
        60: '#FFC107',
        50: '#FFCA28',
        40: '#FFD54F',
        30: '#FFE082',
        20: '#FFECB3',
        10: '#FFF8E1',
    },
    red: {
        100: '#7A163B',
        90: '#991E41',
        80: '#AC2444',
        70: '#C02B48',
        60: '#CE304B',
        50: '#D54762',
        40: '#DD647C',
        30: '#E58FA0',
        20: '#EFBAC5',
        10: '#F8E4E8',
    },
};
