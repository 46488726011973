import { StyledTargetCard } from './TargetCard.styled.tsx';
import { Add } from '@mui/icons-material';
import React from 'react';
import { useDialog } from '../../components/Dialog/useDialog.ts';

export const NewTarget = ({ widgetId }: { widgetId?: string }) => {
    const { openDialog } = useDialog<{ id?: string; widgetId?: string }>(
        'target'
    );

    const handleTargetOnClick = () => openDialog({ widgetId });

    return (
        <StyledTargetCard isNew onClick={handleTargetOnClick}>
            <Add />
            Add new target
        </StyledTargetCard>
    );
};
