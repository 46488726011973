import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getRustAPIUrl } from '../../../utils/api.ts';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

type CreateAgencyWorkspaceArgs = { name: string; license_id?: number };
type Response = { id: string };

export const useCreateWorkspace = () => {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (body: CreateAgencyWorkspaceArgs) => {
            const response = await axios.post<Response>(
                `${getRustAPIUrl()}/workspaces`,
                body
            );

            return response.data;
        },
        onSuccess: async ({ id }) => {
            enqueueSnackbar('Successfully created workspace', {
                variant: 'success',
            });

            await queryClient.invalidateQueries(['fetch-initial-data']);
            navigate(`/workspaces/${id}/settings/members`);
        },
        onError: () =>
            enqueueSnackbar('Failed to create workspace', { variant: 'error' }),
    });
};
