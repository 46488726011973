import { useActiveExplorerView } from '../providers/ExplorerViewProvider.hooks';
import { useUndoState } from './use-undo-state';
import { ExplorerView } from '../components/ExplorerGrid/grid/utils/gridView';
import { useCallback, useMemo } from 'react';
import { cloneDeep } from 'lodash';
import { eventBus } from '../event-bus';

export const useExplorerState = () => {
    const { viewId, view: serverView } = useActiveExplorerView();

    const dispatchEvent = useCallback(
        (view: ExplorerView) =>
            eventBus.publish({ type: 'explorer_reset', view }),
        []
    );

    const {
        value: localView,
        newRemoteValue: newRemoteView,
        save: saveLocal,
        clear: clearLocalChanges,
        canUndo,
        canRedo,
        redo,
        undo,
    } = useUndoState<ExplorerView>(
        'explorer-views',
        viewId,
        100,
        serverView,
        useMemo(() => (a, b) => a.version > b.version, []),
        dispatchEvent
    );

    return useMemo(
        () => ({
            view: cloneDeep(localView),
            newRemoteView,
            saveLocal,
            clearLocalChanges,
            canUndo,
            canRedo,
            redo,
            undo,
        }),
        [
            localView,
            newRemoteView,
            saveLocal,
            clearLocalChanges,
            canUndo,
            canRedo,
            redo,
            undo,
        ]
    );
};
