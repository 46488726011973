import { useViews } from './useViews';
import { Autocomplete, TextField } from '@mui/material';
import { AvailableGoogleAnalyticsView } from '../../../../types/integration';

interface SelectViewProps {
    workspaceId: string;
    integrationId: string;
    value: AvailableGoogleAnalyticsView | null;
    onChange: (view: AvailableGoogleAnalyticsView | null) => void;
}

export const SelectView = ({
    workspaceId,
    integrationId,
    value,
    onChange,
}: SelectViewProps) => {
    const {
        data: { availableViews },
    } = useViews(workspaceId, integrationId);

    return (
        <Autocomplete
            multiple={false}
            freeSolo={false}
            value={value}
            onChange={(_, value) => onChange(value)}
            options={availableViews || []}
            getOptionLabel={option => option.name}
            groupBy={option => option.account_name}
            size="small"
            renderInput={params => (
                <TextField
                    {...params}
                    variant="outlined"
                    fullWidth
                    label="Select a GA view"
                />
            )}
        />
    );
};
