import { useAuth0 } from '@auth0/auth0-react';
import { CircularProgress, Fade } from '@mui/material';
import { useEffect } from 'react';
import styled from 'styled-components';

const LogoutWrapper = styled.div`
    position: absolute;
    z-index: 10000000;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: white;
    right: 0;
    text-align: center;

    .App-dark & {
        background-color: #181818;
    }
`;

const SpinnerContainer = styled.div`
    margin-top: 150px;
`;

export const Logout = () => {
    const { logout } = useAuth0();

    useEffect(() => {
        const timeoutId = setTimeout(
            () =>
                logout({
                    logoutParams: {
                        returnTo: window.location.origin,
                    },
                }),
            800
        );
        return () => clearTimeout(timeoutId);
    }, [logout]);

    return (
        <Fade in={true}>
            <LogoutWrapper>
                <SpinnerContainer>
                    <CircularProgress />
                </SpinnerContainer>
            </LogoutWrapper>
        </Fade>
    );
};
