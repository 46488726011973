import { Box, CircularProgress } from '@mui/material';
import { styled } from '@mui/system';

export const Loader = () => (
    <LoaderContainer>
        <CircularProgress />
    </LoaderContainer>
);
const LoaderContainer = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    justifyItems: 'center',
    marginTop: '6rem',
});
