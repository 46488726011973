import { DataModelSchema } from '../types/datamodel/schema';
import { dateFormat } from '../utils/date-format';
import { BinaryFilter } from './filters';
import { sqlForFilters } from './filters-sql';
import { indent } from './indent';
import { sqlForMetrics } from './metrics-sql';
import { Granularity } from './time-series';
import { sqlForDimensions, sqlForDimensionsGroupBy } from './dimensions-sql';

type HighLowQuery = {
    dateRange: Interval;
    granularity?: Granularity;
    metric: string;
    dimension: string;
    filters: BinaryFilter[];
};

export function sqlForHighLowQuery(
    schema: DataModelSchema,
    sourceTable: string,
    query: HighLowQuery
) {
    const { dateRange, metric, dimension, filters } = query;
    const start = dateFormat(dateRange.start);
    const end = dateFormat(dateRange.end);

    if (!metric || !dimension) return '';

    const returnValue = `
WITH R AS (
    SELECT ${sqlForMetrics(schema, [metric])}
${indent(sqlForDimensions(schema, [dimension]), 4)}
    FROM ${sourceTable}
    WHERE ${schema.dateField} BETWEEN '${start}' AND '${end}'
    ${sqlForFilters(schema, filters)}
${indent(sqlForDimensionsGroupBy(schema, [dimension]), 4)}
) SELECT * FROM (SELECT * FROM R order by ${metric} nulls last limit 1) union all (select * from R order by ${metric} desc nulls last limit 1);
`;

    return returnValue;
}
