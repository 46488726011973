import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { App } from './App';
import './index.css';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { AG_GRID_LICENSE_KEY } from './const/agGrid.ts';
import {
    createRoutesFromChildren,
    matchRoutes,
    useLocation,
    useNavigationType,
} from 'react-router-dom';

if (import.meta.env.VITE_SENTRY_DSN) {
    Sentry.init({
        dsn: import.meta.env.VITE_SENTRY_DSN,
        environment: import.meta.env.VITE_SENTRY_ENV,
        release: import.meta.env.VITE_SENTRY_RELEASE,
        integrations: [
            Sentry.reactRouterV6BrowserTracingIntegration({
                useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes,
            }),
            Sentry.replayIntegration(),
        ],
        replaysSessionSampleRate: 0.0,
        replaysOnErrorSampleRate: 1.0,
        tracesSampleRate: 1.0,
        tracePropagationTargets: ['api.seino.ai', 'api-rs.seino.ai'],
    });
}

LicenseManager.setLicenseKey(AG_GRID_LICENSE_KEY);

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);

declare global {
    interface Window {
        dataLayer: Record<string, any>[];
    }
}
