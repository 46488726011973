import { BinaryOperator } from '../../queries/filters.ts';

export const metricOperators: Record<
    string,
    { id: BinaryOperator; label: string }
> = {
    gt: { id: 'gt', label: 'Is greater than' },
    gte: { id: 'gte', label: 'Is greater than or equal to' },
    lt: { id: 'lt', label: 'Is less than' },
    lte: { id: 'lte', label: 'Is less than or equal to' },
    equals: { id: 'equals', label: 'Is equal to' },
    outlierHigh: { id: 'outlierHigh', label: 'Is significantly higher' },
    outlierLow: { id: 'outlierLow', label: 'Is significantly lower' },
    outlier: { id: 'outlier', label: 'Significantly deviates' },
};
