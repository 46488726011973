import { MenuItem, Select, SelectProps } from '@mui/material';
import React from 'react';
import { useDatasets } from '../../hooks/use-datasets';
import _ from 'lodash';

type DatasetSelectProps = SelectProps<string> & { allowedDatasets: string[] };

export const DatasetSelect = ({
    allowedDatasets,
    ...props
}: DatasetSelectProps) => {
    const { data: availableDatasets, isLoading: datasetsLoading } =
        useDatasets();

    const options = _.chain(availableDatasets || [])
        .filter(({ id }) => allowedDatasets.includes(id))
        .map(({ id, title }) => ({ label: title || id, value: id }))
        .sortBy('label')
        .value();

    if (datasetsLoading) {
        return <></>;
    }

    return (
        <Select size="small" {...props}>
            {options.map(({ label, value }) => (
                <MenuItem key={value} value={value}>
                    {label}
                </MenuItem>
            ))}
        </Select>
    );
};
