import { SettingsTab } from '../WorkspaceSettings/SettingsTab.tsx';
import { WorkspaceList } from '../WorkspaceSettings/Agency/WorkspaceList.tsx';
import { useActiveUser } from '../../providers/CurrentUserProvider.hooks.ts';

export const AllWorkspaces = () => {
    const { email } = useActiveUser();
    if (!email.endsWith('seino.ai')) {
        return null;
    }
    return (
        <SettingsTab title="All workspaces">
            <WorkspaceList all={true} />
        </SettingsTab>
    );
};
