import { useAuth0 } from '@auth0/auth0-react';
import { Help, MenuOutlined } from '@mui/icons-material';
import {
    Box,
    Container,
    IconButton,
    Stack,
    styled,
    Tab,
    Tabs,
    useTheme,
} from '@mui/material';
import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { GraphicLogo } from '../Graphics/GraphicLogo';
import { WorkspaceSelector } from '../WorkspaceSelector/WorkspaceSelector';
import { useNavigationItems } from './useTabs';
import { User } from './User';
import { useInitialData } from '../../hooks/use-initial-data';
import { Changelog } from '../../pages/Explorer/Changelog';
import { MoreMenu } from '../MoreMenu/MoreMenu.tsx';
import { useGettingStartedDrawer } from '../Drawer/useGettingStartedDrawer.tsx';

const MobileMenu = styled(Box)({});
MobileMenu.defaultProps = {
    flexGrow: 1,
    display: { xs: 'flex', md: 'none' },
    alignItems: 'center',
};

const SmallScreenMenu = styled(Box)({});
SmallScreenMenu.defaultProps = {
    flexGrow: 1,
    display: { xs: 'none', sm: 'flex' },
    alignItems: 'center',
};

const MediumScreenMenu = styled(Stack)({});
MediumScreenMenu.defaultProps = {
    flexGrow: 1,
    direction: 'row',
    gap: 1,
    display: { xs: 'none', md: 'flex' },
    alignItems: 'center',
};

interface LinkTabProps {
    label: string;
    href: string;
    onClick: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

const StyledTabs = styled(Tabs)(({ theme: { blue, palette } }) => ({
    '& .MuiTabs-indicator': {
        backgroundColor: blue[40],
    },

    '& .MuiTab-root': {
        color: palette.primary.contrastText,
    },
}));

export const Header = ({
    showNavigation = true,
    showWorkspaceSelector = true,
}: {
    showNavigation?: boolean;
    showWorkspaceSelector?: boolean;
}) => {
    const { isAuthenticated } = useAuth0();
    const { data } = useInitialData();

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const theme = useTheme();
    const { openDrawer, closeDrawer, isOpen } = useGettingStartedDrawer();

    const { menuItems, activeTab } = useNavigationItems();
    const menuItemsWithOnClick = menuItems.map(item => ({
        ...item,
        onClick: (event: React.MouseEvent) => {
            event.preventDefault();

            navigate({
                pathname: item.href,
                search: isOpen ? searchParams.toString() : '',
            });
        },
    }));

    const LinkTab = (props: LinkTabProps) => {
        return <Tab component="a" {...props} />;
    };

    const handleHelp = () => (isOpen ? closeDrawer() : openDrawer());

    return (
        <Container maxWidth={false}>
            <Stack direction="row">
                {showNavigation && (
                    <MobileMenu>
                        <MoreMenu
                            icon={<MenuOutlined />}
                            items={menuItemsWithOnClick}
                            iconButtonProps={{ color: 'contrastText' }}
                        />
                        {isAuthenticated && showWorkspaceSelector && (
                            <WorkspaceSelector noBorder />
                        )}
                    </MobileMenu>
                )}
                <MediumScreenMenu>
                    <GraphicLogo onClick={() => navigate({ pathname: '/' })} />
                    {isAuthenticated && showWorkspaceSelector && (
                        <WorkspaceSelector noBorder />
                    )}
                    {showNavigation && (
                        <StyledTabs
                            value={activeTab === -1 ? false : activeTab}
                            textColor="inherit"
                            indicatorColor="secondary"
                            aria-label="secondary tabs example"
                        >
                            {menuItemsWithOnClick.map(
                                ({ href, label, onClick }) => (
                                    <LinkTab
                                        href={href}
                                        label={label}
                                        key={href}
                                        onClick={onClick}
                                    />
                                )
                            )}
                        </StyledTabs>
                    )}
                </MediumScreenMenu>
                <Stack direction="row">
                    <SmallScreenMenu>
                        <Changelog />
                        <IconButton onClick={handleHelp}>
                            <Help
                                htmlColor={theme.tab.toolbar.contrastText}
                                fontSize="small"
                            />
                        </IconButton>
                    </SmallScreenMenu>
                    {isAuthenticated && (
                        <User showProfileLink={!data?.onboardingRequired} />
                    )}
                </Stack>
            </Stack>
        </Container>
    );
};
