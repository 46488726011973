import { SeinoLogo } from '../Graphics/SeinoLogo';
import { Box, Fade, Link, styled, Typography } from '@mui/material';

const reasonMap = new Map([
    ['token_not_found', 'The registration was not found in the database.'],
    ['token_already_used', 'The registration link has already been used.'],
    ['token_expired', 'The registration link has expired.'],
    ['account_already_exists', 'This account already exists.'],
]);

const ErrorWrapperPage = styled(Box)({
    width: '100%',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
});

export const PublicErrorPage = () => {
    const errorReason = new URLSearchParams(window.location.search).get(
        'reason'
    );
    const error = reasonMap.get(errorReason ?? 'unknown');

    return (
        <Fade in={true}>
            <ErrorWrapperPage>
                <SeinoLogo />
                <Typography variant="h6" mt={2} mb={2}>
                    Oops, something went wrong!
                </Typography>
                <Typography variant="body1" mb={1}>
                    {error}
                </Typography>
                <Typography variant="body2">
                    Click <Link href="/">here</Link> to go back to the home
                    page.
                </Typography>
            </ErrorWrapperPage>
        </Fade>
    );
};
