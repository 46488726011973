import { useEffect } from 'react';
import { useActiveWorkspace } from '../providers/WorkspaceProvider.hooks';

export const useLastExplorerViewId = () => {
    const { workspaceId } = useActiveWorkspace();
    return window.localStorage.getItem(`explorer-view-${workspaceId}`);
};

export const useSetLastExplorerViewId = (
    workspaceId: string,
    viewId: string | undefined
) => {
    useEffect(() => {
        if (!viewId) {
            return;
        }

        window.localStorage.setItem(`explorer-view-${workspaceId}`, viewId);
    }, [workspaceId, viewId]);
};
