import { Box, Link, Typography } from '@mui/material';
import { InfoPanel } from '../InfoPanel/InfoPanel.tsx';

export const WorkspaceSuspendedError = () => (
    <InfoPanel
        dividerTitle="Workspace suspended"
        content={
            <Box marginBlock={2}>
                <Typography paragraph={true} variant="body1">
                    You’re trying to access a workspace that has been
                    temporarily suspended.
                </Typography>

                <Typography variant="body1">
                    Please
                    <Link
                        href="mailto:support@seino.ai"
                        style={{ marginInline: 4 }}
                    >
                        contact us
                    </Link>
                    to reinstate your account.
                </Typography>
            </Box>
        }
    />
);
