import { ContentCopy } from '@mui/icons-material';
import { IconButton } from '@mui/material';

export const DuplicateWidgetButton = ({ onClick }: { onClick: () => void }) => {
    return (
        <>
            <IconButton onClick={() => onClick()} aria-label="Duplicate widget">
                <ContentCopy />
            </IconButton>
        </>
    );
};
