import { Delete } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useState } from 'react';
import ConfirmDialog from '../../../../components/ConfirmDialog/ConfirmAlert';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { getRustAPIUrl } from '../../../../utils/api.ts';
import { integrationsQueryKey } from '../../../../hooks/use-integrations.ts';

interface DeleteViewProps {
    workspaceId: string;
    integrationId: string;
    viewId: string;
}

export const DeleteView = ({ workspaceId, viewId }: DeleteViewProps) => {
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const queryClient = useQueryClient();
    const { mutateAsync, isLoading } = useMutation({
        mutationFn: () =>
            axios.delete(
                `${getRustAPIUrl()}/workspaces/${workspaceId}/integrations/google-analytics/properties/${encodeURIComponent(
                    viewId
                )}`
            ),
        onSuccess: () =>
            queryClient.invalidateQueries(integrationsQueryKey(workspaceId)),
    });

    const handleConfirm = () => {
        mutateAsync();
    };

    return (
        <>
            <IconButton onClick={() => setDialogOpen(true)}>
                <Delete />
            </IconButton>
            <ConfirmDialog
                open={dialogOpen}
                title="Remove Google Analytics view"
                content="Are you sure you want to remove this view?"
                continueText="Remove"
                isDangerousAction={true}
                isExecuting={isLoading}
                onCancel={() => setDialogOpen(false)}
                onContinue={handleConfirm}
            />
        </>
    );
};
