import {
    Box,
    Button,
    ClickAwayListener,
    Paper,
    Popper,
    Stack,
} from '@mui/material';
import { startOfMonth, subMonths } from 'date-fns';
import { rangeRight } from 'lodash';
import { useCallback, useMemo, useRef } from 'react';
import { CalendarMonth } from './CalendarMonth';
import { Controls } from './Controls';
import { PickerToggle } from './PickerToggle';
import { useDateRangePicker } from './useDateRangePicker.tsx';

type DateRangePickerProps = {
    onCancel: () => void;
    onApply: () => void;
};

export const DateRangePicker = ({
    onCancel,
    onApply,
}: DateRangePickerProps) => {
    const {
        calendarCount,
        state: { isOpen, lastVisibleMonth },
    } = useDateRangePicker();

    const toggleRef = useRef(null);

    const visibleMonths = useMemo(
        () =>
            rangeRight(calendarCount).map(i => subMonths(lastVisibleMonth, i)),
        [calendarCount, lastVisibleMonth]
    );

    const onClickAway = useCallback(() => {
        if (!isOpen) {
            return;
        }

        onApply();
    }, [isOpen, onApply]);

    return (
        <ClickAwayListener onClickAway={onClickAway}>
            <Box ml={2}>
                <PickerToggle ref={toggleRef} />
                <Popper
                    open={isOpen}
                    anchorEl={toggleRef.current}
                    placement="bottom-end"
                    sx={theme => ({ zIndex: theme.zIndex.modal })}
                >
                    <Paper elevation={8}>
                        <Stack
                            direction={{ xs: 'column', sm: 'row' }}
                            sx={{ height: { sm: 360 } }}
                        >
                            <Controls />
                            <Stack
                                spacing={2}
                                sx={{ my: 2, mx: 2 }}
                                justifyContent="space-between"
                                flexGrow={1}
                            >
                                <Stack direction="row" spacing={2}>
                                    {visibleMonths.map((m, i) => (
                                        <CalendarMonth
                                            key={m.getMonth()}
                                            month={startOfMonth(m)}
                                            isFirst={i === 0}
                                            isLast={
                                                i === visibleMonths.length - 1
                                            }
                                        />
                                    ))}
                                </Stack>

                                <Stack
                                    direction="row"
                                    alignSelf="flex-end"
                                    spacing={1}
                                >
                                    <Button size="small" onClick={onCancel}>
                                        Cancel
                                    </Button>

                                    <Button
                                        size="small"
                                        type="button"
                                        onClick={onApply}
                                    >
                                        Apply
                                    </Button>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Paper>
                </Popper>
            </Box>
        </ClickAwayListener>
    );
};
