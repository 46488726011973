import { useContext } from 'react';
import { CurrentUserContext } from './CurrentUserProvider.tsx';

export function useActiveUser() {
    const context = useContext(CurrentUserContext);

    if (context === undefined) {
        throw new Error(
            'useActiveUser must be used within a CurrentUserProvider'
        );
    }

    return context;
}
