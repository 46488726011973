import { useEffect } from 'react';
import { EventBus } from './event-bus.ts';

/**
 * Create a useSubscribe hook for a specific event bus.
 *
 * @example
 * export const useSubscribe = createUseSubscribe(yourEventBus);
 * useSubscribe('your_event', ({message}) => console.log(message));
 *
 * yourEventBus.publish({ type: 'your_event', message: 'Hello, World!' });
 */
export const createUseSubscribe =
    <T extends { type: string }>(eventBus: EventBus<T>) =>
    <K extends T['type']>(
        type: K,
        eventHandler: (data: Extract<T, { type: K }>) => void
    ) => {
        useEffect(() => {
            const unsubscribe = eventBus.subscribe(type, eventHandler);

            return () => {
                unsubscribe();
            };
        }, [type, eventHandler]);
    };
