import { Box, IconButton, Typography } from '@mui/material';
import { FileCopy } from '@mui/icons-material';
import { useSnackbar } from 'notistack';

type ServiceAccountEmailProps = { email: string };

export const ServiceAccountEmail = ({ email }: ServiceAccountEmailProps) => {
    const { enqueueSnackbar } = useSnackbar();

    return (
        <Box>
            <Typography variant="body2">
                Add the following e-mail address under "Property access
                management" with the role "Viewer" in the GA Admin for each
                property you want SEINō to access.
            </Typography>

            <Box sx={{ marginTop: 2 }}>
                <Typography
                    fontFamily="monospace"
                    style={{ userSelect: 'all' }}
                    component="span"
                    sx={theme => ({
                        display: 'inline-block',
                        padding: 1,
                        borderRadius: 2,
                        background: theme.elevation[1],
                    })}
                >
                    {email}

                    <IconButton
                        sx={{ marginLeft: 4 }}
                        onClick={() => {
                            navigator.clipboard.writeText(email);
                            enqueueSnackbar('E-mail copied to clipboard', {
                                autoHideDuration: 2000,
                            });
                        }}
                    >
                        <FileCopy fontSize="small" />
                    </IconButton>
                </Typography>
            </Box>
        </Box>
    );
};
