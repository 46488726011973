import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { useState } from 'react';
import { Snippet } from '../../../components/Snippet/Snippet.tsx';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { getRustAPIUrl } from '../../../utils/api.ts';
import { Search } from '@mui/icons-material';
import { ActionButton } from '../../../components/Button/ActionButton.tsx';
import { useActiveWorkspaceId } from '../../../providers/useActiveWorkspaceId.ts';

type BucketDetailsProps = {
    path: string;
};
export const BucketDetails = ({ path }: BucketDetailsProps) => {
    const workspaceId = useActiveWorkspaceId();
    const [open, setOpen] = useState(false);

    const { data, isLoading } = useQuery({
        queryKey: [workspaceId, 'bucket_info'],
        queryFn: async () =>
            (
                await axios.get(
                    `${getRustAPIUrl()}/workspaces/${workspaceId}/integrations/gcs/bucket`
                )
            ).data.data,
        staleTime: Infinity,
        enabled: open,
    });

    return (
        <>
            <ActionButton
                onClick={() => setOpen(true)}
                size="small"
                startIcon={<Search />}
            >
                View bucket details
            </ActionButton>
            <Dialog open={open} onClose={() => setOpen(false)} fullWidth={true}>
                <DialogContent>
                    <table style={{ width: '100%' }}>
                        <tbody>
                            <tr>
                                <td style={{ width: '120px' }}>Bucket name</td>
                                <td>
                                    <Snippet
                                        isLoading={isLoading}
                                        text={data?.name}
                                    />
                                </td>
                            </tr>

                            <tr>
                                <td>Path</td>
                                <td>
                                    <Snippet
                                        isLoading={isLoading}
                                        text={path}
                                    />
                                </td>
                            </tr>

                            <tr>
                                <td>Key</td>
                                <td>
                                    <Snippet
                                        isLoading={isLoading}
                                        text={data?.key_json}
                                        mask={true}
                                        downloadFilename={`${data?.name}.json`}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>Close</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
