import { Stack, styled } from '@mui/material';

export const ToolbarLeftContent = styled(Stack)({
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
});
ToolbarLeftContent.defaultProps = {
    gap: 1,
};
export const ToolbarRightContent = styled(Stack)({
    flexDirection: 'row',
    alignItems: 'center',
});
export const SeinoToolbar = styled(Stack)(({ theme: { palette, zIndex } }) => ({
    backgroundColor: palette.background.paper,
    borderBottom: `1px solid ${palette.info.light}`,
    position: 'sticky',
    top: '48px',
    zIndex: zIndex.appBar,

    '.divider': {
        margin: '0 0.5rem',
    },
}));

export const ToolbarContent = styled(Stack)({
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginInline: '16px',
    marginTop: '4px',
    marginBottom: '4px',
    flexWrap: 'wrap',
    gap: 4,
});
