import { ArrowDropDown, Done } from '@mui/icons-material';
import {
    Autocomplete,
    AutocompleteCloseReason,
    Button,
    ButtonProps,
    ClickAwayListener,
    createFilterOptions,
    Stack,
    Typography,
} from '@mui/material';
import * as React from 'react';
import { HTMLAttributes, ReactNode } from 'react';
import {
    AutocompleteButton,
    CheckedOption,
    OptionLabel,
    PopperHeader,
    StyledAutocompletePopper,
    StyledInput,
    StyledPopper,
} from './ButtonAutocomplete.styled';
import { AutocompleteItem } from './autocompleteItem';
import {
    AutocompleteChangeReason,
    AutocompleteRenderOptionState,
} from '@mui/material/Autocomplete/Autocomplete';
import { HighlightText } from '../Hightlight/Hightlight';

const strEmpty = (a: string) => a.length === 0;
const strEquals = (a: string, b: string) => a.toLowerCase() === b.toLowerCase();

const contextThatMatchName = (extraContext: string[], other: string) =>
    extraContext.filter(context =>
        context.toLowerCase().includes(other.toLowerCase())
    );
interface PopperComponentProps {
    anchorEl?: any;
    disablePortal?: boolean;
    open: boolean;
}

const PopperComponent = (props: PopperComponentProps) => {
    const { disablePortal, anchorEl, open, ...other } = props;
    return <StyledAutocompletePopper {...other} />;
};

const AutocompleteOption = (
    props: HTMLAttributes<HTMLLIElement>,
    option: AutocompleteItem,
    { selected, inputValue }: AutocompleteRenderOptionState
) => (
    <li key={`autocomplete-option-${option.id}`} {...props}>
        <Stack gap={3} direction="row">
            <CheckedOption component={Done} $selected={selected} />
            <Stack>
                <OptionLabel>
                    <HighlightText query={inputValue} text={option.name} />
                </OptionLabel>
                {!strEmpty(inputValue) &&
                    option.searchContext &&
                    !strEquals(option.name, inputValue) &&
                    contextThatMatchName(
                        option.searchContext || [],
                        inputValue
                    ).map(context => (
                        <Typography
                            key={`option-${option.id}-${context}`}
                            noWrap
                            fontSize={8}
                            variant="overline"
                        >
                            <HighlightText query={inputValue} text={context} />
                        </Typography>
                    ))}
            </Stack>
        </Stack>
    </li>
);

const shouldIgnoreChange = (
    event: React.SyntheticEvent,
    reason: AutocompleteChangeReason
) =>
    event.type === 'keydown' &&
    (event as React.KeyboardEvent).key === 'Backspace' &&
    reason === 'removeOption';

interface ButtonAutocompleteProps<
    T extends AutocompleteItem,
    DisableClearable extends boolean | undefined
> {
    label: string;
    renderLabel?: (label: string) => string | ReactNode;
    popperTitle: string;
    popperSubTitle?: string | ReactNode;
    selected?: T;
    disableClearable?: DisableClearable;
    options: T[];
    groupBy?: (item: T) => string;
    variant?: ButtonProps['variant'];
    buttonProps?: Omit<ButtonProps, 'onClick' | 'ref' | 'disabled'>;
    onChange: (selected: T | null) => void;
}

export const ButtonAutocomplete = <
    T extends AutocompleteItem,
    DisableClearable extends boolean | undefined = undefined
>(
    props: ButtonAutocompleteProps<T, DisableClearable>
) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const {
        options,
        label,
        renderLabel = label => label,
        popperTitle,
        popperSubTitle,
        selected: value,
        variant,
        disableClearable,
        buttonProps,
        groupBy,
        onChange,
    } = props;

    const handleClick = (event: React.MouseEvent<HTMLElement>) =>
        setAnchorEl(event.currentTarget);

    const handleClear = () => {
        onChange(null);
        handleClose();
    };
    const handleClose = () => {
        anchorEl?.focus();
        setAnchorEl(null);
    };

    const handleAutocompleteOnClose = (
        _event: unknown,
        reason: AutocompleteCloseReason
    ) => {
        if (reason === 'escape') {
            handleClose();
        }
    };

    const handleAutocompleteOnChange = (
        event: React.SyntheticEvent,
        newValue: T | null,
        reason: AutocompleteChangeReason
    ) => {
        if (shouldIgnoreChange(event, reason) || !newValue) {
            return;
        }

        onChange(newValue);
        handleClose();
    };

    const filterOptions = createFilterOptions({
        stringify: (option: T) => JSON.stringify(option),
    });

    if (!options || options?.length === 0) {
        return (
            <AutocompleteButton
                variant={variant || 'outlined'}
                fullWidth
                disabled
                disableRipple
                {...buttonProps}
            >
                Nothing found
            </AutocompleteButton>
        );
    }

    return (
        <>
            <AutocompleteButton
                variant={variant || 'outlined'}
                endIcon={<ArrowDropDown />}
                onClick={handleClick}
                disableRipple
                {...buttonProps}
            >
                {value?.name ? renderLabel(value.name) : label}
            </AutocompleteButton>
            <StyledPopper
                open={open}
                anchorEl={anchorEl}
                placement="bottom-start"
            >
                <ClickAwayListener onClickAway={handleClose}>
                    <div>
                        <PopperHeader>
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Typography variant="caption" fontWeight="bold">
                                    {popperTitle}
                                </Typography>

                                {!disableClearable && (
                                    <Button
                                        onClick={handleClear}
                                        color="error"
                                        variant="text"
                                        size="small"
                                    >
                                        Clear
                                    </Button>
                                )}
                            </Stack>
                            {popperSubTitle}
                        </PopperHeader>
                        <Autocomplete
                            open
                            sx={{ py: 0 }}
                            onClose={handleAutocompleteOnClose}
                            filterOptions={filterOptions}
                            value={value}
                            groupBy={groupBy}
                            multiple={false}
                            freeSolo={false}
                            disableClearable={disableClearable}
                            onChange={handleAutocompleteOnChange}
                            PopperComponent={PopperComponent}
                            renderTags={() => null}
                            noOptionsText="No labels"
                            isOptionEqualToValue={(a, b) => a.id === b.id}
                            renderOption={AutocompleteOption}
                            options={options}
                            getOptionLabel={option => option.id}
                            renderInput={params => (
                                <StyledInput
                                    ref={params.InputProps.ref}
                                    inputProps={params.inputProps}
                                    autoFocus
                                />
                            )}
                        />
                    </div>
                </ClickAwayListener>
            </StyledPopper>
        </>
    );
};
