import { AutocompleteRenderInputParams } from '@mui/material/Autocomplete/Autocomplete';
import React from 'react';
import { TextField as MuiTextField, styled } from '@mui/material';
import { AutocompleteValue } from './Autocomplete';

const TextField = styled(MuiTextField)(
    ({ theme }) => `
    padding: 5px;
    box-sizing: border-box;
    background-color: ${theme.palette.background.paper};
`
);

export type AutocompleteInputProps<T> = AutocompleteRenderInputParams & {
    options: T[];
} & {
    isLoading?: boolean;
};

export type AutocompleteInputOverrideProps = {
    noResultsMessage?: string;
    placeholder?: string;
};

export const AutocompleteInput = <T extends AutocompleteValue>({
    options,
    isLoading,
    noResultsMessage,
    placeholder,
    ...params
}: AutocompleteInputProps<T> & AutocompleteInputOverrideProps) => (
    <TextField
        {...params}
        fullWidth
        inputProps={{
            ...params.inputProps,
            style: {
                paddingBottom: 0,
                margin: '0.3rem',
                fontSize: '.8rem',
            },
        }}
        size="small"
        placeholder={
            isLoading
                ? 'Loading...'
                : options.length === 0
                ? noResultsMessage
                : placeholder
        }
        variant="standard"
        InputProps={{
            ...params.InputProps,
            disableUnderline: true,
        }}
    />
);
