import { Annotation, Severity, severity } from '../../types/annotation';
import { Controller, useForm } from 'react-hook-form';
import { useAnnotationMutation } from './use-annotation-mutation.ts';
import {
    Box,
    Button,
    Collapse,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { LoadingButton } from '@mui/lab';
import * as React from 'react';
import { useEffect } from 'react';
// @ts-expect-error todo
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { SeverityIndicator } from './SeverityIndicator.tsx';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { SnackbarMessage } from '../../pages/Explorer/SnackBarMessage.tsx';

const validationSchema = z
    .object({
        id: z.string(),
        workspaceId: z.string(),
        severity: z.enum(severity),
        message: z.string().min(1, { message: 'Please provide a message' }),
        dateStart: z.date({ required_error: 'Please select a date range' }),
        dateEnd: z.date({ required_error: 'Please select a date range' }),
    })
    .refine(data => data.dateStart <= data.dateEnd, {
        path: ['dateEnd'],
        message: 'Start date of period must be before the end date',
    });

const SeverityListItem = ({ severity }: { severity: Severity }) => (
    <Box
        sx={{
            display: 'flex',
            gap: 1,
            alignItems: 'center',
        }}
    >
        <SeverityIndicator severity={severity} />
        <span
            style={{
                textTransform: 'capitalize',
            }}
        >
            {severity}
        </span>
    </Box>
);

export const AnnotationForm = ({
    initialAnnotation,
    collapsed,
    textFieldOnFocus,
    handleOnMutateSuccess,
}: {
    initialAnnotation: Annotation;
    collapsed: boolean;
    textFieldOnFocus: () => void;
    handleOnMutateSuccess?: () => void;
}) => {
    const {
        control,
        reset,
        handleSubmit,
        watch,
        setValue,
        formState: { isDirty },
    } = useForm({
        defaultValues: initialAnnotation,
        resolver: zodResolver(validationSchema),
    });

    const { update, isUpdating, isSuccess } = useAnnotationMutation();

    const mutatedAnnotation = watch();

    useEffect(() => {
        if (mutatedAnnotation.dateStart > mutatedAnnotation.dateEnd) {
            setValue('dateEnd', mutatedAnnotation.dateStart);
        }
    }, [mutatedAnnotation, setValue]);

    useEffect(() => {
        // reset form on collapse
        if (collapsed) {
            reset(initialAnnotation);
        }
    }, [collapsed, initialAnnotation, reset]);

    const submitHandler = handleSubmit(async (value: Annotation) =>
        update(value).then(() => {
            handleOnMutateSuccess?.();
            reset(initialAnnotation);
        })
    );

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Stack component="form" onSubmit={submitHandler}>
                <Box mb={!collapsed ? 1 : undefined}>
                    <Controller
                        control={control}
                        name="message"
                        render={({ field, fieldState }) => (
                            <TextField
                                {...field}
                                size="small"
                                multiline
                                variant="filled"
                                minRows={collapsed ? 1 : 3}
                                fullWidth
                                maxRows={7}
                                onFocus={textFieldOnFocus}
                                error={!!fieldState.error}
                                helperText={fieldState.error?.message}
                                label={
                                    collapsed
                                        ? 'Type to add new annotation...'
                                        : 'Message'
                                }
                            />
                        )}
                    />
                </Box>
                <Collapse in={!collapsed}>
                    <Stack gap={1}>
                        <FormControl
                            component="fieldset"
                            size="small"
                            variant="filled"
                        >
                            <InputLabel id="severity-label">
                                Severity
                            </InputLabel>
                            <Controller
                                rules={{ required: true }}
                                control={control}
                                name="severity"
                                render={({ field }) => (
                                    <Select
                                        labelId="severity-label"
                                        id="severity"
                                        label="Severity"
                                        MenuProps={{
                                            disableScrollLock: true,
                                        }}
                                        renderValue={(value: Severity) => (
                                            <SeverityListItem
                                                severity={value}
                                            />
                                        )}
                                        {...field}
                                    >
                                        {severity.map(level => (
                                            <MenuItem key={level} value={level}>
                                                <SeverityListItem
                                                    severity={level}
                                                />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                )}
                            />
                        </FormControl>

                        <Stack
                            direction="row"
                            spacing={1}
                            justifyContent="space-between"
                        >
                            <Controller
                                control={control}
                                name="dateStart"
                                render={({ field, fieldState }) => (
                                    <DatePicker
                                        {...field}
                                        slotProps={{
                                            openPickerIcon: {
                                                fontSize: 'small',
                                            },
                                            openPickerButton: {
                                                size: 'small',
                                                sx: {
                                                    marginRight: -1,
                                                    marginTop: 2,
                                                },
                                            },
                                            textField: {
                                                fullWidth: true,
                                                variant: 'filled',
                                                error: !!fieldState.error,
                                                size: 'small',
                                                helperText:
                                                    fieldState.error?.message,
                                            },
                                        }}
                                        label="Start date"
                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                name="dateEnd"
                                render={({ field, fieldState }) => (
                                    <DatePicker
                                        {...field}
                                        slotProps={{
                                            openPickerIcon: {
                                                fontSize: 'small',
                                            },
                                            openPickerButton: {
                                                size: 'small',
                                                sx: {
                                                    marginRight: -1,
                                                    marginTop: 2,
                                                },
                                            },
                                            textField: {
                                                fullWidth: true,
                                                variant: 'filled',
                                                error: !!fieldState.error,
                                                helperText:
                                                    fieldState.error?.message,
                                            },
                                        }}
                                        label="End date"
                                    />
                                )}
                            />
                        </Stack>

                        <Stack
                            direction="row"
                            spacing={1}
                            justifyContent="space-between"
                            mt={1}
                        >
                            <Box>
                                {isDirty && (
                                    <Button
                                        hidden={!isDirty}
                                        type="reset"
                                        size="small"
                                        onClick={e => {
                                            e.preventDefault();
                                            reset(initialAnnotation);
                                        }}
                                    >
                                        Reset
                                    </Button>
                                )}
                            </Box>

                            <LoadingButton
                                type="submit"
                                size="small"
                                disabled={!isDirty}
                                loading={isUpdating}
                            >
                                Save
                            </LoadingButton>
                        </Stack>
                    </Stack>
                </Collapse>
                {isSuccess && (
                    <SnackbarMessage message="Annotation successfully saved" />
                )}
            </Stack>
        </LocalizationProvider>
    );
};
