import { DataModelField, DataModelSchema } from '../types/datamodel/schema';
import { match } from 'ts-pattern';
import { getFieldsFromSchema } from './schema';
import { SqlContext } from './field-sql.ts';
import { sqlForFilter } from './filters-sql.ts';

export const sqlForMetrics = (
    schema: DataModelSchema,
    metrics: string[],
    indent: string = ''
): string =>
    getFieldsFromSchema(schema, metrics)
        .map(m => `${indent}(${sqlForMetric(m)})::DOUBLE AS "${m.id}",`)
        .join('\n');

export const sqlForMetric = (
    field: DataModelField,
    context: SqlContext = {}
) => {
    const percentageRange = context.percentRange || '1.0';
    const filter = context.pivotFilter
        ? ` filter (${sqlForFilter(context.pivotFilter)})`
        : '';

    const prefix = (input: string) =>
        escape(
            context.sourcePrefix ? `${context.sourcePrefix}${input}` : input
        );

    const escape = (input: string) => `"${input.replace('"', '\\"')}"`;

    return match(field)
        .with(
            { agg: { type: 'sum' } },
            ({ id }) => `SUM(${prefix(id)})${filter}`
        )
        .with(
            { agg: { type: 'weighted_avg' } },
            ({ agg: { numerator, denominator } }) =>
                `${percentageRange} * SUM(${prefix(
                    numerator
                )})${filter} / SUM(${prefix(denominator)})${filter}`
        )
        .with({ agg: { type: 'count_distinct' } }, ({ id }) =>
            context.intermediateTable
                ? `flatten(array_agg(${prefix(id)})${filter})`
                : `list_unique(flatten(array_agg(${prefix(id)})))`
        )
        .otherwise(field => {
            throw new Error(
                `Unable to generate SQL for field ${field.id} without aggregation details`
            );
        });
};
