import { Box, Skeleton, Stack } from '@mui/material';
import styled from 'styled-components';

const AltContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 150px;
    padding: 0 8px;
    width: 100%;
    height: 240px;
`;
export const PerformanceCardSkeleton = () => (
    <AltContainer>
        <Stack spacing={1} width="100%">
            <Skeleton variant="rectangular" width="100%" height={84} />
            <Skeleton variant="rectangular" width="90%" height={60} />
            <Skeleton variant="text" width="50%" />
            <Skeleton variant="text" width="50%" />
        </Stack>
    </AltContainer>
);
