import { Target } from '../../../types/targets';
import { DateRange } from '../../../components/DateRangePicker/reducer.tsx';
import { BinaryFilter } from '../../../queries/filters.ts';
import { useActiveWorkspace } from '../../../providers/WorkspaceProvider.hooks.ts';
import { Md5 } from 'ts-md5';
import { useDuckDatasetQuery } from '../../../hooks/use-duck-dataset-query.ts';
import { DataModelSchema } from '../../../types/datamodel/schema.ts';
import { dateFormat } from '../../../utils/date-format.ts';
import { sqlForFilters } from '../../../queries/filters-sql.ts';
import { indent } from '../../../queries/indent.ts';
import { ComparePreset } from '../../../components/DateRangePicker/presetDefinitions.ts';
import { match } from 'ts-pattern';
import {
    precedingPeriod,
    yearOverYear,
} from '../../../components/DateRangePicker/dateHelpers.ts';

type TargetQueryOptions = {
    dateRange?: DateRange;
    metric: string;
    filters: BinaryFilter[];
};

type PrecedingPeriodResult = { value: number };

export const usePrecedingPeriodData = (
    target: Target,
    comparePeriod: Extract<ComparePreset, 'preceding_period' | 'year_over_year'>
) => {
    const { workspaceId } = useActiveWorkspace();

    const dateRange: DateRange = match(comparePeriod)
        .with('preceding_period', () =>
            precedingPeriod({ start: target.dateStart, end: target.dateEnd })
        )
        .with('year_over_year', () =>
            yearOverYear({ start: target.dateStart, end: target.dateEnd })
        )
        .run();

    const query: TargetQueryOptions = {
        dateRange,
        filters: target.filters,
        metric: target.metric,
    };

    const queryKey = Md5.hashStr(
        JSON.stringify({ dataset: target.dataset, ...query })
    );

    const {
        data = [],
        isLoading,
        error,
    } = useDuckDatasetQuery<PrecedingPeriodResult>({
        queryKey: ['duckdb', workspaceId, 'time-series', queryKey],
        dataset: target.dataset,
        queryFn: (sourceTable, _, schema) =>
            renderSql(schema, sourceTable, query),
        queryOptions: {
            enabled: !!target.metric,
        },
    });

    return {
        error,
        isLoading,
        data,
        value: data[0],
    };
};

function renderSql(
    schema: DataModelSchema,
    sourceTable: string,
    query: TargetQueryOptions
) {
    if (!query.dateRange) {
        return `
SELECT 
  (SUM(${query.metric}))::DOUBLE AS value
FROM ${sourceTable}
${indent(sqlForFilters(schema, query.filters), 6)}
`;
    }

    const start = dateFormat(query.dateRange.start);
    const end = dateFormat(query.dateRange.end);

    return `
SELECT 
  (SUM(${query.metric}))::DOUBLE AS value
FROM ${sourceTable}
WHERE ${schema.dateField} BETWEEN date '${start}' AND date '${end}'
${indent(sqlForFilters(schema, query.filters), 6)}
`;
}
