import { useDrawer } from '../../hooks/use-drawer.ts';
import { SeinoDrawer } from '../Drawer/Drawer.tsx';
import { CircularProgress, Stack } from '@mui/material';
import { useAnnotations } from './use-annotations.ts';
import { MutableAnnotation } from './MutableAnnotation.tsx';
import React, { useMemo } from 'react';
import { newAnnotation } from '../../types/annotation';
import { useActiveUser } from '../../providers/CurrentUserProvider.hooks.ts';
import Typography from '@mui/material/Typography';
import { useDateRange } from '../../hooks/use-date-range.ts';
import { useActiveWorkspaceId } from '../../providers/useActiveWorkspaceId.ts';

export function AnnotationsDrawer() {
    const { isOpen, closeDrawer } = useDrawer('annotations');

    const workspaceId = useActiveWorkspaceId();
    const activeUser = useActiveUser();

    const {
        dateRanges: { main },
    } = useDateRange();

    const { data, isLoading } = useAnnotations(main);

    const annotations = useMemo(
        () =>
            data?.map(annotation => (
                <MutableAnnotation
                    key={annotation.id}
                    annotation={annotation}
                    collapsedOnInit={false}
                />
            )),
        [data]
    );

    return (
        <SeinoDrawer
            open={isOpen}
            variant="temporary"
            header={<span>Annotations</span>}
            onClose={closeDrawer}
        >
            <Stack p={2} gap={2}>
                <MutableAnnotation
                    annotation={newAnnotation(workspaceId, activeUser)}
                    isNew
                />

                {isLoading && (
                    <Stack direction="row" gap={2}>
                        <CircularProgress size={12} />
                        <Typography variant="smallMediumBody">
                            Loading
                        </Typography>{' '}
                    </Stack>
                )}

                {annotations}
            </Stack>
        </SeinoDrawer>
    );
}
