import { createContext, PropsWithChildren } from 'react';
import { useInitialData } from '../hooks/use-initial-data';
import { InitialData } from '../types/user';

export const CurrentUserContext = createContext<InitialData | undefined>(
    undefined
);

export function CurrentUserProvider({ children }: PropsWithChildren) {
    const { data: initialData } = useInitialData();

    if (!initialData) {
        return null;
    }

    return (
        <CurrentUserContext.Provider value={initialData}>
            {children}
        </CurrentUserContext.Provider>
    );
}
