import { Fade, Link } from '@mui/material';
import styled from 'styled-components';
import { SeinoLogo } from '../Graphics/SeinoLogo';
import { useSeinoAuth } from '../../hooks/use-seino-auth';
import { ReactNode } from 'react';
import { useLocationChange } from '../../hooks/use-location-change';

const ErrorPageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 75vh;
    width: 500px;
    margin: auto;
    justify-content: center;
    text-align: center;
`;

const LogoWrapper = styled.div`
    margin: 0 auto 3rem;
`;

const ActionsWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const Action = styled.div`
    margin: 0.125rem 0;
`;

interface ErrorPageProps {
    title?: string | ReactNode;
    subtitle?: string | ReactNode;
    showActions?: boolean;
    resetError?: () => void;
}

export const ErrorPage = ({
    title,
    subtitle,
    showActions = true,
    resetError = () => {},
}: ErrorPageProps) => {
    const { logout } = useSeinoAuth();

    useLocationChange(resetError);

    return (
        <Fade in={true}>
            <ErrorPageWrapper>
                <LogoWrapper>
                    <SeinoLogo />
                </LogoWrapper>
                <p>{title || 'Yikes, we encountered an error!'}</p>
                {subtitle || (
                    <p>
                        We've been notified and will try to fix it as soon as
                        possible. In the meantime, you can try the following to
                        mitigate the error:
                    </p>
                )}

                {showActions && (
                    <ActionsWrapper>
                        <Action>
                            <Link
                                href="#"
                                onClick={() => window.location.reload()}
                            >
                                Refresh the page
                            </Link>
                        </Action>
                        <Action>
                            <Link
                                href="#"
                                onClick={() =>
                                    logout({
                                        logoutParams: {
                                            returnTo: window.location.origin,
                                        },
                                    })
                                }
                            >
                                Sign in again
                            </Link>
                        </Action>
                        <Action>
                            <span>Try a different browser</span>
                        </Action>
                        <Action>
                            <span>Check your internet connection</span>
                        </Action>
                    </ActionsWrapper>
                )}
            </ErrorPageWrapper>
        </Fade>
    );
};
