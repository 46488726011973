import axios from 'axios';
import { getRustAPIUrl } from '../../utils/api';
import { z, TypeOf } from 'zod';
import { DataModelSchema } from '../../types/datamodel/schema.ts';

interface ListDatasetsResponse {
    datasets: Dataset[];
}

export interface Dataset {
    id: DatasetId;
    title: string;
}

export type DatasetWithSchema = Dataset & { schema: DataModelSchema };

export type DatasetId =
    | 'campaign_metrics_by_send_date'
    | 'campaign_metrics_by_event_date'
    | 'list_members'
    | 'website_metrics_ga_api'
    | 'ecommerce'
    | 'squeezely'
    | string;

export const fetchAvailableDatasets = (workspaceId: string) => async () => {
    const result = await axios.get<ListDatasetsResponse>(
        `${getRustAPIUrl()}/workspaces/${workspaceId}/datasets`
    );

    if (!result.data.datasets?.length) {
        return [
            {
                id: 'campaign_metrics_by_send_date',
                title: 'Campaign metrics by send date',
            } satisfies Dataset,
        ];
    }

    return result.data.datasets.filter(f => !f.id.endsWith('_unnested'));
};

const campaignDetailsSchema = z.object({
    url: z.string().nullable(),
});

export type CampaignDetails = TypeOf<typeof campaignDetailsSchema>;

export const fetchCampaignDetails = async (
    workspaceId: string,
    campaignId: string
): Promise<CampaignDetails> => {
    const response = await axios.get(
        `${getRustAPIUrl()}/workspaces/${workspaceId}/datasets/campaigns/${campaignId}`
    );

    return campaignDetailsSchema.parse(response.data);
};
