import { createEventBus } from './event-bus.ts';
import { createUseSubscribe } from './create-use-subscribe.ts';
import { ExplorerView } from '../components/ExplorerGrid/grid/utils/gridView.ts';

export type Event =
    | { type: 'explorer_reset'; view: ExplorerView }
    | { type: 'target_created'; id: string; name: string; widgetId?: string };

export const eventBus = createEventBus<Event>();
export const useSubscribe = createUseSubscribe(eventBus);
