import { TooltipProps } from 'recharts';
import { useLocale } from '../../providers/LocaleProvider.hooks';
import { format } from 'date-fns';
import { Box, Card, Stack, Typography } from '@mui/material';
import { formatNumber } from '../../utils/number-format';
import React from 'react';

export const RechartTooltip = ({
    active,
    label,
    payload,
}: TooltipProps<any, any>) => {
    const locale = useLocale();

    const dateFormat = 'EEEE do, LLLL yyyy';
    const textLabel = label instanceof Date ? format(label, dateFormat) : label;

    if (!active) {
        return null;
    }

    return (
        <Card elevation={2} sx={{ p: 2, zIndex: 10000 }}>
            <Stack spacing={1}>
                <Typography variant="body2">
                    <strong>{textLabel}</strong>
                </Typography>

                {payload?.map((condition, i) => (
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        key={i}
                    >
                        <Stack
                            direction="row"
                            spacing={1}
                            sx={{ marginRight: 1.5 }}
                        >
                            <Box
                                sx={{
                                    width: 4,
                                    height: '100%',
                                    background: condition.color,
                                }}
                            ></Box>
                            <Typography variant="body2">
                                {condition.name}
                            </Typography>
                        </Stack>

                        <Typography variant="body2">
                            {formatNumber(condition.value, 'number', locale)}
                        </Typography>
                    </Stack>
                ))}
            </Stack>
        </Card>
    );
};
