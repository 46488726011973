import { ModifiedBy } from '../../types/user';
import { useActiveUser } from '../../providers/CurrentUserProvider.hooks.ts';
import { formatDistanceToNow } from 'date-fns';
import { Tooltip, Typography } from '@mui/material';
import React from 'react';
import { History } from '@mui/icons-material';
import { useFlags } from 'launchdarkly-react-client-sdk';

type LastModifiedTextProps = {
    modifiedBy?: ModifiedBy;
    modifiedAt: number;
};
export const LastModifiedText = ({
    modifiedAt,
    modifiedBy,
}: LastModifiedTextProps) => {
    const user = useActiveUser();
    const { annotations } = useFlags();

    const owner = user?.memberId === modifiedBy?.id ? 'you' : modifiedBy?.name;

    const text =
        `Saved ${formatDistanceToNow(new Date(modifiedAt))} ago` +
        (owner ? ` by ${owner}` : '');

    if (annotations) {
        return (
            <Tooltip title={text} placement="top">
                <History fontSize="small" />
            </Tooltip>
        );
    }

    return <Typography variant="caption">{text}</Typography>;
};
