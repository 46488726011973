import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { formatNumber, NumberFormat } from '../../../../../utils/number-format';
import { ChangeText, ValueWrapperComponent } from '../SubscriberList.styles';
import { useLocale } from '../../../../../providers/LocaleProvider.hooks';
import { SubscriberListDatum } from '../use-subscriber-list-data';

interface ChangeProps {
    data: SubscriberListDatum[];
}

export const ChangeRechart = ({ data }: ChangeProps) => {
    const locale = useLocale();
    if (!data || data.length < 1) return <></>;

    const values = data
        .filter(x => isFinite(x.members ?? NaN))
        .map(x => x.members);

    const startValue = values[0];
    const endValue = values[values.length - 1];
    const totalDelta = endValue - startValue;
    const isPositive = totalDelta > 0;
    const Indicator = totalDelta > 0 ? ArrowUpward : ArrowDownward;

    return (
        <Box display="flex" justifyContent="flex-end">
            <Typography variant="h6">
                {formatNumber(endValue, NumberFormat.Generic, locale)}
                <ValueWrapperComponent
                    isPositive={isPositive ? 'true' : undefined}
                >
                    <Indicator
                        fontSize={'small'}
                        style={{ verticalAlign: 'middle' }}
                    />
                    <ChangeText sx={{ fontWeight: 'bold' }}>
                        {`${formatNumber(
                            totalDelta,
                            NumberFormat.Generic,
                            locale
                        )}`}
                    </ChangeText>
                </ValueWrapperComponent>
                <ValueWrapperComponent
                    isPositive={isPositive ? 'true' : undefined}
                >
                    <ChangeText>
                        {formatNumber(
                            totalDelta / startValue,
                            'percent',
                            locale
                        )}
                    </ChangeText>
                </ValueWrapperComponent>
            </Typography>
        </Box>
    );
};
