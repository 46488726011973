import { Box, Button } from '@mui/material';
import { useDashboardMutation } from './use-dashboard-mutation.ts';

export const NewDashboard = () => {
    const { createDashboard } = useDashboardMutation();

    return (
        <Box>
            <Button
                type="button"
                size="small"
                onClick={() => createDashboard()}
            >
                New
            </Button>
        </Box>
    );
};
