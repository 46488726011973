import * as React from 'react';
import { useViews } from '../../hooks/use-explorer-views';
import { ExplorerView } from '../../components/ExplorerGrid/grid/utils/gridView';
import { useActiveWorkspace } from '../../providers/WorkspaceProvider.hooks';
import { useNavigate } from 'react-router-dom';
import { useRemoveView } from './Landing/useRemoveView';
import { useModify } from './Landing/useModify';
import ItemSelector from '../../components/ItemSelector/ItemSelector';
import { useExplorerState } from '../../hooks/use-explorer-state.ts';
import { isPermitted } from '../../types/permission/permission.ts';

type MinimalViewProperties = Pick<ExplorerView, 'id' | 'title' | 'modifiedAt'>;

export const ViewSelector = () => {
    const { workspaceId } = useActiveWorkspace();
    const { remove } = useRemoveView(workspaceId);
    const { data: views = [], isLoading } = useViews();
    const { view: currentView } = useExplorerState();
    const { duplicate } = useModify(workspaceId);
    const navigate = useNavigate();

    const handleViewSelect = (row: MinimalViewProperties) => {
        navigate(`/workspaces/${workspaceId}/explorer/${row.id}`);
    };

    const items = views.map(view => ({
        name: view.id === currentView.id ? currentView.title : view.title,
        ...view,
    }));

    const handleOnDuplicate = (item: MinimalViewProperties) => {
        const viewToDuplicate = items?.find(v => v.id === item.id);

        if (viewToDuplicate) {
            duplicate(viewToDuplicate).then(newView =>
                navigate(`/workspaces/${workspaceId}/explorer/${newView.id}`)
            );
        }
    };

    const handleOnRemove = ({ id }: MinimalViewProperties) => {
        const otherView = items.find(v => v.id !== id);
        if (otherView) {
            navigate(`/workspaces/${workspaceId}/explorer/${otherView.id}`);
            remove(id);
        }
    };

    return (
        <ItemSelector
            title="Select view"
            closeOnSelect
            items={items}
            onDuplicate={handleOnDuplicate}
            onRemove={handleOnRemove}
            isLoading={isLoading}
            canRemove={item =>
                isPermitted(item.accessLevel, 'delete') &&
                (items?.length || [].length) > 1
            }
            selected={items.find(d => d.id === currentView.id)}
            onSelect={i => handleViewSelect(i)}
        />
    );
};
