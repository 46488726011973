import { Box, Container, Stack } from '@mui/material';
import { PageWithSidebar } from '../../components/PageWithSidebar/PageWithSidebar.tsx';
import { Outlet, useMatch, useNavigate } from 'react-router-dom';
import * as React from 'react';
import { Menu } from '../../components/Menu/Menu.tsx';
import { Apps } from '@mui/icons-material';
import { useActiveWorkspace } from '../../providers/WorkspaceProvider.hooks.ts';

export const AdminSettings = () => {
    return (
        <Container maxWidth="lg">
            <Box py={2}>
                <PageWithSidebar
                    sideBar={
                        <Stack spacing={1} p={2}>
                            <AdminSideNav />
                        </Stack>
                    }
                >
                    <Box flexGrow={1} display="flex" flexDirection="column">
                        <Outlet />
                    </Box>
                </PageWithSidebar>
            </Box>
        </Container>
    );
};

const AdminSideNav = () => {
    const navigate = useNavigate();
    const { workspace } = useActiveWorkspace();
    const navigateTo = (path: string) =>
        navigate(`/workspaces/${workspace.id}/admin${path}`);

    return (
        <Menu
            sections={[
                {
                    id: 'items',
                    tabs: [
                        {
                            id: 'workspaces',
                            primary: 'Workspaces',
                            selected: Boolean(
                                useMatch(
                                    '/workspaces/:workspaceId/admin/workspaces'
                                )
                            ),
                            icon: <Apps fontSize="small" />,
                            onClick: () => navigateTo('/workspaces'),
                        },
                    ],
                },
            ]}
        />
    );
};
