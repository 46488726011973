import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import axios from 'axios';
import { getRustAPIUrl } from '../utils/api.ts';
import { Permission } from '../types/permission/permission.ts';

type UpdatePermissionArgs = Pick<
    Permission,
    | 'id'
    | 'workspaceId'
    | 'objectType'
    | 'objectId'
    | 'subjectType'
    | 'subjectId'
    | 'accessLevel'
>;

type Options = Pick<
    UseMutationOptions<unknown, Error, UpdatePermissionArgs>,
    'onSuccess' | 'onError'
>;

const updatePermission = ({ workspaceId, id, ...rest }: UpdatePermissionArgs) =>
    axios.put(
        `${getRustAPIUrl()}/workspaces/${workspaceId}/permissions/${id}`,
        rest
    );

const removePermission = ({ workspaceId, id }: UpdatePermissionArgs) =>
    axios.delete(
        `${getRustAPIUrl()}/workspaces/${workspaceId}/permissions/${id}`
    );

export const usePermissionMutation = (options?: Options) =>
    useMutation<unknown, Error, UpdatePermissionArgs>({
        mutationFn: variables => {
            if (variables.accessLevel === 'none') {
                return removePermission(variables);
            } else {
                return updatePermission(variables);
            }
        },
        ...options,
    });
