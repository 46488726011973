import * as React from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import { match } from 'ts-pattern';
import { FormatType } from '../../types/datamodel/schema';
import { useLocale } from '../../providers/LocaleProvider.hooks.ts';

type NumberTextFieldProps = TextFieldProps & {
    format: FormatType;
};

export const NumberTextField = ({
    format,
    onChange,
    ...props
}: NumberTextFieldProps) => {
    const { currencySymbol } = useLocale();
    const inputProps = match(format)
        .with('percent', () => ({ endAdornment: '%' }))
        .with('currency', () => ({ startAdornment: currencySymbol }))
        .otherwise(() => ({}));

    return (
        <TextField
            onChange={e => {
                e.target.value = e.target.value
                    .replace(',', '.')
                    .replace(/[^0-9.]/g, '');
                onChange && onChange(e);
            }}
            inputMode="numeric"
            InputProps={{
                ...inputProps,
                sx: { '& input': { textAlign: 'right', paddingRight: 1 } },
            }}
            {...props}
        />
    );
};
