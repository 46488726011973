import { BaseRow } from './types';
import { getValueAsString } from './getValueAsString';

export function getFinalValue<T extends BaseRow>(
    row: T,
    name: keyof T,
    getValue?: (row: T) => string
): string {
    return (getValue ? getValue(row) : getValueAsString(row[name])) ?? '';
}
