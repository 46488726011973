import { TextField } from '@mui/material';
import { useDashboardState } from './useDashboardState.ts';
import { setTitle } from '../../types/dashboard';
import { useForm } from 'react-hook-form';
import * as React from 'react';
import { useEffect } from 'react';
import { z } from 'zod';
// @ts-expect-error todo
import { zodResolver } from '@hookform/resolvers/zod';
import { useDebouncedCallback } from 'use-debounce';

export const DashboardTitle = () => {
    const { saveLocal, localDashboard } = useDashboardState();
    const updateValue = useDebouncedCallback(saveLocal, 300);

    const {
        handleSubmit,
        register,
        reset,
        formState: { errors },
    } = useForm<{ title: string }>({
        mode: 'onBlur',
        resolver: zodResolver(
            z.object({ title: z.string().min(1) }).required()
        ),
        defaultValues: {
            title: localDashboard.config.title,
        },
    });

    useEffect(() => {
        reset({ title: localDashboard.config.title });
    }, [localDashboard, reset]);

    const onSubmit = ({ title }: { title: string }) => {
        updateValue(setTitle(localDashboard, title));
    };

    return (
        <TextField
            size="small"
            fullWidth
            label="Name"
            variant="outlined"
            error={Boolean(errors.title)}
            helperText={errors.title?.message}
            margin="none"
            {...register('title', {
                required: 'This field is required',
                onBlur: handleSubmit(onSubmit),
            })}
        />
    );
};
