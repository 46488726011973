import { match, P } from 'ts-pattern';
import { DataModelField, DataModelSchema } from '../types/datamodel/schema';
import { BinaryFilter } from './filters';
import { isDefined } from '../utils/is-defined';

export const fieldExists =
    (schema: DataModelSchema) => (field: string | BinaryFilter | undefined) => {
        const fieldId = match(field)
            .with({ member: P.string }, f => f.member)
            .with(P.string, f => f)
            .otherwise(() => 'unknown pattern');

        return !!schema.fields.find(f => f.id === fieldId);
    };

type FindField = ReturnType<typeof findField>;

export const findField =
    (schema: DataModelSchema | undefined) =>
    (fieldId: string | undefined): DataModelField | undefined =>
        schema?.fields.find(f => f.id === fieldId);

export const isField = (f: DataModelField | undefined): f is DataModelField =>
    !!f;

export const includeSourceMetrics =
    (findField: FindField) => (f: DataModelField) =>
        match(f)
            .with(
                { agg: { type: 'weighted_avg' } },
                ({ agg: { numerator, denominator } }) =>
                    [f, findField(numerator), findField(denominator)].filter(
                        isField
                    )
            )
            .otherwise(() => [f]);

export const getFieldsFromSchema = (
    schema: DataModelSchema,
    fields: string[]
): DataModelField[] => fields.map(findField(schema)).filter(isDefined);
