import { useQuery } from '@tanstack/react-query';
import { DatasetId, fetchAvailableDatasets } from '../api-client/datasets';
import { useActiveWorkspace } from '../providers/WorkspaceProvider.hooks';

export const useDatasets = () => {
    const { workspaceId } = useActiveWorkspace();

    return useQuery(
        ['datasets', workspaceId],
        fetchAvailableDatasets(workspaceId)
    );
};

export const useHasDataset = (dataset: DatasetId) => {
    const { data } = useDatasets();

    return data?.some(d => d.id === dataset);
};
