import styled from 'styled-components';
import { LoadingButton } from '@mui/lab';

export const ActionButton: typeof LoadingButton = styled(LoadingButton).attrs(
    props => ({
        color: props.color || 'info',
        size: props.size || 'small',
        variant: props.variant || 'outlined',
    })
)``;
