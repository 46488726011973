import { useSeinoStore } from '../../store/seino-store.ts';
import { useDashboards } from './use-dashboards.ts';
import { useNavigate, useParams } from 'react-router-dom';
import { isPermitted } from '../../types/permission/permission.ts';
import { useActiveWorkspaceId } from '../../providers/useActiveWorkspaceId.ts';

export function useDashboardFromRoute() {
    const { dashboardId } = useParams<{ dashboardId: string | undefined }>();
    const { data, isLoading } = useDashboards();
    const workspaceId = useActiveWorkspaceId();
    const navigate = useNavigate();

    const selectedDashboard = data?.find(({ id }) => dashboardId === id);
    const state = useSeinoStore(state => state.dashboard);

    const canWrite = isPermitted(selectedDashboard?.accessLevel, 'write');

    const handleSelectOnChange = (id: string) =>
        navigate(`/workspaces/${workspaceId}/dashboard/${id}`);

    return {
        ...state,
        isLoading,
        canWrite,
        setSelectedDashboardId: handleSelectOnChange,
        selectedDashboard,
        selectedDashboardId: dashboardId,
    };
}
