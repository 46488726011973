import * as React from 'react';
import { useRef, useState } from 'react';
import { Box } from '@mui/material';
import { Condition, Dimension, HealthChartDatum } from './HealthChart';
import { HealthChartTooltip } from './HealthChartTooltip';
import { resultToColor } from '../../utils/resultToColor';

interface HealthChartItemProps {
    conditions: Condition[];
    dimension: Dimension | null;
    data: HealthChartDatum;
}

export const HealthChartItem = ({
    conditions,
    dimension,
    data,
}: HealthChartItemProps) => {
    const ref = useRef();
    const [hover, setHover] = useState(false);

    return (
        <>
            <Box
                ref={ref}
                key={data.rangeEnd}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                sx={theme => ({
                    borderRadius: 2,
                    width: '6px',
                    height: '32px',
                    background: resultToColor(data.result, theme),
                    '&:hover': {
                        background: theme.palette.text.primaryLight,
                    },
                })}
            />
            <HealthChartTooltip
                key={`${data.rangeEnd}-tooltip`}
                open={hover}
                data={data}
                conditions={conditions}
                dimension={dimension}
                anchorEl={ref.current}
            />
        </>
    );
};
