import { useActiveWorkspace } from '../../providers/WorkspaceProvider.hooks';
import { useCampaignCollection } from '../../hooks/use-campaign-collections';
import { Baseline } from '../../types/baselines';

import { CollectionConfigurator } from './CollectionConfigurator';
import { CurrentViewBaselinesConfigurator } from './CurrentViewBaselinesConfigurator';
import { FormWrapper } from '../Form';
import React, { useMemo } from 'react';
import {
    CampaignCollection,
    createBaselineTargetsFromKpiData,
    createCollection,
} from '../../types/collections';
import { ExplorerView } from '../ExplorerGrid/grid/utils/gridView';
import { updateWithKpiData } from '../CampaignCollection/updateWithKpiData';
import { useCampaignCollectionMutation } from '../../hooks/use-campaign-collections-mutation';
import { SelectCollection } from './CollectionForm/SelectCollection';
import { ulid } from 'ulid';
import { useDatasetSchema } from '../../hooks/use-dataset-schema';
import { useExplorerState } from '../../hooks/use-explorer-state';
import { useDialog } from '../Dialog/useDialog.ts';

export type CollectionForm = {
    title: string;
    targets?: Baseline[];
};

export const Collections = () => {
    const {
        parameters: { baselineId },
        openDialog,
    } = useDialog<{ baselineId?: string }>('explorer');
    const { workspaceId } = useActiveWorkspace();
    const { saveLocal, view } = useExplorerState();
    const { create, update, remove } = useCampaignCollectionMutation();
    const { data: campaignCollections = [] } =
        useCampaignCollection(workspaceId);
    const {
        data: datasetSchema = { fields: [] },
        isInitialLoading,
        isLoading,
    } = useDatasetSchema(
        view.config.dataset || 'campaign_metrics_by_send_date'
    );

    const { closeDialog } = useDialog('explorer');
    const [editMode, setEditMode] = React.useState(false);
    const [createTemplate, setCreateTemplate] = React.useState(false);

    const collection = campaignCollections?.find(
        ({ baselineSet }) => baselineSet.id === baselineId
    );

    const schemas = datasetSchema.fields;

    const defaultValues = useMemo(
        () =>
            collection
                ? {
                      title: collection?.name || '',
                      targets: collection?.baselineSet.targets || [],
                  }
                : {
                      title: '',
                      targets: createBaselineTargetsFromKpiData(
                          view.config.kpiData,
                          schemas || []
                      ),
                  },
        [collection, view.config.kpiData, schemas]
    );

    const applyKPIs = (
        collection: CampaignCollection | undefined,
        currentView: ExplorerView
    ) => {
        saveLocal({
            ...currentView,
            config: updateWithKpiData(currentView.config, collection),
        });
    };

    if (!baselineId || isInitialLoading || isLoading) {
        return null;
    }

    const reset = () => {
        setEditMode(false);
        setCreateTemplate(false);
    };

    const handleOnRemove = (collectionId: string) => {
        remove(collectionId);
        openDialog({ baselineId: 'current' });
        reset();
    };

    const createNewTemplate = async (form: CollectionForm) => {
        const newCollection = createCollection();
        const updatedCollection = {
            ...newCollection,
            name: form.title,
            baselineSet: {
                id: newCollection.baselineSet.id,
                targets: form.targets || [],
            },
        };

        await create(updatedCollection);
        openDialog({ baselineId: updatedCollection.baselineSet.id });
        setCreateTemplate(false);
        setEditMode(false);
    };

    const saveTemplate = (updatedCollection: CampaignCollection) => {
        update(updatedCollection.id, () => updatedCollection);
        setEditMode(false);
        // if the saved template is the one currently applied to the view, update the view
        if (view.config.baselineId === updatedCollection.baselineSet.id) {
            applyKPIs(updatedCollection, view);
        }
    };

    const applyTemplateToView = (updatedCollection: CampaignCollection) => {
        applyKPIs(updatedCollection, view);
        closeDialog();
    };

    const applyBaselinesToView = (updatedCollection: CampaignCollection) => {
        applyKPIs(
            {
                ...updatedCollection,
                baselineSet: {
                    ...updatedCollection.baselineSet,
                    id: ulid(),
                },
            },
            view
        );
        closeDialog();
    };

    const handleOnSubmit = async (form: CollectionForm) => {
        if (createTemplate) {
            await createNewTemplate(form);
            return;
        }

        const baseCollection = collection || createCollection();

        const updatedCollection = {
            ...baseCollection,
            name: form.title,
            baselineSet: {
                id: baseCollection?.baselineSet.id,
                targets: form.targets || [],
            },
        };

        if (baselineId === 'none') {
            applyKPIs(undefined, view);
            closeDialog();
        }

        if (!baselineId || baselineId === 'current') {
            applyBaselinesToView(updatedCollection);
            return;
        }

        if (editMode) {
            saveTemplate(updatedCollection);
        } else {
            applyTemplateToView(updatedCollection);
        }
    };

    return (
        <FormWrapper<CollectionForm>
            onSubmit={handleOnSubmit}
            defaultValues={defaultValues}
            style={{ width: '100%' }}
        >
            <SelectCollection
                hide={editMode}
                onSelect={reset}
                schemas={schemas}
            />
            {baselineId === 'current' ? (
                <CurrentViewBaselinesConfigurator
                    createTemplate={createTemplate}
                    schemas={schemas}
                    setCreateTemplate={setCreateTemplate}
                />
            ) : (
                <CollectionConfigurator
                    editMode={editMode}
                    setEditMode={setEditMode}
                    baselineId={baselineId}
                    schemas={schemas}
                    onRemove={handleOnRemove}
                />
            )}
        </FormWrapper>
    );
};
