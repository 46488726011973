import { Done, MoreVert } from '@mui/icons-material';
import {
    Box,
    Button,
    ButtonProps,
    ClickAwayListener,
    Grow,
    IconButton,
    IconButtonProps,
    ListItemIcon,
    ListItemText,
    MenuItem,
    MenuList,
    Paper,
    Popper,
} from '@mui/material';
import React, { ReactElement, useRef, useState } from 'react';
import { PopperPlacementType } from '@mui/base/Popper/Popper.types';

export type MoreMenuItem = {
    key: string;
    label: ReactElement | string;
    secondary?: ReactElement | string;
    icon?: ReactElement;
    disabled?: boolean;
    onClick?: (event: React.MouseEvent) => void;
    hasDivider?: boolean;
};

type MoreMenuProps = {
    icon?: ReactElement;
    header?: ReactElement;
    buttonLabel?: ReactElement | string;
    buttonProps?: Omit<ButtonProps, 'onClick'>;
    iconButtonProps?: Omit<IconButtonProps, 'onClick'>;
    items: MoreMenuItem[];
    selected?: MoreMenuItem['key'];
    placement?: PopperPlacementType;
    disableMenuListPadding?: boolean;
};

export const MoreMenu = ({
    selected,
    items,
    icon,
    header,
    buttonLabel,
    iconButtonProps,
    placement,
    buttonProps,
    disableMenuListPadding,
}: MoreMenuProps) => {
    const anchorEl = useRef<null | HTMLButtonElement>(null);
    const [open, setOpen] = useState(false);

    const handleToggle = () => setOpen(prevState => !prevState);

    const handleClose = (event: MouseEvent | TouchEvent | React.MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();

        if (
            event.target instanceof HTMLElement &&
            anchorEl.current?.contains(event.target)
        ) {
            return;
        }

        setOpen(false);
    };

    return (
        <>
            {buttonLabel && (
                <Button
                    size="small"
                    ref={anchorEl}
                    {...buttonProps}
                    onClick={handleToggle}
                    endIcon={
                        icon ? icon : <MoreVert fontSize={buttonProps?.size} />
                    }
                >
                    {buttonLabel}
                </Button>
            )}
            {!buttonLabel && (
                <IconButton
                    size="small"
                    ref={anchorEl}
                    {...iconButtonProps}
                    onClick={handleToggle}
                >
                    {icon ? icon : <MoreVert fontSize={buttonProps?.size} />}
                </IconButton>
            )}
            <Popper
                open={open}
                anchorEl={anchorEl.current}
                transition
                placement={placement}
                sx={theme => ({ zIndex: theme.zIndex.modal })}
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom'
                                    ? 'center top'
                                    : 'center bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                    sx={{ minWidth: 180 }}
                                    dense
                                    disablePadding={disableMenuListPadding}
                                >
                                    {header && (
                                        <Box
                                            component="li"
                                            sx={theme => ({
                                                backgroundColor:
                                                    theme.elevation[1],
                                                display: 'block',
                                                paddingInline: 2,
                                                paddingBlock: 1,
                                                borderBottom: `1px solid ${theme.border.light}`,
                                            })}
                                        >
                                            {header}
                                        </Box>
                                    )}

                                    {items.map(
                                        ({
                                            label,
                                            secondary,
                                            disabled,
                                            key,
                                            icon,
                                            onClick,
                                            hasDivider = false,
                                        }) => (
                                            <MenuItem
                                                divider={hasDivider}
                                                key={key}
                                                onClick={e => {
                                                    onClick?.(e);
                                                    handleClose(e);
                                                }}
                                                disabled={disabled}
                                            >
                                                {!selected &&
                                                    selected === key && (
                                                        <ListItemIcon>
                                                            <Done />
                                                        </ListItemIcon>
                                                    )}
                                                {icon && (
                                                    <ListItemIcon>
                                                        {icon}
                                                    </ListItemIcon>
                                                )}
                                                <ListItemText
                                                    primary={label}
                                                    primaryTypographyProps={{
                                                        variant: 'body2',
                                                    }}
                                                    secondary={secondary}
                                                />
                                                {selected &&
                                                    selected === key && (
                                                        <ListItemIcon>
                                                            <Done />
                                                        </ListItemIcon>
                                                    )}
                                            </MenuItem>
                                        )
                                    )}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
};
