import { useQuery } from '@tanstack/react-query';
import { orderBy } from 'lodash';
import { Annotation } from '../../types/annotation';
import { getAnnotations } from '../../api-client/annotations.ts';
import { DateRange } from '../DateRangePicker/reducer.tsx';
import { isAnnotationWithinInterval } from './is-annotation-within-interval.ts';
import { useActiveWorkspaceId } from '../../providers/useActiveWorkspaceId.ts';

export function useAnnotations(period: DateRange) {
    const workspaceId = useActiveWorkspaceId();

    return useQuery<Annotation[]>({
        queryKey: [workspaceId, 'annotations'],
        queryFn: async () => await getAnnotations(workspaceId),
        select: data =>
            orderBy(
                data?.filter(annotation =>
                    isAnnotationWithinInterval(annotation, period)
                ),
                ['dateStart', 'dateEnd', 'modifiedAt', 'createdAt'],
                ['asc', 'asc', 'desc', 'desc']
            ),
    });
}
