import create, {
    GetState,
    SetState,
    StateCreator,
    StoreApi,
    Subscribe,
} from 'zustand';

type NestedObject = {
    [T: string]: object;
};
export function compose<
    StoreState extends NestedObject,
    ComposeKey extends keyof StoreState,
    ComposeState extends object = StoreState[ComposeKey]
>(
    key: ComposeKey,
    store: StateCreator<ComposeState>
): (
    storeSet: SetState<StoreState>,
    storeGet: GetState<StoreState>,
    storeApi: StoreApi<StoreState>
) => ComposeState {
    return (rootStoreSet: any, rootStoreGet: any, rootStoreApi: any) => {
        const setState: SetState<ComposeState> = (
            updates: any,
            replace?: boolean
        ) => {
            rootStoreSet((liftedState: StoreState) => {
                if (typeof updates === 'function') {
                    updates = updates(liftedState[key]);
                }
                if (!replace) {
                    updates = { ...liftedState[key], ...updates };
                }
                return {
                    ...liftedState,
                    [key]: updates,
                };
            }, replace);
        };
        const getState: GetState<ComposeState> = () => {
            return rootStoreGet()[key];
        };
        const subscribe = ((listener: any, selector: any, equalityFn?: any) => {
            if (selector) {
                return rootStoreApi.subscribe(
                    listener,
                    (state: any) => selector(state[key]),
                    equalityFn
                );
            } else {
                return rootStoreApi.subscribe(
                    listener,
                    (state: any) => state[key]
                );
            }
        }) as Subscribe<ComposeState>;
        const destroy = () => {};
        const api = { getState, setState, subscribe, destroy };
        return store(setState, getState, api);
    };
}

export const createStore: typeof create = (store: any) => create(store);
