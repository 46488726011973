import { Avatar, Box, Stack, Typography } from '@mui/material';
import {
    IntegrationType,
    SupportedIntegrations,
} from '../../../types/workspace-management';
import { ReactNode } from 'react';
import { EnableButton } from '../../../components/Button/EnableButton';
import { LoadingButtonProps } from '@mui/lab';

interface IntegrationInfoProps {
    integrationType: IntegrationType;
    enabled: boolean;
    headerContent?: ReactNode;
    description?: ReactNode;
    enableButtonProps?: LoadingButtonProps;
}

export const IntegrationInfo = ({
    integrationType,
    enabled,
    headerContent,
    description,
    enableButtonProps,
}: IntegrationInfoProps) => {
    const integration = SupportedIntegrations[integrationType];
    return (
        <Stack my={2} gap={2}>
            <Stack
                direction="row"
                gap={2}
                alignItems="center"
                justifyContent="space-between"
            >
                <Stack direction="row" gap={2} alignItems="center">
                    <Box py={1} sx={{ height: 40, width: 40 }}>
                        <Avatar
                            variant="square"
                            sx={{ height: 'auto', width: 'auto' }}
                            src={integration.svg}
                        />
                    </Box>
                    <Stack py={1}>
                        <Typography variant="h6">
                            {integration.label}
                        </Typography>

                        <Typography
                            textAlign="left"
                            width="auto"
                            variant="caption"
                        >
                            {enabled ? 'Enabled' : 'Not enabled'}
                        </Typography>
                    </Stack>
                </Stack>

                {!enabled && enableButtonProps && (
                    <EnableButton {...enableButtonProps} />
                )}
                {enabled && headerContent}
            </Stack>
            {description}
        </Stack>
    );
};
