import { captureException } from '@sentry/react';
import {
    MutationFunction,
    QueryKey,
    useMutation,
    useQueryClient,
} from '@tanstack/react-query';
import { Maybe } from 'purify-ts';

type OptimisticUpdateOptions = {
    invalidateOnSettled?: boolean;
};

export const useOptimisticUpdate = <
    TItem extends { id: string },
    TResponse = void
>(
    mutationFn: MutationFunction<TResponse, TItem>,
    queryKey: QueryKey,
    { invalidateOnSettled }: OptimisticUpdateOptions = {}
) => {
    const queryClient = useQueryClient();

    const mutation = useMutation(mutationFn, {
        mutationKey: queryKey,
        onMutate: async (newItem: TItem) => {
            await queryClient.cancelQueries(queryKey);
            const previousValue = queryClient.getQueryData<TItem[]>(queryKey);

            queryClient.setQueryData<TItem[]>(queryKey, old =>
                Maybe.fromNullable(old)
                    .map(value =>
                        value.filter(x => x.id !== newItem.id).concat(newItem)
                    )
                    .orDefault([newItem])
            );

            return { previousValue };
        },
        onError: (err, variables, context) => {
            captureException(err);
            if (context?.previousValue) {
                queryClient.setQueryData(queryKey, context.previousValue);
            }
        },
        onSettled: () => {
            if (invalidateOnSettled) {
                queryClient.invalidateQueries(queryKey);
            }
        },
    });

    const findItem = (id: string | undefined) => {
        return queryClient
            .getQueryData<TItem[]>(queryKey)
            ?.find(x => x.id === id);
    };

    return {
        ...mutation,
        create: (item: TItem) => mutation.mutateAsync(item),
        update: (id: string | undefined, updateFn: (item: TItem) => TItem) => {
            return Maybe.fromNullable(findItem(id))
                .map(updateFn)
                .map(mutation.mutateAsync)
                .toEither(
                    new Error(
                        `Mutation attempted on non existent item with id '${id}'`
                    )
                )
                .unsafeCoerce();
        },
    };
};

export const useOptimisticRemove = <TItem extends { id: string }>(
    mutationFn: MutationFunction<void, string>,
    queryKey: QueryKey
) => {
    const queryClient = useQueryClient();

    const mutation = useMutation(mutationFn, {
        onMutate: async (id: string) => {
            await queryClient.cancelQueries(queryKey);
            const previousValue = queryClient.getQueryData<TItem[]>(queryKey);

            queryClient.setQueryData<TItem[]>(queryKey, items =>
                Maybe.fromNullable(items)
                    .map(items => items.filter(x => x.id !== id))
                    .orDefault([])
            );

            return { previousValue };
        },
        onError: (err, variables, context) => {
            if (context?.previousValue) {
                queryClient.setQueryData(queryKey, context.previousValue);
            }
        },
    });

    return {
        ...mutation,
        remove: mutation.mutateAsync,
    };
};
