import {
    Breakpoint,
    Dialog as MuiDialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
    styled,
} from '@mui/material';
import { Close } from '@mui/icons-material';

export const CloseButton = styled(IconButton)(({ theme }) => ({
    position: 'absolute',
    right: 8,
    top: 8,
    color: theme.palette.grey[500],
}));

export const LegacyDialog = ({
    open,
    handleClose,
    error,
    title,
    subtitle,
    content,
    maxWidth = 'sm',
}: {
    open: boolean;
    title: string;
    error?: string | JSX.Element | false;
    subtitle?: string | JSX.Element;
    content: JSX.Element;
    handleClose: () => void;
    maxWidth?: Breakpoint;
}) => {
    return (
        <MuiDialog
            fullWidth
            maxWidth={maxWidth}
            open={open}
            onClose={handleClose}
        >
            <DialogTitle fontSize={16}>
                {title}
                <CloseButton aria-label="close" onClick={handleClose}>
                    <Close />
                </CloseButton>
            </DialogTitle>
            <DialogContent>
                <Stack>
                    {subtitle}

                    {error && error}

                    {content}
                </Stack>
            </DialogContent>
        </MuiDialog>
    );
};
