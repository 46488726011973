import { useContext } from 'react';
import { Context, FilterBuilderContextProps } from './FilterBuilderContext.tsx';

export const useFilterBuilderContext = (): FilterBuilderContextProps => {
    const context = useContext(Context);
    if (!context) {
        throw new Error(
            'useFilterBuilderContext can only be used within a FilterBuilderContext'
        );
    }

    return context;
};
