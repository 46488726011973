import { Navigate, useParams } from 'react-router-dom';
import { useInitialData } from '../../hooks/use-initial-data';
import { useLastWorkspaceId } from '../../hooks/use-last-workspace-id';
import { NotFound } from '../../pages/NotFound/NotFound.tsx';

export const NavigateWithWorkspaceId = ({ to }: { to: string }) => {
    const { workspaceId } = useParams<{ workspaceId: string | undefined }>();
    const lastWorkspaceId = useLastWorkspaceId();

    const { data, isLoading } = useInitialData();

    if (isLoading) {
        return null;
    }

    if (data?.onboardingRequired) {
        return <Navigate to="/welcome" />;
    }

    const workspaces = data?.workspaces || [];
    if (workspaces.length === 0) {
        return <Navigate to="/info/no-workspaces" />;
    }

    const firstWorkspace = workspaceId
        ? workspaces.find(w => w.id === workspaceId)
        : (lastWorkspaceId && workspaces.find(w => w.id === lastWorkspaceId)) ||
          workspaces.find(x => x !== undefined);

    if (!firstWorkspace) {
        return <NotFound />;
    }

    return <Navigate to={`/workspaces/${firstWorkspace.id}/${to}`} />;
};
