import {
    DataModelField,
    isDimension,
    isMetric,
    isNonGroupedDimension,
    isStringFormat,
    shouldCastToFloat,
} from '../types/datamodel/schema.ts';
import { match } from 'ts-pattern';
import { Maybe } from 'purify-ts';
import { sqlForMetric } from './metrics-sql.ts';
import { Filter } from './filters.ts';

export type SqlContext = {
    isProjection?: boolean;
    intermediateTable?: boolean;
    percentRange?: '1.0' | '100.0';
    pivotFilter?: Filter;
    sourcePrefix?: string;
};

export const fieldSql = (
    f: DataModelField,
    context: SqlContext
): string | undefined =>
    Maybe.fromNullable(
        match(f)
            .when(isDimension, f => sqlForDimension(f, context))
            .when(isMetric, m => sqlForMetric(m, context))
            .otherwise(() => undefined)
    )
        .map(cast(f))
        .map(sql => (context.isProjection ? `${sql} as ${f.id}` : sql))
        .extract();

const cast = (field: DataModelField) => (sql: string) =>
    match(field)
        .when(shouldCastToFloat, () => `${sql}::double`)
        .otherwise(() => sql);

const sqlForDimension = (dimension: DataModelField, context: SqlContext = {}) =>
    match(dimension)
        .when(
            field => field.format === 'date' && !context.intermediateTable,
            () => `strftime(${dimension.id}, '%x')`
        )
        .when(isNonGroupedDimension, f => sqlForNonGroupedDimension(f.id))
        .otherwise(() =>
            isStringFormat(dimension.format)
                ? `ifnull(${dimension.id}, '')`
                : dimension.id
        );

function sqlForNonGroupedDimension(dimension: string) {
    return `any_value(${dimension})`;
}
