import { DataSource, DataSourceState } from './dataSource.ts';
import { useLocale } from '../../providers/LocaleProvider.hooks.ts';
import { useEffect, useState } from 'react';
import { match } from 'ts-pattern';
import { formatNumber } from '../../utils/number-format.ts';

type TotalRowCountProps = { dataSource: DataSource };
export const TotalRowCount = ({ dataSource }: TotalRowCountProps) => {
    const locale = useLocale();
    const [state, setState] = useState<DataSourceState>({ state: 'loading' });
    useEffect(() => {
        const handler = (event: Event) =>
            setState((event as CustomEvent<DataSourceState>).detail);

        dataSource.addEventListener('updated', handler);

        return () => dataSource.removeEventListener('updated', handler);
    }, [dataSource, setState]);

    return (
        <div className="ag-status-name-value">
            <span className="component">Total rows:&nbsp;</span>
            <span className="ag-status-name-value-value">
                {match(state)
                    .with({ state: 'success' }, ({ totalCount }) =>
                        formatNumber(totalCount, 'number', locale)
                    )
                    .with({ state: 'loading' }, () => '...')
                    .with({ state: 'error' }, () => 'N/A')
                    .exhaustive()}
            </span>
        </div>
    );
};
