import {
    IntegrationType,
    isValidIntegration,
    SupportedIntegrations,
} from '../../../types/workspace-management';
import { GoogleAnalyticsIntegrationPage } from './GoogleAnalytics/GoogleAnalyticsIntegration.tsx';
import { DeployteqIntegration } from './Deployteq/DeployteqIntegration.tsx';
import { CopernicaIntegration } from './Copernica/CopernicaIntegration.tsx';
import { Box, Button, Stack } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { CustomSetupIntegration } from './CustomSetupIntegration';
import { KlaviyoIntegration } from './Klaviyo/KlaviyoIntegration.tsx';
import { SalesforceIntegration } from './Salesforce/SalesforceIntegration.tsx';
import React from 'react';
import { ChevronLeft } from '@mui/icons-material';
import { useActiveWorkspaceId } from '../../../providers/useActiveWorkspaceId.ts';

const page = (type: IntegrationType) => {
    if (SupportedIntegrations[type].custom) {
        return <CustomSetupIntegration integrationType={type} />;
    }

    switch (type) {
        case 'google_analytics':
            return <GoogleAnalyticsIntegrationPage />;
        case 'deployteq':
            return <DeployteqIntegration />;
        case 'copernica':
            return <CopernicaIntegration />;
        case 'klaviyo':
            return <KlaviyoIntegration />;
        case 'salesforce':
            return <SalesforceIntegration />;
    }
};

export const IntegrationPage = () => {
    const { integrationType } = useParams();

    if (!integrationType) {
        return null;
    }

    const isValidIntegrationId = isValidIntegration(integrationType);
    if (!isValidIntegrationId) {
        return <>Handle invalid integrationId</>;
    }

    return (
        <Stack spacing={2}>
            <BackButton />
            {page(integrationType)}
        </Stack>
    );
};

const BackButton = () => {
    const workspaceId = useActiveWorkspaceId();

    return (
        <Box>
            <Button
                component={Link}
                startIcon={<ChevronLeft />}
                to={`/workspaces/${workspaceId}/settings/integrations`}
                variant="text"
                sx={{ textTransform: 'none' }}
            >
                Integrations
            </Button>
        </Box>
    );
};
