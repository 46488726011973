import { deleteCampaignCollection, putCampaignCollection } from '../api-client';
import { useActiveWorkspace } from '../providers/WorkspaceProvider.hooks';
import { CampaignCollection } from '../types/collections';
import {
    useOptimisticRemove,
    useOptimisticUpdate,
} from './use-optimistic-update';

export const useCampaignCollectionMutation = () => {
    const { workspaceId } = useActiveWorkspace();

    const { create, update } = useOptimisticUpdate<CampaignCollection>(
        async (collection: CampaignCollection) =>
            putCampaignCollection(workspaceId, collection),
        ['campaign-collections', workspaceId]
    );

    const { remove } = useOptimisticRemove(
        async (id: string) => deleteCampaignCollection(workspaceId, id),
        ['campaign-collections', workspaceId]
    );

    return {
        create,
        update,
        remove,
    };
};
