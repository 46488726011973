import { Navigate, Params, useLocation, useParams } from 'react-router-dom';
import { InitialData } from '../types/user';
import { useActiveUser } from '../providers/CurrentUserProvider.hooks';
import { PropsWithChildren } from 'react';

export interface RouteCondition {
    condition: (
        currentUser: InitialData,
        routeParams: Readonly<Params<string>>
    ) => boolean;
    navigateTo?: string;
    priority?: number;
}

export function ProtectedRoute({
    conditions,
    children,
}: PropsWithChildren<{
    conditions: RouteCondition[];
}>) {
    const location = useLocation();
    const params = useParams();
    const currentUser = useActiveUser();

    const foundCondition = conditions
        .sort((a, b) => (b?.priority || 1) - (a?.priority || 1))
        .find(condition => !condition.condition(currentUser, params));

    if (foundCondition !== undefined) {
        return (
            <Navigate
                to={foundCondition.navigateTo || '/'}
                state={{ from: location }}
                replace={true}
            />
        );
    }

    return <>{children}</>;
}
