import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { getRustAPIUrl } from '../../../utils/api.ts';
import { membersKey } from './useMembers.tsx';
import { useNotify } from '../../../hooks/use-notify.tsx';
import { useActiveWorkspaceId } from '../../../providers/useActiveWorkspaceId.ts';

export const useRemoveMember = () => {
    const workspaceId = useActiveWorkspaceId();
    const queryClient = useQueryClient();
    const notify = useNotify();

    return useMutation({
        mutationFn: (memberId: string) =>
            axios.delete(
                `${getRustAPIUrl()}/workspaces/${workspaceId}/members`,
                { data: { member_id: memberId } }
            ),
        onSuccess: () => queryClient.invalidateQueries(membersKey(workspaceId)),
        onError: () => notify('Failed to remove workspace member', 'error'),
    });
};
