import { Grid, Typography } from '@mui/material';
import { WeeklySummarySettings } from './WeeklySummarySettings';

export function ReportingSettings() {
    return (
        <Grid container rowSpacing={4} columnSpacing={4}>
            <Grid item xs={4}>
                <Typography variant="h6">Reporting settings</Typography>
                <Typography variant="smallMediumBody" color="text.primaryLight">
                    We understand that effective communication is key to your
                    success. That's why we put you in control with our reporting
                    settings. Tailor your experience, receive updates where it
                    matters most, and stay on top of your game.
                </Typography>
            </Grid>
            <Grid item xs={8}>
                <WeeklySummarySettings />
            </Grid>
        </Grid>
    );
}
