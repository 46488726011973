import { Loader } from '../../components/Loader/Loader.tsx';
import { NavigateWithQuery } from '../../components/Navigate/NavigateWithQuery.tsx';
import { useDashboardSelector } from './useDashboardSelector.ts';
import { DashboardNotFound } from './DashboardNotFound.tsx';

export const DashboardLandingPage = () => {
    const { routeToDashboard, isLoading } = useDashboardSelector();

    if (isLoading) {
        return <Loader />;
    }

    return routeToDashboard ? (
        <NavigateWithQuery replace to={routeToDashboard} />
    ) : (
        <DashboardNotFound />
    );
};
