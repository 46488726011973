import '@ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import '@ag-grid-community/styles/ag-theme-balham.css';
import { useCallback, useRef } from 'react';
import { GridConfig } from './grid/utils/gridView';
import { CampaignCollection } from '../../types/collections';
import { Localization } from '../../types/datamodel/localization';
import { ExplorerGridLoader } from './ExplorerGridLoader';
import { ErrorPage } from '../ErrorPage/ErrorPage';
import { useDuckDataset } from '../../hooks/use-duck-dataset.ts';
import { useSubscribe } from '../../event-bus';
import { Grid, GridHandle } from './Grid.tsx';

interface ExplorerGridProps {
    collection?: CampaignCollection;
    gridConfig: GridConfig;
    autoSave: (view: GridConfig) => void;
    firstDayOfWeek: Day;
    topMarginOffset?: number;
    locale: Localization;
    availableRedoActions?: number;
}

export const ExplorerGrid = ({
    gridConfig,
    topMarginOffset = 109,
}: ExplorerGridProps) => {
    const gridHandle = useRef<GridHandle>(null);
    const datasetId = gridConfig.dataset || 'campaign_metrics_by_send_date';
    const datasetQuery = useDuckDataset(datasetId);

    useSubscribe(
        'explorer_reset',
        useCallback(
            ({ view }) => gridHandle.current?.applyConfig(view.config),
            []
        )
    );

    if (datasetQuery.error) {
        return (
            <ErrorPage
                title={'Yikes! Something went wrong fetching your data.'}
                subtitle={<p>Please try the following steps to mitigate:</p>}
            />
        );
    }

    if (datasetQuery.isLoading) {
        return <ExplorerGridLoader dataset={datasetId} />;
    }

    if (!datasetQuery.data) {
        return <></>;
    }

    return (
        <Grid
            containerStyle={{
                height: `calc(100vh - ${topMarginOffset}px)`,
            }}
            dataset={datasetQuery.data}
            ref={gridHandle}
        />
    );
};
