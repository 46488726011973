import { createContext, PropsWithChildren, useCallback, useMemo } from 'react';
import { DateRanges } from '../components/DateRangePicker/reducer.tsx';
import {
    deserializeDateRanges,
    serializeDateRanges,
} from '../components/DateRangePicker/serialize.ts';
import { defaultDateRanges } from '../hooks/use-date-range.ts';
import { recalculateDateRanges } from '../components/DateRangePicker/presetDefinitions.ts';
import { useSeinoStore } from '../store/seino-store.ts';

export type DateRangeContextProps<T> = {
    dateRanges?: DateRanges<T>;
    setDateRanges: (ranges: DateRanges<T>) => void;
    enabled: boolean;
};

export const DateRangeContext = createContext<
    DateRangeContextProps<Date> | undefined
>(undefined);

export const DateRangeProvider = ({
    children,
    dateRanges,
    setDateRanges,
    enabled,
}: PropsWithChildren<DateRangeContextProps<string>>) => {
    const { firstDayOfWeek } = useSeinoStore(store => store.views);
    const innerDateRanges = useMemo(
        () =>
            dateRanges
                ? recalculateDateRanges(
                      firstDayOfWeek,
                      deserializeDateRanges(dateRanges)
                  )
                : defaultDateRanges(),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [JSON.stringify(dateRanges)]
    );

    const innerSetDateRanges = useCallback(
        (dateRanges: DateRanges) =>
            setDateRanges(serializeDateRanges(dateRanges)),
        [setDateRanges]
    );

    const value = useMemo(
        () => ({
            dateRanges: innerDateRanges,
            setDateRanges: innerSetDateRanges,
            enabled,
        }),
        [innerDateRanges, innerSetDateRanges, enabled]
    );

    return (
        <DateRangeContext.Provider value={value}>
            {children}
        </DateRangeContext.Provider>
    );
};
