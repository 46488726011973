import DropdownButton from '../Button/DropdownButton.tsx';
import React from 'react';

interface SimpleSaveButtonProps {
    onSave: () => void;
    onReset: () => void;
    onDuplicate: () => void;
    canWrite: boolean;
    canUndo: boolean;
    isLoading: boolean;
    isShared: boolean;
}

export const SaveButton = ({
    isLoading,
    onSave,
    onDuplicate,
    canWrite,
    canUndo,
    isShared,
}: SimpleSaveButtonProps) => {
    const saveEveryone = {
        id: 'everyone',
        label: 'Save for everyone',
        onClick: onSave,
    };

    const saveNewView = {
        id: 'new_view',
        label: 'Save as new view',
        onClick: onDuplicate,
    };

    const saveSimple = {
        id: 'simple',
        label: 'Save',
        onClick: onSave,
    };

    const getOptions = () => {
        if (!isShared) {
            return [saveSimple];
        }

        return [...(canWrite ? [saveEveryone] : []), saveNewView];
    };

    return (
        <DropdownButton
            buttonProps={{
                disabled: !canUndo,
                loading: isLoading,
            }}
            options={getOptions()}
        />
    );
};
