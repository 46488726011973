import { Box, Button, lighten } from '@mui/material';
import {
    format,
    getDay,
    isFirstDayOfMonth,
    isLastDayOfMonth,
    isToday,
} from 'date-fns';
import { memo } from 'react';
import { DateRangePickerDispatch, PeriodType } from './reducer';
import { green, orange } from '@mui/material/colors';

const colors = {
    main: {
        dark: '#009DBB',
        light: lighten('#009DBB', 0.9),
    },
    compare: {
        dark: orange[600],
        light: orange[50],
    },
    mixed: {
        dark: green[500],
        light: green[50],
    },
};

interface CalendarDayProps {
    dispatch: DateRangePickerDispatch;
    date: Date;
    inMainRange: boolean;
    firstOfMainRange: boolean;
    lastOfMainRange: boolean;
    inCompareRange: boolean;
    firstOfCompareRange: boolean;
    lastOfCompareRange: boolean;
    currentPeriod: PeriodType;
    allowCompare: boolean;
    weekStartsOn: Day;
}

const CalendarDay = ({
    dispatch,
    date,
    weekStartsOn,
    inMainRange,
    firstOfMainRange,
    lastOfMainRange,
    inCompareRange,
    firstOfCompareRange,
    lastOfCompareRange,
    allowCompare,
    currentPeriod,
}: CalendarDayProps) => {
    const dayOfWeek = (7 + getDay(date) - weekStartsOn) % 7;

    const firstInWeek = dayOfWeek === 0 || isFirstDayOfMonth(date);
    const lastInWeek = dayOfWeek === 6 || isLastDayOfMonth(date);
    const handleClick = () => dispatch({ type: 'selectDate', date });

    const shouldRoundBorderLeft =
        firstInWeek ||
        (firstOfMainRange && firstOfCompareRange) ||
        (firstOfMainRange && !inCompareRange) ||
        (firstOfCompareRange && !inMainRange);

    const shouldRoundBorderRight =
        lastInWeek ||
        (lastOfMainRange && lastOfCompareRange) ||
        (lastOfMainRange && !inCompareRange) ||
        (lastOfCompareRange && !inMainRange);

    return (
        <Box
            sx={() => ({
                gridColumnStart: date.getDate() === 1 ? dayOfWeek + 1 : 'auto',
                background: getBackgroundColor(
                    inMainRange,
                    firstOfMainRange,
                    lastOfMainRange,
                    inCompareRange,
                    firstOfCompareRange,
                    lastOfCompareRange,
                    allowCompare
                ),
                ...(shouldRoundBorderLeft && roundBorderLeft),
                ...(shouldRoundBorderRight && roundBorderRight),
            })}
        >
            <Button
                data-testid={`calendar-date-${format(date, 'yyyy-MM-dd')}`}
                variant={
                    firstOfMainRange ||
                    lastOfMainRange ||
                    (allowCompare &&
                        (firstOfCompareRange || lastOfCompareRange))
                        ? 'contained'
                        : 'text'
                }
                disableElevation={true}
                onClick={handleClick}
                sx={theme => {
                    const backgroundColor =
                        inMainRange && inCompareRange && allowCompare
                            ? (currentPeriod === 'main' &&
                                  (firstOfMainRange || lastOfMainRange)) ||
                              (currentPeriod === 'compare' &&
                                  (firstOfCompareRange || lastOfCompareRange))
                                ? colors.mixed.dark
                                : colors.mixed.light
                            : inMainRange
                            ? currentPeriod === 'main'
                                ? colors.main.dark
                                : colors.main.light
                            : inCompareRange
                            ? currentPeriod === 'compare'
                                ? colors.compare.dark
                                : colors.compare.light
                            : theme.palette.background.paper;

                    return {
                        fontWeight: isToday(date) ? 700 : 400,
                        minWidth: 0,
                        fontSize: 14,
                        width: 32,
                        height: 32,
                        borderRadius: 32,
                        '&.MuiButton-contained': {
                            backgroundColor,
                            color:
                                backgroundColor === colors.main.dark ||
                                backgroundColor === colors.compare.dark ||
                                backgroundColor === colors.mixed.dark
                                    ? theme.palette.primary.contrastText
                                    : theme.palette.primary.main,
                        },
                    };
                }}
            >
                {date.getDate()}
            </Button>
        </Box>
    );
};

const getBackgroundColor = (
    inMainRange: boolean,
    firstOfMainRange: boolean,
    lastOfMainRange: boolean,
    inCompareRange: boolean,
    firstOfCompareRange: boolean,
    lastOfCompareRange: boolean,
    allowCompare: boolean
) => {
    if (allowCompare && inMainRange && inCompareRange) {
        if (firstOfCompareRange && lastOfCompareRange) {
            return colors.main.light;
        }

        if (firstOfMainRange) {
            if (lastOfCompareRange) {
                return `linear-gradient(90deg, ${colors.compare.light} 50%, ${colors.main.light} 50%);`;
            }
            return `linear-gradient(90deg, ${colors.compare.light} 50%, ${colors.mixed.light} 50%);`;
        }

        if (firstOfCompareRange) {
            if (lastOfMainRange) {
                return `linear-gradient(90deg, ${colors.main.light} 50%, ${colors.compare.light} 50%);`;
            }
            return `linear-gradient(90deg, ${colors.main.light} 50%, ${colors.mixed.light} 50%);`;
        }

        if (lastOfCompareRange) {
            return `linear-gradient(90deg, ${colors.mixed.light} 50%, ${colors.main.light} 50%);`;
        }

        if (lastOfMainRange) {
            return `linear-gradient(90deg, ${colors.mixed.light} 50%, ${colors.compare.light} 50%);`;
        }

        return colors.mixed.light;
    }

    if (inMainRange) {
        return colors.main.light;
    }

    if (allowCompare && inCompareRange) {
        return colors.compare.light;
    }

    return 'unset';
};

const roundBorderLeft = {
    borderTopLeftRadius: 32,
    borderBottomLeftRadius: 32,
};

const roundBorderRight = {
    borderTopRightRadius: 32,
    borderBottomRightRadius: 32,
};

export const GridSpacer = () => <Box sx={{ width: 32, height: 24 }}></Box>;

const Component = memo(CalendarDay);
export { Component as CalendarDay };
