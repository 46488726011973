export const listOfEsps = [
    'ActiveCampaign',
    'Bloomreach',
    'Braze',
    'Campaign Monitor',
    'Copernica',
    'Deployteq',
    'Emarsys',
    'Emma',
    'expertsender',
    'Flowmailer',
    'Hubspot',
    'Iterable',
    'Klaviyo',
    'Magnews',
    'Mailblue',
    'MailCampaigns',
    'Mailchimp',
    'Maileon',
    'Mailkit',
    'Mandrill',
    'Marketo',
    'Omnisend',
    'Oracle Eloqua',
    'Pardot',
    'Responsys',
    'Sailthru',
    'Salesforce Marketing Cloud',
    'Selligent',
    'Sendgrid',
    'Sharpspring',
    'Sparkpost',
    'Spotler',
    'Tripolis',
    'Webpower',
] as const;
