import { deleteDashboardConfig, updateDashboardConfig } from '../../api-client';
import {
    createDashboard,
    DashboardConfig,
    duplicate,
} from '../../types/dashboard';
import {
    useOptimisticRemove,
    useOptimisticUpdate,
} from '../../hooks/use-optimistic-update.ts';
import { useActiveWorkspace } from '../../providers/WorkspaceProvider.hooks.ts';
import { useActiveUser } from '../../providers/CurrentUserProvider.hooks.ts';
import { useDashboardFromRoute } from './useDashboardFromRoute.ts';
import { useNavigate } from 'react-router-dom';
import { ModifiedBy } from '../../types/user';
import { AxiosResponse } from 'axios';

export const useDashboardMutation = () => {
    const { selectedDashboardId, setSelectedDashboardId, setEditMode } =
        useDashboardFromRoute();
    const { workspaceId } = useActiveWorkspace();
    const { memberId, email, name } = useActiveUser();
    const navigate = useNavigate();
    const user = useActiveUser();
    const currentUser: ModifiedBy = {
        id: user.memberId,
        name: user.name,
        email: user.email,
    };

    const { create, update, isLoading, isError } = useOptimisticUpdate<
        DashboardConfig,
        AxiosResponse<DashboardConfig>
    >(
        async dashboard =>
            await updateDashboardConfig(workspaceId, dashboard, dashboard.id),
        ['dashboards', workspaceId]
    );

    const { remove } = useOptimisticRemove(
        async (id: string) => {
            await deleteDashboardConfig(workspaceId, id);

            setEditMode(false);
            if (id === selectedDashboardId) {
                navigate(`/workspaces/${workspaceId}/dashboard`);
            }
        },
        ['dashboards', workspaceId]
    );

    const duplicateDashboard = async (dashboard: DashboardConfig) => {
        const newDashboard = duplicate(dashboard, currentUser);

        await create(newDashboard);

        return Promise.resolve(newDashboard);
    };

    return {
        createDashboard: async (
            title: string | undefined = 'Untitled',
            initialDashboard?: DashboardConfig,
            editMode: boolean = true
        ) => {
            const dashboard = createDashboard(
                title,
                {
                    id: memberId,
                    name,
                    email,
                },
                initialDashboard
            );

            await create(dashboard);
            setSelectedDashboardId(dashboard.id);
            setEditMode(editMode);

            return dashboard;
        },
        isError,
        isLoading,
        remove,
        duplicateDashboard,
        updateDashboard: (id: string, config: DashboardConfig['config']) =>
            update(id, (dashboard: DashboardConfig) => ({
                ...dashboard,
                config,

                // Just for making optimistic update work properly
                modifiedAt: Date.now(),
                modifiedBy: currentUser,
                version: dashboard.version + 1,
            })),
    };
};
