import React from 'react';
import { Stack, Typography } from '@mui/material';
import { Heading2 } from '../Settings.styled.tsx';
import { WorkspaceList } from './WorkspaceList.tsx';
import { useActiveWorkspace } from '../../../providers/WorkspaceProvider.hooks.ts';

export const Agency = () => {
    const { workspace } = useActiveWorkspace();

    return (
        <Stack spacing={2}>
            <Heading2>
                {workspace.licenseHolder ?? 'Agency'} workspaces
            </Heading2>

            <Typography></Typography>
            <WorkspaceList />
        </Stack>
    );
};
