import React, { useCallback } from 'react';
import { Navigation } from './Navigation';
import { SnackbarMessage } from './SnackBarMessage';
import { useSeinoStore } from '../../store/seino-store';
import { useExplorerState } from '../../hooks/use-explorer-state';
import { GridConfig } from '../../components/ExplorerGrid/grid/utils/gridView';
import { useLocale } from '../../providers/LocaleProvider.hooks';
import { DemoBanner } from '../../components/Banner/DemoBanner';
import { DateRangeProvider } from '../../providers/DateRangeProvider';
import { DateRanges } from '../../components/DateRangePicker/reducer.tsx';
import { ExplorerDialog } from './ExplorerDialog.tsx';
import { ExplorerGrid } from '../../components/ExplorerGrid/ExplorerGrid.tsx';
import { AnnotationsDrawer } from '../../components/Annotations/AnnotationsDrawer.tsx';
import { useDrawer } from '../../hooks/use-drawer.ts';
import { useActiveWorkspaceId } from '../../providers/useActiveWorkspaceId.ts';

export const ExplorerView = () => {
    const workspaceId = useActiveWorkspaceId();
    const locale = useLocale();
    const { view: currentView, saveLocal } = useExplorerState();

    const { dashboardMessage, firstDayOfWeek } = useSeinoStore(
        store => store.views
    );

    const { currentDrawer } = useDrawer();

    const saveConfig = useCallback(
        (config: GridConfig) => {
            if (!workspaceId) {
                return;
            }

            saveLocal(currentView => ({ ...currentView, config }));
        },
        [workspaceId, saveLocal]
    );

    const setDateRanges = useCallback(
        (dateRanges: DateRanges<string>) => {
            saveLocal(currentView => ({
                ...currentView,
                config: {
                    ...currentView.config,
                    dateRanges,
                },
            }));
        },
        [saveLocal]
    );

    return (
        <DateRangeProvider
            dateRanges={currentView.config.dateRanges}
            setDateRanges={setDateRanges}
            enabled={true}
        >
            <DemoBanner />
            <Navigation />

            {currentDrawer === 'annotations' && <AnnotationsDrawer />}
            {currentView && (
                <ExplorerGrid
                    gridConfig={currentView.config}
                    autoSave={saveConfig}
                    firstDayOfWeek={firstDayOfWeek}
                    locale={locale}
                    key={currentView.id}
                />
            )}
            {dashboardMessage && <SnackbarMessage message={dashboardMessage} />}
            <ExplorerDialog />
        </DateRangeProvider>
    );
};
