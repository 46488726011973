import { FormControl, styled, TextField, Typography } from '@mui/material';

export const FormControlGrid = styled(FormControl)({
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    columnGap: '2rem',
    rowGap: '1rem',
});

export const BoldStyle = styled(Typography)({
    fontWeight: 'bold',
    variant: 'body2',
    alignSelf: 'center',
});

export const Input = styled(TextField)({
    background: '#fff',
    fieldset: {
        borderColor: '#ddd',
    },
});
