import { StyledTargetCard } from './TargetCard.styled.tsx';
import { Stack, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';

export function CardWithError({
    error,
    onRemove,
}: {
    error: string;
    onRemove?: () => void;
}) {
    const theme = useTheme();
    return (
        <StyledTargetCard onRemove={onRemove}>
            <Stack height="100%" justifyContent="center" alignItems="center">
                <Typography
                    variant="smallBoldBody"
                    color={theme.palette.error.main}
                >
                    {error}
                </Typography>
            </Stack>
        </StyledTargetCard>
    );
}
