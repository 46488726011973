import { useIsMutating, useQuery } from '@tanstack/react-query';
import { fetchIntegrations } from '../api-client';
import { Integration } from '../types/integration';

import { useActiveWorkspaceId } from '../providers/useActiveWorkspaceId.ts';

export const integrationsQueryKey = (workspaceId: string) => [
    'useIntegrations',
    workspaceId,
];

export const useIntegrations = (enabled = true) => {
    const workspaceId = useActiveWorkspaceId();

    return useQuery<Integration[]>(
        integrationsQueryKey(workspaceId),
        async () => {
            const response = await fetchIntegrations(workspaceId);

            if (response.data.data) {
                return response.data.data;
            }
        },
        {
            enabled:
                useIsMutating(['useIntegrations', workspaceId]) === 0 &&
                enabled,
        }
    );
};
