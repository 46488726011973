import { SetState, StateCreator } from 'zustand';

export interface DashboardStore {
    editMode: boolean;
    reorderMode: boolean;
    setEditMode: (value: boolean) => void;
    setReorderMode: (value: boolean) => void;
}

export const dashboardStore: StateCreator<DashboardStore> = (
    set: SetState<DashboardStore>
) => ({
    editMode: false,
    reorderMode: false,
    setEditMode: (value: boolean) => {
        set({ editMode: value });
        if (!value) {
            set({ reorderMode: false });
        }
    },
    setReorderMode: (value: boolean) => set({ reorderMode: value }),
});
