import { useState, useCallback, useEffect } from 'react';
import {
    DateField,
    DateFieldProps,
    DateValidationError,
} from '@mui/x-date-pickers';
import type { FieldChangeHandler } from '@mui/x-date-pickers/internals';
import { useLocale } from '../../providers/LocaleProvider.hooks.ts';

type DateFieldWrapperProps = Omit<
    DateFieldProps<Date>,
    'value' | 'onChange'
> & {
    value: Date;
    onChange: (d: Date) => void;
};

export const DateFieldWrapper = (props: DateFieldWrapperProps) => {
    const { value, onChange, ...other } = props;
    const { dateFormat } = useLocale();

    const [localValue, setLocalValue] = useState<Date | null>(value);

    // Reset local value when the controlled value changes
    useEffect(() => {
        setLocalValue(value);
    }, [setLocalValue, value]);

    const handleChange: FieldChangeHandler<Date | null, DateValidationError> =
        useCallback(
            (date, { validationError }) => {
                setLocalValue(date);
                if (!date || validationError) {
                    return;
                }

                onChange(date);
            },
            [onChange]
        );

    return (
        <DateField
            format={dateFormat}
            variant="standard"
            sx={{
                width: 80,
                flex: 1,
                '& .MuiInput-input': { fontSize: '12px' },
                '& .MuiInputLabel-root': { fontSize: '12px' },
            }}
            value={localValue}
            onChange={handleChange}
            minDate={new Date('2000-01-01')}
            {...other}
        />
    );
};
