import { useStoredDashboard } from './useStoredDashboard.ts';
import { useParams } from 'react-router-dom';
import { useDashboards } from './use-dashboards.ts';

import { useActiveWorkspaceId } from '../../providers/useActiveWorkspaceId.ts';

export function useDashboardSelector() {
    const workspaceId = useActiveWorkspaceId();
    const { storedDashboardId } = useStoredDashboard(workspaceId);
    const { dashboardId: dashboardIdFromUrl } = useParams<{
        dashboardId: string | undefined;
    }>();

    const { data, isLoading } = useDashboards();
    const dashboardFromUrl = data?.find(({ id }) => dashboardIdFromUrl === id);
    const selectedDashboard =
        dashboardFromUrl ||
        data?.find(({ id }) => id === storedDashboardId) ||
        data?.[0];

    return {
        isLoading,
        selectedDashboard,
        dashboardId: selectedDashboard?.id,
        routeToDashboard: selectedDashboard
            ? `/workspaces/${workspaceId}/dashboard/${selectedDashboard.id}`
            : undefined,
    };
}
