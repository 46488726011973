import { ValueGetterParams } from '@ag-grid-community/core';
import { months, weekdays } from '../../../../const/date.ts';

const monthMap = Object.fromEntries(months.map((d, i) => [d, i]));

export const monthGetter = (params: ValueGetterParams) => {
    const colId = params.column.getColId();
    if (!params?.data?.[colId]) return undefined;
    if (!months[params.data[colId]]) return params.data[colId];

    return months[params.data[colId]];
};

export const monthComparator = (a: any, b: any) =>
    a in monthMap && b in monthMap ? monthMap[a] - monthMap[b] : a - b;

const weekdayMap = Object.fromEntries(weekdays.map((d, i) => [d, i]));

export const weekdayGetter = (params: ValueGetterParams) => {
    const colId = params.column.getColId();
    if (typeof params?.data?.[colId] !== 'number') return undefined;
    if (!weekdays[params.data[colId]]) return params.data[colId];

    return weekdays[params.data[colId]];
};

export const weekdayComparator = (a: any, b: any) =>
    a in weekdayMap && b in weekdayMap ? weekdayMap[a] - weekdayMap[b] : a - b;
