import DropdownButton from '../Button/DropdownButton.tsx';
import React from 'react';

interface ResolveButtonProps {
    onReset: () => void;
    onSave: () => void;
    onDuplicate: () => void;
    canUndo: boolean;
}

export const ResolveButton = ({
    onReset,
    onDuplicate,
    canUndo,
}: ResolveButtonProps) => {
    const dropdownOptions = [
        {
            id: 'reset',
            label: 'Load new version',
            onClick: onReset,
        },
        ...(canUndo
            ? [
                  {
                      id: 'new',
                      label: 'Save as new version',
                      onClick: onDuplicate,
                  },
              ]
            : []),
    ];

    return <DropdownButton options={dropdownOptions} />;
};
