import {
    useMutation,
    UseMutationOptions,
    useQueryClient,
} from '@tanstack/react-query';
import axios from 'axios';
import { getRustAPIUrl, SimpleError } from '../../../../utils/api';
import { integrationsQueryKey } from '../../../../hooks/use-integrations.ts';

const connect = (workspaceId: string, token: string) => {
    return axios.post(
        `${getRustAPIUrl()}/workspaces/${workspaceId}/integrations/copernica`,
        { token }
    );
};

type Options = Pick<
    UseMutationOptions<
        unknown,
        Error | SimpleError,
        {
            workspaceId: string;
            integrationId: string;
            token: string;
        }
    >,
    'onSuccess' | 'onError'
>;
export const useConnectCopernica = (options?: Options) => {
    const queryClient = useQueryClient();
    return useMutation(
        ({ workspaceId, token }) => connect(workspaceId, token),
        {
            ...options,
            onSuccess: (data, variables, context) => {
                queryClient.invalidateQueries(
                    integrationsQueryKey(variables.workspaceId)
                );
                options?.onSuccess?.(data, variables, context);
            },
        }
    );
};
