import { useDatasetSchema } from '../../hooks/use-dataset-schema';
import { useDatasets } from '../../hooks/use-datasets';

export const DatasetSchemaPreloader = () => {
    const { data: datasets } = useDatasets();

    return (
        <>
            {datasets?.map(dataset => (
                <SchemaPreloadDataset dataset={dataset.id} key={dataset.id} />
            ))}
        </>
    );
};

const SchemaPreloadDataset = ({ dataset }: { dataset: string }) => {
    useDatasetSchema(dataset);

    return null;
};
