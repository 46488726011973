import { useState } from 'react';
import ConfirmDialog from '../../../components/ConfirmDialog/ConfirmAlert';
import { ActionButton } from '../../../components/Button/ActionButton';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { getRustAPIUrl } from '../../../utils/api.ts';
import { integrationsQueryKey } from '../../../hooks/use-integrations.ts';
import { useNavigate } from 'react-router-dom';
import { useActiveWorkspaceId } from '../../../providers/useActiveWorkspaceId.ts';

export const DisableIntegration = ({
    integrationId,
    name,
}: {
    integrationId: string;
    name: string;
}) => {
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const workspaceId = useActiveWorkspaceId();
    const queryClient = useQueryClient();
    const { mutateAsync, isLoading } = useMutation({
        mutationFn: (id: string) =>
            axios.delete(
                `${getRustAPIUrl()}/workspaces/${workspaceId}/integrations/${id}`
            ),
        onSuccess: () => {
            queryClient.invalidateQueries(integrationsQueryKey(workspaceId));
            navigate(`/workspaces/${workspaceId}/settings/integrations`);
        },
    });

    if (!workspaceId || !integrationId) {
        return <></>;
    }

    return (
        <>
            <ConfirmDialog
                title={`Remove ${name} integration`}
                content={`Are you sure you want to remove the ${name} integration from this workspace?`}
                continueText="Remove"
                isDangerousAction={true}
                isExecuting={isLoading}
                onCancel={() => setOpen(false)}
                onContinue={() => mutateAsync(integrationId)}
                open={open}
            />
            <ActionButton color="error" onClick={() => setOpen(true)}>
                Remove
            </ActionButton>
        </>
    );
};
