import { DataModelSchema } from '../types/datamodel/schema';
import { TableCompareQuery } from './table-compare';
import { sqlForMetrics } from './metrics-sql';
import { dateFormat } from '../utils/date-format';
import { sqlForFilters } from './filters-sql';
import { sqlForDimensions, sqlForDimensionsGroupBy } from './dimensions-sql';
import { indent } from './indent';
import { findField, includeSourceMetrics, isField } from './schema';
import _ from 'lodash';

export function sqlForTableCompareQuery(
    schema: DataModelSchema,
    sourceTable: string,
    inputQuery: TableCompareQuery
) {
    const fieldFinder = findField(schema);
    const metrics = _.chain(inputQuery.metrics)
        .map(findField(schema))
        .filter(isField)
        .flatMap(includeSourceMetrics(fieldFinder))
        .map(f => f.id)
        .uniq()
        .value();

    const dimensions = _.chain(inputQuery.dimensions)
        .map(findField(schema))
        .filter(isField)
        .map(f => f.id)
        .uniq()
        .value();

    const query = { ...inputQuery, metrics, dimensions };

    return `
WITH
  current_period AS (
${sqlForPeriod(schema, sourceTable, query, query.dateRange)}
  ),

  last_period AS (
${sqlForPeriod(schema, sourceTable, query, query.compareDateRange)}
  )

SELECT
${query.dimensions.map(d => `  ${d},`).join('\n')}
${query.metrics
    .map(
        m =>
            `  { current: current_period.${m}, last: last_period.${m} } AS ${m},`
    )
    .join('\n')}
FROM current_period
${
    query.dimensions.length > 0
        ? `LEFT JOIN last_period USING (${query.dimensions.join()})`
        : `CROSS JOIN last_period`
}
`;
}

function sqlForPeriod(
    schema: DataModelSchema,
    sourceTable: string,
    query: TableCompareQuery,
    period: Interval
) {
    const start = dateFormat(period.start);
    const end = dateFormat(period.end);
    return indent(
        `SELECT
${indent(sqlForDimensions(schema, query.dimensions), 2)}
${indent(sqlForMetrics(schema, query.metrics), 2)}
FROM ${sourceTable}
WHERE ${schema.dateField} BETWEEN '${start}' AND '${end}'
${indent(sqlForFilters(schema, query.filters), 2)}
${sqlForDimensionsGroupBy(schema, query.dimensions)}`,
        4
    );
}
