import {
    IconButton,
    Skeleton,
    Stack,
    styled,
    Tooltip,
    Typography,
} from '@mui/material';
import { Download, FileCopy } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { download } from '../../utils/download.ts';

type SnippetProps = {
    text: string;
    downloadFilename?: string;
    mask?: boolean;
    isLoading?: boolean;
};

export const Snippet = ({
    text,
    downloadFilename,
    mask = false,
    isLoading = false,
}: SnippetProps) => {
    const { enqueueSnackbar } = useSnackbar();

    if (isLoading) {
        return (
            <StyledStack>
                <Skeleton width="100%" height={36} />
            </StyledStack>
        );
    }

    return (
        <StyledStack
            justifyContent="space-between"
            alignItems="center"
            direction="row"
        >
            <Typography fontFamily="monospace" fontSize={14}>
                {mask ? '..................' : text}
            </Typography>

            <Stack direction="row">
                {downloadFilename && (
                    <Tooltip title="Download as file" placement="left">
                        <IconButton
                            onClick={() => download(text, downloadFilename)}
                        >
                            <Download fontSize="small" />
                        </IconButton>
                    </Tooltip>
                )}
                <Tooltip title="Copy to clipboard" placement="left">
                    <IconButton
                        onClick={() => {
                            navigator.clipboard.writeText(text);
                            enqueueSnackbar('Copied to clipboard', {
                                autoHideDuration: 2000,
                            });
                        }}
                    >
                        <FileCopy fontSize="small" />
                    </IconButton>
                </Tooltip>
            </Stack>
        </StyledStack>
    );
};

const StyledStack = styled(Stack)(({ theme }) =>
    theme.unstable_sx({
        background: theme.elevation[1],
        my: 0.5,
        py: 0.5,
        px: 1,
        borderRadius: 2,
    })
);
