import { Autocomplete, Box } from '@mui/material';
import { Input } from '../../../../components/Dashboard/Widgets/Config/WidgetConfigForm.styled';
import { countries } from '../../../../utils/countries';
import _, { keyBy } from 'lodash';
import { Market } from '../../../../components/Market/Market';

interface SelectMarketProps {
    value: string | null;
    onChange: (market: string | null) => void;
}

const countryOptions = _.chain(countries).sortBy(['groups', 'name']).value();
const countryIndex = keyBy(countries, 'value');

export const SelectMarket = ({ value, onChange }: SelectMarketProps) => {
    const selectedCountry = countryIndex[value || ''] || null;

    return (
        <Autocomplete
            multiple={false}
            freeSolo={false}
            size="small"
            fullWidth={true}
            value={selectedCountry}
            onChange={(_, country) => onChange(country?.value || null)}
            options={countryOptions}
            getOptionLabel={option => option.label}
            groupBy={c => c.groups?.[0] || 'Rest of world'}
            renderInput={params => (
                <Input
                    {...params}
                    variant="outlined"
                    fullWidth
                    label="Select a market"
                />
            )}
            renderOption={(props, option) => (
                <Box
                    component="li"
                    sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                    {...props}
                >
                    <Market market={option.value} />
                    {option.label}
                </Box>
            )}
        />
    );
};
