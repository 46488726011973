import { Stack, styled, Tooltip, Typography } from '@mui/material';
import { useDatasetSchema } from '../../../../../hooks/use-dataset-schema.ts';
import { BinaryFilter, isSetOperator } from '../../../../../queries/filters.ts';
import { findField } from '../../../../../queries/schema.ts';
import { isDimensionFormat } from '../../../../../types/datamodel/schema.ts';
import { getDimensionFormatter } from '../../../../../utils/dimension-format.ts';
import { conditions } from '../../Config/conditions.tsx';

interface ActiveFiltersProps {
    dataset: string;
    filters?: BinaryFilter[];
}

const isRelevantAndActiveFilter = ({
    member,
    values,
    operator,
}: BinaryFilter) =>
    member &&
    values &&
    (isSetOperator(operator) || (values.length > 0 && values[0] !== ''));

export const HighLowFilters = ({
    dataset,
    filters = [],
}: ActiveFiltersProps) => {
    const { data: schema } = useDatasetSchema(dataset);

    return (
        <Stack gap={0.3}>
            {filters
                .filter(isRelevantAndActiveFilter)
                .map((filter: BinaryFilter, index) => {
                    const dimension = findField(schema)(filter.member);
                    if (!dimension || !isDimensionFormat(dimension.format))
                        return null;

                    const values = filter.values.map(
                        getDimensionFormatter(dimension.format)
                    );

                    return (
                        <Tooltip
                            key={index}
                            disableHoverListener={values.length <= 1}
                            title={<OtherValues values={values} />}
                        >
                            <Typography fontSize={12} maxWidth={320} noWrap>
                                {dimension.shortName ?? dimension.name}{' '}
                                {(
                                    conditions?.[filter.operator]?.label ?? ''
                                ).toLowerCase()}{' '}
                                <JoinedValues values={values} />
                            </Typography>
                        </Tooltip>
                    );
                })}
        </Stack>
    );
};

const UnderlinedText = styled('span')(({ theme }) => ({
    fontSize: 12,
    borderBottom: `1px dashed ${theme.border.dark}`,
}));

const JoinedValues = ({ values }: { values: string[] }) => {
    if (values.length === 0) {
        return null;
    }

    if (values.length === 1) {
        return <span>{values[0]}</span>;
    }

    return <UnderlinedText>{joinValues(values)}</UnderlinedText>;
};

const OtherValues = ({ values }: { values: string[] }) => (
    <ul style={{ paddingInline: 12 }}>
        {values.map(value => (
            <li key={`filter-${value}`}>{value}</li>
        ))}
    </ul>
);

const joinValues = (values: string[]) =>
    [values.slice(0, -1).join(', '), values.slice(-1)]
        .filter(Boolean)
        .join(' or ');
