import {
    Alert,
    Box,
    Card,
    CircularProgress,
    Container,
    LinearProgress,
    Stack,
} from '@mui/material';
import { EngagementRow, useEngagementData } from '../../hooks/use-engagement';
import { EngagementWaffle } from './EngagementWaffle';
import { useContext, useState } from 'react';
import { uniqBy } from 'lodash';
import { DateSelector } from './DateSelector';
import { EngagementTable } from './EngagementTable';
import { SegmentSankey } from './SegmentSankey';
import { EngagementContext } from './EngagementContext';

import { useActiveWorkspaceId } from '../../providers/useActiveWorkspaceId.ts';

export const EngagementPage = () => {
    const workspaceId = useActiveWorkspaceId();
    const [segment, setSegment] = useState(1);
    const { data, isLoading, isError } = useEngagementData();

    if (isLoading) {
        return (
            <Box display="flex" justifyContent="center" mt={16} width="100%">
                <CircularProgress />
            </Box>
        );
    }

    if (isError || data === undefined || data.length === 0) {
        return (
            <Box display="flex" justifyContent="center" mt={16} width="100%">
                <Alert severity="error">
                    SEINō Engagement is not available for this workspace
                </Alert>
            </Box>
        );
    }

    return (
        <EngagementContext.Provider value={{ segment, setSegment }}>
            {isLoading && <LinearProgress />}
            <Container maxWidth="xl">
                <EngagementContent data={data} key={workspaceId} />
            </Container>
        </EngagementContext.Provider>
    );
};

const height = {
    lg: '200px',
    md: '160px',
    xs: '120px',
};

type EngagementContentProps = { data: EngagementRow[] };

const EngagementContent = ({ data }: EngagementContentProps) => {
    const { segment } = useContext(EngagementContext);

    const snapshotDates = uniqBy(
        data.map(row => row.date),
        x => +x
    );

    const [date, setDate] = useState(snapshotDates[0]);

    const currentData = data.filter(row => +row.date === +date);
    const segmentRow = currentData.find(x => x.segment_id === segment);

    return (
        <Stack spacing={1} mt={1}>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
            >
                <DateSelector
                    snapshotDates={snapshotDates}
                    value={date}
                    onChange={setDate}
                />
            </Stack>

            <Stack direction={{ md: 'row', xs: 'column' }} spacing={1}>
                <Card
                    sx={{
                        p: 2,
                        overflow: 'visible',
                        width: { xs: undefined, md: '40%' },
                    }}
                >
                    <Box sx={{ height }}>
                        <EngagementWaffle data={currentData} />
                    </Box>
                </Card>
                <Card
                    sx={{
                        p: 2,
                        flexGrow: 1,
                        width: { xs: undefined, md: '60%' },
                        overflow: 'visible',
                    }}
                >
                    {segmentRow && (
                        <Box sx={{ height }}>
                            <SegmentSankey data={segmentRow} />
                        </Box>
                    )}
                </Card>
            </Stack>

            <Card sx={{ p: 2 }}>
                <Stack spacing={1}>
                    <EngagementTable data={currentData} />
                </Stack>
            </Card>

            <Box>&nbsp;</Box>
        </Stack>
    );
};
