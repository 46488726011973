import { match, P } from 'ts-pattern';
import escapeString from 'escape-sql-string';
import { Filter, isBinaryFilter, isSetOperator } from './filters';
import { DataModelSchema } from '../types/datamodel/schema';
import { fieldExists } from './schema';

export function sqlForFilters(
    schema: DataModelSchema,
    filters: Filter[],
    indent: string = ''
): string {
    return filters
        .filter(f => {
            if (!isBinaryFilter(f)) {
                return true;
            }

            if (!f.aggregate && !fieldExists(schema)(f.member)) {
                return false;
            }

            return f.values.length > 0 || isSetOperator(f.operator);
        })
        .map(f => `${indent}AND ${sqlForFilter(f)}`)
        .join('\n');
}

export function sqlForFilter(filter: Filter): string {
    return match(filter)
        .with(
            { member: P.string, operator: 'notSet' },
            ({ member }) => `${member} IS NULL OR ${member} = ''`
        )
        .with(
            { member: P.string, operator: 'set' },
            ({ member }) => `${member} IS NOT NULL AND ${member} != ''`
        )
        .with(
            { member: P.string, operator: 'inRange' },
            ({ member, values }) =>
                `${member} between ${escapeValue(values[0])} and ${escapeValue(
                    values[1]
                )}`
        )
        .with(
            {
                member: P.string,
                operator: P.union(
                    'contains',
                    'notContains',
                    'equals',
                    'notEquals',
                    'startsWith',
                    'endsWith',
                    'gt',
                    'gte',
                    'lt',
                    'lte'
                ),
                values: P.array(
                    P.union(P.string, P.number, P.boolean, P.nullish)
                ),
            },
            ({ member, operator, values }) =>
                `(${values
                    .map(value => sqlForOperator(member, operator, value))
                    .join(
                        ['notEquals', 'notContains'].includes(operator)
                            ? ' AND '
                            : ' OR '
                    )})`
        )
        .with({ and: P.array() }, ({ and }) => joinFilters(and, 'and'))
        .with({ or: P.array() }, ({ or }) => joinFilters(or, 'or'))
        .otherwise(() => {
            throw new Error(`Unsupported filter: ${JSON.stringify(filter)}`);
        });
}

function joinFilters(filters: Filter[], keyword: 'or' | 'and') {
    return filters
        .map(sqlForFilter)
        .map(f => (filters.length > 1 ? `(${f})` : f))
        .join(` ${keyword} `);
}

function sqlForOperator(
    member: string,
    operator: string,
    value: string | number | null
): string {
    if (value === null) {
        return match(operator)
            .with('equals', () => `${member} is null`)
            .with('notEquals', () => `${member} is not null`)
            .otherwise(op => {
                throw new Error(`Operator ${op} not supported for null`);
            });
    }

    const escaped = escapeValue(value);
    const memberCasted =
        typeof value === 'string' ? `${member}::string` : member;

    return match(operator)
        .with('equals', () => `${memberCasted} = ${escaped}`)
        .with('notEquals', () => `${memberCasted} != ${escaped}`)
        .with(
            'contains',
            () =>
                `contains(strip_accents(lower(${memberCasted})), strip_accents(lower(${escaped})))`
        )
        .with(
            'notContains',
            () =>
                `not contains(strip_accents(lower(${memberCasted})), strip_accents(lower(${escaped})))`
        )
        .with(
            'startsWith',
            () =>
                `starts_with(strip_accents(lower(${memberCasted})), strip_accents(lower(${escaped})))`
        )
        .with(
            'endsWith',
            () =>
                `suffix(strip_accents(lower(${memberCasted})), strip_accents(lower(${escaped})))`
        )
        .with('gt', () => `${member} > ${value}`)
        .with(`gte`, () => `${member} >= ${value}`)
        .with('lt', () => `${member} < ${value}`)
        .with('lte', () => `${member} <= ${value}`)
        .otherwise(op => {
            throw new Error(`Operator ${op} not supported`);
        });
}

const escapeValue = (value: null | number | string) =>
    value === null
        ? 'null'
        : typeof value === 'string'
        ? `${escapeString(value)}`
        : value;
