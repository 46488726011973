import { useLocale } from '../../../../providers/LocaleProvider.hooks';
import { BinaryFilter } from '../../../../queries/filters';
import { useHighLowQuery } from '../../../../queries/high-low-hook';
import { dateFormat } from '../../../../utils/date-format';

interface HighLowData {
    data: {
        high: {
            value: number;
            label: string;
        };
        low: {
            value: number;
            label: string;
        };
    };
    isLoading: boolean;
    error: any;
}

export const useHighLowData = (
    dataset: string,
    dateRange: Interval,
    metric: string,
    dimension: string,
    filters: BinaryFilter[],
    enabled: boolean
): HighLowData => {
    const { months } = useLocale();
    const baseQuery = {
        metric,
        dimension,
        filters: filters,
    };

    const { data, isLoading, error } = useHighLowQuery<any[]>(
        dataset,
        {
            ...baseQuery,
            dateRange,
        },
        { enabled }
    );

    // eslint-disable-next-line prefer-const
    let [highValue, highLabel = ''] = data?.[1]?.valueOf().toArray() || [
        undefined,
        '',
    ];
    // eslint-disable-next-line prefer-const
    let [lowValue, lowLabel = ''] = data?.[0]?.valueOf().toArray() || [
        undefined,
        '',
    ];

    if (highLabel instanceof Date) {
        highLabel = dateFormat(highLabel);
    }

    if (lowLabel instanceof Date) {
        lowLabel = dateFormat(lowLabel);
    }

    if (dimension.includes('month')) {
        highLabel = months[highLabel];
        lowLabel = months[lowLabel];
    }

    if (dimension === 'market') {
        highLabel = getMarketLabel(highLabel);
        lowLabel = getMarketLabel(lowLabel);
    }

    return {
        data: {
            high: {
                value: highValue,
                label: highLabel,
            },
            low: {
                value: lowValue,
                label: lowLabel,
            },
        },
        isLoading,
        error,
    };
};

const getMarketLabel = (market: string) => {
    return (
        <span>
            <img
                alt={market}
                src={`/img/markets/${market === 'UK' ? 'GB' : market}.png`}
                style={{ verticalAlign: 'text-bottom', marginRight: '8px' }}
                onError={event => (event.currentTarget.style.display = 'none')}
            />
            {market}
        </span>
    );
};
