import { WidgetConfig } from '../../../../types/dashboard';
import { useDashboardDataset } from '../../../../hooks/use-dashboard-dataset.ts';
import { useMemo, useState } from 'react';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { Widget } from '../Widget';
import { PerformanceCardGrid } from './CampaignPerformanceWidget.styled';
import { PerformanceCard } from './Chart/PerformanceCard';
import { Box } from '@mui/material';
import { CompareChart } from './Chart/CompareChart';
import { useCampaignPerformanceData } from './use-campaign-performance-data';
import { captureException } from '@sentry/react';
import { useDateRange } from '../../../../hooks/use-date-range';
import { WidgetError } from '../WidgetError.tsx';

export const CampaignPerformanceWidget = (config: WidgetConfig) => {
    const { dateRanges } = useDateRange();
    const showCompare = dateRanges.compare.preset !== 'disabled';

    const { data: dataset } = useDashboardDataset(config.dataset);

    const [selectedMetric, setSelectedMetric] = useState<string | undefined>(
        undefined
    );

    const [animationParent] = useAutoAnimate<HTMLDivElement>();

    const { isLoading, metricData, error } = useCampaignPerformanceData({
        dataset: config.dataset,
        dateRange: dateRanges.main,
        compareDateRange: dateRanges.compare,
        chartGranularity: 'auto',
        metrics: config.metrics,
        filters: config.filters || [],
    });

    const metricCards = useMemo(
        () =>
            config.metrics
                .filter(m => Boolean(dataset.metrics[m]))
                .map(metric => (
                    <Box
                        key={metric}
                        onClick={() =>
                            metric === selectedMetric
                                ? setSelectedMetric(undefined)
                                : setSelectedMetric(metric)
                        }
                    >
                        <PerformanceCard
                            metric={dataset?.metrics?.[metric]}
                            data={metricData?.[metric]}
                            isLoading={isLoading}
                            isSelected={metric === selectedMetric}
                            showCompare={showCompare}
                        />
                    </Box>
                )),
        [
            config.metrics,
            dataset?.metrics,
            metricData,
            isLoading,
            selectedMetric,
            showCompare,
        ]
    );

    if (error) {
        captureException(error);
        return (
            <Widget config={config}>
                <WidgetError />
            </Widget>
        );
    }

    return (
        <Widget config={config}>
            <PerformanceCardGrid ref={animationParent} minItemWidth={180}>
                {metricCards}
            </PerformanceCardGrid>
            {selectedMetric &&
                metricData?.[selectedMetric] &&
                dataset?.metrics?.[selectedMetric] && (
                    <Box sx={{ mt: 4 }}>
                        <CompareChart
                            data={metricData[selectedMetric].chartData}
                            metric={dataset.metrics[selectedMetric]}
                            showLastPeriod={showCompare}
                        />
                    </Box>
                )}
        </Widget>
    );
};
