import { Baseline, BaselineSet } from '../baselines';
import { ulid } from 'ulid';
import { KPIData } from '../../components/ExplorerGrid/grid/utils/gridView';
import { DataModelField } from '../datamodel/schema';

interface CampaignCollectionRule {
    condition: string;
    field: string;
    value: string;
}

export interface CampaignCollection {
    createdAt: string;
    lastUpdatedAt: string;
    name: string;
    id: string;
    rules: CampaignCollectionRule[];
    baselineSet: BaselineSet;
}

export const createCollection = (): CampaignCollection => ({
    name: 'New Campaign Collection',
    createdAt: new Date().toISOString(),
    lastUpdatedAt: new Date().toISOString(),
    id: ulid(),
    rules: [],
    baselineSet: {
        id: ulid(),
        targets: [],
    },
});

export const createBaselineTargetsFromKpiData = (
    kpiData: KPIData,
    campaignSchema: DataModelField[]
): Baseline[] => {
    const targets: Baseline[] = [];
    Object.keys(kpiData).forEach(kpi => {
        const field = campaignSchema.find(f => f.id === kpi);
        if (field) {
            targets.push({
                id: kpi,
                metric: field.name,
                value: kpiData[kpi],
                format: field.format,
                category: field.category,
            });
        }
    });
    return targets;
};
