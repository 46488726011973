import { Target } from '../../../types/targets';
import { usePrecedingPeriodData } from './usePrecedingPeriodData.ts';
import { useMemo } from 'react';
import {
    precedingPeriod,
    yearOverYear,
} from '../../../components/DateRangePicker/dateHelpers.ts';
import { CalculatorListItem } from './PrecedingPeriodList.tsx';
import {
    Preset,
    presetLabel,
} from '../../../components/DateRangePicker/presetDefinitions.ts';
import { DateRange } from '../../../components/DateRangePicker/reducer.tsx';
import { shortDateRangeFormat } from '../../../utils/date-format.ts';

const labelFormatter = (preset: Preset, dateRange: DateRange) => {
    return `${presetLabel(preset)} (${shortDateRangeFormat(
        dateRange.start,
        dateRange.end
    )})`;
};

export const useCalculatorListItemData = (target: Target) => {
    const { data: precedingPeriodData } = usePrecedingPeriodData(
        target,
        'preceding_period'
    );
    const { data: precedingYearData } = usePrecedingPeriodData(
        target,
        'year_over_year'
    );

    const items: CalculatorListItem[] = useMemo(
        () => [
            {
                id: 'preceding_period',
                value: precedingPeriodData?.[0]?.value,
                label: labelFormatter(
                    'preceding_period',
                    precedingPeriod({
                        start: target.dateStart,
                        end: target.dateEnd,
                    })
                ),
            },
            {
                id: 'year_over_year',
                value: precedingYearData?.[0]?.value,
                label: labelFormatter(
                    'year_over_year',
                    yearOverYear({
                        start: target.dateStart,
                        end: target.dateEnd,
                    })
                ),
            },
        ],
        [
            precedingPeriodData,
            precedingYearData,
            target.dateEnd,
            target.dateStart,
        ]
    );

    return items;
};
