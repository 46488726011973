import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { getRustAPIUrl } from '../../../utils/api.ts';
import { Invite } from './InviteUserDialog.tsx';
import { membersKey } from './useMembers.tsx';
import { useNotify } from '../../../hooks/use-notify.tsx';
import { useActiveWorkspaceId } from '../../../providers/useActiveWorkspaceId.ts';

export const useInviteUser = () => {
    const queryClient = useQueryClient();
    const workspaceId = useActiveWorkspaceId();
    const notify = useNotify();

    return useMutation({
        mutationFn: (data: Invite) =>
            axios.post(
                `${getRustAPIUrl()}/workspaces/${workspaceId}/members`,
                data
            ),
        onError: () => {
            notify('An error occurred while sending the invite.', 'error');
        },
        onSuccess: () =>
            Promise.all([
                queryClient.invalidateQueries(['fetch-initial-data']),
                queryClient.invalidateQueries(membersKey(workspaceId)),
            ]),
    });
};
