import { Stack } from '@mui/material';
import { Heading2 } from './Settings.styled.tsx';
import { PropsWithChildren } from 'react';

type TabProps = PropsWithChildren<{
    title: string;
}>;

export const SettingsTab = ({ title, children }: TabProps) => {
    return (
        <Stack gap={2}>
            <Heading2>{title}</Heading2>
            {children}
        </Stack>
    );
};
