import { Autocomplete, IconButton, Stack, TextField } from '@mui/material';
import { format } from 'date-fns';
import React from 'react';
import { ArrowLeft, ArrowRight } from '@mui/icons-material';

type DateSelectorProps = {
    snapshotDates: Date[];
    value: Date;
    onChange: (date: Date) => void;
};
export const DateSelector = ({
    snapshotDates,
    value,
    onChange,
}: DateSelectorProps) => {
    const prev = snapshotDates.slice(0, -1)[snapshotDates.indexOf(value) + 1];
    const next = snapshotDates.slice(0, -1)[snapshotDates.indexOf(value) - 1];

    return (
        <Stack direction="row" spacing={0.5}>
            <IconButton
                size="small"
                disabled={!prev}
                onClick={() => prev && onChange(prev)}
            >
                <ArrowLeft />
            </IconButton>
            <Autocomplete
                multiple={false}
                freeSolo={false}
                sx={{ minWidth: '200px' }}
                options={snapshotDates.slice(0, -1)}
                value={value}
                getOptionLabel={date => format(date, 'MMMM yyyy')}
                onChange={(_, value) => onChange(value)}
                renderInput={params => (
                    <TextField variant="outlined" {...params} />
                )}
                disableClearable={true}
                autoHighlight={true}
                autoSelect={true}
            ></Autocomplete>
            <IconButton
                size="small"
                disabled={!next}
                onClick={() => next && onChange(next)}
            >
                <ArrowRight />
            </IconButton>
        </Stack>
    );
};
