import { match, P } from 'ts-pattern';

export type Filter = BinaryFilter | LogicalOrFilter | LogicalAndFilter;

export type LogicalAndFilter = {
    and: Filter[];
};

export type LogicalOrFilter = {
    or: Filter[];
};
export const isBinaryFilter = (f: Filter): f is BinaryFilter =>
    match(f)
        .with({ and: P.array() }, () => false)
        .with({ or: P.array() }, () => false)
        .with({ operator: P.any }, () => true)
        .exhaustive();

export interface BinaryFilter {
    member?: string;
    operator: BinaryOperator;
    values: (null | number | string)[];
    aggregate?: boolean;
}

export type BinaryOperator = (typeof binaryOperators)[number];
export const binaryOperators = [
    'set',
    'notSet',
    'equals',
    'notEquals',
    'contains',
    'notContains',
    'startsWith',
    'endsWith',
    'gt',
    'gte',
    'lt',
    'lte',
    'outlierHigh',
    'outlierLow',
    'outlier',
    'inRange',
    'inDateRange',
    'notInDateRange',
    'beforeDate',
    'afterDate',
] as const;
export const isBinaryOperator = (op: string): op is BinaryOperator =>
    (binaryOperators as unknown as string[]).includes(op);

export const isEqualsOperator = (op: string) =>
    ['equals', 'notEquals'].includes(op);

export const isSetOperator = (op: string) => ['set', 'notSet'].includes(op);
export const isOutlierOperator = (
    op: string
): op is 'outlier' | 'outlierLow' | 'outlierHigh' =>
    ['outlier', 'outlierLow', 'outlierHigh'].includes(op);
