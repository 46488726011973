import { useRouteError } from 'react-router-dom';
import { useEffect } from 'react';
import { captureException } from '@sentry/react';
import { ErrorPage } from './ErrorPage';

export const ErrorElement = () => {
    const error = useRouteError();
    useEffect(() => {
        captureException(error);
    }, [error]);

    return <ErrorPage />;
};
