import { Table, WithModifiers } from '../../components/Table';
import * as React from 'react';
import { Columns, ColWidth } from '../../components/Table/types';
import { AlertRuleWithViolations } from '../../types/alerting';
import { AlertAsText } from './AlertAsText';
import { SearchInput, useSearch } from '../../components/Search';
import { Stack, Typography } from '@mui/material';
import { Content } from '../../components/Table';
import { formatHumanReadableDate } from '../../utils/date-format';
import { AlertingTableModifier } from './AlertingTableModifier';
import { ActionButton } from '../../components/Button/ActionButton';
import { useAlertRulesWithViolations } from '../../hooks/use-alert-rules-with-violations';
import { useNavigate } from 'react-router-dom';
import { AlertHealthChart } from './AlertHealthChart';
import { useActiveWorkspaceId } from '../../providers/useActiveWorkspaceId.ts';

type AlertRuleTableItem = WithModifiers<
    AlertRuleWithViolations & { name: string }
>;
const columns: Columns<AlertRuleTableItem> = [
    {
        accessor: 'id',
        title: '',
        width: 4,
        headerStyling: { padding: 0 },
        cellStyling: (theme, alert) => {
            const firing = alert.violations.find(v => v.status === 'open');
            const background = alert.enabled
                ? firing
                    ? theme.palette.error.main
                    : theme.palette.success.dark
                : theme.text.disabled;

            return { background, padding: 0, margin: 0 };
        },
        render: () => null,
        isSortable: false,
    },
    {
        accessor: 'title',
        title: 'Title',
        searchable: true,
        render: alert => {
            return (
                <>
                    <Typography variant="subtitle1" textTransform="none">
                        {alert.title}
                    </Typography>
                    <AlertAsText alert={alert} variant="caption" />
                </>
            );
        },
    },
    {
        accessor: 'violations',
        title: '',
        width: ColWidth.Tiny,
        align: 'center',
        render: alert => {
            return <AlertHealthChart ruleId={alert.id} />;
        },
    },
    {
        accessor: 'modifiedAt',
        title: 'Last modified',
        render: alert => <Content>{renderModified(alert)}</Content>,
        isClickable: false,
        width: ColWidth.DateTime,
        align: 'right',
    },
    {
        accessor: 'modifiers',
        title: '',
        isClickable: false,
        align: 'right',
        width: ColWidth.Tiny,
        render: row => (
            <AlertingTableModifier
                alert={row}
                key={`rule-modifier-${row.id}`}
            />
        ),
    },
];

function renderModified(alert: AlertRuleTableItem) {
    if (alert.modifiedAt) {
        return formatHumanReadableDate(new Date(alert.modifiedAt));
    }

    if (alert.createdAt) {
        formatHumanReadableDate(new Date(alert.createdAt));
    }

    return 'N/A';
}

export const AlertingTable = () => {
    const workspaceId = useActiveWorkspaceId();
    const { data: alertRules, isLoading } = useAlertRulesWithViolations();
    const navigate = useNavigate();

    const rules = (alertRules ?? []).map(rule => ({
        name: rule.title,
        ...rule,
    }));

    const handleCreate = () => {
        navigate(`/workspaces/${workspaceId}/alerting/new`);
    };

    const { setSearch, search, results } = useSearch(rules, ['title']);

    return (
        <Stack gap={1}>
            <Stack direction="row" justifyContent="space-between" gap={1}>
                <SearchInput setSearch={setSearch} />
                <ActionButton onClick={handleCreate}>Add alert</ActionButton>
            </Stack>
            <Table
                columns={columns}
                defaultOrderBy="title"
                rows={results}
                searchQuery={search}
                isLoading={isLoading}
            />
        </Stack>
    );
};
