import { Table } from '../../Table/Table';
import { Baseline } from '../../../types/baselines';
import { Columns } from '../../../components/Table/types';
import { Stack, Typography } from '@mui/material';
import { getTargetDisplayColumn } from './getTargetDisplayColumn';

export type TargetsTableProperties = Baseline;

const columns: Columns<TargetsTableProperties> = [
    {
        accessor: 'id',
        isVisible: false,
    },
    {
        accessor: 'metric',
        title: 'Metrics',
        isClickable: false,
        render: row => {
            return (
                <Stack>
                    <Typography variant="body2">{row.metric}</Typography>
                    <Typography variant="caption">{row.category}</Typography>
                </Stack>
            );
        },
    },
];

export const BaselineTargetsTable = ({ targets }: { targets: Baseline[] }) => {
    const orderedFields = targets.sort(
        (a, b) =>
            a.category.localeCompare(b.category) ||
            a.metric.localeCompare(b.metric)
    );

    const columnsWithInputValue: Columns<TargetsTableProperties> = [
        ...columns,
        getTargetDisplayColumn(),
    ];

    if (orderedFields.length < 1) {
        return null;
    }

    return (
        <Table<TargetsTableProperties>
            columns={columnsWithInputValue}
            rows={orderedFields}
        />
    );
};
