import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { getRustAPIUrl } from '../../../utils/api';
import {
    useOptimisticRemove,
    useOptimisticUpdate,
} from '../../../hooks/use-optimistic-update';
import { useSnackbar } from 'notistack';
import {
    ReportingSubscription,
    WeeklySummaryReportingSubscription,
} from '../../../types/reporting/subscription';

const isWeeklySummarySubscription = (
    subscription: ReportingSubscription
): subscription is WeeklySummaryReportingSubscription => {
    return subscription.payload.type === 'weeklySummary';
};

const allSubscriptions = async () => {
    const response = await axios.get<ReportingSubscription[]>(
        `${getRustAPIUrl()}/reporting-subscriptions`
    );
    return response.data;
};
const updateSubscription = async (subscription: ReportingSubscription) => {
    return await axios.put<ReportingSubscription[]>(
        `${getRustAPIUrl()}/reporting-subscriptions/${subscription.id}`,
        subscription
    );
};
const deleteSubscription = async (id: string) =>
    await axios.delete(`${getRustAPIUrl()}/reporting-subscriptions/${id}`);

const useSubscriptions = () =>
    useQuery<ReportingSubscription[]>({
        queryKey: ['reporting-subscriptions'],
        queryFn: async () => allSubscriptions(),
    });

export const useReportingSubscriptions = () => {
    const { data: subscriptions, isLoading: isLoadingSubscriptions } =
        useSubscriptions();

    const weeklySummarySubscriptions =
        subscriptions?.filter(isWeeklySummarySubscription) || [];

    return {
        weeklySummarySubscriptions,
        isLoading: isLoadingSubscriptions,
    };
};

export const useReportingSubscriptionMutation = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { create, isLoading: isUpdating } =
        useOptimisticUpdate<ReportingSubscription>(
            async (subscription: ReportingSubscription) => {
                try {
                    await updateSubscription(subscription);
                } catch (err) {
                    enqueueSnackbar('Failed to update alert', {
                        variant: 'error',
                    });
                    throw err;
                }
            },
            ['reporting-subscriptions'],
            { invalidateOnSettled: true }
        );

    const { remove } = useOptimisticRemove(
        async (id: string) => {
            await deleteSubscription(id);
        },
        ['reporting-subscriptions']
    );

    return {
        subscribe: create,
        unsubscribe: remove,
        isUpdating,
    };
};
