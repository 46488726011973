import { useQuery } from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';
import { CampaignCollection } from '../types/collections';
import { getRustAPIUrl } from '../utils/api';

const fetchCampaignCollection = async (workspaceId: string) => {
    const result = await axios.get<
        CampaignCollection,
        AxiosResponse<{ campaignCollections: CampaignCollection[] }>
    >(`${getRustAPIUrl()}/workspaces/${workspaceId}/campaign-collections`);

    return result.data.campaignCollections;
};

export const useCampaignCollection = (workspaceId: string) => {
    return useQuery(['campaign-collections', workspaceId], () =>
        fetchCampaignCollection(workspaceId)
    );
};
