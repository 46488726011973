import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { fetchCampaignDetails } from '../../api-client/datasets';
import { match, P } from 'ts-pattern';
import { SplashScreen } from '../../components/SplashScreen/SplashScreen.tsx';
import { ErrorPage } from '../../components/ErrorPage/ErrorPage.tsx';
import { useEffect } from 'react';
import axios from 'axios';
import { useActiveWorkspaceFromRoute } from '../../providers/useActiveWorkspaceFromRoute.ts';

/**
 * Built-in Navigate component does not support absolute URLs
 */
const AbsoluteNavigate = ({ to }: { to: string }) => {
    useEffect(() => {
        window.location.href = to;
    }, [to]);

    return <SplashScreen />;
};

export const WebviewRedirect = () => {
    const { campaignId } = useParams();
    const workspace = useActiveWorkspaceFromRoute();

    const { data, status } = useQuery({
        queryKey: ['workspaces', workspace?.id, 'campaign-details', campaignId],
        queryFn: async () => {
            if (!workspace || !campaignId) {
                throw new Error('query should not be enabled');
            }

            return fetchCampaignDetails(workspace.id, campaignId);
        },
        retry: (failureCount, error) => {
            if (axios.isAxiosError(error) && error.response?.status === 404) {
                return false;
            }

            return failureCount < 3;
        },
        enabled: !!workspace?.id && !!campaignId,
    });

    return match([status, data?.url])
        .with(['success', P.string], ([_, url]) => (
            <AbsoluteNavigate to={url} />
        ))
        .with(['loading', P.any], () => <SplashScreen />)
        .otherwise(() => (
            <ErrorPage
                subtitle={<></>}
                title={'Preview URL not available.'}
                showActions={false}
            />
        ));
};
