import { useExplorerState } from '../../../hooks/use-explorer-state';
import { useNavigate } from 'react-router-dom';
import { useActiveWorkspace } from '../../../providers/WorkspaceProvider.hooks';
import { useExplorerViews } from '../../../hooks/use-explorer-views';
import { Stack } from '@mui/material';
import React from 'react';
import { useActiveUser } from '../../../providers/CurrentUserProvider.hooks';
import { ConflictAlert } from '../../../components/Toolbar/ConflictAlert.tsx';
import { userToModifiedBy } from '../../../types/user';
import { UndoRedoReset } from '../../../components/Toolbar/UndoRedoReset.tsx';
import { ResolveButton } from '../../../components/Toolbar/ResolveButton.tsx';
import { isPermitted } from '../../../types/permission/permission.ts';
import { useActiveExplorerView } from '../../../providers/ExplorerViewProvider.hooks.ts';
import { SaveButton } from '../../../components/Toolbar/SaveButton.tsx';
import { LastModifiedText } from '../../../components/Toolbar/LastModifiedText.tsx';
import { SnackbarMessage } from '../SnackBarMessage.tsx';

export const ExplorerViewToolbar = () => {
    const {
        clearLocalChanges,
        view,
        newRemoteView,
        canUndo,
        canRedo,
        redo,
        undo,
    } = useExplorerState();

    const navigate = useNavigate();

    const workspaceId = useActiveWorkspace().workspaceId;
    const { updateView, duplicateView, isError, isLoading } =
        useExplorerViews(workspaceId);
    const currentUser = useActiveUser();
    const { accessLevel, sharedWith } = useActiveExplorerView();
    const canWrite = isPermitted(accessLevel, 'write');

    const handleSave = () => {
        updateView(view.id, view.config, view.title).then(() =>
            clearLocalChanges({
                ...view,
                modifiedAt: Date.now(),
                modifiedBy: userToModifiedBy(currentUser),
                version: view.version + 1,
            })
        );
    };

    const handleDuplicate = () => {
        duplicateView(view).then(newView => {
            clearLocalChanges();
            navigate(`/workspaces/${workspaceId}/explorer/${newView.id}`);
        });
    };

    if (!canUndo && !canRedo && !newRemoteView) {
        return (
            <LastModifiedText
                modifiedAt={view.modifiedAt}
                modifiedBy={userToModifiedBy(currentUser)}
            />
        );
    }

    return (
        <Stack direction="row" spacing={1}>
            {newRemoteView && (
                <ConflictAlert
                    isLoading={isLoading}
                    modifiedBy={newRemoteView.modifiedBy}
                />
            )}

            <UndoRedoReset
                canRedo={canRedo}
                canUndo={canUndo}
                redo={redo}
                undo={undo}
                clear={() => clearLocalChanges(undefined, true)}
            />

            {newRemoteView ? (
                <ResolveButton
                    canUndo={canUndo}
                    onReset={() => clearLocalChanges(newRemoteView, true)}
                    onSave={handleSave}
                    onDuplicate={handleDuplicate}
                />
            ) : (
                <SaveButton
                    isLoading={isLoading}
                    onReset={() => clearLocalChanges(newRemoteView)}
                    onSave={handleSave}
                    onDuplicate={handleDuplicate}
                    canWrite={canWrite}
                    canUndo={canUndo}
                    isShared={sharedWith.length > 0}
                />
            )}

            {isError && (
                <SnackbarMessage message="Something went wrong while saving the explorer" />
            )}
        </Stack>
    );
};
