import { PageWithSidebar } from '../../components/PageWithSidebar/PageWithSidebar.tsx';
import { Box, Container, Stack } from '@mui/material';
import { Outlet } from 'react-router-dom';
import * as React from 'react';
import { SideNav } from './SideNav.tsx';
import { useActiveWorkspace } from '../../providers/WorkspaceProvider.hooks.ts';
import { useMembers } from './Members/useMembers.tsx';
import { useIntegrations } from '../../hooks/use-integrations.ts';

export const Settings = () => {
    const { isAdmin, workspaceId } = useActiveWorkspace();

    // Prefetch members / integrations for less flicker
    useMembers();
    useIntegrations(isAdmin);

    return (
        <Container maxWidth="lg">
            <Box py={2}>
                <PageWithSidebar
                    sideBar={
                        <Stack spacing={1} p={2}>
                            <SideNav />
                        </Stack>
                    }
                >
                    <Box flexGrow={1} display="flex" flexDirection="column">
                        <Outlet key={workspaceId} />
                    </Box>
                </PageWithSidebar>
            </Box>
        </Container>
    );
};
