import { Box, Stack, Typography } from '@mui/material';
import { ResponsiveLine, Serie } from '@nivo/line';

import { memo } from 'react';
import { isEqual } from 'lodash';
import { DataModelField } from '../../../../types/datamodel/schema.ts';
import { useLocale } from '../../../../providers/LocaleProvider.hooks.ts';
import { formatNumber } from '../../../../utils/number-format.ts';
import { Tooltip } from './Tooltip.tsx';
import { linearGradientDef } from '@nivo/core';

interface LineChartProps {
    series: Serie[];
    metric: DataModelField;
    groupBy: DataModelField | undefined;
}

const LineChartInner = ({ series, metric, groupBy }: LineChartProps) => {
    const locale = useLocale();

    return (
        <Stack>
            <Typography variant="h6" textAlign="center">
                {metric.shortName || metric.name}
            </Typography>
            <Box height={300}>
                <ResponsiveLine
                    colors={colors}
                    data={series}
                    enableGridX={false}
                    margin={{ top: 15, right: 20, bottom: 40, left: 80 }}
                    xScale={{
                        type: 'time',
                        precision: 'day',
                        format: '%Y-%m-%d',
                        useUTC: false,
                    }}
                    yScale={{
                        type: 'linear',
                        min: 0,
                        max: metric.format === 'percent' ? 1.01 : undefined,
                    }}
                    pointSize={5}
                    axisBottom={{ format: '%b %d', tickValues: 7 }}
                    axisLeft={{
                        tickValues: 6,
                        format: value =>
                            formatNumber(value, metric.format, locale),
                    }}
                    enableSlices="x"
                    sliceTooltip={({ slice: { points } }) => (
                        <Tooltip
                            points={points}
                            metric={metric}
                            groupBy={groupBy}
                        />
                    )}
                    enableArea={!groupBy}
                    defs={[
                        linearGradientDef('gradientCurrent', [
                            { offset: 0, color: 'inherit' },
                            { offset: 100, color: 'inherit', opacity: 0 },
                        ]),
                    ]}
                    fill={[
                        {
                            match: { id: metric.id },
                            id: 'gradientCurrent',
                        },
                    ]}
                />
            </Box>
        </Stack>
    );
};

export const LineChart = memo(LineChartInner, isEqual);

const colors = [
    '#39b1c9',
    '#ee7e17',
    '#ed7786',
    '#633e76',
    '#d6d263',
    '#476e97',
    '#63d6b3',
    '#638655',
    '#faaaa7',
    '#92a3b1',
];
