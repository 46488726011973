import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    IconButton,
} from '@mui/material';
import { CalculateOutlined, Close } from '@mui/icons-material';
import React, { useCallback, useRef } from 'react';
import { PrecedingPeriodList } from './PrecedingPeriodList.tsx';
import { Target } from '../../../types/targets';
import { DataModelField } from '../../../types/datamodel/schema.ts';
import { useFormContext } from 'react-hook-form';
import PercentageSlider from './PercentageSlider.tsx';
import { formatNumber, NumberFormat } from '../../../utils/number-format.ts';
import { useLocale } from '../../../providers/LocaleProvider.hooks.ts';
import { useCalculateReducer } from './useCalculate.ts';
import { CalculatorPopper } from './CalculatorPopper.tsx';

export function Calculator({
    target,
    dataModelField,
}: {
    target: Target;
    dataModelField: DataModelField;
}) {
    const locale = useLocale();

    const [state, dispatch] = useCalculateReducer();
    const { isOpen, percentage, calculatedValue, value } = state;

    const anchorEl = useRef<null | HTMLButtonElement>(null);

    const { setValue: setFormValue } = useFormContext<Target>();

    const handleClose = (event: MouseEvent | TouchEvent | React.MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();

        if (
            event.target instanceof HTMLElement &&
            anchorEl.current?.contains(event.target)
        ) {
            return;
        }

        dispatch({ type: 'close' });
    };

    const valueFormatter = useCallback(
        (value: number) =>
            formatNumber(
                value,
                dataModelField.format === 'number'
                    ? NumberFormat.Generic
                    : dataModelField.format,
                locale
            ),
        [dataModelField.format, locale]
    );

    return (
        <CalculatorPopper
            isOpen={isOpen}
            onClose={() => dispatch({ type: 'close' })}
            onToggle={() => dispatch({ type: 'toggle' })}
        >
            <Card
                sx={{
                    width: 400,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                }}
            >
                <CardHeader
                    avatar={<CalculateOutlined />}
                    title="Calculate target"
                    sx={{ paddingBottom: 0 }}
                    action={
                        <IconButton onClick={handleClose}>
                            <Close fontSize="small" />
                        </IconButton>
                    }
                />

                <CardContent sx={{ flex: 2, pt: 0 }}>
                    <PrecedingPeriodList
                        title="1. Select a historical period"
                        target={target}
                        formatValue={valueFormatter}
                        onSelect={value =>
                            dispatch({
                                type: 'selectPeriod',
                                value,
                            })
                        }
                    />

                    <PercentageSlider
                        title="2. Adjust (optional)"
                        disabled={!value}
                        value={value}
                        percentage={percentage}
                        formatValue={valueFormatter}
                        onChange={percentage =>
                            dispatch({
                                type: 'setPercentage',
                                percentage,
                            })
                        }
                    />
                </CardContent>

                <CardActions sx={{ marginLeft: 'auto' }}>
                    <Button
                        size="small"
                        color="secondary"
                        variant="outlined"
                        disabled={!value}
                        onClick={event => {
                            setFormValue('target', calculatedValue);

                            handleClose(event);
                        }}
                    >
                        Confirm
                    </Button>
                </CardActions>
            </Card>
        </CalculatorPopper>
    );
}
