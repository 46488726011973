import { Autocomplete as MuiAutocomplete, List } from '@mui/material';
import React, { forwardRef, JSXElementConstructor } from 'react';
import { autocompleteComponentProps } from './AutocompleteInput.styled';
import { AutocompleteListItem } from './AutocompleteListItem';
import {
    AutocompleteInput,
    AutocompleteInputOverrideProps,
} from './AutocompleteInput';

const ListComponent = forwardRef<HTMLUListElement>((params, ref) => {
    return <List {...params} ref={ref} disablePadding />;
}) as JSXElementConstructor<React.HTMLAttributes<HTMLElement>>;

export type AutocompleteValue = {
    label: string;
    value: string;
    secondaryLabel?: string;
};

type InputAutocompleteProps<T extends AutocompleteValue> = {
    disabled?: boolean;
    isLoading: boolean;
    options: T[];
    selected: T[];
    onChange: (option: T[]) => void;
    autocompleteInputProps?: AutocompleteInputOverrideProps;
};

export const Autocomplete = <T extends AutocompleteValue>({
    disabled,
    isLoading,
    options,
    selected = [],
    onChange,
    autocompleteInputProps,
}: InputAutocompleteProps<T>) => (
    <MuiAutocomplete<T, true, true, false>
        multiple
        autoHighlight={true}
        disableClearable
        loading={isLoading}
        value={selected}
        noOptionsText="No options found"
        options={options}
        disabled={disabled || isLoading || options.length === 0}
        fullWidth
        onChange={(event, value) => onChange(value)}
        sx={{ paddingRight: '100px' }}
        componentsProps={autocompleteComponentProps}
        disableCloseOnSelect
        size="small"
        getOptionKey={option => option.value}
        getOptionLabel={option => option.label}
        ListboxComponent={ListComponent}
        ListboxProps={{
            style: {
                padding: 0,
            },
        }}
        renderOption={AutocompleteListItem}
        isOptionEqualToValue={(option, another) =>
            option.value === another.value
        }
        renderInput={params => (
            <AutocompleteInput
                {...params}
                {...autocompleteInputProps}
                isLoading={isLoading}
                options={options}
            />
        )}
    />
);
