import { Alert } from '@mui/material';
import * as React from 'react';
import { useAlertRuleResults } from '../../hooks/use-alert-rule-results';
import { HealthChartSkeleton } from '../../components/HealthChart/HealthChartSkeleton';
import { HealthChart } from '../../components/HealthChart/HealthChart';

export const AlertHealthChart = ({ ruleId }: { ruleId: string }) => {
    const { data, isLoading, isError } = useAlertRuleResults(ruleId);

    if (isError) {
        return (
            <Alert severity="error" sx={{ whiteSpace: 'nowrap' }}>
                Failed to fetch alert history.
            </Alert>
        );
    }

    return isLoading || !data ? (
        <HealthChartSkeleton />
    ) : (
        <HealthChart
            results={data.results}
            conditions={data.conditions}
            dimension={data.dimension}
        />
    );
};
