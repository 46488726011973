import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { SplashScreen } from '../SplashScreen/SplashScreen';
import { Outlet } from 'react-router-dom';
import { useConfigureInterceptor } from '../../hooks/use-configure-interceptor';
import { useFeatureFlags } from '../../hooks/use-feature-flags';
import { useInitialData } from '../../hooks/use-initial-data';
import { CurrentUserProvider } from '../../providers/CurrentUserProvider';
import { SeinoErrorBoundary } from '../ErrorPage/SeinoErrorBoundary';
import { useSentry } from '../../hooks/use-sentry';
import { captureException, captureMessage } from '@sentry/react';
import { ErrorPage } from '../ErrorPage/ErrorPage';

export const AuthenticatedRoute = () => {
    const { isAuthenticated, isLoading, loginWithRedirect, error } = useAuth0();
    useConfigureInterceptor();
    const { data: currentUser, error: errorInitialData } = useInitialData();

    useEffect(() => {
        if (isLoading || isAuthenticated) {
            return;
        }

        (async (): Promise<void> => {
            if (window.location.pathname.includes('callback')) {
                captureMessage('Prevent returning to /callback', {
                    level: 'error',
                });

                return await loginWithRedirect();
            }

            await loginWithRedirect({
                appState: {
                    returnTo: `${window.location.pathname}${window.location.search}`,
                },
            });
        })();
    }, [isLoading, isAuthenticated, loginWithRedirect]);

    useEffect(() => {
        if (error) {
            captureException(error);
        }
    }, [error]);

    useEffect(() => {
        if (errorInitialData) {
            captureException(errorInitialData);
        }
    }, [errorInitialData]);

    if (error || errorInitialData) {
        return <ErrorPage />;
    }

    return isAuthenticated && currentUser ? (
        <CurrentUserProvider>
            <Render />
        </CurrentUserProvider>
    ) : (
        <SplashScreen />
    );
};

const Render = () => {
    useFeatureFlags();
    useSentry();

    return (
        <SeinoErrorBoundary>
            <Outlet />
        </SeinoErrorBoundary>
    );
};
