import { Outlet } from 'react-router-dom';
import { Header } from '../components/Header/Header';
import { SeinoErrorBoundary } from '../components/ErrorPage/SeinoErrorBoundary';
import { AppBar, CssBaseline } from '@mui/material';
import * as React from 'react';
import { useDrawer } from '../hooks/use-drawer.ts';
import { GettingStartedDrawer } from '../components/Drawer/GettingStartedDrawer';
import { Layout } from '../components/Layout/Main';

export const ExplorerLayout = ({
    showNavigation = true,
    hideWorkspaceSelector = false,
}: {
    showNavigation?: boolean;
    hideWorkspaceSelector?: boolean;
}) => {
    const { isOpen } = useDrawer('getting-started');

    return (
        <>
            <CssBaseline />
            <AppBar
                position="sticky"
                sx={{ zIndex: theme => theme.zIndex.drawer + 1 }}
            >
                <Header
                    showNavigation={showNavigation}
                    showWorkspaceSelector={!hideWorkspaceSelector}
                />
            </AppBar>

            <SeinoErrorBoundary>
                <Layout.Main open={isOpen}>
                    {isOpen && <GettingStartedDrawer />}

                    <Outlet />
                </Layout.Main>
            </SeinoErrorBoundary>
        </>
    );
};
