import React, { createContext, useContext, useState } from 'react';
import { TreeItem, TreeView } from '@mui/x-tree-view';
import {
    Database,
    TrackedView,
    useCopernicaChildViews,
    useCopernicaDatabaseViews,
} from './TrackedViews.hooks';
import { MountSensor } from '../../../../../components/MountSensor/MountSensor';
import {
    Check,
    ChevronRight,
    Close,
    Edit,
    ExpandMore,
} from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';

type DatabaseTreeContextProps = {
    databases: Database[];
    integrationId: string;
    selected: TrackedView[];
    setSelected: (val: TrackedView[]) => void;
};

const DatabaseTreeContext = createContext<DatabaseTreeContextProps>({
    databases: [],
    integrationId: '',
    selected: [],
    setSelected: () => {},
});

export const DatabaseTree = (props: DatabaseTreeContextProps) => {
    return (
        <DatabaseTreeContext.Provider value={props}>
            <Stack direction="row" justifyContent="space-between">
                <Typography variant="body2" fontWeight="bold">
                    Copernica database/view
                </Typography>
                <Typography variant="body2" fontWeight="bold">
                    Import as
                </Typography>
            </Stack>
            <TreeView
                defaultExpandIcon={<ChevronRight />}
                defaultCollapseIcon={<ExpandMore />}
                multiSelect={true}
                selected={props.selected.map(v => `${v.id}`)}
            >
                {props.databases.map(db => (
                    <DatabaseNode
                        key={`${db.id}`}
                        {...db}
                        has_children={true}
                    />
                ))}
            </TreeView>
        </DatabaseTreeContext.Provider>
    );
};

type TreeNodeProps = { id: number; name: string; has_children: boolean };
const DatabaseNode = ({ id, name }: TreeNodeProps) => {
    const { integrationId } = useContext(DatabaseTreeContext);
    const [isExpanded, setIsExpanded] = useState(false);
    const { data: views, isLoading } = useCopernicaDatabaseViews(
        integrationId,
        id,
        isExpanded
    );

    return (
        <TreeItem key={id} nodeId={`db-${id}`} label={name}>
            <MountSensor onMount={setIsExpanded} />
            <LoadingNode id={id} isLoading={isLoading} />
            {views?.map(view => (
                <ViewNode key={`${view.id}`} {...view} />
            ))}
        </TreeItem>
    );
};

const ViewNode = ({ id, name, has_children }: TreeNodeProps) => {
    const { integrationId } = useContext(DatabaseTreeContext);

    const [isExpanded, setIsExpanded] = useState(false);
    const { data: views, isLoading } = useCopernicaChildViews(
        integrationId,
        id,
        isExpanded
    );

    if (!has_children) {
        return (
            <TreeItem
                nodeId={`${id}`}
                label={<ViewNodeLabel id={id} name={name} />}
            />
        );
    }

    return (
        <TreeItem
            nodeId={`${id}`}
            label={<ViewNodeLabel id={id} name={name} />}
        >
            <MountSensor onMount={setIsExpanded} />
            <LoadingNode id={id} isLoading={isLoading} />
            {views?.map(view => (
                <ViewNode key={`${view.id}`} {...view} />
            ))}
        </TreeItem>
    );
};

type ViewNodeLabelProps = {
    id: number;
    name: string;
};
function ViewNodeLabel({ id, name }: ViewNodeLabelProps) {
    const { selected, setSelected } = useContext(DatabaseTreeContext);
    const isSelected = selected.map(x => x.id).includes(id);
    const [isEditing, setIsEditing] = useState(false);
    const alias = selected.find(v => v.id === id)?.name || name;
    const [hover, setHover] = useState(false);

    const handleAdd = () => {
        setSelected([
            ...selected.filter(x => x.id !== id),
            { id: id, name: alias },
        ]);
    };

    const handleRemove = () => {
        setSelected(selected.filter(x => x.id !== id));
    };

    return (
        <Stack
            direction="row"
            flexWrap="wrap"
            justifyContent="space-between"
            spacing={2}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            <Stack direction="row">
                <input
                    type="checkbox"
                    checked={isSelected}
                    onChange={e => {
                        if (e.currentTarget.checked) {
                            handleAdd();
                        } else {
                            handleRemove();
                        }
                    }}
                    onClick={e => e.stopPropagation()}
                />
                &nbsp;
                {name}
            </Stack>

            {isSelected && (
                <Stack direction="row" flexGrow={1} justifyContent="end">
                    {isEditing ? (
                        <ViewLabelInput
                            initialValue={alias}
                            onClose={() => setIsEditing(false)}
                            onChange={newName => {
                                setSelected(
                                    selected.map(v =>
                                        v.id === id
                                            ? { id, name: newName || name }
                                            : v
                                    )
                                );
                            }}
                        />
                    ) : (
                        <>
                            <span onClick={() => setIsEditing(true)}>
                                {alias}
                            </span>
                            <Edit
                                fontSize="small"
                                sx={{
                                    opacity: hover && isSelected ? 0.4 : 0.0,
                                }}
                                onClick={e => {
                                    setIsEditing(!isEditing);
                                    e.stopPropagation();
                                }}
                            />
                        </>
                    )}
                </Stack>
            )}
        </Stack>
    );
}

type ViewLabelInputProps = {
    initialValue: string;
    onChange: (newValue: string) => void;
    onClose: () => void;
};

const ViewLabelInput = ({
    initialValue,
    onChange,
    onClose,
}: ViewLabelInputProps) => {
    const [value, setValue] = useState(initialValue);

    const handleConfirm = () => {
        onChange(value);
        onClose();
    };

    const handleCancel = () => {
        onClose();
    };

    return (
        <Stack
            direction="row"
            justifyContent="end"
            flexGrow={1}
            onClick={e => e.stopPropagation()}
        >
            <input
                autoFocus={true}
                value={value}
                style={{ textAlign: 'right', flexGrow: 1 }}
                onKeyDown={e => {
                    switch (e.key) {
                        case 'Enter':
                            handleConfirm();
                            break;
                        case 'Escape':
                            handleCancel();
                            break;
                    }
                }}
                onChange={e => {
                    setValue(e.target.value);
                }}
            />
            <Check fontSize="small" onClick={handleConfirm} />
            <Close fontSize="small" onClick={handleCancel} />
        </Stack>
    );
};

type LoadingNodeProps = { id: number | string; isLoading: boolean };
const LoadingNode = ({ id, isLoading }: LoadingNodeProps) =>
    isLoading ? <TreeItem nodeId={`${id}-loading`} label="Loading..." /> : null;
