import { Autocomplete, Box, TextField } from '@mui/material';

import { useActiveWorkspace } from '../../../providers/WorkspaceProvider.hooks';
import { useCampaignCollection } from '../../../hooks/use-campaign-collections';
import { useFormContext } from 'react-hook-form';
import { CollectionForm } from '../Collections';
import { createBaselineTargetsFromKpiData } from '../../../types/collections';
import { useActiveExplorerView } from '../../../providers/ExplorerViewProvider.hooks';
import { GroupHeader, StyledList } from './SelectCollection.styles';
import { DataModelField } from '../../../types/datamodel/schema';
import { isPermitted } from '../../../types/permission/permission';
import { useDialog } from '../../Dialog/useDialog.ts';

type GroupedOption = {
    group: string;
    title: string;
    value: string | undefined;
};

interface SelectCollectionProps {
    hide: boolean;
    onSelect?: () => void;
    schemas: DataModelField[] | undefined;
}

export const SelectCollection = ({
    hide,
    onSelect,
    schemas,
}: SelectCollectionProps) => {
    const {
        parameters: { baselineId },
        openDialog,
    } = useDialog<{ baselineId?: string }>('explorer');

    const { reset } = useFormContext<CollectionForm>();
    const { view, accessLevel } = useActiveExplorerView();
    const { workspaceId } = useActiveWorkspace();
    const { data: collections = [] } = useCampaignCollection(workspaceId);

    const selectCollection = (baselineId: string) => {
        openDialog({ baselineId });

        if (baselineId === 'current' && schemas) {
            const baselines = createBaselineTargetsFromKpiData(
                view.config.kpiData,
                schemas
            );

            reset({
                title: '',
                targets: baselines,
            });
        } else {
            const selectedCollection = collections?.find(
                ({ baselineSet }) => baselineSet.id === baselineId
            );

            reset({
                title: selectedCollection?.name || '',
                targets: selectedCollection?.baselineSet.targets || [],
            });
        }

        onSelect?.();
    };

    const options = [
        {
            group: 'Standard',
            title: 'Baselines for current view...',
            value: 'current',
        },
        {
            group: 'Standard',
            title: 'No baselines',
            value: 'none',
        },
        ...collections.map(c => ({
            group: 'Custom templates',
            title: c.name,
            value: c.baselineSet.id,
        })),
    ];

    if (hide) return null;

    const val = !baselineId
        ? options.find(o => o.value === view.config.baselineId) || options[0]
        : options.find(o => o.value === baselineId);

    if (!val) {
        return null;
    }

    return (
        <Box mb={2}>
            <Autocomplete
                multiple={false}
                disabled={!isPermitted(accessLevel, 'write')}
                value={val}
                isOptionEqualToValue={(option, value) => {
                    return value === undefined || option.value === value.value;
                }}
                disableClearable
                onChange={(_, groupedOption: GroupedOption | null) => {
                    groupedOption?.value &&
                        selectCollection(groupedOption.value);
                }}
                options={options}
                getOptionLabel={(option: GroupedOption) => option.title}
                groupBy={(option: GroupedOption) => option.group}
                renderGroup={params => {
                    return (
                        <StyledList key={params.key}>
                            {params.group !== 'Standard' && (
                                <GroupHeader>{params.group}</GroupHeader>
                            )}
                            <Box>{params.children}</Box>
                        </StyledList>
                    );
                }}
                size="small"
                renderInput={(params: any) => (
                    <TextField
                        {...params}
                        variant="outlined"
                        fullWidth
                        label="Baseline templates"
                    />
                )}
            />
        </Box>
    );
};
