import {
    Box,
    Chip,
    Container,
    Divider,
    Paper,
    Stack,
    Typography,
} from '@mui/material';
import { ReactElement } from 'react';
import { SeinoLogo } from '../Graphics/SeinoLogo';
import { WorkspaceSelector } from '../WorkspaceSelector/WorkspaceSelector.tsx';
import { useInitialData } from '../../hooks/use-initial-data.ts';
import { useParams } from 'react-router-dom';
import { SignOutLink } from '../Navigate/SignOutLink.tsx';

interface InfoPanelProps {
    showLogo?: boolean;
    title?: string;
    subtitle?: string;
    dividerTitle?: string;
    content: ReactElement;
    hideWorkspaceSelector?: boolean;
}

export const InfoPanel = ({
    showLogo = true,
    title = undefined,
    subtitle = undefined,
    dividerTitle = undefined,
    content,
    hideWorkspaceSelector,
}: InfoPanelProps) => {
    const { data } = useInitialData();

    const { workspaceId } = useParams<{ workspaceId: string | undefined }>();
    const workspaces = data?.workspaces.filter(
        ws => ws.id !== workspaceId && ws.name !== workspaceId
    );

    return (
        <Container
            style={{
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'center',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
            }}
        >
            <Paper variant="outlined" sx={{ position: 'relative' }}>
                <Box position="absolute" right={20} top={10}>
                    <SignOutLink />
                </Box>
                {showLogo && (
                    <Box m={4}>
                        <SeinoLogo />
                    </Box>
                )}

                {title && (
                    <Typography paragraph={true} variant="h4">
                        {title}
                    </Typography>
                )}

                <Box m={4}>
                    {subtitle && (
                        <Typography variant="subtitle2" paragraph={true}>
                            {subtitle}
                        </Typography>
                    )}
                </Box>

                <Divider textAlign="center">
                    {dividerTitle && <Chip label={dividerTitle} />}
                </Divider>

                <Box margin={4}>{content}</Box>
            </Paper>

            {!hideWorkspaceSelector && workspaces && workspaces.length > 1 && (
                <Stack
                    padding={2}
                    gap={1}
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Stack
                        direction="row"
                        alignItems="center"
                        textAlign="left"
                        gap={2}
                    >
                        <Typography variant="smallMediumBody">
                            Current workspace:
                        </Typography>
                        <WorkspaceSelector variant="light" />
                    </Stack>
                </Stack>
            )}
        </Container>
    );
};
