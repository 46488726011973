import React, { ReactElement } from 'react';

import { getFinalValue } from './getFinalValue';

import { BaseRow, Column, Columns } from './types';
import { HighlightText } from '../Hightlight/Hightlight';
import { styled, Typography } from '@mui/material';

export const Content = styled(Typography)({});
Content.defaultProps = {
    variant: 'body2',
};

export const SubContent = styled(Typography)(({ theme }) => ({
    fontSize: 12,
    color: theme.text.primaryLight,
}));
SubContent.defaultProps = {
    variant: 'smallMediumBody',
};

type Props<T extends BaseRow> = {
    column: Column<T>;
    columns: Columns<T>;
    row: T;
    searchQuery?: string;
};

export function TableCellContents<T extends BaseRow>({
    column,
    row,
    columns,
    searchQuery,
}: Props<T>): ReactElement | null {
    const { accessor, render, getValue, searchable } = column;

    if (render) {
        return render(row, columns, searchQuery);
    }

    const textValue = getFinalValue(row, accessor, getValue);

    if (searchQuery && searchable !== false) {
        return <HighlightText text={textValue} query={searchQuery} />;
    }

    return <Content>{textValue}</Content>;
}
