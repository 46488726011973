import { match } from 'ts-pattern';
import { useReducer } from 'react';

export interface CalculatorState {
    value: number;
    percentage: number;
    calculatedValue: number;
    isOpen: boolean;
}

export type CalculatorAction =
    | { type: 'setPercentage'; percentage: number }
    | {
          type: 'selectPeriod';
          value: number;
      }
    | { type: 'toggle' }
    | { type: 'close' }
    | { type: 'open' };

type Reducer<T> = (
    action: Extract<CalculatorAction, { type: T }>
) => CalculatorState;

export const reducer = (
    state: CalculatorState,
    action: CalculatorAction
): CalculatorState =>
    match(action)
        .with({ type: 'open' }, open(state))
        .with({ type: 'close' }, close(state))
        .with({ type: 'setPercentage' }, setPercentage(state))
        .with({ type: 'selectPeriod' }, selectPeriod(state))
        .with({ type: 'toggle' }, toggle(state))
        .exhaustive();

const setPercentage =
    (state: CalculatorState): Reducer<'setPercentage'> =>
    ({ percentage }) => ({
        ...state,
        percentage,
        calculatedValue: state.value * (1 + percentage / 100),
    });

const selectPeriod =
    (state: CalculatorState): Reducer<'selectPeriod'> =>
    ({ value }) => ({
        ...state,
        value,
        calculatedValue: value,
        percentage: 0,
    });

const close =
    (state: CalculatorState): Reducer<'close'> =>
    () => ({
        ...state,
        value: 0,
        percentage: 0,
        calculatedValue: 0,
        isOpen: false,
    });

const toggle =
    (state: CalculatorState): Reducer<'toggle'> =>
    () => ({
        ...state,
        value: 0,
        percentage: 0,
        calculatedValue: 0,
        isOpen: !state.isOpen,
    });

const open =
    (state: CalculatorState): Reducer<'open'> =>
    () => ({
        ...state,
        isOpen: true,
    });

export const useCalculateReducer = (initialState?: CalculatorState) =>
    useReducer(reducer, {
        value: 0,
        percentage: 0,
        calculatedValue: 0,
        isOpen: false,
        ...initialState,
    });
