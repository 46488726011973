import { Column, ColWidth } from '../../Table/types';
import * as React from 'react';
import { TargetsTableProperties } from './BaselineTargetsTable';
import { TargetInputColumn } from './TargetInputColumn.tsx';

export const getTargetInputColumn = (
    editable: boolean
): Column<TargetsTableProperties> => ({
    accessor: 'value',
    title: 'Targets',
    isClickable: false,
    render: row => <TargetInputColumn row={row} disabled={!editable} />,
    width: ColWidth.Medium,
});
