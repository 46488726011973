import { useAlertRuleViolations } from './use-alert-rule-violations';
import { useAlertRules } from './use-alert-rules';
import { AlertRuleWithViolations } from '../types/alerting';

type Result =
    | { status: 'loading'; isLoading: true; data?: undefined }
    | { status: 'error'; isLoading: false; error: unknown; data?: undefined }
    | { status: 'success'; isLoading: false; data: AlertRuleWithViolations[] };

export const useAlertRulesWithViolations = (): Result => {
    const rulesQuery = useAlertRules();
    const violationsQuery = useAlertRuleViolations();

    if (rulesQuery.isLoading || violationsQuery.isLoading) {
        return { status: 'loading', isLoading: true };
    }

    if (rulesQuery.isError || violationsQuery.isError) {
        return {
            status: 'error',
            isLoading: false,
            error: rulesQuery.error || violationsQuery.error,
        };
    }

    return {
        status: 'success',
        isLoading: false,
        data:
            rulesQuery.data?.map(rule => ({
                ...rule,
                violations:
                    violationsQuery.data?.filter(v => v.ruleId === rule.id) ||
                    [],
            })) || [],
    };
};
