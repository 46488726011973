import { useDashboardDataset } from '../../../../hooks/use-dashboard-dataset.ts';
import { useTimeSeriesQuery } from '../../../../queries/time-series-hook';
import {
    fixedConversionMetrics,
    WidgetConfig,
} from '../../../../types/dashboard';
import { Widget } from '../Widget';
import { ChartData, FunnelChart } from './Chart/FunnelChart';
import React, { useMemo } from 'react';
import { useDateRange } from '../../../../hooks/use-date-range';
import { WidgetError } from '../WidgetError.tsx';
import { Alert } from '@mui/material';

export const Funnel = (config: WidgetConfig) => {
    const { dateRanges } = useDateRange();

    const { funnelMetrics, conversionMetric, filters } = config;
    const metrics = useMemo(
        () =>
            funnelMetrics || [
                ...fixedConversionMetrics,
                conversionMetric || 'transactions',
            ],
        [conversionMetric, funnelMetrics]
    );

    const { data, isLoading, error } = useTimeSeriesQuery<any[]>(
        config.dataset,
        {
            dateRange: dateRanges.main,
            metrics: metrics,
            filters: filters || [],
        }
    );

    const transformedData: ChartData[] = useMemo(() => {
        if (!data) {
            return [];
        }

        return metrics.map(m => ({ id: m, value: (data as any[])[0][m] }));
    }, [data, metrics]);

    const { data: dataset } = useDashboardDataset(config.dataset);

    if (error) {
        return (
            <Widget config={config}>
                <WidgetError />
            </Widget>
        );
    }

    return (
        <Widget config={config}>
            {transformedData.length === 0 && (
                <Alert severity="info">
                    No metrics selected. Please select at least one metric in
                    the config panel.
                </Alert>
            )}
            {!isLoading && transformedData?.length > 0 ? (
                <FunnelChart dataset={dataset} data={transformedData} />
            ) : null}
        </Widget>
    );
};
