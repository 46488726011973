import { IAggFunc, ValueGetterParams } from '@ag-grid-community/core';

export class WeightedValue {
    public valueOf: () => number | undefined;

    constructor(public value: number, public weight: number) {
        this.valueOf = () => {
            return this.weight === 0 ? undefined : this.value / this.weight;
        };
    }

    toString() {
        return this.valueOf()?.toFixed(4) || '';
    }

    toNumber() {
        return this.valueOf();
    }
}

export const weightedValueGetter =
    (valueMetric: string, weightMetric: string) =>
    ({ data }: ValueGetterParams) =>
        new WeightedValue(data?.[valueMetric] || 0, data?.[weightMetric] || 0);

export const weightedAverage: IAggFunc = ({ values }) => {
    let totalValue = 0,
        totalWeight = 0;

    values.forEach(weightedVal => {
        totalValue += weightedVal?.value;
        totalWeight += weightedVal?.weight;
    });

    return new WeightedValue(totalValue, totalWeight);
};
