import { ModifierRow } from './Table.tsx';
import { useState } from 'react';
import { IconButton } from '@mui/material';
import { DeleteForever } from '@mui/icons-material';
import ConfirmDialog from '../ConfirmDialog/ConfirmAlert.tsx';
import * as React from 'react';

export function RemoveModifier<T extends ModifierRow>({
    item,
    onRemove,
}: {
    item: T;
    onRemove: (item: T) => void;
}) {
    const [removeDialogOpen, setRemoveDialog] = useState(false);

    return (
        <>
            <IconButton
                size="extraSmall"
                color="light"
                onClick={() => {
                    setRemoveDialog(true);
                }}
            >
                <DeleteForever fontSize="small" />
            </IconButton>
            <ConfirmDialog
                open={removeDialogOpen}
                onAnyDecision={() => setRemoveDialog(false)}
                onContinue={() => onRemove(item)}
                isDangerousAction={true}
                content={`Are you sure you want to remove this item?`}
                continueText="Remove"
            />
        </>
    );
}
