import { isWithinInterval } from 'date-fns';
import { Annotation } from '../../types/annotation';
import { DateRange } from '../DateRangePicker/reducer.tsx';

export const isAnnotationWithinInterval = (
    annotation: Pick<Annotation, 'dateStart' | 'dateEnd'>,
    period: DateRange
) =>
    isWithinInterval(annotation.dateStart, {
        start: period.start,
        end: period.end,
    }) ||
    isWithinInterval(annotation.dateEnd, {
        start: period.start,
        end: period.end,
    }) ||
    isWithinInterval(period.start, {
        start: annotation.dateStart,
        end: annotation.dateEnd,
    }) ||
    isWithinInterval(period.end, {
        start: annotation.dateStart,
        end: annotation.dateEnd,
    });
