import axios, { AxiosResponse } from 'axios';
import { InitialData } from '../types/user';
import { getRustAPIUrl } from '../utils/api';
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from '@tanstack/react-query';

export const useInitialData = () => {
    const { user, getAccessTokenSilently } = useAuth0();

    return useQuery({
        queryKey: ['fetch-initial-data', user?.sub],
        queryFn: async () => {
            // Bypassing the interceptor that normally adds the auth header,
            // since it may not be registered immediately after authenticating.
            const client = axios.create();
            const token = await getAccessTokenSilently();
            const result = await client.get<
                InitialData,
                AxiosResponse<InitialData>
            >(`${getRustAPIUrl()}/me`, {
                headers: { authorization: `Bearer ${token}` },
            });
            return result.data;
        },
        refetchOnWindowFocus: false,
        staleTime: 10_000,
        enabled: Boolean(user?.sub),
    });
};
