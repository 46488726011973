import { useDialog } from '../../components/Dialog/useDialog.ts';

const sections = ['sharing'] as const;
type Section = (typeof sections)[number];

function isValidPanel(section?: string): section is Section {
    return section !== undefined && sections.includes(section as Section);
}

type DialogArgs = {
    panel?: string;
};
export function useDashboardDialog() {
    const { closeDialog, isOpen, currentDialog, openDialog, parameters } =
        useDialog<DialogArgs>('dashboard');

    return {
        closeDialog,
        isOpen,
        currentDialog,
        panel: isValidPanel(parameters.panel) ? parameters.panel : 'sharing',
        openDialog: ({ panel }: DialogArgs) => {
            if (isValidPanel(panel)) {
                openDialog({ panel: panel });
            }
        },
        parameters,
        isValidSection: isValidPanel,
    };
}
