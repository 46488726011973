import { Alert } from '@mui/material';
import { IntegrationInfo } from './IntegrationInfo';
import {
    IntegrationType,
    SupportedIntegrations,
} from '../../../types/workspace-management';
import { Page } from '../../../components/Page/Page';
import { useIntegrations } from '../../../hooks/use-integrations';

interface CustomSetupIntegrationProps {
    integrationType: IntegrationType;
}

export const CustomSetupIntegration = ({
    integrationType,
}: CustomSetupIntegrationProps) => {
    const { data } = useIntegrations();
    const enabled = !!data?.find(x => x.type === integrationType);

    return (
        <Page padding={false}>
            <IntegrationInfo
                integrationType={integrationType}
                enabled={enabled}
            />
            <Alert severity="warning">
                Please contact SEINō to setup{' '}
                {SupportedIntegrations[integrationType].label} for your
                workspace.
            </Alert>
        </Page>
    );
};
