import {
    Bar,
    BarChart,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';
import { SubscriberListDatum } from '../use-subscriber-list-data';
import { useTheme } from '@mui/material';
import React from 'react';
import { RechartTooltip } from '../../../../Rechart/RechartTooltip';

interface DivergenceChartProps {
    data: SubscriberListDatum[];
}

export const DivergenceRechart = ({ data }: DivergenceChartProps) => {
    const absMax = Math.max(
        ...data.flatMap(row => [row.members_in, row.members_out].map(Math.abs))
    );
    const theme = useTheme();

    return (
        <ResponsiveContainer width="100%" height="100%">
            <BarChart data={data} stackOffset="sign" barCategoryGap={'20%'}>
                <Tooltip
                    filterNull={false}
                    cursor={false}
                    position={{ y: -100 }}
                    wrapperStyle={{ zIndex: 10 }}
                    content={<RechartTooltip />}
                />
                <XAxis
                    dataKey="date"
                    axisLine={false}
                    tickLine={false}
                    hide={true}
                />
                <YAxis hide={true} domain={[-1.1 * absMax, 1.1 * absMax]} />
                <Bar
                    isAnimationActive={false}
                    dataKey="members_in"
                    name="Members in"
                    fill={theme.palette.success.dark}
                    stackId="stack"
                />
                <Bar
                    isAnimationActive={false}
                    dataKey="members_out"
                    name="Members out"
                    fill={theme.palette.error.main}
                    stackId="stack"
                />
                <ReferenceLine y={0} stroke="rgb(226, 92, 59)" />
            </BarChart>
        </ResponsiveContainer>
    );
};
