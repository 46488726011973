import { LegacyDialog } from '../../components/Dialog/LegacyDialog.tsx';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useActiveWorkspace } from '../../providers/WorkspaceProvider.hooks';
import { useAlertRules } from '../../hooks/use-alert-rules';
import { AlertForm } from './AlertForm';
import { newAlertRule } from '../../types/alerting';
import { ulid } from 'ulid';
import { cloneDeep } from 'lodash';
import { useInitialData } from '../../hooks/use-initial-data';
import { useLegacyDialog } from '../../components/Dialog/useLegacyDialog.tsx';

export const AlertDialog = () => {
    const { close } = useLegacyDialog();
    const navigate = useNavigate();
    const { workspaceId } = useActiveWorkspace();
    const { data: userData } = useInitialData();

    const { alertId } = useParams<{ alertId: string }>();
    const [searchParams] = useSearchParams();

    const { data: alertRules } = useAlertRules();

    const handleOnClose = () => {
        close();
        navigate(`/workspaces/${workspaceId}/alerting`);
    };

    let alert =
        alertId === 'new'
            ? newAlertRule(workspaceId, userData?.email)
            : alertRules?.find(rule => rule.id === alertId);

    if (alert && searchParams.has('copy')) {
        alert = {
            ...cloneDeep(alert),
            id: ulid(),
            title: `${alert.title} (copy)`,
        };
    }

    return (
        <LegacyDialog
            open={Boolean(alert)}
            content={
                <>
                    {alert && (
                        <AlertForm alert={alert} onSubmit={handleOnClose} />
                    )}
                </>
            }
            handleClose={() => handleOnClose()}
            title="Edit alert"
            maxWidth="lg"
        />
    );
};
