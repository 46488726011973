import { KPIData } from './grid/utils/gridView.ts';
import { Localization } from '../../types/datamodel/localization.ts';
import {
    DataModelField,
    isDimension,
    isGroupedDimension,
    isMetric,
    isNegativeMetric,
    isNonGroupedDimension,
    isSetFilterDisabled,
    notHasFeature,
} from '../../types/datamodel/schema.ts';
import {
    ColDef,
    ColGroupDef,
    INumberFilterParams,
    ISetFilterParams,
    ITextFilterParams,
    ValueFormatterFunc,
    ValueFormatterParams,
    ValueGetterFunc,
} from '@ag-grid-community/core';
import { groupBy } from 'lodash';
import { CountryComponent, dateFormatter } from './grid/utils';
import { LinkComponent } from './grid/utils/LinkComponent.tsx';
import { match } from 'ts-pattern';
import { months, weekdays } from '../../const/date.ts';
import { formatNumber } from '../../utils/number-format.ts';
import { Maybe } from 'purify-ts';
import {
    monthComparator,
    monthGetter,
    weekdayComparator,
    weekdayGetter,
} from './grid/utils/datePartFormat.ts';

export type ColumnDefContext = {
    kpiData: KPIData;
    locale: Localization;
    weekStartsOn: Day;
    widthCalculator?: (text: string | undefined) => number;
};

export const createColumnDefs = (
    fields: DataModelField[],
    ctx: ColumnDefContext
): ColGroupDef[] =>
    Object.entries(
        groupBy(
            fields
                .filter(notHasFeature('hide'))
                .filter(notHasFeature('explorer_master_detail'))
                .filter(f => !isMetric(f) || !!f.agg?.type),
            'category'
        )
    ).map(
        ([category, fields]): ColGroupDef => ({
            groupId: category,
            headerName: category,
            children: fields.map(createColumnDef(ctx)),
        })
    );

export const createColumnDef =
    (ctx: ColumnDefContext) =>
    (field: DataModelField): ColDef => ({
        colId: field.id,
        field: field.id,
        width: ctx.widthCalculator
            ? ctx.widthCalculator(field.name)
            : undefined,
        headerName: field.name,
        autoHeaderHeight: true,
        ...(isDimension(field) ? createDimensionField(field, ctx) : {}),
        ...(isMetric(field) ? createMetricField(field, ctx) : {}),
        floatingFilter: true,
        ...createFilter(field),
        ...kpiConfig(field, ctx),
    });

const createDimensionField = (
    field: DataModelField,
    ctx: ColumnDefContext
): ColDef => ({
    enableRowGroup: true,
    ...(field.format === 'country' ? { cellRenderer: CountryComponent } : {}),
    ...(field.format === 'url' ? { cellRenderer: LinkComponent } : {}),

    enablePivot: !isNonGroupedDimension(field),

    valueFormatter: createDimensionFormatter(field, ctx),
    valueGetter: createValueGetter(field),
    comparator: createValueComparator(field),
});

export const getDefaultAggFunc = (field: DataModelField) =>
    match(field.agg?.type)
        .with('sum', () => 'sum')
        .with('weighted_avg', () => 'avg')
        .otherwise(() => undefined);

const createMetricField = (
    field: DataModelField,
    ctx: ColumnDefContext
): ColDef => {
    const defaultAggFunc = getDefaultAggFunc(field);

    return {
        cellDataType: 'number',
        type: 'numericColumn',
        defaultAggFunc,
        allowedAggFuncs: defaultAggFunc ? [defaultAggFunc] : undefined,
        enableValue: true,
        valueFormatter: metricFormatter(field, ctx),
        useValueFormatterForExport: false,
    };
};

const createDimensionFormatter = (
    field: DataModelField,
    ctx: ColumnDefContext
): ValueFormatterFunc | undefined => {
    return match(field.format)
        .with('boolean', () => booleanFormatter)
        .with('date', () => dateFormatter(ctx.locale.dateFormat))
        .with('weekday', () => weekdayFormatter)
        .with('month', () => monthFormatter)
        .otherwise(() => undefined);
};

const createValueGetter = (
    field: DataModelField
): ValueGetterFunc | undefined =>
    match(field.format)
        .with('weekday', () => weekdayGetter)
        .with('month', () => monthGetter)
        .otherwise(() => undefined);

const createValueComparator = (field: DataModelField) =>
    match(field.format)
        .with('weekday', () => weekdayComparator)
        .with('month', () => monthComparator)
        .otherwise(() => undefined);

const booleanFormatter: ValueFormatterFunc = ({ value }) =>
    typeof value === 'boolean' ? (value ? 'Yes' : 'No') : '';
const weekdayFormatter: ValueFormatterFunc = ({ value }) => weekdays[value];
const monthFormatter: ValueFormatterFunc = ({ value }) => months[value];
const metricFormatter =
    (field: DataModelField, ctx: ColumnDefContext): ValueFormatterFunc =>
    ({ value }: ValueFormatterParams) =>
        formatNumber(value, field.format, ctx.locale, { nan_text: '' });

export const createFilter = (field: DataModelField) =>
    match(field.format)
        .with('string', 'number_text', 'country', () => dimensionFilter(field))
        .with('number', 'percent', 'currency', () => metricFilter())
        .with('boolean', () => booleanFilter())
        .with('month', () => monthFilter())
        .with('weekday', () => weekdayFilter())
        .otherwise(() => ({}));

const dimensionFilter = (field: DataModelField) =>
    match(field)
        .when(isGroupedDimension, () => ({
            filter: 'agMultiColumnFilter',
            filterParams: {
                filters: [
                    textFilter(),
                    ...(isSetFilterDisabled(field) ? [] : [setFilter(field)]),
                ],
            },
        }))
        .otherwise(() => textFilter());

const textFilter = () => ({
    filter: 'agTextColumnFilter',
    filterParams: {
        defaultOption: 'contains',
        debounceMs: 120,
    } satisfies ITextFilterParams,
});

const setFilter = (field: DataModelField) => ({
    filter: 'agSetColumnFilter',
    filterParams: {
        applyMiniFilterWhileTyping: true,
        debounceMs: 32,
        suppressSorting: true,
        values: ({ context: dataSource, success }) => {
            dataSource
                .distinctValues(field)
                .then((result: string[]) => success(result));
        },
    } satisfies ISetFilterParams,
});

const booleanFilter = () => ({
    filter: 'agSetColumnFilter',
    filterParams: {
        applyMiniFilterWhileTyping: true,
        suppressMiniFilter: true,
        keyCreator: ({ value }) => {
            return value === null ? '' : value === 'true' ? 'true' : 'false';
        },
        valueFormatter: ({ value }) =>
            value === 'true' ? 'Yes' : value === 'false' ? 'No' : '(blank)',
        values: [null, 'true', 'false'],
    } satisfies ISetFilterParams,
});

const monthFilter = () => ({
    filter: 'agSetColumnFilter',
    filterParams: {
        applyMiniFilterWhileTyping: true,
        keyCreator: ({ value }) => {
            return value;
        },
        valueFormatter: ({ value }) => months[value],
        values: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
        suppressSorting: true,
    } satisfies ISetFilterParams,
});

const weekdayFilter = () => ({
    filter: 'agSetColumnFilter',
    filterParams: {
        applyMiniFilterWhileTyping: true,
        keyCreator: ({ value }) => {
            return value;
        },
        valueFormatter: ({ value }) => weekdays[value],
        values: ['0', '1', '2', '3', '4', '5', '6'],
        suppressSorting: true,
    } satisfies ISetFilterParams,
});

const metricFilter = () => ({
    filter: 'agNumberColumnFilter',
    filterParams: {
        defaultOption: 'greaterThan',
        debounceMs: 120,
        filterOptions: [
            'equals',
            'notEqual',
            'greaterThan',
            'greaterThanOrEqual',
            'lessThan',
            'lessThanOrEqual',
            'inRange',
        ],
    } satisfies INumberFilterParams,
});

const kpiConfig = (
    field: DataModelField,
    ctx: ColumnDefContext
): Partial<ColDef> => {
    const invert = isNegativeMetric(field) ?? false;
    const isPercentage = field.format === 'percent';

    return (
        Maybe.fromNullable(ctx.kpiData[field.id])
            // @ts-expect-error parseFloat also accepts non-string values.
            .map(parseFloat)
            .filter(threshold => !isNaN(threshold))
            .map(threshold => (isPercentage ? threshold / 100 : threshold))
            .map(
                (n): ColDef => ({
                    cellStyle: ({ value }) => ({
                        color: (invert ? value <= n : value >= n)
                            ? 'green'
                            : 'red',
                    }),
                    headerComponentParams: {
                        template: `<div class='ag-cell-label-container' role='presentation'>
                  <span ref='eMenu' class='ag-header-icon ag-header-cell-menu-button'></span>
                  <span ref='eFilterButton' class='ag-header-icon ag-header-cell-filter-button'></span>
                  <div ref='eLabel' class='ag-header-cell-label' role='presentation'>
                    <span ref='eSortOrder' class='ag-header-icon ag-sort-order'></span>
                    <span ref='eSortAsc' class='ag-header-icon ag-sort-ascending-icon'></span>
                    <span ref='eSortDesc' class='ag-header-icon ag-sort-descending-icon'></span>
                    <span ref='eSortNone' class='ag-header-icon ag-sort-none-icon'></span>
                    <span style='display: flex; flex-direction: column'>
                        <span ref='eText' class='ag-header-cell-text' role='columnheader'></span>
                        <span style='font-weight: normal; font-style: italic'>Baseline:&nbsp;${formatNumber(
                            ctx.kpiData[field.id],
                            field.format,
                            ctx.locale
                        )}</span>
                    </span>
                    <span ref='eFilter' class='ag-header-icon ag-filter-icon'></span>
                  </div>
                </div>`,
                    },
                })
            )
            .orDefault({})
    );
};
