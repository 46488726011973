import { Alert, AlertProps, Container } from '@mui/material';
import Card from '@mui/material/Card';

export const AlertCard = ({ children, ...props }: AlertProps) => (
    <Container sx={{ my: 4 }}>
        <Card>
            <Alert {...props}>{children}</Alert>
        </Card>
    </Container>
);
