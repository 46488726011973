import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { formatNumber, NumberFormat } from '../../../../../utils/number-format';
import { useLocale } from '../../../../../providers/LocaleProvider.hooks';
import { useTheme } from '@mui/material';

interface PerformanceIndicatorProps {
    period: number | undefined;
    moreIsBetter: boolean;
}

export const PerformanceIndicator = ({
    period,
    moreIsBetter,
}: PerformanceIndicatorProps) => {
    return (
        <table
            style={{ fontSize: '0.875rem', width: '100%', marginTop: '1rem' }}
        >
            <tbody>
                <PerformanceIndicatorRow
                    percentageChange={period}
                    highlighted={false}
                    moreIsBetter={moreIsBetter}
                />
            </tbody>
        </table>
    );
};

interface PerformanceIndicatorRowProps {
    highlighted: boolean;
    percentageChange: number | undefined;
    moreIsBetter: boolean;
}

const PerformanceIndicatorRow = ({
    highlighted,
    percentageChange,
    moreIsBetter,
}: PerformanceIndicatorRowProps) => {
    const locale = useLocale();
    const theme = useTheme();
    const color = highlighted
        ? theme.text.primary
        : typeof percentageChange === 'undefined' ||
          Math.abs(percentageChange) < 0.005
        ? theme.text.primaryLight
        : (moreIsBetter ? percentageChange < 0 : percentageChange > 0)
        ? theme.palette.error.main
        : theme.palette.success.dark;

    return (
        <tr>
            <td style={{ color }}>
                <Indicator percentageChange={percentageChange} />
            </td>
            <td style={{ textAlign: 'right', color, lineHeight: '1.25rem' }}>
                {formatNumber(
                    percentageChange,
                    NumberFormat.SignedPercentage,
                    locale
                )}
            </td>
            <td style={{ width: '100%' }}></td>
        </tr>
    );
};
type IndicatorProps = { percentageChange: number | undefined };
const Indicator = ({ percentageChange }: IndicatorProps) => {
    if (
        typeof percentageChange === 'undefined' ||
        Math.abs(percentageChange) < 0.005
    ) {
        return <></>;
    }

    const Indicator = percentageChange > 0 ? ArrowUpward : ArrowDownward;
    return <Indicator fontSize={'small'} style={{ verticalAlign: 'middle' }} />;
};
