import { useState } from 'react';
import { useInterval } from 'usehooks-ts';

export const useIntervalRender = (intervalMs: number) => {
    const [count, setCount] = useState(0);

    useInterval(() => setCount(current => current + 1), intervalMs);

    return count;
};
