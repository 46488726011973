/**
 * | increase | invert | color |
 * |---------------------------|
 * | true     | false  | green |
 * | false    | false  | red   |
 * | true     | true   | red   |
 * | false    | true   | green |
 */
export const metricColor = (
    increase: boolean,
    invert: boolean
): 'red' | 'green' => (increase === invert ? 'red' : 'green');
