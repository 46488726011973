import { SetState, StateCreator } from 'zustand';

export interface ViewStore {
    dashboardMessage: string;
    firstDayOfWeek: Day;
    setFirstDayOfWeek: (dayIndex: Day) => void;
}

const initialFirstDayOfTheWeek = parseInt(
    window.localStorage.getItem('first_day_of_week') || '1'
);

export const viewStore: StateCreator<ViewStore> = (
    set: SetState<ViewStore>
) => ({
    dashboardMessage: '',
    firstDayOfWeek:
        initialFirstDayOfTheWeek >= 0 && initialFirstDayOfTheWeek <= 6
            ? (initialFirstDayOfTheWeek as Day)
            : 1,
    setFirstDayOfWeek: (dayIndex: Day) => {
        set({ firstDayOfWeek: dayIndex });
    },
});
