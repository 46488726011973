import { TabContext, TabList, TabPanel } from '@mui/lab';
import { styled, Stack, Tab } from '@mui/material';
import { useDateRangePicker } from './useDateRangePicker.tsx';
import { Presets } from './Presets.tsx';
import { DateFields } from './DateFields.tsx';

export const Controls = () => {
    const {
        dispatch,
        currentDateRange,
        state: { currentPeriod, dateRanges },
        allowCompare,
    } = useDateRangePicker();

    return (
        <ControlsStack>
            {allowCompare ? (
                <TabContext value={currentPeriod}>
                    <StyledTabList
                        onChange={(event, value) =>
                            dispatch({
                                type: 'selectPeriod',
                                period: value,
                            })
                        }
                    >
                        <Tab label="Date range" value="main" />
                        <Tab label="Comparison" value="compare" />
                    </StyledTabList>
                    <StyledTabPanel
                        value="main"
                        hidden={currentPeriod !== 'main'}
                    >
                        <Presets
                            periodType={currentPeriod}
                            current={currentDateRange.preset}
                        />
                        <DateFields />
                    </StyledTabPanel>
                    <StyledTabPanel
                        value="compare"
                        hidden={currentPeriod !== 'compare'}
                    >
                        <Presets
                            periodType={currentPeriod}
                            current={currentDateRange.preset}
                        />
                        {currentDateRange.preset !== 'disabled' && (
                            <DateFields />
                        )}
                    </StyledTabPanel>
                </TabContext>
            ) : (
                <Stack
                    sx={{
                        flexDirection: 'column',
                        height: '100%',
                    }}
                >
                    <Presets
                        periodType="main"
                        current={dateRanges.main.preset}
                    />
                    <DateFields />
                </Stack>
            )}
        </ControlsStack>
    );
};

const ControlsStack = styled(Stack)(({ theme }) => ({
    background: theme.palette.background.default,
    [theme.breakpoints.up('sm')]: {
        borderRight: `1px solid ${theme.border.light}`,
    },
    height: '100%',
}));

const StyledTabList = styled(TabList)(({ theme }) => ({
    borderBottom: `1px solid ${theme.border.medium}`,

    '& .MuiTab-root': {
        flex: 1,
        fontSize: '12px',
    },
}));

const StyledTabPanel = styled(TabPanel)(({ hidden }) => {
    return {
        padding: 0,
        display: hidden ? 'none' : 'flex',
        flexDirection: 'column',
        height: '100%',
    };
});
