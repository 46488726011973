export const routes: { path: string; rewriteTo?: string }[] = [
    {
        path: '/workspaces/:workspaceId/explorer/*',
        rewriteTo: '/workspaces/:workspaceId/explorer',
    },
    {
        path: '/workspaces/:workspaceId/dashboard/*',
        rewriteTo: '/workspaces/:workspaceId/dashboard',
    },
    {
        path: '/workspaces/:workspaceId/targets/*',
        rewriteTo: '/workspaces/:workspaceId/targets',
    },
    { path: '/workspaces/:workspaceId/*' },
    { path: '/workspaces/:workspaceId' },
];
