import Typography from '@mui/material/Typography';
import { Stack, Tooltip, useTheme } from '@mui/material';
import React from 'react';
import { Target } from '../../types/targets';
import { formatNumber, NumberFormat } from '../../utils/number-format.ts';
import { useLocale } from '../../providers/LocaleProvider.hooks.ts';
import { DataModelField } from '../../types/datamodel/schema.ts';
import { TargetData } from './useTargetData.ts';
import { getPercentageDifference } from '../../utils/get-percentage-difference.ts';

export function ProgressLegend({
    isLoading,
    currentValue,
    target,
    dataModelField,
}: {
    isLoading: boolean;
    currentValue?: TargetData;
    target: Target;
    dataModelField?: DataModelField;
}) {
    const theme = useTheme();
    const locale = useLocale();

    if (isLoading) {
        return;
    }

    const format =
        dataModelField?.format === 'number'
            ? NumberFormat.Generic
            : dataModelField?.format;

    return (
        <Stack>
            <Stack direction="row" justifyContent="space-between">
                <Tooltip
                    title={`Expected: ${
                        currentValue && dataModelField
                            ? formatNumber(
                                  currentValue.expectedValue,
                                  format,
                                  locale,
                                  { mode: 'compact' }
                              )
                            : '-'
                    }`}
                >
                    <Stack
                        direction="row"
                        gap={0.4}
                        borderBottom={`1px dotted ${theme.border.dark}`}
                    >
                        <Typography variant="smallMediumBody">
                            {currentValue && dataModelField
                                ? formatNumber(
                                      currentValue.cumulativeValue,
                                      format,
                                      locale,
                                      { mode: 'compact' }
                                  )
                                : '-'}
                        </Typography>
                        {currentValue && (
                            <Typography
                                variant="smallMediumBody"
                                color={
                                    currentValue.cumulativeValue >=
                                    currentValue.expectedValue
                                        ? theme.palette.success.main
                                        : theme.palette.error.main
                                }
                            >
                                (
                                {formatNumber(
                                    getPercentageDifference(
                                        currentValue.expectedValue,
                                        currentValue.cumulativeValue
                                    ),
                                    NumberFormat.SignedPercentage,
                                    locale
                                )}
                                )
                            </Typography>
                        )}
                    </Stack>
                </Tooltip>

                <Typography variant="smallMediumBody">
                    {dataModelField
                        ? formatNumber(target.target, format, locale, {
                              mode: 'compact',
                          })
                        : '-'}
                </Typography>
            </Stack>
        </Stack>
    );
}
