import { Stack } from '@mui/material';
import { ProgressBar } from './ProgressBar.tsx';
import { Target } from '../../types/targets';
import React, { useState } from 'react';
import { ProgressLegend } from './ProgressLegend.tsx';
import { ProgressLine } from './ProgressLine.tsx';
import { useDatasetSchema } from '../../hooks/use-dataset-schema.ts';
import { StyledTargetCard } from './TargetCard.styled.tsx';
import {
    DataModelField,
    isTargetMetric,
} from '../../types/datamodel/schema.ts';
import { TargetCardSkeleton } from './TargetCardSkeleton.tsx';
import { ErrorBoundary } from '@sentry/react';
import { CardWithError } from './CardWithError.tsx';
import { useTargetMutation } from './useTargetMutation.ts';
import ConfirmDialog from '../../components/ConfirmDialog/ConfirmAlert.tsx';
import { TargetData, useTargetData } from './useTargetData.ts';
import Typography from '@mui/material/Typography';
import { shortDateRangeFormat } from '../../utils/date-format.ts';
import { formatNumber, NumberFormat } from '../../utils/number-format.ts';
import { useLocale } from '../../providers/LocaleProvider.hooks.ts';
import { Localization } from '../../types/datamodel/localization.ts';

const renderPercentageChange = (
    target: Target,
    locale: Localization,
    lastValue?: TargetData
) => {
    if (target.target <= 0) {
        return 'N/A';
    }

    if (!lastValue) {
        return 'N/A';
    }

    const percentageChange = (lastValue?.cumulativeValue ?? 0) / target.target;
    if (percentageChange < 0 && percentageChange > 1000) {
        return 'N/A';
    }

    return formatNumber(percentageChange, NumberFormat.Percentage, locale);
};

function TargetCardContent({
    target,
    onEdit,
    onClick,
    onRemove,
    onRemoveFromDashboard,
    isLoading,
    dataModelField,
}: {
    target: Target;
    onEdit?: () => void;
    onClick?: () => void;
    onRemove?: () => void;
    onRemoveFromDashboard?: () => void;
    isLoading: boolean;
    dataModelField?: DataModelField;
}) {
    const { data, firstValue, lastValue } = useTargetData(
        target,
        !dataModelField
    );
    const locale = useLocale();

    const percentage = renderPercentageChange(target, locale, lastValue);

    return (
        <ErrorBoundary
            fallback={<CardWithError error="Something went wrong" />}
        >
            <StyledTargetCard
                title={target.title || 'Your title here'}
                subheader={
                    dataModelField?.agg?.type === 'weighted_avg' ? (
                        <>&nbsp;</>
                    ) : (
                        percentage
                    )
                }
                onEdit={onEdit}
                onClick={onClick}
                onRemove={onRemove}
                onRemoveFromDashboard={onRemoveFromDashboard}
            >
                <ProgressLegend
                    isLoading={isLoading}
                    currentValue={lastValue}
                    dataModelField={dataModelField}
                    target={target}
                />

                <Stack height={40} marginBlock={1}>
                    {target.visualization === 'bar' && (
                        <ProgressBar lastValue={lastValue} target={target} />
                    )}

                    {target.visualization === 'line' && (
                        <ProgressLine
                            firstValue={firstValue}
                            lastValue={lastValue}
                            target={target}
                            dataModelField={dataModelField}
                            data={data}
                        />
                    )}
                </Stack>

                <Typography fontSize={12}>
                    {shortDateRangeFormat(target.dateStart, target.dateEnd)}
                </Typography>
            </StyledTargetCard>
        </ErrorBoundary>
    );
}

export function TargetCard({
    target,
    onEdit,
    onClick,
    onRemoveFromDashboard,
    isLoading,
    disableMenu,
}: {
    target: Target;
    isLoading: boolean;
    onEdit?: (id: string) => void;
    onClick?: (id: string) => void;
    onRemoveFromDashboard?: () => void;
    disableMenu?: boolean;
}) {
    const [removeDialogOpen, setRemoveDialog] = useState(false);
    const { data: schema, isLoading: isLoadingSchema } = useDatasetSchema(
        target.dataset
    );
    const dataModelField = schema?.fields?.find(f => f.id === target.metric);
    const { remove } = useTargetMutation();

    const handleOnRemove = async () => await remove(target.id);

    if (isLoadingSchema) {
        return <TargetCardSkeleton />;
    }

    if (dataModelField && !isTargetMetric(dataModelField)) {
        return (
            <CardWithError
                error={`Unsupported metric "${target.metric}"`}
                onRemove={handleOnRemove}
            />
        );
    }

    return (
        <>
            <TargetCardContent
                target={target}
                dataModelField={dataModelField}
                isLoading={isLoading}
                onRemove={
                    !disableMenu ? () => setRemoveDialog(true) : undefined
                }
                onRemoveFromDashboard={
                    !disableMenu && onRemoveFromDashboard
                        ? () => onRemoveFromDashboard?.()
                        : undefined
                }
                onEdit={!disableMenu ? () => onEdit?.(target.id) : undefined}
                onClick={!disableMenu ? () => onClick?.(target.id) : undefined}
            />
            <ConfirmDialog
                open={removeDialogOpen}
                onAnyDecision={() => setRemoveDialog(false)}
                onContinue={handleOnRemove}
                isDangerousAction={true}
                title={`Remove "${target.title}"`}
                content="Are you sure you want to remove this item? This will also remove it from other dashboards within the workspace. This action cannot be undone."
                continueText="Remove"
            />
        </>
    );
}
