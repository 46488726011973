import Typography from '@mui/material/Typography';
import { AlertRuleConfig } from '../../types/alerting';
import { useDatasetSchema } from '../../hooks/use-dataset-schema';
import { findField } from '../../queries/schema';
import { match } from 'ts-pattern';
import { TypographyProps } from '@mui/material';
import { isOutlierOperator } from '../../queries/filters';
import { dimensionOperators } from '../../components/FilterBuilder/dimensionOperators.tsx';
import { metricOperators } from '../../components/FilterBuilder/metricOperators.tsx';
import { useLocale } from '../../providers/LocaleProvider.hooks.ts';
import { formatNumber } from '../../utils/number-format.ts';
import { formatDimension } from '../../utils/dimension-format.ts';

type AlertAsTextProps = {
    alert: AlertRuleConfig;
} & TypographyProps;

export const AlertAsText = ({ alert, ...props }: AlertAsTextProps) => {
    const locale = useLocale();
    const { data: schema } = useDatasetSchema(alert.dataset);
    if (!schema) {
        return null;
    }

    const find = findField(schema);
    const getLabel = (id: string | undefined) => find(id || '')?.name || '';
    const getAgg = (id: string | undefined) =>
        match(find(id || '')?.agg?.type)
            .with('sum', () => 'sum')
            .with('weighted_avg', () => 'average')
            .otherwise(() => '');

    return (
        <Typography {...props}>
            Send a notification when{' '}
            <span>
                {alert.conditions.map(({ member, operator, values }, index) => (
                    <span key={index}>
                        {index ? ' and ' : ''}
                        the {getAgg(member)} of the metric{' '}
                        <strong>{getLabel(member).toLowerCase()}</strong>{' '}
                        {metricOperators[operator].label.toLowerCase()}{' '}
                        {isOutlierOperator(operator) ? null : (
                            <strong>
                                {formatNumber(
                                    values[0],
                                    find(member)?.format,
                                    locale,
                                    { nan_text: '' }
                                )}
                            </strong>
                        )}
                    </span>
                ))}
            </span>
            {alert.dimension && (
                <span>
                    {' '}
                    for any{' '}
                    <strong>{getLabel(alert.dimension).toLowerCase()}</strong>
                </span>
            )}
            {` in the past `}
            <strong>
                {alert.duration} day{alert.duration > 1 ? 's' : ''}
            </strong>
            {alert.filters.length > 0 ? ` where ` : ''}
            <span>
                {alert.filters.map(
                    ({ member, operator, values }, filterIndex) => (
                        <span key={filterIndex}>
                            {filterIndex ? ' and ' : ''}
                            {['set', 'notSet'].includes(operator) ? (
                                <span>
                                    the dimension{' '}
                                    <strong>{getLabel(member)}</strong>{' '}
                                    {dimensionOperators[
                                        operator
                                    ].label.toLowerCase()}
                                </span>
                            ) : (
                                values.map((value, valueIndex) => (
                                    <span key={valueIndex}>
                                        {valueIndex ? ' or ' : ''}

                                        {valueIndex === 0 && (
                                            <span>
                                                the dimension{' '}
                                                <strong>
                                                    {getLabel(member)}
                                                </strong>{' '}
                                                {dimensionOperators[
                                                    operator
                                                ].label.toLowerCase()}{' '}
                                            </span>
                                        )}
                                        <strong>
                                            {formatDimension(
                                                value,
                                                find(member)?.format
                                            )}
                                        </strong>
                                    </span>
                                ))
                            )}
                        </span>
                    )
                )}
            </span>
            .
        </Typography>
    );
};
