import { BinaryFilter } from '../../queries/filters';
import { ulid } from 'ulid';
import { FormatType } from '../datamodel/schema';

export interface AlertRule {
    id: string;
    workspaceId: string;
    title: string;
    dataset: string;
    filters: BinaryFilter[];
    conditions: BinaryFilter[];
    dimension?: string | null;
    duration: number;
    recipients: string[];
    webhookUrl?: string;
    message: string;
    enabled: boolean;
    createdAt?: number;
    modifiedAt?: number;
}

export type AlertRuleConfig = Pick<
    AlertRule,
    'dataset' | 'filters' | 'conditions' | 'dimension' | 'duration'
>;

export type AlertRuleWithViolations = AlertRule & {
    violations: AlertRuleViolation[];
};

export const newAlertRule = (
    workspaceId: string,
    email: string | undefined
) => ({
    id: ulid(),
    workspaceId,
    title: 'New alert',
    dataset: 'campaign_metrics_by_send_date',
    filters: [],
    conditions: [],
    duration: 1,
    recipients: email ? [email] : [],
    message: '',
    enabled: true,
});

export interface AlertRuleViolation {
    ruleId: string;
    status: 'open' | 'closed';
    createdAt: string;
    closedAt?: string;
}

export interface AlertRuleResults {
    conditions: AlertRuleCondition[];
    dimension: AlertRuleDimension | null;
    results: AlertRuleResult[];
}

interface AlertRuleCondition {
    name: string;
    value: number;
    format: FormatType;
}

interface AlertRuleDimension {
    id: string;
    name: string;
    format: FormatType;
}

interface AlertRuleResult {
    rangeStart: string;
    rangeEnd: string;
    result: boolean;
    checkValues: number[];
    checkResults: boolean[];
    details: AlertRuleResultDetails[];
}

interface AlertRuleResultDetails {
    dimension: string | null;
    result: boolean | null;
    metrics: (number | null)[];
    results: (boolean | null)[];
}
