import { useActiveWorkspace } from '../../providers/WorkspaceProvider.hooks.ts';
import { useMatch, useNavigate } from 'react-router-dom';
import { Menu } from '../../components/Menu/Menu.tsx';
import {
    Apps,
    Link as LinkIcon,
    People,
    Settings as SettingsIcon,
} from '@mui/icons-material';
import * as React from 'react';

const useIsMatch = (path: string) => Boolean(useMatch(path));

export const SideNav = () => {
    const { workspace } = useActiveWorkspace();
    const navigate = useNavigate();

    const navigateTo = (path: string) =>
        navigate(`/workspaces/${workspace.id}/settings${path}`);

    const onAgencyRoute = useIsMatch(
        '/workspaces/:workspaceId/settings/agency'
    );

    return (
        <Menu
            sections={[
                {
                    id: 'workspace-items',
                    tabs: [
                        {
                            id: 'general',
                            primary: 'General',
                            selected: useIsMatch(
                                '/workspaces/:workspaceId/settings/general'
                            ),
                            icon: <SettingsIcon fontSize="small" />,
                            onClick: () => navigateTo('/general'),
                        },
                        {
                            id: 'members',
                            primary: 'Members',
                            selected: useIsMatch(
                                '/workspaces/:workspaceId/settings/members'
                            ),
                            icon: <People fontSize="small" />,
                            onClick: () => navigateTo('/members'),
                        },
                        {
                            id: 'integrations',
                            primary: 'Integrations',
                            selected: useIsMatch(
                                '/workspaces/:workspaceId/settings/integrations/*'
                            ),
                            icon: <LinkIcon fontSize="small" />,
                            onClick: () => navigateTo('/integrations'),
                        },
                        ...(workspace.licenseType === 'agency' &&
                        workspace.licenseHolder
                            ? [
                                  {
                                      id: 'agency',
                                      primary: workspace.licenseHolder,
                                      icon: <Apps fontSize="small" />,
                                      selected: onAgencyRoute,
                                      onClick: () => navigateTo('/agency'),
                                  },
                              ]
                            : []),
                    ],
                },
            ]}
        />
    );
};
