import SeinoSelect, { components, Props } from 'react-select';
import { useSelectStyle } from './utils/select-style';
import styled from 'styled-components';
import { useTheme } from '@mui/material';

const Label = styled.p`
    font-weight: 300;
    margin-bottom: 0;
    min-height: 27px;
    font-size: 0.8rem;
`;

type SelectProps<
    T extends { label: string; value: string } = {
        label: string;
        value: string;
    }
> = {
    testId?: string;
    size?: 'small' | 'medium' | 'large';
    border?: boolean;
    dark?: boolean;
    minWidth?: string | number;
} & Props<T>;

const DropdownIndicator = (props: any) => {
    const theme = useTheme();
    return (
        <components.DropdownIndicator {...props}>
            <svg
                width="10"
                height="5"
                viewBox="0 0 10 5"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M0 0L5 5L10 0H0Z"
                    fill={props.selectProps.dark ? theme.white : theme.black}
                />
            </svg>
        </components.DropdownIndicator>
    );
};

export const Select = <T extends { label: string; value: string }>(
    props: SelectProps<T>
) => {
    const {
        size = 'medium',
        label,
        border = true,
        onChange,
        options,
        dark,
        defaultValue,
        isLoading,
    } = props;

    const CustomLabel = () => (label ? <Label>{label}</Label> : <></>);

    return (
        <div style={{ minWidth: props.minWidth }} data-testid={props.testId}>
            <CustomLabel />
            <SeinoSelect
                {...props}
                defaultValue={defaultValue}
                onChange={onChange}
                isLoading={isLoading}
                options={options}
                components={{ DropdownIndicator, ...props.components }}
                styles={useSelectStyle(size, dark, border)}
            />
        </div>
    );
};
