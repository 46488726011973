import { Annotation, Severity } from '../types/annotation';
import { format } from 'date-fns';
import axios from 'axios';
import { getRustAPIUrl } from '../utils/api.ts';
import { parseDate } from '../utils/date-format.ts';

type AnnotationResponse = {
    id: string;
    workspace_id: string;
    severity: Severity;
    message: string;
    date_start: string;
    date_end: string;
    created_at: number;
    created_by_id: string;
    created_by_name: string;
    created_by_email: string;
    modified_at: number;
    modified_by_id?: string;
    modified_by_name?: string;
    modified_by_email?: string;
};
export const getAnnotations = async (
    workspaceId: string
): Promise<Annotation[]> => {
    const data = await axios.get<{ data: AnnotationResponse[] }>(
        `${getRustAPIUrl()}/workspaces/${workspaceId}/annotations`
    );

    return data.data.data.map(response => ({
        id: response.id,
        workspaceId: response.workspace_id,
        severity: response.severity,
        message: response.message,
        dateStart: parseDate(response.date_start),
        dateEnd: parseDate(response.date_end),
        createdAt: response.created_at,
        modifiedAt: response.modified_at,
        createdBy: {
            id: response.created_by_id,
            name: response.created_by_name,
            email: response.created_by_email,
        },
        modifiedBy: {
            id: response.created_by_id,
            name: response.created_by_name,
            email: response.created_by_email,
        },
    }));
};

export const putAnnotation = async (
    workspaceId: string,
    annotation: Annotation
) => {
    return await axios.put<Annotation>(
        `${getRustAPIUrl()}/workspaces/${workspaceId}/annotations/${
            annotation.id
        }`,
        {
            id: annotation.id,
            workspace_id: annotation.workspaceId,
            severity: annotation.severity,
            message: annotation.message,
            date_start: format(annotation.dateStart, 'yyyy-MM-dd'),
            date_end: format(annotation.dateEnd, 'yyyy-MM-dd'),
        }
    );
};

export const deleteAnnotation = async (
    workspaceId: string,
    annotationId: string
) => {
    return await axios.delete(
        `${getRustAPIUrl()}/workspaces/${workspaceId}/annotations/${annotationId}`
    );
};
