import {
    Bar,
    BarChart,
    ReferenceDot,
    ResponsiveContainer,
    XAxis,
    YAxis,
} from 'recharts';
import { darken, useTheme } from '@mui/material';
import React from 'react';
import { getColorPalette } from './getColorPalette.ts';
import { Target } from '../../types/targets';
import { TargetData } from './useTargetData.ts';

const getBarValues = (target: Target, lastValue: TargetData) => {
    const firstBar = () => {
        if (lastValue.percentage > 1) {
            return 100;
        }

        if (lastValue.cumulativeValue > target.target) {
            return Math.min(100, lastValue.percentage * 100);
        }

        if (lastValue.cumulativeValue < lastValue.expectedValue) {
            return lastValue.percentage * 100;
        }

        return Math.min(100, lastValue.percentage * 100);
    };

    const secondBar = () => {
        if (target.dateEnd < new Date()) {
            return 100 - lastValue.percentage * 100;
        }

        if (lastValue.cumulativeValue > target.target) {
            return lastValue.percentage * 100 - firstBar();
        }

        if (lastValue.percentage * 100 > 100) {
            return lastValue.percentage * 100 - firstBar();
        }

        if (lastValue.cumulativeValue < lastValue.expectedValue) {
            return (lastValue.expectedValue / target.target) * 100 - firstBar();
        }

        return (lastValue.cumulativeValue / target.target) * 100 - firstBar();
    };

    return {
        firstBar: Math.abs(firstBar()),
        secondBar: Math.abs(secondBar()),
    };
};

export function ProgressBar({
    target,
    lastValue,
}: {
    target: Target;
    lastValue?: TargetData;
}) {
    const theme = useTheme();

    if (!lastValue) {
        return null;
    }

    const colorPalette = getColorPalette(target, lastValue);
    const palette = theme.palette[colorPalette];
    const isTargetReached = lastValue.percentage * 100 > 100;

    const { firstBar, secondBar } = getBarValues(target, lastValue);

    return (
        <ResponsiveContainer>
            <BarChart
                data={[
                    {
                        name: 'progress',
                        firstBar,
                        secondBar,
                    },
                ]}
                margin={{ right: 10 }}
                barSize={15}
                layout="vertical"
            >
                <defs>
                    <filter
                        id="shadow"
                        colorInterpolationFilters="sRGB"
                        height="120%"
                        y="-10%"
                    >
                        <feDropShadow
                            dx="0"
                            dy="0"
                            stdDeviation="1.2"
                            floodOpacity="0.6"
                        />
                    </filter>
                    <pattern
                        id={`stripes-${colorPalette}-${target.id}`}
                        patternUnits="userSpaceOnUse"
                        width="3.5"
                        height="3.5"
                        patternTransform="rotate(45)"
                    >
                        <line
                            x1="0"
                            y="0"
                            x2="0"
                            y2="3.5"
                            stroke={palette.main}
                            strokeWidth="6"
                        />
                    </pattern>
                </defs>
                <XAxis
                    type="number"
                    domain={[
                        0,
                        Math.max(
                            100,
                            firstBar,
                            secondBar,
                            lastValue.percentage * 100
                        ),
                    ]}
                    hide
                />
                <YAxis type="category" dataKey="name" hide />
                <Bar
                    radius={[4, 0, 0, 4]}
                    stackId="progress"
                    dataKey="firstBar"
                    fill={darken(palette.main, 0.3)}
                    background={{ fill: palette.light, radius: 4 }}
                />

                <Bar
                    radius={[0, 4, 4, 0]}
                    stackId="progress"
                    dataKey="secondBar"
                    fill={`url(#stripes-${colorPalette}-${target.id})`}
                />

                <ReferenceDot
                    x={isTargetReached ? lastValue.percentage * 100 : firstBar}
                    y="progress"
                    strokeOpacity={2}
                    r={7}
                    strokeWidth={2}
                    stroke="white"
                    fill={palette.dark}
                />
            </BarChart>
        </ResponsiveContainer>
    );
}
