import { Stack, styled } from '@mui/material';

export const AutocompleteContainer = styled(Stack)(
    ({ theme: { border, palette } }) => ({
        position: 'relative',
        flexGrow: 1,
        alignItems: 'flex-start',
        flexWrap: 'nowrap',
        background: palette.background.paper,
        cursor: 'text',
        overflow: 'hidden',
        paddingInline: '6px',
        border: `1px solid ${border.medium}`,
        borderRadius: '5px',
    })
);
