import { useActiveWorkspace } from '../providers/WorkspaceProvider.hooks';
import { useDuckDatasetQuery } from './use-duck-dataset-query';

export const useCubeDimension = (dataset: string, dimension: string) => {
    const { workspaceId } = useActiveWorkspace();

    const duckDimension = dimension.replace(/^Campaigns\./, '');

    return useDuckDatasetQuery<{ dim: string }, string[]>({
        queryKey: ['duck', workspaceId, dataset, 'dimensions', dimension],
        dataset,
        queryFn: sourceTable =>
            `SELECT DISTINCT ${duckDimension}::string AS dim FROM ${sourceTable} WHERE ${duckDimension} IS NOT NULL ORDER BY ${duckDimension} LIMIT 10000`,
        queryOptions: { enabled: !!dimension },
        processResult: rows => rows.map(row => row.dim),
    });
};
