import { useDuckDatasetQuery } from './use-duck-dataset-query';

import { useActiveWorkspaceId } from '../providers/useActiveWorkspaceId.ts';

export type EngagementRow = {
    date: Date;
    segment_id: number;
    segment_name: string;
    size: number;
    days_since_last_click: number;
    click_total: number;
    send_total: number;
    click_rate: number;
    inflow: { toArray: () => FlowRow[] };
    outflow: { toArray: () => FlowRow[] };
};

type FlowRow = {
    segment_id: number;
    segment_name: string;
    size: number;
};

export const useEngagementData = () => {
    const workspaceId = useActiveWorkspaceId();
    return useDuckDatasetQuery<EngagementRow>({
        queryKey: ['engagement', workspaceId],
        dataset: 'engagement_000000000000',
        queryFn: sourceTable => {
            return `select date,
                           cast(segment_id as int32) as segment_id,
                           segment_name,
                           size,
                           days_since_last_click,
                           click_total,
                           send_total,
                           click_rate,
                           [{
                               segment_id: cast(x.segment_id as int32),
                               segment_name: x.segment_name,
                               size: cast(x.size as int32)
                            } for x in inflow] as inflow,
                           [{
                               segment_id: cast(x.segment_id as int32),
                               segment_name: x.segment_name,
                               size: cast(x.size as int32)
                            } for x in outflow] as outflow,
                      from ${sourceTable}
                  order by date desc, segment_id`;
        },
    });
};
