import { ErrorPage } from './ErrorPage';
import { ErrorBoundary } from '@sentry/react';
import { PropsWithChildren } from 'react';

export const SeinoErrorBoundary = ({ children }: PropsWithChildren) => {
    return (
        <ErrorBoundary
            fallback={({ resetError }) => <ErrorPage resetError={resetError} />}
        >
            {children}
        </ErrorBoundary>
    );
};
