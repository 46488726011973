import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { useActiveWorkspace } from '../../../providers/WorkspaceProvider.hooks.ts';
import { getRustAPIUrl } from '../../../utils/api.ts';
import { useNotify } from '../../../hooks/use-notify.tsx';

export const useDeleteWorkspace = () => {
    const queryClient = useQueryClient();
    const notify = useNotify();
    const { workspaceId } = useActiveWorkspace();

    return useMutation({
        mutationFn: () => {
            return axios.delete(`${getRustAPIUrl()}/workspaces/${workspaceId}`);
        },
        onError: () => notify('Failed to delete workspace', 'error'),
        onSuccess: () => {
            notify('Deleted workspace', 'success');
            return queryClient.invalidateQueries(['fetch-initial-data']);
        },
    });
};
