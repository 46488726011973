import { match, P } from 'ts-pattern';
import { Theme } from '@mui/material';

export const resultToColor = (
    result: boolean | undefined | null,
    theme: Theme
): string =>
    match(result)
        .with(P.nullish, () => theme.palette.grey['200'])
        .with(false, () => theme.palette.success.main)
        .with(true, () => theme.palette.error.main)
        .exhaustive();
