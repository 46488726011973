export interface Localization {
    locale: string;
    currency: string;
    currencySymbol: string;
    months: string[];
    dateFormat: string;
}

const monthLabels = [
    '',
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
];

export const amsterdamLocale = {
    locale: 'nl-NL',
    currency: 'EUR',
    currencySymbol: '€',
    months: monthLabels,
    dateFormat: 'd/M/yyyy',
};

export const londonLocale = {
    locale: 'en-GB',
    currency: 'GBP',
    currencySymbol: '£',
    months: monthLabels,
    dateFormat: 'd/M/yyyy',
};

export const usLocale = {
    locale: 'en-US',
    currency: 'USD',
    currencySymbol: '$',
    months: monthLabels,
    dateFormat: 'M/d/yyyy',
};
