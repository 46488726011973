import {
    AccessLevel,
    friendlyAccessLevel,
    Permission,
} from '../../../types/permission/permission.ts';
import { Columns, ColWidth } from '../../../components/Table/types.ts';
import { Box, Stack } from '@mui/material';
import { SubContent } from '../../../components/Table';
import { HighlightText } from '../../../components/Hightlight/Hightlight.tsx';
import { AccessLevelSelector } from './AccessLevelSelector.tsx';
import * as React from 'react';
import { Business } from '@mui/icons-material';

export const shareColumns = (
    currentUser: string,
    ownAccessLevel: AccessLevel,
    accessLevelOnChange: (
        permission: Permission,
        newAccessLevel: AccessLevel
    ) => void,
    currentUserIsFullAdmin: boolean
): Columns<Permission> => [
    {
        accessor: 'id',
        isVisible: false,
    },
    {
        accessor: 'subjectName',
        title: 'Name',
        render: (row, _, searchQuery) => {
            const isYou = row?.subjectId === currentUser;

            return (
                <Stack direction="column">
                    <Box>
                        {row.subjectType === 'workspace' && (
                            <Stack direction="row" alignItems="center" gap={1}>
                                <Business fontSize="small" />
                                <HighlightText
                                    text={`Everyone in ${row.subjectName}`}
                                    query={searchQuery}
                                />
                            </Stack>
                        )}

                        {row.subjectType === 'user' && (
                            <HighlightText
                                text={
                                    isYou
                                        ? `${row.subjectName} (You)`
                                        : row.subjectName
                                }
                                query={searchQuery}
                            />
                        )}
                    </Box>

                    {row.subjectEmail && (
                        <SubContent>
                            <HighlightText
                                text={row.subjectEmail}
                                query={searchQuery}
                            />
                        </SubContent>
                    )}
                </Stack>
            );
        },
    },
    {
        accessor: 'accessLevel',
        title: 'Access level',
        isClickable: false,
        align: 'right',
        width: ColWidth.Large,
        getValue: value => friendlyAccessLevel(value.accessLevel),
        render: row => {
            return (
                <AccessLevelSelector
                    key={`access-level-selector-${row.id}`}
                    value={row}
                    enabled={
                        ownAccessLevel === 'full_access' &&
                        currentUser !== row.subjectId &&
                        (row.subjectType !== 'workspace' ||
                            currentUserIsFullAdmin)
                    }
                    onRemove={() => accessLevelOnChange(row, 'none')}
                    onChange={newValue => accessLevelOnChange(row, newValue)}
                />
            );
        },
    },
];
