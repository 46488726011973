import { ResponsiveWaffle } from '@nivo/waffle';
import { formatNumber } from '../../utils/number-format';
import { useLocale } from '../../providers/LocaleProvider.hooks';
import { EngagementRow } from '../../hooks/use-engagement';
import { sumBy } from 'lodash';
import { getEngagementColor } from './segments';
import { useContext, useMemo } from 'react';
import { EngagementContext } from './EngagementContext';

interface EngagementWaffleProps {
    data: EngagementRow[];
}

export const EngagementWaffle = ({ data }: EngagementWaffleProps) => {
    const locale = useLocale();
    const filteredData = data;
    const { setSegment } = useContext(EngagementContext);

    const colors = useMemo(
        () => data.map(({ segment_id }) => getEngagementColor(segment_id)),
        [data]
    );

    return (
        <ResponsiveWaffle
            data={filteredData.map(d => ({
                id: d.segment_id,
                label: d.segment_name,
                value: d.size,
            }))}
            colors={colors}
            fillDirection="left"
            total={sumBy(filteredData, 'size')}
            tooltipFormat={value => formatNumber(value, 'number', locale)}
            rows={20}
            columns={60}
            onClick={(datum: any) => setSegment(datum.data.id)}
            padding={0}
            borderWidth={0.625}
            borderColor="white"
        />
    );
};
