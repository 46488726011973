import {
    useOptimisticRemove,
    useOptimisticUpdate,
} from './use-optimistic-update';
import { AlertRule } from '../types/alerting';
import { deleteAlertRule, putAlertRule } from '../api-client/alert-rules';
import { useSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';
import { useActiveWorkspaceId } from '../providers/useActiveWorkspaceId.ts';

export const useAlertRuleMutation = () => {
    const workspaceId = useActiveWorkspaceId();
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();

    const { create, isLoading: isUpdating } = useOptimisticUpdate<AlertRule>(
        async rule => {
            try {
                await putAlertRule(workspaceId, rule);
            } catch (err) {
                enqueueSnackbar('Failed to update alert', {
                    variant: 'error',
                });
                throw err;
            }
        },
        [workspaceId, 'alert-rules'],
        { invalidateOnSettled: true }
    );

    const { remove } = useOptimisticRemove(
        async alertRuleId => {
            await deleteAlertRule(workspaceId, alertRuleId);
        },
        [workspaceId, 'alert-rules']
    );

    return {
        create,
        update: async (alert: AlertRule) => {
            await create(alert);
            queryClient.invalidateQueries([
                workspaceId,
                'alert-rule-violations',
            ]);
            queryClient.resetQueries([
                workspaceId,
                'alert-rule-results',
                alert.id,
            ]);
        },
        isUpdating,
        remove,
    };
};
