import axios from 'axios';
import { getRustAPIUrl } from '../utils/api';
import {
    AlertRule,
    AlertRuleConfig,
    AlertRuleViolation,
} from '../types/alerting';

export const getAlertRules = async (workspaceId: string) => {
    const result = await axios.get<{ rules: AlertRule[] }>(
        `${getRustAPIUrl()}/workspaces/${workspaceId}/alerting/rules`
    );

    return result.data.rules;
};

export const getAlertRuleResults = async (
    workspaceId: string,
    ruleId: string
) => {
    const result = await axios.get(
        `${getRustAPIUrl()}/workspaces/${workspaceId}/alerting/rules/${ruleId}/results`
    );

    return result.data;
};

export const getAlertRulePreview = async (
    workspaceId: string,
    rule: AlertRuleConfig
) => {
    const result = await axios.put(
        `${getRustAPIUrl()}/workspaces/${workspaceId}/alerting/rules/preview`,
        rule
    );

    return result.data;
};

export const putAlertRule = async (
    workspaceId: string,
    alertRule: AlertRule
) => {
    return await axios.put<AlertRule>(
        `${getRustAPIUrl()}/workspaces/${workspaceId}/alerting/rules/${
            alertRule.id
        }`,
        alertRule
    );
};

export const deleteAlertRule = async (
    workspaceId: string,
    alertRuleId: string
) => {
    return await axios.delete(
        `${getRustAPIUrl()}/workspaces/${workspaceId}/alerting/rules/${alertRuleId}`
    );
};

export const getAlertRuleViolations = async (workspaceId: string) => {
    const result = await axios.get<{ violations: AlertRuleViolation[] }>(
        `${getRustAPIUrl()}/workspaces/${workspaceId}/alerting/violations`
    );

    return result.data.violations;
};
