// @ts-expect-error todo
import { yupResolver } from '@hookform/resolvers/yup';
import React, { CSSProperties, useCallback } from 'react';
import {
    DeepPartial,
    FormProvider,
    SubmitHandler,
    useForm,
} from 'react-hook-form';
import * as yup from 'yup';

interface FormProps<FormData extends object> {
    onSubmit?: SubmitHandler<FormData>;
    defaultValues?: DeepPartial<FormData>;
    validationSchema?: yup.SchemaOf<FormData>;
}

type Props<FormData extends object> = React.PropsWithChildren<
    FormProps<FormData>
> & { style?: CSSProperties };

export const FormWrapper = <FormData extends object>({
    onSubmit,
    validationSchema,
    defaultValues,
    children,
    style,
}: Props<FormData>) => {
    const methods = useForm({
        // @ts-expect-error todo
        defaultValues: defaultValues,
        resolver: validationSchema ? yupResolver(validationSchema) : undefined,
    });

    const submitHandler: SubmitHandler<FormData> = useCallback(
        async data => {
            await onSubmit?.(data);
        },
        [onSubmit]
    );

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(submitHandler)} style={style}>
                {children}
            </form>
        </FormProvider>
    );
};
