import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { styled, Chip } from '@mui/material';

export function SortableChip(props: {
    value: string;
    label: string;
    id: string | number;
    disabled?: boolean;
    onDelete: (item: string) => void;
}) {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({ id: props.value, disabled: props.disabled });

    const StyledChip = styled(Chip)({
        cursor: 'grab',
        '&.MuiChip-root': {
            backgroundColor: isDragging ? 'gray' : 'white',
            zIndex: isDragging ? 99999 : 1,
        },
    });

    const style = {
        transform: CSS.Translate.toString(transform),
        transition,
    };

    return (
        <div ref={setNodeRef} style={{ padding: '2px' }}>
            <StyledChip
                style={style}
                {...listeners}
                {...attributes}
                onPointerDown={(e: React.PointerEvent<HTMLDivElement>) => {
                    if ((e.target as HTMLElement).nodeName === 'SPAN') {
                        listeners?.onPointerDown(e);
                    }
                }}
                label={props.label}
                onDelete={() => props.onDelete(props.value)}
                variant="outlined"
                size="small"
            />
        </div>
    );
}
