import { useMemo } from 'react';

import { Order, sortItemsBy, sortItemsByKey } from './sorting';
import { Columns } from './types';

export const useSortedItems = <T extends object>(
    items: T[],
    orderBy: keyof T,
    order: Order,
    columns: Columns<T>,
    orderByFunction?: (a: T, b: T) => number
) =>
    useMemo(() => {
        if (orderByFunction) {
            return items.sort(orderByFunction);
        }

        const selectedColumn = columns.find(col => col.accessor === orderBy);
        let sortedItems = items;

        if (selectedColumn) {
            if (selectedColumn.secondarySort) {
                sortedItems = sortItemsByKey(
                    items,
                    selectedColumn.secondarySort.key,
                    selectedColumn.secondarySort.reverseOrder
                        ? order === 'desc'
                            ? 'asc'
                            : 'desc'
                        : order
                );
            }

            if (selectedColumn.getSortValue) {
                return sortItemsBy(
                    sortedItems,
                    selectedColumn.getSortValue,
                    order
                );
            }
        }
        return sortItemsByKey(sortedItems, orderBy, order);
    }, [items, orderBy, orderByFunction, order, columns]);
