import { format, isToday } from 'date-fns';
import { DateRanges } from './reducer.tsx';
import { normalizeInterval } from './dateHelpers.ts';
import { parseDate } from '../../utils/date-format.ts';

export const serializeDateRanges = ({
    main,
    compare,
}: DateRanges): DateRanges<string> => ({
    main: {
        start: format(main.start, 'yyyy-MM-dd'),
        end: isToday(main.end) ? 'today' : format(main.end, 'yyyy-MM-dd'),
        preset: main.preset,
    },
    compare: {
        start: format(compare.start, 'yyyy-MM-dd'),
        end: format(compare.end, 'yyyy-MM-dd'),
        preset: compare.preset,
    },
});

export const deserializeDateRanges = ({
    main,
    compare,
}: DateRanges<string>): DateRanges => ({
    main: normalizeInterval({
        start: parseDate(main.start),
        end: parseDate(main.end),
        preset: main.preset,
    }),
    compare: normalizeInterval({
        start: parseDate(compare.start),
        end: parseDate(compare.end),
        preset: compare.preset,
    }),
});
