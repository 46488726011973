import { DateRanges, DateRangeWithPreset } from './reducer.tsx';
import { match } from 'ts-pattern';
import {
    lastDays,
    lastMonth,
    lastQuarter,
    lastWeek,
    lastYear,
    precedingPeriod,
    yearOverYear,
    thisMonth,
    thisQuarter,
    thisWeek,
    thisYear,
} from './dateHelpers.ts';

export const mainPresets = [
    'this_week',
    'this_month',
    'this_quarter',
    'this_year',
    'last_week',
    'last_month',
    'last_quarter',
    'last_year',
    'last_7_days',
    'last_14_days',
    'last_28_days',
    'last_30_days',
    'last_90_days',
    'custom',
] as const;

export const comparePresets = [
    'disabled',
    'preceding_period',
    'year_over_year',
    'custom',
] as const;

export type MainPreset = (typeof mainPresets)[number];
export type ComparePreset = (typeof comparePresets)[number];
export type Preset = MainPreset | ComparePreset;

export const presetLabel = (preset: Preset) =>
    preset.charAt(0).toUpperCase() + preset.slice(1).replace(/_/g, ' ');

/**
 * Recalculate the date ranges based on their presets. Useful for restoring date
 * ranges that were stored on another date, so that for example "last week"
 * actually becomes last week again.
 */
export const recalculateDateRanges = (
    weekStartsOn: Day,
    dateRanges: DateRanges
): DateRanges => {
    const main = calculateMainRange(weekStartsOn, dateRanges.main);

    return {
        main,
        compare: calculateCompareRange(main, dateRanges.compare),
    };
};

export const calculateMainRange = (
    weekStartsOn: Day,
    range: DateRangeWithPreset<MainPreset>,
    preset?: MainPreset
): DateRangeWithPreset<MainPreset> =>
    match(preset ?? range.preset)
        .with('last_7_days', () => lastDays(7))
        .with('last_14_days', () => lastDays(14))
        .with('last_28_days', () => lastDays(28))
        .with('last_30_days', () => lastDays(30))
        .with('last_90_days', () => lastDays(90))
        .with('last_week', () => lastWeek(weekStartsOn))
        .with('last_month', () => lastMonth())
        .with('last_quarter', () => lastQuarter())
        .with('last_year', () => lastYear())
        .with('this_week', () => thisWeek(weekStartsOn))
        .with('this_month', () => thisMonth())
        .with('this_quarter', () => thisQuarter())
        .with('this_year', () => thisYear())
        .with('custom', () => ({ ...range, preset: 'custom' as const }))
        .exhaustive();

export const calculateCompareRange = (
    main: DateRangeWithPreset<MainPreset>,
    compare: DateRangeWithPreset<ComparePreset>,
    preset?: ComparePreset
): DateRangeWithPreset<ComparePreset> =>
    match(preset ?? compare.preset)
        .with('custom', () =>
            match(compare.preset)
                // When transitioning from the disabled to the custom preset,
                // we do not have a valid date range as a starting point, so we
                // default to the preceding period.
                .with('disabled', () => ({
                    ...precedingPeriod(main),
                    preset: 'custom' as const,
                }))
                .otherwise(() => ({ ...compare, preset: 'custom' as const }))
        )
        .with('preceding_period', () => precedingPeriod(main))
        .with('year_over_year', () => yearOverYear(main))
        .with('disabled', () => ({
            start: new Date('0001-01-01'),
            end: new Date('0001-01-01'),
            preset: 'disabled' as const,
        }))
        .exhaustive();
