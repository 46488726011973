import React, { useRef, useState } from 'react';
import {
    Button,
    ButtonGroup,
    Menu,
    MenuItem,
    MenuListProps,
} from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';
import { LoadingButton, LoadingButtonProps } from '@mui/lab';

type Option = {
    id: string;
    label: string;
    onClick?: () => void;
    disabled?: boolean;
};

type DropdownButtonProps = {
    options: Option[];
    buttonProps?: LoadingButtonProps;
    menuListProps?: MenuListProps;
};

export default function DropdownButton({
    options,
    buttonProps,
}: DropdownButtonProps) {
    const [open, setOpen] = useState(false);
    const anchorRef = useRef<HTMLButtonElement>(null);

    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };

    const handleClose = (event: Event) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }

        setOpen(false);
    };

    const handleClick = (onClick?: () => void) => () => {
        setOpen(false);
        onClick && onClick();
    };

    if (options.length === 1) {
        return (
            <LoadingButton
                type="submit"
                size="small"
                {...buttonProps}
                onClick={options[0].onClick}
            >
                {options[0].label}
            </LoadingButton>
        );
    }

    return (
        <>
            <ButtonGroup variant="contained" disableElevation={true}>
                <LoadingButton
                    type="submit"
                    size="small"
                    {...buttonProps}
                    onClick={options[0].onClick}
                >
                    {options[0].label}
                </LoadingButton>
                <Button
                    type="submit"
                    size="small"
                    disabled={!!buttonProps?.loading || buttonProps?.disabled}
                    onClick={handleToggle}
                    ref={anchorRef}
                >
                    <KeyboardArrowDown />
                </Button>
            </ButtonGroup>
            <Menu
                open={open}
                anchorEl={anchorRef.current}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                MenuListProps={{ dense: true }}
            >
                {options.map(({ id, label, onClick }) => (
                    <MenuItem key={id} onClick={handleClick(onClick)}>
                        {label}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
}
