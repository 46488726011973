import styled from 'styled-components';
import { Stack, TextField, Typography } from '@mui/material';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { isSimpleApiError, SimpleError } from '../../../../utils/api';
import { useState } from 'react';
import { useSetDeployteqCredentials } from './useSetDeployteqCredentials';
import { FormDialog } from '../../../../components/Dialog/FormDialog';

const StyledTextField = styled(TextField).attrs({
    variant: 'outlined',
    padding: '.5em',
    size: 'small',
})`
    font-size: 0.8em;
`;

const Subtitle = () => (
    <Typography paragraph={true} variant="body2">
        SEINō uses the Deployteq REST API to retrieve data about your campaigns.
        In order to access the API, we need the Client ID and a secret
        associated with your Deployteq account.
    </Typography>
);

interface IFormInput {
    clientId: string;
    clientSecret: string;
}

export const EnableDeployteq = ({
    workspaceId,
    integrationId,
    open = false,
    onEnable,
    onClose,
}: {
    workspaceId: string;
    integrationId: string;
    open: boolean;
    onEnable: () => void;
    onClose: () => void;
}) => {
    const [errorMessage, setErrorMessage] = useState<undefined | string>(
        undefined
    );
    const { mutate, isError, isLoading } = useSetDeployteqCredentials({
        onSuccess: () => onEnable(),
        onError: (error: Error | SimpleError) => {
            const errorMessage = isSimpleApiError(error)
                ? error.response.data.error
                : 'Failed to enable the integration';
            setErrorMessage(errorMessage);
        },
    });

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            clientId: '',
            clientSecret: '',
        },
    });

    const handleOnSubmit: SubmitHandler<IFormInput> = ({
        clientId,
        clientSecret,
    }) => {
        mutate({ workspaceId, integrationId, clientId, clientSecret });
    };

    return (
        <FormDialog
            open={open}
            handleClose={onClose}
            handleConfirm={handleSubmit(handleOnSubmit)}
            title="Configure Deployteq credentials"
            subtitle={<Subtitle />}
            error={
                isError &&
                errorMessage && (
                    <Typography
                        variant="body2"
                        marginY={2}
                        paragraph
                        color="red"
                    >
                        {errorMessage}
                    </Typography>
                )
            }
            content={
                <Stack marginY={2} spacing={2}>
                    <Controller
                        name="clientId"
                        control={control}
                        rules={{ required: 'Client ID is required' }}
                        render={({ field }) => (
                            <StyledTextField
                                disabled={isLoading}
                                error={Boolean(errors.clientId)}
                                helperText={errors.clientId?.message}
                                label="Client ID"
                                {...field}
                            />
                        )}
                    />
                    <Controller
                        name="clientSecret"
                        control={control}
                        rules={{ required: 'Client secret is required' }}
                        render={({ field }) => (
                            <StyledTextField
                                disabled={isLoading}
                                label="Client secret"
                                error={Boolean(errors.clientSecret)}
                                helperText={errors.clientSecret?.message}
                                {...field}
                            />
                        )}
                    />
                </Stack>
            }
            confirmButtonText="Confirm"
            confirmButtonProps={{ loading: isLoading }}
        />
    );
};
