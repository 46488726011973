import { differenceInCalendarDays } from 'date-fns';
import {
    addPeriods,
    calculateGranularity,
    Granularity,
} from '../../../../queries/time-series';
import { useTimeSeriesQuery } from '../../../../queries/time-series-hook';
import { MetricData } from './Chart/useCompareDateRangeQuery';
import { BinaryFilter } from '../../../../queries/filters';
import { range } from 'lodash';

interface UseCampaignPerformanceDataProps {
    dataset: string;
    dateRange: Interval;
    compareDateRange: Interval;
    chartGranularity: 'auto' | Granularity;
    metrics: string[];
    filters: BinaryFilter[];
}

export const useCampaignPerformanceData = ({
    dataset,
    dateRange,
    compareDateRange,
    chartGranularity,
    metrics,
    filters,
}: UseCampaignPerformanceDataProps) => {
    const lengthOfRangeInDays = Math.max(
        differenceInCalendarDays(dateRange.end, dateRange.start),
        differenceInCalendarDays(compareDateRange.end, compareDateRange.start)
    );

    const granularity =
        chartGranularity === 'auto'
            ? calculateGranularity(lengthOfRangeInDays)
            : chartGranularity;

    const baseQuery = {
        metrics,
        filters: filters,
    };

    const chartDataCurrentPeriod = useTimeSeriesQuery<any[]>(dataset, {
        ...baseQuery,
        dateRange,
        granularity,
    });

    const chartDataLastPeriod = useTimeSeriesQuery<any[]>(dataset, {
        ...baseQuery,
        dateRange: compareDateRange,
        granularity,
    });

    const totalsCurrentPeriod = useTimeSeriesQuery<any[]>(dataset, {
        ...baseQuery,
        dateRange,
        granularity: undefined,
    });

    const totalsLastPeriod = useTimeSeriesQuery<any[]>(dataset, {
        ...baseQuery,
        dateRange: compareDateRange,
        granularity: undefined,
    });

    return {
        isLoading:
            chartDataCurrentPeriod.isLoading ||
            chartDataLastPeriod.isLoading ||
            totalsCurrentPeriod.isLoading ||
            totalsLastPeriod.isLoading,
        error:
            chartDataCurrentPeriod.error ||
            chartDataLastPeriod.error ||
            totalsCurrentPeriod.error ||
            totalsLastPeriod.error,
        metricData:
            chartDataCurrentPeriod.data &&
            chartDataLastPeriod.data &&
            totalsCurrentPeriod.data &&
            totalsLastPeriod.data
                ? transformResult(
                      metrics,
                      {
                          currentPeriod: chartDataCurrentPeriod.data,
                          lastPeriod: chartDataLastPeriod.data,
                          totals: {
                              currentPeriod: totalsCurrentPeriod.data,
                              lastPeriod: totalsLastPeriod.data,
                          },
                      },
                      granularity
                  )
                : undefined,
    };
};

function transformResult(
    metrics: string[],
    data: {
        currentPeriod: any[];
        lastPeriod: any[];
        totals: {
            lastPeriod: any[];
            currentPeriod: any[];
        };
    },
    granularity: Granularity
): Record<string, MetricData> {
    const length = Math.max(data.currentPeriod.length, data.lastPeriod.length);

    return Object.fromEntries(
        metrics.map(metric => [
            metric,
            {
                chartData: range(length).map(index => ({
                    current: data.currentPeriod[index]
                        ? {
                              x: data.currentPeriod[index].start,
                              y: data.currentPeriod[index][metric],
                              xEnd: data.currentPeriod[index].end,
                          }
                        : {
                              x: addPeriods(
                                  data.currentPeriod[0].start,
                                  granularity,
                                  index
                              ),
                              y: null,
                              xEnd: addPeriods(
                                  data.currentPeriod[0].end,
                                  granularity,
                                  index
                              ),
                          },
                    lastPeriod: data.lastPeriod[index]
                        ? {
                              x: data.lastPeriod[index]?.['start'],
                              y: data.lastPeriod[index]?.[metric],
                              xEnd: data.lastPeriod[index]?.['end'],
                          }
                        : undefined,
                })),
                totals: {
                    current: data.totals.currentPeriod[0][metric],
                    lastPeriod: data.totals.lastPeriod[0][metric],
                },
            },
        ])
    );
}
