import { Palette } from '@mui/material';
import { TargetData } from './useTargetData.ts';
import { Target } from '../../types/targets';

type SelectedPalette = keyof Pick<Palette, 'success' | 'error'>;
export const getColorPalette = (
    target: Target,
    lastValue: TargetData
): SelectedPalette => {
    const onTrack = lastValue.cumulativeValue >= lastValue.expectedValue;
    const isExpired = target.dateEnd < new Date();

    if (isExpired && lastValue.cumulativeValue < target.target) {
        return 'error';
    }

    if (!onTrack) {
        return 'error';
    }

    return 'success';
};
