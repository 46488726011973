import React, { PropsWithChildren, ReactElement } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { CloseOutlined } from '@mui/icons-material';
import { Drawer, DrawerProps, Typography } from '@mui/material';
import { drawerWidth } from '../Layout/Main';
import { match, P } from 'ts-pattern';

const Container = styled(Stack)`
    background-color: ${({ theme }) => theme.elevation.base};
    border-bottom: 1px solid ${({ theme }) => theme.border.light};
    z-index: 2;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 48px;
`;
Container.defaultProps = {
    direction: 'row-reverse',
    p: 1,
};

const StyledStack = styled(Stack)`
    position: relative;
    background-color: ${({ theme }) => theme.elevation[1]};
`;

const Content = styled(Box)`
    width: 100%;
    height: 100%;
`;

const SeinoDrawerTitle = styled(Typography)``;
SeinoDrawerTitle.defaultProps = {
    variant: 'mediumBoldBody',
};

const Offset = styled('div', {
    shouldForwardProp: prop => prop !== 'offset',
})<{
    offset?: 'dense' | 'normal' | number | undefined;
}>(({ theme, offset = undefined }) =>
    match(offset)
        .with(P.number, minHeight => ({ minHeight }))
        .with('normal', () => theme.mixins.toolbar)
        .otherwise(() => theme.mixins.denseToolbar)
);

type HeaderProps = {
    onClose: () => void;
};

type SeinoDrawerProps = {
    header: ReactElement | string;
    top?: number;
    offset?: 'dense' | 'normal' | number | undefined;
};

export const SeinoDrawer = ({
    children,
    header,
    onClose,
    offset,
    ...drawerProps
}: PropsWithChildren<DrawerProps & SeinoDrawerProps>) => (
    <Drawer
        variant="temporary"
        anchor="right"
        sx={({ breakpoints, elevation }) => ({
            width: drawerWidth,
            [`& .MuiDrawer-paper`]: {
                backgroundColor: elevation.base,
                width: '100vw',

                [breakpoints.up('md')]: {
                    width: '359px',
                },
                boxSizing: 'border-box',
            },
        })}
        onClose={onClose}
        {...drawerProps}
    >
        <Offset offset={offset} />
        <Content>
            <SeinoDrawerHeader onClose={() => onClose?.({}, 'escapeKeyDown')}>
                <SeinoDrawerTitle>{header}</SeinoDrawerTitle>
            </SeinoDrawerHeader>
            {children}
        </Content>
    </Drawer>
);

export function SeinoDrawerHeader({
    children,
    onClose,
}: PropsWithChildren<HeaderProps>) {
    return (
        <Container>
            <IconButton size="small" aria-label="close" onClick={onClose}>
                <CloseOutlined fontSize="small" />
            </IconButton>
            <StyledStack p={1} spacing={2}>
                {children}
            </StyledStack>
        </Container>
    );
}
