import { UseQueryResult } from '@tanstack/react-query';
import { DatasetWithSchema } from '../api-client/datasets';
import { useDatasets } from './use-datasets';
import { useDatasetSchema } from './use-dataset-schema';

export const usePrimaryDataset = (): UseQueryResult<DatasetWithSchema> => {
    const { data: datasets } = useDatasets();

    const primaryDataset =
        datasets?.find(ds => ds.id === 'campaign_metrics_by_send_date') ??
        datasets?.[0];

    const schemaQuery = useDatasetSchema(primaryDataset?.id);

    if (schemaQuery.isSuccess && primaryDataset) {
        return {
            ...schemaQuery,
            data: { schema: schemaQuery.data, ...primaryDataset },
        } as UseQueryResult<DatasetWithSchema>;
    } else {
        return schemaQuery as UseQueryResult<DatasetWithSchema>;
    }
};
