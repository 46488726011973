import { useMutation, useQueryClient } from '@tanstack/react-query';
import { integrationsQueryKey } from '../../../../hooks/use-integrations.ts';
import axios from 'axios';
import { getRustAPIUrl } from '../../../../utils/api.ts';
import { Link, Stack, TextField } from '@mui/material';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { FormDialog } from '../../../../components/Dialog/FormDialog.tsx';
import Typography from '@mui/material/Typography';
import { useActiveWorkspaceId } from '../../../../providers/useActiveWorkspaceId.ts';

type ConnectSalesforce = { workspaceId: string; accountId: string };

type EnableSalesforceProps = {
    open: boolean;
    onClose: () => void;
};
export const EnableSalesforce = ({ open, onClose }: EnableSalesforceProps) => {
    const queryClient = useQueryClient();
    const workspaceId = useActiveWorkspaceId();
    const { mutateAsync, isLoading } = useMutation(
        ({ workspaceId, accountId }: ConnectSalesforce) =>
            axios.post(
                `${getRustAPIUrl()}/workspaces/${workspaceId}/integrations/salesforce`,
                { account_id: parseInt(accountId) }
            ),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(
                    integrationsQueryKey(workspaceId)
                );
                onClose();
            },
        }
    );

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            accountId: '',
        },
    });

    const handleOnSubmit: SubmitHandler<{ accountId: string }> = ({
        accountId,
    }) => {
        mutateAsync({ workspaceId, accountId });
    };

    return (
        <FormDialog
            open={open}
            handleClose={onClose}
            handleConfirm={handleSubmit(handleOnSubmit)}
            title="Add Salesforce Marketing Cloud"
            content={
                <Stack marginY={2} spacing={2}>
                    <Typography>
                        Please enter the member ID (MID) of the SFMC account you
                        want to connect. Please refer to{' '}
                        <Link
                            href="https://salesforce.vidyard.com/watch/WEjEkkwn9onVyPYKyM6h5t"
                            target="_blank"
                        >
                            this video
                        </Link>{' '}
                        for instructions on how to find your MID.
                    </Typography>
                    <Controller
                        name="accountId"
                        control={control}
                        rules={{
                            required: 'This field is required',
                            pattern: /\d+/,
                        }}
                        render={({ field }) => (
                            <TextField
                                error={Boolean(errors.accountId)}
                                helperText={errors.accountId?.message}
                                label="Marketing Cloud Member ID"
                                {...field}
                            />
                        )}
                    />
                </Stack>
            }
            confirmButtonText="Confirm"
            confirmButtonProps={{ loading: isLoading }}
        />
    );
};
