import React from 'react';
import { Box, Card, Container, Stack, ThemeProvider } from '@mui/material';
import { DatasetSchemaPreloader } from '../../components/DatasetSchemaPreloader/DatasetSchemaPreloader';
import { AlertingTable } from './AlertingTable';
import { DialogProvider } from '../../components/Dialog/DialogContext';
import { useNavigate } from 'react-router-dom';
import { useActiveWorkspace } from '../../providers/WorkspaceProvider.hooks';
import { AlertDialog } from './AlertDialog';
import { theme } from '../../theme';
import { DemoBanner } from '../../components/Banner/DemoBanner';

export const AlertingPage = () => {
    const navigate = useNavigate();
    const { workspaceId } = useActiveWorkspace();

    const handleOnClose = () => navigate(`/workspaces/${workspaceId}/alerting`);

    return (
        <ThemeProvider theme={theme}>
            <DemoBanner />
            <DatasetSchemaPreloader />
            <Container>
                <Stack spacing={1} mt={1}>
                    <Card sx={{ p: 2 }}>
                        <DialogProvider onClose={() => handleOnClose}>
                            <AlertDialog />
                            <Box
                                flexGrow={1}
                                display="flex"
                                flexDirection="column"
                            >
                                <AlertingTable />
                            </Box>
                        </DialogProvider>
                    </Card>
                </Stack>
            </Container>
        </ThemeProvider>
    );
};
