import { Box } from '@mui/material';
import { linearGradientDef } from '@nivo/core';
import { ResponsiveLine } from '@nivo/line';

import { formatNumber, NumberFormat } from '../../../../../utils/number-format';
import { Metric } from './PerformanceCard';
import { Tooltip } from './Tooltip';
import { ChartData } from './useCompareDateRangeQuery';
import { useLocale } from '../../../../../providers/LocaleProvider.hooks';
import { memo } from 'react';
import { isEqual } from 'lodash';

interface CompareChartProps {
    data: ChartData[];
    metric: Metric;
    showLastPeriod?: boolean;
}

const CompareChartInner = (props: CompareChartProps) => {
    const locale = useLocale();
    const {
        data,
        metric: { label, format },
        showLastPeriod = true,
    } = props;

    const currentPeriod = {
        id: 'current',
        data: data.filter(x => x.current.y !== undefined).map(x => x.current),
    };

    const lastPeriod = {
        id: 'lastPeriod',
        data: data
            .filter(x => x.lastPeriod)
            .map(x => ({ x: x.current.x, y: x.lastPeriod!.y })),
    };

    const preparedData = showLastPeriod
        ? [currentPeriod, lastPeriod]
        : [currentPeriod];

    // Hacky workaround for duplicate ticks on x-axis when dataset contains 8 consecutive days or less.
    // GitHub: https://github.com/plouc/nivo/issues/548
    const tickValues =
        preparedData[0].data.length <= 8 ? 'every day' : undefined;

    return (
        <Box sx={{ height: 300 }}>
            <ResponsiveLine
                animate={false}
                colors={['#009dbb', '#ee7e17']}
                legends={[
                    {
                        anchor: 'bottom-right',
                        direction: 'column',
                        justify: false,
                        translateX: 100,
                        translateY: 0,
                        itemsSpacing: 0,
                        itemDirection: 'left-to-right',
                        itemWidth: 80,
                        itemHeight: 20,
                        itemOpacity: 0.75,
                        symbolSize: 12,
                        symbolShape: 'circle',
                        symbolBorderColor: 'rgba(0, 0, 0, .5)',
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemBackground: 'rgba(0, 0, 0, .03)',
                                    itemOpacity: 1,
                                },
                            },
                        ],
                    },
                ]}
                enableArea={true}
                defs={[
                    linearGradientDef('gradientCurrent', [
                        { offset: 0, color: 'inherit' },
                        { offset: 100, color: 'inherit', opacity: 0 },
                    ]),
                    linearGradientDef('gradientLastPeriod', []),
                ]}
                fill={[
                    {
                        match: { id: 'current' },
                        id: 'gradientCurrent',
                    },
                    {
                        match: { id: 'lastPeriod' },
                        id: 'gradientLastPeriod',
                    },
                ]}
                useMesh={true}
                data={preparedData}
                enableGridX={false}
                enablePoints={true}
                enableGridY={true}
                margin={{ top: 4, right: 20, bottom: 40, left: 80 }}
                xScale={{
                    type: 'time',
                    format: '%Y-%m-%d',
                    precision: 'day',
                    useUTC: false,
                }}
                yScale={{
                    type: 'linear',
                    min: 0,
                    max: format === NumberFormat.Percentage ? 1.01 : undefined,
                }}
                curve="linear"
                axisTop={null}
                axisRight={null}
                pointSize={5}
                pointBorderWidth={2}
                pointLabelYOffset={0}
                axisBottom={{
                    format: '%b %d',
                    tickValues,
                    legendOffset: -12,
                }}
                axisLeft={{
                    tickValues: 6,
                    format: value => formatNumber(value, format, locale),
                }}
                enableSlices="x"
                sliceTooltip={({ slice: { points } }) => (
                    <Tooltip
                        metricName={label}
                        numberFormat={format}
                        data={data}
                        points={points}
                    />
                )}
            />
        </Box>
    );
};

export const CompareChart = memo(CompareChartInner, isEqual);
