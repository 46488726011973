import { createSvgIcon } from '@mui/material';

export const ExplorerIcon = createSvgIcon(
    <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6.70835 8.99999C6.70835 8.65481 6.98818 8.37499 7.33335 8.37499H8.37502V7.33332C8.37502 6.98815 8.65484 6.70832 9.00002 6.70832C9.3452 6.70832 9.62502 6.98815 9.62502 7.33332V8.37499H10.6667C11.0119 8.37499 11.2917 8.65481 11.2917 8.99999C11.2917 9.34517 11.0119 9.62499 10.6667 9.62499H9.62502V10.6667C9.62502 11.0118 9.3452 11.2917 9.00002 11.2917C8.65484 11.2917 8.37502 11.0118 8.37502 10.6667V9.62499H7.33335C6.98818 9.62499 6.70835 9.34517 6.70835 8.99999Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.00002 0.0416565C4.05247 0.0416565 0.041687 4.05244 0.041687 8.99999C0.041687 13.9475 4.05247 17.9583 9.00002 17.9583C13.9476 17.9583 17.9584 13.9475 17.9584 8.99999C17.9584 4.05244 13.9476 0.0416565 9.00002 0.0416565ZM8.37502 1.31663C4.61627 1.61822 1.61825 4.61624 1.31666 8.37499H3.16669C3.51187 8.37499 3.79169 8.65481 3.79169 8.99999C3.79169 9.34517 3.51187 9.62499 3.16669 9.62499H1.31666C1.61825 13.3837 4.61627 16.3818 8.37502 16.6834V14.8333C8.37502 14.4881 8.65484 14.2083 9.00002 14.2083C9.3452 14.2083 9.62502 14.4881 9.62502 14.8333V16.6834C13.3838 16.3818 16.3818 13.3837 16.6834 9.62499H14.8334C14.4882 9.62499 14.2084 9.34517 14.2084 8.99999C14.2084 8.65481 14.4882 8.37499 14.8334 8.37499H16.6834C16.3818 4.61624 13.3838 1.61822 9.62502 1.31663V3.16666C9.62502 3.51183 9.3452 3.79166 9.00002 3.79166C8.65484 3.79166 8.37502 3.51183 8.37502 3.16666V1.31663Z"
            fill="currentColor"
        />
    </svg>,
    'ExplorerIcon'
);
