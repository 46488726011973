import { UseQueryOptions } from '@tanstack/react-query';
import { Md5 } from 'ts-md5';
import { useDuckDatasetQuery } from '../hooks/use-duck-dataset-query';
import { useActiveWorkspace } from '../providers/WorkspaceProvider.hooks';
import { sqlForHighLowQuery } from './high-low-sql';

export const useHighLowQuery = <TResult>(
    dataset: string,
    query: any,
    queryOptions?: UseQueryOptions<TResult>
) => {
    const { workspaceId } = useActiveWorkspace();
    const queryKey = Md5.hashStr(JSON.stringify({ dataset, ...query }));

    return useDuckDatasetQuery({
        queryKey: ['duckdb', workspaceId, 'high-low', queryKey],
        dataset,
        queryFn: (sourceTable, _, schema) =>
            sqlForHighLowQuery(schema, sourceTable, query),
        queryOptions,
    });
};
