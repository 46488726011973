import { useActiveWorkspace } from '../../providers/WorkspaceProvider.hooks.ts';
import { Box, Link, Typography } from '@mui/material';
import { SeinoLogo } from '../../components/Graphics/SeinoLogo.tsx';

export function DashboardNotFound() {
    const { workspaceId } = useActiveWorkspace();
    return (
        <Box
            width="100%"
            height="100vh"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
        >
            <SeinoLogo />
            <Typography variant="h2" my={4}>
                404
            </Typography>

            <Typography>
                The dashboard view you were looking for could not be found.
            </Typography>
            <Box mt={4}>
                <Typography>
                    Click{' '}
                    <Link href={`/workspaces/${workspaceId}/dashboard`}>
                        here
                    </Link>{' '}
                    to go your default view.
                </Typography>
            </Box>
        </Box>
    );
}
