import { TextField, TextFieldProps } from '@mui/material';
import React from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useForm } from 'react-hook-form';
import { useActiveExplorerView } from '../../providers/ExplorerViewProvider.hooks';
import { isPermitted } from '../../types/permission/permission';
import { useExplorerState } from '../../hooks/use-explorer-state';

type EditViewProps = {
    defaultValue: string;
    label: string;
    onChange: (newValue: string) => void;
} & Omit<TextFieldProps, 'onChange' | 'variant'>;

interface FormData {
    value: string;
}

const DebouncedTextField = ({
    defaultValue,
    label,
    onChange,
    ...rest
}: EditViewProps) => {
    const updateValue = useDebouncedCallback(onChange, 300);
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<FormData>({
        mode: 'onChange',
        defaultValues: {
            value: defaultValue,
        },
    });
    const onSubmit = ({ value }: FormData) => {
        updateValue(value);
    };

    return (
        <TextField
            size="small"
            fullWidth
            placeholder={label}
            label={label}
            error={Boolean(errors.value)}
            helperText={errors.value?.message}
            margin="none"
            {...register('value', {
                required: 'This field is required',
                onChange: handleSubmit(onSubmit),
            })}
            {...rest}
        />
    );
};

export const EditViewName = () => {
    const { saveLocal, view } = useExplorerState();
    const { accessLevel } = useActiveExplorerView();

    const handleOnChange = (newValue: string) => {
        if (!view) {
            return null;
        }

        saveLocal({ ...view, title: newValue });
    };

    return (
        <>
            {accessLevel && view && (
                <DebouncedTextField
                    defaultValue={view.title}
                    label="View name"
                    onChange={handleOnChange}
                    disabled={!isPermitted(accessLevel, 'write')}
                />
            )}
        </>
    );
};
