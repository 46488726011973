import { useExplorerState } from '../../hooks/use-explorer-state.ts';
import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { GridConfig } from './grid/utils/gridView.ts';
import { resolveNewState } from '../../utils/resolve-new-state.ts';

export const useGridConfig = (): [
    GridConfig,
    Dispatch<SetStateAction<GridConfig>>
] => {
    const { view, saveLocal } = useExplorerState();

    const setState = useCallback(
        (arg: SetStateAction<GridConfig>) => {
            saveLocal(view => {
                return {
                    ...view,
                    config: resolveNewState(view.config, arg),
                };
            });
        },
        [saveLocal]
    );

    return useMemo(() => [view.config, setState], [view.config, setState]);
};
