import { ReactElement } from 'react';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import { SeinoTheme } from '../../theme/lightTheme.ts';

export type BaseRow = { id?: string } | { id: string };

export enum ColWidth {
    Tiny = 25,
    Small = 110,
    Medium = 130,
    Large = 150,
    DateTime = 155,
}

export interface Column<T extends BaseRow> {
    align?: 'left' | 'center' | 'right';
    cellStyling?: (theme: Theme, row: T) => SxProps;
    headerStyling?: SxProps<SeinoTheme>;
    getSortValue?: (row: T) => string | number | undefined;
    getValue?: (row: T) => string;
    isSortable?: boolean;
    isVisible?: boolean;
    isClickable?: boolean;
    accessor: keyof T;
    render?: (
        row: T,
        columns: Columns<T>,
        searchQuery?: string
    ) => ReactElement | null;
    searchable?: boolean;
    secondarySort?: {
        key: keyof T;
        reverseOrder: boolean;
    };
    title?: string;
    width?: number;
}

type NonEmptyArray<T> = [T, ...T[]];

export type Columns<T extends BaseRow> = NonEmptyArray<Column<T>>;
