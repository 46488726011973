import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import * as Sentry from '@sentry/browser';

export const useSentry = () => {
    const { user } = useAuth0();

    const id = user?.sub;
    const email = user?.email;

    useEffect(() => {
        Sentry.setUser(id ? { id, email } : null);
    }, [id, email]);
};
