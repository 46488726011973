import { createContext, PropsWithChildren, useState } from 'react';

export const DialogContext = createContext({
    isOpen: false,
    open: () => {},
    close: () => {},
});

const DialogProvider = (
    props: {
        open?: boolean;
        onClose?: () => void;
    } & PropsWithChildren
) => {
    const { children, open, onClose, ...rest } = props;

    const [isOpen, setIsOpen] = useState(open || false);

    const handleClose = () => {
        setIsOpen(false);
        onClose?.();
    };
    const handleOpen = () => setIsOpen(true);

    return (
        <DialogContext.Provider
            value={{ isOpen, open: handleOpen, close: handleClose }}
            {...rest}
        >
            {children}
        </DialogContext.Provider>
    );
};

export { DialogProvider };
