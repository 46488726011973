import {
    Box,
    Skeleton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { match } from 'ts-pattern';
import { Integration } from '../../../types/integration';
import {
    IntegrationType,
    SupportedIntegrations,
} from '../../../types/workspace-management';
import { groupBy } from 'lodash';
import { Link, useParams } from 'react-router-dom';
import { useIntegrations } from '../../../hooks/use-integrations.ts';

export const EnabledIntegrations = () => {
    const { data, isLoading } = useIntegrations();

    const integrationsByType = groupBy(
        (data || []).filter(i =>
            Object.keys(SupportedIntegrations).includes(i.type)
        ),
        'type'
    );

    if (!isLoading && data?.length === 0) {
        return (
            <Typography variant="body2">
                No integrations have been enabled yet.
            </Typography>
        );
    }

    return (
        <Table size="small" component="div">
            <TableHead component="div"></TableHead>

            <TableBody component="div">
                {isLoading && <LoadingTableRow />}
                {data &&
                    data.length > 0 &&
                    Object.entries(integrationsByType).map(
                        ([type, integrations]) => (
                            <IntegrationRow
                                key={type}
                                type={type as IntegrationType}
                                integrations={integrations}
                            />
                        )
                    )}
            </TableBody>
        </Table>
    );
};

function LoadingTableRow() {
    return (
        <TableRow component="div" sx={{ textDecoration: 'none' }} hover>
            <TableCell component="div">
                <Stack alignItems="center" direction="row" spacing={2}>
                    <Skeleton width={32} height={32} variant="rectangular" />
                    <Stack>
                        <Typography variant="body2">
                            <Skeleton width={80} />
                        </Typography>
                        <Typography variant="body2" color="text.disabled">
                            <Skeleton width={120} />
                        </Typography>
                    </Stack>
                </Stack>
            </TableCell>
            <TableCell component="div" align="right">
                <Box display="flex" justifyContent="end">
                    <Skeleton width={50} />
                </Box>
            </TableCell>
        </TableRow>
    );
}

interface IntegrationRowProps {
    type: IntegrationType;
    integrations: Integration[];
}

const IntegrationRow = ({ type, integrations }: IntegrationRowProps) => {
    const { workspaceId } = useParams();
    const integrationInfo = SupportedIntegrations[type];

    const childCount = integrations
        .map(i =>
            match(i)
                .with({ type: 'google_analytics' }, ({ views }) => views.length)
                .otherwise(() => 1)
        )
        .reduce((a, b) => a + b, 0);

    return (
        <TableRow
            component={Link}
            sx={{ textDecoration: 'none' }}
            to={`/workspaces/${workspaceId}/settings/integrations/${type}`}
            hover
        >
            <TableCell component="div">
                <Stack direction="row" spacing={2}>
                    <img src={integrationInfo.svg} width={32} alt="" />
                    <Stack>
                        <Typography variant="body2">
                            {integrationInfo.label}
                        </Typography>
                        <Typography variant="body2" color="primary.light">
                            {`${childCount} ${integrationInfo.child}`}
                            {childCount !== 1 && 's'} connected
                        </Typography>
                    </Stack>
                </Stack>
            </TableCell>
            <TableCell component="div" align="right">
                {childCount > 0 ? 'Active' : 'Inactive'}
            </TableCell>
        </TableRow>
    );
};
