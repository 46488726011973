function getValue(value: unknown): string | string[] {
    if (Array.isArray(value)) {
        return [...new Set(value.flatMap(getValue))];
    }

    if (typeof value === 'string') {
        return value;
    }

    if (typeof value === 'number') {
        return value.toString();
    }

    if (typeof value === 'boolean') {
        return value ? 'Yes' : 'No';
    }

    if (value instanceof Date) {
        return value.toLocaleDateString();
    }

    return '';
}

export function getValueAsString(value: unknown): string {
    const v = getValue(value);
    return Array.isArray(v) ? v.join(', ') : v;
}
