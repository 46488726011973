import * as React from 'react';
import { AutocompleteRenderOptionState } from '@mui/material/Autocomplete/Autocomplete';
import { AutocompleteValue } from './Autocomplete';
import {
    Checkbox,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from '@mui/material';

export const AutocompleteListItem = <T extends AutocompleteValue>(
    props: React.HTMLAttributes<HTMLLIElement>,
    option: T,
    state: AutocompleteRenderOptionState
) => {
    return (
        <ListItem {...props} style={{ padding: 0 }} dense>
            <ListItemButton>
                <ListItemIcon>
                    <Checkbox size="small" checked={state.selected} />
                </ListItemIcon>
                <ListItemText
                    primary={option.label}
                    primaryTypographyProps={{ fontSize: '0.8rem' }}
                    secondary={option.secondaryLabel}
                    secondaryTypographyProps={{ fontSize: '0.7rem' }}
                />
            </ListItemButton>
        </ListItem>
    );
};
