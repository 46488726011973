import { useParams } from 'react-router-dom';
import {
    Alert,
    LinearProgress,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { Content, SubContent } from '../../../../components/Table';
import {
    AvailableGoogleAnalyticsView,
    ConfiguredGoogleAnalyticsView,
} from '../../../../types/integration';
import { AddView } from './AddView';
import { Market } from '../../../../components/Market/Market';
import { useViews } from './useViews';
import { DeleteView } from './DeleteView';
import { ActionButton } from '../../../../components/Button/ActionButton';

type ViewRowProps = {
    workspaceId: string;
    integrationId: string;
    renderMarketColumn: boolean;
    property: AvailableGoogleAnalyticsView & ConfiguredGoogleAnalyticsView;
};

const ViewRow = ({
    workspaceId,
    integrationId,
    renderMarketColumn,
    property: { view_id, name, account_name, market },
}: ViewRowProps) => {
    return (
        <TableRow key={`view-row-${view_id}`}>
            {renderMarketColumn && (
                <TableCell align="center" width={40}>
                    <Market market={market} />
                </TableCell>
            )}
            <TableCell>
                <Stack alignItems="center" direction="row">
                    <div>
                        <Content>{name ?? 'Missing'}</Content>
                        <SubContent>
                            {view_id.replace(/properties\//, '')}
                            {account_name && ` - ${account_name}`}
                        </SubContent>
                    </div>
                </Stack>
            </TableCell>
            <TableCell align="right">
                <DeleteView
                    workspaceId={workspaceId}
                    integrationId={integrationId}
                    viewId={view_id}
                />
            </TableCell>
        </TableRow>
    );
};

interface ViewListProps {
    integrationId: string;
}

export const ViewList = ({ integrationId }: ViewListProps) => {
    const { workspaceId } = useParams();

    const {
        data: { availableViews, selectedViews },
        isLoading,
        error,
        refetchAvailableViews,
        isRefetching,
    } = useViews(workspaceId || '', integrationId || '');

    const totalViewCount = availableViews.length + selectedViews.length;

    if (isLoading) {
        return (
            <Stack spacing={2}>
                <Typography variant="body2">
                    Please wait while we check which Google Analytics properties
                    you can access, this can take a while.
                </Typography>
                <LinearProgress />
            </Stack>
        );
    }

    if (error) {
        return (
            <Alert severity="error">
                <Typography>
                    We were unable to connect with your Google Analytics
                    account. Please contact us if this problem persists.
                </Typography>
            </Alert>
        );
    }

    const renderMarketColumn = selectedViews.some(v => v.market);
    return (
        <Stack spacing={2}>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
            >
                <Typography variant="body2">
                    Your account currently has access to {totalViewCount}
                    {totalViewCount !== 1 ? ' GA views' : ' GA view'}.
                </Typography>

                <Stack direction="row" spacing={2}>
                    <ActionButton
                        loading={isRefetching}
                        onClick={() => refetchAvailableViews()}
                    >
                        Refresh list
                    </ActionButton>
                    <AddView
                        workspaceId={workspaceId || ''}
                        integrationId={integrationId || ''}
                    />
                </Stack>
            </Stack>

            <TableContainer>
                <Table size="small">
                    <TableHead style={{ borderTop: '1px double #e0e0e0' }}>
                        <TableRow>
                            {renderMarketColumn && (
                                <TableCell align="center">
                                    <strong>Market</strong>
                                </TableCell>
                            )}
                            <TableCell align="left">
                                <strong>Google Analytics View</strong>
                            </TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {selectedViews.map(view => (
                            <ViewRow
                                workspaceId={workspaceId || ''}
                                integrationId={integrationId || ''}
                                renderMarketColumn={renderMarketColumn}
                                key={view.view_id}
                                property={view}
                            />
                        ))}
                        {selectedViews.length === 0 && <EmptyRow />}
                    </TableBody>
                </Table>
            </TableContainer>
        </Stack>
    );
};

const EmptyRow = () => {
    return (
        <TableRow>
            <TableCell colSpan={2}>
                No Google Analytics views have been selected yet.
            </TableCell>
        </TableRow>
    );
};
