export const createColumnWidthCalculator = () => {
    const element = document.createElement('canvas');
    const context = element.getContext('2d');
    if (!context) {
        throw new Error('Unable to measure text, context is null');
    }

    context.font =
        '600 12px -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif';

    return (text: string | undefined) =>
        text ? context.measureText(text).width + 60 : 100;
};
