import { EditModeSwitch } from '../../components/Dashboard/EditMode/EditModeSwitch';
import { DateRangePickerWrapper } from '../../components/DateRangePicker/DateRangePickerWrapper';
import { DashboardSelector } from './Toolbar/DashboardSelector';
import { NewDashboard } from './NewDashboard';
import {
    SeinoToolbar,
    ToolbarContent,
    ToolbarLeftContent,
    ToolbarRightContent,
} from '../Explorer/Navigation.styles';
import * as React from 'react';
import { UndoRedoResetToolbar } from './Toolbar/UndoRedoResetToolbar.tsx';
import { ShareDashboard } from './ShareDashboard.tsx';
import { AnnotationsButton } from '../../components/Annotations/AnnotationsButton.tsx';

export const DashboardToolbar = () => {
    return (
        <SeinoToolbar color="secondary">
            <ToolbarContent>
                <ToolbarLeftContent>
                    <DashboardSelector />
                    <NewDashboard />
                    <ShareDashboard />
                    <EditModeSwitch />
                    <AnnotationsButton />
                </ToolbarLeftContent>
                <ToolbarRightContent>
                    <UndoRedoResetToolbar />
                    <DateRangePickerWrapper allowCompare={true} />
                </ToolbarRightContent>
            </ToolbarContent>
        </SeinoToolbar>
    );
};
