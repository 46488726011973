import { InputAdornment, InputAdornmentProps } from '@mui/material';
import { FormatType } from '../../types/datamodel/schema.ts';
import { match } from 'ts-pattern';

export type FormatAdornmentProps = InputAdornmentProps & {
    format?: FormatType;
};
export const FormatAdornment = ({ format, ...props }: FormatAdornmentProps) => (
    <InputAdornment {...props}>
        {match(format)
            .with('percent', () => '%')
            .with('currency', () => '€')
            .otherwise(() => '')}
    </InputAdornment>
);
