import { useDuckDatasetQuery } from '../../../../hooks/use-duck-dataset-query';
import { keyBy } from 'lodash';
import { dateFormat, parseDate } from '../../../../utils/date-format';
import { calculateGranularity } from '../../../../queries/time-series';
import { differenceInCalendarDays } from 'date-fns';
import { useDateRange } from '../../../../hooks/use-date-range';
import { useActiveWorkspaceId } from '../../../../providers/useActiveWorkspaceId.ts';

type Vector<T> = { toArray: () => T[] };

interface QueryResultRow {
    list: string;
    data: Vector<{
        date: string;
        members: number;
        members_delta: number;
        members_in: number;
        members_out: number;
    }>;
}

interface SubscriberList {
    list: string;
    data: SubscriberListDatum[];
}

export interface SubscriberListDatum {
    date: Date;
    members: number;
    members_delta: number;
    members_in: number;
    members_out: number;
}

export const useSubscriberListData = () => {
    const workspaceId = useActiveWorkspaceId();

    const { dateRanges } = useDateRange();

    const granularity = calculateGranularity(
        differenceInCalendarDays(dateRanges.main.end, dateRanges.main.start)
    );

    const start = dateFormat(dateRanges.main.start);
    const end = dateFormat(dateRanges.main.end);

    return useDuckDatasetQuery<QueryResultRow, Record<string, SubscriberList>>({
        queryKey: [workspaceId, start, end, 'list_members'],
        dataset: 'list_members',
        queryFn: sourceTable => {
            return `
     WITH lists AS (SELECT DISTINCT list FROM ${sourceTable}),
          y AS (
               SELECT list,
                      DATE_TRUNC('${granularity}', date) AS x,
                      ARRAY_AGG(members ORDER BY date)[1]::int32 AS members,
                      SUM(members_delta)::int32 AS members_delta,
                      SUM(members_in)::int32 AS members_in,
                      SUM(-1 * members_out)::int32 AS members_out,
                FROM ${sourceTable}
               WHERE date BETWEEN date '${start}' AND date '${end}'
            GROUP BY list, x
            ORDER BY x
          ),
          x AS (
                SELECT DATE_TRUNC('${granularity}', d) AS x,
                       list,
                  FROM (SELECT UNNEST(GENERATE_SERIES(date '${start}', date '${end}', INTERVAL 1 DAY)) AS d)
            CROSS JOIN lists
              GROUP BY x, list
          )

   SELECT x.list,
          ARRAY_AGG({
            date: x.x::string,
            members: y.members,
            members_delta: y.members_delta,
            members_in: y.members_in,
            members_out: y.members_out,
          } ORDER BY x.x) AS data
     FROM x
LEFT JOIN y USING (x, list)
 GROUP BY x.list
`;
        },
        processResult: rows =>
            keyBy(
                rows.map(row => ({
                    ...row,
                    data: row.data
                        .toArray()
                        .map(item => ({ ...item, date: parseDate(item.date) })),
                })),
                'list'
            ),
    });
};
