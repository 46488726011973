import React from 'react';
import {
    styled,
    Table as MuiTable,
    TableBody,
    TableCell as MuiTableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import TableSortLabel from '@mui/material/TableSortLabel';

import { getValueAsString } from './getValueAsString';

import { Order } from './sorting';
import { TableCellContents } from './TableCellContents';
import { BaseRow, Columns } from './types';
import { useOrder } from './useOrder';
import { useSortedItems } from './useSortedItems';
import { Skeleton, useTheme } from '@mui/material';

const TableCell = styled(MuiTableCell, {
    shouldForwardProp: prop => prop !== '$isClickable',
})<{
    $isClickable?: boolean;
}>(({ $isClickable }) => ({
    cursor: $isClickable ? 'pointer' : 'default',
}));

const NotFoundMessage = styled(Typography)({});
NotFoundMessage.defaultProps = {
    variant: 'body2',
};

type TableProps<T extends BaseRow> = {
    columns: Columns<T>;
    defaultOrder?: Order;
    defaultOrderBy?: keyof T;
    orderByFunction?: (a: T, b: T) => number;
    isRowHighlighted?: (row: T) => boolean;
    isHeaderVisible?: boolean;
    notFoundMessage?: string;
    onRowClick?: (row: T) => void;
    rows: T[];
    searchQuery?: string;
    isLoading?: boolean;
};

const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);

export function Table<T extends BaseRow>({
    rows,
    columns,
    notFoundMessage = 'No items found',
    onRowClick,
    orderByFunction,
    defaultOrderBy,
    defaultOrder = 'asc',
    isRowHighlighted,
    searchQuery,
    isHeaderVisible = true,
    isLoading = false,
}: TableProps<T>) {
    const [orderBy, order, changeOrder] = useOrder(
        defaultOrderBy,
        defaultOrder,
        columns
    );
    const theme = useTheme();

    const sortedRows = useSortedItems(
        rows,
        orderBy,
        order,
        columns,
        orderByFunction
    );
    const rowAmount = rows.length;
    const visibleColumns = columns.filter(c => c.isVisible !== false);

    return (
        <MuiTable stickyHeader size="small">
            <TableHead>
                <TableRow>
                    {isHeaderVisible &&
                        visibleColumns.map(
                            ({
                                accessor,
                                title = capitalize(getValueAsString(accessor)),
                                width,
                                align,
                                isSortable = true,
                                headerStyling,
                            }) => (
                                <TableCell
                                    align={align}
                                    key={accessor as string}
                                    sortDirection={
                                        orderBy === accessor ? order : false
                                    }
                                    width={width}
                                    sx={headerStyling}
                                >
                                    {isSortable ? (
                                        <TableSortLabel
                                            sx={() => ({
                                                textAlign: align,
                                            })}
                                            active={orderBy === accessor}
                                            direction={
                                                orderBy === accessor
                                                    ? order
                                                    : 'asc'
                                            }
                                            onClick={() =>
                                                changeOrder(accessor)
                                            }
                                        >
                                            {title}
                                        </TableSortLabel>
                                    ) : (
                                        title
                                    )}
                                </TableCell>
                            )
                        )}
                </TableRow>
            </TableHead>

            <TableBody>
                {!isLoading && rowAmount === 0 && (
                    <TableRow key={'not-found'}>
                        <TableCell colSpan={visibleColumns.length}>
                            <NotFoundMessage>{notFoundMessage}</NotFoundMessage>
                        </TableCell>
                    </TableRow>
                )}

                {isLoading && (
                    <TableRow hover key={'table-loading'}>
                        {visibleColumns.map(column => (
                            <TableCell
                                align={column.align}
                                key={column.accessor as string}
                                width={column.width}
                                $isClickable={false}
                                onClick={undefined}
                            >
                                <Skeleton />
                            </TableCell>
                        ))}
                    </TableRow>
                )}

                {!isLoading &&
                    sortedRows.map((row, index) => (
                        <TableRow
                            key={row.id || index}
                            selected={isRowHighlighted && isRowHighlighted(row)}
                            hover
                        >
                            {visibleColumns.map(({ width, ...column }) => (
                                <TableCell
                                    align={column.align}
                                    key={column.accessor as string}
                                    sx={{
                                        minWidth: width,
                                        width: width,
                                        ...column.cellStyling?.(theme, row),
                                    }}
                                    $isClickable={column.isClickable}
                                    onClick={() =>
                                        column.isClickable &&
                                        onRowClick &&
                                        onRowClick(row)
                                    }
                                >
                                    <TableCellContents
                                        column={column}
                                        columns={columns}
                                        row={row}
                                        searchQuery={searchQuery}
                                    />
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
            </TableBody>
        </MuiTable>
    );
}

export type WithModifiers<T extends BaseRow> = T & { modifiers?: never };
export type ModifierRow = WithModifiers<BaseRow>;
