import * as React from 'react';
import { ReactNode } from 'react';
import Slider from '@mui/material/Slider';
import { List, ListItem, ListItemText, ListSubheader } from '@mui/material';

function valuetext(value: number) {
    return `${value}%`;
}

const marks = [
    {
        value: -25,
        label: '-25%',
    },
    {
        value: 0,
        label: '0%',
    },
    {
        value: 25,
        label: '25%',
    },
    {
        value: 50,
        label: '50%',
    },
    {
        value: 75,
        label: '75%',
    },
    {
        value: 100,
        label: '100%',
    },
];

const calculateValue = (value: number, percentage: number) =>
    value * (1 + percentage / 100);

export default function PercentageSlider({
    title,
    percentage,
    value,
    formatValue,
    onChange,
    disabled,
}: {
    title: string;
    percentage: number;
    value: number;
    onChange: (value: number) => void;
    formatValue: (value: number) => ReactNode;
    disabled?: boolean;
}) {
    const handleSliderChange = (_: Event, newValue: number | number[]) => {
        onChange(newValue as number);
    };

    return (
        <List
            disablePadding
            subheader={
                <ListSubheader sx={{ paddingLeft: 0 }}>{title}</ListSubheader>
            }
        >
            <ListItem>
                <ListItemText
                    primaryTypographyProps={{
                        sx: theme => ({
                            color: disabled
                                ? theme.text.disabled
                                : theme.text.primary,
                        }),
                    }}
                    primary={`${
                        percentage >= 0 ? 'Increased' : 'Decreased'
                    } by ${percentage}% to ${formatValue(
                        calculateValue(value, percentage)
                    )}`}
                />
            </ListItem>
            <ListItem
                sx={{
                    paddingInline: 2,
                    paddingBlock: 0,
                }}
            >
                <Slider
                    size="small"
                    color="primary"
                    min={-25}
                    max={100}
                    step={1}
                    slotProps={{
                        root: {
                            style: {
                                padding: 0,
                            },
                        },
                        markLabel: {
                            style: {
                                top: '10px',
                                color: 'black',
                            },
                        },
                    }}
                    getAriaValueText={valuetext}
                    marks={marks}
                    value={percentage}
                    onChange={handleSliderChange}
                    disabled={disabled}
                />
            </ListItem>
        </List>
    );
}
