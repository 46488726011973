import { SeinoDialog } from '../../components/Dialog/SeinoDialog.tsx';
import {
    SeinoDialogContainer,
    SeinoDialogContent,
} from '../../components/Dialog/SeinoDialog.styled.tsx';
import * as React from 'react';
import { TargetForm } from './TargetForm.tsx';
import { newTarget } from '../../types/targets';
import { useDialog } from '../../components/Dialog/useDialog.ts';
import { useTargets } from './useTargets.ts';
import { CircularProgress } from '@mui/material';
import { useActiveWorkspaceId } from '../../providers/useActiveWorkspaceId.ts';

export function TargetDialog() {
    const workspaceId = useActiveWorkspaceId();

    const {
        parameters: { id },
    } = useDialog<{ id?: string }>('target');

    const { data, isLoading } = useTargets();
    const target = data?.find(t => t.id === id) || newTarget(workspaceId);

    return (
        <SeinoDialog id="target" maxWidth="lg" fullWidth>
            <SeinoDialogContainer>
                <SeinoDialogContent disablePadding>
                    {isLoading ? (
                        <CircularProgress />
                    ) : (
                        <TargetForm target={target} isNew={!id} />
                    )}
                </SeinoDialogContent>
            </SeinoDialogContainer>
        </SeinoDialog>
    );
}
