import { AnalyticsConnectionType } from '../workspace-management';

type BaseIntegration = {
    id: string;
    field_mappings?: FieldMapping[];
};

export type GoogleAnalyticsIntegration = {
    type: 'google_analytics';
    connection_type: AnalyticsConnectionType;
    views: ConfiguredGoogleAnalyticsView[];
    service_account: string;
} & BaseIntegration;

export type ConfiguredGoogleAnalyticsView = {
    view_id: string;
    market?: string | null;
    version?: 'ua' | 'ga4';
};

export type AvailableGoogleAnalyticsView = {
    id: string;
    name: string;
    account_id: string;
    account_name: string;
};

export type BrazeIntegration = {
    type: 'copernica';
} & BaseIntegration;

export type CanopyDeployIntegration = {
    type: 'deployteq';
    name: string;
    portal_id: number;
    brand_id: number;
} & BaseIntegration;

export type CopernicaIntegration = {
    type: 'copernica';
} & BaseIntegration;

type KlaviyoIntegration = {
    type: 'klaviyo';
} & BaseIntegration;

type MailcampaignsIntegration = {
    type: 'mailcampaigns';
} & BaseIntegration;

type MaileonIntegration = {
    type: 'maileon';
} & BaseIntegration;

type MailkitIntegration = {
    type: 'mailkit';
} & BaseIntegration;

type MarigoldIntegration = {
    type: 'marigold';
} & BaseIntegration;

type SalesforceIntegration = {
    type: 'salesforce';
} & BaseIntegration;

type SMTPeterIntegration = {
    type: 'smtpeter';
} & BaseIntegration;

type WebpowerIntegration = {
    type: 'webpower';
} & BaseIntegration;

type BaseFieldMapping = {
    name: string;
};

export type FieldMappingType = 'null' | 'fixed_value' | 'condition' | 'custom';
export type FieldMapping =
    | (BaseFieldMapping & { type: 'null' })
    | FixedValueFieldMapping
    | ConditionFieldMapping
    | (BaseFieldMapping & { type: 'custom'; sql: string });

type FixedValueFieldMapping = BaseFieldMapping & {
    type: 'fixed_value';
    value: string;
};

export type ConditionFieldMapping = BaseFieldMapping & {
    type: 'condition';
    rules: FieldMappingRule[];
};

export type FieldMappingRule = {
    field: string;
    type: 'contains' | 'starts_with' | 'ends_with' | 'regex';
    value: string;
    result: string;
};

export type Integration =
    | BrazeIntegration
    | GoogleAnalyticsIntegration
    | CanopyDeployIntegration
    | CopernicaIntegration
    | KlaviyoIntegration
    | MailcampaignsIntegration
    | MaileonIntegration
    | MailkitIntegration
    | MarigoldIntegration
    | SalesforceIntegration
    | SMTPeterIntegration
    | WebpowerIntegration;

export const isGA = (
    integration: Integration | undefined
): integration is GoogleAnalyticsIntegration => {
    return integration?.type === 'google_analytics';
};

export const isDeployteq = (
    integration: Integration | undefined
): integration is CanopyDeployIntegration => {
    return integration?.type === 'deployteq';
};
