import { PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';
import { AUTH0_API_AUDIENCE, AUTH0_DEFAULT_SCOPE } from '../constants';
import { Auth0Provider, AuthorizationParams } from '@auth0/auth0-react';
import { AppState } from '@auth0/auth0-react/src/auth0-provider';

const clientId = import.meta.env.VITE_AUTH0_CLIENT_ID;
const domain = import.meta.env.VITE_AUTH0_DOMAIN;
const redirectUri = `${window.location.origin}/callback`;

const authorizationParams: AuthorizationParams = {
    redirect_uri: redirectUri,
    audience: AUTH0_API_AUDIENCE,
    scope: AUTH0_DEFAULT_SCOPE,
};

export const Auth0ProviderWithNavigate = ({ children }: PropsWithChildren) => {
    const navigate = useNavigate();

    const handleOnRedirectCallback = (appState?: AppState) => {
        navigate(appState?.returnTo || window.location.pathname);
    };

    if (!clientId || !domain || !redirectUri) {
        return null;
    }

    return (
        <Auth0Provider
            clientId={import.meta.env.VITE_AUTH0_CLIENT_ID}
            domain={import.meta.env.VITE_AUTH0_DOMAIN}
            authorizationParams={authorizationParams}
            onRedirectCallback={handleOnRedirectCallback}
            cacheLocation={'memory'}
        >
            {children}
        </Auth0Provider>
    );
};
