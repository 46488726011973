import { Stack, Typography } from '@mui/material';
import { ErrorOutline } from '@mui/icons-material';
import React from 'react';
import { useActiveUser } from '../../providers/CurrentUserProvider.hooks.ts';
import { ModifiedBy } from '../../types/user';

interface ConflictAlertProps {
    modifiedBy?: ModifiedBy;
    isLoading?: boolean;
}

export function ConflictAlert({
    isLoading = false,
    modifiedBy,
}: ConflictAlertProps) {
    const user = useActiveUser();

    const modifier =
        user?.memberId === modifiedBy?.id ? 'You' : modifiedBy?.name;

    if (isLoading) {
        return null;
    }

    return (
        <Stack direction="row" alignItems="center" spacing={1}>
            <ErrorOutline sx={{ fontSize: 20, color: 'error.main' }} />
            <Typography variant="caption" color="error.main">
                {modifier} saved a newer version.
            </Typography>
        </Stack>
    );
}
