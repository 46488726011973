import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { fetchGaViews } from '../../../../api-client';
import { AvailableGoogleAnalyticsView } from '../../../../types/integration';

export const useAvailableViews = (workspaceId: string) => {
    return useQuery(
        ['workspaces', workspaceId, 'google-analytics-views'],
        async (): Promise<AvailableGoogleAnalyticsView[]> => {
            return (await fetchGaViews({ workspaceId })).data.data;
        }
    );
};

export const useRefreshAvailableViews = (workspaceId: string) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async () => {
            return (await fetchGaViews({ workspaceId })).data.data;
        },
        onSuccess: data => {
            queryClient.setQueryData(
                ['workspaces', workspaceId, 'google-analytics-views'],
                data
            );
        },
    });
};
