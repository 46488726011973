import { SearchRounded } from '@mui/icons-material';
import { alpha, InputBase, styled } from '@mui/material';
import { ChangeEvent, useCallback, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    flexGrow: 1,
    borderRadius: 5,
    backgroundColor: alpha(theme.palette.common.black, 0.03),
    marginLeft: 0,
    [theme.breakpoints.up('sm')]: {
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    fontSize: '.8em',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    width: '100%',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        fontSize: '.8em',
    },
}));

export const SearchInput = ({
    setSearch,
    placeholder,
}: {
    setSearch: (value: string) => void;
    placeholder?: string;
}) => {
    const [searchText, setSearchText] = useState('');
    const updateSearch = useDebouncedCallback(setSearch, 200);

    const handleOnChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            setSearchText(event.target.value);
            updateSearch(event.target.value);
        },
        [setSearchText, updateSearch]
    );

    return (
        <Search>
            <SearchIconWrapper>
                <SearchRounded fontSize="small" />
            </SearchIconWrapper>
            <StyledInputBase
                placeholder={placeholder || 'Search…'}
                value={searchText}
                onChange={handleOnChange}
            />
        </Search>
    );
};
