import { Stack } from '@mui/material';
import { CircularProgressWithLabel } from '../Progress/CircularProgressWithLabel';
import { useContext } from 'react';
import { DuckContext } from '../../providers/duck';
import { useIntervalRender } from '../../hooks/use-interval-render';
import { useActiveWorkspaceId } from '../../providers/useActiveWorkspaceId.ts';

type ExplorerGridLoaderProps = { dataset?: string };
export const ExplorerGridLoader = ({ dataset }: ExplorerGridLoaderProps) => {
    const workspaceId = useActiveWorkspaceId();
    const duck = useContext(DuckContext);
    const progress = dataset
        ? duck?.downloadProgress?.current?.[workspaceId]?.[dataset]
        : undefined;

    useIntervalRender(100);

    return (
        <Stack
            justifyContent="center"
            alignItems="center"
            flexGrow={1}
            mt={20}
            spacing={2}
        >
            <CircularProgressWithLabel value={progress} />
        </Stack>
    );
};
