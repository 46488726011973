import { Button, Stack, styled } from '@mui/material';
import { format } from 'date-fns';
import React, { forwardRef } from 'react';

import { useDateRangePicker } from './useDateRangePicker.tsx';
import { ArrowDropDown } from '@mui/icons-material';
import { DateRangeWithPreset } from './reducer.tsx';
import { presetLabel } from './presetDefinitions.ts';
import { useDateRange } from '../../hooks/use-date-range.ts';

export const PickerToggle = forwardRef<HTMLButtonElement>((x, ref) => {
    const { dispatch, allowCompare } = useDateRangePicker();
    const { dateRanges } = useDateRange();

    const mainStart = format(dateRanges.main.start, getFormat(dateRanges.main));
    const mainEnd = format(dateRanges.main.end, 'd MMM yyyy');
    const compareStart = format(
        dateRanges.compare.start,
        getFormat(dateRanges.compare)
    );
    const compareEnd = format(dateRanges.compare.end, 'd MMM yyyy');
    const showCompare =
        allowCompare && dateRanges.compare.preset !== 'disabled';

    return (
        <PickerButton
            ref={ref}
            onClick={() => dispatch({ type: 'toggle', dateRanges })}
            endIcon={<ArrowDropDown />}
        >
            <Stack>
                <PickerText>
                    <PresetLabel>
                        {presetLabel(dateRanges.main.preset)}
                    </PresetLabel>
                    {`${mainStart} – ${mainEnd}`}
                </PickerText>

                {showCompare && (
                    <CompareLabel>{`vs. ${compareStart} – ${compareEnd}`}</CompareLabel>
                )}
            </Stack>
        </PickerButton>
    );
});

const PickerButton = styled(Button)({
    textTransform: 'none',
    minHeight: '48px',
});

const PickerText = styled('span')({
    fontSize: '14px',
    lineHeight: '18px',
    fontWeight: 500,
    textAlign: 'right',
    letterSpacing: '0.15px',
});

const PresetLabel = styled('span')(({ theme }) => ({
    fontSize: '11px',
    fontWeight: 500,
    background: theme.palette.action.hover,
    letterSpacing: '0.8px',
    color: '#3c4043',
    height: '16px',
    lineHeight: '16px',
    margin: 'auto 6px auto auto',
    padding: '0 4px',
}));

const CompareLabel = styled('span')({
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    textAlign: 'right',
    letterSpacing: '0.3px',
});

const getFormat = ({ start, end }: DateRangeWithPreset<unknown>) => {
    if (start.getFullYear() != end.getFullYear()) {
        return 'd MMM yyyy';
    }

    if (start.getMonth() != end.getMonth()) {
        return 'd MMM';
    }

    return 'd';
};
