import { Box, useTheme } from '@mui/material';
import {
    FunnelCustomLayerProps,
    FunnelDatum,
    ResponsiveFunnel,
} from '@nivo/funnel';
import { useDashboardDataset } from '../../../../../hooks/use-dashboard-dataset.ts';
import { formatNumber, NumberFormat } from '../../../../../utils/number-format';
import { useLocale } from '../../../../../providers/LocaleProvider.hooks';
import { hsl } from 'd3-color';
import { useMemo } from 'react';

export interface ChartData {
    id: string;
    value: number;
    label?: string;
}

interface FunnelChartProps {
    data: ChartData[];
    dataset: ReturnType<typeof useDashboardDataset>['data'];
}

const Legends = (layerProps: FunnelCustomLayerProps<FunnelDatum>) => {
    return (
        <svg>
            {layerProps.parts.map(({ data }, i: number) => {
                const y = (layerProps.parts[i].y + layerProps.parts[i].y1) / 2;
                return (
                    <svg key={data.id}>
                        <rect
                            x={0}
                            y={y - 15}
                            width={15}
                            height={15}
                            fill={layerProps.parts[i].color}
                        />{' '}
                        <text x={25} y={y - 2} style={{ fontSize: 14 }}>
                            {data.label}
                        </text>
                    </svg>
                );
            })}
        </svg>
    );
};

const Conversion = (layerProps: FunnelCustomLayerProps<FunnelDatum>) => {
    const theme = useTheme();
    const locale = useLocale();

    const fontSize = 24;
    const conversions = layerProps.parts.map(({ data }, i) => {
        if (i > layerProps.parts.length - 2 || !data.value) {
            return <text key={data.label}></text>;
        }

        // Calculate the vertical center for the current and next part
        const nextPartCenterY =
            (layerProps.parts[i + 1].y0 + layerProps.parts[i + 1].y1) / 2;

        return (
            <text
                key={data.label}
                textAnchor="end"
                x={layerProps.width}
                y={nextPartCenterY + layerProps.parts.length}
                style={{ fontSize, opacity: 0.2, fontWeight: 'bolder' }}
            >
                {formatNumber(
                    layerProps.parts[i + 1]?.data.value / data.value,
                    NumberFormat.Percentage,
                    locale
                )}
            </text>
        );
    });

    // Calculate the vertical center for the first part for the "Conversion" text
    const firstPartCenterY =
        (layerProps.parts[0].y0 + layerProps.parts[0].y1) / 2;

    return (
        <svg>
            <text
                textAnchor="end"
                x={layerProps.width}
                y={firstPartCenterY + layerProps.parts.length}
                style={{
                    fontSize,
                    color: theme.palette.info.main,
                    opacity: 0.2,
                    fontWeight: 'bolder',
                }}
            >
                Conversion
            </text>
            {conversions}
        </svg>
    );
};

const generateColorPalette = (
    data: ChartData[],
    baseColor: string
): string[] => {
    const baseColorHSL = hsl(baseColor);

    const steps = data.length;
    const stepSize = 0.3; // 10% darker each step

    return Array.from({ length: steps }, (_, i) => {
        const darkerColor = baseColorHSL.darker(i * stepSize);
        return darkerColor.toString();
    });
};

export const FunnelChart = ({ data, dataset }: FunnelChartProps) => {
    const locale = useLocale();
    const theme = useTheme();

    const mappedData = useMemo(
        () =>
            data.map(d => ({
                ...d,
                label: dataset?.funnelMetrics[d.id]?.label || d.id,
            })),
        [data, dataset?.funnelMetrics]
    );

    const colorPalettes = generateColorPalette(
        mappedData,
        theme.palette.secondary.main
    );

    return (
        <Box width="100%" height="300px">
            <ResponsiveFunnel
                data={mappedData}
                layers={['separators', 'parts', 'labels', Legends, Conversion]}
                margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
                borderWidth={0}
                valueFormat={v => formatNumber(v, NumberFormat.Generic, locale)}
                theme={{
                    labels: {
                        text: {
                            fontSize: 24,
                            fontWeight: 'bold',
                            fill: theme.palette.text.primary,
                        },
                    },
                }}
                borderColor={'#000'}
                interpolation="linear"
                currentBorderWidth={2}
                shapeBlending={0}
                colors={colorPalettes}
                labelColor={{
                    from: 'color',
                    modifiers: [['darker', 3]],
                }}
                beforeSeparatorLength={200}
                beforeSeparatorOffset={20}
                afterSeparatorLength={200}
                afterSeparatorOffset={20}
                currentPartSizeExtension={10}
                motionConfig="stiff"
            />
        </Box>
    );
};
