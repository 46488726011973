import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

export const useLocationChange = (callback: () => void) => {
    const initialLocation = useRef(useLocation());
    const location = useLocation();

    useEffect(() => {
        if (location !== initialLocation.current) {
            callback();
        }
    }, [initialLocation, location, callback]);
};
