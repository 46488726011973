import { useDuckDatasetQuery } from './use-duck-dataset-query';
import { useActiveWorkspace } from '../providers/WorkspaceProvider.hooks';

interface ListType {
    list: string;
}

export const useLists = () => {
    const { workspaceId } = useActiveWorkspace();
    const { data: lists = [] } = useDuckDatasetQuery({
        queryKey: [workspaceId, 'list_members'],
        dataset: 'list_members',
        queryFn: sourceTable => {
            return `
                SELECT DISTINCT list
                FROM ${sourceTable}
                ORDER BY list
            `;
        },
    });

    let listOptions: string[] = [];
    try {
        listOptions = JSON.parse(JSON.stringify(lists)).map(
            ({ list }: ListType) => list
        );
    } catch (e) {
        console.error(`Error parsing list_members: ${e}`);
    }

    return listOptions;
};
