import { DateRange } from '../../components/DateRangePicker/reducer.tsx';
import { BinaryFilter } from '../../queries/filters.ts';
import {
    DataModelSchema,
    isWeightedAvgDataModelField,
    WeightedAverageDataModelField,
} from '../../types/datamodel/schema.ts';
import {
    calculateGranularity,
    Granularity,
} from '../../queries/time-series.ts';
import { dateFormat, parseDate } from '../../utils/date-format.ts';
import { indent } from '../../queries/indent.ts';
import { sqlForFilters } from '../../queries/filters-sql.ts';
import { useActiveWorkspace } from '../../providers/WorkspaceProvider.hooks.ts';
import { Md5 } from 'ts-md5';
import { useDuckDatasetQuery } from '../../hooks/use-duck-dataset-query.ts';
import { Target } from '../../types/targets';
import { sqlForMetric } from '../../queries/metrics-sql.ts';
import { findField } from '../../queries/schema.ts';

export type TargetData<T = Date> = {
    start: T;
    end: T;
    cumulativeValue: number;
    expectedValue: number;
    percentage: number;
};

type TargetQueryOptions = {
    dateRange: DateRange;
    granularity: Granularity;
    metric: string;
    target: number;
    filters: BinaryFilter[];
};

export function getLastNonNullValue(arr: TargetData[]): TargetData | undefined {
    for (let i = arr.length - 1; i >= 0; i--) {
        if (arr[i].cumulativeValue !== null) {
            return arr[i];
        }
    }

    return undefined;
}

export const useTargetData = (target: Target, disabled?: boolean) => {
    const { workspaceId } = useActiveWorkspace();

    const dateRange: DateRange = {
        start: target.dateStart,
        end: target.dateEnd,
    };

    const query: TargetQueryOptions = {
        dateRange,
        granularity: calculateGranularity(dateRange),
        target: target.target,
        filters: target.filters,
        metric: target.metric,
    };

    const queryKey = Md5.hashStr(
        JSON.stringify({ dataset: target.dataset, ...query })
    );

    const {
        data = [],
        isLoading,
        error,
    } = useDuckDatasetQuery<TargetData<string>, TargetData[]>({
        queryKey: ['duckdb', workspaceId, 'time-series', queryKey],
        dataset: target.dataset,
        queryFn: (sourceTable, _, schema) =>
            renderSql(schema, sourceTable, query),
        processResult: rows =>
            rows.map(row => ({
                ...row,
                start: parseDate(row.start),
                end: parseDate(row.end),
            })),
        queryOptions: {
            enabled: !!target.metric && !disabled && !isNaN(target.target),
        },
    });

    return {
        error,
        isLoading,
        data,
        firstValue: data.find(r => r.cumulativeValue !== null),
        lastValue: getLastNonNullValue(data),
    };
};

function renderSql(
    schema: DataModelSchema,
    sourceTable: string,
    query: TargetQueryOptions
) {
    const field = findField(schema)(query.metric);
    if (!field) {
        throw new Error('todo: error handling');
    }

    if (isWeightedAvgDataModelField(field)) {
        return renderSqlWeightedAvg(schema, sourceTable, query, field);
    }

    const start = dateFormat(query.dateRange.start);
    const end = dateFormat(query.dateRange.end);

    return `
WITH
  y AS (
    SELECT
      DATE_TRUNC('${query.granularity}', ${schema.dateField}) AS x,
      (SUM(${query.metric}))::DOUBLE AS value
    FROM ${sourceTable}
    WHERE ${schema.dateField} BETWEEN date '${start}' AND date '${end}'
${indent(sqlForFilters(schema, query.filters), 6)}
    GROUP BY x
  ),
  
  x AS (
    SELECT
      DATE_TRUNC('${query.granularity}', d) AS x,
      CAST(MIN(d) AS DATE) AS start,
      CAST(MAX(d) AS DATE) AS end,
    FROM (SELECT UNNEST(GENERATE_SERIES(date '${start}', date '${end}', INTERVAL 1 DAY)) AS d)
    GROUP BY x
  )

SELECT
  x.start::string as start,
  x.end::string as end,
  IF(x.start < TODAY(), SUM(y.value) OVER (ORDER BY x.start), null) AS cumulativeValue,
  (((x.end - '${start}'::DATE) / ('${end}'::DATE - '${start}'::DATE)) * ${
        query.target
    }) AS expectedValue,
  IF(x.start < TODAY(), SUM(y.value) OVER (ORDER BY x.start), null) / ${
      query.target
  } as percentage
FROM x
LEFT JOIN y USING (x)
ORDER BY start
`;
}

function renderSqlWeightedAvg(
    schema: DataModelSchema,
    sourceTable: string,
    query: TargetQueryOptions,
    field: WeightedAverageDataModelField
) {
    const start = dateFormat(query.dateRange.start);
    const end = dateFormat(query.dateRange.end);

    const denominator = findField(schema)(field.agg.denominator);
    const numerator = findField(schema)(field.agg.numerator);

    if (!denominator || !numerator) {
        throw new Error('todo: error handling');
    }

    return `
WITH
  y AS (
    SELECT 
      DATE_TRUNC('${query.granularity}', ${schema.dateField}) AS x,
      ${sqlForMetric(denominator)} as denominator,
      ${sqlForMetric(numerator)} as numerator,
    FROM ${sourceTable}
    WHERE ${schema.dateField} BETWEEN date '${start}' AND date '${end}'
${indent(sqlForFilters(schema, query.filters), 6)}
    GROUP BY x
  ),
  
  x AS (
    SELECT
      DATE_TRUNC('${query.granularity}', d) AS x,
      CAST(MIN(d) AS DATE) AS start,
      CAST(MAX(d) AS DATE) AS end,
    FROM (SELECT UNNEST(GENERATE_SERIES(date '${start}', date '${end}', INTERVAL 1 DAY)) AS d)
    GROUP BY x
  )

SELECT
  x.start::string as "start",
  x.end::string as "end",
  IF(x.start < today(), 1 * sum(numerator) over (order by x.start) / sum(denominator) over (order by x.start), null) AS cumulativeValue,
  (${query.target})::double as expectedValue,
  IF(x.start < today(), IF(x.start < today(), 1 * sum(numerator) over (order by x.start) / sum(denominator) over (order by x.start), null), null) / 0.05 as percentage
FROM x
LEFT JOIN y USING (x)
ORDER BY start
`;
}
