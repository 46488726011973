import axios from 'axios';
import { ExplorerView } from '../../components/ExplorerGrid/grid/utils/gridView';
import { getRustAPIUrl } from '../../utils/api';
import { FetchWorkspaceProps } from '../workspace';

export const fetchViews = async ({ workspaceId }: FetchWorkspaceProps) => {
    const result = await axios.get(
        `${getRustAPIUrl()}/workspaces/${workspaceId}/explorer-views`
    );

    return result.data;
};

interface SaveViewProps extends FetchWorkspaceProps {
    view: ExplorerView;
}

export const saveView = async ({
    workspaceId,
    view: { id, config, title, createdAt, modifiedAt },
}: SaveViewProps) => {
    return axios.put<ExplorerView>(
        `${getRustAPIUrl()}/workspaces/${workspaceId}/explorer-views/${id}`,
        {
            viewId: id,
            config: JSON.stringify({ ...config, title, createdAt, modifiedAt }),
        }
    );
};

interface DeleteViewProps extends FetchWorkspaceProps {
    viewId: string;
}

export const deleteView = async ({ workspaceId, viewId }: DeleteViewProps) => {
    return axios.delete(
        `${getRustAPIUrl()}/workspaces/${workspaceId}/explorer-views/${viewId}`
    );
};
