import { Box, Typography, useTheme } from '@mui/material';
import { getPercentageDifference } from '../../../../../utils/get-percentage-difference';
import { formatNumber, NumberFormat } from '../../../../../utils/number-format';
import { MetricChartLine } from './MetricChartLine';
import { PerformanceCardSkeleton } from './PerformanceCardSkeleton';
import { PerformanceIndicator } from './PerformanceIndicator';
import { MetricData } from './useCompareDateRangeQuery';
import { Localization } from '../../../../../types/datamodel/localization';
import { useLocale } from '../../../../../providers/LocaleProvider.hooks';
import { isEqual } from 'lodash';
import { memo } from 'react';

interface PerformanceCardProps {
    metric?: Metric;
    data?: MetricData;
    isLoading: boolean;
    isSelected: boolean;
    showCompare: boolean;
}

export interface Metric {
    id: string;
    label: string;
    format: NumberFormat;
    moreIsBetter: boolean;
}

const PerformanceCardInner = (props: PerformanceCardProps) => {
    const { data, isSelected, metric } = props;
    const locale = useLocale();
    const theme = useTheme();

    if (!metric || !data) {
        return <PerformanceCardSkeleton />;
    }

    const { label = 'none', format, moreIsBetter } = metric;

    if (!data?.chartData || !data?.totals) {
        return null;
    }

    const lineData = data.chartData
        .map(x => x.current)
        .filter(p => p.y !== null);

    const { current, lastPeriod } = data.totals;

    const percentageChangePeriod = getPercentageDifference(lastPeriod, current);

    const sxSelected = {
        background: theme.palette.background.default,
        boxShadow: '0 2px 4px rgba(0,0,0, 0.25)',
        border: `1px solid rgba(0, 157, 187, 0.33)`,
    };

    return (
        <Box
            sx={theme => ({
                display: 'flex',
                cursor: 'pointer',
                flexDirection: 'column',
                padding: theme.spacing(2),
                border: '1px solid #fff',
                '&:hover': sxSelected,
                ...(isSelected ? sxSelected : {}),
            })}
        >
            <Box sx={{ height: 84 }}>
                <MetricChartLine format={format} data={lineData} />
            </Box>

            {metricTitle(label)}
            {metricValue(current, format, locale)}

            {props.showCompare && (
                <PerformanceIndicator
                    period={percentageChangePeriod}
                    moreIsBetter={moreIsBetter}
                />
            )}
        </Box>
    );
};
const metricTitle = (title: string) => (
    <Typography
        variant="body2"
        sx={{
            textTransform: 'uppercase',
            whiteSpace: 'nowrap',
            letterSpacing: '0.025rem',
            lineHeight: '2.5rem',
            fontWeight: 'bold',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        }}
    >
        {title}
    </Typography>
);
const metricValue = (
    value: number | undefined,
    format: NumberFormat,
    locale: Localization
) => (
    <Typography
        variant="h5"
        sx={{
            lineHeight: '1rem',
            letterSpacing: '0.025rem',
        }}
    >
        {formatNumber(value, format, locale)}
    </Typography>
);

export const PerformanceCard = memo(PerformanceCardInner, isEqual);
