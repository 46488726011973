import { Box, styled } from '@mui/material';
import { grey } from '@mui/material/colors';

export const StyledList = styled(Box)`
    font-size: 12px;
    flex-direction: column;
    justify-content: start;
`;

export const GroupHeader = styled(Box)({
    fontWeight: 'bold',
    borderBottom: `1px solid ${grey[100]}`,
    padding: '8px 10px',
});
