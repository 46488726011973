import { ICellRendererParams } from '@ag-grid-community/core';

export const LinkComponent = ({ value, colDef }: ICellRendererParams) =>
    value && (
        <a
            href={value}
            style={{ color: 'black' }}
            target="_blank"
            rel="noreferrer"
        >
            {colDef?.headerName}
        </a>
    );
