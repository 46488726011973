import { Grid } from '@mui/material';
import {
    updateWidget,
    WidgetConfig,
    WidgetType,
} from '../../../../types/dashboard';
import {
    CampaignPerformanceWidgetType,
    FunnelWidgetType,
    HighLowWidgetType,
    SubscriberGrowthType,
    TableWidgetType,
} from '../MapWidget';
import { WidgetConfigForm } from './WidgetConfigForm';
import { useDashboardState } from '../../../../pages/Dashboard/useDashboardState.ts';

interface WidgetConfigPanelProps {
    selectedConfig: WidgetConfig;
    type:
        | WidgetType
        | typeof CampaignPerformanceWidgetType
        | typeof FunnelWidgetType
        | typeof TableWidgetType
        | typeof HighLowWidgetType
        | typeof SubscriberGrowthType;
}

export const WidgetConfigPanel = (props: WidgetConfigPanelProps) => {
    const { localDashboard, saveLocal } = useDashboardState();

    const selectedConfig = {
        ...props.selectedConfig,
        type: props.type,
    };

    const applyChange = (
        change: Partial<WidgetConfig>,
        widget: WidgetConfig
    ) => {
        const newConfig = {
            ...widget,
            ...change,
        };

        saveLocal(
            updateWidget(localDashboard, {
                ...newConfig,
            })
        );
    };

    return (
        <Grid
            container
            p={4}
            mb={2}
            sx={{ background: 'rgba(0,0,0,0.02)', borderRadius: 1.5 }}
        >
            <Grid item xs={12}>
                <WidgetConfigForm
                    currentConfig={selectedConfig}
                    onChange={change => applyChange(change, selectedConfig)}
                />
            </Grid>
        </Grid>
    );
};
