import { useLocale } from '../../providers/LocaleProvider.hooks';
import { EngagementRow } from '../../hooks/use-engagement';
import {
    alpha,
    Box,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
} from '@mui/material';
import { getEngagementColor } from './segments';
import { SegmentMarker } from './SegmentMarker';
import { formatNumber } from '../../utils/number-format';
import React, { useContext, useMemo } from 'react';
import { EngagementContext } from './EngagementContext';
import { sumBy } from 'lodash';
import { Download } from '@mui/icons-material';
import { useDuckExecute } from '../../hooks/use-duck-execute';
import { download } from '../../utils/download';
import { dateFormat } from '../../utils/date-format';
import { format, subMonths } from 'date-fns';

type EngagementTableProps = {
    data: EngagementRow[];
};

type CustomerSegment = { customer_id: number; segment_id: number };

export const EngagementTable = ({ data }: EngagementTableProps) => {
    const date = dateFormat(data[0].date);
    const { segment, setSegment } = useContext(EngagementContext);
    const locale = useLocale();
    const total = sumBy(data, 'size');
    const lookupData = useMemo(() => {
        const lookup: Record<number, Record<number | string, number>> = {};
        for (const { segment_id, inflow } of data) {
            lookup[segment_id] = {};
            for (const inflowRow of inflow.toArray()) {
                lookup[segment_id][inflowRow.segment_id ?? 'new'] =
                    inflowRow.size;
            }
        }

        return lookup;
    }, [data]);

    const { status, execute } = useDuckExecute<CustomerSegment>({
        dataset: `engagement_customers_${date}_000000000000`,
    });

    const handleDownload = async (id: number, name: string) => {
        if (status !== 'ready') {
            return;
        }

        const result = await execute(
            sourceTable =>
                `select unnest(customer_ids) as customer_id from ${sourceTable} where segment_id = '${id}'`
        );

        return download(
            'customer_id,segment\n' +
                result.map(row => `${row.customer_id},${name}\n`).join(''),
            `${date} - ${name}.csv`
        );
    };

    const segmentNamesById = useMemo(
        () =>
            Object.fromEntries(
                data.map(row => [row.segment_id, row.segment_name])
            ),
        [data]
    );

    const handleDownloadAll = async () => {
        if (status !== 'ready') {
            return;
        }

        const result = await execute(
            sourceTable =>
                `select unnest(customer_ids) as customer_id, segment_id from ${sourceTable} order by segment_id, customer_id`
        );

        return download(
            'customer_id,segment\n' +
                result
                    .map(
                        row =>
                            `${row.customer_id},${
                                segmentNamesById[row.segment_id]
                            }\n`
                    )
                    .join(''),
            `${date} - All segments.csv`
        );
    };

    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell></TableCell>
                    <TableCell
                        colSpan={7}
                        sx={theme => ({
                            borderRight: `1px solid ${theme.palette.divider}`,
                        })}
                    >
                        Segment details
                    </TableCell>
                    <TableCell colSpan={data.length + 1}>
                        Inflow breakdown vs.{' '}
                        {format(subMonths(data[0].date, 1), 'MMMM yyyy')}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell sx={{ padding: 0, width: 32 }}></TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell align="right">Size</TableCell>
                    <TableCell align="right">% of total</TableCell>
                    <TableCell align="right">Days since last click</TableCell>
                    <TableCell align="right">Click frequency</TableCell>
                    <TableCell align="right">Click rate</TableCell>
                    <TableCell
                        align="center"
                        sx={theme => ({
                            borderRight: `1px solid ${theme.palette.divider}`,
                        })}
                    >
                        Download
                    </TableCell>
                    {data.map(x => (
                        <TableCell key={x.segment_id} align="center">
                            <Tooltip
                                title={`Inflow from ${x.segment_name} segment of previous period`}
                                placement="top"
                            >
                                <Box>
                                    <SegmentMarker segment={x.segment_id} />
                                </Box>
                            </Tooltip>
                        </TableCell>
                    ))}
                    <TableCell align="center">New</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map(a => (
                    <TableRow
                        key={a.segment_id}
                        className="striped interactive"
                        onClick={() => setSegment(a.segment_id)}
                        sx={{
                            background:
                                segment === a.segment_id
                                    ? alpha(
                                          getEngagementColor(a.segment_id),
                                          0.05
                                      )
                                    : undefined,
                        }}
                    >
                        <TableCell
                            sx={{ paddingX: 0, paddingY: 1.625 }}
                            align="right"
                        >
                            <SegmentMarker
                                segment={a.segment_id}
                                active={segment === a.segment_id}
                            />
                        </TableCell>
                        <TableCell>{a.segment_name}</TableCell>
                        <TableCell align="right">
                            {formatNumber(a.size, 'number', locale)}
                        </TableCell>
                        <TableCell align="right">
                            {formatNumber(a.size / total, 'percent', locale)}
                        </TableCell>
                        <TableCell align="right">
                            {formatNumber(
                                a.days_since_last_click,
                                'number_rounded',
                                locale
                            )}
                        </TableCell>
                        <TableCell align="right">
                            {formatNumber(
                                a.click_total / a.size,
                                'number',
                                locale,
                                { max_digits: 1 }
                            )}
                        </TableCell>
                        <TableCell align="right">
                            {formatNumber(
                                a.click_total / a.send_total,
                                'percent',
                                locale
                            )}
                        </TableCell>
                        <TableCell
                            align="center"
                            sx={theme => ({
                                borderRight: `1px solid ${theme.palette.divider}`,
                            })}
                        >
                            <IconButton
                                size="small"
                                disabled={status === 'loading'}
                                onClick={() =>
                                    handleDownload(a.segment_id, a.segment_name)
                                }
                            >
                                <Download />
                            </IconButton>
                        </TableCell>
                        {data.map(b => (
                            <TableCell key={b.segment_id} align="center">
                                {formatNumber(
                                    lookupData[a.segment_id]?.[b.segment_id],
                                    'number',
                                    locale,
                                    { nan_text: '' }
                                )}
                            </TableCell>
                        ))}
                        <TableCell align="center">
                            {formatNumber(
                                lookupData[a.segment_id]?.['new'],
                                'number',
                                locale,
                                { nan_text: '' }
                            )}
                        </TableCell>
                    </TableRow>
                ))}
                <TableRow>
                    <TableCell></TableCell>
                    <TableCell>Total</TableCell>
                    <TableCell align="right">
                        {formatNumber(total, 'number', locale)}
                    </TableCell>
                    <TableCell align="right">
                        {formatNumber(1, 'percent', locale)}
                    </TableCell>
                    <TableCell colSpan={3} />
                    <TableCell
                        align="center"
                        sx={theme => ({
                            borderRight: `1px solid ${theme.palette.divider}`,
                        })}
                    >
                        <IconButton
                            size="small"
                            disabled={status === 'loading'}
                            onClick={() => handleDownloadAll()}
                        >
                            <Download />
                        </IconButton>
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );
};
