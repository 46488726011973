import {
    SortableContext,
    sortableKeyboardCoordinates,
    useSortable,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { WidgetCard } from '../../components/Dashboard/Widgets/Widget.tsx';
import { Box, Typography } from '@mui/material';
import { reorderWidgets, WidgetConfig } from '../../types/dashboard';
import {
    closestCenter,
    DndContext,
    DragEndEvent,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { useCallback } from 'react';
import { useDashboardState } from './useDashboardState.ts';

export function SortableWidgets() {
    const { saveLocal, localDashboard } = useDashboardState();
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const handleDragEnd = useCallback(
        (event: DragEndEvent) => {
            const { active, over } = event;

            saveLocal(
                reorderWidgets(localDashboard, active.id, over?.id || null)
            );
        },
        [localDashboard, saveLocal]
    );

    return (
        <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            modifiers={[restrictToVerticalAxis]}
            onDragEnd={handleDragEnd}
        >
            <SortableContext
                items={localDashboard.config.widgets}
                strategy={verticalListSortingStrategy}
            >
                {localDashboard.config.widgets.map(widget => (
                    <SortableWidget
                        id={widget.id}
                        title={widget.title}
                        key={widget.id}
                    />
                ))}
            </SortableContext>
        </DndContext>
    );
}

export const SortableWidget = ({
    id,
    title,
}: Pick<WidgetConfig, 'id' | 'title'>) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({ id: id, disabled: false });

    const style = {
        transform: CSS.Translate.toString(transform),
        transition,
    };

    return (
        <div ref={setNodeRef} style={{ padding: '2px' }}>
            <WidgetCard
                style={style}
                {...listeners}
                {...attributes}
                sx={{
                    px: 4,
                    py: 2,
                    maxWidth: '100%',
                    cursor: isDragging ? 'pointer' : 'grab',
                    opacity: isDragging ? 1 : 0.5,
                    zIndex: isDragging ? 99999 : 1,
                    '&:hover': {
                        opacity: 1,
                    },
                }}
            >
                <Box>
                    <Typography
                        variant="h5"
                        color="secondary.main"
                        fontWeight="bold"
                    >
                        {title}
                    </Typography>
                </Box>
            </WidgetCard>
        </div>
    );
};
