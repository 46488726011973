import { IntegrationInfo } from '../IntegrationInfo.tsx';
import { IntegrationType } from '../../../../types/workspace-management';
import { Page } from '../../../../components/Page/Page.tsx';
import { EnableCopernica } from './EnableCopernica.tsx';
import { useActiveWorkspace } from '../../../../providers/WorkspaceProvider.hooks.ts';
import { useState } from 'react';
import { ulid } from 'ulid';
import { useIntegrations } from '../../../../hooks/use-integrations.ts';
import { DisableIntegration } from '../DisableIntegration.tsx';
import { TrackedViews } from './TrackedViews/TrackedViews.tsx';

const integrationType: IntegrationType = 'copernica';

export const CopernicaIntegration = () => {
    const { workspaceId } = useActiveWorkspace();
    const [open, setOpen] = useState(false);
    const [integrationId, setIntegrationId] = useState('');
    const { data } = useIntegrations();
    const integration = data?.find(x => x.type === 'copernica');

    const handleEnableClick = () => {
        setIntegrationId(ulid());
        setOpen(true);
    };

    return (
        <Page padding={false}>
            <IntegrationInfo
                integrationType={integrationType}
                enabled={!!integration}
                enableButtonProps={{ onClick: handleEnableClick }}
                headerContent={
                    integration?.id && (
                        <DisableIntegration
                            integrationId={integration.id}
                            name="Copernica"
                        />
                    )
                }
            />
            {integration?.type === 'copernica' && (
                <TrackedViews integration={integration} />
            )}
            <EnableCopernica
                workspaceId={workspaceId}
                integrationId={integrationId}
                open={open}
                onClose={() => setOpen(false)}
                onEnable={() => setOpen(false)}
            />
        </Page>
    );
};
