import { useSnackbar } from 'notistack';
import {
    useOptimisticRemove,
    useOptimisticUpdate,
} from '../../hooks/use-optimistic-update.ts';
import { Annotation } from '../../types/annotation';
import {
    deleteAnnotation,
    putAnnotation,
} from '../../api-client/annotations.ts';
import { useActiveWorkspaceId } from '../../providers/useActiveWorkspaceId.ts';

export function useAnnotationMutation() {
    const workspaceId = useActiveWorkspaceId();
    const { enqueueSnackbar } = useSnackbar();

    const {
        create,
        isLoading: isUpdating,
        isSuccess,
    } = useOptimisticUpdate<Annotation>(
        async annotation => {
            try {
                await putAnnotation(workspaceId, annotation);
            } catch (err) {
                enqueueSnackbar('Failed to update annotation', {
                    variant: 'error',
                });
                throw err;
            }
        },
        [workspaceId, 'annotations'],
        { invalidateOnSettled: true }
    );

    const { remove } = useOptimisticRemove(
        async annotationId => {
            await deleteAnnotation(workspaceId, annotationId);
        },
        [workspaceId, 'annotations']
    );

    return {
        create,
        update: (annotation: Annotation) => create(annotation),
        isUpdating,
        isSuccess,
        remove,
    };
}
