export const mergeAndMaintainSequence = (
    options: readonly string[] | string[],
    currentItems: string[],
    newItems: string[]
): string[] => {
    const itemsDiff = newItems.filter(item => !currentItems.includes(item));

    const result: string[] = [...currentItems];
    let currentIdx = 0;

    for (const option of options) {
        if (currentItems.includes(option)) {
            const idx = currentItems.indexOf(option);
            if (idx >= currentIdx) {
                currentIdx = idx + 1;
            }
        } else if (itemsDiff.includes(option)) {
            result.splice(currentIdx, 0, option);
            currentIdx++;
        }
    }

    return result;
};
