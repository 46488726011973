import { IntegrationInfo } from '../IntegrationInfo.tsx';
import { useState } from 'react';
import { useIntegrations } from '../../../../hooks/use-integrations.ts';
import { ulid } from 'ulid';
import { useActiveWorkspace } from '../../../../providers/WorkspaceProvider.hooks.ts';
import { EnableKlaviyo } from './EnableKlaviyo.tsx';
import { Typography } from '@mui/material';
import { DisableIntegration } from '../DisableIntegration.tsx';

export const KlaviyoIntegration = () => {
    const { workspaceId } = useActiveWorkspace();

    const [openDialog, setOpenDialog] = useState(false);
    const [createIntegrationId, setCreateIntegrationId] = useState('');
    const { data } = useIntegrations();
    const integration = data?.find(x => x.type === 'klaviyo');
    const isEnabled = Boolean(integration);

    const handleEnableClick = () => {
        setOpenDialog(true);
        setCreateIntegrationId(ulid());
    };

    return (
        <>
            <IntegrationInfo
                integrationType={'klaviyo'}
                enabled={isEnabled}
                enableButtonProps={{ onClick: handleEnableClick }}
                headerContent={
                    integration?.id && (
                        <DisableIntegration
                            integrationId={integration.id}
                            name="Klaviyo"
                        />
                    )
                }
            />
            {isEnabled && (
                <Typography>
                    The connection to your Klaviyo account has been established
                    successfully.
                </Typography>
            )}
            <EnableKlaviyo
                workspaceId={workspaceId}
                integrationId={createIntegrationId}
                open={openDialog}
                onEnable={() => setOpenDialog(false)}
                onClose={() => setOpenDialog(false)}
            />
        </>
    );
};
