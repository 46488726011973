import { ulid } from 'ulid';

export type AccessLevel = 'none' | 'read' | 'read_write' | 'full_access';
export type ObjectType = 'dashboard' | 'explorer_view' | 'workspace';
export type SubjectType = 'user' | 'workspace';
export type Permission = {
    id: string;
    workspaceId: string;
    subjectType: SubjectType;
    subjectId: string;
    subjectName: string;
    subjectEmail?: string;
    objectType: ObjectType;
    objectId: string;
    accessLevel: AccessLevel;
    createdAt: Date;
    lastModifiedAt: Date;
};

export const hasFullAccess = (permission: Permission) =>
    permission.accessLevel === 'full_access';

export const createPermission = (
    workspaceId: string,
    subjectType: SubjectType,
    subjectId: string,
    displayName: string,
    objectType: ObjectType,
    objectId: string,
    accessLevel?: AccessLevel
): Permission => ({
    id: ulid(),
    workspaceId: workspaceId,
    subjectType,
    subjectId,
    subjectName: displayName,
    objectType,
    objectId,
    accessLevel: accessLevel ?? 'none',
    createdAt: new Date(),
    lastModifiedAt: new Date(),
});

export type Operation = 'read' | 'write' | 'delete' | 'share';

export const isPermitted = (
    accessLevel: AccessLevel | undefined,
    operation: Operation
) => {
    if (!accessLevel) {
        return false;
    }

    switch (operation) {
        case 'read':
            return true;
        case 'write':
            return (
                accessLevel === 'read_write' || accessLevel === 'full_access'
            );
        case 'delete':
            return accessLevel === 'full_access';
        case 'share':
            return accessLevel === 'full_access';
        default:
            return false;
    }
};

export const friendlyAccessLevel = (accessLevel: AccessLevel | 'none') => {
    switch (accessLevel) {
        case 'none':
            return 'No access';
        case 'read':
            return 'Can view';
        case 'read_write':
            return 'Can edit';
        case 'full_access':
            return 'Full Access';
    }
};

export const grantAccessLevelDescription = (accessLevel: AccessLevel) => {
    switch (accessLevel) {
        case 'read':
            return `Provide read access. No editing and sharing options.`;
        case 'read_write':
            return `Grant access for editing, without sharing options.`;
        case 'full_access':
            return `Grant full access editing and sharing options.`;
    }
};
