import { createContext } from 'react';
import {
    DateRangePickerDispatch,
    DateRangePickerState,
    DateRanges,
} from './reducer';

export type DateRangePickerContextProps = {
    dispatch: DateRangePickerDispatch;
    state: DateRangePickerState;
    onChange: (dateRanges: DateRanges) => void;
    locale: Locale;
    weekStartsOn: Day;
    calendarCount: number;
    allowCompare: boolean;
};

export const DateRangePickerContext =
    createContext<DateRangePickerContextProps>(undefined as any);
