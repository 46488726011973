import { ProfileSection } from '../ProfileSection';
import { List, ListItem } from '@mui/material';
import { ConfigureSubscription } from './ConfigureSubscription';
import { useActiveUser } from '../../../providers/CurrentUserProvider.hooks';
import { SyntheticEvent, useState } from 'react';
import { useReportingSubscriptions } from './useReportingSubscriptions';

export function WeeklySummarySettings() {
    const { weeklySummarySubscriptions } = useReportingSubscriptions();
    const { workspaces, memberId } = useActiveUser();

    const [expanded, setExpanded] = useState<string | false>(
        weeklySummarySubscriptions.length < 20 ? 'weekly-summary' : false
    );

    const multipleWorkspaces = Boolean(weeklySummarySubscriptions.length > 1);

    const handleChange =
        (panel: string) => (_: SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

    const subscriptionByWorkspace = (workspaceId: string) =>
        weeklySummarySubscriptions.find(
            s => workspaceId === s.payload.workspaceId
        );

    return (
        <ProfileSection.Section
            expanded={expanded === 'weekly-summary' || !multipleWorkspaces}
            onChange={handleChange('weekly-summary')}
        >
            <ProfileSection.Summary id="weekly-summary-header">
                <ProfileSection.Header
                    primary={`Weekly Summary (${weeklySummarySubscriptions.length} / ${workspaces.length} enabled)`}
                    secondary="Receive a weekly summary of your campaign performance"
                />
            </ProfileSection.Summary>
            <ProfileSection.Details>
                <List dense disablePadding>
                    {workspaces?.map((workspace, index, array) => (
                        <ListItem
                            key={`reporting-weekly-summary-${workspace.id}`}
                            divider={index !== array.length - 1}
                            disableGutters
                            disablePadding
                        >
                            <ConfigureSubscription
                                workspaceId={workspace.id}
                                memberId={memberId}
                                subscription={subscriptionByWorkspace(
                                    workspace.id
                                )}
                                label={workspace.displayName}
                            />
                        </ListItem>
                    ))}
                </List>
            </ProfileSection.Details>
        </ProfileSection.Section>
    );
}
