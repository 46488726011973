import { createContext, PropsWithChildren } from 'react';
import { DataModelSchema } from '../../types/datamodel/schema';
import { FilterFieldType } from './FilterBuilder';
import { ControllerFieldState } from 'react-hook-form';

export interface FilterBuilderContextProps {
    dataset: string;
    schema: DataModelSchema;
    fieldType: FilterFieldType;
    fieldState: ControllerFieldState;
}

export const Context = createContext<FilterBuilderContextProps | undefined>(
    undefined
);

export const FilterBuilderContext = ({
    children,
    ...props
}: PropsWithChildren<FilterBuilderContextProps>) => {
    return <Context.Provider value={props}>{children}</Context.Provider>;
};
