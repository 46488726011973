import { ulid } from 'ulid';
import { DashboardConfig, WidgetConfig } from './index';
import { ModifiedBy } from '../user';

export const quickInsightsDashboardConfig = (
    currentUser: ModifiedBy
): DashboardConfig => ({
    id: ulid(),
    config: {
        title: 'Quick insights',
        widgets: [
            performanceKPIWidget(['UK']),
            performanceKPIWidget(['NL']),
            funnelPerformanceWidget(),
            revenueTrendLinesGraphWidget(),
            databaseDevelopmentSubscriberGrowthWidget(),
            bulkEmailsHighLowWidget(),
            bulkEmailsTableWidget(),
            serviceMailTableWidget(),
        ],
    },
    version: 0,
    accessLevel: 'full_access',
    modifiedAt: Date.now(),
    modifiedBy: currentUser,
    createdAt: Date.now(),
    createdBy: currentUser,
});

const performanceKPIWidget = (market: string[]): WidgetConfig => ({
    id: ulid(),
    dataset: 'campaign_metrics_by_send_date',
    type: 'campaign_performance',
    title: `Main performance KPI's (${market})`,
    modified: new Date().getTime(),
    filters: [
        {
            member: 'market',
            values: market,
            operator: 'equals',
        },
    ],
    metrics: [
        'revenue_per_session',
        'sent_unique',
        'average_order_value',
        'sessions',
        'clicks_unique',
        'transactions',
    ],
});

const funnelPerformanceWidget = (): WidgetConfig => ({
    id: ulid(),
    dataset: 'campaign_metrics_by_send_date',
    type: 'funnel',
    title: 'Funnel performance',
    modified: new Date().getTime(),
    filters: [],
    metrics: ['sent_unique', 'open', 'clicks_unique', 'transactions'],
});

const revenueTrendLinesGraphWidget = (): WidgetConfig => ({
    id: ulid(),
    dataset: 'campaign_metrics_by_send_date',
    type: 'graph',
    title: 'Revenue trendlines',
    modified: new Date().getTime(),
    filters: [],
    metrics: ['transactions', 'revenue'],
});

const databaseDevelopmentSubscriberGrowthWidget = (): WidgetConfig => ({
    id: ulid(),
    dataset: 'list_members',
    type: 'subscriber_growth',
    title: 'Database development',
    modified: new Date().getTime(),
    filters: [],
    metrics: ['sent_unique', 'open', 'clicks_unique', 'transactions'],
    filteredLists: [
        'Subscribers - Not customers',
        'Subscribers - Customers first-time buyers',
        'Subscribers - Customer repeat buyers',
    ],
});

const bulkEmailsHighLowWidget = (): WidgetConfig => ({
    id: ulid(),
    dataset: 'campaign_metrics_by_send_date',
    type: 'high_low',
    title: 'High / Low performance (bulk emails)',
    modified: new Date().getTime(),
    metrics: [],
    metricsByDimensions: [
        {
            metric: 'open_rate',
            filters: [
                {
                    member: 'mailing_type',
                    values: ['promotional emails'],
                    operator: 'equals',
                },
            ],
            dimension: 'content_name',
        },
        {
            metric: 'cvr_sends',
            filters: [
                {
                    member: 'mailing_type',
                    values: ['promotional emails'],
                    operator: 'equals',
                },
            ],
            dimension: 'content_name',
        },
        {
            metric: 'revenue_per_email',
            filters: [
                {
                    member: 'mailing_type',
                    values: ['promotional emails'],
                    operator: 'equals',
                },
            ],
            dimension: 'content_name',
        },
    ],
});

const bulkEmailsTableWidget = (): WidgetConfig => ({
    id: ulid(),
    dataset: 'campaign_metrics_by_send_date',
    type: 'table',
    title: 'Bulk emails',
    modified: new Date().getTime(),
    filters: [
        {
            member: 'mailing_type',
            values: ['promotional emails'],
            operator: 'equals',
        },
    ],
    metrics: ['sent_unique', 'open', 'cto_unique', 'transactions', 'revenue'],
    dimensions: ['send_date', 'content_name', 'market'],
});

const serviceMailTableWidget = (): WidgetConfig => ({
    id: ulid(),
    dataset: 'campaign_metrics_by_send_date',
    type: 'table',
    title: 'Service emails',
    modified: new Date().getTime(),
    filters: [
        {
            member: 'mailing_type',
            values: ['service mail'],
            operator: 'equals',
        },
    ],
    groupBy: [],
    metrics: ['sent_unique', 'open', 'cto_unique', 'transactions', 'revenue'],
    dimensions: ['content_name'],
});
