import { Box, Stack, Typography, useTheme } from '@mui/material';
import * as React from 'react';

export const HealthChartLegend = () => {
    const theme = useTheme();
    const color = theme.palette.grey['400'];

    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
        >
            <Typography sx={{ fontSize: 10, color }}>30 days ago</Typography>
            <Box
                sx={{
                    height: '1px',
                    background: color,
                    flexGrow: 1,
                    mx: 1,
                }}
            ></Box>
            <Typography sx={{ fontSize: 10, color }}>Yesterday</Typography>
        </Stack>
    );
};
