import { Close, Delete } from '@mui/icons-material';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
} from '@mui/material';
import Button from '@mui/material/Button';
import React, { ReactElement, useState } from 'react';
import { CloseButton } from '../Dialog/LegacyDialog.tsx';
import { LoadingButton } from '@mui/lab';

export default function ConfirmDialog({
    open,
    title,
    content,
    continueText,
    cancelText,
    isDangerousAction = false,
    isExecuting = false,
    onAnyDecision,
    inputLabel,
    inputDefaultValue,
    inputType,
    onContinue,
    onCancel,
}: {
    open: boolean;
    title?: string;
    content?: ReactElement | string;
    inputDefaultValue?: string;
    inputLabel?: string;
    inputType?: React.HTMLInputTypeAttribute;
    continueText?: string;
    cancelText?: string;
    isDangerousAction?: boolean;
    isExecuting?: boolean;
    onAnyDecision?: () => void;
    onContinue?: (input?: string) => void;
    onCancel?: () => void;
}) {
    const [input, setInput] = useState<string>();
    const handleClose = (value: boolean) => {
        onAnyDecision?.();
        value ? onContinue?.(input) : onCancel?.();
        setInput('');
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={() => handleClose(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                {title && (
                    <DialogTitle fontSize={16}>
                        <strong>{title}</strong>
                        <CloseButton
                            aria-label="close"
                            onClick={() => handleClose(false)}
                        >
                            <Close />
                        </CloseButton>
                    </DialogTitle>
                )}
                <DialogContent>
                    <DialogContentText
                        paragraph
                        variant="body2"
                        id="alert-dialog-description"
                    >
                        {content ?? 'Do you want to continue?'}
                    </DialogContentText>
                    {inputLabel && (
                        <TextField
                            autoFocus
                            margin="dense"
                            defaultValue={inputDefaultValue}
                            autoComplete="new-password"
                            id="name"
                            label={inputLabel}
                            type={inputType}
                            fullWidth
                            onChange={({ target: { value } }) =>
                                setInput(value)
                            }
                            variant="standard"
                        />
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        autoFocus={!inputLabel}
                        variant="text"
                        onClick={() => handleClose(false)}
                    >
                        {cancelText ?? 'Cancel'}
                    </Button>
                    <LoadingButton
                        color={isDangerousAction ? 'error' : 'info'}
                        variant="text"
                        endIcon={isDangerousAction ? <Delete /> : null}
                        onClick={() => handleClose(true)}
                        loading={isExecuting}
                    >
                        {continueText ?? 'Continue'}
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </div>
    );
}
