import {
    ObjectType,
    Permission,
    SubjectType,
} from '../types/permission/permission.ts';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { getRustAPIUrl } from '../utils/api.ts';
import { useQuery } from '@tanstack/react-query';

type PermissionResponse = {
    type: string;
    data: Permission[];
};

type RequestParams = {
    object_type: ObjectType;
    object_id: string;
    subject_type?: SubjectType;
    subject_id?: string;
};

const fetchViewPermissions = (
    workspaceId: string,
    requestParams: RequestParams
) => {
    return axios.get<PermissionResponse, AxiosResponse<PermissionResponse>>(
        `${getRustAPIUrl()}/workspaces/${workspaceId}/permissions`,
        {
            params: requestParams,
        }
    );
};

export const usePermissions = (
    workspaceId: string,
    objectId: string,
    objectType: ObjectType
) => {
    const requestParams: RequestParams = {
        object_type: objectType,
        object_id: objectId,
    };

    return useQuery<PermissionResponse['data'], AxiosError>(
        ['usePermissions', workspaceId, objectId, objectType],
        async () => {
            const result = await fetchViewPermissions(
                workspaceId,
                requestParams
            );
            return result.data.data;
        },
        {
            retry: (count, error) => {
                if (error.isAxiosError && error.response) {
                    return error.response.status !== 404;
                }

                return true;
            },
        }
    );
};
