import React, { PropsWithChildren, useRef } from 'react';
import {
    ClickAwayListener,
    Grow,
    IconButton,
    Paper,
    Popper,
} from '@mui/material';
import { CalculateOutlined } from '@mui/icons-material';

export function CalculatorPopper({
    children,
    isOpen,
    onToggle,
    onClose,
}: PropsWithChildren<{
    isOpen: boolean;
    onToggle: () => void;
    onClose: () => void;
}>) {
    const anchorEl = useRef<null | HTMLButtonElement>(null);

    const handleClose = (event: MouseEvent | TouchEvent | React.MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();

        if (
            event.target instanceof HTMLElement &&
            anchorEl.current?.contains(event.target)
        ) {
            return;
        }

        onClose();
    };

    return (
        <>
            <IconButton ref={anchorEl} size="small" onClick={() => onToggle()}>
                <CalculateOutlined />
            </IconButton>
            <Popper
                open={isOpen}
                anchorEl={anchorEl.current}
                transition
                placement="right"
                sx={theme => ({
                    zIndex: theme.zIndex.modal,
                })}
            >
                {({ TransitionProps }) => (
                    <Grow {...TransitionProps}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <div>{children}</div>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
}
