import * as React from 'react';
import { FormDialog } from '../../../components/Dialog/FormDialog.tsx';
import { WorkspaceMember } from '../../../types/workspace-management';
import { TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useChangeUserName } from './useChangeUserName.tsx';

type ChangeNameDialogProps = {
    open: boolean;
    onClose: () => void;
    member: WorkspaceMember;
};

export const ChangeNameDialog = ({
    open,
    onClose,
    member,
}: ChangeNameDialogProps) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: { name: member.name },
    });
    const { mutateAsync, isLoading } = useChangeUserName();

    const onSubmit = handleSubmit(async ({ name }) => {
        await mutateAsync({ memberId: member.id, name });
        onClose();
    });

    return (
        <FormDialog
            disableRestoreFocus={true}
            open={open}
            title="Change name"
            content={
                <TextField
                    label="Name"
                    {...register('name', { required: true })}
                    error={!!errors.name}
                    helperText={errors.name && 'Name cannot be empty'}
                    autoFocus
                />
            }
            confirmButtonText="Save"
            handleClose={onClose}
            handleConfirm={onSubmit}
            confirmButtonProps={{
                loading: isLoading,
            }}
        />
    );
};
