import { Controller, useFormContext } from 'react-hook-form';
import { CollectionForm } from '../Collections';
import { TextField } from '@mui/material';

interface CollectionTitleProps {
    initial?: string;
}

export const CollectionTitle = ({
    initial = 'New Campaign Template',
}: CollectionTitleProps) => {
    const { control } = useFormContext<CollectionForm>();

    return (
        <Controller
            name="title"
            control={control}
            defaultValue={initial}
            render={({ field }) => (
                <TextField
                    {...field}
                    fullWidth
                    label="Campaign template name"
                    size="small"
                />
            )}
        />
    );
};
