import { DeleteOutline } from '@mui/icons-material';
import { Autocomplete, Box, Grid, IconButton, Typography } from '@mui/material';
import { useDashboardDataset } from '../../../../../hooks/use-dashboard-dataset.ts';
import { MetricByDimension } from '../../../../../types/dashboard';
import { Input } from '../WidgetConfigForm.styled';
import { useDatasetSchema } from '../../../../../hooks/use-dataset-schema.ts';
import { SchemaFieldSelect } from '../../../../SchemaFieldSelect/SchemaFieldSelect.tsx';
import { isDimension } from '../../../../../types/datamodel/schema.ts';

interface MetricByDimensionBuilderRowProps {
    datasetName: string;
    metricByDimension: MetricByDimension;
    onChange: (metricByDimension: MetricByDimension) => void;
    onDelete: () => void;
}

export const MetricByDimensionBuilderRow = ({
    datasetName,
    metricByDimension,
    onChange,
    onDelete,
}: MetricByDimensionBuilderRowProps) => {
    const { data: dataset } = useDashboardDataset(datasetName);
    const { data: schema } = useDatasetSchema(datasetName);

    const handleChangeMetric = (value: string | null) =>
        onChange({ metric: value || '', dimension: '' });

    const handleChangeDimension = (value: string | null) =>
        onChange({ ...metricByDimension, dimension: value || '' });

    if (!schema) {
        return schema;
    }

    return (
        <Box display="flex" alignItems="center">
            <Grid item xs={12} sm={5}>
                <Autocomplete
                    multiple={false}
                    key="config-metrics"
                    renderInput={params => (
                        <Input
                            {...params}
                            variant="outlined"
                            fullWidth
                            label="Metric"
                        />
                    )}
                    disableCloseOnSelect={true}
                    getOptionLabel={option =>
                        dataset?.metrics[option]?.label || option
                    }
                    groupBy={option => dataset?.metrics[option].category || ''}
                    value={metricByDimension.metric}
                    options={Object.keys(dataset?.metrics || [])}
                    onChange={(_, newValue) => {
                        handleChangeMetric(newValue);
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={1}>
                <Typography textAlign="center">By</Typography>
            </Grid>
            <Grid item xs={12} sm={5}>
                <SchemaFieldSelect
                    fullWidth
                    schema={schema}
                    value={metricByDimension.dimension}
                    onChange={handleChangeDimension}
                    fieldFilter={isDimension}
                />
            </Grid>
            <Grid item xs={12} sm={0.5}>
                <IconButton onClick={onDelete}>
                    <DeleteOutline />
                </IconButton>
            </Grid>
        </Box>
    );
};
