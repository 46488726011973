import { ulid } from 'ulid';
import { InitialData, ModifiedBy, userToModifiedBy } from '../user';

export const severity = ['note', 'critical'] as const;
export type Severity = (typeof severity)[number];

export type Annotation = {
    id: string;
    workspaceId: string;
    severity: Severity;
    message: string;
    dateStart: Date;
    dateEnd: Date;
    createdAt?: number;
    modifiedAt?: number;
    createdBy?: ModifiedBy;
    modifiedBy?: ModifiedBy;
};

export const newAnnotation = (
    workspaceId: string,
    currentUser: InitialData,
    extra?: Partial<Omit<Annotation, 'workspaceId'>>
): Annotation => ({
    id: ulid(),
    workspaceId,
    severity: 'note',
    message: extra?.message || '',
    dateStart: extra?.dateStart || new Date(),
    dateEnd: extra?.dateEnd || new Date(),
    createdAt: extra?.createdAt || new Date().getTime(),
    modifiedAt: extra?.modifiedAt || new Date().getTime(),
    createdBy: userToModifiedBy(currentUser),
    modifiedBy: userToModifiedBy(currentUser),
});
