import { Typography } from '@mui/material';
import { find } from 'lodash';
import { countries } from '../../../utils/countries';
import { GAView } from './CreateRuleRow';

interface AnalyticsView {
    viewId: string;
    propertyId: string;
    propertyName: string;
    viewName: string;
}

export const getImageForCountryCode = (code: string): JSX.Element => {
    const found = find(countries, ['value', code]);
    if (!found) return <Typography variant="body2">{code}</Typography>;

    return (
        <img
            src={`/img/markets/${code === 'UK' ? 'GB' : code}.png`}
            style={{ verticalAlign: 'text-bottom', paddingRight: '4px' }}
            alt=""
        />
    );
};

/**
 * @prefixValueWithGroup - prefixes the value with the group name,
 * this is a way of allowing the filter functionality to work
 * without having to supply a custom filter function.
 *
 * Tested with some real data (400kb) JSON file and this was unworkably slow.
 *
 * Prefixing the value with the [GROUP_NAME]---[VALUE] format allows the filter
 * to operate including the group name.
 *
 * NOTE:
 * Using this option means the value you receive from the Select will be prefixed
 * so you will have to retrieve it using
 * const [groupName, value] = value.split('---');
 */
export const createGroupedGAViewOptions = (
    views: GAView[] | AnalyticsView[],
    prefixValueWithGroup: boolean = false
) => {
    const result: any[] = [];

    new Set(views.map(({ propertyId }) => propertyId)).forEach(currentId => {
        result.push({
            label: views.filter(({ propertyId }) => propertyId === currentId)[0]
                .propertyName,
            options: views
                .filter(({ propertyId }) => propertyId === currentId)
                .map((view: GAView) => ({
                    value: prefixValueWithGroup
                        ? `${view.propertyName}---${view.viewId}`
                        : view.viewId,
                    label: view.viewName,
                })),
        });
    });

    return result;
};
