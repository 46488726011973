import { Alert, Box, CircularProgress } from '@mui/material';
import {
    ColDef,
    ColumnEvent,
    ColumnMovedEvent,
    ColumnPinnedEvent,
    ColumnResizedEvent,
    ColumnVisibleEvent,
    SortChangedEvent,
} from '@ag-grid-community/core';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridReact, AgGridReactProps } from '@ag-grid-community/react';
import { find } from 'lodash';
import { useCallback } from 'react';
import { DataModelSchema } from '../../../../../types/datamodel/schema';
import { TableWidgetConfig } from '../TableWidget';
import './table-widget.css';
import { getColDefs } from './utils';
import { useLocale } from '../../../../../providers/LocaleProvider.hooks';
import { weightedAverage } from '../../../../ExplorerGrid/grid/utils/weightedAverage';

export type TableChartGridConfig = Pick<
    ColDef,
    'colId' | 'hide' | 'sort' | 'width'
>[];

interface TableChartProps {
    data: any;
    totalRowData: any;
    metrics: string[];
    dimensions: string[];
    groupBy: string[];
    isLoading?: boolean;
    schema: DataModelSchema;
    gridConfig?: TableChartGridConfig;
    onConfigUpdated: (config: Partial<TableWidgetConfig>) => void;
    showCompare: boolean;
}

const EVENT_SOURCES_WHICH_SHOULD_TRIGGER_SAVE = [
    'autosizeColumns',
    'uiColumnDragged',
    'toolPanelUi',
    'uiColumnMoved',
    'uiColumnResized',
    'uiColumnDragged',
    'uiColumnExpanded',
    'uiColumnSorted',
    'contextMenu',
    'columnMenu',
];

const height = '400px';

export const TableChart = ({
    isLoading = false,
    schema,
    data,
    totalRowData,
    metrics,
    dimensions,
    groupBy,
    gridConfig: passedGridState = [],
    onConfigUpdated,
    showCompare,
}: TableChartProps) => {
    const locale = useLocale();
    const update = useCallback(
        (payload: any[]) => {
            const order = payload.map(c => c.colId);
            const sort = (a: string, b: string) =>
                order.indexOf(a) - order.indexOf(b);

            onConfigUpdated({
                data: payload,
                metrics: metrics.toSorted(sort),
                dimensions: dimensions.toSorted(sort),
            });
        },
        [metrics, dimensions, onConfigUpdated]
    );

    const saveChange = (evt: ColumnEvent, prop?: string) => {
        const colState = evt.api.getColumnState();

        const payload = colState.map(c => {
            if (!c.colId) return c;
            type ColumnKey = keyof typeof c;

            const curr = find(passedGridState, ['colId', c.colId]);
            const returnValue = prop
                ? {
                      ...curr,
                      colId: c.colId,
                      [prop]: c[prop as ColumnKey],
                  }
                : { ...curr, colId: c.colId };

            return returnValue;
        });

        update(payload);
    };

    const applyConfig = (
        colDefs: ColDef[] | null | undefined,
        config: TableChartGridConfig
    ) => {
        if (!colDefs) return null;

        const newColDefs = colDefs.map(c => {
            const configItem = find(config, ['colId', c.colId]);
            if (!configItem) return c;

            return {
                ...c,
                ...configItem,
            };
        });

        return newColDefs;
    };

    const colDefs = getColDefs(
        schema,
        dimensions,
        groupBy,
        metrics,
        locale,
        showCompare
    );

    const gridOptions: AgGridReactProps = {
        columnDefs: applyConfig(colDefs, passedGridState),
        rowHeight: 32,
        defaultColDef: {
            minWidth: 100,
            sortable: true,
            resizable: true,
            menuTabs: ['generalMenuTab', 'columnsMenuTab'],
            columnsMenuParams: {
                suppressSyncLayoutWithGrid: false,
            },
        },
        suppressAggFuncInHeader: true,
        groupDisplayType: 'custom',
        animateRows: true,
        aggFuncs: {
            weightedAvg: weightedAverage,
        },
    };

    if (isLoading) {
        return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height={height}
            >
                <CircularProgress />
            </Box>
        );
    }

    if (metrics.length === 0 && dimensions.length === 0) {
        return (
            <Alert severity="warning">
                Select at least 1 dimension or metric.
            </Alert>
        );
    }

    return (
        <div
            className={`widget ag-theme-alpine`}
            style={{
                width: '100%',
                height,
            }}
        >
            <AgGridReact
                gridOptions={gridOptions}
                rowData={data}
                pinnedBottomRowData={totalRowData}
                onColumnMoved={(evt: ColumnMovedEvent) => {
                    if (
                        evt.finished &&
                        EVENT_SOURCES_WHICH_SHOULD_TRIGGER_SAVE.includes(
                            evt.source
                        )
                    ) {
                        saveChange(evt, 'pinned');
                    }
                }}
                onSortChanged={(evt: SortChangedEvent) => {
                    saveChange(evt as ColumnEvent, 'sort');
                }}
                onColumnPinned={(evt: ColumnPinnedEvent) => {
                    saveChange(evt, 'pinned');
                }}
                onColumnResized={(evt: ColumnResizedEvent) => {
                    if (
                        EVENT_SOURCES_WHICH_SHOULD_TRIGGER_SAVE.includes(
                            evt.source
                        ) &&
                        evt.finished
                    ) {
                        saveChange(evt, 'width');
                    }
                }}
                onColumnVisible={(evt: ColumnVisibleEvent) => {
                    if (
                        EVENT_SOURCES_WHICH_SHOULD_TRIGGER_SAVE.includes(
                            evt.source
                        )
                    ) {
                        saveChange(evt, 'hide');
                    }
                }}
            />
        </div>
    );
};
