import { useState } from 'react';
import { FormDialog } from '../../../../components/Dialog/FormDialog';
import { Alert, Link, Stack, TextField, Typography } from '@mui/material';
import { useConnectKlaviyo } from './useConnectKlaviyo.ts';

export const EnableKlaviyo = ({
    workspaceId,
    integrationId,
    open = false,
    onEnable,
    onClose,
}: {
    workspaceId: string;
    integrationId: string;
    open: boolean;
    onEnable: () => void;
    onClose: () => void;
}) => {
    const [apiKey, setApiKey] = useState('');
    const [errorMessage, setErrorMessage] = useState<undefined | string>(
        undefined
    );

    const { mutate, isError, isLoading } = useConnectKlaviyo({
        onSuccess: () => onEnable(),
        onError: () => {
            setErrorMessage(
                'Failed to connect to your Klaviyo account, please check the API key.'
            );
        },
    });

    const handleSubmit = () => {
        mutate({ workspaceId, integrationId, apiKey });
    };

    return (
        <FormDialog
            open={open}
            handleClose={onClose}
            handleConfirm={handleSubmit}
            title="Enable Klaviyo integration"
            error={
                isError &&
                errorMessage && (
                    <Alert severity="error" sx={{ mb: 1 }}>
                        {errorMessage}
                    </Alert>
                )
            }
            content={
                <Stack spacing={2}>
                    <Typography variant="body2">
                        SEINō uses the Klaviyo REST API to retrieve data about
                        your e-mail campaigns. In order to access the API, we
                        need a private, read-only API key.
                    </Typography>

                    <Typography variant="body2">
                        Please create an API key through the{' '}
                        <Link
                            href="https://www.klaviyo.com/create-private-api-key"
                            target="_blank"
                        >
                            API keys page
                        </Link>{' '}
                        in Klaviyo and copy it in the field below.
                    </Typography>
                    <TextField
                        size="small"
                        label="Private API Key"
                        onChange={e => setApiKey(e.target.value)}
                    />
                </Stack>
            }
            confirmButtonText="Confirm"
            confirmButtonProps={{ loading: isLoading }}
        />
    );
};
