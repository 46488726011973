import { useQuery } from '@tanstack/react-query';
import { AlertRuleViolation } from '../types/alerting';
import { sortBy } from 'lodash';
import { getAlertRuleViolations } from '../api-client/alert-rules';
import { useActiveWorkspaceId } from '../providers/useActiveWorkspaceId.ts';

export const useAlertRuleViolations = () => {
    const workspaceId = useActiveWorkspaceId();

    const result = useQuery<AlertRuleViolation[]>({
        queryKey: [workspaceId, 'alert-rule-violations'],
        queryFn: async () => {
            return getAlertRuleViolations(workspaceId);
        },

        // todo: remove
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        staleTime: Infinity,
    });

    return {
        ...result,
        data: result.data && sortBy(result.data, 'title'),
    };
};
