import { EditModeComponent } from '../../components/Dashboard/EditMode/EditModeComponent.tsx';
import { AddWidgetButton } from '../../components/Dashboard/Widgets/AddWidgetButton.tsx';
import { Box } from '@mui/material';
import {
    addWidget,
    defaultWidgetOfType,
    WidgetType,
} from '../../types/dashboard';
import { useHasDataset } from '../../hooks/use-datasets.ts';
import { useDashboardState } from './useDashboardState.ts';

export function AddWidget() {
    const hasSubscribersDataset = useHasDataset('list_members');
    const { saveLocal, localDashboard } = useDashboardState();

    const handleAddWidget = async (type: WidgetType) =>
        saveLocal(addWidget(localDashboard, defaultWidgetOfType(type)));

    return (
        <Box
            display="flex"
            width="100%"
            justifyContent={{ md: 'space-between' }}
            gap={2}
            flexWrap="wrap"
        >
            <EditModeComponent
                component={
                    <AddWidgetButton
                        label="Graph"
                        type={'graph'}
                        onClick={handleAddWidget}
                    />
                }
            />
            <EditModeComponent
                component={
                    <AddWidgetButton
                        label="Funnel"
                        type={'funnel'}
                        onClick={handleAddWidget}
                    />
                }
            />
            <EditModeComponent
                component={
                    <AddWidgetButton
                        label="High low"
                        type={'high_low'}
                        onClick={handleAddWidget}
                    />
                }
            />
            <EditModeComponent
                component={
                    <AddWidgetButton
                        label="Table"
                        type={'table'}
                        onClick={handleAddWidget}
                    />
                }
            />
            <EditModeComponent
                component={
                    <AddWidgetButton
                        label="Performance"
                        type={'campaign_performance'}
                        onClick={handleAddWidget}
                    />
                }
            />
            {hasSubscribersDataset && (
                <EditModeComponent
                    component={
                        <AddWidgetButton
                            label="Subscriber"
                            type={'subscriber_growth'}
                            onClick={handleAddWidget}
                        />
                    }
                />
            )}

            <EditModeComponent
                component={
                    <AddWidgetButton
                        label="Target"
                        type={'target'}
                        onClick={handleAddWidget}
                    />
                }
            />
        </Box>
    );
}
