import { Day, getWeek } from 'date-fns';
import { range } from 'lodash';

type DayFormatWidth = 'narrow' | 'short' | 'abbreviated' | 'wide';

export const getWeekNumber = (date: Date, weekStartsOn: Day) =>
    getWeek(date, { weekStartsOn, firstWeekContainsDate: 4 });

export const getWeekdays = (
    locale: Locale,
    width: DayFormatWidth,
    weekStartsOn: Day
) => {
    const weekdays = range(7).map(i => locale.localize?.day(i, { width }));

    return [
        ...weekdays.slice(weekStartsOn),
        ...weekdays.slice(0, weekStartsOn),
    ];
};
