import { IntegrationInfo } from '../IntegrationInfo.tsx';
import { IntegrationType } from '../../../../types/workspace-management';
import { ViewList } from './ViewList.tsx';
import {
    integrationsQueryKey,
    useIntegrations,
} from '../../../../hooks/use-integrations.ts';
import { Page } from '../../../../components/Page/Page.tsx';
import { Stack } from '@mui/material';
import { ConnectionInfo } from './ConnectionInfo.tsx';
import { DisableIntegration } from '../DisableIntegration.tsx';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { connectGoogleAnalytics } from '../../../../api-client';

import { useActiveWorkspaceId } from '../../../../providers/useActiveWorkspaceId.ts';

const integrationType: IntegrationType = 'google_analytics';

export const GoogleAnalyticsIntegrationPage = () => {
    const workspaceId = useActiveWorkspaceId();
    const { data } = useIntegrations();

    const integrationId = data?.find(x => x.type === 'google_analytics')?.id;

    const queryClient = useQueryClient();
    const { mutateAsync, isLoading } = useMutation({
        mutationFn: () => connectGoogleAnalytics(workspaceId),
        onSettled: () =>
            queryClient.invalidateQueries(integrationsQueryKey(workspaceId)),
    });

    return (
        <Page padding={false}>
            <IntegrationInfo
                integrationType={integrationType}
                enabled={!!integrationId}
                enableButtonProps={{
                    onClick: () => mutateAsync(),
                    loading: isLoading,
                }}
                headerContent={
                    integrationId && (
                        <DisableIntegration
                            integrationId={integrationId}
                            name="Google Analytics"
                        />
                    )
                }
            />
            {integrationId && (
                <Stack spacing={2}>
                    <ConnectionInfo integrationId={integrationId} />
                    <ViewList integrationId={integrationId} />
                </Stack>
            )}
        </Page>
    );
};
