import { Box, CircularProgress, Stack, Toolbar } from '@mui/material';
import styled from 'styled-components';
import { GraphicLogo } from '../../components/Graphics/GraphicLogo';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useTimeout } from 'usehooks-ts';
import { captureMessage } from '@sentry/react';

const SpinnerContainer = styled.div`
    align-self: center;
    height: 96svh;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const LeftSide = styled(Box)`
    display: flex;
    align-items: center;
    gap: 1.5rem;
    padding-left: 8px;
`;

export const Callback = () => {
    const navigate = useNavigate();
    const { isAuthenticated, isLoading } = useAuth0();

    useTimeout(() => {
        captureMessage('User is stuck at callback screen for 5s', {
            level: 'error',
            extra: { isAuthenticated, isLoading },
        });

        if (!isLoading) {
            navigate('/');
        }
    }, 5000);

    return (
        <Stack alignContent="center">
            <Toolbar>
                <LeftSide>
                    <GraphicLogo onClick={() => navigate('/')} />
                </LeftSide>
            </Toolbar>
            <SpinnerContainer>
                <CircularProgress />
            </SpinnerContainer>
        </Stack>
    );
};
