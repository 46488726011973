import axios from 'axios';
import { getRustAPIUrl } from '../utils/api.ts';
import { Target } from '../types/targets';
import { format } from 'date-fns';
import { parseDate } from '../utils/date-format.ts';

export const getTargets = async (workspaceId: string): Promise<Target[]> => {
    const data = await axios.get<{ data: Target<string>[] }>(
        `${getRustAPIUrl()}/workspaces/${workspaceId}/targets`
    );

    return data.data.data.map(t => ({
        ...t,
        dateStart: parseDate(t.dateStart),
        dateEnd: parseDate(t.dateEnd),
    }));
};

export const putTarget = async (workspaceId: string, target: Target) => {
    return await axios.put<Target>(
        `${getRustAPIUrl()}/workspaces/${workspaceId}/targets/${target.id}`,
        {
            ...target,
            dateStart: format(target.dateStart, 'yyyy-MM-dd'),
            dateEnd: format(target.dateEnd, 'yyyy-MM-dd'),
        }
    );
};

export const deleteTarget = async (workspaceId: string, targetId: string) => {
    return await axios.delete(
        `${getRustAPIUrl()}/workspaces/${workspaceId}/targets/${targetId}`
    );
};
