import {
    useAvailableViews,
    useRefreshAvailableViews,
} from './useAvailableViews';
import { useIntegration } from '../useIntegration';
import _, { keyBy } from 'lodash';
import { isGA } from '../../../../types/integration';

export const useViews = (workspaceId: string, integrationId: string) => {
    const {
        data: availableViews,
        isLoading: availableViewsLoading,
        error: availableViewsError,
    } = useAvailableViews(workspaceId || '');

    const { mutate: refetchAvailableViews, isLoading: isRefetching } =
        useRefreshAvailableViews(workspaceId || '');

    const {
        data: integration,
        isLoading: integrationLoading,
        error: integrationError,
    } = useIntegration(integrationId || '');

    const availableViewsByProperty = keyBy(availableViews || [], 'id');
    const selectedViews = isGA(integration)
        ? integration.views
              .map(view => ({
                  ...availableViewsByProperty[view.view_id],
                  ...view,
              }))
              .filter(Boolean)
              .sort((a, b) => (a.name > b.name ? 1 : -1))
        : [];

    const selectedViewIdSet = new Set(selectedViews.map(v => v.view_id));

    return {
        isLoading: availableViewsLoading || integrationLoading,
        error: availableViewsError || integrationError,
        data: {
            availableViews: _.chain(availableViews || [])
                .filter(view => !selectedViewIdSet.has(view.id))
                .sortBy(['propertyName', 'viewName'])
                .value(),
            selectedViews,
        },
        refetchAvailableViews,
        isRefetching,
    };
};
