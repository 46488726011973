import { DateRangePickerAction, PeriodType } from './reducer.tsx';
import { useDateRangePicker } from './useDateRangePicker.tsx';
import { List, ListItemButton, ListItemText, styled } from '@mui/material';
import {
    comparePresets,
    mainPresets,
    Preset,
    presetLabel,
} from './presetDefinitions.ts';

type PresetProps = {
    periodType: PeriodType;
    current: Preset;
};

export const Presets = ({ periodType, current }: PresetProps) => {
    const { dispatch } = useDateRangePicker();
    const presets = actions[periodType];

    return (
        <PresetList dense={true}>
            {presets.map((item, i: number) => (
                <ListItemButton
                    selected={current === item.preset}
                    autoFocus={current === item.preset}
                    key={i}
                    onClick={() => dispatch(item)}
                >
                    <ListItemText>{presetLabel(item.preset)}</ListItemText>
                </ListItemButton>
            ))}
        </PresetList>
    );
};

const PresetList = styled(List)(({ theme }) => ({
    flex: '1 1 auto',
    overflowY: 'auto',
    padding: 0,
    [theme.breakpoints.up('xs')]: {
        height: 'auto',
        maxHeight: 128,
    },
    [theme.breakpoints.up('sm')]: {
        height: 0,
        maxHeight: 'initial',
    },
    '&  .MuiListItemText-primary': {
        fontSize: '0.75rem',
    },
}));

type PresetAction = Extract<DateRangePickerAction, { type: 'selectPreset' }>;
const actions: Record<PeriodType, PresetAction[]> = {
    main: mainPresets.map(preset => ({
        type: 'selectPreset',
        period: 'main',
        preset,
    })),
    compare: comparePresets.map(preset => ({
        type: 'selectPreset',
        period: 'compare',
        preset,
    })),
};
