import { Box, Card, Stack, styled, Typography } from '@mui/material';
import { Point } from '@nivo/line';
import { format } from 'date-fns';
import { useLocale } from '../../../../../providers/LocaleProvider.hooks';
import { getPercentageDifference } from '../../../../../utils/get-percentage-difference';
import { formatNumber, NumberFormat } from '../../../../../utils/number-format';
import { ChartData } from './useCompareDateRangeQuery';
import { parseDate } from '../../../../../utils/date-format.ts';

interface TooltipProps {
    metricName: string;
    numberFormat: NumberFormat;
    data: ChartData[];
    points: Point[];
}

const DATE_FORMAT = 'EEEE, d MMMM yyyy';

export const Tooltip = (props: TooltipProps) => {
    const { metricName, numberFormat, data, points } = props;

    const index = parseInt(points[0].id.split('.')[1]);

    const current = points.find(({ serieId }) => serieId === 'current');
    const lastPeriod = points.find(({ serieId }) => serieId === 'lastPeriod');

    const dataCurrent = data[index].current;
    const dataLastPeriod = data[index].lastPeriod;

    const percentageDifference =
        current &&
        lastPeriod &&
        getPercentageDifference(dataLastPeriod?.y, dataCurrent.y);

    return (
        <Card elevation={2} sx={{ p: 2 }}>
            <Stack spacing={1}>
                {current && (
                    <MetricSummary
                        metricName={metricName}
                        numberFormat={numberFormat}
                        date={dataCurrent.x}
                        dateEnd={dataCurrent.xEnd}
                        value={dataCurrent.y}
                        color={current.color}
                    />
                )}

                {lastPeriod && dataLastPeriod && (
                    <MetricSummary
                        metricName={metricName}
                        numberFormat={numberFormat}
                        date={dataLastPeriod.x}
                        dateEnd={dataLastPeriod.xEnd}
                        value={dataLastPeriod.y}
                        color={lastPeriod.color}
                        change={percentageDifference}
                    />
                )}
            </Stack>
        </Card>
    );
};

interface MetricSummaryProps {
    metricName: string;
    numberFormat: NumberFormat;
    date: string;
    dateEnd: string;
    value: number | undefined;
    color: string;
    change?: number;
}

const MetricSummary = (props: MetricSummaryProps) => {
    const locale = useLocale();
    const { metricName, numberFormat, date, dateEnd, value, color, change } =
        props;

    const from = format(parseDate(date), DATE_FORMAT);
    const to = format(parseDate(dateEnd), DATE_FORMAT);

    return (
        <Box sx={{ fontSize: 14 }}>
            <Bold>{from === to ? from : [from, to].join(' - ')}</Bold>
            <Stack direction="row">
                <Legend color={color} />
                <Stack>
                    <Text>
                        {`${metricName}: `}
                        <strong>
                            {formatNumber(value, numberFormat, locale)}
                        </strong>
                    </Text>

                    {change && (
                        <Text>
                            {`Change: `}
                            <strong>
                                {formatNumber(
                                    change,
                                    NumberFormat.SignedPercentage,
                                    locale
                                )}
                            </strong>
                        </Text>
                    )}
                </Stack>
            </Stack>
        </Box>
    );
};

const Text = styled(Typography)({
    paragraph: false,
    fontSize: 14,
});

const Bold = styled(Text)({
    fontWeight: 'bold',
});

interface LegendProps {
    color: string;
}

const Legend = styled(Box)<LegendProps>(({ color }) => ({
    component: 'span',
    width: 8,
    height: 8,
    background: color,
    verticalAlign: 'middle',
    marginTop: '0.375rem',
    marginRight: '0.375rem',
}));
