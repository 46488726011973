import { Button, Stack, TextFieldVariants, Typography } from '@mui/material';
import { BinaryFilter } from '../../queries/filters';
import { FilterBuilderRow } from './FilterBuilderRow';
import { FilterBuilderContext } from './FilterBuilderContext';
import { DataModelSchema } from '../../types/datamodel/schema';
import { ControllerFieldState } from 'react-hook-form';
import { defaultFilter } from './defaultFilter.tsx';
import { Add } from '@mui/icons-material';

export type FilterFieldType = 'dimensions' | 'metrics';
interface FilterBuilderProps {
    dataset: string;
    schema: DataModelSchema;
    fieldType: FilterFieldType;
    filters: BinaryFilter[];
    onChange: (filters: BinaryFilter[]) => void;
    fieldState?: ControllerFieldState;
    addButtonText?: string;
    variant?: TextFieldVariants;
}

const fallbackFieldState: ControllerFieldState = {
    invalid: false,
    isDirty: false,
    isTouched: false,
};

export const FilterBuilder = ({
    dataset,
    schema,
    fieldType,
    filters,
    onChange,
    fieldState = fallbackFieldState,
    addButtonText = 'Add filter',
    variant,
}: FilterBuilderProps) => {
    const addFilter = () => onChange([...filters, defaultFilter()]);

    const handleChange = (replaceI: number) => (newFilter: BinaryFilter) =>
        onChange(filters.map((f, i) => (i === replaceI ? newFilter : f)));

    const handleDelete = (deleteI: number) => () =>
        onChange(filters.filter((_, i) => i !== deleteI));

    return (
        <FilterBuilderContext
            dataset={dataset}
            schema={schema}
            fieldType={fieldType}
            fieldState={fieldState}
        >
            <Stack gap={1} alignItems="start">
                {filters.map((f, i) => (
                    <FilterBuilderRow
                        key={i}
                        index={i}
                        filter={f}
                        variant={variant}
                        onChange={handleChange(i)}
                        onDelete={handleDelete(i)}
                    />
                ))}
                <Stack direction="row" alignItems="center" spacing={1}>
                    <Button
                        startIcon={<Add />}
                        variant="filter"
                        onClick={addFilter}
                    >
                        {addButtonText}
                    </Button>
                    {fieldState.error?.type === 'too_small' && (
                        <Typography
                            variant="body2"
                            component="span"
                            color="error"
                        >
                            Add at least one condition
                        </Typography>
                    )}
                </Stack>
            </Stack>
        </FilterBuilderContext>
    );
};
