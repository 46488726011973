import { useContext } from 'react';
import { WorkspaceContext } from './WorkspaceProvider.tsx';

export const useActiveWorkspace = () => {
    const context = useContext(WorkspaceContext);

    if (context === undefined) {
        throw new Error('useWorkspace must be used within a WorkspaceProvider');
    }

    return context;
};
