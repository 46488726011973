import axios, { AxiosRequestConfig } from 'axios';
import { getAPIUrl, getRustAPIUrl } from './utils/api';

export const configureInterceptor = (fetchToken: () => Promise<string>) => {
    const interceptor = axios.interceptors.request.use(
        async (config: AxiosRequestConfig) => {
            if (!config.url) return;

            // Do something before request is sent
            const base = new URL(config.url);
            if (
                base.origin === getAPIUrl() ||
                base.origin === getRustAPIUrl()
            ) {
                const token = await fetchToken();
                config.headers = {
                    ...config.headers,
                    Authorization: `Bearer ${token}`,
                };
            }
            return config;
        },
        (error: any) => {
            // Do something with request error
            return Promise.reject(error);
        }
    );

    return () => {
        axios.interceptors.request.eject(interceptor);
    };
};
