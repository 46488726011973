import { useEffect, useState } from 'react';
import { Input } from './WidgetConfigForm.styled';

interface CampaignTitleProps {
    initial?: string;
    onChange?: (newValue: string) => void;
}
export const CampaignTitle = ({ initial, onChange }: CampaignTitleProps) => {
    const [title, setTitle] = useState('');

    useEffect(() => {
        setTitle(initial ?? '');
    }, [initial]);

    return (
        <Input
            id="config-title"
            fullWidth
            onChange={event => {
                setTitle(event.target.value);
            }}
            onBlur={event => {
                onChange?.(event.target.value);
            }}
            label="Widget title"
            value={title}
        />
    );
};
