import { useDashboardState } from '../useDashboardState.ts';
import { LastModifiedText } from '../../../components/Toolbar/LastModifiedText.tsx';
import { Stack } from '@mui/material';
import { ConflictAlert } from '../../../components/Toolbar/ConflictAlert.tsx';
import { UndoRedoReset } from '../../../components/Toolbar/UndoRedoReset.tsx';
import { ResolveButton } from '../../../components/Toolbar/ResolveButton.tsx';
import { SaveButton } from '../../../components/Toolbar/SaveButton.tsx';
import React from 'react';
import { useActiveUser } from '../../../providers/CurrentUserProvider.hooks.ts';
import { useDashboardMutation } from '../use-dashboard-mutation.ts';
import { userToModifiedBy } from '../../../types/user';
import { useNavigate } from 'react-router-dom';
import { usePermissions } from '../../../hooks/use-permissions.ts';
import { isPermitted } from '../../../types/permission/permission.ts';
import { useDashboardContext } from '../useDashboardContext.ts';
import { SnackbarMessage } from '../../Explorer/SnackBarMessage.tsx';
import { useActiveWorkspaceId } from '../../../providers/useActiveWorkspaceId.ts';

export function UndoRedoResetToolbar() {
    const workspaceId = useActiveWorkspaceId();
    const {
        clearLocalChanges,
        canUndo,
        undo,
        canRedo,
        redo,
        newRemoteDashboard,
        localDashboard,
    } = useDashboardState();
    const { data: permissions } = usePermissions(
        workspaceId,
        localDashboard.id,
        'dashboard'
    );

    const currentUser = useActiveUser();
    const { updateDashboard, duplicateDashboard, isLoading, isError } =
        useDashboardMutation();
    const dashboardConfig = useDashboardContext();
    const canWrite = isPermitted(dashboardConfig.accessLevel, 'write');
    const sharedWith =
        permissions?.filter(
            p =>
                p.objectId === localDashboard.id &&
                p.subjectId !== currentUser.memberId
        ) ?? [];

    const navigate = useNavigate();

    const handleSave = () => {
        updateDashboard(localDashboard.id, localDashboard.config).then(() =>
            clearLocalChanges({
                ...localDashboard,
                modifiedAt: Date.now(),
                modifiedBy: userToModifiedBy(currentUser),
                version: localDashboard.version + 1,
            })
        );
    };

    const handleDuplicate = () => {
        duplicateDashboard(localDashboard).then(newDashboard => {
            clearLocalChanges();
            navigate(`/workspaces/${workspaceId}/dashboard/${newDashboard.id}`);
        });
    };

    if (!permissions) {
        return;
    }

    if (!canUndo && !canRedo && !newRemoteDashboard) {
        return (
            <LastModifiedText
                modifiedAt={localDashboard.modifiedAt}
                modifiedBy={localDashboard.modifiedBy}
            />
        );
    }

    return (
        <Stack direction="row" spacing={1}>
            {newRemoteDashboard && (
                <ConflictAlert
                    isLoading={isLoading}
                    modifiedBy={newRemoteDashboard.modifiedBy}
                />
            )}

            <UndoRedoReset
                canRedo={canRedo}
                canUndo={canUndo}
                redo={redo}
                undo={undo}
                clear={clearLocalChanges}
            />

            {newRemoteDashboard ? (
                <ResolveButton
                    canUndo={canUndo}
                    onReset={() => clearLocalChanges(newRemoteDashboard)}
                    onSave={handleSave}
                    onDuplicate={handleDuplicate}
                />
            ) : (
                <SaveButton
                    isLoading={isLoading}
                    onReset={() => clearLocalChanges(newRemoteDashboard)}
                    onSave={handleSave}
                    onDuplicate={handleDuplicate}
                    canWrite={canWrite}
                    canUndo={canUndo}
                    isShared={sharedWith.length > 0}
                />
            )}

            {isError && (
                <SnackbarMessage message="Something went wrong while saving the dashboard" />
            )}
        </Stack>
    );
}
