import {
    SeinoDialogContainer,
    SeinoDialogTab,
    SeinoDialogTabPanel,
    SeinoDialogTabs,
} from '../../components/Dialog/SeinoDialog.styled.tsx';
import * as React from 'react';
import { ShareDialog } from './Dialog/ShareDialog.tsx';
import { useDialog } from '../../components/Dialog/useDialog.ts';
import { SeinoDialog } from '../../components/Dialog/SeinoDialog.tsx';

export function DashboardDialog() {
    const {
        openDialog,
        parameters: { panel },
    } = useDialog<{ panel: string }>('dashboard');

    const handleOnChange = (_: unknown, panel: string) => {
        openDialog({ panel });
    };

    return (
        <SeinoDialog id="dashboard">
            <SeinoDialogContainer>
                <SeinoDialogTabs
                    value={panel || 'sharing'}
                    onChange={handleOnChange}
                >
                    <SeinoDialogTab
                        key="dialog-tab-sharing"
                        label="Sharing"
                        value="sharing"
                    />
                </SeinoDialogTabs>
                <SeinoDialogTabPanel
                    key="dialog-tab-panel-sharing"
                    value={panel}
                    id="sharing"
                >
                    <ShareDialog />
                </SeinoDialogTabPanel>
            </SeinoDialogContainer>
        </SeinoDialog>
    );
}
