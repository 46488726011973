import { ValueFormatterParams } from '@ag-grid-community/core';
import { format } from 'date-fns';
import { parseDate } from '../../../../utils/date-format.ts';

export const dateFormatter =
    (dateFormat: string) => (params: ValueFormatterParams) => {
        if (!params.value) return params.value;

        const dateToFormat = parseDate(params.value.split(' ')[0]);

        if (!dateToFormat) return params;

        return format(dateToFormat, dateFormat);
    };
