import { Box, Stack, styled, Typography } from '@mui/material';
import {
    BinaryFilter,
    BinaryOperator,
    isSetOperator,
} from '../../../queries/filters';

import { conditions } from './Config/conditions.tsx';
import { useDatasetSchema } from '../../../hooks/use-dataset-schema.ts';
import { findField } from '../../../queries/schema.ts';
import { getDimensionFormatter } from '../../../utils/dimension-format.ts';
import { isDimensionFormat } from '../../../types/datamodel/schema.ts';

interface ActiveFiltersProps {
    dataset: string;
    filters?: BinaryFilter[];
}

const getLabelForOperator = (operator: BinaryOperator) =>
    conditions[operator]?.label.toLowerCase() || '';

const FilterChip = styled(Box)`
    display: flex;
    align-items: center;
    border: 1px solid #009dbb;
    border-radius: 14px;
    color: #009dbb;
    padding: 2px 12px;
    font-size: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 2px 4px;
    user-select: none;
`;

const Container = styled(Stack)({
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginBottom: 1,
});

const isRelevantAndActiveFilter = ({
    member,
    values,
    operator,
}: BinaryFilter) =>
    member &&
    values &&
    (isSetOperator(operator) || (values.length > 0 && values[0] !== ''));

export const ActiveFilters = ({
    dataset,
    filters = [],
}: ActiveFiltersProps) => {
    const { data: schema } = useDatasetSchema(dataset);

    return (
        <Container>
            {filters
                ?.filter(isRelevantAndActiveFilter)
                .map((filter: BinaryFilter, index) => {
                    const dimension = findField(schema)(filter.member);
                    if (!dimension || !isDimensionFormat(dimension.format)) {
                        return null;
                    }

                    return (
                        <FilterChip key={index}>
                            <Typography
                                sx={{ fontSize: '12px' }}
                                maxWidth={600}
                                noWrap
                            >
                                <strong>
                                    {dimension.shortName ?? dimension.name}{' '}
                                </strong>
                                {getLabelForOperator(filter.operator)}{' '}
                                {joinValues(
                                    filter.values.map(
                                        getDimensionFormatter(dimension.format)
                                    )
                                )}
                            </Typography>
                        </FilterChip>
                    );
                })}
        </Container>
    );
};

const joinValues = (values: string[]) =>
    [values.slice(0, -1).join(', '), values.slice(-1)]
        .filter(Boolean)
        .join(' or ');
