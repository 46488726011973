import { Box, Grid, Paper, styled } from '@mui/material';
import { ReactNode } from 'react';

const Content = styled(Box)`
    padding: ${({ theme }) => theme.spacing(3)};
`;

const Sidebar = styled(Box)`
    height: 100%;
    background-color: ${({ theme }) => theme.palette.background.default};
`;

export const PageWithSidebar = ({
    sideBar,
    children,
}: {
    sideBar: ReactNode;
    children: ReactNode;
}) => (
    <Box component={Paper}>
        <Grid container>
            <Grid item xs={3}>
                <Sidebar>{sideBar}</Sidebar>
            </Grid>
            <Grid item xs={9}>
                <Content>{children}</Content>
            </Grid>
        </Grid>
    </Box>
);
