import { memo } from 'react';

type CountryComponentProps = { value: string };

const countryMapping: Record<string, string> = {
    BENL: 'BE',
    BEFR: 'BE',
};

const CountryComponentInner = ({ value }: CountryComponentProps) => {
    if (!value) return <></>;

    const marketNormalized = value.toUpperCase();
    let flag = marketNormalized;
    if (marketNormalized.length > 2) {
        if (marketNormalized in countryMapping) {
            flag = countryMapping[marketNormalized];
        } else {
            const matches = marketNormalized.match(/[A-Z]{2,}/g);
            if (matches && matches.length > 0) {
                flag = matches[matches.length - 1];
            }
        }
    }

    return (
        <span>
            <img
                alt={marketNormalized}
                src={`/img/markets/${flag === 'UK' ? 'GB' : flag}.png`}
                style={{ verticalAlign: 'text-bottom' }}
                onError={event => (event.currentTarget.style.display = 'none')}
            />{' '}
            {marketNormalized}
        </span>
    );
};

export const CountryComponent = memo(CountryComponentInner);
