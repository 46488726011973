import { Localization } from '../types/datamodel/localization';
import { match } from 'ts-pattern';
import { memoize } from 'lodash';
import { FormatType } from '../types/datamodel/schema';

export const genericDefaultOptions: Partial<Intl.NumberFormatOptions> = {
    maximumFractionDigits: 3,
};
export const genericCompactOptions: Partial<Intl.NumberFormatOptions> = {
    maximumFractionDigits: 2,
    notation: 'compact',
};

export const currencyCompactFormatOptions: Partial<Intl.NumberFormatOptions> = {
    style: 'currency',
    notation: 'compact',
    maximumFractionDigits: 2,
};

export enum NumberFormat {
    Generic = 'generic',
    Percentage = 'percentage',
    Percent = 'percent',
    SignedPercentage = 'signed_percentage',
    Currency = 'currency',
    Duration = 'duration',
}

type FormatterOptions = {
    mode?: 'compact' | 'default';
    nan_text?: string;
    max_digits?: number;
};
type WithFormatterOptions<T> = T & FormatterOptions;
type Formatter = (value: number) => string;

const genericFormatter = ({
    locale,
    mode,
    max_digits,
}: WithFormatterOptions<Pick<Localization, 'locale'>>) =>
    new Intl.NumberFormat(locale, {
        ...(mode === 'compact' ? genericCompactOptions : genericDefaultOptions),
        ...(max_digits !== undefined
            ? { maximumFractionDigits: max_digits }
            : {}),
    });

const currencyFormatter = ({
    locale,
    currency,
    mode,
    max_digits,
}: WithFormatterOptions<Pick<Localization, 'locale' | 'currency'>>) =>
    new Intl.NumberFormat(locale, {
        style: 'currency',
        currency,
        ...(mode === 'compact' ? currencyCompactFormatOptions : {}),
        ...(max_digits !== undefined
            ? { maximumFractionDigits: max_digits }
            : {}),
    });

const percentageFormatter = ({
    locale,
}: WithFormatterOptions<Pick<Localization, 'locale'>>) =>
    new Intl.NumberFormat(locale, {
        style: 'percent',
        maximumFractionDigits: 2,
    });

const signedPercentageFormatter = ({
    locale,
}: WithFormatterOptions<Pick<Localization, 'locale'>>) =>
    new Intl.NumberFormat(locale, {
        style: 'percent',
        signDisplay: 'exceptZero',
    });

const formatDuration: Formatter = (value: number) => {
    const totalSeconds = Math.round(value);

    if (!totalSeconds) {
        return '';
    }

    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return [
        hours ? hours + 'h' : '',
        minutes ? minutes + 'm' : '',
        seconds ? seconds + 's' : '',
    ]
        .filter(Boolean)
        .join(' ');
};

const getFormatterInternal = (
    format: NumberFormat | FormatType,
    locale: Localization,
    options?: FormatterOptions
): Formatter => {
    return match(format)
        .with(
            NumberFormat.Currency,
            'currency',
            () => currencyFormatter({ ...locale, ...options }).format
        )
        .with(
            'currency_rounded',
            () =>
                currencyFormatter({ ...locale, ...options, max_digits: 0 })
                    .format
        )
        .with(
            NumberFormat.Percent,
            NumberFormat.Percentage,
            'percent',
            () => percentageFormatter(locale).format
        )
        .with(
            NumberFormat.SignedPercentage,
            () => signedPercentageFormatter(locale).format
        )
        .with(NumberFormat.Duration, 'duration', () => formatDuration)
        .with(
            'number_rounded',
            () =>
                genericFormatter({
                    locale: locale.locale,
                    max_digits: 0,
                    ...options,
                }).format
        )
        .otherwise(
            () =>
                genericFormatter({
                    locale: locale.locale,
                    ...options,
                }).format
        );
};

export const getFormatter = memoize(
    getFormatterInternal,
    (format, locale, options) => {
        return JSON.stringify([format, locale, options]);
    }
);

export const formatNumber = (
    input: unknown,
    format: NumberFormat | FormatType | undefined,
    locale: Localization,
    options?: FormatterOptions
) => {
    const value =
        typeof input === 'string' ? parseNumber(input, format) : input;

    return typeof value === 'number' && !isNaN(value)
        ? getFormatter(format || 'number', locale, options)(value)
        : options?.nan_text ?? 'N/A';
};

const parseNumber = (
    input: unknown,
    format: NumberFormat | FormatType | undefined
): number => {
    if (typeof input === 'number') {
        return input;
    }

    if (typeof input === 'string') {
        const number = parseFloat(input);

        return format === 'percent' ||
            format === 'percentage' ||
            format === 'signed_percentage'
            ? number / 100
            : number;
    }

    return NaN;
};
