import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { SnackbarProvider, SnackbarProviderProps } from 'notistack';
import './App.css';
import { SeinoRouter } from './router/Router';
import { BrowserRouter } from 'react-router-dom';
import { Auth0ProviderWithNavigate } from './providers/Auth0ProviderWithNavigate';
import {
    MutationCache,
    QueryCache,
    QueryClient,
    QueryClientProvider,
} from '@tanstack/react-query';
import { DuckProvider } from './providers/duck';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { captureException } from '@sentry/react';
import { ThemeProvider } from '@mui/material';
import { theme } from './theme';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { StatusBarModule } from '@ag-grid-enterprise/status-bar';
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail';
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import { GridChartsModule } from '@ag-grid-enterprise/charts';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { SideBarModule } from '@ag-grid-enterprise/side-bar';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { CsvExportModule } from '@ag-grid-community/csv-export';
import { MultiFilterModule } from '@ag-grid-enterprise/multi-filter';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';

const snackbarProps: Partial<SnackbarProviderProps> = {
    maxSnack: 3,
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
    },
    autoHideDuration: 2000,
};

const queryClient = new QueryClient({
    queryCache: new QueryCache({
        onError: error => captureException(error),
    }),
    mutationCache: new MutationCache({
        onError: error => captureException(error),
    }),
});

ModuleRegistry.registerModules([
    ClientSideRowModelModule,
    ClipboardModule,
    ColumnsToolPanelModule,
    CsvExportModule,
    ExcelExportModule,
    FiltersToolPanelModule,
    GridChartsModule,
    MasterDetailModule,
    MenuModule,
    MultiFilterModule,
    RangeSelectionModule,
    RowGroupingModule,
    ServerSideRowModelModule,
    SetFilterModule,
    SideBarModule,
    StatusBarModule,
]);

const BaseApp = () => {
    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <Auth0ProviderWithNavigate>
                    <QueryClientProvider client={queryClient}>
                        <DuckProvider>
                            <SnackbarProvider {...snackbarProps}>
                                <SeinoRouter />
                                <ReactQueryDevtools
                                    initialIsOpen={false}
                                    position="bottom-right"
                                />
                            </SnackbarProvider>
                        </DuckProvider>
                    </QueryClientProvider>
                </Auth0ProviderWithNavigate>
            </BrowserRouter>
        </ThemeProvider>
    );
};

export const App = withLDProvider({
    clientSideID: import.meta.env.VITE_LD_CLIENT_ID,
    options: {
        bootstrap: 'localStorage',
    },
})(BaseApp);
