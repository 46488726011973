import {
    Button,
    ButtonGroup,
    ClickAwayListener,
    Divider,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Popper,
    Typography,
} from '@mui/material';
import React, { useRef, useState } from 'react';
import {
    ArrowDropDown,
    ContentCopy,
    DeleteOutlined,
    Edit,
} from '@mui/icons-material';
import styled from 'styled-components';
import { useAlertRuleMutation } from '../../hooks/use-alert-rule-mutation';
import { AlertRule } from '../../types/alerting';
import { useNavigate } from 'react-router-dom';
import { useActiveWorkspace } from '../../providers/WorkspaceProvider.hooks';
import ConfirmDialog from '../../components/ConfirmDialog/ConfirmAlert';

const OptionButton = styled(Typography).attrs({
    variant: 'body2',
    fontSize: 12,
})``;

interface SelectorInterface {
    enabled?: boolean;
    alert: AlertRule;
}

export function AlertingTableModifier({
    enabled = true,
    alert,
}: SelectorInterface) {
    const [open, setOpen] = useState(false);
    const [removeDialogOpen, setRemoveDialog] = useState(false);
    const navigate = useNavigate();
    const { workspaceId } = useActiveWorkspace();
    const anchorRef = useRef<HTMLDivElement | null>(null);

    const { remove } = useAlertRuleMutation();

    const handleEditOnClick = () => {
        setOpen(false);
        navigate(`/workspaces/${workspaceId}/alerting/${alert.id}`);
    };

    const handleToggle = () => setOpen(prevOpen => !prevOpen);

    const handleDuplicate = () => {
        setOpen(false);
        navigate(`/workspaces/${workspaceId}/alerting/${alert.id}?copy`);
    };

    const handleClose = (event: Event) => {
        const currentAnchorRef = anchorRef.current;
        if (
            currentAnchorRef &&
            currentAnchorRef.contains(event.target as HTMLElement)
        ) {
            return;
        }

        setOpen(false);
    };

    return (
        <>
            <ButtonGroup
                disabled={!enabled}
                variant="text"
                disableElevation
                disableRipple
                disableFocusRipple
                ref={anchorRef}
            >
                <Button
                    style={{ textTransform: 'capitalize' }}
                    size="small"
                    onClick={handleToggle}
                    endIcon={enabled && <ArrowDropDown />}
                >
                    <OptionButton>Edit</OptionButton>
                </Button>
            </ButtonGroup>

            <Popper
                sx={{ zIndex: 1301 }}
                open={open}
                anchorEl={anchorRef.current}
            >
                <Paper sx={{ minWidth: 'fit-content', maxWidth: '400px' }}>
                    <ClickAwayListener onClickAway={handleClose}>
                        <List>
                            <ListItemButton onClick={handleEditOnClick}>
                                <ListItemIcon>
                                    <Edit />
                                </ListItemIcon>
                                <ListItemText primary="Edit" />
                            </ListItemButton>
                            <Divider />
                            <ListItemButton onClick={handleDuplicate}>
                                <ListItemIcon>
                                    <ContentCopy />
                                </ListItemIcon>
                                <ListItemText primary="Duplicate" />
                            </ListItemButton>
                            <Divider />
                            <ListItemButton
                                onClick={() => {
                                    setOpen(false);
                                    setRemoveDialog(true);
                                }}
                            >
                                <ListItemIcon>
                                    <DeleteOutlined color="error" />
                                </ListItemIcon>
                                <ListItemText primary="Remove" />
                            </ListItemButton>
                        </List>
                    </ClickAwayListener>
                </Paper>
            </Popper>
            <ConfirmDialog
                open={removeDialogOpen}
                onAnyDecision={() => setRemoveDialog(false)}
                onContinue={() => remove(alert.id)}
                isDangerousAction={true}
                content="Are you sure you want to remove this alert rule?"
                continueText="Remove"
            />
        </>
    );
}
