import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { DateRangePickerDispatch } from './reducer';
import { GridSpacer } from './CalendarDay';

interface MonthSwitcherProps {
    dispatch: DateRangePickerDispatch;
    dir: 'next' | 'prev';
    hide?: boolean;
}

export const MonthSwitcher = ({ dispatch, dir, hide }: MonthSwitcherProps) => {
    if (hide) {
        return <GridSpacer />;
    }

    const handleClick = () =>
        dispatch({
            type: 'adjustVisibleMonths',
            offset: dir === 'next' ? 1 : -1,
        });

    const icon = dir === 'next' ? <ChevronRight /> : <ChevronLeft />;

    return (
        <IconButton size="small" onClick={handleClick} sx={{ p: 0.5 }}>
            {icon}
        </IconButton>
    );
};
