import { DuckContext } from '../providers/duck.tsx';
import { useContext } from 'react';

export const useDuckConnection = () => {
    const ctx = useContext(DuckContext);
    if (!ctx?.connection) {
        throw new Error('NEIN NEIN NEIN NEIN');
    }

    return ctx.connection;
};
