import { StyledTargetCard } from './TargetCard.styled.tsx';
import { Skeleton, Stack } from '@mui/material';
import React from 'react';
import Typography from '@mui/material/Typography';

export function TargetCardSkeleton() {
    return (
        <StyledTargetCard>
            <Stack>
                <Stack>
                    <Typography variant="h4" paddingBlock={1}>
                        <Skeleton />
                    </Typography>
                    <Typography variant="h6" component="div">
                        <Skeleton />
                    </Typography>
                    <Typography textTransform="capitalize">
                        <Skeleton />
                    </Typography>
                </Stack>
            </Stack>

            <Stack>
                <Skeleton
                    variant="rectangular"
                    height={40}
                    sx={{ mb: '15px' }}
                />

                <Skeleton variant="rectangular" height={30} />
            </Stack>
        </StyledTargetCard>
    );
}
