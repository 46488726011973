import {
    autocompleteClasses,
    Box,
    Button,
    InputBase,
    Popper,
    styled,
} from '@mui/material';
import { grey } from '@mui/material/colors';

export const StyledPopper = styled(Popper)(({ theme }) => ({
    border: `1px solid ${
        theme.palette.mode === 'light' ? '#e1e4e8' : '#30363d'
    }`,
    boxShadow: `0 8px 24px ${
        theme.palette.mode === 'light'
            ? 'rgba(149, 157, 165, 0.2)'
            : 'rgb(1, 4, 9)'
    }`,
    borderRadius: 6,
    width: 300,
    zIndex: theme.zIndex.modal,
    fontSize: 13,
    color: theme.palette.mode === 'light' ? '#24292e' : '#c9d1d9',
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
}));

export const StyledInput = styled(InputBase)(({ theme }) => ({
    padding: 10,
    width: '100%',
    borderBottom: `1px solid ${
        theme.palette.mode === 'light' ? '#eaecef' : '#30363d'
    }`,
    '& input': {
        borderRadius: 4,
        backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#0d1117',
        padding: 8,
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        border: `1px solid ${
            theme.palette.mode === 'light' ? '#eaecef' : '#30363d'
        }`,
        fontSize: 14,
        '&:focus': {
            boxShadow: `0px 0px 0px 3px ${
                theme.palette.mode === 'light'
                    ? 'rgba(3, 102, 214, 0.3)'
                    : 'rgb(12, 45, 107)'
            }`,
            borderColor: theme.palette.mode === 'light' ? '#0366d6' : '#388bfd',
        },
    },
}));

export const StyledAutocompletePopper = styled('div')(({ theme }) => ({
    [`& .${autocompleteClasses.paper}`]: {
        boxShadow: 'none',
        margin: 0,
        color: 'inherit',
        fontSize: 13,
    },
    [`& .${autocompleteClasses.listbox}`]: {
        backgroundColor: '#fff',
        padding: 0,
        [`& .${autocompleteClasses.option}`]: {
            minHeight: 'auto',
            alignItems: 'flex-start',
            padding: 8,
            '&[aria-selected="true"]': {
                backgroundColor: 'transparent',
            },
            [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]:
                {
                    backgroundColor: theme.palette.action.hover,
                },
        },
    },
    [`&.${autocompleteClasses.popperDisablePortal}`]: {
        position: 'relative',
    },
}));

export const AutocompleteButton = styled(Button)({
    textTransform: 'initial',
    borderColor: grey[400],
    justifyContent: 'start',
});

export const PopperHeader = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    borderBottom: `1px solid ${grey[100]}`,
    padding: '8px 10px',
});

export const CheckedOption = styled(Box, {
    shouldForwardProp: prop => prop !== '$selected',
})<{ $selected: boolean }>(({ $selected }) => ({
    width: 17,
    height: 17,
    mr: '5px',
    ml: '-2px',
    visibility: $selected ? 'visible' : 'hidden',
}));

export const OptionLabel = styled(Box)({
    flexGrow: 1,
    '& span': {
        color: '#586069',
    },
});
