import * as React from 'react';
import { MoreMenu } from '../../../components/MoreMenu/MoreMenu.tsx';
import { WorkspaceMember } from '../../../types/workspace-management';
import { useActiveUser } from '../../../providers/CurrentUserProvider.hooks.ts';
import { ChangeNameDialog } from './ChangeNameDialog.tsx';
import { RemoveMemberDialog } from './RemoveMemberDialog.tsx';
import { useToggle } from 'usehooks-ts';
import { useChangeRole } from './useChangeRole.tsx';

type ActionsColumnProps = { member: WorkspaceMember };
export const ActionsColumn = ({ member }: ActionsColumnProps) => {
    const me = useActiveUser();
    const isSelf = member.id === me.memberId;

    const [isRemoveDialogOpen, toggleRemoveDialog] = useToggle(false);
    const [isNameDialogOpen, toggleNameDialog] = useToggle(false);
    const { mutateAsync: changeRole } = useChangeRole();

    return (
        <>
            <MoreMenu
                placement="bottom-end"
                items={[
                    {
                        key: 'change-name',
                        label: 'Change name',
                        onClick: toggleNameDialog,
                    },
                    ...(isSelf
                        ? []
                        : [
                              {
                                  key: 'change-role',
                                  label:
                                      member.role === 'admin'
                                          ? 'Convert to member'
                                          : 'Promote to admin',
                                  onClick: () => {
                                      changeRole({
                                          memberId: member.id,
                                          role:
                                              member.role === 'admin'
                                                  ? 'member'
                                                  : 'admin',
                                      });
                                  },
                              },
                              {
                                  key: 'remove',
                                  label: 'Remove member',
                                  onClick: toggleRemoveDialog,
                              },
                          ]),
                ]}
            />
            <ChangeNameDialog
                open={isNameDialogOpen}
                onClose={toggleNameDialog}
                member={member}
            />
            <RemoveMemberDialog
                open={isRemoveDialogOpen}
                onClose={toggleRemoveDialog}
                member={member}
            />
        </>
    );
};
