import {
    format,
    formatDistanceStrict,
    isBefore,
    isToday,
    isTomorrow,
    isYesterday,
    parse,
} from 'date-fns';

export const dateFormat = (date: number | Date) => format(date, 'yyyy-MM-dd');
export const shortDateFormat = (date: number | Date) => format(date, 'd MMM');
export const longDateTimeFormat = (date: Date) =>
    format(date, 'EEEE io, MMM yyyy HH:mm');

export const shortDateRangeFormat = (
    start: number | Date,
    end: number | Date
) => `${shortDateFormat(start)} - ${shortDateFormat(end)}`;

export const formatHumanReadableDate = (
    date?: Date | string,
    roundingMethod: 'floor' | 'ceil' | 'round' | undefined = undefined,
    unit:
        | 'second'
        | 'minute'
        | 'hour'
        | 'day'
        | 'month'
        | 'year'
        | undefined = undefined
) => {
    if (!date) {
        return '';
    }
    const d = new Date(date);

    if (isToday(d)) {
        return 'Today';
    }
    if (isTomorrow(d)) {
        return 'Tomorrow';
    }
    if (isYesterday(d)) {
        return 'Yesterday';
    }

    const today = new Date();

    const formattedDistance = formatDistanceStrict(
        today.setUTCHours(0, 0, 0, 0),
        d.setUTCHours(0, 0, 0, 0),
        {
            roundingMethod: roundingMethod,
            unit: unit,
        }
    );

    if (isBefore(today, d)) {
        return `in ${formattedDistance}`;
    }

    return `${formattedDistance} ago`;
};

export const parseDate = (input: string) =>
    parse(
        input === 'today' ? format(new Date(), 'yyyy-MM-dd') : input,
        'yyyy-MM-dd',
        new Date()
    );
